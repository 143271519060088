<template>
  <SkModalSection border-bottom="none">
    <SkCard>
      <div class="conflict-modal__card-description">
        {{ $tc('plannings.toolbar.modal.create_from.conflict_resolution.period_info.subtitle',
               conflictDateRanges.length) }}
        <div
          v-for="dateRange in conflictDateRanges"
          :key="dateRange.startDate.format()"
          class="conflict-modal__card-dates"
        >
          {{ dateLabel(dateRange) }}
        </div>
        <div class="conflict-modal__conflicts-details">
          <div class="conflict-modal__tag-label">
            <CrossIcon />
            <span class="conflict-modal__tag-text">{{ tagLabel }}</span>
          </div>
          <SkOroraButton
            class="conflict-modal__conflicts-details__button"
            variant="tertiary"
            @click="handleOpenSidePanel"
          >
            <!-- eslint-disable-next-line max-len -->
            {{ $t('plannings.toolbar.modal.create_from.conflict_resolution.period_info.show_details') }}
          </SkOroraButton>
        </div>
      </div>
      <WarningIcon />
    </SkCard>
    <div class="conflict-modal__choice-section">
      {{ $t('plannings.toolbar.modal.create_from.conflict_resolution.select_resolution.subtitle') }}
    </div>
    <div class="conflict-modal__radio-buttons">
      <div
        v-for="conflictResolutionOption in conflictResolutionOptions"
        :key="conflictResolutionOption"
        :class="radioButtonClass(conflictResolutionOption)"
      >
        <SkRadio
          v-model="selectedConflictResolution"
          :data-value="conflictResolutionOption"
        >
          <!-- eslint-disable max-len -->
          <span class="conflict-modal__radio-button__title">
            {{ $t(`plannings.toolbar.modal.create_from.conflict_resolution.select_resolution.${conflictResolutionOption}.label`) }}
          </span>
          <p class="sk-subtitle--small">
            {{ $t(`plannings.toolbar.modal.create_from.conflict_resolution.select_resolution.${conflictResolutionOption}.description`) }}
          </p>
          <!-- eslint-enable max-len -->
        </SkRadio>
      </div>
    </div>
    <div class="modal__body__buttons-wrapper">
      <SkOroraButton
        variant="secondary"
        @click="handleCancel"
      >
        {{ $t('actions.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        :loading="duplicatingPlanning"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </div>
  </SkModalSection>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  SkModalSection,
  SkCard,
  SkRadio,
  CrossIcon,
  WarningIcon,
} from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';

import { uniq } from '@skello-utils/collection';
import { capitalize } from '@skello-utils/formatting/strings';

const CONFLICT_RESOLUTION_OPTION = {
  keep_shift: 'keep_current_shift_in_conflict',
  replace_shift: 'replace_current_shift_in_conflict',
  unassign_shift: 'unassign_template_shift_in_conflict',
};

export default {
  name: 'ConflictResolutionSection',
  components: {
    CrossIcon,
    SkCard,
    SkModalSection,
    SkRadio,
    WarningIcon,
  },
  data() {
    return {
      selectedConflictResolution: CONFLICT_RESOLUTION_OPTION.keep_shift,
    };
  },
  computed: {
    ...mapState('planningsTemplates', ['conflicts', 'duplicatingPlanning']),
    ...mapState('planningsState', ['shopPlanningConfig']),
    ...mapGetters('planningsState', ['areUnassignedShiftsAllowed', 'currentDate', 'isDailyView']),
    conflictResolutionOptions() {
      const options = [
        CONFLICT_RESOLUTION_OPTION.keep_shift,
        CONFLICT_RESOLUTION_OPTION.replace_shift,
      ];

      if (this.areUnassignedShiftsAllowed) {
        options.push(CONFLICT_RESOLUTION_OPTION.unassign_shift);
      }

      return options;
    },
    tagLabel() {
      const usersCount = uniq(
        this.conflicts.map(conflict => (
          conflict.planning_shift.user ? conflict.planning_shift.user.id : 'unassigned'
        )),
      ).length;

      return this.$t(
        'plannings.toolbar.modal.create_from.conflict_resolution.period_info.employees_conflicts',
        { conflicts_count: this.conflicts.length, users_count: usersCount },
      );
    },
    conflictMondays() {
      const conflictMondays = this.conflicts.map(
        conflict => skDate(conflict.planning_shift.starts_at).startOf('w').format(),
      );
      return uniq(conflictMondays).sort();
    },
    // Date range should merge weeks that follow each other,
    // so that 1-7/8-15 becomes 1-15
    conflictDateRanges() {
      const unmergedDateRanges = this.conflictMondays.map(monday => ({
        startDate: skDate(monday),
        endDate: skDate(monday).endOf('week'),
      }));

      // Initialise with first week from unmergedDateRanges
      const dateRangesInit = [unmergedDateRanges.shift()];
      return unmergedDateRanges.reduce((accumulator, dateRange) => {
        const lastDateRange = accumulator[accumulator.length - 1];
        // If last range covers previous week -> add current week to it
        if (lastDateRange.endDate.isSame(dateRange.endDate.clone().subtract(1, 'week'))) {
          lastDateRange.endDate = dateRange.endDate;
        } else {
          // Otherwise, add a new range
          accumulator.push(dateRange);
        }
        return accumulator;
      }, dateRangesInit);
    },
  },
  methods: {
    ...mapMutations('planningsTemplates', ['toggleConflictSidePanel']),
    radioButtonClass(switchOption) {
      return {
        'conflict-modal__radio-button': true,
        'conflict-modal__radio-button--active': this.selectedConflictResolution === switchOption,
      };
    },
    handleOpenSidePanel() {
      this.$skAnalytics.track('planning_conflicts_view_details');

      this.toggleConflictSidePanel(true);
    },
    dateLabel(dateRange) {
      if (this.isDailyView) {
        return capitalize(skDate(this.currentDate).format('dddd ll'));
      }

      const startDate = capitalize(skDate(dateRange.startDate).format('dddd ll'));
      const endDate = capitalize(skDate(dateRange.endDate).format('dddd ll'));

      return this.$t('plannings.toolbar.modal.create_from.conflict_resolution.period_info.days',
        { startDate, endDate });
    },
    handleCancel(event) {
      this.$emit('cancel-tab');
      this.$skAnalytics.track('planning_conflicts_cancel_modal');
      event.stopPropagation();
    },
    handleSubmit() {
      if (this.duplicatingPlanning) return;

      this.$skAnalytics.track('planning_conflicts_validate_modal');

      this.$emit('submit-tab', this.selectedConflictResolution);
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-card__container {
  padding: 15px 25px 7px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.conflict-modal__card-description {
  font-size: $fs-text-m;
}

.conflict-modal__card-dates {
  padding-top: 11px;
  font-size: $fs-text-l;
  line-height: 22px;
}

.conflict-modal__conflicts-details {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 22px;

  .conflict-modal__conflicts-details__button {
    position: relative;
    top: -4px;
  }
}

.conflict-modal__tag-label {
  color: $sk-black;
  background-color: $sk-error-10;
  margin-right: 12px;
  padding: 5px 16px;
  border-radius: 15px;
  height: 32px;
  font-size: $fs-text-m;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conflict-modal__tag-text {
  padding-left: 10px;
}

.conflict-modal__choice-section {
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
  padding-top: 27px;
  line-height: 22px;
}

.conflict-modal__radio-button {
  padding: 15px 16px 7px;
  border-radius: 4px;
  margin-top: 10px;

  /* Stick to design specifications for radio buttons */
  ::v-deep .sk-radio-v3 {
    .sk-radio__label {
      padding-top: 0;
    }

    .sk-radio__label > input:checked + .sk-radio__radio-display::after {
      top: 1px;
    }

    .sk-radio__radio-display {
      margin-top: 3px;
    }
  }
}

.conflict-modal__radio-buttons {
  margin-top: 22px;
}

.conflict-modal__radio-button--active {
  background: #ddd;
}

.conflict-modal__radio-button__title {
  font-size: $fs-text-m;
  font-weight: $fw-regular;
}

.modal__body__buttons-wrapper {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 8px 1px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 6px 6px;
}
</style>
