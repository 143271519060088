<template>
  <div class="counters-cell">
    <div
      v-if="showDash"
      class="counters-cell--pto"
    >
      <span :class="[currentPeriodTotalWithChanges < 0 && 'counters-cell-value--negative']">-</span>
    </div>

    <div
      v-else-if="ptoCounterCellComponentStoreProps.hasYearlyDistinction"
      class="counters-cell--yearly-distinction"
    >
      <div class="counters-cell--pto">
        <span
          :class="[
            'counters-cell--yearly-distinction--value',
            previousPeriodRemainingPTO < 0 && 'counters-cell-value--negative'
          ]"
        >
          {{ formatNumber(previousPeriodRemainingPTO) }}
        </span>

        <span class="counters-cell--yearly-distinction--unit">
          {{ $t('plannings.monthly_v3.counters.user.yearly_pto_suffix') }}
        </span>
      </div>

      <div class="counters-cell--pto">
        <span
          :class="[
            'counters-cell--yearly-distinction--value',
            currentPeriodTotalWithChanges < 0 && 'counters-cell-value--negative'
          ]"
        >
          {{ formatNumber(currentPeriodTotalWithChanges) }}
        </span>

        <span class="counters-cell--yearly-distinction--unit">
          {{ $t('plannings.monthly_v3.counters.user.pto_suffix') }}
        </span>
      </div>
    </div>

    <div
      v-else
      class="counters-cell--pto"
    >
      <span :class="[currentPeriodTotalWithChanges < 0 && 'counters-cell-value--negative']">
        {{ formatNumber(currentPeriodTotalWithChanges) }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PtoCounterCell',
  props: {
    user: {
      type: Object,
      required: true,
    },
    ptoCounterCellComponentStoreProps: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userPlc() {
      return this.ptoCounterCellComponentStoreProps.bulkPaidLeaveCounters[this.user.id];
    },
    currentPeriod() {
      return this.userPlc?.current_period;
    },
    previousPeriod() {
      return this.userPlc?.previous_period;
    },
    currentPeriodTotalWithChanges() {
      return (this.ptoCounterCellComponentStoreProps.hasYearlyDistinction ?
        this.currentPeriod?.current_year_counter_balance_w_changes :
        this.currentPeriod?.pl_total_w_changes);
    },
    previousPeriodRemainingPTO() {
      return this.previousPeriod?.balance;
    },
    showDash() {
      return this.userPlc == null || this.currentPeriod.show_dash;
    },
  },
  methods: {
    formatNumber(number) {
      return number.toLocaleString(undefined, {
        minimumFractionDigits: 2, maximumFractionDigits: 2, minimumIntegerDigits: 2,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.counters-cell {
  padding: 6px 0;
  line-height: 1;
  font-size: $fs-text-m;

  &--yearly-distinction {
    display: flex;
    flex-direction: column;
    gap: 3.5px;
    justify-content: center;

    &--value {
      flex-basis: 68px;
      text-align: right;
    }

    &--unit {
      flex-basis: 48px;
      text-align: left;
      color: $sk-grey-50;
      font-size: $fs-text-s;
    }
  }

  &--pto {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 4px;
  }
}

.counters-cell-value--negative {
  color: $sk-error;
}
</style>
