<template>
  <div>
    <div class="col-md-4 offset-md-4 mx-auto organisation-setting__page-content">
      <div class="organisation-setting__header">
        <h1 class="sk-header--1">
          {{ $t('organisation_creation.organisation_form.title') }}
        </h1>
      </div>
      <div class="organisation-setting__subtitle">
        <p class="sk-subtitle--large">
          {{ $t('organisation_creation.organisation_form.subtitle') }}
        </p>
      </div>
      <div class="row">
        <div class="col organisation-setting__form_title">
          <h3 class="sk-header--3">
            {{ $t('organisation_creation.organisation_form.form_title') }}
          </h3>
        </div>
        <div class="col organisation-setting__form_helper">
          <p class="sk-subtitle--medium">
            {{ $t('organisation_creation.common.form_helper_beggining') }}
            <span class="organisation-setting__skello-blue">*</span>
            {{ $t('organisation_creation.common.form_helper_end') }}
          </p>
        </div>
      </div>
      <div class="row sk-form__row">
        <div :class="socialeDenominationFieldClass">
          <SkInput
            v-model.trim="socialDenomination"
            :label="$t('organisation_creation.organisation_form.social_denomination')"
            :debounce="validateDenominationSocialeField"
            :debounce-ms="100"
            :error-message="socialeDenominationErrorMessage"
            :errored="socialeDenominationError"
            @keyup.enter.native="validateForm"
          >
            <template #icon>
              <CircledQuestionMarkIcon
                v-tooltip="$t('organisation_creation.common.social_denomination')"
                class="organisation-setting__helper"
              />
            </template>
          </SkInput>
        </div>
        <div :class="organisationNameFieldClass">
          <SkInput
            v-model.trim="organisationName"
            :label="$t('organisation_creation.organisation_form.organisation_name')"
            :debounce="validateOrganisationField"
            :debounce-ms="100"
            :error-message="$t('organisation_creation.error.mandatory')"
            :errored="organisationNameError"
            @keyup.enter.native="validateForm"
          >
            <template #icon>
              <CircledQuestionMarkIcon
                v-tooltip="$t('organisation_creation.common.organisation_name')"
                class="organisation-setting__helper"
              />
            </template>
          </SkInput>
        </div>
      </div>
      <div class="row organisation-setting__second_form_row">
        <div class="col-6 organisation-setting__col">
          <SkSelectV2
            v-model="businessSector"
            :label="$t('organisation_creation.organisation_form.business_sector')"
            :options="businessSectors"
            :no-results-label="$t('search_bar.no_result')"
          />
        </div>
        <div class="col organisation-setting__col">
          <SkInput
            v-model.trim="siren"
            :errored="sirenError"
            :error-message="$t('organisation_creation.error.siren')"
            :label="$t('organisation_creation.organisation_form.siren')"
          />
        </div>
        <span
          v-if="isProspectPaymentTypeSepa"
          class="organisation-setting__form-hr"
        />
      </div>

      <template v-if="isProspectPaymentTypeSepa">
        <div class="row">
          <div class="col organisation-setting__form_title">
            <h3 class="sk-header--3">
              {{ $t('organisation_creation.billing_choice_form.title') }}
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="organisation-setting__col organisation-setting__info">
            {{ $t('organisation_creation.billing_choice_form.info') }}
          </div>
        </div>
        <div class="row">
          <div class="organisation-setting__col organisation-setting__info">
            {{ $t('organisation_creation.billing_choice_form.info_2') }}
          </div>
        </div>
        <div class="row">
          <div class="col-9 organisation-setting__col">
            <SkSelectV2
              v-model="paymentEntity"
              :label="$t('organisation_creation.billing_choice_form.title')"
              :options="paymentEntities"
            />
          </div>
        </div>
      </template>
      <div class="organisation-setting__submit">
        <SkOroraButton
          class="organisation-setting__button"
          :disabled="isDisabled"
          :loading="loading"
          @click="validateForm"
        >
          {{ $t('organisation_creation.organisation_form.submit') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import {
  SkOroraButton,
  SkInput,
  SkSelectV2,
  CircledQuestionMarkIcon,
} from '@skelloapp/skello-ui';

export default {
  name: 'OrganisationSetting',
  components: { SkOroraButton, SkInput, SkSelectV2, CircledQuestionMarkIcon },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToOrganisation) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashOrganisation();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      loading: false,
      sirenError: null,
      organisationNameError: false,
      socialeDenominationError: false,
    };
  },
  computed: {
    ...mapState('onboarding', ['currentOrganisation', 'currentShop']),
    ...mapState('config', ['config']),
    ...mapState('onboarding', ['userCreation']),
    ...mapGetters('onboarding', ['unsavedChangesToOrganisation']),

    isProspectPaymentTypeNoType() {
      if (!this.userCreation.prospect) return null;

      return this.userCreation.prospect.attributes.paymentType ===
        this.config.prospect_payment_types[0];
    },
    isProspectPaymentTypeSepa() {
      if (!this.userCreation.prospect) return null;

      return this.userCreation.prospect.attributes.paymentType ===
        this.config.prospect_payment_types[1];
    },
    isProspectPaymentTypeTransfer() {
      if (!this.userCreation.prospect) return null;

      return this.userCreation.prospect.attributes.paymentType ===
        this.config.prospect_payment_types[2];
    },
    isBillingNoEntity() {
      return this.currentOrganisation.attributes.paymentEntity ===
        this.config.payment_entities[0];
    },
    isDisabled() {
      return !this.organisationName ||
        !this.socialDenomination ||
        this.socialDenomination.trim().length < 3;
    },
    organisationParams() {
      let paymentEntity;

      if (this.isProspectPaymentTypeTransfer) {
        paymentEntity = this.config.payment_entities[2];
      } else if (this.isProspectPaymentTypeNoType) {
        paymentEntity = this.config.payment_entities[0];
      } else {
        paymentEntity = this.paymentEntity;
      }

      return {
        id: this.currentOrganisation.id,
        cluster_node_id: this.currentOrganisation.attributes.rootNodeId,
        organisation: {
          name: this.organisationName,
          business_sector: this.businessSector,
          denomination_sociale: this.socialDenomination,
          siren: this.siren,
          payment_entity: paymentEntity,
        },
      };
    },
    organisationNameFieldClass() {
      const suppClass = this.organisationNameError ? 'organisation-setting__col--errored' : '';
      return `${suppClass} col organisation-setting__col`;
    },
    socialeDenominationFieldClass() {
      const suppClass = this.socialeDenominationError ? 'organisation-setting__col--errored' : '';
      return `${suppClass} col-6 organisation-setting__col`;
    },
    businessSectors() {
      return this.config.business_sectors.map(businessSector => ({
        id: businessSector,
        text: this.$t(`business_sector.${businessSector}`),
      }));
    },
    paymentEntities() {
      return [
        {
          id: this.config.payment_entities[1],
          text: this.$t(`payment_entities.${this.config.payment_entities[1]}`),
        },
        {
          id: this.config.payment_entities[2],
          text: this.$t(`payment_entities.${this.config.payment_entities[2]}`),
        },
      ];
    },
    businessSector: {
      get() {
        return this.currentOrganisation.attributes.businessSector;
      },
      set(newValue) {
        this.setOrganisationAttributes({ businessSector: newValue });
      },
    },
    socialDenomination: {
      get() {
        return this.currentOrganisation.attributes.denominationSociale;
      },
      set(newValue) {
        this.setOrganisationAttributes({ denominationSociale: newValue });
      },
    },
    organisationName: {
      get() {
        return this.currentOrganisation.attributes.name;
      },
      set(newValue) {
        this.setOrganisationAttributes({ name: newValue });
      },
    },
    siren: {
      get() {
        return this.currentOrganisation.attributes.siren;
      },
      set(newValue) {
        this.setOrganisationAttributes({ siren: newValue });
      },
    },
    paymentEntity: {
      get() {
        return this.currentOrganisation.attributes.paymentEntity;
      },
      set(newValue) {
        this.setOrganisationAttributes({ paymentEntity: newValue });
      },
    },
    socialeDenominationErrorMessage() {
      if (
        this.socialDenomination &&
        this.socialDenomination.trim().length > 0 &&
        this.socialDenomination.trim().length < 3
      ) {
        return this.$t('organisation_creation.error.mandatory_length', { min: 3 });
      }
      return this.$t('organisation_creation.error.mandatory');
    },
  },
  created() {
    this.updateOrganisationStep('organisation');
  },
  methods: {
    ...mapActions('onboarding', ['setInitialOrganisation', 'createOrganisation', 'updateOrganisation']),
    ...mapMutations(
      'onboarding',
      ['setOrganisationAttributes', 'setDefaultShopAttributes', 'updateOrganisationStep', 'squashOrganisation'],
    ),
    ...mapActions('onboarding', ['getProspect']),

    validateForm() {
      if (this.isDisabled) return;
      if (this.siren) {
        this.sirenError = this.siren.length <= 0;
      }
      if (this.sirenError || this.organisationNameError || this.socialeDenominationError) return;

      this.$skAnalytics.track('account_creation_organisation_submit');

      this.loading = true;

      if (this.currentOrganisation.id === null) {
        this.createOrganisation(this.organisationParams)
          .then(this.onOrganisationSaved.bind(this))
          .catch(() => {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          });
      } else {
        this.updateOrganisation(this.organisationParams)
          .then(this.onOrganisationSaved.bind(this))
          .catch(() => {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          });
      }
    },
    validateOrganisationField() {
      this.organisationNameError = !this.organisationName;
    },
    validateDenominationSocialeField() {
      this.socialeDenominationError = !(
        this.socialDenomination &&
        this.socialDenomination.trim().length >= 3
      );
    },
    onOrganisationSaved() {
      this.$skAnalytics.setAnalyticsVariables({ currentOrganisation: this.currentOrganisation });
      this.$skAnalytics.sendAnalytics();

      if (!this.currentShop.id) {
        this.setDefaultShopAttributes({
          name: this.currentOrganisation.attributes.name,
          siret: this.currentOrganisation.attributes.siren,
        });
      }
      if (this.paymentEntity === this.config.payment_entities[0] ||
        this.paymentEntity === this.config.payment_entities[1]) {
        this.$router.push({ name: 'shop_form' });
      } else {
        this.updateOrganisationStep('organisation_billing_form');
        this.$router.push({ name: 'organisation_billing_form' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-setting__button {
  width: 90%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.organisation-setting__skello-blue {
  color: $sk-blue;
}

.organisation-setting__form_title {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 18px;

  &:last-of-type {
    margin-top: 30px;
  }
}

.organisation-setting__form_helper {
  margin-top: 45px;
  margin-bottom: 20px;
  text-align: right;
}

.organisation-setting__page-content {
  margin-top: 35px;
  margin-bottom: 30px;
  max-width: 572px;
}

.organisation-setting__submit {
  margin-top: 30px;
}

.organisation-setting__second_form_row {
  margin-top: 10px;
}

.organisation-setting__form-hr {
  width: 550px;
  border-bottom: 1px solid $sk-grey-10;
  padding-bottom: 29px;
  margin-left: 15px;
  margin-right: 10px;
}

.organisation-setting__helper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: relative;
  bottom: 4px;
  top: 0;
}

.organisation-setting__header {
  margin-bottom: 9px;
}

.organisation-setting__subtitle {
  max-width: 370px;
  margin: auto;
}

.organisation-setting__col {
  padding-left: 15px;
  padding-right: 10px;
}

.organisation-setting__info {
  color: $sk-grey;
  text-align: left;

  &:first-of-type {
    margin-bottom: 15px;
  }
}

.organisation-setting__col--errored {
  padding-bottom: 10px;
}
</style>
