<template>
  <SkModal
    id="display-options-modal"
    ref="displayOptionsModal"
    :modal-title="$t('plannings.toolbar.modal.display_options.title')"
    @show="handleShow"
    @hidden="handleHide"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #title-icon>
      <SkMedallion
        icon="OpenEyeIcon"
        color="#727272"
        background-color="#f3f3f3"
      />
    </template>
    <template #body>
      <DayOptionsSelection
        :visible-days="visibleDays"
        class="display-options-modal__day-option-selection"
        @update-visible-days="handleUpdateVisibleDays"
      />

      <ZoomRangeSelection class="display-options-modal__zoom-range-selection" />

      <SkModalSection
        v-for="displayAction in displayActions"
        :key="displayAction.type"
        class="display-options-modal__content"
      >
        <div :class="displayOptionsTitleClasses(displayAction)">
          {{ displayAction.title }}
        </div>
        <SkSwitch
          v-model="displayAction.toggleValue"
          v-tooltip="packOfferTooltip"
          :data-test="displayAction.dataTest"
          :disabled="displayAction.disabled"
        />
        <span
          v-if="displayAction.disabled"
          class="display-options-modal__pack-tag"
        >
          <PackTag type="success" />
        </span>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="shopPlanningConfigLoading"
        data-test="display-options__submit"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import skDate from '@skello-utils/dates';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';
import PackTag from '@app-js/shared/components/PackTag';
import DayOptionsSelection from './DayOptionsSelection';
import ZoomRangeSelection from './ZoomRangeSelection';

export default {
  name: 'DisplayOptionsModal',
  components: {
    DayOptionsSelection,
    ZoomRangeSelection,
    PackTag,
  },
  data() {
    return {
      visibleDays: [],
      displayActions: [],
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser', 'originalCurrentUserData']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', ['shopPlanningConfig', 'shopPlanningConfigLoading']),
    ...mapGetters('planningsState', ['isDailyView']),
    ...mapGetters('features', ['isFeatureEnabled']),
    packOffer() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    packOfferNameTranslated() {
      return this.$t(`pack_offers.${this.packOffer}`);
    },
    packOfferTooltip() {
      if (this.isFeatureEnabled(FEATURES.FEATURE_UNASSIGNED_SHIFTS, this.currentShop.id)) return '';
      return this.$t('plannings.toolbar.modal.display_options.disabled_basic', {
        pack_name_translated: this.packOfferNameTranslated,
      });
    },
  },
  methods: {
    ...mapActions('planningsState', ['updateShopPlanningConfig']),
    ...mapActions('currentUser', ['updateCurrentUser']),
    ...mapMutations('currentUser', ['squashChangesToCurrentUser']),
    handleSubmit() {
      const actionObject = {};

      this.displayActions.forEach(action => {
        actionObject[action.type] = action.toggleValue;
      });

      const shopPlanningParams = {
        shop_id: this.currentShop.id,
        shop_planning_settings: {
          ...actionObject,
          visible_days: this.visibleDays.map(visibleDay => visibleDay.selectedStatus),
        },
      };

      const currentUserParams = {
        planning_zoom: this.currentUser.attributes.planningZoom,
        // reset shift_display_template for user if custom shifts was disabled
        shift_display_template: actionObject.custom_shift ? undefined : [true, true, true],
      };

      if (this.currentUser.attributes.planningZoom !==
        this.originalCurrentUserData.attributes.planningZoom) {
        this.$skAnalytics.track('week_planning_zoom_changes');
      }

      Promise.all([
        this.updateShopPlanningConfig(shopPlanningParams),
        this.updateCurrentUser(currentUserParams),
      ]).then(() => {
        // If user disables the day he's currently on from daily view
        // he's redirected to the next visible day
        if (this.isDailyView) {
          this.emitOnRoot('redirect-to-next-visible-day');
        }

        this.emitOnRoot(MODAL_HIDE_EVENT, null, 'display-options-modal');
        this.$svcEvents.create(EVENT_SUBTYPE_ACTION.PLANNING_SETTING, undefined);
      }).catch(() => {
        this.$skToast({
          message: this.$t('plannings.toolbar.modal.display_options.action.error'),
          variant: 'error',
        });
      });
    },
    handleShow() {
      this.initVisibleDays();
      this.initDisplayActions();
    },
    handleHide() {
      // modal takes time to disappear
      setTimeout(() => {
        this.squashChangesToCurrentUser();
      }, 200);
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'display-options-modal');
    },
    handleUpdateVisibleDays(newValue) {
      this.visibleDays[newValue.index].selectedStatus = newValue.selectedStatus;
    },
    initVisibleDays() {
      if (this.shopPlanningConfig) {
        this.visibleDays =
          this.shopPlanningConfig.attributes.visibleDays.map((visibleDayStatus, index) => ({
            date: skDate().day(index + 1).format('dd'),
            selectedStatus: visibleDayStatus,
          }));
      }
    },
    initDisplayActions() {
      this.displayActions = [
        {
          title: this.$t('plannings.toolbar.modal.display_options.birthday_display.title'),
          type: 'birthday_display',
          toggleValue: this.shopPlanningConfig.attributes.birthdayDisplay,
          dataTest: 'display-options__birthdays',
        },
        {
          title: this.$t('plannings.toolbar.modal.display_options.custom_shift.title'),
          type: 'custom_shift',
          toggleValue: this.shopPlanningConfig.attributes.customShift,
          dataTest: 'display-options__custom-shifts',
        },
        {
          title: this.$t('plannings.toolbar.modal.display_options.allow_unassigned_shift.title'),
          type: 'allow_unassigned_shifts',
          toggleValue: this.shopPlanningConfig.attributes.allowUnassignedShifts,
          dataTest: 'display-options__unassigned-shifts',
          disabled: !this.isFeatureEnabled(FEATURES.FEATURE_UNASSIGNED_SHIFTS, this.currentShop.id),
        },
      ];
    },
    displayOptionsTitleClasses(displayAction) {
      return {
        'display-options-modal__content-title': true,
        'display-options--disabled': displayAction.disabled,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.sk-modal__section__bottom-border--normal.display-options-modal__day-option-selection {
  padding-bottom: 12px;
}

.sk-modal__section.display-options-modal__zoom-range-selection {
  padding: 12px 0 0;
}

.display-options-modal__content-title {
  width: 240px;
  display: flex;
  align-items: flex-start;
  font-weight: 700;
  margin-top: 10px;
}

.display-options-modal__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }
}

.display-options-modal__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.sk-modal__section.display-options-modal__content {
  padding: 7px 0 6px;
}

.display-options-modal__pack-tag {
  display: flex;
  align-items: center;
}

.display-options--disabled {
  cursor: not-allowed;
  color: $sk-grey;
}

// product need 25px between cta and modal-section
// can't trigger padding without !important
::v-deep .sk-modal__footer {
  padding-top: 9px !important;
}
</style>
