<template>
  <SkCollapse
    ref="collapse"
    :class="cardClass"
    :disabled="disabled"
  >
    <template #labelLeft>
      <div class="sk-flex">
        <div class="pay-partner-card__icon sk-flex-center">
          <component :is="iconName" />
        </div>
        <div class="pay-partner-card__title sk-flex">
          <div class="sk-heading-small-semibold">
            {{ name }}
          </div>
          <p class="sk-subtitle--large">
            {{ subTitle }}
          </p>
        </div>
        <div class="pay-partner-card__tag sk-flex-center">
          <slot name="tag" />
        </div>
      </div>
    </template>
    <template #labelRight>
      <slot name="cta" />
    </template>
    <template #content>
      <slot />
    </template>
  </SkCollapse>
</template>

<script>

import {
  Silae, Cegedim,
} from '../shared/icons';

export default {
  name: 'PayPartnerCard',
  components: { Silae, Cegedim },
  props: {
    name: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideCaret: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClass() {
      return {
        'pay-partner-card': true,
        'pay-partner-card--hide-caret': this.hideCaret,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-partner-card {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;

  &--hide-caret {
    ::v-deep .sk-collapse__caret-wrapper {
      display: none;
    }

    ::v-deep .sk-collapse__label {
      cursor: default;
    }
  }
}

.pay-partner-card__icon {
  height: 72px;
  width: 72px;
  border-radius: 20px;
  padding: 12px;
  border: 1px solid $sk-grey-10;
  margin-right: 16px;
}

.pay-partner-card__tag {
  margin-left: 8px;
}

.pay-partner-card__title {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>
