<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features';
import GenericDropdownItem from './GenericDropdownItem';

export default {
  name: 'OptimizationAction',
  components: {},
  extends: GenericDropdownItem,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('features', ['isFeatureEnabled']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.optimization.label');
    },
    icon() {
      return 'OptimizationIcon';
    },
    isOptimizationAllowed() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_OPTIMISATON, this.currentShop.id);
    },
    visibleIn() {
      return 'dropdown';
    },
    disabled() {
      return this.isDataLoading || !this.isOptimizationAllowed;
    },
    tooltip() {
      return '';
    },
    isVisible() {
      return this.currentLicense.attributes.canCreateShifts;
    },
  },
  methods: {
    handler() {
      this.emitOnRoot('open-optimisation-sidepanel');
    },
  },
};
</script>
