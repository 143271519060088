<template>
  <Toolbar class="employees__toolbar">
    <SkSearch
      ref="employeeSearchBar"
      v-model="localQuery"
      :placeholder="$t('employees.toolbar.search')"
      size="medium"
      variant="light"
      class="employees__toolbar__employee-search"
    />
    <div
      v-if="isPayslipsFeatureActive"
      class="employees__toolbar__payslips-button-wrapper"
    >
      <SkCircleButton
        v-modal.payslips-dispatch-modal
        v-tooltip="payslipsTooltip"
        v-track="'clicked_on_payslips_dispatch_btn'"
        data-test="payslipDispatch_button"
        icon="MultiplePayslipsIcon"
        :aria-label="payslipsTooltip"
      />
      <SkOroraTag
        size="small"
        color="skViolet50"
        background-color="skViolet10"
        class="employees__toolbar__payslips-button-wrapper__tag--new"
      >
        {{ $t(`employees.toolbar.payslips.new`) }}
      </SkOroraTag>
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <PayslipsDispatchModal />
      </MountingPortal>
    </div>
    <div
      v-if="activeTab === 'active' && shopHasTimeclockOrBadging"
      class="employees__toolbar__send-pins-button"
    >
      <SkPopover
        :disabled="canSendMassivePunchClockPin"
        placement="top"
        as-tooltip
      >
        <template #anchor>
          <SkCircleButton
            v-modal.invite_employees_modal
            v-tooltip="sendPunchClockPinTooltip"
            v-track="'clicked_on_punch_clock_pin_btn'"
            :disabled="!canSendMassivePunchClockPin"
            icon="PaperAirplaneV2Icon"
          />
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <InviteEmployeesModal />
          </MountingPortal>
        </template>
        <template #content>
          <div class="employees__toolbar__send-email-tooltip">
            {{ $t('employees.send_punch_clock_pin.no-employee') }}
          </div>
        </template>
      </SkPopover>
    </div>
    <div class="employees__toolbar__send-email-button">
      <SkCircleButton
        v-modal.send-email-modal
        v-tooltip="emailTooltip"
        v-track="'clicked_on_email_btn'"
        icon="EnvelopeV2Icon"
      />
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <SendEmailModal
          has-attachment-option
          has-bcc
        />
      </MountingPortal>
    </div>
    <div
      class="employees__toolbar__send-signature-button"
    >
      <SkPopover
        v-tooltip="signatureTooltip"
        placement="top-end"
        :disabled="!isEsignaturePlanActive"
      >
        <template #anchor>
          <div class="employees__toolbar__btn-wrapper">
            <SkCircleButton
              v-modal.null
              icon="SignV2Icon"
              icon-size="20"
              :disabled="isEsignatureDocumentFeatureMaintenance"
              @click="handleEsignatureIconClick"
            />
          </div>
        </template>
      </SkPopover>
    </div>
    <div
      v-if="canDownloadStaffRegister"
      class="employees__toolbar__staff-register-button"
    >
      <SkPopover
        :disabled="staffRegisterEnabled"
        placement="top-end"
        as-tooltip
      >
        <template #anchor>
          <SkCircleButton
            v-modal.staff-register-modal
            v-tooltip="staffRegisterTooltip"
            v-track="'rup_export'"
            :disabled="!staffRegisterEnabled"
            icon="DownloadV2Icon"
            @click="handleStaffRegisterClick"
          />
          <MountingPortal
            v-if="showStaffRegisterModal"
            mount-to="#modals-portal"
            append
          >
            <StaffRegisterModal
              ref="modalWrapper"
              @download="downloadStaffRegister"
            />
          </MountingPortal>
        </template>
        <template #content>
          {{ $t('employees.toolbar.register.staff_register_disabled.before_tag') }}
          {{ $t('employees.toolbar.register.staff_register_disabled.after_tag') }}
          <br>
          <br>
          {{ $t('employees.toolbar.register.staff_register_disabled.second_line') }}
        </template>
      </SkPopover>
    </div>
    <div
      v-if="canCreateEmployee"
      class="sk-divider--dark"
      style="margin: 0 30px 0 12px"
    />
    <SkOroraButton
      v-if="timeclockFeatureButNotPlanningFeature && canCreateEmployee"
      id="open-new-add-employee-modal"
      v-modal.new-add-employee-modal
      v-track="'add_employee'"
      variant="highlighted"
      icon="PlusSignV2Icon"
    >
      {{ $t('employees.new_add_employee') }}
    </SkOroraButton>
    <SkOroraButton
      v-else-if="canCreateEmployee"
      id="open-add-employee-modal"
      v-modal.add-employee-modal
      v-track="'add_employee'"
      variant="highlighted"
      icon="PlusSignV2Icon"
    >
      {{ $t('employees.add_employee.general') }}
    </SkOroraButton>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <AddEmployeeModal />
      <NewAddEmployeeModal
        v-if="timeclockFeatureButNotPlanningFeature"
        @submit="onNewEmployeeModalSubmit()"
      />
    </MountingPortal>
  </Toolbar>
</template>
<script>
import debounce from 'lodash/debounce';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import axios from 'axios';
import {
  downloadFile, sanitizeFileName,
} from '@skello-utils/file.js';
import { httpClient } from '@skello-utils/clients';
import Toolbar from '@app-js/shared/components/Toolbar';
import SendEmailModal from '@app-js/shared/components/SendEmailModal';

import {
  MODAL_SHOW_EVENT,
  SkOroraTag,
} from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';
import InviteEmployeesModal from '@app-js/badgings/shared/components/TimeclockChecklist/InviteEmployeesModal/index';
import AddEmployeeModal from './AddEmployeeModal';
import PayslipsDispatchModal from './PayslipsDispatchModal';
import NewAddEmployeeModal from './NewAddEmployeeModal';
import StaffRegisterModal from './StaffRegisterModal';

export default {
  name: 'EmployeesToolbar',
  components: {
    Toolbar,
    AddEmployeeModal,
    NewAddEmployeeModal,
    StaffRegisterModal,
    SendEmailModal,
    InviteEmployeesModal,
    PayslipsDispatchModal,
    SkOroraTag,
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('employees', ['activeTab', 'searchQuery', 'lastQueryTimeStamp', 'employees']),
    ...mapState('selectedEmployee', ['employee']),

    ...mapGetters('currentShop', ['isDevFlagEnabled', 'isEsignatureAdministrativeDocsEnabled']),
    ...mapGetters('currentLicense', ['canManageEmployeeDocuments', 'isSystemAdmin', 'canReadEmployeePersonalInfosAndBankData']),
    ...mapGetters('currentLicense', ['canCreateEmployee']),
    ...mapGetters('employees', ['displayPayrollPreparation']),
    ...mapGetters('features', ['isFeatureEnabled']),
    signatureTooltip() {
      if (!this.isEsignaturePlanActive) {
        return this.$t('employees.signature_followup.no_enabled_esignature');
      }

      if (this.isEsignatureDocumentFeatureActive) {
        if (this.isEsignatureDocumentFeatureMaintenance) {
          return this.$t('employees.signature_followup.maintenance');
        }

        return this.$t('employees.signature_followup.title');
      }
      return null;
    },
    timeclockFeatureButNotPlanningFeature() {
      return (
        this.isFeatureEnabled(FEATURES.FEATURE_BADGING, this.currentShop.id) &&
        !this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id)
      );
    },
    sendPunchClockPinTooltip() {
      return this.canSendMassivePunchClockPin ? this.$t('employees.send_punch_clock_pin.title') : null;
    },
    payslipsTooltip() {
      return this.$t('employees.toolbar.payslips.tooltip');
    },
    emailTooltip() {
      return this.$t('employees.send_email.title');
    },
    staffRegisterTooltip() {
      return this.staffRegisterEnabled ? this.$t('employees.toolbar.register.tooltip') : null;
    },
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    isEsignaturePlanActive() {
      if (this.canManageEmployeeDocuments) {
        return this.isEsignatureAdministrativeDocsEnabled({ requireAll: true });
      }
      return false;
    },
    isEsignatureDocumentFeatureMaintenance() {
      return this.isDevFlagEnabled('FEATUREDEV_DOCS_ESIGNATURE_MAINTENANCE') && this.isEsignaturePlanActive;
    },
    isEsignatureDocumentFeatureActive() {
      return this.isEsignatureAdministrativeDocsEnabled() && this.canManageEmployeeDocuments;
    },
    isPayslipsFeatureActive() {
      return this.isDevFlagEnabled('FEATUREDEV_PAYSLIPS_DISPATCH_CANARY') &&
        this.canManageEmployeeDocuments &&
        this.canReadEmployeePersonalInfosAndBankData &&
        this.isFeatureEnabled(FEATURES.FEATURE_PAYSLIPS_DISPATCH, this.currentShop.id);
    },
    canSendMassivePunchClockPin() {
      return this.employees.length > 1 &&
        this.employees.some(e => e.attributes.email);
    },
    shopHasTimeclockOrBadging() {
      return this.isFeatureEnabled(FEATURES.FEATURE_BADGING, this.currentShop.id);
    },
    canDownloadStaffRegister() {
      return this.isSystemAdmin;
    },
    staffRegisterEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_STAFF_REGISTER, this.currentShop.id);
    },
    showStaffRegisterModal() {
      return this.currentUser.attributes.exportWarningEnabled;
    },
    localQuery: {
      get() {
        return this.searchQuery;
      },
      set(query) {
        if (query) {
          const searchValue = query.trim();
          this.debouncedTrack();
          this.debouncedSearch(searchValue);
        } else {
          this.debouncedSearch('');
        }

        this.setSearchQuery(query);
      },
    },
  },
  watch: {
    currentShop() {
      this.clearSearchQuery();
    },
    employee() {
      this.clearSearchQuery();
    },
  },
  created() {
    this.debouncedSearch = debounce(this.performSearch, 700);

    this.debouncedTrack = debounce(() => {
      this.$skAnalytics.track('search_employee');
    }, 1500);
  },
  methods: {
    ...mapActions('employees', ['search', 'fetchDocumentsEsignature', 'reloadSidebar']),
    ...mapActions('selectedEmployee', ['fetchEmployee']),
    ...mapMutations('employees', ['setLastQueryTimeStamp', 'clearSearchQuery', 'setSearchQuery']),
    performSearch(query) {
      const queryTimeStamp = Date.now();
      this.setLastQueryTimeStamp(queryTimeStamp);

      this.search({
        queryTimeStamp,
        cluster_node_id: this.navContext.clusterNodeId,
        query,
        with_missing_attributes: this.displayPayrollPreparation,
      });
    },
    async onNewEmployeeModalSubmit() {
      await this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });
      this.$router.replace({ name: 'user_personal', params: { user_id: this.employees[0].id, shop_id: this.currentShop.id } });
    },
    handleStaffRegisterClick() {
      if (!this.showStaffRegisterModal) {
        this.downloadStaffRegister();
      }
    },
    async downloadStaffRegisterBlob(url) {
      // URL is an s3 bucket link:  https://s3.eu-west-1.amazonaws.com
      // We have to instantiate a clean Axios client to get rid of Authorization headers.
      // The bucket's CORS policy does not allows the Authorization header.
      const response = await axios.create().get(url, { responseType: 'blob' });
      return response.data;
    },
    async handleEsignatureIconClick() {
      if (this.isEsignaturePlanActive) {
        this.openFollowUpSideBar();
        return;
      }
      this.showDocEsignatureUpsellModal();
    },
    async openFollowUpSideBar() {
      this.$skAnalytics.track('esignature_documents_open_follow_up');
      this.emitOnRoot('open-docs-esignature');

      await this.fetchDocumentsEsignature({
        shopId: this.currentShop.id,
        userId: this.currentUser.id,
      });
    },
    showDocEsignatureUpsellModal() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'doc-esignature-upsell-modal');
    },
    async downloadStaffRegister() {
      const params = {};
      if (!this.navContext.viewAllShops) {
        params.cluster_node_id = this.navContext.clusterNodeId;
        params.shop_id = this.currentShop.id;
      }

      try {
        const response = await httpClient.get('/v3/api/staff_registers', { params });
        if (response.data.url) {
          downloadFile(response.data.url, sanitizeFileName(this.navContext.name));
        } else {
          throw new Error('No URL provided');
        }
      } catch (error) {
        this.$skToast({
          message: this.$t('employees.error_message'),
          variant: 'error',
          containerId: 'employees__container',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.employees__toolbar {
  padding: 0 10px;
  display: flex;
  align-items: center;

  .employees__toolbar__employee-search {
    width: 300px;
    margin-right: auto;
  }

  // stays aligned with rightmost employee content
  @media (max-width: $md-width) {
    max-width: 1105px;
    width: 1105px;
  }
}

.employees__toolbar__payslips-button-wrapper {
  padding-right: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &__tag--new {
    position: absolute;
    margin-bottom: -5px;
  }
}

.employees__toolbar__send-pins-button {
  padding-right: 10px;
}

.employees__toolbar__send-email-button {
  padding-right: 10px;
}

.employees__toolbar__send-signature-button {
  padding-right: 10px;
}

.employees__toolbar__send-email-tooltip {
  max-width: 340px;
  word-break: break-word;
}

.employees__toolbar__btn-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

</style>
