<template>
  <div>
    <div>
      <h3>
        {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_on_affiliated_shop.title') }}
      </h3>
      <span class="clock-in-out-settings_explanation">
        {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_on_affiliated_shop.explanation') }}
      </span>
      <div class="clock-in-out-settings__options__select">
        <SkSelectV2
          v-model="punchOnAffiliatedShop"
          :options="punchOnAffiliatedShopOptions"
          :label="$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_on_affiliated_shop.label')"
          width="320px"
        >
          <template #selected-option="{ value }">
            <span v-tooltip="showPunchOnAffiliatedShopTooltip(value)">
              {{ truncatePunchOnAffiliatedShopOption(value, 50) }}
            </span>
          </template>
          <template #option="{ option }">
            <div> {{ option.text }} </div>
          </template>
        </SkSelectV2>
      </div>
    </div>

    <div
      v-if="showPunchFrequency &&
        isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, currentShop.id)"
      class="clock-in-out-settings__punch_frequency"
    >
      <h3>
        {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.title') }}
      </h3>
      <!-- eslint-disable vue/no-v-html -->
      <span
        class="clock-in-out-settings_explanation"
        v-html="$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.explanation')"
      />
      <!-- eslint-enable vue/no-v-html -->
      <div class="clock-in-out-settings__options__select">
        <SkSelectV2
          v-model="dayClocksInOut"
          :options="dayClocksInOutOptions"
          :label="$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.label')"
          width="320px"
        >
          <template #selected-option="{ value }">
            <span v-tooltip="showDayClocksInOutTooltip(value)">
              {{ truncateDayClocksInOutOption(value, 50) }}
            </span>
          </template>
          <template #option="{ option }">
            <div v-if="option.id === false">
              {{ option.text }}
            </div>
            <div
              v-else
              class="clock-in-out-settings__select-line"
            >
              {{ option.text }}
              <i> {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.daily_punch_yes_detail') }} </i>
            </div>
          </template>
        </SkSelectV2>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import { arrayToSelectOptions } from '@skello-utils/form';
import { truncateString } from '@skello-utils/formatting/strings';
import { FEATURES } from '@app-js/shared/constants/features.js';

export default {
  name: 'ClockInOutSettings',
  props: {
    punchOnAffiliatedShopValue: {
      type: Boolean,
      required: true,
    },
    dayClocksInOutValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isHospitalitySector']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('features', ['isFeatureEnabled']),
    punchOnAffiliatedShop: {
      get() {
        return this.punchOnAffiliatedShopValue;
      },
      set(newValue) {
        this.$emit('change', { kind: 'punchOnAffiliatedShop', newValue });
      },
    },
    dayClocksInOut: {
      get() {
        return this.dayClocksInOutValue;
      },
      set(newValue) {
        this.$emit('change', { kind: 'dayClocksInOut', newValue });
      },
    },
    punchOnAffiliatedShopOptions() {
      const punchOnAffiliatedShopOptions = [
        true,
        false,
      ];
      return arrayToSelectOptions(punchOnAffiliatedShopOptions,
        value => {
          const option = value ? 'main_shop' : 'clock_in_out_shop';
          return this.$t(`shop_settings.tabs.punch_clock.tablet_parameters_card.punch_on_affiliated_shop.${option}`);
        });
    },
    dayClocksInOutOptions() {
      const dayClocksInOutOptions = [
        false,
        true,
      ];
      return arrayToSelectOptions(dayClocksInOutOptions,
        value => {
          if (value) {
            return this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.daily_punch_yes');
          }
          return this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.daily_punch_no');
        });
    },
    showPunchFrequency() {
      /* May be extended to other sectors in the future */
      return !this.isHospitalitySector;
    },
  },
  methods: {
    truncatePunchOnAffiliatedShopOption(value, length) {
      return truncateString(this.getPunchOnAffiliatedShopText(value), length);
    },
    showPunchOnAffiliatedShopTooltip(value) {
      const punchOnAffiliatedShopText = this.getPunchOnAffiliatedShopText(value);

      return punchOnAffiliatedShopText.length > 50 ? punchOnAffiliatedShopText : null;
    },
    getPunchOnAffiliatedShopText(value) {
      return this.punchOnAffiliatedShopOptions.find(
        option => option.id === value,
      ).text;
    },
    truncateDayClocksInOutOption(value, length) {
      return truncateString(this.getDayClocksInOutText(value), length);
    },
    showDayClocksInOutTooltip(value) {
      const dayClocksInOutText = this.getDayClocksInOutText(value);

      return dayClocksInOutText.length > 50 ? dayClocksInOutText : null;
    },
    getDayClocksInOutText(value) {
      if (!value) {
        return this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.daily_punch_no');
      }
      return this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.daily_punch_yes') +
        this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.punch_frequency.daily_punch_yes_detail');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-dropdown__anchor {
  width: 320px;
}

.clock-in-out-settings_explanation {
  margin-top: 16px;
}

.clock-in-out-settings__options__select {
  display: flex;
  justify-content: space-between;
  margin: 16px 0 0;
  align-items: center;
}

.clock-in-out-settings__select-line {
  line-height: 1;
}

.clock-in-out-settings__punch_frequency {
  margin-top: 32px;
}
</style>
