<template>
  <div class="step-five__content">
    <div
      v-for="(packOffer, key, index) in packOffersConfig"
      :key="index"
    >
      <BundleOfferCard
        ref="bundleCards"
        :pack-name="key"
        :title="packOffer.title"
        :description="packOffer.description"
        :benefits="packOffer.benefits"
        :submit-form="submitForm"
        :max-description-height="maxDescriptionHeight"
        :show-popular-tag="key === 'planning-timeclock'"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapState,
} from 'vuex';
import { httpClient } from '@app-js/shared/utils/clients';
import { getPackOfferNameFromPack } from '@app-js/shared/utils/self_serve/utils';
import BundleOfferCard from '../../shared/components/BundleOfferCard';

export default {
  name: 'StepFive',
  components: { BundleOfferCard },
  // eslint-disable-next-line vue/require-prop-types
  props: ['next'],
  data() {
    return {
      screenWidth: window.innerWidth,
      maxDescriptionHeight: 0,
      maxHeaderHeight: 0,
      packOffersConfig: {
        timeclock: {
          title: this.$t('self_serve.step_five.bundle_offers.timeclock.title'),
          description: this.$t('self_serve.step_five.bundle_offers.timeclock.description'),
          benefits: [
            this.$t('self_serve.step_five.bundle_offers.timeclock.benefits.first'),
            this.$t('self_serve.step_five.bundle_offers.timeclock.benefits.second'),
            this.$t('self_serve.step_five.bundle_offers.timeclock.benefits.third'),
          ],
        },
        planning: {
          title: this.$t('self_serve.step_five.bundle_offers.planning.title'),
          description: this.$t('self_serve.step_five.bundle_offers.planning.description'),
          benefits: [
            this.$t('self_serve.step_five.bundle_offers.planning.benefits.first'),
            this.$t('self_serve.step_five.bundle_offers.planning.benefits.second'),
            this.$t('self_serve.step_five.bundle_offers.planning.benefits.third'),
          ],
        },
        'planning-timeclock': {
          title: this.$t('self_serve.step_five.bundle_offers.planning-timeclock.title'),
          description: this.$t('self_serve.step_five.bundle_offers.planning-timeclock.description'),
          benefits: [
            this.$t('self_serve.step_five.bundle_offers.planning-timeclock.benefits.first'),
            this.$t('self_serve.step_five.bundle_offers.planning-timeclock.benefits.second'),
            this.$t('self_serve.step_five.bundle_offers.planning-timeclock.benefits.third'),
          ],
        },
      },
    };
  },
  computed: {
    ...mapState('onboarding', ['currentUser']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('onboarding', ['salesforceBundle']),
  },
  created() {
    this.fetchCurrentUser();
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.updateResize);
  },
  mounted() {
    this.equalizeBlocksHeights();
    window.addEventListener('resize', this.equalizeBlocksHeights);

    // Google Analytics script for Marketing team
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'self_serve_step_viewed',
      page_type: 'selfserve',
      page_category: 'selfserve',
      selfserve_version: localStorage.getItem('self-serve-version'),
      step_index: 'stepFive',
      step_name: 'stepFive',
      page_path: window.location.pathname,
      referrer: document.referrer,
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.updateResize);
    window.removeEventListener('resize', this.equalizeBlocksHeights);
  },
  methods: {
    ...mapActions('onboarding', ['updateOrganisation', 'fetchCurrentUser', 'updateProspect']),

    setLoading(loading) {
      this.isLoading = loading;
    },
    updateResize() {
      this.screenWidth = window.innerWidth;
    },
    async submitForm(packName) {
      this.isLoading = true;
      try {
        this.sendSegmentTrackers(packName);

        const updateOrganisationParams = {
          id: this.currentUser.attributes.organisationId,
          organisation: { pack_offer_name: getPackOfferNameFromPack(packName) },
        };
        updateOrganisationParams.organisation.badging = packName === 'planning-timeclock' || packName === 'timeclock';
        await this.updateOrganisation(updateOrganisationParams);

        localStorage.setItem('step_five_bundle', packName);
        const salesforceBundle = this.salesforceBundle(packName);
        const notifyParams = {
          step: 'step_five',
          bundle: salesforceBundle,
        };

        await httpClient.post('/v3/api/self_serve/notify_salesforce', notifyParams);

        const updateProspectParams = {
          prospect: {
            step: 'permissions_set',
          },
        };
        if (this.isDevFlagEnabled('FEATUREDEV_SELF_SERVE_RAUL')) {
          // We need to await this call to continue the flow as it gets the chargebee id
          // of the prospect and updates the prospect with this id in the backend
          // If the chargebee id is undefined on prospect (aka this call fails for some reason)
          // we will not be able to create the subscription on the next step and this blocks the flow
          await this.updateProspect(updateProspectParams);
        } else {
          this.updateProspect(updateProspectParams);
        }

        this.$skAnalytics.track(`ss_pack_choice_step5_${packName.replace('-', '_and_')}`);
        this.next({ packName });
      } catch (error) {
        this.$skToast({
          message: this.$t('self_serve.errors.generic'),
          variant: 'error',
        });
      }
    },
    sendSegmentTrackers(packName) {
      const stepThreeData = JSON.parse(localStorage.getItem('step_three'));
      const stepFourData = JSON.parse(localStorage.getItem('step_four'));
      const stepOneData = JSON.parse(localStorage.getItem('step_one'));

      const params = {
        first_name: this.currentUser.attributes.firstName,
        last_name: this.currentUser.attributes.lastName,
        email: this.currentUser.attributes.email,
        phone: this.currentUser.attributes.phone,
        employees: stepThreeData?.selectedEmployeesCount,
        sector: stepThreeData?.selectedSector,
        company: stepFourData?.socialDenomination,
        country: stepFourData?.country,
        country_code: stepOneData?.country_code,
        customer_id: Date.now() + Math.random(),
        source: packName,
        pack: packName,
      };

      this.$skAnalytics.track('self_serve_choose_pack_offer', params);
    },
    getBlockMaxHeight(bundleCards, blockQuerySelector) {
      let maxHeight = 0;

      // Measure the height of each wanted block inside BundleOfferCard
      bundleCards.forEach(card => {
        const block = card.$el.querySelector(blockQuerySelector);
        const height = block.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      // Returns the biggest wanted block as the max height
      return maxHeight;
    },
    equalizeBlocksHeights() {
      this.$nextTick(() => {
        const bundleCards = this.$refs.bundleCards;

        this.maxDescriptionHeight = this.getBlockMaxHeight(bundleCards, '.bundle-offer-card__description');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.step-five__content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-inline: 100vw;
  margin-inline: -100vw;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
