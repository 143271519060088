<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';
import GenericDropdownItem from './GenericDropdownItem.vue';

export default {
  name: 'SaveAsTemplateAction',
  extends: GenericDropdownItem,
  computed: {
    ...mapState('planningsTemplates', ['templates', 'templatesLoading']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('features', ['getFeatureLimit', 'isFeatureEnabled']),
    maxTemplatesPerShop() {
      return this.getFeatureLimit(
        FEATURES.FEATURE_PLANNING_TEMPLATES, this.currentShop.id);
    },
    canSaveTemplates() {
      return this.maxTemplatesPerShop === null || this.templates.length < this.maxTemplatesPerShop;
    },
    packOfferNameTranslated() {
      return this.$t(`pack_offers.${this.currentOrganisation.attributes.packOffer.name}`);
    },
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.save_as_template.label');
    },
    icon() {
      return 'FloppyDiskV2Icon';
    },
    visibleIn() {
      return 'dropdown';
    },
    disabled() {
      return this.templatesLoading || !this.canSaveTemplates;
    },
    tooltip() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.save_as_template.tooltip', {
        pack_name_translated: this.packOfferNameTranslated,
        max_planning_templates: this.maxTemplatesPerShop,
      });
    },
    isVisible() {
      return this.currentLicense.attributes.canCreateShifts &&
        this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_TEMPLATES, this.currentShop.id);
    },
    dataTest() {
      return 'planning-toolbar__save-as-template';
    },
  },
  methods: {
    handler(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-template-modal');
    },
  },
};
</script>
