<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="title"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#FFF7ED"
      border-color="#9A3412"
      data-test="column__pay-elements__closed"
    />
    <transition name="hrat-column">
      <div
        v-if="columnActive"
        class="column-wrap column-wrap--open"
        data-test="column__pay-elements__opened"
      >
        <div
          class="column__header pay-elements-title"
          data-test="column__pay-elements__close-button"
          @click="closeColumn"
        >
          {{ title }}

          <div class="column__close-btn">
            <ReportCloseIcon />
          </div>
        </div>

        <table>
          <thead class="column__title-wrap">
            <th
              v-if="visibleSalary || canEditBonus"
              class="column__title column__title--open pay-elements__column__title--min_width"
            >
              {{ $t('reports.pay_elements.primes', { currencySymbol }) }}
            </th>
            <th
              v-if="mealCompensationDone && benefitInKind && showOwedMeals"
              class="column__title column__title--open pay-elements__column__title--min_width"
            >
              {{ $t('reports.pay_elements.owed_meals') }}
            </th>
            <th
              v-if="mealCompensationDone && benefitInKind"
              class="column__title column__title--open pay-elements__column__title--min_width"
            >
              {{ $t('reports.pay_elements.meals') }}
            </th>
            <th
              v-if="mealCompensationDone && showOwedMeals"
              class="column__title column__title--open pay-elements__column__title--min_width"
            >
              {{ getMealsTitle() }}
            </th>
            <th class="column__title column__title--open pay-elements__column__title--min_width">
              {{ $t('reports.pay_elements.transport_cost', { currencySymbol }) }}
            </th>
          </thead>
          <tbody>
            <template v-for="employeeInfos in employeesInfos">
              <tr
                v-for="contractInfos in employeeInfos.contracts_infos"
                :key="contractInfos.contract.id"
                class="column__cell-wrap"
              >
                <!-- Primes/Avances -->
                <td
                  v-if="visibleSalary || canEditBonus"
                  class="column__cell pay-elements__column__cell--min_width"
                >
                  <div class="prime-amount">
                    <div
                      v-if="canEditBonus"
                      class="report-prime__button"
                    >
                      <div
                        @click="
                          displayPrimeModal(employeeInfos.user_infos.id, contractInfos.contract.id)
                        "
                      >
                        <CircledPlusSignIcon
                          fill="#2b66fe"
                          :data-test="`primes-button__contract-${contractInfos.contract.id}`"
                        />
                      </div>
                    </div>
                    <span>
                      {{ contractInfos.primes }}
                    </span>
                  </div>
                </td>

                <!-- Repas dûs -->
                <td
                  v-if="mealCompensationDone && benefitInKind && showOwedMeals"
                  class="column__cell column__cell--open pay-elements__column__cell--min_width"
                >
                  {{ contractInfos.owed_meals }}
                </td>

                <!-- Repas Pris -->
                <td
                  v-if="mealCompensationDone && benefitInKind"
                  class="column__cell column__cell--open pay-elements__column__cell--min_width"
                >
                  {{ contractInfos.taken_meals }}
                </td>

                <!-- Indemnités Compensatrices -->
                <td
                  v-if="mealCompensationDone && showOwedMeals"
                  class="column__cell column__cell--open pay-elements__column__cell--min_width"
                >
                  {{ contractInfos.meal_allowance }}
                </td>

                <!-- Coût de transport -->
                <td class="column__cell column__cell--open pay-elements__column__cell--min_width">
                  {{ contractInfos.transport_cost }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'PayElementsColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    visibleSalary: {
      type: Boolean,
      default: false,
    },
    benefitInKind: {
      type: Boolean,
      default: false,
    },
    mealCompensationDone: {
      type: Boolean,
      default: false,
    },
    mealsTitle: {
      type: String,
      default: '',
    },
    canEditBonus: {
      type: Boolean,
      default: false,
    },
    currencySymbol: {
      type: String,
      default: '€',
    },
    showOwedMeals: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: this.$t('reports.pay_elements.title'),
    };
  },
  methods: {
    getMealsTitle() {
      if (this.benefitInKind) return this.$t('reports.pay_elements.meals_title');
      return this.mealsTitle;
    },
    displayPrimeModal(employeeId, contractId) {
      this.$emit('display-primes-modal', { employeeId, contractId });
    },
  },
};
</script>

<style lang="scss" scoped>
.column__title--open {
  padding: 25px 5px !important;
}

.pay-elements-title {
  min-width: 200px;
  border-top: 4px solid #9a3412;
}

.pay-elements__column__title--min_width,
.pay-elements__column__cell--min_width {
  min-width: 200px;
}

.report-prime__button {
  position: relative;
  top: 2px;
  padding: 8px;
  cursor: pointer;
}

.prime-amount {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
</style>
