<template>
  <SkModal
    id="esignature-upsell-modal"
    ref="esignatureUpsellModal"
    size="ds-medium"
    :display-footer="false"
    @cancel="closeModal"
    @close="closeModal"
  >
    <template #body>
      <SkModalSection
        border-bottom="none"
        class="esignature-upsell__section"
      >
        <component
          :is="step"
          :initial-step="initialStep"
          @update-step="updateStep"
          @go-back="$emit('go-back')"
        />
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

import IntroStep from './IntroStep';
import Signature from './assets/Signature';

export default {
  name: 'EsignatureUpsellModal',
  components: {
    IntroStep,
    Signature,
  },
  props: {
    initialStep: {
      type: String,
      default: 'IntroStep',
    },
  },
  data() {
    return {
      step: this.initialStep,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
  },
  methods: {
    closeModal() {
      this.$skAnalytics.track('esignature_upsell_close');
      this.$emit('close');
      setTimeout(() => { this.step = this.initialStep; }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
// Upsell modal style override
#esignature-upsell-modal {
  ::v-deep .sk-modal__dialog {
    position: relative; // To position the signature images
  }

  ::v-deep .sk-modal__header {
    border-bottom: 0;
    padding: 0 24px;
  }

  ::v-deep .sk-modal__body-wrapper {
    margin-top: -24px;
  }

  ::v-deep .sk-modal__body {
    padding: 0 24px 24px;
  }

  ::v-deep .sk-modal__section {
    padding: 0;
    margin: 0;
  }

  ::v-deep footer.sk-modal__footer {
    padding: 30px 24px 24px;
  }

.esignature-upsell__section {
    padding-top: 0;
  }
}

.esignature-upsell__background-signature__wrapper--top,
.esignature-upsell__background-signature__wrapper--bottom {
  position: absolute;
  height: 173px;
  width: 170px;
  overflow: hidden;
}

.esignature-upsell__background-signature__wrapper--top {
  top: 9px;
  left: 0;
}

.esignature-upsell__background-signature__wrapper--bottom {
  bottom: 1px;
  right: 0;
}

.esignature-upsell__background-signature--top,
.esignature-upsell__background-signature--bottom {
  position: absolute;
}

.esignature-upsell__background-signature--top {
  top: -24px;
  left: -205px;
}

.esignature-upsell__background-signature--bottom {
  transform: rotate(127deg);
  left: -87px;
  bottom: -210px;
}
</style>
