<template>
  <div
    ref="tableWrapper"
    class="table-wrapper"
  >
    <table
      class="monthly-table"
      :class="hoveredWeek && `week-${hoveredWeek}`"
    >
      <thead>
        <Header
          :header-component-store-props="headerComponentStoreProps"
          @load-week="fetchWeekDaysWorked($event)"
          @weekly-counter-hovered="week => { hoveredWeek = week }"
        />
      </thead>
      <tbody>
        <UserRow
          v-if="displayUnassignedShiftsRow"
          is-unassigned-shifts-row
          :shifts="unassignedShifts['null']"
          :user-row-component-store-props="userRowComponentStoreProps({ id: null })"
          :user="{ id: null }"
          :days-worked-by-week="daysWorkedByWeek"
        />
        <UserRow
          v-for="(user, index) of displayedInPlanningUsers"
          :ref="`userRow${user.id}`"
          :key="user.id"
          :class="index === displayedInPlanningUsers.length - 1 && 'last-row'"
          :row-index="index"
          :user="user"
          :shifts="shiftsByUser[parseInt(user.id, 10)]"
          :availabilities="availabilitiesForUser(user.id)"
          :days-worked-by-week="daysWorkedByWeek"
          :feature-state-component-props="featureStateComponentProps"
          :user-row-component-store-props="userRowComponentStoreProps(user)"
          @load-week="fetchWeekDaysWorked($event)"
          @weekly-counter-hovered="week => { hoveredWeek = week }"
        />

        <tr
          v-show="arePlanningDataBatchesLoading"
          class="loading-row"
        />

        <tr
          ref="emptyRow"
          :style="{
            height: `max(${remainingHeight - 49}px, 49px)`,
          }"
        />

        <DailyCountersRow
          :weeks="monthlyVisibleWeeks"
          :daily-counters-row-component-store-props="dailyCountersRowComponentStoreProps"
        />

        <tr v-if="arePlanningDataBatchesLoading || kpisLoading">
          <td class="kpi-loader">
            <SkLoader />
          </td>
        </tr>
      </tbody>
    </table>

    <SkLoader
      v-show="arePlanningDataBatchesLoading"
      class="loading-spinner"
      size="medium"
    />
  </div>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';
import SimpleBar from 'simplebar';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { getOpeningTimesByShopAndDate } from '@app-js/plannings/shared/utils/planning_helpers';
import { FEATURES } from '@app-js/shared/constants/features';
import UserRow from './Row/UserRow.vue';
import Header from './Header/index.vue';
import DailyCountersRow from './Row/DailyCountersRow.vue';

import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'MonthlyTable',
  components: {
    UserRow,
    Header,
    DailyCountersRow,
  },
  props: {
    currentScrollOffset: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hoveredWeek: null,
      scrollElement: null,
      remainingHeight: 0,
      daysWorkedByWeek: {},
    };
  },
  computed: {
    ...mapState('planningsPostes', ['absences']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsShifts', ['pendingLeaveRequestShifts']),
    ...mapState('planningsKpis', ['kpisLoading', 'kpis']),
    ...mapState('planningsLoading', ['arePlanningDataBatchesLoading']),
    ...mapState('planningsState', ['selectedWeeklyCountersInMonthlyView', 'selectedDate', 'shopPlanningConfig']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('monthlyPlanning', ['selectedCounter', 'bulkPaidLeaveCounters', 'draggedShift', 'usingDraggedShiftId']),
    ...mapState('planningsUsers', ['daysWorked']),
    ...mapState('config', ['config']),
    ...mapState('annualization', ['areEmployeeAnnualizationConfigsLoading']),
    ...mapState('shopTeams', ['teamSchedules']),

    ...mapGetters('planningsState', [
      'firstMonthDay',
      'lastMonthDay',
      'weeksToFetch',
      'isShiftInFilters',
      'monday',
      'sunday',
      'isDailyView',
      'currentDate',
      'monthlyVisibleWeeks',
    ]),
    ...mapGetters('planningsUsers', ['displayedInPlanningUsers', 'availabilitiesForUser', 'filteredUsers']),
    ...mapGetters('planningsShifts', ['monthlyShifts', 'unassignedMonthlyShifts']),
    ...mapGetters('currentShop', [
      'isDevFlagEnabled',
      'checkFeatureFlag',
      'isShopOnPaidVacationCalculationTypeOpeningDay',
      'isShopOnPaidVacationCalculationTypeCalendarDay',
      'isAnnualizedWorkingTimeAvailable',
    ]),
    ...mapGetters('employees', ['userInitials', 'getAvatarUrlForUser']),
    ...mapGetters('annualization', [
      'employeeAnnualizationConfigs',
      'isAnnualizationCurrentlyActive',
      'periodTheoreticalBalanceAt',
    ]),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('features', ['isFeatureEnabled']),

    shiftsByUser() {
      let shiftsByUser = this.monthlyShifts.reduce(
        (map, shift) => this.addShiftToUserShifts(map, shift), {});

      if (!this.currentLicense.attributes.canManageEmployeeRequests) {
        return shiftsByUser;
      }
      this.pendingLeaveRequestShifts.forEach(leaveRequest => {
        const {
          userId, posteId, startDate, endDate, absenceCalculation, shopId,
        } = leaveRequest.attributes;
        const relatedAbsence = this.absences.find(absence => parseInt(absence.id, 10) === posteId);
        const currentDate = skDate.utc(startDate).clone();

        const craftedShift = {
          attributes: {
            userId,
            shopId,
            posteId,
            absenceCalculation,
            isPendingLeaveRequest: true,
            durationInSeconds: 24 * 3600, // A leave request will be displayed as a full day
            shopOpeningTime: this.currentShop.attributes.openingTime,
            shopClosingTime: this.currentShop.attributes.closingTime,
            note: null,
            tasks: [],
            comments: [],
          },
          relationships: {
            poste: relatedAbsence,
          },
        };
        while (currentDate.isSameOrBefore(skDate.utc(endDate))) {
          const craftedLeaveRequestShift = {
            ...craftedShift,
            id: `tmp${leaveRequest.id}`,
            attributes: {
              ...craftedShift.attributes,
              endsAt: endDate,
              startsAt: startDate,
              startsAtForDisplay: currentDate,
            },
          };
          shiftsByUser = this.addShiftToUserShifts(shiftsByUser, craftedLeaveRequestShift);

          currentDate.add(1, 'day');
        }
      });

      return shiftsByUser;
    },
    unassignedShifts() {
      return this.monthlyShifts.filter(shift => !shift.attributes.userId).reduce(
        (map, shift) => this.addShiftToUserShifts(map, shift), {},
      );
    },
    isMonthlySkeletonEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_MONTHLY_SKELETON');
    },
    isLastWeeklyCounterColumnSelected() {
      const lastMonday = skDate(this.lastMonthDay).startOf('isoWeek').format('YYYY-MM-DD');
      return this.selectedWeeklyCountersInMonthlyView[lastMonday];
    },
    dailyCountersRowComponentStoreProps() {
      const {
        weeksToFetch,
        currentShop,
        arePlanningDataBatchesLoading,
        kpisLoading,
        kpis,
      } = this;

      return {
        weeksToFetch,
        currentShop,
        arePlanningDataBatchesLoading,
        kpisLoading,
        kpis,
      };
    },
    headerComponentStoreProps() {
      const {
        selectedCounter,
        currentShop,
        checkFeatureFlag,
        filteredUsers,
        selectedWeeklyCountersInMonthlyView,
        hasYearlyDistinction,
        isPtoCounterEnabled,
      } = this;

      return {
        selectedCounter,
        currentShop,
        checkFeatureFlag,
        filteredUsers,
        selectedWeeklyCountersInMonthlyView,
        hasYearlyDistinction,
        isPtoCounterEnabled,
      };
    },
    displayUnassignedShiftsRow() {
      return (this.shopPlanningConfig.attributes.allowUnassignedShifts ||
          this.unassignedMonthlyShifts.length > 0) &&
        this.isFeatureEnabled(FEATURES.FEATURE_UNASSIGNED_SHIFTS, this.currentShop.id);
    },
    featureStateComponentProps() {
      const {
        canDisplayTasks,
      } = this;

      return {
        canDisplayTasks,
      };
    },
    canDisplayTasks() {
      return this.isFeatureEnabled(FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id);
    },
    hasYearlyDistinction() {
      return this.checkFeatureFlag('FEATURE_N1_N_PTO_TRACKER') &&
        this.isFeatureEnabled(FEATURES.FEATURE_PTO_COUNTER, this.currentShop.id);
    },
    isPtoCounterEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PTO_COUNTER, this.currentShop.id);
    },
  },
  watch: {
    async displayedInPlanningUsers() {
      await this.$nextTick();
      this.updateVisibleWidth();
    },
    async isLastWeeklyCounterColumnSelected(newValue, oldValue) {
      if (!oldValue && newValue) {
        await this.$nextTick();
        this.programmaticScroll(1);
      }
    },
    selectedWeeklyCountersInMonthlyView: {
      deep: true,
      async handler() {
        await this.$nextTick();
        this.updateVisibleWidth();
      },
    },
  },
  mounted() {
    this.daysWorkedByWeek = Object.keys(this.monthlyVisibleWeeks).reduce((acc, weekFrom) => {
      acc[weekFrom] = {};
      return acc;
    }, {});

    this.listenOnRoot('planning-horizontal-scroll', this.programmaticScroll);
    window.addEventListener('resize', this.updateVisibleWidth);

    this.initializeScrollBar();

    if (this.$route.query.target_start_date) {
      const startDate = this.$route.query.target_start_date;
      const endDate = this.$route.query.target_start_date;

      this.$nextTick(this.scrollTo({ startDate, endDate }));
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateVisibleWidth);
    this.scrollElement.removeEventListener('scroll', this.updateVisibleWidth);
  },
  methods: {
    isMulticontractsEnabled(user) {
      // this handle unassigned shifts where we fallback on current shop
      if (user.id === null) return false;

      return this.isFeatureEnabled(FEATURES.FEATURE_MULTI_CONTRACTS, user.attributes.shopId);
    },
    userRowComponentStoreProps(user) {
      const {
        selectedCounter,
        bulkPaidLeaveCounters,
        daysWorked,
        currentLicense,
        selectedWeeklyCountersInMonthlyView,
        selectedDate,
        currentShop,
        pendingLeaveRequestShifts,
        userInitials,
        checkFeatureFlag,
        firstMonthDay,
        lastMonthDay,
        isShiftInFilters,
        filteredUsers,
        config,
        absences,
        usingDraggedShiftId,
        draggedShift,
        monday,
        sunday,
        isShopOnPaidVacationCalculationTypeOpeningDay,
        isShopOnPaidVacationCalculationTypeCalendarDay,
        employeeAnnualizationConfigs,
        isAnnualizationCurrentlyActive,
        periodTheoreticalBalanceAt,
        isAnnualizedWorkingTimeAvailable,
        planningZoom,
        getAvatarUrlForUser,
        isDailyView,
        currentDate,
        areEmployeeAnnualizationConfigsLoading,
        shopPlanningConfig,
        teamSchedules,
        unassignedMonthlyShifts,
        hasYearlyDistinction,
      } = this;

      return {
        selectedCounter,
        bulkPaidLeaveCounters,
        daysWorked,
        currentLicense,
        selectedWeeklyCountersInMonthlyView,
        selectedDate,
        currentShop,
        pendingLeaveRequestShifts,
        userInitials,
        checkFeatureFlag,
        isMulticontractsEnabled: this.isMulticontractsEnabled(user),
        firstMonthDay,
        lastMonthDay,
        isShiftInFilters,
        filteredUsers,
        config,
        absences,
        usingDraggedShiftId,
        draggedShift,
        monday,
        sunday,
        isShopOnPaidVacationCalculationTypeOpeningDay,
        isShopOnPaidVacationCalculationTypeCalendarDay,
        employeeAnnualizationConfigs,
        isAnnualizationCurrentlyActive,
        periodTheoreticalBalanceAt,
        isAnnualizedWorkingTimeAvailable,
        planningZoom,
        getAvatarUrlForUser,
        isDailyView,
        currentDate,
        areEmployeeAnnualizationConfigsLoading,
        shopPlanningConfig,
        teamSchedules,
        unassignedMonthlyShifts,
        hasYearlyDistinction,
      };
    },
    addShiftToUserShifts(monthlyShiftsMap, shift) {
      const { openingTime } = getOpeningTimesByShopAndDate(
        shift,
        shift.attributes.startsAtForDisplay,
      );

      const userId = shift.attributes.userId;
      // We want to show the shift in the previous day if the shift starts before the opening time
      let day = skDate(shift.attributes.startsAtForDisplay).utc();
      let week = skDate(shift.attributes.startsAtForDisplay).utc().startOf('isoWeek');
      if (!this.isPendingLeaveRequest(shift)) {
        if (day.isBefore(openingTime)) day = day.subtract(1, 'day');
        if (day.isBefore(week)) week = week.subtract(1, 'week');
      }
      day = day.format('YYYY-MM-DD');
      week = week.format('YYYY-MM-DD');

      monthlyShiftsMap[userId] ??= {};
      monthlyShiftsMap[userId][week] ??= {};
      monthlyShiftsMap[userId][week][day] ??= [];

      monthlyShiftsMap[userId][week][day].push(shift);

      return monthlyShiftsMap;
    },
    initializeScrollBar() {
      const simpleBar = new SimpleBar(this.$refs.tableWrapper);
      this.scrollElement = simpleBar.getScrollElement();
      this.scrollElement.addEventListener('scroll', this.updateVisibleWidth);

      if (this.isMonthlySkeletonEnabled) {
        this.programmaticScroll(this.currentScrollOffset);
        this.updateTableHeight();
      } else {
        this.updateVisibleWidth();
      }
    },
    programmaticScroll(scrollOffset) {
      const { scrollWidth, clientWidth } = this.scrollElement;
      this.scrollElement.scrollLeft = scrollOffset * (scrollWidth - clientWidth);
    },
    scrollTo({ startDate, endDate }) {
      if (this.displayedInPlanningUsers.length === 0) {
        return;
      }

      const userId = this.displayedInPlanningUsers[0].id;
      const userRow = this.$refs[`userRow${userId}`][0];

      const sidebarCell = userRow.$refs[`sidebarCell${userId}`];
      const startDayCell = userRow.$refs[`user${userId}DayCell${startDate}`][0];

      const { width: sidebarWidth } = sidebarCell.$el.getBoundingClientRect();
      const { left: startDayLeft } = startDayCell.$el.getBoundingClientRect();

      const startDayOffset = startDayLeft - sidebarWidth;

      const lastDisplayedDay = skDate(Object.values(this.monthlyVisibleWeeks).at(-1).to);

      if (skDate(endDate).isAfter(lastDisplayedDay)) {
        this.scrollElement.scrollTo({ left: startDayOffset });

        return;
      }

      const countersCell = userRow.$refs[`countersCell${userId}`];
      const endDayCell = startDate === endDate ? startDayCell : userRow.$refs[`user${userId}DayCell${endDate}`][0];

      const { clientWidth } = this.scrollElement;
      const { width: countersWidth } = countersCell.$el.getBoundingClientRect();
      const { left: endDayLeft, width: endDayWidth } = endDayCell.$el.getBoundingClientRect();

      // endDayLeft - startDayLeft cancels itself out when start date === end date
      const daysWidth = endDayLeft - startDayLeft + endDayWidth;
      const tableWidth = clientWidth - sidebarWidth - countersWidth;

      const middleOffset = startDayLeft - (clientWidth - daysWidth) / 2;

      // snap to start date when period is wider than table width otherwise scroll to the middle of it
      const leftOffset = daysWidth > tableWidth ? startDayOffset : middleOffset;

      this.scrollElement.scrollTo({ left: leftOffset });
    },
    updateVisibleWidth() {
      this.updateTableHeight();

      const { clientWidth, scrollWidth, scrollLeft } = this.scrollElement;

      const hiddenWidth = scrollWidth - clientWidth;
      const scrollOffset = hiddenWidth === 0 ? 0 : scrollLeft / hiddenWidth;

      this.$emit('update-visible-width', {
        visibleWidth: clientWidth / scrollWidth,
        scrollOffset,
      });
      this.emitOnRoot('planning-horizontal-scroll', scrollOffset);
    },
    updateTableHeight() {
      const rect = this.$refs.emptyRow.getBoundingClientRect();
      this.remainingHeight = window.innerHeight - rect.top;
    },
    isPendingLeaveRequest(shift) {
      return shift.attributes.isPendingLeaveRequest === true;
    },
    async fetchWeekDaysWorked(weekStart) {
      this.daysWorkedByWeek[weekStart] = {
        isLoading: true,
      };

      const url = '/v3/api/plannings/day_rate_total';
      const params = {
        shop_id: this.currentShop.id,
        starts_at: weekStart,
        ends_at: skDate(weekStart).startOf('isoWeek').format('YYYY-MM-DD'),
        is_monthly_fetch: false,
      };

      const response = await httpClient.get(url, { params });

      this.daysWorkedByWeek[weekStart] = {
        ...response.data,
        isLoading: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  scrollbar-width: none; // hide horizontal scrolling bar

  &::-webkit-scrollbar { // hide horizontal scrolling bar ( cross-broswer )
    display: none;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: $fs-text-xs;
  width: 0;
  table-layout: fixed;
}

::v-deep {
  // Hide SimpleBar's horizontal scrollbar
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
     visibility: hidden;
  }
}

::v-deep {
  tbody {
    tr {
      td:nth-child(2) {
        border-left: none;
      }

      &:first-child {
        td {
          height: 48px;
          border-top: 1px;
        }
      }

      &:not(:first-child):not(.last-row) {
        td {
          height: 49px;
        }
      }

      &:not(:last-child) {
        background-color: $sk-white;
      }

      &.loading-row {
        height: 32px;
      }
    }
  }
}

.loading-spinner {
  position: fixed;
  bottom: 80px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -10;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 3s forwards; /* 1s delay before showing */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.kpi-loader {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 158px;
  width: calc(100% - 278px);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep {
  .last-row > td {
    height: 50px;

    &:not(:first-child, :last-child, .weekly-counter, .empty-col) {
      border-bottom: 1px solid $sk-grey-10;
    }
  }
}
</style>

<style lang="scss">
table {
  @for $i from 1 through 6 {
      &.week-#{$i} {
        .week-#{$i}.weekly-counter {
          background-color: $sk-grey-5 !important;
        }
      }
    }
}
</style>
