<template>
  <SkModal
    id="web-redirection-modal"
    :style="mobileTablet()"
    :display-footer="false"
    :dismissable="false"
  >
    <template #body>
      <div class="web-redirection-modal__body">
        <Computer
          :width="iconWidth"
          :height="iconHeight"
        />
        <div class="web-redirection-modal__body-title">
          {{ $t('organisation_creation.web_redirection_modal.title') }}
        </div>
        <div class="web-redirection-modal__body-subtitle">
          {{ $t('organisation_creation.web_redirection_modal.subtitle_start') }}
          {{ $t(`organisation_creation.web_redirection_modal.device_${device}`) }}
          <div class="web-redirection-modal__body-subtitle_second">
            {{ $t('organisation_creation.web_redirection_modal.subtitle_end') }}
          </div>
        </div>
        <SkOroraButton
          class="web-redirection-modal__body-button"
          :loading="isLoading"
          @click="closeModal"
        >
          {{ $t('organisation_creation.web_redirection_modal.continue') }}
        </SkOroraButton>
      </div>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal,
  SkOroraButton,
} from '@skelloapp/skello-ui';

import Computer from '../../shared/assets/svg/Computer';

export default {
  name: 'WebRedirectionModal',
  components: { SkModal, SkOroraButton, Computer },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isMobile() {
      return /Android|iPhone|iPod/i.test(navigator.userAgent);
    },
    isMobileOrVerticalTablet() {
      return this.isMobile || window.innerWidth < 800;
    },
    iconWidth() {
      return this.isMobile ? '140' : '190';
    },
    iconHeight() {
      return this.isMobile ? '180' : '250';
    },
    device() {
      return this.isMobile ? 'mobile' : 'tablet';
    },
  },
  methods: {
    closeModal() {
      this.isLoading = true;
      this.$emit('close-modal', event);
    },
    mobileTablet() {
      return {
        '--text-alignment': this.isMobile ? 'left' : 'center',
        '--margin': this.isMobile ? '60px' : '80px',
        '--button-margin': this.isMobile ? '62px' : '60px',
        '--horizontal-spacing': this.isMobile ? '27px' : '85px',
        '--vertical-spacing': this.isMobile ? '27px' : '49px',
        '--margin-subtitle': this.isMobileOrVerticalTablet ? '30px' : '0',
      };
    },
  },
};

</script>

<style lang="scss" scoped>
/* no need of the header on this modal */
::v-deep .sk-modal__header {
  display: none !important;
}

/* need different sizes than the default */
::v-deep .sk-modal {
  max-width: calc(100% - var(--horizontal-spacing)) !important;
  height: calc(100% - var(--vertical-spacing)) !important;
  justify-content: center;
}

/* need different sizes than the default */
::v-deep .sk-modal__dialog {
  width: 100% !important;
  margin: 0 !important;
}

.web-redirection-modal__body {
  text-align: var(--text-alignment);
  margin: var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    font-weight: $fw-semi-bold;
    font-size: $fs-heading-s;
    margin-top: 40px;
  }

  &-subtitle {
    font-size: $fs-heading-xs;
    margin-top: 30px;
    color: $sk-grey;
    font-weight: 400;

    &_second {
      margin-top: var(--margin-subtitle);
    }
  }

  &-button {
    margin-top: var(--button-margin);
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 386px;
  }
}

</style>
