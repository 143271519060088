<template>
  <SkModalV2
    id="doc-esignature-upsell-modal"
    :display-footer="false"
    @close="handleClose"
    @cancel="$emit('cancel')"
  >
    <template #body>
      <main class="doc-esignature-upsell-modal-main">
        <div class="doc-esignature-upsell-modal-background-image">
          <Signature />
        </div>
        <div class="doc-esignature-upsell-modal-title">
          {{ $t('upsell_docs_esignature.title.part_1') }}
          <em>{{ $t('upsell_docs_esignature.title.part_2') }}</em>
        </div>
        <div class="doc-esignature-upsell-modal-subtitle">
          {{ $t('upsell_docs_esignature.subtitle.part_1') }}
          <a
            target="_blank"
            :href="customerDocumentationLink"
          >
            {{ $t('upsell_docs_esignature.subtitle.part_2') }}
          </a>
          {{ $t('upsell_docs_esignature.subtitle.part_3') }}
        </div>
        <div class="doc-esignature-upsell-modal-offerings">
          <div class="doc-esignature-upsell-modal-offering">
            <img src="../assets/img/upsell_docs_esignature_1.svg">
            <div class="doc-esignature-upsell-modal-offering-legend">
              {{ $t('upsell_docs_esignature.benefits.paragraph_1') }}
            </div>
          </div>
          <div class="doc-esignature-upsell-modal-offering">
            <img src="../assets/img/upsell_docs_esignature_2.svg">
            <div class="doc-esignature-upsell-modal-offering-legend">
              {{ $t('upsell_docs_esignature.benefits.paragraph_2') }}
            </div>
          </div>
          <div class="doc-esignature-upsell-modal-offering">
            <img src="../assets/img/upsell_docs_esignature_3.svg">
            <div class="doc-esignature-upsell-modal-offering-legend">
              {{ $t('upsell_docs_esignature.benefits.paragraph_3') }}
            </div>
          </div>
        </div>
      </main>
      <footer class="doc-esignature-upsell-modal-footer">
        <main class="doc-esignature-upsell-modal-footer-main">
          <SkOroraButton
            v-if="canShowDemoLink"
            variant="tertiary"
            :disabled="waitingForServer"
            @click="askForDemo"
          >
            {{ $t('upsell_docs_esignature.ask_for_demo.action') }}
          </SkOroraButton>
        </main>
      </footer>
    </template>
  </SkModalV2>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import { mapState } from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import Signature from '../../../app/plannings/shared/components/Toolbar/EsignatureUpsellModal/assets/Signature';

export default {
  name: 'DocEsignatureUpsellModal',
  components: {
    Signature,
  },
  data() {
    return {
      waitingForServer: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    canShowDemoLink() {
      return this.currentLicenseIs('system_admin') && this.currentShopCountryIs('FR');
    },
    customerDocumentationLink() {
      return this.$t('upsell_docs_esignature.documentation');
    },
  },
  methods: {
    currentLicenseIs(license) {
      return this.currentLicense?.attributes?.originalType === license;
    },
    currentShopCountryIs(country) {
      return this.currentShop?.attributes?.country === country;
    },
    handleClose() {
      this.$skAnalytics.track('esignature_documents_upsell_modal_close');
      this.$emit('close');
    },
    askForDemo() {
      if (this.waitingForServer) return;
      this.$skAnalytics.track('esignature_documents_upsell_modal_demo_request');

      const params = {
        user: {
          current_shop_id: this.currentShop.id,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('upsell_docs_esignature.ask_for_demo.action'),
          upsell_type: 'esignature_documents',
          current_page: 'Documents',
        },
      };
      this.waitingForServer = true;
      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.$skToast({
            message: this.$t('upsell_docs_esignature.ask_for_demo.success'),
            variant: 'success',
          });
          this.emitOnRoot(MODAL_HIDE_EVENT, event, 'doc-esignature-upsell-modal');
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('upsell_docs_esignature.ask_for_demo.error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.waitingForServer = false;
        });
    },
  },
};
</script>

<style lang="scss">
.doc-esignature-upsell-modal-header {
  margin: 58px 0;
}

.doc-esignature-upsell-modal-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.doc-esignature-upsell-modal-footer {
  margin: 54px 0 24px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

#doc-esignature-upsell-modal .sk-modal__header__wrapper {
  height: 58px;
  border: 0;
}

#doc-esignature-upsell-modal .sk-modal {
  position: relative;
  overflow: hidden;
}

.doc-esignature-upsell-modal-background-image {
  position: absolute;
  left: -207px;
  top: 5px;
  overflow-x: hidden;
}

.doc-esignature-upsell-modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: $fs-heading-m;
  line-height: 36px;
  text-align: center;
  color: $sk-black;
  align-self: stretch;
  flex-grow: 0;
  padding: 0 24px;
  margin: 24px 0 0;

  em {
    color: $sk-blue;
    font-style: normal;
  }
}

.doc-esignature-upsell-modal-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: $fs-text-l;
  line-height: 21px;
  color: $sk-grey;
  padding: 0 24px;
  text-align: center;
}

.doc-esignature-upsell-modal-offerings {
  display: flex;
  gap: 8px;
  padding: 0 43px;
}

.doc-esignature-upsell-modal-offering {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.doc-esignature-upsell-modal-offering-legend {
  font-style: normal;
  font-weight: 400;
  font-size: $fs-text-s;
  line-height: 16px;
  text-align: center;
  color: $sk-grey;
}

.doc-esignature-upsell-modal-footer-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .sk-button + .sk-button {
    margin-left: 0px !important;
  }
}
</style>
