<!--
   This component is kept for the moment as a common wrapper
   We will see with time and development if it is really needed and relevent
-->
<template>
  <div
    ref="planningScrollableContent"
    :class="planningTableWrapperClasses"
    @dragover="handleDragOver"
    @drop="stopScroll"
    @dragend="stopScroll"
  >
    <!-- planning-table__rows: mandatory div to make simple bar reactive -->
    <div class="planning-table__rows">
      <PlanningRow
        v-if="displayUnassignedShiftsRow"
        :shifts="unassignedShifts"
        :row-item="{ id: null }"
        :row-order="0"
        :visible-days="visibleDays"
        :global-config="globalConfig({ id: null })"
        is-unassigned-shifts-row
        :row-selected-days="selectedCellsPerRow['0']"
        :multiple-selection-enabled="multipleSelectionEnabled"
      />
      <PlanningRow
        v-for="(rowItem, rowItemIndex) in rowItems"
        :key="rowItem.id"
        :class="planningRowClasses(rowItemIndex, rowItems.length)"
        :row-item="rowItem"
        :row-order="rowItemIndex + 1"
        :shifts="rowShifts(rowItem.id)"
        :period-shifts="rowPeriodShifts(rowItem.id)"
        :visible-days="visibleDays"
        :global-config="globalConfig(rowItem)"
        :availabilities="canSeeAvailabilities ? availabilitiesForUser(rowItem.id) : []"
        :row-selected-days="selectedCellsPerRow[isUnassignEnabledOnShop
          ? `${rowItemIndex + 1}`
          : `${rowItemIndex}`]"
        :multiple-selection-enabled="multipleSelectionEnabled"
      />
      <AddAbsenceRow
        v-if="isPostesView"
        :visible-days="visibleDays"
      />
      <div
        v-if="isFetchingBatches && isProgressiveLoadingEnabled"
        class="loading-planning-row__wrapper"
      >
        <div class="loading-planning-row__sidebar-cell__wrapper cell--shadow cell--shadow-right" />
        <div class="loading-planning-row__main">
          <SkLoader size="medium" />
        </div>
        <div class="loading-planning-row__counters-cell__main cell--shadow cell--shadow-left" />
      </div>
      <div
        v-if="isDailyView && !isWorkloadPlanDisplayed"
        :style="{
          height: '42px',
        }"
      />
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ManageShiftModal :can-see-availability-tooltip="canSeeAvailabilities" />
      <ManageLeaveRequestModal :source="{ page: 'plannings', sub: 'week' }" />
      <TransferLeaveRequestModal />
      <ReadOnlyShiftModal />
      <BlockingAlertModal />
      <BlockingAlertForWeekLockModal />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { capitalize } from '@skello-utils/formatting/strings';

import PlanningRow from '@app-js/plannings/shared/PlanningTable/PlanningRow';
import AddAbsenceRow from '@app-js/plannings/shared/PlanningTable/AddAbsenceRow';
import ManageShiftModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/ManageShiftModal';
import ManageLeaveRequestModal from '@app-js/shared/components/ManageLeaveRequestModal';
import TransferLeaveRequestModal from '@app-js/shared/components/TransferLeaveRequestModal';
import ReadOnlyShiftModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/ReadOnlyShiftModal';
import BlockingAlertModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/BlockingAlertModal';
import BlockingAlertForWeekLockModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/BlockingAlertForWeekLockModal';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'PlanningTable',
  components: {
    PlanningRow,
    AddAbsenceRow,
    ReadOnlyShiftModal,
    ManageShiftModal,
    ManageLeaveRequestModal,
    TransferLeaveRequestModal,
    BlockingAlertModal,
    BlockingAlertForWeekLockModal,
  },
  props: {
    isWorkloadPlanDisplayed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      topPosition: 0,
      multipleSelectionEnabled: false,
      scrollElement: null,
      isScrolling: false,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsPostes', ['absences']),
    ...mapState('planningsShifts', ['blockingAlertShiftsByUser', 'pendingLeaveRequestShifts']),
    ...mapState('planningsState', [
      'shiftDragging',
      'shopPlanningConfig',
      'popularShiftDragging',
      'isMaskDisplayed',
      'shiftDragAndCreatingRowId',
      'dayViewPlanningSizeVariables',
      'visibleDays',
      'isManageShiftModalOpen',
    ]),
    ...mapState('planningsUsers', ['users', 'daysWorked']),
    ...mapState('annualization', ['areEmployeeAnnualizationConfigsLoading']),
    ...mapState('shopTeams', ['teamSchedules']),
    ...mapState('planningsAutomaticPlanning', ['brainLoading', 'brainShifts']),
    ...mapState('planningsLoading', ['planningDataStatus']),
    ...mapState('planningsBulkEdit', ['selectedCells']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),
    ...mapGetters('currentShop', [
      'isShopOnPaidVacationCalculationTypeOpeningDay',
      'isShopOnPaidVacationCalculationTypeCalendarDay',
      'isAnnualizedWorkingTimeAvailable',
      'is24hShop',
    ]),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('employees', ['userInitials', 'getAvatarUrlForUser']),
    ...mapGetters('planningsPostes', ['filteredPostes', 'positionViewPostes']),
    ...mapGetters('planningsShifts', [
      'shiftsForUser',
      'shiftsForPoste',
      'unassignedShifts',
      'monthlyShiftsForUser',
      'monthlyShiftsForPoste',
      'dayCellShifts',
      'shouldDisplayComments',
    ]),
    ...mapGetters('planningsState', [
      'isDailyView',
      'isEmployeesView',
      'isPostesView',
      'monday',
      'sunday',
      'visibleDays',
      'isAnyDayLocked',
      'currentDate',
      'isDayViewScrollable',
      'isShiftInFilters',
      'isWeeklyView',
    ]),
    ...mapGetters('planningsUsers', ['displayedInPlanningUsers', 'availabilitiesForUser', 'planningRowsCount']),
    ...mapGetters('annualization', [
      'employeeAnnualizationConfigs',
      'isAnnualizationCurrentlyActive',
      'periodTheoreticalBalanceAt',
    ]),
    ...mapGetters('shiftsDocuments', ['getDocumentsCountByShiftId']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('planningsState', ['areUnassignedShiftsAllowed']),

    ...mapGetters('planningsBulkEdit', [
      'displayBulkEditShifts',
      'getSelectedCellKey',
      'selectedCellsCount',
      'selectedCellsShifts',
      'canCopySelectedCells',
      'canPasteCopiedCells',
      'canDeleteSelectedCells',
      'canUnassignSelectedCells',
    ]),
    rowItems() {
      return this.isPostesView ? this.displayedPostes : this.displayedInPlanningUsers;
    },
    displayUnassignedShiftsRow() {
      // TODO: DEV-11588 rework on getters ( !isPostesView <> this.isEmployeesView )
      return !this.isPostesView &&
        (this.shopPlanningConfig.attributes.allowUnassignedShifts ||
          this.unassignedShifts.length > 0) &&
          this.isFeatureEnabled(FEATURES.FEATURE_UNASSIGNED_SHIFTS, this.currentShop.id);
    },
    canSeeAvailabilities() {
      return this.isFeatureEnabled(FEATURES.FEATURE_AVAILABILITY_REQUEST, this.currentShop.id);
    },
    canDisplayTasks() {
      return this.isFeatureEnabled(FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id);
    },
    selectedCellsPerRow() {
      if (!this.displayBulkEditShifts || this.planningRowsCount === 0) {
        return {};
      }
      const userListLength = this.planningRowsCount;
      const cellsByRow = this.selectedCells.reduce((cells, cell) => {
        const { x, y } = JSON.parse(cell);
        cells[y] ??= [];
        cells[y].push(x);
        return cells;
      }, {});

      for (let index = 0; index < userListLength; index++) {
        cellsByRow[index] ??= [];
      }
      return cellsByRow;
    },
    planningTableWrapperClasses() {
      return {
        'planning-table__wrapper': true,
        'planning-table__wrapper--padding': this.isWorkloadPlanDisplayed,
      };
    },
    employeesNames() {
      return this.users.map(user => ({
        id: user.id,
        formattedName: capitalize(`${user.attributes.firstName} ${user.attributes.lastName}`),
      }));
    },
    displayedPostes() {
      if (this.pendingLeaveRequestShifts.length > 0) {
        const leaveRequestPosteIds = this.pendingLeaveRequestShifts.map(
          leaveRequest => leaveRequest.attributes.posteId,
        );

        return [...new Set(this.filteredPostes.concat(this.positionViewPostes.filter(
          poste => leaveRequestPosteIds.includes(parseInt(poste.id, 10)),
        )))];
      }
      return this.filteredPostes;
    },
    isFetchingBatches() {
      return this.planningDataStatus === PLANNING_DATA_STATUS.LOADING_BATCHES;
    },
    isUnassignEnabledOnShop() {
      return this.areUnassignedShiftsAllowed || this.unassignedShifts.length > 0;
    },
  },
  watch: {
    blockingAlertShiftsByUser(newValue) {
      if (Object.values(newValue).length > 0) {
        this.emitOnRoot(MODAL_SHOW_EVENT, null, 'blocking-alert-modal');
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const simpleBar = new SimpleBar(this.$refs.planningScrollableContent);
      this.scrollElement = simpleBar.getScrollElement();
      this.scrollElement.addEventListener('scroll', this.handleScroll);
    });
    window.addEventListener('click', this.handleClickOutsideThePlanning);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutsideThePlanning);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    ...mapMutations('planningsBulkEdit', ['resetSelectedCells']),

    isMulticontractsEnabled(user) {
      // this handle unassigned shifts where we fallback on current shop
      if (user.id === null) return false;

      return this.isFeatureEnabled(FEATURES.FEATURE_MULTI_CONTRACTS, user.attributes.shopId);
    },
    globalConfig(user) {
      const {
        absences,
        areEmployeeAnnualizationConfigsLoading,
        blockingAlertShiftsByUser,
        brainLoading,
        brainShifts,
        config,
        currentDate,
        currentLicense,
        currentShop,
        dayCellShifts,
        dayViewPlanningSizeVariables,
        daysWorked,
        employeeAnnualizationConfigs,
        employeesNames,
        getAvatarUrlForUser,
        getDocumentsCountByShiftId,
        is24hShop,
        isAnnualizationCurrentlyActive,
        isAnnualizedWorkingTimeAvailable,
        isAnyDayLocked,
        isDailyView,
        isDayViewScrollable,
        isEmployeesView,
        isMaskDisplayed,
        isPostesView,
        isShiftInFilters,
        isShopOnPaidVacationCalculationTypeCalendarDay,
        isShopOnPaidVacationCalculationTypeOpeningDay,
        monday,
        pendingLeaveRequestShifts,
        periodTheoreticalBalanceAt,
        planningZoom,
        popularShiftDragging,
        shiftDragAndCreatingRowId,
        shiftDragging,
        shopPlanningConfig,
        shouldDisplayComments,
        canDisplayTasks,
        sunday,
        teamSchedules,
        userInitials,
        visibleDays,
        displayedInPlanningUsers,
        unassignedShifts,
        // Bulk edit
        selectedCells,
        displayBulkEditShifts,
        selectedCellsCount,
        selectedCellsShifts,
        getSelectedCellKey,
        canCopySelectedCells,
        canPasteCopiedCells,
        canDeleteSelectedCells,
        canUnassignSelectedCells,
      } = this;

      const { customShift } = shopPlanningConfig.attributes;

      return {
        absences,
        areEmployeeAnnualizationConfigsLoading,
        blockingAlertShiftsByUser,
        brainLoading,
        brainShifts,
        config,
        currentDate,
        currentLicense,
        currentShop,
        customShift,
        dayCellShifts,
        dayViewPlanningSizeVariables,
        daysWorked,
        employeeAnnualizationConfigs,
        employeesNames,
        getAvatarUrlForUser,
        getDocumentsCountByShiftId,
        is24hShop,
        isAnnualizationCurrentlyActive,
        isAnnualizedWorkingTimeAvailable,
        isAnyDayLocked,
        isDailyView,
        isDayViewScrollable,
        isEmployeesView,
        isMaskDisplayed,
        isMulticontractsEnabled: this.isMulticontractsEnabled(user),
        isPostesView,
        isShiftInFilters,
        isShopOnPaidVacationCalculationTypeCalendarDay,
        isShopOnPaidVacationCalculationTypeOpeningDay,
        monday,
        pendingLeaveRequestShifts,
        periodTheoreticalBalanceAt,
        planningZoom,
        popularShiftDragging,
        shiftDragAndCreatingRowId,
        shiftDragging,
        shopPlanningConfig,
        shouldDisplayComments,
        canDisplayTasks,
        sunday,
        teamSchedules,
        userInitials,
        visibleDays,
        displayedInPlanningUsers,
        unassignedShifts,
        // Bulk edit
        selectedCells,
        displayBulkEditShifts,
        selectedCellsCount,
        selectedCellsShifts,
        getSelectedCellKey,
        canCopySelectedCells,
        canPasteCopiedCells,
        canDeleteSelectedCells,
        canUnassignSelectedCells,
      };
    },
    ...mapActions('planningsBulkEdit', [
      'copySelectedCells',
      'pasteCopiedCells',
      'deleteSelectedCells',
    ]),
    planningRowClasses(rowItemIndex, rowItemsLength) {
      return {
        'planning-row__last-item': rowItemIndex === rowItemsLength - 1,
      };
    },
    rowShifts(rowItemId) {
      return this.isPostesView ?
        this.shiftsForPoste(rowItemId) :
        this.shiftsForUser(rowItemId);
    },
    rowPeriodShifts(rowItemId) {
      if (this.isEmployeesView) {
        return this.monthlyShiftsForUser(rowItemId);
      }

      if (this.isDailyView) {
        return this.shiftsForUser(rowItemId);
      }

      if (this.isPostesView) {
        return this.monthlyShiftsForPoste(rowItemId);
      }

      return [];
    },
    handleScroll(event) {
      // emit an event to weekly planning, to trigger recursive loading
      if (this.isProgressiveLoadingEnabled && this.isWeeklyView) {
        this.emitOnRoot('scrolling');
        this.emitOnRoot('hide-popover-menu');
      }
      const newScrollPosition = event.target.scrollTop;
      const scrollDelta = this.topPosition - newScrollPosition;
      this.topPosition = newScrollPosition;
      this.emitOnRoot('planning-table-scroll', scrollDelta);

      // Workaround to disable shift menu
      // Browser doesn't emit mouseleave without mouse movement so we
      // manually disable the menu on scroll
      this.emitOnRoot('hide-shift-menu');
    },
    handleDragOver(event) {
      // Headers + toolbar is about 150px so 200px from top should land on planning top row
      const draggingTop = event.clientY < 200;
      // kpi-row is about 30px so document.body.clientHeight - 80 should land on planning bottom row
      const draggingBottom = event.clientY > document.body.clientHeight - 80;
      const scrollSpeed = 7;

      if (!this.isScrolling) {
        if (draggingTop) {
          this.isScrolling = true;
          this.scroll(-scrollSpeed);
        } else if (draggingBottom) {
          this.isScrolling = true;
          this.scroll(scrollSpeed);
        }
      }

      if (!draggingTop && !draggingBottom) {
        this.isScrolling = false;
      }
    },
    handleClickOutsideThePlanning(event) {
      const planningTable = this.$refs.planningScrollableContent;
      const modalsPortal = document.querySelector('#modals-portal');

      // Ignore clicks inside planning table or any modals
      if (planningTable.contains(event.target) || modalsPortal?.contains(event.target)) {
        return;
      }
      this.resetSelectedCells();
    },
    scroll(step) {
      const scrollY = this.scrollElement.scrollTop;
      this.scrollElement.scrollTop = scrollY + step;
      setTimeout(() => {
        if (this.isScrolling) {
          this.scroll(step);
        }
      }, 10);
    },
    stopScroll() {
      this.isScrolling = false;
    },
    async handleKeyDown(e) {
      this.multipleSelectionEnabled = e.metaKey || e.ctrlKey;

      if (this.isManageShiftModalOpen) return;

      // Handle Escape key
      if (e.key === 'Escape') {
        this.handleEscapeKey();
        return;
      }

      // Handle delete shortcut
      if (e.key === 'Backspace' || e.key === 'Delete') {
        await this.handleDeleteKey();
        return;
      }

      // Check for keyboard shortcuts (Ctrl or Cmd)
      if (!(e.ctrlKey || e.metaKey)) return;

      // Handle copy shortcut
      if (e.key === 'c') {
        this.handleCopyKey();
        return;
      }
      // Handle paste shortcut
      if (e.key === 'v') {
        await this.handlePasteKey();
      }
    },
    handleKeyUp() {
      this.multipleSelectionEnabled = false;
    },
    handleEscapeKey() {
      this.resetSelectedCells();
      this.$skAnalytics.track('bulk_edit_cancel_shortcut', { cells_selected: this.selectedCellsCount });
      this.emitOnRoot('hide-popover-menu');
    },
    handleCopyKey() {
      if (!this.canCopySelectedCells) {
        this.$skToast({
          message: this.$t('plannings.table.cells.error.invalid_copy_selection'),
          variant: 'error',
        });
        return;
      }

      this.copySelectedCells();
      this.$skAnalytics.track('bulk_edit_copy_shift_shortcut', { cells_selected: this.selectedCellsCount });
      this.emitOnRoot('hide-popover-menu');
    },
    async handlePasteKey() {
      if (!this.canPasteCopiedCells) return;

      try {
        await this.pasteCopiedCells();
        this.$skAnalytics.track('bulk_edit_paste_shift_shortcut', {
          cells_selected: this.globalConfig.selectedShiftsCount,
        });
      } catch {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }

      this.emitOnRoot('hide-popover-menu');
    },
    async handleDeleteKey() {
      if (!this.canDeleteSelectedCells) return;

      try {
        await this.deleteSelectedCells();
        this.$skAnalytics.track('bulk_edit_delete_shift_shortcut', { cells_selected: this.selectedCellsCount });
      } catch {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }
      this.emitOnRoot('hide-popover-menu');
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-table__wrapper {
  overflow: auto;
  height: 100%;

  &--padding {
    padding-bottom: 44px;
  }
}

.loading-planning-row__wrapper {
  display: flex;
  position: relative;
  height: 48px;
}

.loading-planning-row__main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.loading-planning-row__sidebar-cell__wrapper {
  min-width: 160px;
  position: relative;
  background-color: white;
}

.loading-planning-row__counters-cell__main {
  width: 146px;
  position: relative;
}
</style>
