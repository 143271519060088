<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17842 3.97204C1.33373 4.08164 1.8019 4.40714 2.58295 4.94838C3.36402 5.48962 3.96238
         5.90636 4.37804 6.19863C4.4237 6.23066 4.52073 6.3003 4.66915 6.40765C4.8176 6.51506
         4.94095 6.60186 5.0391 6.66807C5.13732 6.73425 5.25606 6.8085 5.39544 6.89069C5.53477
         6.97281 5.6661 7.03458 5.78943 7.07547C5.91278 7.1167 6.02696 7.13712 6.13199
         7.13712H6.13888H6.14579C6.25083 7.13712 6.36506 7.11668 6.4884 7.07547C6.61168 7.03458
         6.74314 6.97274 6.88235 6.89069C7.02163 6.8084 7.14037 6.73423 7.23859 6.66807C7.33681
         6.60186 7.46006 6.51506 7.60856 6.40765C7.75696 6.30021 7.8541 6.23066 7.89977
         6.19863C8.31992 5.90636 9.38884 5.1641 11.1062 3.9719C11.4396 3.73905 11.7182
         3.45809 11.942 3.1292C12.1659 2.80046 12.2778 2.45559 12.2778 2.09479C12.2778
         1.79328 12.1692 1.53519 11.9522 1.32053C11.7353 1.10583 11.4783 0.998535 11.1815
         0.998535H1.09618C0.744499 0.998535 0.473855 1.11727 0.284299 1.35475C0.0947663
         1.59228 0 1.88918 0 2.24542C0 2.53318 0.125651 2.845 0.376833 3.1807C0.627991
         3.51643 0.895276 3.78023 1.17842 3.97204Z"
      fill="#727272"
    />
    <path
      d="M13.2773 10.3475C13.2375 10.5063 13.2185 10.6359 13.2199 10.7364C13.2211 10.837
         13.2365 10.9167 13.2664 10.9748C13.296 11.0329 13.3382 11.0733 13.3928 11.0954C13.4473
         11.1181 13.5094 11.129 13.5789 11.129C13.683 11.129 13.7816 11.0962 13.8748 11.0304C13.9677
         10.9646 14.049 10.8721 14.1183 10.7528C14.188 10.6339 14.2431 10.4924 14.2841
         10.3288C14.3251 10.1651 14.3456 9.98634 14.3456 9.79311C14.3456 9.47047 14.2953 9.18651
         14.1948 8.94087C14.0944 8.69542 13.9534 8.49006 13.7723 8.32518C13.5914 8.16011 13.3762
         8.03605 13.1267 7.95298C12.8776 7.86974 12.604 7.8282 12.3061 7.8282C11.9787 7.8282 11.676
         7.88988 11.3983 8.01252C11.1202 8.13516 10.879 8.30343 10.6746 8.51662C10.4699 8.73 10.3097
         8.98169 10.1945 9.27207C10.0792 9.56227 10.0214 9.87493 10.0214 10.2097C10.0214 10.6167
         10.0845 10.9746 10.2111 11.2833C10.3377 11.5922 10.5115 11.8504 10.732 12.0575C10.9528
         12.2646 11.2126 12.4201 11.5117 12.5242C11.8106 12.6284 12.1338 12.6805 12.4808
         12.6805C12.6717 12.6805 12.8507 12.6698 13.0169 12.6489C13.1832 12.6279 13.3358
         12.5994 13.4746 12.5634C13.6133 12.5276 13.7397 12.4866 13.8541 12.4407C13.9682
         12.3948 14.07 12.3459 14.1593 12.2939C14.2236 12.2566 14.2807 12.2454 14.3304
         12.2603C14.38 12.2753 14.4158 12.3124 14.4383 12.3718L14.5648 12.7033C14.3067
         12.8716 14.0055 13.0094 13.6607 13.1162C13.3158 13.2229 12.9226 13.276 12.4808
         13.276C12.0318 13.276 11.6163 13.2055 11.2345 13.0641C10.8523 12.9224 10.5218
         12.7197 10.2428 12.4557C9.96369 12.1914 9.74533 11.8694 9.58775 11.4899C9.43035
         11.1102 9.35156 10.6839 9.35156 10.2097C9.35156 9.94445 9.38561 9.68634 9.45388
         9.43571C9.52215 9.18526 9.61948 8.95014 9.74604 8.73053C9.8726 8.5111 10.025
         8.30949 10.2038 8.12571C10.3824 7.94229 10.5808 7.78489 10.7992 7.65333C11.0174
         7.52178 11.2537 7.41946 11.5081 7.3462C11.7625 7.27311 12.0284 7.23657 12.3063
         7.23657C12.6587 7.23657 12.9967 7.2954 13.3202 7.41322C13.6438 7.53105 13.9306
         7.69968 14.18 7.91929C14.4292 8.13872 14.6283 8.40682 14.7771 8.72322C14.926
         9.03963 15.0005 9.39614 15.0005 9.79311C15.0005 10.0584 14.9614 10.3072 14.8832
         10.5391C14.8051 10.7712 14.696 10.9726 14.5557 11.1439C14.4156 11.3151 14.2506
         11.4496 14.0609 11.5477C13.8712 11.6459 13.6646 11.6947 13.4413 11.6947C13.2702
         11.6947 13.1186 11.655 12.9874 11.5755C12.8559 11.4965 12.7653 11.3684 12.7158
         11.1924C12.5693 11.3709 12.4143 11.499 12.2507 11.5755C12.0869 11.6527 11.9095
         11.691 11.7184 11.691C11.5623 11.691 11.4263 11.6635 11.3111 11.609C11.1956 11.5545
         11.099 11.4792 11.0207 11.384C10.9427 11.2883 10.8842 11.1753 10.8461 11.0454C10.8076
         10.9151 10.7883 10.7732 10.7883 10.6191C10.7883 10.4776 10.8063 10.3343 10.8423
         10.1894C10.8781 10.0443 10.9322 9.90417 11.0042 9.76887C11.076 9.63375 11.166 9.50719
         11.2739 9.38936C11.3819 9.27154 11.5083 9.16868 11.6534 9.08063C11.7985 8.99257
         11.9625 8.92305 12.1446 8.87224C12.327 8.82144 12.5274 8.79577 12.7459 8.79577C12.9393
         8.79577 13.1042 8.81075 13.2406 8.84052C13.3769 8.87028 13.5085 8.91235 13.6349
         8.96708L13.2773 10.3475ZM12.8418 9.39489C12.7625 9.38009 12.6807 9.37261 12.5963
         9.37261C12.4425 9.37261 12.2987 9.40612 12.1646 9.47297C12.0306 9.53981 11.9147
         9.63054 11.8165 9.74445C11.7186 9.85871 11.6411 9.98955 11.5839 10.137C11.5268
         10.2847 11.4983 10.4379 11.4983 10.5965C11.4983 10.7557 11.5323 10.8787 11.6006
         10.9667C11.6689 11.0552 11.7687 11.0992 11.9003 11.0992C11.9646 11.0992 12.03 11.089
         12.0958 11.0692C12.1614 11.0495 12.2241 11.0138 12.2837 10.963C12.343 10.9122 12.3979
         10.8446 12.4473 10.7603C12.4969 10.6758 12.5379 10.5705 12.5701 10.4439L12.8418 9.39489Z"
      fill="#727272"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.18063 7.0277C8.95702 6.46582 10.0945 5.67784 11.5925 4.66392C11.853 4.48584
         12.0813 4.28702 12.2778 4.06787V6.32983C10.1589 6.32994 8.44116 8.04769 8.44116
         10.1666C8.44116 10.3146 8.44953 10.4605 8.46583 10.6041H1.09625C0.794773 10.6041
         0.536656 10.4968 0.321998 10.282C0.107293 10.0673 0 9.80939 0 9.50774V4.06787C0.200979
         4.29165 0.431643 4.49047 0.692064 4.66392C2.34552 5.78751 3.48055 6.57554 4.09721
         7.0277C4.35754 7.21946 4.56881 7.36916 4.73092 7.47643C4.89307 7.58377 5.10892
         7.69339 5.37837 7.80524C5.64781 7.91726 5.89907 7.9731 6.13201
         7.9731H6.1389H6.14588C6.37887 7.9731 6.62998 7.91726 6.89943
         7.80524C7.16897 7.69339 7.38468 7.58377 7.54688 7.47643C7.70908
         7.36916 7.92028 7.21946 8.18063 7.0277Z"
      fill="#727272"
    />
  </svg>
</template>

<script>

export default {
  name: 'EnvelopeDotIcon',
};
</script>
