<template>
  <div class="amendment-setup__form">
    <div class="amendment-setup__form__row">
      <span class="sk-text-large-semibold">
        {{ $t(`${amendmentsLocaleKey}.modal.type`) }}
      </span>
      <div class="amendment-setup__form__cell">
        <SkSelectV2
          v-model="amendmentType"
          :options="amendmentTypes"
          :label="$t(`${amendmentsLocaleKey}.modal.type`)"
        />
      </div>
    </div>
    <div class="amendment-setup__form__row">
      <span class="sk-text-large-semibold">
        {{ durationTitle }}
      </span>
      <div class="amendment-setup__form__cell--col">
        <div class="sk-flex">
          <SkDatePicker
            ref="startDatepicker"
            v-model="amendmentStartsAt"
            :disabled-date="disabledDaysStart"
            :errored="isStartDateInvalid"
            :error-message="amendmentStartErrorMessage"
            :lang="$i18n.locale"
            :placeholder="$t(`${amendmentsLocaleKey}.modal.start`)"
          />
          <SkDatePicker
            v-if="isTemporary"
            ref="endDatepicker"
            v-model="amendmentEndsAt"
            :errored="isEndDateInvalid"
            :error-message="amendmentEndErrorMessage"
            :disabled-date="disabledDaysEnd"
            :lang="$i18n.locale"
            :placeholder="$t(`${amendmentsLocaleKey}.modal.end`)"
          />
        </div>
        <div
          v-if="invalidAmendmentMessage"
          class="amendment-setup__date-error sk-text-small-regular"
        >
          {{ invalidAmendmentMessage }}
        </div>
      </div>
    </div>
    <div class="amendment-setup__form__row">
      <span class="sk-text-large-semibold">
        {{ $t(`${amendmentsLocaleKey}.modal.contract_hours`) }}
      </span>
      <SkInputGroup
        :errored="hasAmendmentHoursError"
        :error-message="$t('errors.missing_value')"
        class="amendment-setup__form__cell--col"
      >
        <SkInput
          v-model="amendmentHours"
          type="number"
          min="0"
          :label="$t(`${amendmentsLocaleKey}.modal.${contractHoursLabel}`)"
        />
        <template #append>
          <span class="sk-text-medium-regular amendment-hours__label">
            {{ $t('contract.per_week_unit') }}
          </span>
        </template>
      </SkInputGroup>
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'AmendmentSetup',
  props: {
    employeeAnnualizationConfig: {
      type: Object,
      default: () => ({}),
    },
    shopAnnualizationConfig: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amendmentsLocaleKey: 'employees.tabs.contracts.full_contract.data.salary.amendments.update_card.create_amendment',
      amendmentTypes: [
        { id: 'temporary', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.temporary') },
        { id: 'permanent', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.permanent') },
      ],
      isResetingDuration: false,
    };
  },
  computed: {
    ...mapGetters('amendments', ['hasActivePermanentAmendment', 'permanentAmendments', 'temporaryAmendments']),
    ...mapGetters('currentShop', ['arePaidBreaksActivated']),
    ...mapState('selectedEmployee', ['employee']),
    amendment: {
      get() {
        return this.value;
      },
      set(newAmendment) {
        this.$emit('input', newAmendment);
      },
    },
    invalidAmendmentMessage() {
      if (this.hasAmendmentRangeError) {
        return this.$t(`${this.amendmentsLocaleKey}.errors.non_consecutive`);
      }
      if (this.isAmendementRangeInvalid) {
        return this.$t(`${this.amendmentsLocaleKey}.errors.range_error`);
      }

      return null;
    },
    amendmentEndsAt: {
      get() {
        return this.amendment.endsAt;
      },
      set(newEndDate) {
        if (this.amendment.endsAt === newEndDate) {
          return;
        }

        this.amendment = { ...this.amendment, endsAt: newEndDate };
        this.$nextTick(this.setValidity);
      },
    },
    amendmentHours: {
      get() {
        return this.amendment.hours;
      },
      set(newHours) {
        if (this.amendment.hours === newHours) {
          return;
        }

        this.amendment = { ...this.amendment, hours: newHours };
        this.$nextTick(this.setValidity);
      },
    },
    amendmentStartsAt: {
      get() {
        return this.amendment.startsAt;
      },
      set(newStartDate) {
        if (this.amendment.startsAt === newStartDate) {
          return;
        }

        this.amendment = { ...this.amendment, startsAt: newStartDate };
        this.$nextTick(this.setValidity);
      },
    },
    amendmentType: {
      get() {
        return this.amendment.type;
      },
      set(newAmendmentType) {
        if (this.amendment.type === newAmendmentType) {
          return;
        }

        this.amendment = {
          ...this.amendment,
          endsAt: null,
          isInvalid: true,
          startsAt: null,
          type: newAmendmentType,
        };
        this.resetDuration();
        this.$skAnalytics.track(`annualization_amendment_${newAmendmentType}_selector`);
      },
    },
    amendmentStartErrorMessage() {
      if (this.invalidAmendmentMessage) {
        return null;
      }

      if (this.isStartDateDifferentThanMonday) {
        return this.$t(`${this.amendmentsLocaleKey}.errors.wrong_start`);
      }

      return this.$t('errors.missing_value');
    },
    amendmentEndErrorMessage() {
      if (this.invalidAmendmentMessage) {
        return null;
      }

      if (this.isEndDateDifferentThanSunday) {
        return this.$t(`${this.amendmentsLocaleKey}.errors.wrong_end`);
      }

      return this.$t('errors.missing_value');
    },
    durationTitle() {
      const key = this.isTemporary ? 'start_and_end' : 'start';

      return this.$t(`${this.amendmentsLocaleKey}.modal.${key}`);
    },
    hasAmendmentHoursError() {
      return !this.amendment.hours ||
        isNaN(parseInt(this.amendment.hours, 10)) ||
        parseInt(this.amendment.hours, 10) < 0;
    },
    hasAmendmentRangeError() {
      if (!this.isTemporary || !this.amendment.startsAt || !this.amendment.endsAt) {
        return false;
      }

      return this.amendment.startsAt > this.amendment.endsAt;
    },
    isAmendementRangeInvalid() {
      if (!this.isTemporary ||
          !this.amendment.startsAt || !this.amendment.endsAt ||
          !this.isEmployeeAnnualized ||
          !this.temporaryAmendments) {
        return false;
      }

      // Check if any temporary amendment is in the same period as the amendment
      return this.temporaryAmendments.some(amendment => {
        const startDate = skDate(amendment.attributes.startsAt);
        const endDate = skDate(amendment.attributes.endsAt);
        return startDate.isBetween(this.amendment.startsAt, this.amendment.endsAt, 'day', '[]') ||
          endDate.isBetween(this.amendment.startsAt, this.amendment.endsAt, 'day', '[]');
      });
    },
    isEmployeeAnnualized() {
      return Object.keys(this.employeeAnnualizationConfig).length > 0;
    },
    isEndDateDifferentThanSunday() {
      if (!this.amendment.endsAt) {
        return false;
      }

      return !skDate(this.amendment.endsAt).isSunday();
    },
    isEndDateInvalid() {
      if (!this.isTemporary || this.isResetingDuration) {
        return false;
      }

      return this.hasAmendmentRangeError ||
        !this.amendmentEndsAt ||
        this.isEndDateDifferentThanSunday;
    },
    isStartDateDifferentThanMonday() {
      if (!this.amendment.startsAt) {
        return false;
      }

      return !skDate(this.amendment.startsAt).isMonday();
    },
    isStartDateInvalid() {
      if (this.isResetingDuration) {
        return false;
      }

      return this.hasAmendmentRangeError ||
        !this.amendmentStartsAt ||
        this.isStartDateDifferentThanMonday;
    },
    isTemporary() {
      return this.amendment.type === this.amendmentTypes[0].id;
    },
    arePaidBreaksActivatedForEmployee() {
      return this.arePaidBreaksActivated({
        shop: this.employee.relationships.shop,
        convention: this.employee.relationships.convention,
      });
    },
    contractHoursLabel() {
      return this.arePaidBreaksActivatedForEmployee ?
        'contract_hours_presence_time_label' :
        'contract_hours_label';
    },
  },
  mounted() {
    this.setValidity();
  },
  methods: {
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),

    setValidity() {
      const isInvalid = this.isStartDateInvalid || this.isEndDateInvalid ||
        this.hasAmendmentHoursError || this.isAmendementRangeInvalid;

      if (this.amendment.isInvalid !== isInvalid) {
        this.amendment = { ...this.amendment, isInvalid };
      }
    },
    disabledDaysStart(date) {
      const isNotMonday = !skDate(date).isMonday();

      if (!this.isEmployeeAnnualized) {
        return isNotMonday;
      }

      return isNotMonday ||
        skDate(date).isBefore(
          skDate(this.employeeAnnualizationConfig.initializationDate).startOf('day'),
        ) ||
        this.isOnSamePeriodAsATemporaryAmendment(date) ||
        this.isOnSameDayAsPermanentAmendmentStart(date);
    },
    disabledDaysEnd(date) {
      const condition = !skDate(date).isSunday() || skDate(date).isBefore(this.amendment.startsAt);

      if (!this.isEmployeeAnnualized) {
        return condition;
      }

      return condition || this.isOnSamePeriodAsATemporaryAmendment(date);
    },
    isOnSameDayAsPermanentAmendmentStart(date) {
      if (!this.isTemporary || !this.hasActivePermanentAmendment) {
        return false;
      }

      return this.permanentAmendments.some(amendment => skDate(date).isSame(
        skDate.utc(amendment.attributes.startsAt),
        'day',
      ));
    },
    isOnSamePeriodAsATemporaryAmendment(date) {
      if (!this.isTemporary || !this.temporaryAmendments) {
        return false;
      }

      return this.temporaryAmendments.some(amendment => {
        const startDate = skDate(amendment.attributes.startsAt).utc();
        const endDate = skDate(amendment.attributes.endsAt).utc();
        return skDate(date).isBetween(startDate, endDate, 'day', '[]');
      });
    },
    resetData() {
      this.amendment = {
        ...this.amendment,
        endsAt: null,
        isInvalid: true,
        startsAt: null,
        type: 'temporary',
      };
      this.resetDuration();
    },
    resetDuration() {
      this.isResetingDuration = true;
      this.$nextTick(() => {
        this.$refs.startDatepicker.resetModified();
        if (this.isTemporary) this.$refs.endDatepicker.resetModified();
        this.isResetingDuration = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.amendment-setup__form {
  padding: 8px 24px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      padding-bottom: 24px;
    }
  }

  &__cell {
    display: flex;
    width: calc(50% + 32px);

    &--col {
      display: flex;
      flex-direction: column;
      width: calc(50% + 32px);
    }
  }
}

.amendment-setup__date-error {
  margin-top: 4px;
  color: $sk-error;
}

.amendment-hours__label {
  color: $sk-grey-50;
}

::v-deep .sk-datepicker__wrapper {
  width: 100%;

  &:nth-of-type(2) {
    margin-left: 12px;
  }
}

::v-deep .sk-datepicker__error-message {
  margin-top: 4px;
  font-size: $fs-text-s;
  line-height: normal;
  font-weight: $fw-regular;
}

::v-deep .sk-input-group__error-label {
  margin: 4px 0 0 0 !important;
  line-height: normal;
  font-weight: $fw-regular;
}
</style>
