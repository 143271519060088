<template>
  <div class="settings__postes__container">
    <h1 class="sk-header--1">
      {{ $t('shop_settings.tabs.shop_postes.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t(`shop_settings.tabs.shop_postes.subtitle.${sector}`) }}
    </p>
    <div class="shop_postes__explanation-card">
      <SkMedallion
        size="small"
        text="i"
        background-color="#2b66fe"
        color="white"
      />
      <div class="shop_postes__explanation-card__content">
        <p class="shop_postes__explanation-card__title">
          {{ $t('shop_settings.tabs.shop_postes.explanation_card.title') }}
        </p>
        <p class="sk-subtitle--medium">
          {{ $t(`shop_settings.tabs.shop_postes.explanation_card.description.${sector}.p1`) }}
        </p>
        <p class="sk-subtitle--medium shop_postes__explanation-card--italic">
          {{ $t(`shop_settings.tabs.shop_postes.explanation_card.description.${sector}.p2`) }}
        </p>
        <p class="sk-subtitle--medium shop_postes__explanation-card--italic">
          {{ $t(`shop_settings.tabs.shop_postes.explanation_card.description.${sector}.p3`) }}
        </p>
        <p
          v-if="sector !== 'general'"
          class="sk-subtitle--medium shop_postes__explanation-card--italic"
        >
          {{ $t(`shop_settings.tabs.shop_postes.explanation_card.description.${sector}.p4`) }}
        </p>
      </div>
    </div>
    <div
      v-if="loadingFetch || loading"
      class="postes__spinner"
    >
      <SkLoader size="large" />
    </div>
    <table
      v-else
      class="shop_postes__table"
    >
      <tr class="shop_postes__table__header-row">
        <th class="shop_postes__table__header-cell">
          <p class="sk-subtitle--small">
            {{ $t('shop_settings.tabs.shop_postes.table.headers.color') }}
          </p>
        </th>
        <th class="shop_postes__table__header-cell">
          <p class="sk-subtitle--small">
            {{ $t('shop_settings.tabs.shop_postes.table.headers.postes') }}
          </p>
        </th>
        <th
          v-if="pauseTimeInPercentage"
          class="shop_postes__table__header-cell"
        >
          <p class="sk-subtitle--small">
            {{ $t('shop_settings.tabs.shop_postes.table.headers.pause_type_1') }}
          </p>
          <p class="sk-subtitle--small">
            {{ $t('shop_settings.tabs.shop_postes.table.headers.pause_type_2') }}
          </p>
        </th>
        <th class="shop_postes__table__header-cell">
          <p class="sk-subtitle--small">
            {{ $t('shop_settings.tabs.shop_postes.table.headers.pause_time') }}
          </p>
        </th>
      </tr>
      <PosteRow
        v-for="shopPoste in shopPostes"
        :key="shopPoste.id"
        :disabled="isPosteRowDisabled(shopPoste)"
        :shop-poste="shopPoste"
        @update-shop-poste="handleUpdate"
        @delete-shop-poste="handleDelete"
      />
      <NewPosteRow
        v-if="canCreatePoste"
        ref="newPosteRow"
        @create-shop-poste="handleCreate"
      />
    </table>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features';
import PosteRow from './PosteRow';
import NewPosteRow from './NewPosteRow';

export default {
  name: 'Postes',
  components: { NewPosteRow, PosteRow },
  computed: {
    ...mapState('convention', ['convention', 'loading']),
    ...mapState('config', ['config']),
    ...mapState('shopPostes', ['shopPostes', 'loadingFetch']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentLicense', ['canCreatePosteAndTeam']),
    ...mapGetters('currentShop', ['sector']),
    ...mapGetters('features', ['isFeatureEnabled']),

    pauseTimeInPercentage() {
      return this.config.convention_percentage_pause_time.includes(
        this.convention.attributes.name,
      );
    },
    canCreatePoste() {
      return this.canCreatePosteAndTeam && this.isFeatureEnabled(
        FEATURES.FEATURE_CREATE_POSITION,
        this.currentShop.id,
      );
    },
  },
  watch: {
    currentShop(newValue, oldValue) {
      this.fetchConvention(this.currentShop.id);
      this.fetchShopPostes({ shopId: this.currentShop.id });
    },
  },
  created() {
    this.fetchConvention(this.currentShop.id);
    this.fetchShopPostes({ shopId: this.currentShop.id });
  },
  methods: {
    ...mapActions('shopPostes', ['fetchShopPostes', 'createShopPoste', 'updateShopPoste', 'deleteShopPoste']),
    ...mapActions('convention', ['fetchConvention']),

    isPosteRowDisabled(poste) {
      return !this.canCreatePoste || poste.attributes.default;
    },
    handleCreate(shopPoste) {
      this.createShopPoste({ shopId: this.currentShop.id, shopPoste })
        .then(() => {
          this.$refs.newPosteRow.resetValues();
          this.$skToast({
            message: this.$t('shop_settings.tabs.shop_postes.table.actions.create.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.shop_postes.table.actions.create.error_message'),
            variant: 'error',
          });
        });
    },
    handleUpdate(shopPoste) {
      this.updateShopPoste({ shopId: this.currentShop.id, shopPoste })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.shop_postes.table.actions.update.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.shop_postes.table.actions.update.error_message'),
            variant: 'error',
          });
        });
    },
    handleDelete(shopPoste) {
      this.$root.$emit('confirm', event, {
        title: this.$t('shop_settings.tabs.shop_postes.table.actions.delete.confirmation_message'),
        onConfirm: () => {
          this.deleteShopPoste({ shopId: this.currentShop.id, shopPoste })
            .then(() => {
              this.$skToast({
                message: this.$t('shop_settings.tabs.shop_postes.table.actions.delete.success_message'),
                variant: 'success',
              });
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__postes__container {
  padding-bottom: 100px;

  .shop_postes__explanation-card {
    margin: 20px 0 40px;
    width: 100%;
    border: 1px solid $sk-grey-10;
    border-radius: 4px;
    padding: 20px;
    display: flex;

    .shop_postes__explanation-card__content {
      display: flex;
      flex-direction: column;
      padding-left: 15px;

      .shop_postes__explanation-card__title {
        margin-bottom: 5px;
      }

      .shop_postes__explanation-card--italic {
        font-style: italic;
      }
    }
  }

  .postes__spinner {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-blue;
  }

  .shop_postes__table {
    width: 100%;

    .shop_postes__table__header-cell {
      padding-bottom: 10px;
    }
  }
}
</style>
