<template>
  <div class="toolbar__datepicker">
    <GlobalEvents
      @keydown="keyShortcut"
    />
    <router-link
      :to="previousDateQuery"
      :data-test="previousNavigationDataName"
      class="history__toolbar__date-actions"
    >
      <SkCircleButton
        :disabled="isLoading"
        icon="ChevronLeftV2Icon"
      />
    </router-link>
    <div class="history__toolbar__date__datepicker-wrapper">
      <div
        v-if="isLoading"
        class="history__toolbar__date__datepicker-loading"
      >
        <SkLoader />
      </div>
      <SkDatePicker
        v-else
        v-model="currentDateValue"
        data-test="date-picker"
        :clearable="false"
        :type="datePickerInputType"
        :disabled-date="disabledDate"
        :input-moment-format="datePickerInputFormat"
        :lang="$i18n.locale"
        :range="range"
        no-icon
      >
        <template #input>
          <div :class="datePickerDateRangeClasses">
            {{ displayedDates }}
          </div>
        </template>
      </SkDatePicker>
    </div>
    <router-link
      :to="nextDateQuery"
      :data-test="nextNavigationDataName"
      class="history__toolbar__date-actions"
    >
      <SkCircleButton
        :disabled="isLoading"
        icon="ChevronRightV2Icon"
      />
    </router-link>
    <div
      v-if="isLoading && !isExtraSmallScreen"
      class="datepicker__week-tag-loading"
    />
    <SkTag
      v-else-if="isWeekTagDisplayed && !isExtraSmallScreen"
      class="datepicker__week-tag"
      variant="light"
    >
      {{ weekTag }}
    </SkTag>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';
import GlobalEvents from 'vue-global-events';

export default {
  name: 'ToolbarDatePicker',
  components: {
    GlobalEvents,
  },
  props: {
    // Date string used to initialize the datepicker
    currentDate: {
      type: [String, Date, Number, Array],
      required: true,
    },
    // The DatePicker component will add routes for previous and next arrow links,
    // By default, those 2 routes are : { query: { date: 'xxx' } }
    // Additional query params can be used to add more query params to the generated routes.
    additionalQueryParams: {
      type: Object,
      default: () => {},
    },
    dateType: {
      type: String,
      required: false,
      default: 'date',
    },
    disabledDate: {
      type: Function,
      default: undefined,
    },
    range: {
      type: Boolean,
      default: false,
    },
    maxMonthRange: {
      type: Number,
      default: 2,
    },
    isDayRangeNavigation: {
      type: Boolean,
      default: false,
    },
    isYearRangeNavigation: {
      type: Boolean,
      default: false,
    },
    isOneDayRangeAuthorized: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExtraSmallScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localCurrentDate: this.currentDate,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['isDayVisible', 'nextVisibleDay', 'previousVisibleDay']),
    datePickerInputType() {
      return this.dateType;
    },
    datePickerInputFormat() {
      switch (this.datePickerInputType) {
        case 'month':
          return 'YYYY MMMM';
        default:
          return 'YYYY-MM-DD';
      }
    },
    datePickerDateRangeClasses() {
      return {
        datepicker__daterange__label: true,
        'datepicker__daterange__label-year': this.isYearRangeNavigation,
      };
    },
    currentDateValue: {
      get() {
        return this.localCurrentDate;
      },
      set(newDate) {
        this.localCurrentDate = newDate;
      },
    },
    isMonthNavigation() {
      return this.datePickerInputType === 'month';
    },
    isWeekTagDisplayed() {
      if (this.isMonthNavigation) return false;

      return true;
    },
    isEndDateEndOfMonth() {
      return skDate(this.currentDate[1]).clone().format('YYYY-MM-DD') ===
        skDate(this.currentDate[1]).clone().endOf('month').format('YYYY-MM-DD');
    },
    nextDateQuery() {
      if (this.isLoading) return {};

      const query = { ...this.additionalQueryParams };

      if (this.range) {
        query.start_date = skDate(this.currentDate[0]).add(1, 'M').format('YYYY-MM-DD');

        if (this.isEndDateEndOfMonth) {
          query.end_date = skDate(this.currentDate[1]).add(1, 'M').endOf('month').format('YYYY-MM-DD');
        } else {
          query.end_date = skDate(this.currentDate[1]).add(1, 'M').format('YYYY-MM-DD');
        }
      } else {
        query.date = this.nextDate;
      }

      return { query };
    },
    previousDateQuery() {
      if (this.isLoading) return {};

      const query = { ...this.additionalQueryParams };

      if (this.range) {
        query.start_date = skDate(this.currentDate[0]).subtract(1, 'M').format('YYYY-MM-DD');

        if (this.isEndDateEndOfMonth) {
          query.end_date = skDate(this.currentDate[1]).subtract(1, 'M').endOf('month').format('YYYY-MM-DD');
        } else {
          query.end_date = skDate(this.currentDate[1]).subtract(1, 'M').format('YYYY-MM-DD');
        }
      } else {
        query.date = this.previousDate;
      }

      return { query };
    },
    previousDate() {
      if (this.isDayRangeNavigation) {
        const previousDay = skDate(this.currentDate).subtract(1, 'd').format('YYYY-MM-DD');
        if (!this.isDayVisible(previousDay)) {
          return this.previousVisibleDay(previousDay);
        }

        return previousDay;
      }

      if (this.isMonthNavigation) {
        return skDate(this.currentDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      }

      return skDate(this.currentDate).subtract(7, 'd').format('YYYY-MM-DD');
    },
    nextDate() {
      if (this.isDayRangeNavigation) {
        const nextDay = skDate(this.currentDate).add(1, 'd').format('YYYY-MM-DD');
        if (!this.isDayVisible(nextDay)) {
          return this.nextVisibleDay(nextDay);
        }

        return nextDay;
      }

      if (this.isMonthNavigation) {
        return skDate(this.currentDate).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      }

      return skDate(this.currentDate).add(7, 'd').format('YYYY-MM-DD');
    },
    startDate() {
      return this.range ?
        skDate(this.currentDate[0]).format('D MMM') :
        skDate(this.currentDate).startOf('isoWeek').format('D MMM');
    },
    endDate() {
      return this.range ?
        skDate(this.currentDate[1]).format('D MMM') :
        skDate(this.currentDate).endOf('isoWeek').format('D MMM');
    },
    startDateWithYearDisplayed() {
      return this.range ?
        skDate(this.currentDate[0]).format('DD MMM YYYY') :
        skDate(this.currentDate).startOf('isoWeek').format('DD MMM YYYY');
    },
    endDateWithYearDisplayed() {
      return this.range ?
        skDate(this.currentDate[1]).format('DD MMM YYYY') :
        skDate(this.currentDate).endOf('isoWeek').format('DD MMM YYYY');
    },
    weekNumber() {
      return skDate(this.currentDate).startOf('day').isoWeek();
    },
    startDateWeekNumber() {
      if (!this.range) return '';

      return skDate(this.currentDate[0]).startOf('day').isoWeek();
    },
    endDateWeekNumber() {
      if (!this.range) return '';

      return skDate(this.currentDate[1]).startOf('day').isoWeek();
    },
    weekTag() {
      if (this.isLoading) return '';

      if (this.range && (this.endDateWeekNumber - this.startDateWeekNumber !== 0)) {
        return this.$t('dates.week_range', {
          from: this.startDateWeekNumber,
          to: this.endDateWeekNumber,
        });
      }

      let weekNumber = this.weekNumber;
      if (
        this.range &&
        parseInt(this.endDateWeekNumber, 10) - parseInt(this.startDateWeekNumber, 10) === 0
      ) {
        weekNumber = this.endDateWeekNumber;
      }
      return this.$t('dates.full_week', { weekNumber });
    },
    displayedDates() {
      if (this.isDayRangeNavigation) {
        const formattedDate = skDate(this.currentDateValue).format('ddd DD MMM YYYY');
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
      }

      if (this.isMonthNavigation) {
        const monthDate = skDate(this.currentDateValue).format('MMMM YYYY');
        return monthDate.charAt(0).toUpperCase() + monthDate.slice(1);
      }

      return `${this.startDate} - ${this.endDateWithYearDisplayed}`;
    },
    previousNavigationDataName() {
      if (this.isDayRangeNavigation) return 'history__previous-day';
      return 'history__previous-week';
    },
    nextNavigationDataName() {
      if (this.isDayRangeNavigation) return 'history__next-day';
      return 'history__next-week';
    },
  },
  watch: {
    currentDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localCurrentDate = newValue;
      }
    },
    localCurrentDate(newValue, oldValue) {
      if (newValue === oldValue) return;

      if (this.range) {
        const dates = this.handleMaxRange(newValue);

        this.$router.push({
          query: {
            ...this.additionalQueryParams,
            start_date: dates[0],
            end_date: dates[1],
          },
        });
      } else {
        this.$router.push({ query: { ...this.additionalQueryParams, date: newValue } });
      }
    },
  },
  mounted() {
    this.$skAnalytics.stdProperties.source = this.getTrackerSource();
  },
  methods: {
    ...mapGetters('overlays', ['isAnyOverlayOpen']),
    handleMaxRange(newValue) {
      const startDate = skDate(newValue[0]);
      let endDate = skDate(newValue[1]);

      if (!this.isOneDayRangeAuthorized && startDate.isSame(endDate)) {
        endDate = startDate.clone().add(1, 'day');

        this.$skToast({
          message: this.$t('dates.notice_min_range'),
          variant: 'notice',
        });
      } else if (endDate.clone().subtract(this.maxMonthRange, 'months').isAfter(startDate)) {
        endDate = startDate.clone().add(this.maxMonthRange, 'months');

        this.$emit('track-max-range');

        this.$skToast({
          message: this.$t('dates.notice_max_range', { max: this.maxMonthRange }),
          variant: 'notice',
        });
      }

      return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
    },
    keyShortcut(event) {
      if ((event.ctrlKey || event.metaKey) && event.code === 'ArrowLeft') {
        this.handleMetaOrCtrlPlusLeftArrowShorcut(event);
      }
      if ((event.ctrlKey || event.metaKey) && event.code === 'ArrowRight') {
        this.handleMetaOrCtrlPlusRightArrowShorcut(event);
      }
    },
    handleMetaOrCtrlPlusLeftArrowShorcut(event) {
      event.preventDefault();

      if (this.isLoading || event.repeat || this.isAnyOverlayOpen()) {
        return;
      }

      this.$skAnalytics.track('sc_previous_period_action');
      this.$router.push(this.previousDateQuery);
    },
    handleMetaOrCtrlPlusRightArrowShorcut(event) {
      event.preventDefault();

      if (this.isLoading || event.repeat || this.isAnyOverlayOpen()) {
        return;
      }

      this.$skAnalytics.track('sc_next_period_action');
      this.$router.push(this.nextDateQuery);
    },
    getTrackerSource() {
      switch (this.$router.currentRoute.name) {
        case 'badgings_shifts_days':
        case 'badgings_shifts_users':
          return 'Time Clock';
        case 'plannings_days':
          return 'Day';
        case 'plannings_weeks_employees':
        case 'plannings_weeks_postes':
          return 'Week';
        case 'plannings_months':
          return 'Month';
        case 'reports':
          return 'Report';
        case 'analytics_dashboard':
          return 'Dashboard';
        default:
          return this.$router.currentRoute.name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar__datepicker {
  display: flex;
  align-items: center;

  .history__toolbar__date-actions {
    padding: 0 10px;
  }
}

.datepicker__daterange__label {
  width: 168px;
  height: 36px;
  background-color: $sk-white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;

  &:hover {
    cursor: pointer;
  }
}

.history__toolbar__date__datepicker-loading {
  width: 238px;
  height: 36px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $sk-grey-10;
  border-radius: 3px;
}

.datepicker__week-tag {
  cursor: default;

  &-loading {
    width: 115px;
    height: 22px;
    border-radius: 3px;
    background-color: $sk-grey-10;
  }
}
</style>
