<template>
  <!-- eslint-disable max-len -->
  <div class="employee__amendments">
    <div class="employee__amendments__content--wrapper">
      <div class="employee__amendments__content">
        <div class="employee__amendments__content__description">
          <div class="employee__amendments__icon--wrapper">
            <AmendmentIcon />
          </div>
          <h3 class="sk-header--3">
            {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.title') }}
          </h3>
          <p v-if="!hasAmendments">
            {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.no_amendment') }}
          </p>
        </div>
        <SkPopover
          v-if="!isContractReadOnlyView"
          :disabled="canAddAmendment"
          placement="left"
          as-tooltip
        >
          <template #anchor>
            <SkOroraButton
              icon="PlusSignV2Icon"
              variant="secondary"
              data-test="continue_add_button"
              :disabled="!canAddAmendment"
              @click="openCreateAmendmentModal"
            >
              {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.create_amendment.button') }}
            </SkOroraButton>
          </template>
          <template #content>
            {{ $t('employees.tabs.documents.amendments_disabled.before_tag') }}
            <PackTag :type="packType" />
            {{ $t('employees.tabs.documents.amendments_disabled.after_tag') }}
            <br>
            <br>
            {{ $t('employees.tabs.documents.amendments_disabled.second_line') }}
          </template>
        </SkPopover>
      </div>
      <template v-if="hasAmendments">
        <AmendmentsTable
          :amendments="restrictedAmendments"
          @delete-amendment="handleDelete"
        />
        <div
          v-if="hasMoreThanRestrictedAmendements"
          v-modal.employee-amendments-modal
          v-track="'extend_amendments_list'"
          class="employee__amendments__display-more"
        >
          {{ displayMoreAmendmentsText }}
        </div>
      </template>
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <CreateAmendmentModal
        :employee-annualization-config="employeeAnnualizationConfig"
        :tracking-options="trackingOptions"
      />
    </MountingPortal>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <employee-amendments-modal :employee-annualization-config="employeeAnnualizationConfig" />
    </MountingPortal>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';
import PackTag from '@app-js/shared/components/PackTag';
import AmendmentsTable from './AmendmentsTable';
import AmendmentIcon from './AmendmentIcon.vue';
import EmployeeAmendmentsModal from './EmployeeAmendmentsModal.vue';
import CreateAmendmentModal from './CreateAmendmentModal';

const RESTRICTED_AMENDMENTS = 3;

export default {
  name: 'Amendments',
  components: {
    AmendmentsTable,
    AmendmentIcon,
    EmployeeAmendmentsModal,
    CreateAmendmentModal,
    PackTag,
  },
  props: {
    employeeAnnualizationConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('amendments', ['amendments']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('amendments', ['amendmentsCount']),
    ...mapGetters('contracts', ['isContractReadOnlyView']),
    ...mapGetters('features', ['isFeatureEnabled']),

    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    canAddAmendment() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_CONTRACTS_AMENDMENTS,
        this.employee.relationships.shop.id,
      );
    },
    hasAmendments() {
      return this.amendmentsCount > 0;
    },
    hasMoreThanRestrictedAmendements() {
      return this.amendmentsCount > RESTRICTED_AMENDMENTS;
    },
    additionnalAmendments() {
      if (!this.hasMoreThanRestrictedAmendements) return null;

      return this.amendmentsCount - RESTRICTED_AMENDMENTS;
    },
    displayMoreAmendmentsText() {
      return this.additionnalAmendments === 1 ?
        this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.display_more.amendment') :
        this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.display_more.amendments',
          { additionnalAmendments: this.additionnalAmendments });
    },
    restrictedAmendments() {
      return this.amendments.slice(0, RESTRICTED_AMENDMENTS);
    },
    trackingOptions() {
      return {
        submit: 'multicontract_add_contracted_hours_amendment_validate',
        cancel: 'add_amendment_cancel',
      };
    },
  },
  methods: {
    ...mapActions('amendments', ['deleteAmendment']),

    handleDelete(amendmentId, employeeId, contractId) {
      this.$root.$emit('confirm', event, {
        title: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.delete.confirm'),
        // eslint-disable-next-line arrow-body-style
        onConfirmSuccess: () => {
          return this.deleteAmendment({
            employee_id: employeeId,
            amendment_id: amendmentId,
            contract_id: contractId,
          })
            .then(() => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.delete.success'),
                variant: 'success',
                containerId: 'employees__container',
              });
            })
            .catch(error => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.delete.failure'),
                variant: 'error',
                containerId: 'employees__container',
              });
            });
        },
      });
    },
    openCreateAmendmentModal(event) {
      this.$skAnalytics.track(
        'add_contracted_hours_amendment_edition',
      );
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-amendment-modal');
    },
  },
};
</script>

<style lang="scss">
.employee__amendments {
  width: 100%;
  border: 1px $sk-grey-10 solid;
  border-radius: 4px;
}

.employee__amendments__content--wrapper {
  overflow: auto;
}

.employee__amendments__content,
.employee__amendments__content--no-amendment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 0;
}

.employee__amendments__content__description {
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    margin: 0;
  }
}

.employee__amendments__icon--wrapper {
  background: $sk-blue-5;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  svg {
    margin-left: 5px;
  }
}

.employee__amendments__display-more {
  color: $sk-blue;
  border: none;
  background: none;
  text-align: center;
  padding: 15px 0;
  border-radius: 3px;

  &:hover {
    background: $sk-blue;
    cursor: pointer;
    transition: all .3s ease-out;
  }
}
</style>
