import * as Sentry from '@sentry/vue';
import Cookies from 'js-cookie';
import { TokenWebModel } from '@skelloapp/skello-auth-client';
import { httpClient } from './http_client';
import { authClient } from './auth_client';
import { KEY_AUTH_IMPERSONATE_USER_ID } from './constant_client';

class ImpersonateClient {
  // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
  async impersonateLegacy() {
    const impersonateUserId = Cookies.get(
      KEY_AUTH_IMPERSONATE_USER_ID,
    );
    if (!impersonateUserId || !authClient.authToken) return;

    const { tokenData } = authClient.authToken;

    // eslint-disable-next-line no-console
    console.log('Impersonate as id=', impersonateUserId);

    try {
      // Here we check if you are impersonating someone, we are asking a new access token from
      // the user you are impersonating, we keep refresh token from the true user to keep detecting
      // you are a super admin
      if (String(tokenData.sub) !== impersonateUserId && tokenData.superAdmin) {
        // this call will be moved inside skello-auth-client package
        const { data } = await httpClient.post('/v3/login/impersonate', {
          impersonate_user_id: impersonateUserId,
        });

        const token = new TokenWebModel({
          token: data.token,
          refreshToken: data.refresh_token,
        });

        authClient.setAuthToken(token);
      }
    } catch (error) {
      Sentry.captureException(error, {
        contexts: {
          impersonateUserId,
          tokenData,
        },
      });
    }
  }

  async impersonate(statelessTokenParams) {
    try {
      const { data } = await httpClient.post('/v3/login/impersonate', {
        token: statelessTokenParams.token,
        purpose: statelessTokenParams.purpose,
      });

      const token = new TokenWebModel({
        token: data.token,
        refreshToken: data.refresh_token,
      });

      authClient.setAuthToken(token);
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  }
}

export const impersonateClient = new ImpersonateClient();
