<template>
  <SkModalV2
    id="sign-document-modal"
    ref="modal"
    :title="$t('text_document_templates.sign_document_modal.title')"
    :subtitle="$t('text_document_templates.sign_document_modal.subtitle')"
    :submit-button-label="$t('text_document_templates.sign_document_modal.submit')"
    :cancel-button-label="$t('text_document_templates.sign_document_modal.cancel')"
    :next-step-button-label="$t('text_document_templates.sign_document_modal.next')"
    :previous-step-button-label="$t('text_document_templates.sign_document_modal.back')"
    :submit-disabled="disableSubmit"
    :step-count="2"
    :landing-step="1"
    show-progress-bar
    dismissable
    display-footer
    @update-step="setCurrentStep"
    @submit="submitSignatures"
    @cancel="cancelSignatures"
    @show="fetchDocumentUrl"
  >
    <template #body>
      <SkModalSectionV2
        v-if="isOnSignerSelectionStep"
        border-bottom="none"
      >
        <SignerSelectionStep
          :are-initials-active="areInitialsActive"
          :add-initials="addInitials"
          @signers-chosen="signersChosen"
        />
      </SkModalSectionV2>
      <SkModalSectionV2
        v-if="isOnSignatureStep"
        border-bottom="none"
      >
        <p>
          {{ $t('text_document_templates.sign_document_modal.description') }}
        </p>
        <PdfViewer
          :pdf-url="documentUrl"
          :signers="signers"
          :document-id="document.id"
          @load-signatures="loadSignatures"
          @clean-signatures="cleanSignatures"
        />
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import PdfViewer from '@app-js/users/pages/documents/esignature/pdf_viewer/PdfViewer';
import {
  httpClient, svcDocumentV2Client,
} from '@skello-utils/clients';
import SignerSelectionStep from './steps/SignerSelectionStep.vue';

export default {
  name: 'SignDocumentModal',
  components: {
    PdfViewer,
    SignerSelectionStep,
  },
  data: () => ({
    signatures: null,
    currentStep: 1,
    signers: [],
    areInitialsActive: false,
    documentUrl: '',
  }),
  computed: {
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('textDocumentTemplates', {
      document: state => state.selectedEsignatureDocument,
    }),
    ...mapGetters('employeeDocuments', ['isDocumentV2']),
    disableSubmit() {
      if (this.isOnSignerSelectionStep && this.signers.length === 0) return true;
      if (this.isOnSignatureStep && this.signatures === null) return true;
      return false;
    },
    isOnSignerSelectionStep() {
      return this.currentStep === 1;
    },
    isOnSignatureStep() {
      return this.currentStep === 2;
    },
  },
  methods: {
    ...mapActions('employeeDocuments', ['updateDocument']),
    setCurrentStep(currentStep) {
      if (this.currentStep !== currentStep && currentStep === 2) this.$skAnalytics.track('esignature_documents_select_employee_modal_next');
      this.currentStep = currentStep;
    },
    signersChosen(signers) {
      this.signers = signers;
    },
    addInitials(areInitialsActive) {
      this.areInitialsActive = areInitialsActive;
    },
    loadSignatures({ signatures }) {
      this.signatures = signatures;
    },
    cleanSignatures() {
      this.signatures = null;
    },
    async fetchDocumentUrl() {
      if (this.isDocumentV2(this.document.id)) {
        this.documentUrl = await svcDocumentV2Client.document.getUrl(this.document.id, 'inline');
      } else {
        this.documentUrl = this.document.attributes.url;
      }
    },
    handleBack() {
      const { folder } = this.document.attributes;

      this.$router.push({
        name: 'user_documents',
        params: {
          shop_id: this.currentShop.id,
          user_id: this.selectedEmployee.id,
        },
        query: { folder },
      });
    },
    async checkDocumentSignable() {
      return httpClient.get('/v3/api/request_esignatures/document_signable', {
        params: {
          shop_id: this.currentShop.id,
          document_id: this.document.id,
          user_id: this.selectedEmployee.id,
        },
      });
    },
    async triggerEsignatureFlow() {
      return httpClient.post('/v3/api/request_esignatures/trigger_document_esignature', {
        shop_id: this.currentShop.id,
        document_id: this.document.id,
        user_id: this.selectedEmployee.id,
        include_initials: this.areInitialsActive,
      });
    },
    async updateDocumentSignatures() {
      if (this.isDocumentV2(this.document.id)) {
        const formattedSignatures = this.signatures.map(signature => ({
          user_id: signature.signer.id,
          coordinates: {
            page: signature.page,
            width: signature.width,
            height: signature.height,
            x: signature.x,
            y: signature.y,
          },
        }));

        return svcDocumentV2Client.document.update(this.document.id, {
          esignature: {
            signers: formattedSignatures,
            includeInitials: this.areInitialsActive,
          },
        });
      }

      return this.updateDocument({
        employeeId: this.selectedEmployee.id,
        folder: this.document.attributes.folder,
        documentId: this.document.id,
        esignature: {
          signatures: this.signatures,
          initials: this.areInitialsActive,
        },
      });
    },
    cancelSignatures() {
      if (this.isOnSignerSelectionStep) {
        this.$skAnalytics.track('esignature_documents_select_employee_modal_cancel');
      }
      if (this.isOnSignatureStep) {
        this.$skAnalytics.track('esignature_documents_place_signatures_modal_cancel');
      }
    },
    async submitSignatures() {
      const messageSuccess = this.$t('text_document_templates.sign_document_modal.submit_success');
      const messageError = this.$t('text_document_templates.sign_document_modal.submit_error');
      const nonSignablePdfError = this.$t('text_document_templates.sign_document_modal.non_signable_pdf');

      try {
        this.$skAnalytics.track('esignature_documents_place_signatures_modal_send');
        await this.checkDocumentSignable();
        await this.updateDocumentSignatures();
        await this.triggerEsignatureFlow();
        this.$skToast({
          message: messageSuccess,
          variant: 'success',
        });

        this.$root.$emit('signature-process-started', this.document.id);
      } catch (error) {
        const message = error.response?.status === 403 ? nonSignablePdfError : messageError;
        this.$skToast({ message, variant: 'error' });
      }
      this.handleBack();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal {
  max-height: 100% !important;
}

::v-deep .sk-modal__section {
  padding-bottom: 0 !important;
}

</style>
