<template>
  <div
    id="step-two__wrapper"
    class="step-two__wrapper"
  >
    <div class="step-two-card__info-container">
      <div class="sk-text-large-regular">
        {{ $t('self_serve.step_two.explanation_text') }}
      </div>
      <div class="step-two-card__email sk-text-medium-semibold">
        {{ prospect.attributes.email }}
      </div>
      <SkOroraButton
        v-track="'self_serve_change_email'"
        v-modal.update-email-modal
        fill-parent
        :variant-color="modifyEmailButtonColor"
        @click="trackChangeEmailClick"
      >
        <span class="step-two__edit-email sk-text-medium-semibold">
          {{ $t('self_serve.step_two.edit_email') }}
        </span>
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <UpdateEmailModal v-model="prospect" />
        </MountingPortal>
      </SkOroraButton>
    </div>
    <div class="step-two-hint__container">
      <div>
        <CircledIIcon
          class="step-two-hint__icon"
          fill="#2b66fe"
          width="24px"
          height="24px"
        />
      </div>
      <div class="step-two-hint__text">
        <div>
          <span>
            {{ $t('self_serve.step_two.no_email_received_1') }}
          </span>
          <span>
            <SkOroraLink
              v-track="'self_serve_resend_email'"
              @click.native.stop="resendActivationMail"
            >
              <span>{{ $t('self_serve.step_two.no_email_received_2') }}</span>
            </SkOroraLink>
            {{ $t('self_serve.step_two.no_email_received_3') }}
          </span>
          <span>
            <SkOroraLink
              v-track="'self_serve_login'"
              :href="loginUrlWithParams"
              @click.native.stop="trackLoginLink"
            >
              <span>{{ $t('self_serve.step_two.no_email_received_4') }}</span>
            </SkOroraLink>
            {{ $t('self_serve.step_two.no_email_received_5') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import {
  CircledIIcon,
  SkOroraButton,
  SkOroraLink,
  SK_COLORS,
} from '@skelloapp/skello-ui';
import UpdateEmailModal from './UpdateEmailModal.vue';

export default {
  name: 'StepTwo',
  components: {
    CircledIIcon,
    UpdateEmailModal,
    SkOroraButton,
    SkOroraLink,
  },
  data() {
    return {
      modifyEmailButtonColor: SK_COLORS.skWhite,
      prospect: {
        attributes: {
          email: '',
        },
      },
    };
  },
  computed: {
    loginUrlWithParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const langParams = urlParams.get('lang') ? `?lang=${urlParams.get('lang')}` : '';

      return `${window.location.protocol }//${window.location.host}/users/sign_in${langParams}`;
    },
    hasTokenExpired() {
      return new Date(this.prospect.attributes.expirationDate) < new Date();
    },
  },
  mounted() {
    if (this.$route.params.email) {
      this.prospect.attributes.email = this.$route.params.email;
      this.prospect.id = this.$route.params.id;
    } else {
      httpClient
        .get(`/v3/api/prospects/${this.$route.query.token}`)
        .then(response => {
          this.prospect = response.data.data;

          if (this.hasTokenExpired) this.resendActivationMail();
        });
    }

    // Google Analytics script for Marketing team
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'self_serve_step_viewed',
      page_type: 'selfserve',
      page_category: 'selfserve',
      selfserve_version: localStorage.getItem('self-serve-version'),
      step_index: 'stepTwo',
      step_name: 'stepTwo',
      page_path: window.location.pathname,
      referrer: document.referrer,
    });

    this.$nextTick(() => {
      window.scrollBy({ top: -100000 });
    });
  },
  methods: {
    async resendActivationMail(event) {
      if (event) event.preventDefault();
      this.$skAnalytics.track('ss_email_validation_step2_resend');
      try {
        const response = await httpClient
          .post(`/v3/api/users/${this.prospect.id}/self_serve/reset_activation_code`);
        const prospect = response.data.data;
        // In reset_activation_code endpoint, prospect.token has been updated.
        // Replace token in route allows user to StepTwo page.
        this.$router.replace({
          name: 'step_two',
          query: {
            token: prospect.attributes.token,
            lang: this.$i18n.locale,
          },
          params: {
            id: prospect.attributes.id,
          },
        });

        this.$skToast({
          message: this.$t('self_serve.step_two.toasts.resent'),
          variant: 'success',
          containerId: 'step-two__wrapper',
          dimension: 'long',
        });
      } catch (_) {
        this.$skToast({
          message: this.$t('self_serve.errors.generic'),
          variant: 'error',
          containerId: 'step-two__wrapper',
          dimension: 'long',
        });
      }
    },
    trackChangeEmailClick() {
      this.$skAnalytics.track('ss_email_validation_step2_modify_email');
    },
    trackLoginLink() {
      this.$skAnalytics.track('ss_account_creation_step2_login_link');
    },
  },
};
</script>

<style lang="scss" scoped>
.step-two-card__info-container {
  border-radius: 4px;
  background-color: $sk-grey-5;
  display: flex;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  color: $sk-black;

  .step-two__edit-email, .step-two-card__email {
      color: $sk-black;
  }
  .step-two-card__email {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.step-two-card__svg {
  display: flex;
  align-items: center;

  @media (max-width: $sm-width) {
    display: none;
  }
}

.step-two__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.step-two-hint__container {
  display: flex;
  width: 100%;
  min-height: 42px;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  max-width: 750px;
  padding: 18px;
  align-items: center;

  .step-two-hint__icon {
    fill: $sk-blue;
    margin-right: 12px;
  }

  .step-two-hint__text {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: $md-width) {
      align-items: flex-start;
      line-height: 25.6px;
      flex-direction: column;
      padding-left: 14px;
    }
  }
}
</style>

<style  lang="scss">
.sk-toast {
  @media (max-width: $sm-width) {
    width: 90% !important;
  }
}
</style>
