<template>
  <img
    src="./images/cegedim-employee-info.svg"
    alt="Illustration of realtime employee information transfert for Cegedim"
    width="255px"
    height="160px"
  >
</template>

<script>
export default {
  name: 'CegedimEmployeeInfo',
};
</script>
