<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 192 209"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3256_434"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="29"
      y="120"
      width="132"
      height="89"
    >
      <rect
        x="29"
        y="120"
        width="132"
        height="89"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_3256_434)">
      <g filter="url(#filter0_d_3256_434)">
        <path
          d="M48.0092 140.33L96.0864 167.734L48.0091 195.29L48.0092 140.33Z"
          fill="#2b66fe"
        />
        <path
          d="M143.202 195.134L96.0861 167.655L143.202 140.175L143.202 195.134Z"
          fill="#2b66fe"
        />
        <rect
          x="48.0117"
          y="61"
          width="95.1928"
          height="106.731"
          fill="#2b66fe"
        />
      </g>
      <mask
        id="mask1_3256_434"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="48"
        y="61"
        width="96"
        height="135"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M143.201 61H48.0086V140.329L48.0078 140.329L48.0078 195.288L96.085
             167.733L96.0826 167.731H96.2246L143.206 195.133L143.206 140.173L143.201
             140.175V61Z"
          fill="#2b66fe"
        />
      </mask>
      <g mask="url(#mask1_3256_434)">
        <rect
          x="77.4844"
          y="50.7148"
          width="36.4495"
          height="147.667"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter1_d_3256_434)">
      <path
        d="M37.8476 20C37.3943 20 36.9455 20.0901 36.5268 20.2652C36.1081
           20.4402 35.7277 20.6968 35.4074 21.0202C35.087 21.3437 34.833 21.7276
           34.6599 22.1501C34.4868 22.5726 34.3979 23.0253 34.3984
           23.4825V95.5213C34.3982 95.9782 34.4872 96.4307 34.6605 96.8528C34.8337
           97.275 35.0878 97.6586 35.4081 97.9817C35.7285 98.3047 36.1088 98.561
           36.5274 98.7357C36.946 98.9104 37.3946 99.0002 37.8476 99H153.942C154.395
           99.0005 154.844 98.9109 155.263 98.7364C155.683 98.5618 156.063 98.3057
           156.384 97.9827C156.705 97.6596 156.96 97.276 157.134 96.8537C157.308
           96.4314 157.398 95.9786 157.398 95.5213V23.4825C157.398 22.559 157.034
           21.6735 156.387 21.0205C155.739 20.3676 154.861 20.0005 153.946
           20H37.8476ZM149.268 91.2111H42.2988V27.5627H149.259L149.268 91.2111Z"
        fill="#FEBF3F"
      />
    </g>
    <g filter="url(#filter2_d_3256_434)">
      <path
        d="M156.368 100.484H35.632C34.8132 100.491 34.0285 100.815 33.4409
           101.389L21.2774 112.903C18.7604 115.282 20.2118 119.999 23.4647
           119.999H168.537C171.787 119.999 173.24 115.286 170.725 112.903L158.552
           101.389C157.966 100.817 157.184 100.493 156.368 100.484Z"
        fill="#FEBF3F"
      />
    </g>
    <path
      d="M149.46 91.1973V27.5625H42.3125V91.1973H149.46ZM94.8544
         68.3237H71.6548V65.4225H94.8544V68.3237ZM121.206
         84.3167H96.2983V82.115H121.206V84.3167ZM144.117
         80.5371H96.3077V78.3372H144.117V80.5371ZM144.117
         76.767H96.3077V74.5614H144.117V76.767ZM144.117
         72.9968H96.3077V70.7837H144.117V72.9968ZM96.3077
         68.3237V65.4225H119.505V68.3237H96.3077ZM144.151
         68.3237H120.955V65.4225H144.151V68.3237ZM47.0169
         32.967H144.151V62.8117H47.0169V32.967ZM47.0169
         65.4225H70.209V68.3237H47.0169V65.4225ZM47.0169
         70.9534H94.8263V85.6061H47.0169V70.9534Z"
      fill="white"
    />
    <path
      d="M144.15 32.9648H47.0156V62.8076H144.15V32.9648Z"
      fill="#FFC03F"
      fill-opacity="0.34"
    />
    <path
      d="M70.2096 65.4219H47.0156V68.323H70.2096V65.4219Z"
      fill="#FFC03F"
    />
    <path
      d="M94.8251 70.9531H47.0156V85.6078H94.8251V70.9531Z"
      fill="#FFC03F"
    />
    <path
      d="M94.8562 65.4219H71.6641V68.323H94.8562V65.4219Z"
      fill="#FFC03F"
    />
    <path
      d="M119.505 65.4219H96.3086V68.323H119.505V65.4219Z"
      fill="#FFC03F"
    />
    <path
      d="M144.118 70.7812H96.3086V72.983H144.118V70.7812Z"
      fill="#FFC03F"
      fill-opacity="0.34"
    />
    <path
      d="M144.118 74.5586H96.3086V76.7566H144.118V74.5586Z"
      fill="#FFC03F"
      fill-opacity="0.34"
    />
    <path
      d="M144.118 78.3398H96.3086V80.5397H144.118V78.3398Z"
      fill="#FFC03F"
      fill-opacity="0.34"
    />
    <path
      d="M121.207 82.1133H96.3086V84.3151H121.207V82.1133Z"
      fill="#FFC03F"
      fill-opacity="0.34"
    />
    <path
      d="M144.149 65.4219H120.953V68.323H144.149V65.4219Z"
      fill="#FFC03F"
    />
    <g
      style="mix-blend-mode:soft-light"
      opacity="0.16"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.9996 20H37.8473C37.3941 20 36.9449 20.0898 36.5269 20.2656C36.3082
           20.3594 36.1012 20.4727 35.9059 20.6055C35.7262 20.7266 35.5582 20.8633
           35.4059 21.0195C35.0855 21.3438 34.8316 21.7266 34.6598 22.1484C34.4879
           22.5742 34.398 23.0234 34.398 23.4844V95.5195C34.398 95.9766 34.4879
           96.4297 34.6598 96.8516C34.8316 97.2734 35.0855 97.6602 35.4059
           97.9805C35.7262 98.3047 36.109 98.5625 36.5269 98.7344C36.9449
           98.9102 37.3941 99 37.8473 99H48.0074V100.484H35.6324C34.8121
           100.492 34.0269 100.816 33.441 101.391L21.2769 112.902C18.7613
           115.281 20.2105 120 23.4644 120H48.0074V140.328V195.289L95.9996
           167.785V20Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3256_434"
        x="28.0078"
        y="45"
        width="135.195"
        height="174.289"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.133333 0 0 0 0 0.388235 0 0 0 0 0.956863 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3256_434"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3256_434"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_3256_434"
        x="14.3984"
        y="0"
        width="163"
        height="119"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.996078 0 0 0 0 0.74902 0 0 0 0 0.247059 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3256_434"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3256_434"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_3256_434"
        x="0"
        y="80.4844"
        width="192"
        height="59.5156"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.996078 0 0 0 0 0.74902 0 0 0 0 0.247059 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3256_434"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3256_434"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Computer',
  props: {
    width: {
      type: String,
      default: '283',
    },
    height: {
      type: String,
      default: '311',
    },
  },
};
</script>
