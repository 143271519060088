<template>
  <!-- eslint-disable  max-len -->
  <SkModal
    :id="id"
    ref="modifyHoursCounterModal"
    :modal-title="$t('employees.tabs.counters.hours_counter.manual_changes_modal.title')"
    :disabled-submit-button="disableSubmitButton"
    :tracking-options="trackingOptions"
    size="medium"
    @submit="handleSubmit"
    @cancel="handleCancel"
    @show="handleShow"
  >
    <template #body>
      <SkModalSection>
        <div class="counter-section__form__input">
          <div class="counter-section__form__input-label">
            {{ $t('employees.tabs.counters.hours_counter.manual_changes_modal.at') }}
            <div class="counter-section__form__input-label--regular">
              {{ $t('employees.tabs.counters.hours_counter.manual_changes_modal.end_of_week') }}
              &#8239;{{ dateLabel(date) }}
            </div>
          </div>
          <div> {{ oldCounter }} </div>
        </div>
        <div class="counter-section__form__input">
          <div class="counter-section__form__input-label">
            {{ $t('employees.tabs.counters.hours_counter.manual_changes_modal.modify') }}
            <div class="counter-section__form__input-label">
              {{ $t('employees.tabs.counters.hours_counter.manual_changes_modal.modify_end') }}
              <span class="counter-section__form__input-helper">
                <CircledQuestionMarkIcon
                  v-tooltip.top="$t('employees.tabs.counters.hours_counter.manual_changes_modal.helper')"
                  width="18"
                  height="18"
                />
              </span>
            </div>
          </div>
          <SkInput
            v-model="manualChanges"
            :errored="modifyInputError"
            :error-message="$t('employees.tabs.counters.day_rate_counter.manual_changes_modal.error_message')"
            error-message-align-right
            type="number"
            center
            class="modify-section__form__input-right-part"
            @keyup.enter.native="handleSubmit"
          />
        </div>
      </SkModalSection>
      <SkModalSection border-bottom="none">
        <div class="counter-section__form__input">
          <div class="counter-section__form__input-label">
            {{ $t('employees.tabs.counters.hours_counter.manual_changes_modal.new_counter_at') }}
            <div class="counter-section__form__input-label--regular">
              {{ $t('employees.tabs.counters.hours_counter.manual_changes_modal.end_of_week') }}
              &#8239;{{ dateLabel(date) }}
            </div>
          </div>
          <div class="modify-section__form__new-counter-right-part">
            {{ newCounter }}
          </div>
        </div>
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { captureException } from '@sentry/vue';
import { roundFloat } from '@skello-utils/formatting/numbers';
import { isFloatWithPrependSign } from '@skello-utils/validators';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

export default {
  name: 'ModifyCounterModal',
  props: {
    oldCounter: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      manualChanges: 0,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('selectedEmployee', ['initials']),
    disableSubmitButton() {
      return !this.manualChanges;
    },
    newCounter() {
      return roundFloat(this.manualChanges + this.oldCounter, 2);
    },
    modifyInputError() {
      return !isFloatWithPrependSign(this.manualChanges);
    },
    trackingOptions() {
      return {
        submit: 'manual_change_hours_counter_validate',
        cancel: 'manual_change_hours_counter_cancel',
      };
    },
  },
  methods: {
    ...mapActions('employeeCounters', ['updateEmployeeHoursCounter', 'fetchSelectedEmployeeHoursCounter']),
    dateLabel(date) {
      return skDate(date, 'DD-MM-YYYY').isoWeek();
    },
    handleCancel() {
      this.manualChanges = 0;
    },
    handleSubmit(event) {
      event.preventDefault();

      this
        .updateEmployeeHoursCounter({
          user_id: this.employee.id,
          date: this.date,
          manual_changes: Number(this.manualChanges) * 3600,
          silentLoading: true,
        })
        .then(() => {
          try {
            this.$svcEvents.create(
              EVENT_SUBTYPE_ACTION.EMPLOYEE_HOUR_TRACKER_UPDATE, {
                date: skDate(this.date, 'DD-MM-YYYY').unix() * 1000,
                employee: this.employee,
                values: [this.oldCounter, this.manualChanges],
              },
            );
          } catch (error) {
            captureException(error);
          }

          this.$refs.modifyHoursCounterModal.hide();
          this.fetchSelectedEmployeeHoursCounter({
            user_id: this.employee.id,
            date: this.date,
            silentLoading: true,
          });
          this.$skToast({
            message: this.$t('employees.tabs.counters.hours_counter.manual_changes_modal.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.tabs.counters.hours_counter.manual_changes_modal.error'),
            variant: 'error',
          });
        });
    },
    handleShow() {
      this.manualChanges = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.counter-section__form__input-helper {
  display: inline;
  position: absolute;
  margin-left: 16px;
  padding-top: 1px;
  padding-bottom: 2px;
}

.counter-section__form__input {
  width: 100%;
  background: white;
  justify-content: space-between;
  display: flex;
  padding-bottom: 20px;
  align-items: center;

  &:first-child {
    padding-right: 25px;
  }

  .sk-input__error-label {
    position: absolute;
    top: 44px;
    left: 0;
    font-size: $fs-text-s;
    color: $sk-error;
  }
}

.counter-section__form__input-label {
  font-weight: $fw-semi-bold;
  background: white;
}

.counter-section__form__input-label--regular {
  font-weight: $fw-regular;
  background: white;
}

.modify-section__form__new-counter-right-part {
  color: $sk-blue-50;
}

div.modify-section__form__input-right-part {
  width: 60px;
}
</style>
