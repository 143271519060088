<template>
  <div class="planning__toolbar-actions-items-bar">
    <component
      :is="item.name"
      v-for="(item, index) of items"
      :key="index"
      :ref="`actionItem${index}`"
      v-bind="item.props"
      :is-data-loading="isDataLoading"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';

import ActionsSeparator from './Toolbar/ActionsSeparator';
import PlanningFiltersAction from './Toolbar/PlanningFiltersAction';
import UndoRedoAction from './Toolbar/UndoRedoAction';
import SmartPlannerAction from './Toolbar/SmartPlannerAction';
import PopularShiftsAction from './Toolbar/PopularShiftsAction';
import WorkloadPlanAction from './Toolbar/WorkloadPlanAction';
import EsignatureAction from './Toolbar/EsignatureAction';
import PrintAction from './Toolbar/PrintAction';

export default {
  name: 'ToolbarActionItems',
  components: {
    ActionsSeparator,
    PlanningFiltersAction,
    UndoRedoAction,
    SmartPlannerAction,
    PopularShiftsAction,
    WorkloadPlanAction,
    EsignatureAction,
    PrintAction,
  },
  props: {
    isDataLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('planningsState', [
      'isPostesView',
      'isDailyView',
      'isMonthlyView',
    ]),
    ...mapGetters('features', [
      'isFeatureEnabled',
    ]),
    isSchedulePrintFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_SCHEDULE_PRINT, this.currentShop.id);
    },
    isPackOfferBasic() {
      return this.currentOrganisation.attributes.packOffer.name === 'basic';
    },
    canDisplayUndoRedo() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNINGS_UNDO_REDO, this.currentShop.id);
    },
    monthlyViewItems() {
      const actionBarItems = [
        { name: 'PlanningFiltersAction' },
      ];

      if (this.isPackOfferBasic) {
        if (this.isSchedulePrintFeatureEnabled) {
          actionBarItems.push(
            { name: 'ActionsSeparator' },
            { name: 'PrintAction' },
          );
        }

        return actionBarItems;
      }

      actionBarItems.push(
        { name: 'ActionsSeparator' },
        { name: 'UndoRedoAction', props: { sourceType: 'Month', type: 'undo' } },
        { name: 'UndoRedoAction', props: { sourceType: 'Month', type: 'redo' } },
        { name: 'ActionsSeparator' },
        { name: 'PrintAction' },
      );

      return actionBarItems;
    },
    genericItems() {
      const actionBarItems = [
        { name: 'PlanningFiltersAction' },
        { name: 'ActionsSeparator', props: { shouldHideOnExtraSmallScreen: true } },
        { name: 'UndoRedoAction', props: { sourceType: 'Week', type: 'undo' } },
        { name: 'UndoRedoAction', props: { sourceType: 'Week', type: 'redo' } },
      ];

      if (!this.isPostesView) {
        actionBarItems.push(
          {
            name: 'ActionsSeparator',
            props: {
              shouldHideOnSmallScreen: true,
              shouldBeVisible: this.canDisplayUndoRedo,
            },
          },
          { name: 'SmartPlannerAction' },
          { name: 'PopularShiftsAction' },
          { name: 'WorkloadPlanAction' },
          {
            name: 'ActionsSeparator',
            props: {
              shouldHideOnSmallScreen: true,
              shouldBeVisible: this.currentLicense.attributes.canCreateShifts && !this.isDailyView,
            },
          },
          { name: 'EsignatureAction' },
        );
      }

      if (this.isSchedulePrintFeatureEnabled) {
        actionBarItems.push(
          { name: 'PrintAction' },
        );
      }

      if (this.isDailyView) {
        return actionBarItems;
      }

      return actionBarItems;
    },
    items() {
      if (
        this.isMonthlyView
      ) {
        return this.monthlyViewItems;
      }

      return this.genericItems;
    },
  },
  mounted() {
    this.listenOnRoot(MODAL_SHOW_EVENT, this.closePops);
  },
  methods: {
    closePops() {
      this.$nextTick(() => {
        this.items.forEach((_, index) => {
          this.$refs[`actionItem${index}`][0].$refs.popov?.handleHide();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.planning__toolbar-actions-items-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

</style>
