<template>
  <SkDropdown
    id="enhanced-document-actions__dropdown"
    ref="actionDropdown"
    :force-show="shouldForceShow"
    :prevent-overflow="false"
    placement="bottom-start"
    y-offset="5px"
    trigger="click"
  >
    <template #anchor>
      <SkCircleButton
        ref="actionDropdownAnchor"
        icon="KebabV2Icon"
      />
    </template>
    <template #menu>
      <div
        ref="actionDropdownMenu"
        class="enhanced-document-actions__menu"
      >
        <div
          ref="actionDropdownRenameDocument"
          v-track="'hris_rename_document'"
          class="enhanced-document-actions__menu-item"
          @click="$emit('rename-document', $event)"
        >
          <PencilV2Icon
            fill="#000000"
            class="enhanced-document-actions__menu-item__icon"
          />
          {{ $t('employees.tabs.documents.rename_document.button') }}
        </div>
        <div
          v-if="canSendEmail"
          ref="actionDropdownSendEmail"
          v-modal="`document-send-email-modal-${document.id}`"
          v-tooltip="emptyMail"
          v-track="'hris_send_document_document-tab_send_by_mail'"
          :class="sendEmailClasses"
        >
          <EnvelopeIcon
            :fill="hasUserEmail ? '#000000' : '#C3C7CA'"
            class="enhanced-document-actions__menu-item__icon"
          />
          {{ $t('employees.tabs.documents.document_send_email') }}
          <MountingPortal
            v-if="hasUserEmail"
            mount-to="#modals-portal"
            append
          >
            <document-send-email-modal
              :tracking-options="trackingOptions"
              :document="document"
            />
          </MountingPortal>
        </div>
        <template v-if="sendEnabled">
          <div
            v-if="docSignatureAlreadySent"
            v-tooltip.left="getTooltipResendSignature"
            :class="reSendSignatureClass"
            @click="handleClickResendSignature(document)"
          >
            <PaperAirplaneIcon
              :fill="canResendSignature ? '#000000' : '#C3C7CA'"
              class="enhanced-document-actions__menu-item__icon"
            />
            <span class="enhanced-document-actions__menu-item-text">
              {{ $t('employees.tabs.documents.esginature.document_resend_esignature') }}
            </span>
          </div>
          <div
            v-else
            ref="actionDropdownSendSignature"
            v-tooltip.left="getTooltipSignature"
            :class="sendSignatureClass"
            @click="handleClickSendSignature(document)"
          >
            <PaperAirplaneIcon
              :fill="sendSignatureEnabled ? '#000000' : '#C3C7CA'"
              class="enhanced-document-actions__menu-item__icon"
            />
            <span class="enhanced-document-actions__menu-item-text">
              {{ $t('employees.tabs.documents.esginature.document_send_esignature') }}
            </span>
          </div>
        </template>

        <div
          v-if="isFeatureDocumentsFoldersEnabled"
          ref="actionDropdownMoveDocument"
          v-modal="`move-document-modal-${document.id}`"
          v-track="'hris_move_document'"
          class="enhanced-document-actions__menu-item"
        >
          <MoveItemIcon
            fill="#000000"
            class="enhanced-document-actions__menu-item__icon"
          />
          {{ $t('employees.tabs.documents.move_document') }}
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <move-document-modal :document-id="document.id" />
          </MountingPortal>
        </div>
        <div
          ref="actionDropdownDeleteDocument"
          class="enhanced-document-actions__menu-item"
          @click="$emit('delete-document', $event)"
        >
          <TrashCanV2Icon
            width="15"
            height="16"
            fill="#d03e50"
            class="enhanced-document-actions__menu-item__icon"
          />
          {{ $t('employees.tabs.documents.delete_document') }}
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import { FEATURES } from '@app-js/shared/constants/features.js';
import { httpClient } from '@skello-utils/clients';
import DocumentSendEmailModal from './DocumentSendEmailModal';
import MoveDocumentModal from './MoveDocumentModal';

export default {
  name: 'EnhancedActionsDropdown',
  components: { MoveDocumentModal, DocumentSendEmailModal },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldForceShow: false,
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('currentShop', ['hasShopDocumentTemplatesAccess', 'isDevFlagEnabled', 'isEsignatureAdministrativeDocsEnabled']),

    isInAllLocations() {
      return this.currentShop.id === 'all';
    },
    isEsignatureDocumentFeatureMaintenance() {
      return this.isDevFlagEnabled('FEATUREDEV_DOCS_ESIGNATURE_MAINTENANCE');
    },
    canSendEmail() {
      return this.hasShopDocumentTemplatesAccess(this.employee.attributes.shopId) &&
        ['application/pdf', 'pdf'].includes(this.document.attributes.format);
    },
    sendEnabled() {
      return this.isEsignatureAdministrativeDocsEnabled();
    },
    canResendSignature() {
      // send && resend are only available through specific shop
      return !this.isInAllLocations &&
        this.isEsignatureAdministrativeDocsEnabled() &&
        this.isPartiallySignedOrRequestSent &&
        this.hasUserEmail;
    },
    isPartiallySignedOrRequestSent() {
      const { status } = this.document.attributes;
      return ['partiallySigned', 'signatureRequestSent'].includes(status);
    },
    docSignatureAlreadySent() {
      const { status } = this.document.attributes;
      return !!status && status !== 'cancelled';
    },
    sendSignatureEnabled() {
      return !this.isInAllLocations &&
        this.hasUserEmail &&
        ['application/pdf', 'pdf'].includes(this.document.attributes.format) &&
        this.isEsignatureAdministrativeDocsEnabled() &&
        !this.docSignatureAlreadySent;
    },
    getTooltipSignature() {
      if (this.isEsignatureDocumentFeatureMaintenance) {
        return this.$t('employees.signature_followup.maintenance');
      }

      if (this.docSignatureAlreadySent) {
        return this.$t('employees.tabs.documents.esginature.already_sent');
      }

      if (this.isInAllLocations) {
        return this.$t('employees.tabs.documents.esginature.in_all_locations');
      }

      if (!this.isEsignatureAdministrativeDocsEnabled()) {
        return this.$t('employees.tabs.documents.esginature.no_enabled_esignature');
      }

      if (!['application/pdf', 'pdf'].includes(this.document.attributes.format)) {
        return this.$t('employees.tabs.documents.esginature.no_pdf_format');
      }

      if (!this.hasUserEmail) {
        return this.emptyMail;
      }

      return '';
    },
    getTooltipResendSignature() {
      if (this.isEsignatureDocumentFeatureMaintenance) {
        return this.$t('employees.signature_followup.maintenance');
      }

      if (!this.isEsignatureAdministrativeDocsEnabled()) {
        return this.$t('employees.tabs.documents.esginature.no_enabled_esignature');
      }

      if (!this.hasUserEmail) {
        return this.emptyMail;
      }

      if (this.isInAllLocations) {
        return this.$t('employees.tabs.documents.esginature.in_all_locations');
      }

      if (!this.canResendSignature && this.docSignatureAlreadySent) {
        return this.$t('employees.tabs.documents.esginature.already_sent');
      }

      return '';
    },
    hasUserEmail() {
      return this.employee.attributes.email;
    },
    emptyMail() {
      return this.hasUserEmail ?
        '' :
        this.$t('employees.tabs.documents.user_has_no_mail');
    },
    sendSignatureClass() {
      return {
        'enhanced-document-actions__menu-item': true,
        'enhanced-document-actions__menu-item--disabled': !this.sendSignatureEnabled || this.isEsignatureDocumentFeatureMaintenance,
      };
    },
    reSendSignatureClass() {
      return {
        'enhanced-document-actions__menu-item': true,
        'enhanced-document-actions__menu-item--disabled': !this.canResendSignature || this.isEsignatureDocumentFeatureMaintenance,
      };
    },
    sendEmailClasses() {
      return {
        'enhanced-document-actions__menu-item': true,
        'enhanced-document-actions__menu-item--disabled': !this.hasUserEmail,
      };
    },
    trackingOptions() {
      return {
        submit: 'hris_send_document_document-tab_validate',
        cancel: 'hris_send_document_document-tab_cancel',
      };
    },
    isFeatureDocumentsFoldersEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_DOCUMENTS_FOLDERS,
        this.employee.attributes.shopId,
      );
    },
  },
  methods: {
    ...mapActions('textDocumentTemplates', ['selectEsignatureDocument']),
    ...mapActions('employees', ['fetchDocumentsEsignature']),
    toggleForceShowValue() {
      this.shouldForceShow = !this.shouldForceShow;
    },
    handleClickSendSignature(document) {
      if (!this.sendSignatureEnabled || this.isEsignatureDocumentFeatureMaintenance) return;

      this.$skAnalytics.track('esignature_documents_employee_document_space_request_signature');
      this.selectEsignatureDocument(document);
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'sign-document-modal');
    },
    handleClickResendSignature(document) {
      if (!this.canResendSignature || this.isEsignatureDocumentFeatureMaintenance) return;
      this.resendSignatureRequest(document);
    },
    resendSignatureRequest(document) {
      this.$skAnalytics.track('esignature_documents_resend_employee_space');
      const documentOwnerId = document.attributes.userId;
      const params = {
        document_id: +document.id,
        organisation_id: this.currentOrganisation.id,
        user_id: +documentOwnerId,
        shop_id: this.currentShop.id,
      };

      httpClient
        .post('/v3/api/request_esignatures/resend_document_esignature', params)
        .then(() => this.$skToast({
          message: this.$t('employees.tabs.documents.esginature.resend.success'),
          variant: 'success',
        }))
        .catch(() => this.$skToast({
          message: this.$t('employees.tabs.documents.esginature.resend.error'),
          variant: 'error',
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.enhanced-document-actions__menu {
  width: 180px;
  background: white;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .16);
}

.enhanced-document-actions__menu-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 10px;
  }

  &:hover {
    background: $sk-grey-5;
  }
}

.enhanced-document-actions__menu-item--disabled {
  color: #c3c7ca;
  cursor: not-allowed;
}
</style>
