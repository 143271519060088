<template>
  <SkModal
    id="update-email-modal"
    ref="updateEmailModal"
    :modal-title="$t('self_serve.update_email_modal.title')"
    :submit-button-label="$t('self_serve.update_email_modal.submit_button')"
    @show="email = value.attributes.email"
    @cancel="reset"
    @close="reset"
  >
    <template #title-icon>
      <div class="update-email-modal__medallion">
        <EnvelopeDotIcon />
      </div>
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
        :padding-bottom="false"
      >
        {{ $t('self_serve.update_email_modal.body1') }}
        <strong>{{ $t('self_serve.update_email_modal.body2') }}</strong>
        {{ $t('self_serve.update_email_modal.body3') }}
      </SkModalSection>
      <SkModalSection
        border-bottom="none"
        :padding-bottom="false"
      >
        <div class="update-email-modal__input-container">
          <div class="update-email-modal__input-title">
            {{ $t('self_serve.update_email_modal.email_title') }}
          </div>
          <SkOroraInput
            v-model="email"
            class="update-email-modal__input"
            :errored="emailError"
            :hint-message="emailErrorMessage"
            :debounce="validateEmail"
            :debounce-ms="1000"
            type="email"
            @input="onInput"
          />
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <div class="update-email-modal__submit-button">
        <SkOroraButton
          :loading="loading"
          :disabled="submitDisabled"
          @click="handleSubmit"
        >
          {{ $t('self_serve.update_email_modal.submit_button') }}
        </SkOroraButton>
      </div>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal,
  SkModalSection,
  SkOroraInput,
  SkOroraButton,
} from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { isValidEmail } from '@skello-utils/validators';
import EnvelopeDotIcon from '../../shared/assets/svg/EnvelopeDotIcon';

export default {
  name: 'UpdateEmailModal',
  components: {
    SkModal,
    SkModalSection,
    SkOroraInput,
    SkOroraButton,
    EnvelopeDotIcon,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: null,
      emailError: false,
      loading: false,
      isMailAlreadyTaken: false,
    };
  },
  computed: {
    emailErrorMessage() {
      if (!this.emailError) return null;

      const errorMessages = {
        true: 'self_serve.step_one.errors.mail_already_taken_label',
        false: 'self_serve.step_one.errors.mail',
      };

      const errorMessageKey = errorMessages[this.isMailAlreadyTaken];
      return this.$t(errorMessageKey);
    },
    isEmailErrored() {
      return !isValidEmail(this.email) || this.isMailAlreadyTaken;
    },
    submitDisabled() {
      return !this.email || this.isEmailErrored;
    },
  },
  methods: {
    validateEmail() {
      this.emailError = this.email && this.isEmailErrored;
    },
    reset() {
      this.isMailAlreadyTaken = false;
      this.emailError = false;
      this.loading = false;
    },
    onInput() {
      this.isMailAlreadyTaken = false;
    },
    handleSubmit() {
      if (!isValidEmail(this.email)) return;
      this.loading = true;
      httpClient
        .patch(`/v3/api/users/${this.value.id}/self_serve/update_email`, {
          token: this.$route.query.token,
          email: this.email,
        })
        .then(response => {
          const { token, email } = response.data.data.attributes;

          this.$router.replace({
            name: 'step_two',
            query: { token },
          });

          this.$emit('input', { ...this.value, attributes: { ...this.value.attributes, email } });
          this.reset();
          this.$refs.updateEmailModal.hide();

          this.$skToast({
            message: this.$t('self_serve.step_two.toasts.email_updated'),
            variant: 'success',
            containerId: 'step-two__wrapper',
            dimension: 'long',
          });
        })
        .catch(error => {
          if (error.response.data.message === 'mail_already_taken') {
            [this.isMailAlreadyTaken, this.emailError] = [true, true];
            return;
          }

          this.$skToast({
            message: this.$t('self_serve.errors.generic'),
            variant: 'error',
            containerId: 'step-two__wrapper',
            dimension: 'long',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* DS handles modal with fixed width
 here we needs some flexibility in order
 to handle mobile responsiveness */
#update-email-modal {
  :deep(.sk-modal__header>.sk-modal__progress-bar-container:not(:has(p))) {
    margin-inline: 4px;
  }

  ::v-deep .sk-modal__dialog.sk-modal__dialog--medium {
    @media (max-width: $md-width) {
      width: 100%;
    }
  }

  ::v-deep footer.sk-modal__footer {
    @media (max-width: $sm-width) {
      margin-top: 30px;
      flex-direction: column-reverse;
      position: relative;
    }
  }

  ::v-deep .sk-modal__footer.scrolling {
    @media (max-width: $sm-width) {
      border: none;
      box-shadow: none;
    }
  }

  ::v-deep .sk-modal__body-wrapper {
    @media (max-width: $sm-width) {
      overflow-x: unset;
      overflow-y: unset;
      font-size: $fs-text-l;
    }
  }

  /* Cancel button design for mobile */
  ::v-deep .sk-button--outlined {
    @media (max-width: $sm-width) {
      width: -webkit-fill-available;
      margin-left: 0 !important;
      display: block;
      margin-top: 10px;
      border: none;
      font-size: $fs-text-l;
    }
  }

  /* Submit button design for mobile */
  ::v-deep .sk-button--filled {
    @media (max-width: $sm-width) {
      width: -webkit-fill-available;
      margin-left: 0 !important;
      display: block;
      height: 35px;
      font-size: $fs-text-l;
    }
  }

  @media (max-width: $sm-width) {
    padding: 30px 20px;
    display: initial;
  }

  ::v-deep .sk-modal {
    @media (max-width: $sm-width) {
      max-width: initial;
    }
  }
}

.update-email-modal__input-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: $sm-width) {
    flex-direction: column;
  }
}

.update-email-modal__input {
  @media (max-width: $sm-width) {
    margin-top: 10px;
  }
}

.update-email-modal__input-title {
  font-weight: $fw-semi-bold;
}

.update-email-modal__submit-button {
  margin-left: 10px;

  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
}

.update-email-modal__medallion {
  background-color: $sk-grey-10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  border-radius: 32px;
}
</style>
