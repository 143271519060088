<script>
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import { uniq } from '@skello-utils/collection';
import skDate from '@skello-utils/dates';
import GenericDropdownItem from './GenericDropdownItem';

export default {
  name: 'LockAction',
  extends: GenericDropdownItem,
  data() {
    return {
      isLockLoading: false,
    };
  },
  computed: {
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('planningsState', [
      'isWeeklyView',
      'isDailyView',
      'currentDate',
      'monday',
      'sunday',
      'isOnlyOneDayUnlocked',
    ]),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek']),
    ...mapGetters('planningsLoading', ['isLoadingInitialData', 'isProgressiveLoadingEnabled']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsState', ['weeklyOptions', 'undoRedoLoading']),
    ...mapState('planningsShifts', ['usersWithWeeklyBlockingAlert']),
    ...mapState('currentShop', ['currentShop']),

    title() {
      return this.isLocked ?
        this.$t('plannings.toolbar.actions_bar.icons_labels.unlock.label') :
        this.$t('plannings.toolbar.actions_bar.icons_labels.lock.label');
    },
    icon() {
      return this.isLocked ? 'OpenLockV2Icon' : 'LockV2Icon';
    },
    visibleIn() {
      return 'toolbar';
    },
    periodScope() {
      return this.isDailyView ? 'day' : 'week';
    },
    dayIndex() {
      return skDate.utc(this.currentDate).dayIndex();
    },
    isDayValidated() {
      return this.weeklyOptions.attributes.validatedDays[this.dayIndex];
    },
    isDayIntermediateLocked() {
      return this.weeklyOptions.attributes.intermediateLockedDays[this.dayIndex];
    },
    isDayPermanentLocked() {
      return this.weeklyOptions.attributes.permanentLockedDays[this.dayIndex];
    },
    isDayLocked() {
      return this.isDayValidated || this.isDayIntermediateLocked || this.isDayPermanentLocked;
    },
    isWeekValidated() {
      return this.weeklyOptions.attributes.validatedDays.every(day => !!day);
    },
    isWeekIntermediateLocked() {
      return this.weeklyOptions.attributes.intermediateLockedDays.every(day => !!day);
    },
    isWeekPermanentLocked() {
      return this.weeklyOptions.attributes.permanentLockedDays.every(day => !!day);
    },
    isWeekLocked() {
      return this.isWeekValidated || this.isWeekPermanentLocked || this.isWeekIntermediateLocked;
    },
    isLocked() {
      return this.isWeekLocked || (this.isDailyView && this.isDayLocked);
    },
    isLockDisabled() {
      if (
        this.isWeekPermanentLocked ||
        (this.isDailyView && this.isDayPermanentLocked) ||
        (this.isWeeklyView &&
          this.isLoadingInitialData &&
          this.isProgressiveLoadingEnabled
        )) {
        return true; // lock is disabled in this case since it should do nothing if clicked on it.
      }
      return !this.currentLicense.attributes.canTmplockPlanning;
    },
    disabled() {
      return this.isLockLoading || this.isLockDisabled;
    },
    isVisible() {
      return true;
    },
  },
  methods: {
    ...mapActions('planningsState', ['validatePeriod']),
    ...mapMutations('planningsShifts', ['setUsersWithWeeklyBlockingAlert']),

    handler(event) {
      this.isLockLoading = true;
      if (
        this.isWeekPermanentLocked ||
        this.undoRedoLoading
      ) {
        this.isLockLoading = false;
        return;
      }

      const validation =
        (!this.isDailyView && !this.isWeekValidated) || (this.isDailyView && !this.isDayValidated);
      const analyticsKey = validation ? `lock_${this.periodScope}` : `unlock_${this.periodScope}`;
      this.$skAnalytics.track(analyticsKey);

      // Only system administrator can intermediate unvalidate
      // Other users can do "unlock request"
      if (!this.isSystemAdmin && !validation) {
        if (!this.isDailyView && this.isWeekIntermediateLocked) {
          this.emitOnRoot(MODAL_SHOW_EVENT, event, `unlock-request-modal-week-${this.monday}`);
          this.isLockLoading = false;
          return;
        }
        if (this.isDailyView && this.isDayIntermediateLocked) {
          this.emitOnRoot(MODAL_SHOW_EVENT, event, `unlock-request-modal-day-${this.currentDate}`);
          this.isLockLoading = false;
          return;
        }
      }

      // Behind this condition there are 3 different cases:
      // 1. Are we locking or unlocking? (if unlocking stop here)
      // 2. Are we locking the whole week or just one day? If locking from daily view it's just
      //    one day, if it's from weekly view it's the whole week. If locking the whole week we
      //    need to trigger the alert.
      // 3. If locking one day, is this the last day to be locked on the week? If it is,
      //    trigger the alert.
      if (validation && (!this.isDailyView || this.isOnlyOneDayUnlocked)) {
        // TODO : Factorise with code in matchFrontAlertsToShifts
        // for this edge case (alert: minimum_weekly_work_time)
        this.setUsersWithWeeklyBlockingAlert(uniq(
          this.shiftsForCurrentWeek
            .filter(shift => (
              this.hasShiftBlockingMinWeeklyWorkingTimeAlert(shift) &&
              !this.getUserById(shift.attributes.userId).attributes.onExtra
            ))
            .map(shift => shift.attributes.userId),
        ));

        if (this.usersWithWeeklyBlockingAlert.length > 0) {
          this.emitOnRoot(MODAL_SHOW_EVENT, null, 'blocking-alert-for-week-lock-modal');
          this.isLockLoading = false;
          return;
        }
      }

      const isIntermediateLocked =
        (!this.isDailyView && this.isWeekIntermediateLocked) ||
        (this.isDailyView && this.isDayIntermediateLocked);
      this.validatePeriod({
        startDate: this.isDailyView ? this.currentDate : this.monday,
        endDate: this.isDailyView ? this.currentDate : this.sunday,
        currentDate: this.currentDate,
        shopId: this.currentShop.id,
        validationLevel: isIntermediateLocked ? 'intermediate_locked_days' : 'validated_days',
        validationValue: validation,
        dayLock: !this.isDayIntermediateLocked,
      }).then(() => {
        this.$skToast({
          message: this.validationToastMessage(validation, 'success', this.periodScope),
          variant: 'success',
        });
      }).catch(error => {
        this.$skToast({
          message: this.validationToastMessage(validation, 'error', this.periodScope),
          variant: 'error',
        });
      }).finally(() => {
        this.isLockLoading = false;
      });
    },
    validationToastMessage(validation, variant, scope) {
      if (validation) {
        return this.$t(`plannings.table.header.actions.${scope}_validation.validate.${variant}`);
      }
      return this.$t(`plannings.table.header.actions.${scope}_validation.unvalidate.${variant}`);
    },
    getUserById(userId) {
      return this.users.find(user => parseInt(user.id, 10) === userId);
    },
    hasShiftBlockingMinWeeklyWorkingTimeAlert(shift) {
      return shift.relationships.alerts &&
        shift.relationships.alerts.some(alert => (
          alert.attributes.blocking && alert.attributes.name === 'minimum_weekly_work_time'
        ));
    },
  },
};
</script>
