<template>
  <!--
    Clones
    app/javascript/src/v3/app/organisation_settings/pages/OrganisationShops/ShopForm/BillingStep.vue
    If you need to make a change make it to both
  -->
  <div class="col-md-4 offset-md-4 mx-auto organisation-setting__page-content">
    <div class="organisation-setting__header">
      <h1 class="sk-header--1">
        {{ $t('organisation_creation.billing_info.title') }}
      </h1>
    </div>
    <div class="organisation-setting__subtitle">
      <p class="sk-subtitle--large">
        {{ $t('organisation_creation.billing_info.subtitle') }}
      </p>
    </div>
    <h3 class="sk-header--3 billing-info__header">
      {{ $t('organisation_creation.billing_info.form_title') }}
    </h3>

    <StripeBox />

    <div class="billing-info__iban">
      <SkInput
        v-model.trim="billingInfo.iban"
        :errored="errors.iban"
        :error-message="ibanErrorMessage"
        :label="$t('organisation_creation.billing_form.iban')"
        :debounce="validateIban"
        :debounce-ms="500"
        :disabled="disabledIban"
      />
    </div>

    <div class="billing-info__billing-email">
      <SkInput
        v-model.trim="billingInfo.email"
        :errored="errors.email"
        :error-message="billingEmailErrorMessage"
        :label="$t('organisation_creation.billing_form.billing_email')"
        :debounce="validateEmail"
        :debounce-ms="500"
      >
        <template #icon>
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('organisation_creation.billing_form.tooltip.billing_email')"
            class="organisation-setting__helper"
          />
        </template>
      </SkInput>
    </div>

    <div class="row-separator" />
    <h3 class="sk-header--3">
      {{ $t('organisation_creation.billing_info.billing_form_title') }}
    </h3>

    <div class="billing-form__billing-address">
      <div class="row">
        <div class="col-10">
          {{ $t('organisation_creation.billing_form.same_billing_address') }}
        </div>
        <div class="col-2">
          <SkSwitch
            v-model="sameAddressAsBilling"
            :label="$t('organisation_creation.billing_form.same_billing_address')"
            class="billing-form__switch"
          />
        </div>
      </div>
      <div v-if="sameAddressAsBilling">
        <div class="billing-form__social-denomination">
          {{ currentShop.attributes.denominationSociale }}
        </div>
        <div class="billing-form__address">
          {{ billingAddress }}
        </div>
        <div>
          {{ serializedCity }}
        </div>
      </div>
      <div v-else>
        <SkInput
          v-model="billingAddress"
          :label="$t('organisation_creation.shop_form.address_2')"
          :error-message="$t('organisation_creation.error.mandatory')"
          :errored="errors.address"
          @keyup="validateMandatoryField('address')"
        />
        <div class="billing-form__city row">
          <div class="col">
            <SkInput
              v-model="billingZipcode"
              :label="$t('organisation_creation.shop_form.zip_code')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.zipcode"
              @keyup="validateMandatoryField('zipcode')"
            />
          </div>
          <div class="col">
            <SkInput
              v-model="billingCity"
              :label="$t('organisation_creation.shop_form.city')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.city"
              @keyup="validateMandatoryField('city')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <SkSelectV2
              v-model="billingCountry"
              :options="countries"
              :label="$t('organisation_creation.shop_form.country')"
              :no-results-label="$t('search_bar.no_result')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row billing-form__legal-section">
      <div class="col">
        <div>
          {{ $t("organisation_creation.billing_form.legal_infos_1") }}
        </div>
        <div class="billing-form__legal-info">
          {{ $t("organisation_creation.billing_form.legal_infos_2") }}
        </div>
      </div>
    </div>
    <div class="row billing-form__navigation-buttons">
      <div class="col">
        <div
          class="billing-form__button billing-form__blank_button"
        >
          <SkOroraButton
            variant="tertiary"
            @click="goBack"
          >
            {{ $t('organisation_creation.shop_form.back') }}
          </SkOroraButton>
        </div>
      </div>
      <div class="col">
        <div class="billing-form__navigation-buttons__submit-wrapper">
          <SkOroraButton
            :disabled="disabledSubmit"
            :loading="loading"
            class="billing-form__button"
            @click="submitForm"
          >
            {{ $t('organisation_creation.shop_form.finish') }}
          </SkOroraButton>
        </div>
      </div>
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <CongratulationModal
        ref="congratulationModal"
        @new-shop="handleNewShopCreation"
      />
    </MountingPortal>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';
import IBAN from 'iban';

import {
  SkInput,
  SkSwitch,
  SkOroraButton,
  SkSelectV2,
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
  CircledQuestionMarkIcon,
} from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { isValidEmail } from '@skello-utils/validators';
import { arrayToSelectOptions } from '@skello-utils/form';
import {
  countryKeys,
  languageToCountryCode,
} from '@skello-utils/country-keys.js';

import CongratulationModal from '../shared/components/CongratulationModal';
import StripeBox from '../shared/components/StripeBox';

export default {
  name: 'ShopBillingForm',
  components: {
    StripeBox,
    SkInput,
    SkSwitch,
    SkOroraButton,
    SkSelectV2,
    CircledQuestionMarkIcon,
    CongratulationModal,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.currentShop.attributes.configured) {
      this.headingBackHandler();
      next();
    }
    // If your shop is configured we prevent going back
    next(false);
  },
  data() {
    return {
      loading: false,
      sameAddressAsBilling: true,
      billingEmailErrorMessage: '',
      ibanErrorMessage: '',
      disabledIban: false,
      errors: {
        address: false,
        city: false,
        zipcode: false,
        email: false,
        iban: false,
      },
      billingInfo: {
        iban: '',
        email: '',
        address: '',
        city: '',
        country: null,
        zipcode: '',
      },
    };
  },
  computed: {
    ...mapState('onboarding', ['currentShop', 'currentUser', 'currentOrganisation', 'organisationCreation', 'originalShopData']),

    serializedCity() {
      return `${this.billingZipcode} ${this.billingCity}, ${this.$t(`countries.${this.billingCountry}`)}`;
    },
    billingAddress: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.currentShop.attributes.address;
        }

        return this.billingInfo.address;
      },
      set(newValue) {
        this.billingInfo.address = newValue;
      },
    },

    billingZipcode: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.currentShop.attributes.zipcode;
        }
        return this.billingInfo.zipcode;
      },
      set(newValue) {
        this.billingInfo.zipcode = newValue;
      },
    },

    billingCity: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.currentShop.attributes.city;
        }
        return this.billingInfo.city;
      },
      set(newValue) {
        this.billingInfo.city = newValue;
      },
    },

    billingCountry: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.currentShop.attributes.country;
        }
        return this.billingInfo.country ? this.billingInfo.country : this.defaultUserCountryCode;
      },
      set(newValue) {
        this.billingInfo.country = newValue;
      },
    },
    defaultUserCountryCode() {
      return languageToCountryCode(this.currentUser.attributes.lang);
    },
    countries() {
      const countryList = countryKeys(this.defaultUserCountryCode);
      return arrayToSelectOptions(countryList, value => this.$t(`countries.${value}`));
    },
    isAddressValid() {
      if (this.sameAddressAsBilling) return true;

      return this.billingAddress && this.billingCity &&
        this.billingCountry && this.billingZipcode;
    },
    isFormValid() {
      return this.isAddressValid && this.billingInfo.iban &&
        this.billingInfo.email && !this.errors.email &&
        !this.errors.iban;
    },
    disabledSubmit() {
      return !this.isFormValid;
    },
  },
  watch: {
    currentShop(newValue, oldValue) {
      if (oldValue.attributes.configured !== newValue.attributes.configured &&
        newValue.attributes.configured) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'congratulation-modal');
      }
    },
  },
  mounted() {
    this.billingInfo.email = this.currentUser.attributes.email;
  },
  methods: {
    ...mapMutations('onboarding', [
      'updateShopStep',
      'updateOrganisationStep',
      'resetCurrentShop',
    ]),
    ...mapActions('onboarding', ['updateShop', 'updateOrganisation', 'updateProspect']),

    goBack() {
      this.$router.push({ name: 'shop_form' });
    },

    submitForm() {
      this.loading = true;

      // handle source on redirection ticket, add source global settings
      this.$skAnalytics.track('account_creation_billing_submit', { source: 'account creation' });

      const params = {
        shop_id: this.currentShop.id,
        billing_infos: {
          email: this.billingInfo.email,
          address: this.billingAddress,
          zipcode: this.billingZipcode,
          city: this.billingCity,
          country: this.billingCountry,
        },
        bank_info: {
          iban: this.billingInfo.iban,
        },
      };

      httpClient.post('/v3/api/billing_infos', params)
        .then(() => {
          Promise.all([
            this.updateShopToConfigured(),
            this.updateProspect({
              prospect: {
                step: 'registration_fully_completed',
              },
            }),
            this.updateOrganisation({
              id: this.currentOrganisation.id,
              shop_id: this.originalShopData.id,
              organisation: {
                status: 'active',
              },
            }),
          ])
            .catch(() => {
              this.$skToast({
                message: this.$t('organisation_creation.error.generic'),
                variant: 'error',
              });
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(error => {
          const errorMessage = error.response?.data?.message;
          const isIBANError = error.response?.status === 422 && errorMessage.includes('IBAN');
          const toastMessage = isIBANError ?
            this.$t('organisation_creation.error.iban') : this.$t('organisation_creation.error.generic');
          this.$skToast({
            message: toastMessage,
            variant: 'error',
          });
          this.loading = false;
        });
    },
    handleNewShopCreation(event) {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, this.$refs.congratulationModal.$el.id);

      this.resetCurrentShop();

      window.location = '/v3/settings/shops/new#onboarding';
    },
    updateShopToConfigured() {
      const params = {
        id: this.currentShop.id,
        shop: {
          configured: true,
        },
      };

      return this.updateShop(params);
    },
    validateIban() {
      this.ibanErrorMessage = null;

      if (!this.billingInfo.iban) {
        this.ibanErrorMessage = this.$t('organisation_creation.error.mandatory');
      } else if (!IBAN.isValid(this.billingInfo.iban)) {
        this.ibanErrorMessage = this.$t('organisation_creation.billing_form.error.iban');
      }
      this.errors.iban = !!this.ibanErrorMessage;
    },
    validateMandatoryField(attribute) {
      if (attribute === 'iban') {
        this.validateIban();
      } else if (attribute === 'email') {
        this.validateEmail();
      } else {
        this.errors[attribute] = !this.billingInfo[attribute];
      }
    },
    validateEmail() {
      this.billingEmailErrorMessage = null;

      if (!this.billingInfo.email) {
        this.billingEmailErrorMessage = this.$t('organisation_creation.error.mandatory');
      } else if (!isValidEmail(this.billingInfo.email)) {
        this.billingEmailErrorMessage = this.$t('organisation_creation.billing_form.error.email');
      }

      this.errors.email = !!this.billingEmailErrorMessage;
    },
    headingBackHandler() {
      const step = this.organisationCreation.shopCreationCurrentStep === 'new_shop_billing_form' ?
        'new_shop_form' :
        'shop_form';

      this.updateShopStep(step);
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-form__billing-address {
  width: 100%;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 30px;
}

.organisation-setting__page-content {
  margin-top: 40px;
  max-width: 650px;
}

.organisation-setting__header {
  margin-bottom: 9px;
}

.organisation-setting__subtitle {
  max-width: 370px;
  margin: auto;
}

.billing-info__iban {
  margin-top: 30px;
  width: 100%;
}

.billing-info__billing-email {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 50%;
}

.billing-info__header {
  padding-top: 40px;
  padding-bottom: 15px;
}

.billing-form__social-denomination {
  font-weight: $fw-semi-bold;
}

.billing-form__address,
.billing-form__social-denomination {
  width: 100%;
}

.billing-form__button {
  margin-top: 40px;
}

.billing-form__blank_button {
  text-align: left;
  margin-left: -8px;

  ::v-deep .sk-button--primary:not([disabled]).sk-button--blank {
    outline: none;
    color: $sk-grey;

    &:hover {
      opacity: .8;
      background: none;
    }
  }
}

.billing-form__navigation-buttons__submit-wrapper {
  display: flex;
  justify-content: flex-end;
}

.billing-form__legal-section {
  color: $sk-grey;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: $fs-text-s;
  line-height: 22px;
}

.billing-form__navigation-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}

.billing-form__legal-info {
  margin-top: 15px;
}

.billing-form__switch {
  display: inline-block;
}

.billing-form__city {
  margin-top: 15px;
  margin-bottom: 15px;
}

.billing-form__input-error {
  padding-bottom: 10px;
}
</style>
