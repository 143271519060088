<template>
  <SkOroraDialog
    id="cegedim-activation-modal"
    ref="cegedimActivationModal"
    class="cegedim-activation-modal"
    :next-step-button-label="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.next')"
    :step-count="landOnCredentialsStep ? 1 : 2"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isSubmitting"
    :submit-button-label="$t('organisation_settings.tabs.pay_partners.cta')"
    :title="modalTitle"
    is-artwork
    size="medium"
    @cancel="handleClose"
    @close="handleClose"
    @update-step="handleUpdateStep"
    @show="resetData"
    @submit.prevent="handleSubmit"
  >
    <template #headerImage>
      <div class="cegedim-activation-modal__header-icon sk-flex-center">
        <Cegedim
          height="70"
          width="70"
        />
      </div>
    </template>
    <template #body>
      <main
        v-if="isInformationStep"
        class="cegedim-activation-modal__content"
      >
        <section class="cegedim-activation-modal__card-container">
          <article class="cegedim-activation-modal__card">
            <section class="cegedim-activation-modal__card-description">
              <strong class="sk-text-large-semibold">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.reassurance_step.cards.employee_info.header') }}
              </strong>
              <p class="cegedim-activation-modal--text sk-text-medium-regular">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.reassurance_step.cards.employee_info.description') }}
              </p>
            </section>
            <CegedimEmployeeInfo />
          </article>
          <hr class="cegedim-activation-modal__card-divider">
          <article class="cegedim-activation-modal__card">
            <section class="cegedim-activation-modal__card-description">
              <header class="cegedim-activation-modal__evp-header">
                <strong class="sk-text-large-semibold">
                  {{ $t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.reassurance_step.cards.evp_export.header') }}
                </strong>
              </header>
              <p class="cegedim-activation-modal--text sk-text-medium-regular">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.reassurance_step.cards.evp_export.description') }}
              </p>
            </section>
            <CegedimEvpExport />
          </article>
        </section>
      </main>
      <main
        v-else-if="isKeysFillingStep"
        class="cegedim-activation-modal__content"
      >
        <section class="cegedim-activation-modal--description">
          <p class="cegedim-activation-modal--text sk-text-medium-regular">
            {{ $t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.configuration_step.description') }}
          </p>
        </section>
        <section class="cegedim-activation-modal__inputs-container">
          <div class="cegedim-activation-modal__input-row">
            <label
              class="sk-text-medium-semibold"
              for="cegedim-client-login"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.cegedim.labels.identification') }}
            </label>
            <SkInput
              v-model="clientLogin"
              input-id="cegedim-client-login"
              :disabled="isSubmitting"
              :placeholder="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.cegedim.placeholder.identification')"
            />
          </div>
          <div class="cegedim-activation-modal__input-row">
            <label
              class="sk-text-medium-semibold"
              for="cegedim-client-password"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.cegedim.labels.password') }}
            </label>
            <SkInput
              v-model="clientPassword"
              input-id="cegedim-client-password"
              type="password"
              :disabled="isSubmitting"
              :placeholder="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.cegedim.placeholder.password')"
            />
          </div>
          <div class="cegedim-activation-modal__input-row">
            <label
              class="sk-text-medium-semibold"
              for="cegedim-client-code"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.cegedim.labels.client_code') }}
            </label>
            <SkInput
              v-model="clientCode"
              input-id="cegedim-client-code"
              :disabled="isSubmitting"
              :placeholder="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.cegedim.placeholder.client_code')"
            />
          </div>
        </section>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import {
  Cegedim,
  CegedimEmployeeInfo,
  CegedimEvpExport,
} from '../../shared/icons';

export default {
  name: 'CegedimActivationModal',
  components: {
    Cegedim,
    CegedimEmployeeInfo,
    CegedimEvpExport,
  },
  props: {
    landOnCredentialsStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientLogin: null,
      clientPassword: null,
      clientCode: null,
      currentStep: 1,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters('currentUser', ['fullName', 'initials']),
    ...mapGetters('organisationCredentials', [
      'isIntegrationEnabled',
      'isIntegrationPending',
      'isCegedimEvpExportEnabled',
    ]),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('organisationCredentials', ['totalShopsCount']),
    isInformationStep() {
      return this.currentStep === 1 && !this.landOnCredentialsStep;
    },
    isKeysFillingStep() {
      return this.landOnCredentialsStep || this.currentStep === 2;
    },
    modalTitle() {
      if (this.isInformationStep) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.reassurance_step.header');
      }

      return this.$t('organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.configuration_step.header');
    },
    isSubmitDisabled() {
      if (this.currentStep === 2 || this.landOnCredentialsStep) {
        return !this.clientLogin || !this.clientPassword || !this.clientCode;
      }

      return false;
    },
  },
  methods: {
    ...mapActions('organisationCredentials', ['createOrganisationCredential', 'updateOrganisationCredential']),
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    handleUpdateStep(nextStep) {
      if (this.currentStep === 1 && nextStep === 2) {
        this.$skAnalytics.track('fortify_cegedim_activation_modal_next');
      }

      this.currentStep = nextStep;
    },
    handleClose(event) {
      if (this.currentStep !== 2) {
        this.$refs.cegedimActivationModal.hide();
        return;
      }

      if (
        !this.clientLogin && !this.clientPassword && !this.clientCode
      ) {
        return;
      }

      event.preventDefault();

      const baseKey = 'organisation_settings.tabs.pay_partners.fortify.cegedim.activation_modal.confirm.keys';

      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        cancelLabel: this.$t(`${baseKey}.cancel`),
        description: this.$t(`${baseKey}.description`),
        onConfirm: this.$refs.cegedimActivationModal.hide,
        submitLabel: this.$t(`${baseKey}.submit`),
        title: this.$t(`${baseKey}.title`),
      });
    },
    async handleSubmit() {
      this.isSubmitting = true;

      if (this.landOnCredentialsStep) {
        this.$skAnalytics.track('fortify_cegedim_keys_update');
      }

      const isIntegrationPending = this.isIntegrationEnabled('CEGEDIM') && this.isIntegrationPending('CEGEDIM');

      const params = { name: 'CEGEDIM' };

      if (isIntegrationPending) {
        this.$skAnalytics.track('fortify_cegedim_activation_modal_configuration');

        params.status = 'active';
        params.value = {
          clientLogin: this.clientLogin,
          clientPassword: this.clientPassword,
          clientCode: this.clientCode,
        };
      } else {
        params.status = 'pending';
      }

      try {
        const promises = [];

        promises.push(this.landOnCredentialsStep ?
          this.updateOrganisationCredential(params) :
          this.createOrganisationCredential(params),
        );

        await Promise.all(promises);

        this.$skToast({
          message: this.$t('organisation_settings.tabs.pay_partners.fortify.cegedim.connection.success'),
          variant: 'success',
        });

        this.$refs.cegedimActivationModal.hide();
        this.$refs.cegedimActivationModal.currentStep = 1;
        this.$emit('submit');
      } catch (error) {
        this.$skToast({ message: this.$t('organisation_settings.tabs.pay_partners.enable.organisation.failure'), variant: 'error' });

        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// remove global boostrap styles
p {
    margin: 0;
}

hr {
    border: none;
}

.cegedim-activation-modal {
  &__header-icon {
  position: absolute;
  border: 1px solid $sk-grey-10;
  border-radius: 20px 20px 20px 20px;
  background-color: $sk-white;
  height: 104px;
  width: 104px;
  top: 24px;
  left: 24px;

  img {
      display: inline-block;
  }
  }

  &--text {
  color: $sk-grey-50;
  }

  &--description {
  margin-bottom: 24px;
  }

  &--divider {
  background-color: $sk-grey-10;
  height: 1px;
  width: 100%;
  border: none;
  margin: 0;
  }
}

.cegedim-activation-modal__content {
  display: flex;
  flex-direction: column;
  margin: 8px 24px;

  a {
  margin-bottom: 24px;
  }

  &--activation-header {
  margin-bottom: 24px;
  }

  &--configuration-step-header {
  margin-bottom: 16px;
  }
}

.cegedim-activation-modal__email {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border: 1px solid $sk-grey-10;
  border-radius: 8px;
}

.cegedim-activation-modal__sender {
  display: flex;
}

.cegedim-activation-modal__sender-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  gap: 2px;

  span:last-child {
  color: $sk-grey-50;
  }
}

.cegedim-activation-modal__card {
    display: flex;
    flex-direction: column;
    width: 255.5px;
}

.cegedim-activation-modal__card-description {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
}

.cegedim-activation-modal__evp-header {
  display: flex;
  justify-content: space-between;
}

.cegedim-activation-modal__card-container {
  display: flex;
}

.cegedim-activation-modal__card-divider {
  background-color: $sk-grey-10;
  border-radius: 1px;
  height: auto;
  margin: 0 32px;
  width: 1px;
}

.cegedim-activation-modal__inputs-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cegedim-activation-modal__input-row {
  display: flex;
  align-items: center;

  label {
  width: 146px;
  margin-right: 16px;
  flex-shrink: 0;
  margin-bottom: .5rem;
  display: inline-block;
  }
}
</style>
