import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import skDate from '@skello-utils/dates';
import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
  ABSENCE_TYPE_HOURS,
  SHIFT_MATCHING_TIME_WINDOW,
} from '@app-js/shared/constants/shift';
import { groupShifts } from '@app-js/badgings/shared/utils';

export default class DayShiftBadgingMatcher {
  // To call with active_users, shifts and badgings of the day
  constructor(users, shifts, badgings, shouldGroupShifts) {
    this.users = users;
    this.matchedShiftsIds = [];
    this.shifts = shifts;
    this.badgings = badgings;
    this.shouldGroupShifts = shouldGroupShifts;
  }

  run() {
    if (this.duplicateBadgingsArePresent()) {
      this.removeDuplicateBadgings(this.badgings);
    }
    this.users.forEach(user => {
      const badgings = sortBy(this.badgings.filter(badging => badging.user_id === parseInt(user.id, 10)), ['attributes.in']);
      const shifts = sortBy(this.shifts.filter(shift => shift.attributes.userId === parseInt(user.id, 10)), ['attributes.startsAt']);

      badgings.forEach((badging, index) => {
        this.associateByTime(badging, shifts, index);
      });
    });
  }

  associateByTime(badging, shifts, index) {
    if (!shifts) {
      this.logData(badging, shifts, index);
      return;
    }

    let found = false;
    let shiftsCollection = shifts;

    if (this.shouldGroupShifts) {
      const predictedShifts = shifts.filter(
        shift => !shift.attributes.previsionalSaved ||
          (shift.attributes.previsionalSaved && shift.attributes.previsionalStart),
      );

      const unpredictedShifts = shifts.filter(
        shift => shift.attributes.previsionalSaved && !shift.attributes.previsionalStart,
      );

      shiftsCollection = groupShifts(sortBy(predictedShifts, 'attributes.startsAt'));

      shiftsCollection = shiftsCollection.concat(unpredictedShifts);
    }

    const nbShifts = shiftsCollection.length;

    // Try to assign badge to schedules
    found = this.foundBadgeByTime(badging, shiftsCollection, nbShifts, false);

    // If not found schedule try to assign to absences
    if (!found) {
      found = this.foundBadgeByTime(badging, shiftsCollection, nbShifts, true);
    }

    if (!found) {
      badging.shift = null;
    }
  }

  foundBadgeByTime(badging, shiftsCollection, nbShifts, assignAbsences) {
    let i = 0;
    let found = false;
    while (!found && i < nbShifts) {
      const shift = shiftsCollection[i];
      let filterAbsence = ![ABSENCE_TYPE_DAY, ABSENCE_TYPE_HALF_DAY, ABSENCE_TYPE_HOURS].includes(
        shift.attributes.absenceCalculation);
      if (assignAbsences) filterAbsence = !filterAbsence;
      if (shift &&
        filterAbsence &&
        this.inShiftHours(shift, badging) &&
        !this.matchedShiftsIds.includes(shift.id)
      ) {
        this.matchedShiftsIds.push(shift.id);
        badging.shift = shift;
        found = true;
      }

      i += 1;
    }
    return found;
  }

  inShiftHours(shift, badging) {
    const startsAt = shift.attributes.previsionalSaved && shift.attributes.previsionalStart ?
      skDate(shift.attributes.previsionalStart) :
      skDate(shift.attributes.startsAt);

    return skDate(badging.in).isSameOrAfter(skDate(startsAt).subtract(SHIFT_MATCHING_TIME_WINDOW, 'hour')) &&
      skDate(badging.in).isSameOrBefore(skDate(startsAt).add(SHIFT_MATCHING_TIME_WINDOW, 'hour'));
  }

  duplicateBadgingsArePresent() {
    const pluckedBadgings = this.badgings.map(badging => ({
      user_id: badging.user_id,
      in: skDate(badging.in).format('YYYY-MM-DDTHH:mm:00Z'),
    }));
    const uniquedBySecond = uniqWith(pluckedBadgings, isEqual);
    return pluckedBadgings.length !== uniquedBySecond.length;
  }

  removeDuplicateBadgings(badgings) {
    const orderedBadgings = new Map();
    badgings.forEach(badging => {
      const str = `${badging.user_id}--${skDate(badging.in).format('YYYY-MM-DDTHH:mm:00Z')}`;
      if (orderedBadgings.has(str)) {
        orderedBadgings.get(str).push(badging);
      } else {
        orderedBadgings.set(str, [badging]);
      }
    });

    orderedBadgings.forEach(badgingsArray => {
      if (badgingsArray.length <= 1) return;
      const badgingToDestroy = this.badgingToDestroy(badgingsArray);
      this.badgings = this.badgings.filter(badging => badging.id !== badgingToDestroy.id);
    });
  }

  badgingToDestroy(badgings) {
    // If no out or pauses - return it
    // If no out - return it (even if there are pauses)
    // then if one is closed by backend -- return it
    // Else just return the first one
    const emptyBadging = badgings.find(b => !b.out && (!b.pauses || b.pauses.length === 0));
    if (emptyBadging) return emptyBadging;

    const noOutBadging = badgings.find(badging => !badging.out);
    if (noOutBadging) return noOutBadging;

    const closedByBacked = badgings.find(badging => badging.closed_by_backend);
    if (closedByBacked) return closedByBacked;

    return badgings[0];
  }

  logData(badging, shifts, index) {
    /* eslint-disable no-console */
    const minIndex = Math.max(index - 2, 0);
    const maxIndex = index + 2;
    const range = [];
    for (let i = minIndex; i <= maxIndex; i++) { range.push(i); }
    let separator = '';
    for (let i = 0; i < 100; i++) { separator += '-'; }

    const tag = '[DayShiftBadgingMatcher][associate_by_time]';
    console.log(separator);
    console.log('');
    console.info(`${tag}[badging]${badging}`);
    console.log('\n');
    console.info(`${tag}[shifts]${shifts}`);
    console.log('\n');
    console.info(`${tag}[index]${index}`);
    console.log('\n');
    console.info(`${tag}[minIndex]${minIndex}`);
    console.log('\n');
    console.info(`${tag}[maxIndex]${maxIndex}`);
    console.log('\n');
    console.info(`${tag}[range]${range}`);
    console.log('');
    console.log(separator);
  }
}
