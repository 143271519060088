<template>
  <div :class="onboardingModalClasses">
    <div class="onboarding-modal__preloaded-backgrounds" />
    <LaunchStep
      v-if="currentStep === 'launch'"
      @next="setCurrentStep('profiling')"
    />
    <ProfilingStep
      v-if="currentStep === 'profiling'"
      :is-internal-testing="isInternalTesting"
      @next="setCurrentStep('convention')"
    />
    <ConventionStep
      v-if="currentStep === 'convention'"
      @next="setCurrentStep('llm')"
    />
    <PlanningImportStep
      v-if="currentStep === 'llm'"
      @next="setCurrentStep('employees', $event)"
      @document-updated="onDocumentUpdated($event)"
    />
    <EmployeeStep
      v-if="currentStep === 'employees'"
      :imported-employees="employeesFromWebsocket"
      :created-employees="localEmployees"
      :can-go-back="displaysPlanningImportStep"
      @update-employees="handleUpdateEmployees"
      @previous="setCurrentStep('llm')"
    />
    <PositionStep
      v-if="currentStep === 'positions'"
      :imported-positions="positionsFromWebsocket"
      :created-positions="localPositions"
      :can-go-back="displaysEmployeesStep"
      @update-positions="handleUpdatePositions"
      @next="setCurrentStep('loading')"
      @previous="setCurrentStep('employees')"
    />
    <LoadingStep
      v-if="currentStep === 'loading'"
      :is-loading-done="isLoadingDone"
      :displays-employees-step="displaysEmployeesStep"
      :displays-positions-step="displaysPositionsStep"
      @redirect-to-planning="redirectToPlanning(true)"
    />
  </div>
</template>

<script>
import {
  mapMutations, mapGetters, mapState, mapActions,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features';
import { websocketApiGatewayUrl } from '@config/env';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import WebsocketClient from '@skello-utils/websocket_client/websocket_client';
import {
  QuestionScope,
  LLM_ENDING_ENTITY_NAME,
  CRITICAL_ERROR_ENTITY_NAME,
  StopReason,
} from '@skelloapp/svc-intelligence-sdk';
import { createShiftApiRequest } from '@skello-store/modules/plannings/api/shift';
import LaunchStep from './components/LaunchStep';
import ProfilingStep from './components/ProfilingStep';
import ConventionStep from './components/ConventionStep';
import PlanningImportStep from './components/PlanningImportStep';
import EmployeeStep from './components/EmployeeStep';
import PositionStep from './components/PositionStep';
import LoadingStep from './components/LoadingStep';

const TIMEOUT_WEBSOCKET_MS = 300000;

export default {
  name: 'AdminOnboarding',
  components: {
    LaunchStep,
    ProfilingStep,
    ConventionStep,
    PlanningImportStep,
    EmployeeStep,
    PositionStep,
    LoadingStep,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.isUserRedirectedToPlanning) {
        vm.redirectToPlanning();
      } else {
        vm.setNavbarVisibility(false);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.formCompleted) {
      next(false);
    } else {
      this.setNavbarVisibility(true);
      next();
    }
  },
  data() {
    return {
      isInternalTesting: false, // Method to help archi-team to test in production
      currentStep: '',
      localEmployees: [],
      localPositions: [],
      createdEmployees: [],
      createdPositions: [],
      websocketClient: null,
      positionsFromWebsocket: [],
      employeesFromWebsocket: [],
      shiftsFromWebsocket: [],
      isEmployeeStepDone: false,
      websocketPositionsReceived: false,
      llmEndingReceived: false,
      timeoutID: null,
      licenses: [],
      onboardingWithLLM: false,
      creatingShifts: false,
      formCompleted: false,
      isLoadingDone: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('currentUser', ['isSystemAdmin']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('config', ['hourlyRateCounterType', 'permanentContractTypes']),

    isUserRedirectedToPlanning() {
      return !this.isSystemAdmin;
    },
    hasPlanningUsers() {
      return this.currentShop.attributes.hasPlanningUsers;
    },
    hasPositions() {
      return this.currentShop.attributes.postesCount > 0;
    },
    displaysProfilingStep() {
      return this.isInternalTesting || !this.currentUser.attributes.isOnboardingEmployeeFilled;
    },
    displaysPlanningImportStep() {
      return this.isInternalTesting || (!this.hasPlanningUsers && !this.hasPositions);
    },
    displaysEmployeesStep() {
      return this.isInternalTesting || !this.hasPlanningUsers;
    },
    canCreatePoste() {
      return this.isFeatureEnabled(FEATURES.FEATURE_CREATE_POSITION, this.currentShop.id);
    },
    displaysPositionsStep() {
      return this.isInternalTesting || (!this.hasPositions && this.canCreatePoste);
    },
    onboardingModalClasses() {
      return {
        'onboarding-modal__wrapper': true,
        [`onboarding-modal__wrapper--background-${this.currentStep}`]: true,
      };
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeUnload);
  },
  mounted() {
    this.isInternalTesting = this.$route.query.showOnboardingModal === 'true' &&
      this.currentUser.attributes.impersonate;

    const currentShopId = this.$router.currentRoute.params.shop_id;
    if (this.currentShop.id !== currentShopId) {
      this.updateCurrentShop({ shopId: currentShopId });
    }

    this.fetchLicenses();

    this.setCurrentStep('launch');
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop', 'updateShop']),
    ...mapActions('shopPostes', ['bulkCreateShopPoste']),
    ...mapMutations(['setNavbarVisibility']),
    ...mapMutations('currentShop', ['updateOnboardingStatus']),

    fetchLicenses() {
      const params = { include_deactivated: true };
      httpClient
        .get('/v3/api/licenses', { params })
        .then(response => {
          this.licenses =
            response.data.data.sort((a, b) => a.attributes.position - b.attributes.position);
        });
    },
    onDocumentUpdated(documentId) {
      const websocketBaseUrl = websocketApiGatewayUrl;
      if (!websocketBaseUrl) {
        console.error('Missing the websocket URL');

        return;
      }

      this.onboardingWithLLM = true;
      this.websocketClient = new WebsocketClient(`${websocketBaseUrl}/?type=Generic&uuid=${documentId}`);

      // Add a maxium waiting time to receive data from the websocket
      // after this delay, user can be redirected to the planning without waiting
      this.timeoutID = setTimeout(this.handleLlmEnding, TIMEOUT_WEBSOCKET_MS);

      this.websocketClient.connect(event => {
        let data;
        try {
          data = JSON.parse(event.data).data;
        } catch (e) {
          console.error('An error occurred while fetching websocket', e);

          return;
        }

        if (data.type === CRITICAL_ERROR_ENTITY_NAME) {
          this.handleCriticalError();
          return;
        }

        if (data.type === LLM_ENDING_ENTITY_NAME) {
          this.handleLlmEnding();
          return;
        }

        /**
           *
          {
            "uuid": "19855366-de01-433f-9adb-61b9d4488790",
            "data": {
              "output": "[{\"position\":\"cuisine\"},{\"position\":\"salle\"}]",
              "type": "POSITIONS"
            }
          }
           */
        if (data.type === QuestionScope.POSITIONS) {
          this.handlePositionsWebsocket(JSON.parse(data.output));
          return;
        }
        /**
           *
          {
            "uuid": "19855366-de01-433f-9adb-61b9d4488790",
            "data": {
              "output": "[{\"lastName\":\"TOUSSAINT\",\"firstName\":\"Agnès\"}]",
              "type": "EMPLOYEE_NAMES"
            }
          }
           */
        if (data.type === QuestionScope.EMPLOYEE_NAMES) {
          this.handleEmployeesWebsocket(JSON.parse(data.output));
          return;
        }

        /**
           *
          {
            "uuid": "19855366-de01-433f-9adb-61b9d4488790",
            "data": {
              "output": "{\"name\":\"Mathis Aubry\",\"days\":[{\"date\":\"2024-01-15\",\"startHour\":\"10:30\",\"endHour\":\"15:00\",\"pause\":\"\",\"poste\":\"Cuisine\"},{\"date\":\"2024-01-16\",\"startHour\":\"22:30\",\"endHour\":\"05:00\",\"pause\":\"\",\"poste\":\"Cuisine\"},{\"date\":\"2024-01-17\",\"startHour\":\"10:30\",\"endHour\":\"15:00\",\"pause\":\"\",\"poste\":\"Cuisine\"},{\"date\":\"2024-01-18\",\"startHour\":\"10:30\",\"endHour\":\"15:00\",\"pause\":\"\",\"poste\":\"Cuisine\"},{\"date\":\"2024-01-19\",\"startHour\":\"10:30\",\"endHour\":\"15:00\",\"pause\":\"\",\"poste\":\"Cuisine\"}]}",
              "stopReason": "END",
              "type": "SHIFTS"
            }
          }
           */
        if (data.type === QuestionScope.SHIFTS) {
          if (data.stopReason === StopReason.END) {
            this.handleShiftsWebsocket(JSON.parse(data.output));
            return;
          }

          console.error('Shifts event stops with a reason:', data.output);
        }
      });
    },
    handleEmployeesStepNext() {
      this.isEmployeeStepDone = true;
      this.setCurrentStep('positions');
    },
    handlePositionsWebsocket(positions) {
      this.websocketPositionsReceived = true;
      this.positionsFromWebsocket = positions.map(position => position.position);
      if (this.isEmployeeStepDone) {
        this.setCurrentStep('positions');
      }
    },
    handleEmployeesWebsocket(employees) {
      this.employeesFromWebsocket = employees;
      this.setCurrentStep('employees');
    },
    handleShiftsWebsocket(shiftsEvent) {
      shiftsEvent.days.forEach(shift => {
        this.shiftsFromWebsocket.push({
          ...shift,
          name: shiftsEvent.name,
        });
      });
    },
    async createShiftsFromWebsocket() {
      let employee;
      let position;

      const shiftsToCreate = this.shiftsFromWebsocket.map(shiftFromWebsocket => {
        employee = this.createdEmployees.find(e => `${e.old?.firstName} ${e.old?.lastName}` === shiftFromWebsocket.name);
        if (!employee) {
          return false;
        }
        position = this.createdPositions
          .find(p => p.old?.position === shiftFromWebsocket.poste);
        if (!position) {
          return false;
        }
        const date = shiftFromWebsocket.date ? this.getSameDayOfWeek(skDate(shiftFromWebsocket.date)).format('YYYY-MM-DD') : shiftFromWebsocket.date;

        // to handle cross day shift
        const endsAt = new Date(`${date}T${shiftFromWebsocket.startHour}:00.000Z`) > new Date(`${date}T${shiftFromWebsocket.endHour}:00.000Z`) ?
          skDate(`${date}T${shiftFromWebsocket.endHour}:00.000Z`).add(1, 'days').toISOString() :
          skDate(`${date}T${shiftFromWebsocket.endHour}:00.000Z`).toISOString();

        return {
          id: null,
          attributes: {
            startsAt: skDate(`${date}T${shiftFromWebsocket.startHour}:00.000Z`).toISOString(),
            endsAt,
            userId: employee.id,
            shopId: this.currentShop.id,
            nbMeal: 0,
            pauseTime: 0,
            dayAbsence: false,
            absenceCalculation: '',
            absenceDurationInSeconds: 0,
            delay: 0,
            showStartTime: true,
            showEndTime: true,
            showDuration: true,
          },
          relationships: {
            poste: {
              id: position.id,
            },
          },
        };
      }).filter(Boolean);

      await createShiftApiRequest({
        periodStartsAt: skDate().startOf('week').format('YYYY-MM-DD'),
        periodEndsAt: skDate().endOf('week').format('YYYY-MM-DD'),
        shopId: this.currentShop.id,
        performLater: false,
        shifts: shiftsToCreate,
      });

      this.creatingShifts = true;
    },
    handleLlmEnding() {
      this.llmEndingReceived = true;

      // we can receive few events after receiving the LlmEnding (race condition on websocket)
      // so waiting 2000ms is enought to make sure we received everything
      setTimeout(() => {
        if (this.currentStep === 'loading') {
          this.updateCurrentShop({ shopId: this.currentShop.id }).then(async () => {
            try {
              await this.createShiftsFromWebsocket();
            } catch (error) {
              console.error('Error while creating the shifts', error);
            } finally {
              this.formCompleted = true;
              window.removeEventListener('beforeunload', this.beforeUnload);
              if (this.websocketClient) this.websocketClient.disconnect();
              clearTimeout(this.timeoutID);
              this.isLoadingDone = true;
            }
          });
        }
      }, 2000);
    },
    handleCriticalError() {
      this.websocketClient.disconnect();
      this.websocketClient = null;

      if (this.currentStep === 'llm') {
        this.emitOnRoot('critical-error-received');
        return;
      }

      this.isLoadingDone = true;

      if (this.currentStep === 'loading') {
        this.setCurrentStep(this.currentStep);
      }
    },
    redirectToPlanning(redirectToCurrentWeek) {
      this.setNavbarVisibility(true);

      const shopId = this.$router.currentRoute.params.shop_id;
      const shopIdToRedirect = (shopId === 'all' || shopId === undefined) ? this.currentUser.shopId : shopId;

      const redirectMessage = this.creatingShifts ?
        this.$t('onboarding_modal.admin_onboarding.shifts_created') :
        this.$t('onboarding_modal.admin_onboarding.employees_and_positions');

      sessionStorage.setItem('adminOnboardingMessage', redirectMessage);

      const params = {
        name: 'plannings_weeks',
        params: { shop_id: shopIdToRedirect },
        ...(redirectToCurrentWeek ? { query: { date: skDate().format('YYYY-MM-DD') } } : {}),
      };
      this.$router.push(params);
    },
    setCurrentStep(nextStep, options) {
      switch (nextStep) {
        case 'launch':
          this.currentStep = 'launch';
          break;
        case 'profiling':
          if (this.displaysProfilingStep) {
            this.currentStep = 'profiling';
          } else {
            this.setCurrentStep('convention');
          }
          break;
        case 'convention':
          this.currentStep = 'convention';
          break;
        case 'llm':
          if (this.displaysPlanningImportStep && !options?.skip) {
            // reset in case of going back
            if (this.websocketClient) this.websocketClient.disconnect();
            this.localEmployees = [];
            this.localPositions = [];
            this.shiftsFromWebsocket = [];
            this.isEmployeeStepDone = false;
            this.currentStep = 'llm';
          } else {
            this.setCurrentStep('employees');
          }
          break;
        case 'employees':
          if (options?.skip && this.websocketClient) {
            this.websocketClient.disconnect();
            this.websocketClient = null;
            this.employeesFromWebsocket = [];
            this.positionsFromWebsocket = [];
          }

          if (this.displaysEmployeesStep) {
            this.currentStep = 'employees';
          } else {
            this.setCurrentStep('positions');
          }
          break;
        case 'positions':
          if (this.websocketClient && !this.websocketPositionsReceived) {
            return;
          }
          if (this.displaysPositionsStep) {
            this.currentStep = 'positions';
          } else {
            this.setCurrentStep('loading');
          }
          break;
        case 'loading':
          this.currentStep = 'loading';
          this.handleLoadingStep();
          break;
        default:
          break;
      }
    },
    async handleLoadingStep() {
      await this.createPositionsAndEmployees();

      if (!this.onboardingWithLLM || (this.onboardingWithLLM && !this.websocketClient)) {
        this.setNewOnboardingStatus('filled', true);
        this.formCompleted = true;
        window.removeEventListener('beforeunload', this.beforeUnload);
        this.$skAnalytics.track('onboarding_redirect_user_to_schedule');
        this.isLoadingDone = true;
      }

      if (this.llmEndingReceived) {
        this.handleLlmEnding();
      }
    },
    getSameDayOfWeek(date) {
      const currentDate = skDate();
      const targetDayOfWeek = date.isoWeekday();

      return currentDate.isoWeekday(targetDayOfWeek);
    },
    async setNewOnboardingStatus(key, isFilled) {
      try {
        this.updateOnboardingStatus({ [key]: isFilled });
        await this.updateShop({ shopId: this.currentShop.id });
      } catch (e) {
        this.updateOnboardingStatus({ [key]: !isFilled });
        console.error(`Error while update ${key}`, e);
      }
    },
    async createPositionsAndEmployees() {
      await Promise.all([
        this.createEmployees(),
        this.createPositions(),
      ]);
    },
    async createEmployees() {
      if (this.isInternalTesting) {
        this.done();
        return;
      }

      try {
        const responses =
          await Promise.all(
            this.localEmployees
              .filter(employee => employee.firstName && employee.lastName)
              .map(async employee => this.createEmployee(employee)),
          );

        responses.forEach(response => {
          const employee = this.localEmployees.find(e => e.firstName + e.lastName ===
            response.data.data.attributes.firstName + response.data.data.attributes.lastName);
          employee.id = Number(response.data.data.id);
        });

        this.createdEmployees = this.localEmployees;
        this.setNewOnboardingStatus('employees_filled', true);
      } catch (e) {
        console.error('Error while creating positions or updating employees_filled', e);
      }
    },
    async createEmployee(employee) {
      let contractTypeId = null;
      const contractTypesCountries =
        this.permanentContractTypes.map(contractType => contractType.country);

      if (contractTypesCountries.includes(this.currentShop.attributes.country)) {
        contractTypeId = this.permanentContractTypes.find(
          contractType => contractType.country === this.currentShop.attributes.country,
        ).id;
      } else {
        contractTypeId = this.permanentContractTypes.find(contractType => contractType.country === 'FR').id;
      }

      const params = {
        shop_id: this.currentShop.id,
        personal_info: {
          first_name: employee.firstName,
          last_name: employee.lastName,
          email: employee.email,
          shop_id: this.currentShop.id,
        },
        contract: {
          contract_hours: 0,
          contract_type_id: contractTypeId,
          counter_type: this.hourlyRateCounterType,
        },
        associations: {
          user_licenses: [{
            id: null,
            license_id: this.licenses[this.licenses.length - 1].id,
            cluster_node_id: this.currentShop.attributes.clusterNodeId,
          }],
          team_memberships: [],
        },
        memberships: [{
          in_planning: true,
          shop_id: this.currentShop.id,
        }],
        send_invitation: false,
      };

      return httpClient.post('/v3/api/users', params);
    },
    handleUpdateEmployees(employees) {
      this.localEmployees = employees;
      this.handleEmployeesStepNext();
    },
    handleUpdatePositions(positions) {
      this.localPositions = positions;
    },
    async createPositions() {
      if (this.isInternalTesting || !this.canCreatePoste) {
        this.done();
        return;
      }

      try {
        const positionsToCreate = this.localPositions
          .filter(position => position.isSelected)
          .map(position => ({ name: position.label }));

        const response = await this.bulkCreateShopPoste({
          shopId: this.currentShop.id,
          shopPostes: positionsToCreate,
        });

        this.createdPositions =
          this.localPositions
            .filter(position => position.isSelected)
            .map(position => {
              const matchingCreatedPosition = response.data.data.find(
                createdPosition => createdPosition.attributes.name === position.label,
              );

              return {
                id: matchingCreatedPosition.id,
                old: { position: position.oldPosition },
              };
            });

        this.setNewOnboardingStatus('positions_filled', true);
      } catch (e) {
        console.error('Error while creating positions or updating positions_filled', e);
      }
    },
    done() {
      this.$emit('next');
    },
    beforeUnload(event) {
      if (this.formCompleted) return;

      // eslint-disable-next-line no-alert
      const confirm = window.confirm();
      if (!confirm) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$background-loading-1: url('../shared/assets/png/orora_gradient_1.png');
$background-loading-2: url('../shared/assets/png/orora_gradient_2.png');
$background-loading-3: url('../shared/assets/png/orora_gradient_3.png');
$background-loading-4: url('../shared/assets/png/orora_gradient_4.png');
$background-launch: url('../shared/assets/png/orora_gradient_launch.png');
$background-positions: url('../shared/assets/png/orora_gradient_positions.png');
$background-employees: url('../shared/assets/png/orora_gradient_employees.png');
$background-llm: url('../shared/assets/png/orora_gradient_llm.png');
$background-profiling: url('../shared/assets/png/orora_gradient_profiling.png');

// Preload all backgrounds so there is no flickering when switching between them
.onboarding-modal__preloaded-backgrounds {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content:
    $background-loading-1
    $background-loading-2
    $background-loading-3
    $background-loading-4
    $background-launch
    $background-positions
    $background-employees
    $background-llm
    $background-profiling;
}

.onboarding-modal__wrapper {
  height: 100vh;

  &--background-positions {
    background: $background-positions no-repeat fixed;
    background-size: cover;
  }

  &--background-employees {
    background: $background-employees no-repeat fixed;
    background-size: cover;
  }

  &--background-llm {
    background: $background-llm no-repeat fixed;
    background-size: cover;
  }

  &--background-convention {
    background: $background-profiling no-repeat fixed;
    background-size: cover;
  }

  &--background-profiling {
    background: $background-profiling no-repeat fixed;
    background-size: cover;
  }

  &--background-launch {
    background: $background-launch no-repeat fixed;
    background-size: cover;
  }
}
</style>
