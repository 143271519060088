<template>
  <div
    class="suggested-company-registrations"
    :style="dropdownMenuStyle"
    @scroll="handleScroll"
    @scrollend="setIsScrollingThroughCompanyRegistrations(false)"
  >
    <div class="suggested-company-registrations__dropdown-menu">
      <SkLoader
        v-if="isLoading"
        class="suggested-company-registrations__loader"
      />
      <template v-else>
        <SuggestedCompanyRegistration
          v-for="company in suggestedCompanies"
          :key="company.establishmentRegistrationNumber"
          ref="dropdown"
          :company="company"
          @click.native="selectSuggestedCompany(company)"
        />
        <SiretManualInputLink
          class="suggested-company-registrations__siret-input-link"
          is-in-dropdown
          @manual-switch-click="$emit('manual-switch-click', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {
  mapMutations, mapState,
} from 'vuex';
import { SkLoader } from '@skelloapp/skello-ui';
import SuggestedCompanyRegistration from './SuggestedCompanyRegistration';
import SiretManualInputLink from '../SiretManualInputLink.vue';

export default {
  name: 'SuggestedCompanyRegistrations',
  components: {
    SkLoader,
    SuggestedCompanyRegistration,
    SiretManualInputLink,
  },
  props: {
    suggestedCompanies: {
      type: Array,
      required: true,
    },
    relatedInput: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixedMenuWidth: 0,
    };
  },
  computed: {
    ...mapState('onboarding', ['isScrollingThroughCompanyRegistrations']),

    dropdownMenuStyle() {
      return {
        'min-width': `${this.fixedMenuWidth}px`,
        'max-width': `${this.fixedMenuWidth}px`,
      };
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateMenuWidth);
    this.updateMenuWidth();
  },
  methods: {
    ...mapMutations('onboarding', ['setIsScrollingThroughCompanyRegistrations']),

    handleScroll() {
      if (!this.isScrollingThroughCompanyRegistrations) {
        this.setIsScrollingThroughCompanyRegistrations(true);
      }
    },
    selectSuggestedCompany(company) {
      this.$emit('select-suggested-company', company);
    },
    updateMenuWidth() {
      this.fixedMenuWidth = this.relatedInput.$el.offsetWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.suggested-company-registrations {
  overflow-y: auto;
  max-height: 260px;
  background-color: $sk-white;
  box-shadow: 0 8px 16px $sk-black-10;
  border-radius: 2px;

  &__dropdown-menu {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__loader {
    place-self: center;
    margin: 16px 0;
  }

  &__siret-input-link {
    padding: 10px 16px;
  }
}
</style>
