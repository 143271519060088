import Vue from 'vue';
import { svcDocumentV2Client } from '@skello-utils/clients';
import {
  chunkArray,
  MAX_IDS_PER_REQUEST,
} from '@skello-utils/batches';

export const MAX_NUMBER_OF_FILES = 6;
export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // 10MB
export const LOCAL_URL_TIMEOUT_SECONDS = 30;
export const LOCAL_URL_TIMEOUT = LOCAL_URL_TIMEOUT_SECONDS * 1000;
export const AUTHORIZED_FILE_TYPE = [
  'application/pdf', // .pdf
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/msword', // .doc
  'application/vnd.oasis.opendocument.text', // .odt
  'text/plain', // .txt
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.oasis.opendocument.spreadsheet', // ods
  'text/csv', // .csv
  'application/csv', // .csv
  'image/jpeg', // .jpg || .jpeg
  'image/png', // .png
  'image/gif', // .gif
  'image/bmp', // .bmp
  'image/tiff', // .tiff
  'audio/mpeg', // .mp3
  'audio/wav', // .wav
  'video/mp4', // .mp4
];
export const INLINE_FILE_TYPE = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'audio/mpeg',
  'audio/wav',
  'video/mp4',
];
export const ERROR_TYPES = {
  MAX_FILES_EXCEEDED: 'max_file_exceeded',
  FILE_TYPE: 'file_type',
  FILE_SIZE: 'file_size',
  FILE_DUPLICATED: 'file_duplicated',
  DOCUMENT_LOAD: 'fetch_documents',
  DOCUMENT_UPLOAD: 'upload_documents',
  DOCUMENT_DELETE: 'delete_documents',
  INVALID: 'invalid',
};

const initialState = {
  documents: [],
  documentsMetadata: [],
  documentsByShift: {},
  creatingAndUploading: false,
  loadingDocuments: false,
  error: null,
  uploadDocumentsQueue: [],
  deleteDocumentsQueue: [],
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loadingDocuments = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loadingDocuments = false;
    }
  },

  setError(state, error) {
    state.error = error;
  },

  setDocumentsMetadata(state, metadata) {
    state.documentsMetadata = metadata;
  },

  setDocumentsByShift(state, payload) {
    Object.keys(payload).forEach(shiftId => {
      if (!state.documentsByShift[shiftId]) {
        Vue.set(state.documentsByShift, shiftId, [...new Set(payload[shiftId])]);
      } else {
        state.documentsByShift[shiftId] = [
          ...new Set(state.documentsByShift[shiftId].concat(payload[shiftId])),
        ];
      }
    });
  },

  deleteDocumentsSuccess(state, payload) {
    // Update documents
    state.documents = state.documents.filter(doc => !payload.includes(doc.id));

    // Update documentsByShift
    Object.keys(state.documentsByShift).forEach(shiftId => {
      state.documentsByShift[shiftId] =
        state.documentsByShift[shiftId].filter(docId => !payload.includes(docId));
      if (state.documentsByShift[shiftId].length === 0) {
        Vue.delete(state.documentsByShift, shiftId);
      }
    });
  },

  setDocuments(state, payload) {
    state.documents = payload;
  },

  fetchDocumentsSuccess(state, payload) {
    state.documents = payload;
  },

  createDocumentsSuccess(state, payload) {
    state.documentsMetadata.push(payload);
  },

  resetDocuments(state) {
    state.documents = [];
    state.documentsMetadata = [];
    state.uploadDocumentsQueue = [];
    state.deleteDocumentsQueue = [];
  },

  resetDocumentsByShift(state) {
    state.documentsByShift = {};
  },

  addToUploadQueue(state, payload) {
    state.uploadDocumentsQueue.push(payload);
  },

  removeFromUploadQueue(state, fileName) {
    state.uploadDocumentsQueue = state.uploadDocumentsQueue
      .filter(doc => doc.file.name !== fileName);
  },

  addToDeleteQueue(state, id) {
    if (typeof id === 'string') {
      state.deleteDocumentsQueue.push(id);
    }
  },
};

const actions = {
  async fetchDocuments({ commit }, { shiftId, shopId }) {
    /*
     find: returns a list of documents for a shift
     findUrl: returns the url of a document based on document id
    */
    if (shiftId) {
      try {
        commit('performingRequest', 'loadingDocuments');

        const response = await svcDocumentV2Client.document.find({
          shiftId,
          shopId,
          recomputeShiftCount: true, // Passively fixes shift count inconsistencies. More info in PR#12633
        });
        commit('setDocumentsMetadata', response.documents);

        // This part can be separated to a different action, when clicking on the document link
        const documents = await Promise.all(
          response.documents.map(async doc => ({
            ...doc,
            url: await svcDocumentV2Client.document.getUrl(doc.id, INLINE_FILE_TYPE.includes(doc.mimeType) ? 'inline' : 'attachment'),
          })),
        );
        commit('fetchDocumentsSuccess', documents);
      } catch (error) {
        commit('setError', error);
        throw error;
      } finally {
        commit('requestComplete', 'loadingDocuments');
      }
    } else {
      commit('resetDocuments');
    }
  },
  async fetchDocumentsByShiftId({ commit }, { shiftIds, shopId }) {
    try {
      const shiftIdsChunked = chunkArray(shiftIds, MAX_IDS_PER_REQUEST);
      const chunkedResponse = await Promise.all(
        shiftIdsChunked.map((
          shiftIdsChunk => svcDocumentV2Client.document.findByShifts(shiftIdsChunk, shopId)
        )),
      );

      const shiftsDocumentsMerged = chunkedResponse.reduce((acc, response) => {
        const { shiftsDocuments } = response;
        Object.keys(shiftsDocuments).forEach(shiftId => {
          acc[shiftId] = shiftsDocuments[shiftId];
        });
        return acc;
      }, {});
      commit('setDocumentsByShift', shiftsDocumentsMerged);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async createAndUpload({ commit }, { dtos, files }) {
    try {
      commit('performingRequest', 'creatingAndUploading');

      const createDocumentsResponseDtos =
        await svcDocumentV2Client.document.createAndUpload(dtos, files);

      commit('setDocuments', createDocumentsResponseDtos);
      return createDocumentsResponseDtos;
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'creatingAndUploading');
    }
  },
  async createDocuments({ commit }, dtos) {
    try {
      commit('performingRequest', 'creatingAndUploading');

      const createDocumentsResponseDtos =
        await svcDocumentV2Client.document.create(dtos);

      commit('createDocumentsSuccess', createDocumentsResponseDtos);

      const documentsByShifts = createDocumentsResponseDtos.reduce((acc, dto) => ({
        ...acc,
        [dto.shiftId]: [...(acc[dto.shiftId] || []), dto.id],
      }), {});
      commit('setDocumentsByShift', documentsByShifts);

      return createDocumentsResponseDtos;
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'creatingAndUploading');
    }
  },
  async uploadDocuments({ commit }, { url, file }) {
    try {
      commit('performingRequest', 'creatingAndUploading');
      await svcDocumentV2Client.document.upload(url, file);
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'creatingAndUploading');
    }
  },

  async deleteDocuments({ commit, state }) {
    if (state.deleteDocumentsQueue.length === 0) {
      return Promise.resolve();
    }

    return svcDocumentV2Client.document.bulkDelete({
      documentIds: state.deleteDocumentsQueue,
      expiresInSeconds: 0,
    }).then(() => {
      commit('deleteDocumentsSuccess', state.deleteDocumentsQueue);
    }).catch(error => {
      commit('setError', error);
    });
  },
};

const getters = {
  isLoadingDocuments: state => state.loadingDocuments,
  documents: state => state.documents,
  getDocuments: state => shiftId => state.documents.filter(doc => doc.shiftId === shiftId),
  getDocumentsCountByShiftId: state => shiftId => state.documentsByShift[shiftId]?.length || 0,
  hasError: state => !!state.error,
  uploadDocumentsQueue: state => state.uploadDocumentsQueue,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
