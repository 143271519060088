<template>
  <!-- eslint-disable max-len -->
  <SkModal
    id="manageShiftNoteModal"
    ref="manageShiftNoteModal"
    :modal-title="$t('badgings.edit_note_modal.title', {name: userName})"
    :modal-subtitle="date.format('dddd Do MMMM YYYY')"
    @hidden="handleReset"
  >
    <template #title-icon>
      <SkMedallion
        icon="ClipboardIcon"
        color="#727272"
        background-color="#f3f3f3"
        size="medium"
      />
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
        :padding-bottom="false"
      >
        <ShiftActivitiesSection
          v-if="shift"
          :shift="shift"
          :disabled="anyLockOnCurrentDay"
          :hide-documents="true"
        />
      </SkModalSection>
    </template>
    <template
      v-if="anyLockOnCurrentDay"
      #footer
    >
      <SkOroraButton @click="handleHide">
        {{ $t('actions.close') }}
      </SkOroraButton>
    </template>
    <template
      v-else
      #footer
    >
      <template>
        <SkOroraButton
          :loading="eventManagingLoading"
          @click="handleSubmit"
        >
          {{ $t('actions.update') }}
        </SkOroraButton>
      </template>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';
import ShiftActivitiesSection from '@app-js/plannings/shared/components/ShiftActivities/ShiftActivitiesSection';

export default {
  name: 'ManageShiftNoteModal',
  components: { ShiftActivitiesSection },
  data() {
    return {
      shift: null,
      originalShift: null,
      date: skDate(),
    };
  },
  computed: {
    ...mapState('planningsState', ['eventManagingLoading', 'eventDeleteLoading']),
    ...mapGetters('badgings', ['anyLockOnCurrentDay']),

    userName() {
      if (!this.shift) return '';

      return `${this.shift.relationships.user.attributes.firstName} ${this.shift.relationships.user.attributes.lastName}`;
    },
    posteName() {
      if (!this.shift) return '';

      return this.shift.relationships.poste.attributes.name;
    },
  },
  created() {
    this.listenOnRoot('init-manage-shift-note-modal', (event, props) => {
      this.shift = structuredClone(props.shift);
      this.originalShift = structuredClone(props.shift);
      this.date = props.date;

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'manageShiftNoteModal');
    });
  },
  methods: {
    ...mapActions('planningsState', ['createEvent', 'updateEvent', 'destroyEvent']),
    ...mapActions('badgings', ['updateShiftActivities']),

    handleSubmit() {
      const updatedShift = structuredClone(this.shift);

      const shiftParams = {
        shopId: this.shift.attributes.shopId,
        shifts: [
          updatedShift,
        ],
        periodStartsAt: this.shift.attributes.startsAt,
        periodEndsAt: this.shift.attributes.endsAt,
      };

      this.updateShiftActivities({ params: shiftParams, originalShift: this.originalShift })
        .then(() => {
          this.handleHide();
          this.$skToast({
            message: this.$t('badgings.edit_note_modal.toaster_success'),
            variant: 'success',
          });
        })
        .catch(error => {
          const message = this.$t('badgings.edit_note_modal.toaster_failure');
          this.$skToast({
            message,
            variant: 'error',
          });
          throw error;
        });
    },
    handleHide() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'manageShiftNoteModal');
    },
    handleReset() {
      this.emitOnRoot('reset-tasks-manager');
    },
  },
};
</script>

<style lang="scss" scoped>
.manage_note_modal__label_container {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  margin-right: 100px;
}

.manage_note_modal__title {
  font-weight: $fw-semi-bold;
}

.manage_note_modal__subtitle {
  color: $sk-grey;
}

.manage_note_modal__body {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
}
</style>
