<template>
  <div>
    <h1 class="sk-header--1">
      {{ $t('organisation_settings.tabs.licenses.title') }}
    </h1>
    <p class="sk-subtitle--large organisation-licenses__subtitle">
      {{ $t('organisation_settings.tabs.licenses.subtitle_1') }}
      <br>
      {{ $t('organisation_settings.tabs.licenses.subtitle_2') }}
    </p>
    <div
      v-if="loading"
      class="organisation-licenses__container__spinner"
    >
      <SkLoader size="large" />
    </div>
    <div
      v-else
      class="organisation-licenses__cards-wrapper"
    >
      <LicenseCard
        v-for="(license, index) in filteredLicenses"
        :key="license.id"
        :index="index"
        :license="license"
        :data-test="license.id"
        @show-side-panel="showSidePanel"
        @update-license="handleUpdateLicense"
      />
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <LicenseSidePanel
        v-if="renderSidePanel"
        :license="editingLicense"
        @update-license="handleUpdateLicense"
        @cancel="resetData"
      />
    </MountingPortal>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import snakeCase from 'lodash/snakeCase';
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import { httpClient } from '@skello-utils/clients';

import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { captureException } from '@sentry/vue';
import { FEATURES } from '@app-js/shared/constants/features';
import LicenseCard from './LicenseCard';
import LicenseSidePanel from './LicenseSidePanel';

const objDiff = (source, target) => {
  const result = {};

  for (const property in source) {
    if (source[property] !== target[property]) {
      result[property] = { oldValue: target[property], newValue: source[property] };
    }
  }

  return result;
};

export default {
  name: 'LicensesAndActions',
  components: { LicenseCard, LicenseSidePanel },
  data() {
    return {
      loading: true,
      licenses: null,
      editingLicense: {},
      updatingLicenses: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('features', ['isFeatureEnabled']),
    renderSidePanel() {
      return Object.keys(this.editingLicense).length > 0;
    },
    filteredLicenses() {
      const licenseDependencies = {
        system_admin: null,
        advanced_master: null,
        standard_employee: null,
        standard_master: FEATURES.FEATURE_PLANNING_ACCESS,
        advanced_planner: FEATURES.FEATURE_PLANNING_ACCESS,
        standard_planner: FEATURES.FEATURE_PLANNING_ACCESS,
        advanced_read_only: FEATURES.FEATURE_PLANNING_ACCESS,
        standard_read_only: FEATURES.FEATURE_PLANNING_ACCESS,
        advanced_employee: FEATURES.FEATURE_PLANNING_ACCESS,
      };

      return this.licenses.filter(license => {
        const licenseType = license.attributes.originalType;
        if (licenseDependencies[licenseType] === null) return true;
        return this.isFeatureEnabled(licenseDependencies[licenseType], 'all');
      });
    },
  },
  mounted() {
    this.fetchLicenses();
  },
  methods: {
    ...mapActions('currentUser', ['fetchCurrentUserAccess']),
    resetData() {
      if (!this.updatingLicenses) this.fetchLicenses();
    },
    fetchLicenses() {
      const params = { include_deactivated: true };
      httpClient
        .get('/v3/api/licenses', { params })
        .then(response => {
          this.loading = false;
          this.licenses = orderBy(response.data.data, 'attributes.position');
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('organisation_settings.tabs.licenses.error_message'),
            variant: 'error',
          });
        });
    },
    showSidePanel(licenseToShow) {
      this.editingLicense = licenseToShow;
      this.editingLicense.index = this.licenses.findIndex(
        license => license.id === licenseToShow.id,
      );

      // double next tick otherwise the editingLicense is not set when the panel is shown
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.emitOnRoot(MODAL_SHOW_EVENT, null, 'license-side-panel');
        });
      });
    },
    handleUpdateLicense(licenseToUpdate) {
      this.updatingLicenses = true;
      const allActions = Object.values(licenseToUpdate.attributes.actions).flat();

      const params = {
        id: licenseToUpdate.id,
        include_deactivated: true,
        attributes: {
          active: licenseToUpdate.attributes.active,
        },
      };

      allActions.forEach(action => { params.attributes[action.name] = action.allowed; });

      httpClient
        .patch(`/v3/api/licenses/${licenseToUpdate.id}`, params)
        .then(response => {
          const licenseIndex = this.licenses.findIndex(license => license.id === licenseToUpdate.id,
          );

          const licenseToUpdateAttributesSnakeCase = Object.keys(licenseToUpdate.attributes).reduce(
            (acc, property) => (
              { ...acc, [snakeCase(property)]: licenseToUpdate.attributes[property] }
            ),
            {},
          );

          try {
            this.$svcEvents.create(EVENT_SUBTYPE_ACTION.COMPANY_LICENSE_UPDATE, {
              changes: objDiff(params.attributes, licenseToUpdateAttributesSnakeCase),
              licenseId: licenseToUpdate.id,
              licenseName: licenseToUpdate.attributes.name,
            });
          } catch (error) {
            captureException(error);
          }

          this.licenses.splice(licenseIndex, 1, response.data.data);
          this.$skToast({
            message: this.$t('organisation_settings.tabs.licenses.success_message'),
            variant: 'success',
          });
          this.fetchCurrentUserAccess(); // reload licenses in stores
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('organisation_settings.tabs.licenses.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.updatingLicenses = false;
          this.resetData();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-licenses__subtitle {
  padding-top: 5px;
  color: $sk-grey;
  font-size: $fs-text-l;
}

.organisation-licenses__cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.organisation-licenses__container__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}
</style>
