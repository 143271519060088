<template>
  <div class="step-three__container">
    <section class="step-three_section">
      {{ $t('self_serve.step_three.labels.employees') }}
      <SkOroraChipGroup
        v-model="employeeRanges"
        variant="pill"
        pill-size="medium"
        :multi-select="false"
        @input="trackEmployeesCount"
      />
    </section>

    <section class="step-three_section">
      {{ $t('self_serve.step_three.labels.sector') }}
      <SkOroraChipGroup
        v-model="sectors"
        variant="pill"
        pill-size="medium"
        :multi-select="false"
        @input="handleSectorChoice"
      />
    </section>

    <div class="step-three__button">
      <SkOroraButton
        id="self-serve-step-three-submit"
        :disabled="isSubmitDisabled"
        fill-parent
        @click="submitForm"
      >
        {{ $t('self_serve.step_three.button_text') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import {
  SkOroraButton,
  SkOroraChipGroup,
} from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { mapState } from 'vuex';

export default {
  name: 'StepThree',
  components: {
    SkOroraButton,
    SkOroraChipGroup,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['next'],
  data() {
    return {
      employeeRanges: [
        { label: '1-10', isSelected: false },
        { label: '11-20', isSelected: false },
        { label: '21-50', isSelected: false },
        { label: '51-200', isSelected: false },
        { label: '200+', isSelected: false },
      ],
      sectors: [
        { label: this.$t('self_serve.step_three.labels.hospitality'), isSelected: false, key: 'hospitality' },
        { label: this.$t('self_serve.step_three.labels.retail'), isSelected: false, key: 'retail' },
        { label: this.$t('self_serve.step_three.labels.health'), isSelected: false, key: 'health' },
        { label: this.$t('self_serve.step_three.labels.leisure'), isSelected: false, key: 'leisure' },
        { label: this.$t('self_serve.step_three.labels.service'), isSelected: false, key: 'services' },
        { label: this.$t('self_serve.step_three.labels.industry'), isSelected: false, key: 'industry' },
        { label: this.$t('self_serve.step_three.labels.construction'), isSelected: false, key: 'construction' },
        { label: this.$t('self_serve.step_three.labels.other'), isSelected: false, key: 'other' },
      ],
      prospect: {
        attributes: {},
      },
    };
  },
  computed: {
    ...mapState('config', ['config']),
    isSubmitDisabled() {
      return !(this.selectedEmployeesCount && this.selectedSector);
    },
    selectedEmployeesCount() {
      return this.employeeRanges.find(choice => choice.isSelected)?.label;
    },
    selectedSector() {
      return this.sectors.find(choice => choice.isSelected)?.key;
    },
  },
  mounted() {
    // Google Analytics script for Marketing team
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'self_serve_step_viewed',
      page_type: 'selfserve',
      page_category: 'selfserve',
      selfserve_version: localStorage.getItem('self-serve-version'),
      step_index: 'stepThree',
      step_name: 'stepThree',
      page_path: window.location.pathname,
      referrer: document.referrer,
    });
  },
  created() {
    const stepThreeData = JSON.parse(localStorage.getItem('step_three'));

    if (stepThreeData) {
      this.employeeRanges.find(
        choice => choice.label === stepThreeData.selectedEmployeesCount,
      ).isSelected = true;

      this.sectors.find(
        choice => choice.key === stepThreeData.selectedSector,
      ).isSelected = true;
    }

    httpClient
      .get(`/v3/api/prospects/${this.$route.query.token}`)
      .then(response => {
        this.prospect = response.data.data;
      });
  },
  methods: {
    trackEmployeesCount() {
      if (!this.selectedEmployeesCount) {
        return;
      }

      this.$skAnalytics.track('self_serve_employee_counts', { source: this.selectedEmployeesCount });
    },
    handleSectorChoice() {
      this.$skAnalytics.track('self_serve_sector', { source: this.selectedSector });
    },
    submitForm() {
      if (this.isSubmitDisabled) return;

      this.sendSegmentTrackers();

      const stateParams = {
        selectedEmployeesCount: this.selectedEmployeesCount,
        selectedSector: this.selectedSector,
      };

      localStorage.setItem('step_three', JSON.stringify(stateParams));

      httpClient.post('/v3/api/self_serve/notify_salesforce', {
        step: 'step_three',
        employees_range: this.selectedEmployeesCount,
      });
      this.$skAnalytics.track('ss_lead_definition_step3_next_button', stateParams);
      this.next();
    },
    sendSegmentTrackers() {
      const stepOneData = JSON.parse(localStorage.getItem('step_one'));

      const params = {
        first_name: this.prospect.attributes.firstName,
        last_name: this.prospect.attributes.lastName,
        email: this.prospect.attributes.email,
        phone: this.prospect.attributes.phone,
        employees: this.selectedEmployeesCount,
        sector: this.selectedSector,
        country_code: stepOneData?.country_code,
        customer_id: Date.now() + Math.random(),
      };

      this.$skAnalytics.track('self_serve_step_three_continue', params);
    },
  },
};
</script>

<style lang="scss" scoped>
.step-three__container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.step-three_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step-three__button {
  width: 100%;

  @media (max-width: $sm-width) {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    background-color: white;
  }
}

.sk-select__selected-option {
  width: 100%;
  max-width: 576px;
}
</style>
