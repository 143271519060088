<template>
  <div class="step-six__container">
    <div class="step-six__content-container">
      <div class="step-six__content-body">
        <div class="step-six__content-form">
          <div class="step-six__content-form__header">
            <LocationPinIcon
              width="25"
              height="25"
              :fill="locationPinIconColor"
            />
            <div class="step-six__content-form__title">
              {{ $t('self_serve.v5.step_six.billing_title') }}
            </div>
          </div>
          <div class="step-six__content-form__row-wrapper">
            <div class="step-six__content-form__row">
              <SkOroraInput
                v-model="billingAddress"
                :label="$t('self_serve.step_six.labels.billing_address')"
                :errored="errors.address"
                :hint-message="errors.address ? $t('self_serve.step_six.errors.mandatory') : null"
                @keyup="validateMandatoryField('address')"
              />
            </div>
          </div>
          <div class="step-six__content-form__row-wrapper">
            <div class="step-six__content-form__row">
              <SkOroraInput
                v-model="billingCity"
                :label="$t('self_serve.step_six.labels.city')"
                :errored="errors.city"
                :hint-message="errors.city ? $t('self_serve.step_six.errors.mandatory') : null"
                @keyup="validateMandatoryField('city')"
              />
            </div>
            <div class="step-six__content-form__row">
              <SkOroraInput
                v-model="billingZipcode"
                :label="$t('self_serve.step_six.labels.zip_code')"
                :errored="errors.zipCode"
                :hint-message="errors.zipCode ? $t('self_serve.step_six.errors.mandatory') : null"
                @keyup="validateMandatoryField('zipCode')"
              />
            </div>
            <div class="step-six__content-form__row">
              <SkOroraSelect
                v-model="billingCountry"
                :options="countries"
                :label="$t('self_serve.step_six.labels.country')"
                :no-search-result-label="$t('search_bar.no_result')"
              />
            </div>
          </div>
          <div class="step-six__payment-form-container">
            <div class="step-six__content-form__header">
              <CreditCardIcon
                width="25"
                height="25"
                :fill="creditCardIconColor"
              />
              <p class="step-six__content-form__title">
                {{ $t('self_serve.v5.step_six.payment_title') }}
              </p>
            </div>
            <div class="step-six__content-form__info">
              <InfoV2Icon
                class="step-six__info-icon"
                height="20"
                width="20"
                :fill="infoIconColor"
              />

              <div class="step-six__payment-method__info">
                <span class="step-six__content-form__info__text">
                  {{ $t('self_serve.v5.step_six.free_trial.validation_info_1') }}
                </span>
                <span class="step-six__content-form__info__text_date">
                  {{ expirationDate }}<span class="step-six__content-form__info__text">.</span>
                </span>
                <span class="step-six__content-form__info__text">
                  {{ $t('self_serve.v5.step_six.free_trial.validation_info_2') }}
                </span>
              </div>
            </div>
            <template v-if="isCreditCardEnabled">
              <div
                v-if="isFetchingPaymentMethod"
                class="step-six__payment-method-loader__row-wrapper"
              >
                <SkLoader />
              </div>
              <div
                v-else
                class="step-six__payment-method__row-wrapper"
              >
                <SkOroraTag
                  v-if="isPaymentMethodAdded"
                  variant="green"
                >
                  {{ $t(paymentMethodTranslationKey) }}
                </SkOroraTag>
                <SkOroraButton
                  :variant="isPaymentMethodAdded ? 'secondary' : 'primary'"
                  :size="paymentMethodButtonSize"
                  :fill-parent="isMobileOrTabletWidth"
                  @click="openCheckout"
                >
                  {{ paymentMethodButtonText }}
                </SkOroraButton>
              </div>
            </template>
            <div
              v-else
              class="step-six__content-form__row-wrapper"
            >
              <SkOroraInput
                v-model.trim="billingInfo.iban"
                :label="$t('self_serve.step_six.labels.iban')"
                :errored="errors.iban"
                :hint-message="errors.iban ? ibanErrorMessage : null"
                :debounce="validateIban"
                :debounce-ms="500"
              />
            </div>
          </div>
          <div class="step-six__content__submit-form-wrapper">
            <div class="step-six__content__submit-form step-six__content__submit-form--within">
              <SkOroraButton
                id="self-serve-step-six-submit"
                class="step-six__content__submit-form--button"
                :disabled="disabledSubmit"
                :loading="loading"
                :size="isMobileOrTabletWidth ? 'large' : 'medium'"
                @click="submitForm"
              >
                {{ $t('self_serve.v5.step_six.submit') }}
              </SkOroraButton>
            </div>
            <div
              class="step-six__content__submit-form__secure-payment"
            >
              <ShieldWithCheckIcon
                width="20"
                height="20"
                :fill="securePaymentIconColor"
              />
              <span
                class="step-six__content__submit-form__secure-payment__text sk-text-small-semibold"
              >
                {{ $t('self_serve.v5.step_six.secure_payment') }}
              </span>
            </div>
            <div class="step-six__content-form__legal-warning">
              {{ $t('self_serve.v5.step_six.legal_warning') }}
              <SkOroraLink
                :href="termsOfService"
                target="_blank"
                size="small"
                @click="trackTermsOfServiceLinkClick"
              >
                <span> {{ $t('self_serve.step_six.legal_warning_link') }}</span>
              </SkOroraLink>.
            </div>
          </div>
        </div>
        <div class="step-six__content-summary">
          <div class="step-six__content-summary__header">
            <div class="step-six__content-summary__logo">
              <component
                :is="getSummaryLogoIcon"
                class="step-six__content-summary__logo__icon"
                width="31"
                height="31"
                fill="#2b66fe"
              />
            </div>
            <div class="step-six__content-summary__header-subcontent">
              <div class="step-six__content-summary__pack-name--big sk-heading-medium-semibold">
                {{ capitalizedPackName }}
              </div>
              <div
                class="step-six__content-summary__description
                  sk-text-medium-regular sk-text-medium-regular__grey"
              >
                {{ packDescription }}
              </div>
            </div>
          </div>
          <div class="step-six__content-summary__modalities">
            <SkOroraSegmentedButtons full-width>
              <SkOroraSegmentedButton
                :active="isAnnualSelected"
                @click="selectAnnual"
              >
                {{ $t('self_serve.v5.step_six.annual') }}
              </SkOroraSegmentedButton>
              <SkOroraSegmentedButton
                :active="!isAnnualSelected"
                @click="selectMonthly"
              >
                {{ $t('self_serve.step_six.monthly') }}
              </SkOroraSegmentedButton>
            </SkOroraSegmentedButtons>
            <div class="step-six__content-summary__modalities-content">
              <div class="sk-text-medium-regular">
                {{ $t('self_serve.step_six.pack') }}
                {{ $t('self_serve.step_six.free_trial.pack_info') }}
              </div>
              <div class="sk-text-large-regular">
                {{ capitalizedPackName }}
              </div>
            </div>
            <div
              class="step-six__content-summary__modalities-content
                step-six__content-summary__modalities-content__extra-padding"
            >
              <div class="sk-text-medium-regular">
                {{ $t('self_serve.step_six.debit') }}
              </div>
              <div
                class="step-six__content-summary_modalities-content-grouped-frequency-discount"
              >
                <SkOroraTag
                  v-if="isAnnualSelected"
                  size="medium"
                  class="step-six__content-summary__modalities-content--discount"
                >
                  {{ $t('self_serve.v5.step_six.discount') }}
                </SkOroraTag>
                <div class="sk-text-large-regular">
                  {{ displayedDirectDebitFrequency }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="step-six__content-summary__modalities
              step-six__content-summary__modalities--bottom"
          >
            <div class="step-six__content-summary__modalities-content">
              <span class="sk-text-medium-regular sk-text-medium-regular__grey">
                {{ $t('self_serve.step_six.ht_total') }}
              </span>
              <div class="sk-text-medium-regular sk-text-medium-regular__grey">
                {{ $t('self_serve.v5.step_six.ht_price', { price: displayedPrice }) }}
              </div>
            </div>
            <div
              class="step-six__content-summary__modalities-content
                step-six__content-summary__modalities-content__extra-padding"
            >
              <div class="sk-text-medium-regular sk-text-medium-regular__grey">
                {{ $t('self_serve.step_six.taxes') }}
                {{ $t('self_serve.step_six.tax_percent', { tax_rate: displayedTaxRate }) }}
              </div>
              <div class="sk-text-medium-regular sk-text-medium-regular__grey">
                {{ $t('self_serve.v5.step_six.ht_price', { price: displayedTaxRateAmount }) }}
              </div>
            </div>
            <div
              class="step-six__content-summary__modalities-price
                step-six__content-summary__modalities-price--total"
            >
              <div class="sk-text-medium-regular">
                {{ $t('self_serve.step_six.ttc_total') }}
                {{ $t('self_serve.step_six.free_trial.price_info') }}
              </div>
              <div class="sk-text-large-regular">
                {{ $t('self_serve.v5.step_six.ttc_price', { total: priceWithTaxes }) }}
              </div>
            </div>
            <div class="step-six__content-summary__total-this-day">
              <div class="sk-heading-small-semibold">
                {{ $t('self_serve.step_six.ttc_this_day') }}
              </div>
              <div class="sk-heading-small-semibold">
                0 €
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step-six__content__submit-form step-six__content__submit-form--outside">
        <SkOroraButton
          class="step-six__content__submit-form--button"
          :disabled="disabledSubmit"
          :loading="loading"
          :size="isMobileOrTabletWidth ? 'large' : 'medium'"
          @click="submitForm"
        >
          <div class="step-six__content__submit-form__content">
            {{ $t('self_serve.v5.step_six.submit') }}
          </div>
        </SkOroraButton>
        <div
          class="step-six__content__submit-form__secure-payment--outside"
        >
          <ShieldWithCheckIcon
            width="20"
            height="20"
            :fill="securePaymentIconColor"
          />
          <span class="step-six__content__submit-form__secure-payment__text sk-text-small-semibold">
            {{ $t('self_serve.v5.step_six.secure_payment') }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <router-view />
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <WebRedirectionModal
          ref="webRedirectionModal"
          @close-modal="handleSkelloRedirection"
        />
      </MountingPortal>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';
import { isSafariOnIOS } from '@app-js/shared/utils/browser';
import {
  httpClient,
  svcBillingAutomationClient,
} from '@skello-utils/clients';
import { arrayToSelectOptions } from '@skello-utils/form';
import { countryKeys } from '@skello-utils/country-keys.js';
import {
  CreditCardIcon,
  LocationPinIcon,
  MODAL_SHOW_EVENT,
  PunchClockV2Icon,
  CalendarV2Icon,
  CrownJewelIcon,
  SkInfoCard,
  SkOroraInput,
  SkOroraButton,
  SkOroraLink,
  SkOroraTag,
  SkOroraSelect,
  SkOroraSegmentedButtons,
  SkOroraSegmentedButton,
  InfoV2Icon,
  SK_COLORS,
  ShieldWithCheckIcon,
  SkLoader,
} from '@skelloapp/skello-ui';
import IBAN from 'iban';

import skDate from '@skello-utils/dates';
import { listenIntercomInit } from '@skello-utils/listen_intercom_init';
import { authClient } from '@skello-utils/clients/auth_client';

import {
  getPackFromPackOfferName,
  getPackOfferNameFromPack,
} from '@app-js/shared/utils/self_serve/utils';
import { chargebeeSite } from '@config/env';
import WebRedirectionModal from './WebRedirectionModal';

const validIbanOrigin = ['AD', 'AT', 'BE', 'BG', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GI', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'SM', 'VA'];

export default {
  components: {
    CreditCardIcon,
    LocationPinIcon,
    PunchClockV2Icon,
    CalendarV2Icon,
    CrownJewelIcon,
    SkInfoCard,
    SkOroraInput,
    SkOroraButton,
    SkOroraLink,
    SkOroraTag,
    SkOroraSelect,
    WebRedirectionModal,
    SkOroraSegmentedButtons,
    SkOroraSegmentedButton,
    InfoV2Icon,
    ShieldWithCheckIcon,
    SkLoader,
  },
  data() {
    return {
      allowedPaymentMethods: ['card', 'direct_debit'],
      chargebeeInstance: window.Chargebee.init({ site: chargebeeSite }),
      infoIconColor: SK_COLORS.skBlue50,
      securePaymentIconColor: SK_COLORS.skBlue50,
      creditCardIconColor: SK_COLORS.skBlack,
      locationPinIconColor: SK_COLORS.skBlack,
      packName: this.$route.params.packName || 'planning-timeclock',
      ibanErrorMessage: '',
      billingInfo: {
        iban: '',
        email: '',
        address: '',
        city: '',
        country: '',
        zipCode: '',
      },
      errors: {
        address: false,
        city: false,
        zipCode: false,
        email: false,
        iban: false,
      },
      currentOrganisation: null,
      loading: false,
      isStripeAvailable: true,
      isAnnualSelected: false,
      intercomInstance: null,
      isPaymentMethodAdded: false,
      isFetchingPaymentMethod: true,
      paymentMethodType: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('onboarding', ['rawBundle', 'packOfferPriceWithOptions']),
    ...mapState('onboarding', [
      'currentUser',
      'userCreation',
      'taxRatesByCountry',
      'packOfferPrices',
    ]),
    billingAddress: {
      get() {
        return this.billingInfo.address;
      },
      set(newValue) {
        this.billingInfo.address = newValue;
      },
    },

    billingZipcode: {
      get() {
        return this.billingInfo.zipCode;
      },
      set(newValue) {
        this.billingInfo.zipCode = newValue;
      },
    },

    billingCity: {
      get() {
        return this.billingInfo.city;
      },
      set(newValue) {
        this.billingInfo.city = newValue;
      },
    },

    billingCountry: {
      get() {
        return this.billingInfo.country;
      },
      set(newValue) {
        this.billingInfo.country = newValue;
      },
    },
    isCreditCardEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SELFSERVE_CREDIT_CARD') && !isSafariOnIOS();
    },
    countries() {
      return arrayToSelectOptions(countryKeys('FR'), value => this.$t(`countries.${value}`));
    },
    isAddressValid() {
      return this.billingAddress && this.billingCity &&
        this.billingCountry && this.billingZipcode;
    },
    isFormValid() {
      if (this.isCreditCardEnabled) {
        return this.isAddressValid && this.isPaymentMethodAdded && !this.isFetchingPaymentMethod;
      }
      return this.isAddressValid && this.billingInfo.iban &&
      !this.errors.iban;
    },
    disabledSubmit() {
      return !this.isFormValid;
    },
    displayedTaxRate() {
      if (this.billingInfo.country && this.isStripeAvailable) {
        return this.taxRate;
      }
      return '-';
    },
    taxRate() {
      if (Object.keys(this.taxRatesByCountry).includes(this.billingInfo.country)) {
        return this.taxRatesByCountry[this.billingInfo.country];
      }

      return this.taxRatesByCountry.other;
    },
    displayedPrice() {
      return this.packOfferPrice();
    },
    displayedTaxRateAmount() {
      return (this.packOfferPrice() * (this.taxRate / 100)).toFixed(2);
    },
    displayedDirectDebitFrequency() {
      return this.isAnnualSelected ?
        this.$t('self_serve.step_six.annual') :
        this.$t('self_serve.step_six.monthly');
    },
    priceWithTaxes() {
      if (!this.isStripeAvailable) return '-';
      if (!this.taxRate) return this.packOfferPrice();

      return (this.packOfferPrice() + (this.packOfferPrice() * (this.taxRate / 100))).toFixed(2);
    },
    // IBAN should be from countries members of SEPA (Single Euro Payments Area)
    isIbanSepaCompatible() {
      return validIbanOrigin.includes(this.billingInfo.iban.substring(0, 2).toUpperCase());
    },
    isMobileOrTablet() {
      return /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent) || this.windowWidth < 1024;
    },
    isMobileOrTabletWidth() {
      return this.windowWidth < 1024;
    },
    termsOfService() {
      return this.$t('self_serve.terms_of_service_link', { lang: this.$i18n.locale });
    },
    capitalizedPackName() {
      const packName = this.$t(`self_serve.step_five.bundle_offers.${this.packName}.title`);
      return packName.charAt(0).toUpperCase() + packName.slice(1).toLowerCase();
    },
    packDescription() {
      return this.$t(`self_serve.step_five.bundle_offers.${this.packName.toLowerCase()}.description`);
    },
    expirationDate() {
      return skDate().add(14, 'days').format('DD/MM/YYYY');
    },
    getSummaryLogoIcon() {
      const ICON_MAP = {
        timeclock: 'PunchClockV2Icon',
        planning: 'CalendarV2Icon',
        'planning-timeclock': 'CrownJewelIcon',
      };
      return ICON_MAP[this.packName] || 'PunchClockV2Icon';
    },
    packOfferName() {
      return getPackOfferNameFromPack(this.packName);
    },
    paymentMethodButtonSize() {
      return this.isMobileOrTabletWidth ? 'large' : 'medium';
    },
    paymentMethodButtonText() {
      return this.isPaymentMethodAdded ?
        this.$t('self_serve.v5.step_six.modify_payment_method') :
        this.$t('self_serve.v5.step_six.add_payment_method');
    },
    paymentMethodTranslationKey() {
      switch (this.paymentMethodType) {
        case 'card':
          return 'self_serve.v5.step_six.card_payment_method_added';
        case 'direct_debit':
          return 'self_serve.v5.step_six.direct_debit_payment_method_added';
        default:
          return 'self_serve.v5.step_six.payment_method_added';
      }
    },
  },
  mounted() {
    // Google Analytics script for Marketing team
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'self_serve_step_viewed',
      page_type: 'selfserve',
      page_category: 'selfserve',
      selfserve_version: localStorage.getItem('self-serve-version'),
      step_index: 'stepSix',
      step_name: 'stepSix',
      page_path: window.location.pathname,
      referrer: document.referrer,
    });
  },
  async created() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.updateResize);

    if (!this.$route.params.packName) {
      this.packName = this.rawBundle;
    }

    await this.fetchCurrentUser();
    await this.fetchProspect();

    if (this.isCreditCardEnabled) {
      // Need to force refresh token because current token does not contain data like organisationId
      // which is needed to fetch payment sources
      await authClient.forceRefreshToken();
      this.fetchPaymentSources();
    }
    this.setBillingInfo();

    // Use /v3/api/onboarding/organisations/:id here because the shop is not
    // configured yet, therefore the scope user.visible_shops used in
    // /v3/api/current_organisation returns no shop
    const getOrganisationResponse = await httpClient
      .get(`/v3/api/onboarding/organisations/${this.currentUser.attributes.organisationId}`);
    this.currentOrganisation = getOrganisationResponse.data.data;
    const lastShopID = this.currentOrganisation.attributes.lastShopId;

    const lastShop = await this.getLastCreatedShop({ shopId: lastShopID });
    const featuresStates = lastShop.data.data.attributes?.featuresStates;

    const badgingEnabled = featuresStates &&
      featuresStates.badging !== undefined &&
      featuresStates.badging.activated;

    // override packName for safety
    this.packName = getPackFromPackOfferName(
      this.currentOrganisation.attributes.packOffer.name,
      badgingEnabled,
    );

    if (this.isDevFlagEnabled('FEATUREDEV_HARDCODED_SELFSERVE_PRICES')) {
      this.setHardCodedPricesByLocale(this.$route.query.lang);
      this.setHardCodedTaxes();
    } else {
      try {
        await Promise.all([this.fetchStripeTaxes(), this.fetchStripePrices()]);
      } catch (_) {
        this.isStripeAvailable = false;
        this.genericSkToastError(this.$t('self_serve.step_six.errors.stripe_unavailable'));
      }
    }
    this.intercomInstance = await listenIntercomInit();

    this.setIntercomPadding();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateResize);
  },
  methods: {
    ...mapActions('onboarding', [
      'fetchCurrentUser',
      'fetchProspect',
      'updateProspect',
      'updateOrganisation',
      'updateShop',
      'fetchStripeTaxes',
      'setHardCodedPricesByLocale',
      'setHardCodedTaxes',
      'fetchStripePrices',
      'getLastCreatedShop',
    ]),
    setBillingInfo() {
      const stepFourData = JSON.parse(localStorage.getItem('step_four'));
      if (stepFourData) {
        this.billingInfo = {
          country: stepFourData.country,
          address: stepFourData.address,
          city: stepFourData.city,
          zipCode: stepFourData.zipCode,
        };
      } else {
        this.billingInfo = {
          country: this.retrieveCountryCode(),
          address: '',
          city: '',
          zipCode: '',
        };
      }
      this.billingInfo.email = this.currentUser.attributes.email;
    },
    async handleCheckoutMessage(event) {
      const isSuccess = event?.data?.status === 'success';
      const isError = event?.data?.status === 'error';
      const isPaymentSourceEvent = ['cb.payment_source.add', 'cb.payment_source.update'].includes(event?.data?.key);

      if (isSuccess && isPaymentSourceEvent) {
        this.chargebeeInstance.closeAll();
        await this.fetchPaymentSources();

        const source = this.paymentMethodType === 'direct_debit' ? 'iban' : 'cb';
        const eventType = event?.data?.key === 'cb.payment_source.add' ? 'add' : 'update';

        this.$skAnalytics.track(`self_serve_${eventType}_payment_method_success_${source}`);
      } else if (isError && isPaymentSourceEvent) {
        const eventType = event.data.key === 'cb.payment_source.add' ? 'add' : 'update';
        this.$skAnalytics.track(`self_serve_${eventType}_payment_method_failed`);
      }
    },
    openCheckout() {
      const { chargebeeId } = this.userCreation.prospect.attributes;

      window.addEventListener('message', this.handleCheckoutMessage);
      const thisComponent = this;
      try {
        this.chargebeeInstance.openCheckout({
          hostedPage() {
            return svcBillingAutomationClient
              .createCheckoutByChargebeeIdAndBillingEntity(
                chargebeeId,
                thisComponent.getBillingEntityFromCountry(),
              );
          },
          loaded() {
            thisComponent.$skAnalytics.track('self_serve_open_payment_method_selection');
          },
          close() {
            window.removeEventListener('message', thisComponent.handleCheckoutMessage);
            if (!thisComponent.isPaymentMethodAdded) {
              thisComponent.$skAnalytics.track('self_serve_chargebee_modal_closed');
            }
          },
          error() {
            this.genericSkToastError();
          },
        });
      } catch (error) {
        this.chargebeeInstance.closeAll();
        this.genericSkToastError();
      }
    },
    getBillingEntityFromCountry() {
      return this.billingInfo.country === 'ES' ? 'Iberia' : 'France';
    },
    extractPaymentMethodType(paymentMethods) {
      const hasPaymentMethods = paymentMethods?.length;
      const paymentMethod = hasPaymentMethods ? paymentMethods[0].type : null;
      const paymentMethodIsAllowed = this.allowedPaymentMethods.includes(paymentMethod);
      if (hasPaymentMethods && !paymentMethodIsAllowed) {
        throw new Error('Payment method is not allowed.');
      }
      return paymentMethod;
    },
    async fetchPaymentSources() {
      try {
        const { chargebeeId } = this.userCreation.prospect.attributes;

        this.isFetchingPaymentMethod = true;

        const paymentMethods = await svcBillingAutomationClient.getSources([chargebeeId]);

        this.paymentMethodType = this.extractPaymentMethodType(paymentMethods);
        this.isPaymentMethodAdded = !!this.paymentMethodType;
      } catch (error) {
        console.error('Error fetching payment sources', error);
        this.genericSkToastError();
      } finally {
        this.isFetchingPaymentMethod = false;
      }
    },
    retrieveCountryCode() {
      // In localize United Kingdom is for GB and not EN
      return this.currentUser.attributes.lang === 'en' ?
        'GB' :
        this.currentUser.attributes.lang.toUpperCase();
    },
    packOfferPrice() {
      if (!this.isStripeAvailable || !this.packOfferPrices[this.packOfferName]) {
        return '-';
      }

      let price;
      if (this.isDevFlagEnabled('FEATUREDEV_HARDCODED_SELFSERVE_PRICES')) {
        const options = this.packName === 'planning-timeclock' ? ['timeclock'] : [];
        price = this.packOfferPriceWithOptions(this.packOfferName, options);
      } else {
        price = this.packOfferPrices[this.packOfferName];
      }

      return this.isAnnualSelected ? price.annual : price.monthly;
    },
    selectAnnual() {
      this.$skAnalytics.track('ss_billing_information_step6_annual_payment');
      this.isAnnualSelected = true;
    },
    selectMonthly() {
      this.$skAnalytics.track('ss_billing_information_step6_monthly_payment');
      this.isAnnualSelected = false;
    },
    submitForm() {
      if (!this.isFormValid) return;

      if (!this.isStripeAvailable) {
        this.genericSkToastError(this.$t('self_serve.step_six.errors.stripe_unavailable'));
        return;
      }
      this.loading = true;
      this.sendSegmentTrackers();

      this
        .createBillingInfo()
        .then(() => {
          // The order is important here, updateShop must be called first
          // shop must be set as configured == true so it can be updated
          const updateShopParams = {
            id: this.currentOrganisation.attributes.lastShopId,
            shop: {
              configured: true,
            },
          };
          this.$skAnalytics.track('ss_billing_information_step6_submit');
          this.updateShop(updateShopParams)
            .then(() => {
              Promise.all([
                this.updateProspect({
                  prospect: {
                    step: 'registration_fully_completed',
                  },
                }),
                this.updateOrganisation({
                  id: this.currentOrganisation.id,
                  shop_id: this.currentOrganisation.attributes.lastShopId,
                  organisation: {
                    status: 'active',
                  },
                }).then(() => {
                  // Google Analytics script for Marketing team
                  window.dataLayer = window.dataLayer || [];
                  dataLayer.push({
                    event: 'selfserve_completed',
                    page_type: 'selfserve',
                    page_category: 'selfserve',
                    selfserve_version: localStorage.getItem('self-serve-version'),
                    step_index: 'stepSix',
                    step_name: 'stepSix',
                    page_path: window.location.pathname,
                    referrer: document.referrer,
                    user_data: {
                      email: this.currentUser.attributes.email,
                      phone: this.currentUser.attributes.phone,
                      first_name: this.currentUser.attributes.firstName,
                      last_name: this.currentUser.attributes.lastName,
                      country_code: this.currentUser.attributes.lang,
                      skello_id: this.currentUser.id,
                    },
                  });

                  localStorage.removeItem('step_one');
                  localStorage.removeItem('step_three');
                  localStorage.removeItem('step_four');
                  localStorage.removeItem('step_five');
                  this.handleModalDisplay();
                  this.notifySalesforce();
                })
                  .catch(error => {
                    const errorMessage = error.response?.data?.message;
                    const isIBANError = error.response?.status === 422 && errorMessage.includes('IBAN');
                    const toastMessage = isIBANError ?
                      this.$t('self_serve.errors.iban') : this.$t('self_serve.errors.generic');
                    this.genericSkToastError(toastMessage);
                  })
                  .finally(() => {
                    this.loading = false;
                  }),
              ])
                .catch(this.handleSubmitError);
            })
            .catch(this.handleSubmitError);
        })
        .catch(this.handleSubmitError);
    },
    createBillingInfo() {
      return this.isDevFlagEnabled('FEATUREDEV_SELF_SERVE_RAUL') ?
        this.createChargebeeSource() : this.createSkelloBillingInfo();
    },
    notifySalesforce() {
      const params = { step: 'step_six', subscription_time: this.isAnnualSelected };
      const paymentMethod = this.paymentMethodType === 'direct_debit' ? 'iban' : 'cb';

      if (this.isDevFlagEnabled('FEATUREDEV_SELF_SERVE_RAUL')) {
        params.billing_info = {
          city: this.billingInfo.city,
          address: this.billingInfo.address,
          zipCode: this.billingInfo.zipCode,
          paymentMethod,
        };
      }

      httpClient.post('/v3/api/self_serve/notify_salesforce', params);
    },
    createChargebeeSource() {
      const iban = this.isCreditCardEnabled ? '' : this.billingInfo.iban;
      return svcBillingAutomationClient
        .createChargebeeSource(
          this.userCreation.prospect.attributes.chargebeeId,
          this.billingInfo.city,
          this.billingInfo.country,
          this.billingInfo.email,
          this.currentUser.attributes.firstName,
          // If Iban is empty, it will just update the billing address
          iban,
          this.currentUser.attributes.lastName,
          this.billingInfo.address,
          this.billingInfo.zipCode,
        );
    },
    createSkelloBillingInfo() {
      const params = {
        shop_id: this.currentOrganisation.attributes.lastShopId,
        billing_infos: { ...this.billingInfo },
        bank_info: {
          iban: this.billingInfo.iban,
        },
        from_self_serve: true,
      };

      return httpClient
        .post('/v3/api/billing_infos', params);
    },
    async handleModalDisplay() {
      // To ensure newly created shop is present in token
      await authClient.forceRefreshToken();

      if (this.isMobileOrTablet) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, this.$refs.webRedirectionModal.$el.id);
      } else {
        this.handleSkelloRedirection();
      }
    },
    handleSkelloRedirection() {
      window.location.href = '/';
    },
    validateIban() {
      this.ibanErrorMessage = null;
      if (!this.billingInfo.iban) {
        this.ibanErrorMessage = this.$t('self_serve.step_six.errors.mandatory');
      } else if (!IBAN.isValid(this.billingInfo.iban)) {
        this.ibanErrorMessage = this.$t('self_serve.step_six.errors.invalid_iban');
      } else if (!this.isIbanSepaCompatible) {
        this.ibanErrorMessage = this.$t('self_serve.step_six.errors.iban_no_sepa');
      }

      this.errors.iban = !!this.ibanErrorMessage;
    },
    validateMandatoryField(attribute) {
      if (attribute === 'iban') {
        this.validateIban();
      } else {
        this.errors[attribute] = !this.billingInfo[attribute];
      }
    },
    formValidColor() {
      return !this.isFormValid ? '#dddddd' : '#2b66fe';
    },
    updateResize() {
      this.setIntercomPadding();
      this.windowWidth = window.innerWidth;
    },
    setIntercomPadding() {
      if (!this.intercomInstance) {
        return;
      }

      const screenWidth = window.innerWidth;
      // Set this padding only for all step except 2 and 5 and when on mobile
      if (screenWidth < 576) {
        this.intercomInstance.style.bottom = '78px';
      } else {
        this.intercomInstance.style.bottom = '20px';
      }
    },
    sendSegmentTrackers() {
      const stepThreeData = JSON.parse(localStorage.getItem('step_three'));
      const stepFourData = JSON.parse(localStorage.getItem('step_four'));
      const stepOneData = JSON.parse(localStorage.getItem('step_one'));

      const params = {
        first_name: this.currentUser.attributes.firstName,
        last_name: this.currentUser.attributes.lastName,
        email: this.currentUser.attributes.email,
        phone: this.currentUser.attributes.phone,
        employees: stepThreeData?.selectedEmployeesCount,
        shops: stepThreeData?.selectedShopsCount,
        sector: stepFourData?.sector,
        country: this.billingInfo.country,
        company: stepFourData?.socialDenomination,
        customer_id: Date.now() + Math.random(),
        self_serve_source: 'v2',
        pack: this.packName,
        country_code: stepOneData?.country_code,
        city: this.billingInfo.city,
      };

      this.$skAnalytics.track('self_serve_confirm_payment', params);
    },
    trackTermsOfServiceLinkClick() {
      this.$skAnalytics.track('ss_billing_information_step6_terms_of_use_link');
    },
    genericSkToastError(message = 'self_serve.errors.generic') {
      this.$skToast({
        message: this.$t(message),
        variant: 'error',
      });
    },
    handleSubmitError(error) {
      this.loading = false;

      if (error.response?.status === 500) {
        const errorMessage = error.response.data?.message;

        if (errorMessage.includes('invalid zip/postal code')) {
          this.genericSkToastError('self_serve.errors.postal_code');
          throw error;
        }

        if (errorMessage?.toLowerCase().includes('iban')) {
          this.genericSkToastError('self_serve.errors.iban');
          throw error;
        }
      }

      this.genericSkToastError();
    },
  },
};
</script>

<style lang="scss" scoped>

.step-six__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step-six__content-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (max-width: $sm-width) {
    margin-bottom: 0px;
  }
}

.step-six__content-background {
  position: absolute;
  bottom: 0;
  right: 50px;
  z-index: -1;
  max-height: 95%;

  @media (max-width: 864px) {
    display: none;
  }
}

.step-six__content-header {
  display: flex;
  align-items: center;

  // override DS forced white
  ::v-deep circle {
    fill: $sk-grey-10;
  }

  svg {
    transform: rotate(180deg);
    margin-right: 20px;
  }

  @media (max-width: $sm-width) {
    flex-direction: column;
    align-items: flex-start;
    color: white;
  }
}

.step-six__content-header__previous-arrow {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 35px;
  margin-right: 20px;

  @media (max-width: $sm-width) {
    display: none;
  }
}

.self-serve__logo-container {
  border-radius: 126.667px;
  box-shadow: 14px 14px 60px rgb(0 0 0 / 12%);
  padding: 15px 20px;
  width: 90px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm-width) {
    background: white;
  }
}

.self-serve__logo {
  width: 65px;
}

.step-six__content-body {
  display: flex;
  gap: 32px;
  height: 90%;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 1023px) {
    flex-flow: column-reverse nowrap;
    height: auto;
    margin-top: 0px;
  }
}

.step-six__content-form {
  border-radius: 8px;
  max-width: 65%;
  background-color: white;
  position: relative;
  height: fit-content;

  @media (max-width: 1023px) {
    max-width: 100%;
  }
}

.step-six__content-form__title {
  font-size: $fs-heading-m;
  font-weight: $fw-semi-bold;
  line-height: 24px;
  margin-bottom: 0;
  padding-left: 8px;
}

.step-six__content-form__info {
  display: flex;
  align-items: flex-start;
}

.step-six__content-form__info__text {
  font-weight: $fw-regular;
  font-size: $fs-text-l;
  color: $sk-black;
}

.step-six__content-form__info__text_date {
  font-weight: $fw-semi-bold;
  font-size: $fs-text-l;
  color: $sk-black;
}

.step-six__content-form__row-wrapper {
  display: flex;
  gap: 16px;
  padding-top: 8px;

  @media (max-width: $sm-width) {
    flex-direction: column;
  }
}

.step-six__content-form__row-wrapper + .step-six__content-form__row-wrapper {
  padding-top: 16px;
}

.step-six__content-form__row {
  flex-grow: 1;
  flex-basis: 50%;
}

.step-six__payment-form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

.step-six__content-form__header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 0 8px;

  @media (max-width: $sm-width) {
    padding: 0;
  }
}

.step-six__content-form__legal-warning {
  font-size: $fs-text-s;
  font-weight: $fw-regular;
  color: $sk-grey;
  line-height: 10px;
  text-align: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: $sm-width) {
    margin-bottom: 120px; // To be able to see content page above the fixed footer
    margin-top: 32px;
  }
}

.step-six__content-summary {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 24px;
  min-width: 376px;
  max-width: 376px;
  background-color: white;
  position: relative;
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;

  @media (max-width: 730px) {
    min-width: 0;
    max-width: 100%;
    padding: 10px 24px;
  }

  @media (max-width: $sm-width) {
    padding: 10px 24px;
    font-size: $fs-text-xs;
  }
}

.step-six__content-summary::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(
    to right,
    var(--sk-azure-blue, #50a5ff),
    var(--sk-royal-blue, #2e26f2),
    var(--sk-violet, #8b45ff),
    var(--sk-coral, #ff7575),
    var(--sk-gold, #ffb545)
  );
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.step-six__content-summary__header {
  display: flex;
  padding: 0px 0px 16px 0px;
  gap: 16px;

  @media (max-width: 730px) {
    display: flex;
    align-items: center;
    max-width: 80%;
  }

  @media (max-width: $sm-width) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    padding-top: 10px;
  }
}

.step-six__content-summary__banner {
  position: absolute;
  right: -5px;
  top: 11px;
}

.step-six__content-summary__logo {
  background-color: $sk-blue-5;
  align-content: center;
  padding: 11px;
  border-radius: 16px;
  width: 52px;
  height: 52px;
}

.step-six__content-summary__header-subcontent {
  flex-direction: column;
}

.step-six__content-summary__pack-name {
  font-size: $fs-heading-xs;
  line-height: 30px;
  padding: 0 20px;

  @media (max-width: 730px) {
    font-size: $fs-text-l;
    display: flex;
    align-items: baseline;
  }

  @media (max-width: $sm-width) {
    flex-direction: column;
  }
}

.step-six__content-summary__pack-name--big {
  @media (max-width: 730px) {
    padding-left: 3px;
  }

  @media (max-width: $sm-width) {
    padding-left: 0;
  }
}

.step-six__content-summary__description {
  padding-top: 4px;

  @media (max-width: 730px) {
    padding: 10px 0;
  }

  @media (max-width: $sm-width) {
    padding: 10px 20px 14px;
  }
}

.step-six__content-summary__modalities {
  border-top: 1px solid $sk-grey-10;
  padding: 24px 0px 16px 0px;
  font-size: $fs-text-s;
}

.step-six__content-summary__modalities--bottom {
  padding-bottom: 0px;
}

.step-six__content-summary__modalities-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  &__extra-padding {
    padding-top: 12px;
  }
}

.step-six__content-summary_modalities-content-grouped-frequency-discount {
  display: flex;
  align-items: center;

  .step-six__content-summary__modalities-content--discount {
    border-radius: 2px;
    gap: 2px;
    height: 16px;
    margin-right: 8px;
    padding: 0 4px;
    width: 29px;
  }
}

.sk-text-medium-regular,
.sk-text-large-regular {
  color: $sk-black;
  &__grey {
    color: $sk-grey-50;
  }
}

.step-six__content-summary__modalities-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step-six__content-summary__modalities-price--total {
  padding-top: 12px;
  gap: 24px;
}

.step-six__content-summary__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: $fs-text-l;
  font-weight: $fw-semi-bold;
}

.step-six__content-summary__total-this-day {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid $sk-grey-10;
}

.step-six__content__submit-form {
  display: flex;
  width: 100%;
  padding-top: 32px;

  .step-six__content__submit-form--button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 864px) {
    bottom: 20px;
  }

  @media (max-width: $sm-width) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 16px 16px 16px;
    background-color: white;
    margin-left: 0px;
  }
}

.step-six__content__submit-form--within {
  @media (max-width: $sm-width) {
    display: none;
  }
}

.step-six__content__submit-form--outside {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  @media (min-width: $sm-width) {
    display: none;
  }
}

.step-six__content__submit-form__content {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.step-six__info-icon {
  flex-shrink: 0;
}

.step-six__payment-method__info {
  padding: 0 0 0 8px;
  line-height: normal;
}

.step-six__payment-method__row-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.step-six__content__submit-form__secure-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $sm-width) {
    display: none;
  }
}

.step-six__content__submit-form__secure-payment--outside {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-six__content__submit-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.step-six__content__submit-form__secure-payment__text {
  color: $sk-blue-50;
}

.step-six__payment-method-loader__row-wrapper {
  display: flex;
  justify-content: center;
  height: 36px;
}

</style>
