<template>
  <!-- eslint-disable max-len -->
  <div class="full-contract-salary__wrapper">
    <div
      v-if="isExtendedView"
      class="full-contract__title"
    >
      <div class="full-contract__title--bold">
        {{ $t('employees.tabs.contracts.full_contract.data.salary.title') }}
      </div>
      <div
        v-if="!isContractReadOnlyView"
        class="full-contract__title__mandatory-warning"
      >
        {{ $t('employees.tabs.contracts.full_contract.data.mandatory_fields') }}
      </div>
    </div>
    <template v-if="!isContractInterim">
      <div class="full-contract__content__form">
        <SkForm
          :title="$t('employees.tabs.contracts.full_contract.data.salary.counter_type.mandatory')"
          split
          class="counter_type_wrapper"
        >
          <div
            v-if="isContractReadOnlyView"
            class="row"
          >
            <div class="col-4 full-contract__readonly">
              {{ $t(`contract.counter_types.${counterType}.label`) }}
            </div>
            <div class="col-6 full-contract__form-tooltip">
              <CircledQuestionMarkIcon
                v-tooltip.top="counterTypeTooltip"
                width="18"
                height="18"
              />
            </div>
          </div>
          <template v-else>
            <div class="row sk-form__row">
              <div class="col-6">
                <SkSelectV2
                  v-model="counterType"
                  :options="counterTypes"
                  :disabled="!canEditWageAndHours"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.counter_type.input_label')"
                />
              </div>
              <div class="col-6 full-contract__form-tooltip">
                <CircledQuestionMarkIcon
                  v-tooltip.top="counterTypeTooltip"
                  width="18"
                  height="18"
                />
              </div>
            </div>
            <div
              v-if="isContractCounterTypeHourlyRate && !arePaidBreaksActivatedForEmployee || !isContractCounterTypeHourlyRate"
              class="row sk-form__row"
            >
              <div class="col-6">
                <SkInputGroup
                  :errored="hasCounterTypeValueError"
                  :error-message="$t(`employees.tabs.contracts.full_contract.data.salary.${counterType}.error`)"
                  :class="counterTypeUnitClass"
                  :is-highlighted="showHighlightForField(contractHours) && showHighlightForField(workDays)"
                >
                  <SkInput
                    v-if="isContractCounterTypeHourlyRate && !arePaidBreaksActivatedForEmployee"
                    v-model="contractHours"
                    v-tooltip="contractHoursTooltip"
                    :disabled="isContractHoursDisabled()"
                    :label="$t(`employees.tabs.contracts.full_contract.data.salary.${counterType}.input_label`)"
                    type="number"
                    min="0"
                  />
                  <SkInput
                    v-if="!isContractCounterTypeHourlyRate"
                    v-model="workDays"
                    :disabled="!canEditWageAndHours"
                    :label="$t(`employees.tabs.contracts.full_contract.data.salary.${counterType}.input_label`)"
                    :debounce="warnWorkDaysOverLimit"
                    :debounce-ms="700"
                    type="number"
                    min="0"
                  />
                  <template #append>
                    {{ $t(`contract.counter_types.${counterType}.unit`) }}
                  </template>
                </SkInputGroup>
                <div
                  v-if="displayContractHoursLabel"
                  class="full-contract__content__form__label"
                >
                  {{ $t(`employees.tabs.contracts.full_contract.data.salary.${counterType}.label`) }}
                </div>
              </div>
              <div class="col-6 full-contract__form-tooltip">
                <CircledQuestionMarkIcon
                  v-tooltip.top="$t(`employees.tabs.contracts.full_contract.data.salary.${counterType}.tooltip`)"
                  width="18"
                  height="18"
                />
              </div>
            </div>
          </template>
        </SkForm>
      </div>
      <div
        v-if="!isContractReadOnlyView && isContractCounterTypeHourlyRate && arePaidBreaksActivatedForEmployee"
        class="full-contract__content__form"
      >
        <SkForm
          :title="$t('employees.tabs.contracts.full_contract.data.salary.distribution_of_attendance_time.label')"
          :border="false"
          id="distribution_of_attendance_time_form"
          split
        >
          <div class="row sk-form__row">
            <div class="col-11">
              <SkInputGroup
                :errored="contractHours === ''"
                :error-message="$t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.error')"
              >
                <SkInput
                  v-model="contractHours"
                  min="0"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.input_label')"
                  type="number"
                  data-test="contract__attendance-hourly-volume"
                />
                <template #append>
                  {{ $t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.unit') }}
                </template>
              </SkInputGroup>
            </div>
            <div class="col-1 hourly_volume_of_presence_time_tooltip_wrapper">
              <CircledQuestionMarkIcon
                v-tooltip.auto.oneLine="$t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.tooltip', {hourlyVolumeOfPresenceTime, percentageOfPaidBreakTime})"
                width="18"
                height="18"
              />
            </div>
          </div>
          <div class="row sk-form__row">
            <div class="col-11">
              <SkInputGroup
                :errored="compensatedBreakHoursPercentage === ''"
                :error-message="$t('employees.tabs.contracts.full_contract.data.salary.percentage_of_paid_break_time.error')"
              >
                <SkInput
                  v-model="compensatedBreakHoursPercentage"
                  min="0"
                  max="100"
                  :debounce="validateCompensatedBreakHoursPercentage"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.percentage_of_paid_break_time.input_label')"
                  type="number"
                  data-test="contract__percentage-compensated-paid-breaks"
                />
                <template #append>
                  {{ $t('employees.tabs.contracts.full_contract.data.salary.percentage_of_paid_break_time.unit') }}
                </template>
              </SkInputGroup>
            </div>
          </div>
        </SkForm>
      </div>
      <!-- This contract line depends on the counterType (the previous one) -->
      <div
        v-if="isContractReadOnlyView"
        class="full-contract__content__form"
      >
        <SkForm
          v-if="!arePaidBreaksActivatedForEmployee"
          class="sk-form--split--no-top-border sk-form--no-min-height"
          split
        >
          <div class="row sk-form__row--concatenated">
            <div class="col-4 full-contract__readonly">
              <span v-if="isContractCounterTypeHourlyRate">
                {{ $t('employees.tabs.contracts.full_contract.data.salary.hourly_rate.value', { value: contractHours }) }}
              </span>
              <span v-else>
                {{ $t('employees.tabs.contracts.full_contract.data.salary.daily_rate.value', { value: workDays }) }}
              </span>
              <span class="ml-1">
                {{ $t(`contract.counter_types.${counterType}.unit`) }}
              </span>
            </div>
            <div class="col-6 full-contract__form-tooltip">
              <CircledQuestionMarkIcon
                v-tooltip.top="$t(`employees.tabs.contracts.full_contract.data.salary.${counterType}.tooltip`)"
                width="18"
                height="18"
              />
            </div>
            <div
              v-if="isContractCounterTypeHourlyRate && !!activeTemporaryAmendment"
              class="col-6 full-contract__readonly"
            >
              <SkTag
                v-if="displayAmendments"
                class="full-contract__amendment-tag"
                variant="blue"
                with-bullet-point
              >
                {{ activeTemporaryAmendmentText }}
              </SkTag>
            </div>
          </div>
        </SkForm>
        <div
          v-else
          class="full-contract__content__form"
        >
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.label')"
            class="full-contract__readonly-status-block"
            split
          >
            <div class="row">
              <div class="col-12 full-contract__readonly hourly_volume_of_presence_time_wrapper">
                <div data-test="contract__hourly-work-hours">
                  {{ $t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.value', { value: contractHours }) }}
                </div>
                <CircledQuestionMarkIcon
                  v-tooltip.auto.oneLine="$t('employees.tabs.contracts.full_contract.data.salary.hourly_volume_of_presence_time.tooltip', {hourlyVolumeOfPresenceTime, percentageOfPaidBreakTime})"
                  width="18"
                  height="18"
                />
              </div>
            </div>
          </SkForm>
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.salary.percentage_of_paid_break_time.label')"
            class="full-contract__readonly-status-block"
            split
          >
            <div class="row">
              <div
                class="col-12 full-contract__readonly"
                data-test="contract__hourly-work-paid-breaks"
              >
                {{ $t('employees.tabs.contracts.full_contract.data.salary.percentage_of_paid_break_time.value', { value: compensatedBreakHoursPercentage }) }}
              </div>
            </div>
          </SkForm>
        </div>
      </div>
      <div
        v-if="isAnnualizationEnabled && !hasAmendments"
        class="full-contract__content__form"
      >
        <SkForm
          :title="$t('employees.tabs.contracts.full_contract.data.salary.annualization.label')"
          split
        >
          <template #title-icon>
            <div class="annualization__icon-title__wrapper">
              <div class="new-tag__wrapper">
                <NewTag />
              </div>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.annualization.tooltip')"
                width="18"
                height="18"
              />
            </div>
          </template>
          <div
            v-if="isContractReadOnlyView"
            class="row"
          >
            <div class="col-4 full-contract__readonly">
              <span class="ml-1">
                {{ $t('employees.tabs.contracts.full_contract.data.salary.annualization.value', { value: annualizationTotal }) }}
              </span>
              {{ $t('employees.tabs.contracts.full_contract.data.salary.annualization.unit') }}
            </div>
          </div>
          <template v-else>
            <div class="row sk-form__row">
              <div class="col-6">
                <SkInputGroup
                  :errored="hasAnnualizationTotalValueError"
                  :error-message="$t('employees.tabs.contracts.full_contract.data.salary.annualization.error')"
                >
                  <SkInput
                    v-model="annualizationTotal"
                    :disabled="!canEditWageAndHours"
                    :label="$t('employees.tabs.contracts.full_contract.data.salary.annualization.input_label')"
                    :debounce="warnAnnualizationTotalOverLimit"
                    :debounce-ms="700"
                    type="number"
                    min="0"
                  />
                  <template #append>
                    {{ $t('employees.tabs.contracts.full_contract.data.salary.annualization.unit') }}
                  </template>
                </SkInputGroup>
              </div>
            </div>
          </template>
        </SkForm>
      </div>
      <div
        v-if="isCyclicAmendmentsSectionDisplayed"
        class="full-contract__content__form"
      >
        <SkForm
          :class="cyclicAmendmentsFormClass"
          split
        >
          <template #title>
            <label class="full-contract__content__form-label">
              <b>
                {{ $t('employees.tabs.contracts.full_contract.data.salary.variable_hours.title') }}
              </b>
              <br>
              {{ $t('employees.tabs.contracts.full_contract.data.salary.variable_hours.description') }}
            </label>
          </template>
          <div :class="cyclicAmendmentsRowClasses">
            <div :class="cyclicAmendmentLeftClasses">
              <div
                v-for="amendment in updatedCyclicAmendments"
                :key="`cyclicAmendment-${amendment.attributes.scheduleWeekIndex}`"
                :class="cyclicAmendmentInputsClasses"
              >
                <div
                  v-if="!isContractReadOnlyView"
                  class="col-9"
                >
                  <VariableContractHoursTag
                    class="full-contract__variable_hours__weekly-tag"
                    :nb-weeks="updatedCyclicAmendments.length"
                    :specific-week-index="amendment.attributes.scheduleWeekIndex"
                  />
                  <SkInputGroup
                    :errored="amendment.hasError"
                    :error-message="$t('employees.tabs.contracts.full_contract.data.salary.hourly_rate.error')"
                    :class="counterTypeUnitClass"
                  >
                    <SkInput
                      v-model="amendment.attributes.hours"
                      :label="$t('employees.tabs.contracts.full_contract.data.salary.variable_hours.input_label')"
                      type="number"
                      min="0"
                      @blur="changeCyclicAmendmentValue(amendment)"
                    />
                    <template #append>
                      {{ $t('contract.counter_types.hourly_rate.unit') }}
                    </template>
                  </SkInputGroup>
                  <div
                    v-if="displayContractHoursLabel"
                    class="full-contract__content__form__label"
                  >
                    {{ $t('employees.tabs.contracts.full_contract.data.salary.hourly_rate.label') }}
                  </div>
                </div>
                <!-- read only view -->
                <div
                  v-else
                  class="col-8 full-contract__readonly"
                >
                  <VariableContractHoursTag
                    class="full-contract__variable_hours__weekly-tag"
                    :nb-weeks="updatedCyclicAmendments.length"
                    :specific-week-index="amendment.attributes.scheduleWeekIndex"
                  />
                  <span>
                    {{ $t('employees.tabs.contracts.full_contract.data.salary.hourly_rate.value',
                          { value: amendment.attributes.hours }) }}
                  </span>
                  <span class="ml-1">
                    {{ $t(`contract.counter_types.${counterType}.unit`) }}
                  </span>
                </div>
                <div :class="tooltipContainerClasses">
                  <CircledQuestionMarkIcon
                    v-tooltip.top="$t(
                      'employees.tabs.contracts.full_contract.data.salary.variable_hours.input_tooltip',
                      { week_index: cyclicAmendmentWeekIndex(amendment)},
                    )"
                    width="18"
                    height="18"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="isRollingPostMvpAvailable"
              class="col-6"
            >
              <SkOroraButton
                v-modal.variable-hours-history-modal
                variant="tertiary"
              >
                {{ $t('employees.tabs.contracts.full_contract.data.salary.variable_hours.historic') }}
              </SkOroraButton>
            </div>
          </div>
        </SkForm>
      </div>
    </template>

    <template v-if="displayAmendments">
      <div class="full-contracts__amendments">
        <Amendments :employee-annualization-config="employeeAnnualizationConfig || {}" />
      </div>
    </template>

    <template v-if="canReadSensitiveData">
      <template v-if="isContractReadOnlyView">
        <div class="full-contract__content__form">
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.salary.monthly_salary.label')"
            class="full-contract__readonly-status-block"
            split
          >
            <template #title-icon>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.monthly_salary.tooltip')"
                width="18"
                height="18"
              />
            </template>
            <div class="row">
              <div class="col-12 full-contract__readonly">
                {{ $tc('employees.tabs.contracts.full_contract.data.salary.monthly_salary.value', monthlySalary, { count: monthlySalary, currency_symbol: currencySymbol }) }}
              </div>
            </div>
          </SkForm>
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage.label')"
            class="full-contract__readonly-status-block"
            split
          >
            <template #title-icon>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage.tooltip')"
                width="18"
                height="18"
              />
            </template>
            <div class="row">
              <div class="col-12 full-contract__readonly">
                {{ $tc('employees.tabs.contracts.full_contract.data.salary.hourly_wage.value', hourlyWage, { count: hourlyWage, currency_symbol: currencySymbol }) }}
              </div>
            </div>
          </SkForm>
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.label')"
            class="full-contract__readonly-status-block"
            split
          >
            <template #title-icon>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.tooltip')"
                width="18"
                height="18"
              />
            </template>
            <div class="row">
              <div class="col-12 full-contract__readonly">
                {{ $tc('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.value', hourlyWageWithCosts, { count: hourlyWageWithCosts,currency_symbol: currencySymbol }) }}
              </div>
            </div>
          </SkForm>
        </div>
      </template>

      <template v-else-if="canEditSensitiveData">
        <div class="full-contract__content__form">
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.salary.calculation_type.label')"
            split
          >
            <div class="row sk-form__row">
              <div class="col-6">
                <SkSelectV2
                  v-model="salaryCalculationType"
                  :disabled="!canEditSensitiveData"
                  :options="salaryCalculationTypes"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.calculation_type.input_label')"
                />
              </div>
            </div>
            <div
              class="row sk-form__row"
              :class="isSalaryCalculationTypeMonthlySalary ? '' : 'sk-form__row--reverse-direction-flex'"
            >
              <div class="col-6">
                <SkInput
                  v-model="monthlySalary"
                  v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.monthly_salary.tooltip')"
                  :disabled="!canEditSensitiveData"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.monthly_salary.label')"
                  type="number"
                  :is-highlighted="showHighlightForField(monthlySalary)"
                  @keyup="updateHourlyWageWithCosts"
                />
              </div>
              <div class="col-6">
                <SkInput
                  v-model="hourlyWage"
                  v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage.tooltip')"
                  :disabled="!canEditSensitiveData"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage.label')"
                  type="number"
                  :is-highlighted="showHighlightForField(hourlyWage)"
                  @keyup="updateHourlyWageWithCosts"
                />
              </div>
            </div>
            <div
              class="row"
            >
              <div class="col-6">
                <SkInput
                  v-model="hourlyWageWithCosts"
                  v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.tooltip')"
                  :disabled="!canEditSensitiveData"
                  :label="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.label')"
                  type="number"
                  :is-highlighted="showHighlightForField(hourlyWageWithCosts)"
                  @keyup="onManualSetting"
                />
              </div>
              <div class="col-6">
                <SkOroraButton
                  v-tooltip.bottom="$t('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.action.estimate.tooltip', hourlyWageRate, {
                    rate: hourlyWageRate
                  })"
                  variant="tertiary"
                  :disabled="!canEditSensitiveData"
                  @click="estimateHourlyWageWithCosts"
                >
                  {{ $t('employees.tabs.contracts.full_contract.data.salary.hourly_wage_with_costs.action.estimate.label') }}
                </SkOroraButton>
              </div>
            </div>
          </SkForm>
        </div>
      </template>
    </template>

    <div
      v-if="isExtendedView"
      class="full-contract__content__form"
    >
      <SkForm
        :title="$t('employees.tabs.contracts.full_contract.data.salary.transport_cost.label')"
        split
      >
        <template #title-icon>
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.salary.transport_cost.tooltip')"
            width="18"
            height="18"
          />
        </template>
        <div
          v-if="isContractReadOnlyView"
          class="row"
        >
          <div class="col-6 full-contract__readonly">
            {{
              $tc('employees.tabs.contracts.full_contract.data.salary.transport_cost.value', transportCost, {
                count: transportCost,
                currency_symbol: currencySymbol
              })
            }}
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-6">
            <SkInput
              v-model="transportCost"
              :disabled="!canEditContract"
              :label="$t('employees.tabs.contracts.full_contract.data.salary.transport_cost.input_label')"
              type="number"
            />
          </div>
        </div>
      </SkForm>
    </div>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import { getTheoreticalBalanceForPeriod } from '@skelloapp/skello-annualization';

import { arrayToSelectOptions } from '@skello-utils/form';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';

import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';
import NewTag from '@app-js/shared/components/NewTag';

import Amendments from './Amendments';

export default {
  name: 'SalaryData',
  components: { Amendments, VariableContractHoursTag, NewTag },
  mixins: [payrollPreparationMixins],
  props: {
    isExtendedView: {
      type: Boolean,
      default: false,
    },
    updatedCyclicAmendments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hasUserTypedLocalInput: false,
      hourlyWageManuallySet: false,
    };
  },
  computed: {
    ...mapState('annualization', ['currentPeriodStartDate']),
    ...mapState('config', ['config']),
    ...mapState('contracts', ['contract']),
    ...mapState('amendments', ['amendments']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee', 'employeeAnnualizationConfig']),
    ...mapState('employees', ['showIncompleteEmployees']),
    ...mapGetters('contracts', [
      'isContractInterim',
      'isContractIntern',
      'isContractCounterTypeHourlyRate',
      'isSalaryCalculationTypeMonthlySalary',
      'isContractReadOnlyView',
      'isContractVersionView',
      'isContractCreationView',
      'hourlyWageWithCostsEstimation',
    ]),
    ...mapGetters('amendments', [
      'activeTemporaryAmendment',
      'hasActivePermanentAmendment',
      'activePermanentAmendment',
      'hasAmendments',
    ]),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrStrictSubordinate', 'primaryShopNode']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('currentLicense', [
      'canEditEmployeeInfo',
      'canManageEmployeeAmendments',
      'canReadEmployeePersonalInfosAndBankData',
      'canEditEmployeePersonalInfosAndBankData',
      'canEditWageAndTime',
    ]),
    ...mapGetters('currentShop', [
      'isVariableContractHoursAvailable',
      'isRollingPostMvpAvailable',
      'defaultHourlyWageRate',
      'arePaidBreaksActivated',
    ]),

    // permissions
    canEditContract() {
      return this.canEditEmployeeInfo && this.isCurrentUserOrStrictSubordinate;
    },
    canEditWageAndHours() {
      return (this.canEditWageAndTime || this.isContractCreationView) &&
        this.isCurrentUserOrStrictSubordinate;
    },
    canReadSensitiveData() {
      return this.canReadEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    canEditSensitiveData() {
      return this.canEditEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    canManageAmendments() {
      return this.canManageEmployeeAmendments && this.isCurrentUserOrStrictSubordinate;
    },
    // other
    activeTemporaryAmendmentText() {
      if (!this.activeTemporaryAmendment) return null;

      const position = this.activeTemporaryAmendment.attributes.typePosition;
      const hours = this.activeTemporaryAmendment.attributes.hours;

      return this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.amendment_tag', { position, hours });
    },
    currencySymbol() {
      return this.employee.relationships.shop.attributes.currencySymbol;
    },

    counterType: {
      get() {
        return this.contract.attributes.counterType;
      },
      set(newCounterType) {
        this.hasUserTypedLocalInput = false;
        this.setContractAttributes({ counterType: newCounterType });
        this.handleCounterTypeChange();
      },
    },
    counterTypes() {
      return arrayToSelectOptions(
        this.config.contract_data.counter_types_collection,
        value => this.$t(`contract.counter_types.${value}.label`),
      );
    },
    hasCounterTypeValueError() {
      if (!this.hasUserTypedLocalInput && this.contract.attributes.contractHours !== 0) {
        return false;
      }

      if (this.isContractCounterTypeHourlyRate) {
        return !this.contract.attributes.contractHours;
      }

      return !this.contract.attributes.workDays;
    },
    counterTypeTooltip() {
      if (this.isContractReadOnlyView) {
        return this.$t('employees.tabs.contracts.full_contract.data.salary.counter_type.tooltips.read_only');
      }

      return this.$t('employees.tabs.contracts.full_contract.data.salary.counter_type.tooltips.update');
    },
    counterTypeUnitClass() {
      return {
        'full-contract__unit--disabled': !this.canEditWageAndHours,
      };
    },
    cyclicAmendmentInputsClasses() {
      return {
        row: true,
        'sk-form__row': !this.isContractReadOnlyView,
      };
    },
    cyclicAmendmentLeftClasses() {
      return {
        'col-6': this.isContractReadOnlyView,
        'col-8': !this.isContractReadOnlyView,
      };
    },

    // contractHours
    contractHours: {
      get() {
        if (this.hasActivePermanentAmendment) {
          return this.activePermanentAmendment.attributes.hours;
        }

        return this.contract.attributes.contractHours;
      },
      set(newContractHours) {
        this.hasUserTypedLocalInput = true;
        if (!this.hasActivePermanentAmendment) {
          this.setContractAttributes({ contractHours: newContractHours });
        }
      },
    },
    contractHoursTooltip() {
      if (this.isContractHoursDisabled()) {
        const translationKey = this.employeeAnnualizationConfig ?
          'employees.tabs.contracts.full_contract.data.salary.hourly_rate.disabled.amendment_warning' :
          'employees.tabs.contracts.full_contract.data.salary.hourly_rate.disabled.unauthorized';
        return this.$t(translationKey);
      }

      if (!this.hasActivePermanentAmendment) {
        return '';
      }

      return this.$t('employees.tabs.contracts.full_contract.data.salary.hourly_rate.disable_tooltip');
    },

    // workDays
    workDays: {
      get() {
        return this.contract.attributes.workDays;
      },
      set(newWorkDays) {
        this.hasUserTypedLocalInput = true;
        this.setContractAttributes({ workDays: newWorkDays });
      },
    },

    isAnnualizationEnabled() {
      return Object.keys(this.employeeAnnualizationConfig || {}).length > 0;
    },

    hasAnnualizationTotalValueError() {
      if (!this.hasUserTypedLocalInput && this.annualizationTotal > 0) {
        return false;
      }

      return !this.employeeAnnualizationConfig.theoreticalBalances;
    },
    annualizationTotal: {
      get() {
        return getTheoreticalBalanceForPeriod(
          this.currentPeriodStartDate.toDate(),
          this.employeeAnnualizationConfig.theoreticalBalances,
        );
      },
      set(newAnnualizationTotal) {
        this.hasUserTypedLocalInput = true;
        this.updateAnnualizationTotal({
          newAnnualizationTotal,
          periodTimestamp: this.currentPeriodStartDate.valueOf(),
        });
      },
    },

    // salaryCalculationType
    salaryCalculationType: {
      get() {
        return this.contract.attributes.salaryCalculationType;
      },
      set(newSalaryCalculationType) {
        this.setContractAttributes({ salaryCalculationType: newSalaryCalculationType });
      },
    },
    salaryCalculationTypes() {
      return arrayToSelectOptions(
        this.config.contract_data.calculation_collection,
        value => this.$t(`contract.calculation_types.${value}`,
        ));
    },

    // amendments
    displayAmendments() {
      return this.canManageAmendments &&
        !this.isContractInterim &&
        this.isContractCounterTypeHourlyRate &&
        !this.isContractCreationView &&
        this.isExtendedView &&
        !this.isContractVersionView;
    },

    // monthly Salary
    monthlySalary: {
      get() {
        return this.contract.attributes.monthlySalary;
      },
      set(newMonthlySalary) {
        this.updateContractMonthlySalary({ monthlySalary: newMonthlySalary });
      },
    },

    // hourly wage
    hourlyWage: {
      get() {
        return this.contract.attributes.hourlyWage;
      },
      set(newHourlyWage) {
        this.updateContractHourlyWage({ hourlyWage: newHourlyWage });
      },
    },

    // hourlyWageWithCosts
    hourlyWageWithCosts: {
      get() {
        return this.contract.attributes.hourlyWageWithCosts;
      },
      set(newHourlyWageWithCosts) {
        this.setContractAttributes({ hourlyWageWithCosts: newHourlyWageWithCosts });
      },
    },
    hourlyWageRate() {
      return this.primaryShopNode.attributes.hourlyWageRate;
    },
    transportCost: {
      get() {
        return this.contract.attributes.transportCost;
      },
      set(newTransportCost) {
        this.setContractAttributes({ transportCost: newTransportCost });
      },
    },
    displayContractHoursLabel() {
      return this.isContractCreationView && !this.hasCounterTypeValueError;
    },
    cyclicAmendmentsFormClass() {
      return {
        'content__form__section--read-only--cyclic_amendments': this.isContractReadOnlyView,
      };
    },
    cyclicAmendmentsRowClasses() {
      return {
        row: true,
        'row--cyclic-amendments': !this.isContractReadOnlyView,
      };
    },
    tooltipContainerClasses() {
      return {
        'full-contract__form-tooltip': true,
        'col-3': !this.isContractReadOnlyView,
        'col-4': this.isContractReadOnlyView,
      };
    },
    isCyclicAmendmentsSectionDisplayed() {
      return this.isVariableContractHoursAvailable &&
        this.isContractCounterTypeHourlyRate &&
        this.updatedCyclicAmendments.length > 0;
    },
    compensatedBreakHoursPercentage: {
      get() {
        return this.contract.attributes.compensatedBreakHoursPercentage;
      },
      set(newCompensatedBreakHoursPercentage) {
        this.setContractAttributes({
          compensatedBreakHoursPercentage: newCompensatedBreakHoursPercentage,
        });
      },
    },
    hourlyVolumeOfPresenceTime() {
      const { compensatedBreakHoursPercentage } = this.contract.attributes;

      const value = this.contractHours / (1 + compensatedBreakHoursPercentage / 100);

      return Math.trunc(value * 100) / 100;
    },
    percentageOfPaidBreakTime() {
      const { compensatedBreakHoursPercentage } = this.contract.attributes;

      const value = this.contractHours - (
        this.contractHours / (1 + compensatedBreakHoursPercentage / 100)
      );

      return Math.trunc(value * 100) / 100;
    },
    arePaidBreaksActivatedForEmployee() {
      return this.arePaidBreaksActivated({
        shop: this.employee.relationships.shop,
        convention: this.employee.relationships.convention,
      });
    },
  },
  mounted() {
    if (this.contract.attributes.hourlyWageWithCosts &&
      this.contract.attributes.hourlyWageWithCosts !==
      this.hourlyWageWithCostsEstimation(this.hourlyWageRate)
    ) {
      this.onManualSetting();
    }
    this.tweakStyles();
  },
  updated() {
    this.tweakStyles();
  },
  methods: {
    ...mapMutations('selectedEmployee', ['updateAnnualizationTotal']),
    ...mapMutations('contracts', ['setContractAttributes', 'setErrorToContract']),
    ...mapActions('contracts', ['updateContractHourlyWage', 'updateContractMonthlySalary']),

    validateCompensatedBreakHoursPercentage() {
      if (this.compensatedBreakHoursPercentage === '') return;

      this.compensatedBreakHoursPercentage =
        Math.max(0, Math.min(this.compensatedBreakHoursPercentage, 100));
    },
    warnWorkDaysOverLimit() {
      if (this.workDays > 218) {
        this.warningToast(
          this.$t('employees.tabs.contracts.full_contract.data.salary.daily_rate.warning'),
        );
      }
    },
    warnAnnualizationTotalOverLimit() {
      if (this.annualizationTotal > 8760) {
        this.warningToast(
          this.$t('employees.tabs.contracts.full_contract.data.salary.annualization.warning'),
        );
      }
    },
    warningToast(msg) {
      this.$skToast({
        message: msg,
        variant: 'information',
        containerId: 'employees__container',
      });
    },
    isContractHoursDisabled() {
      return (
        !this.canEditWageAndHours ||
        this.hasActivePermanentAmendment ||
        (this.employeeAnnualizationConfig && !this.isContractCreationView)
      );
    },
    onManualSetting() {
      this.hourlyWageManuallySet = true;
    },
    // hourlyWageWithCosts is updated when you modify hourly_wage or monthly_salary
    // But only when the manual modification is false
    updateHourlyWageWithCosts() {
      if (this.hourlyWageManuallySet) return;

      this.setContractAttributes({
        hourlyWageWithCosts: this.hourlyWageWithCostsEstimation(this.hourlyWageRate),
      });
    },
    estimateHourlyWageWithCosts() {
      if (this.isContractCreationView) this.$skAnalytics.track('multicontract_estimated_hourly_rate_on_new');

      this.hourlyWageManuallySet = false;

      this.setContractAttributes({
        hourlyWageWithCosts: this.hourlyWageWithCostsEstimation(this.hourlyWageRate),
      });
    },
    handleCounterTypeChange() {
      if (this.isContractCounterTypeHourlyRate) {
        this.setContractAttributes({ workDays: 0 });
      } else {
        this.setContractAttributes({ contractHours: 0 });
      }
    },
    changeCyclicAmendmentValue(amendment) {
      this.$set(
        amendment,
        'hasError',
        !amendment.attributes.hours && amendment.attributes.hours !== 0,
      );

      if (amendment.hasError) return;

      this.$emit('cyclic-amendment-input');
    },
    cyclicAmendmentWeekIndex(amendment) {
      const allWeekIndexes = 'ABCDEFGHIJKLM';

      return allWeekIndexes.charAt(amendment.attributes.scheduleWeekIndex);
    },
    tweakStyles() {
      // Necessary for design guidelines
      // TODO : extend the UI lib to fix it
      const padding = this.isContractCounterTypeHourlyRate ? 0 : '13px';
      if (document.querySelector('#distribution_of_attendance_time_form .sk-form-body-section')) {
        document.querySelector('#distribution_of_attendance_time_form .sk-form-body-section').style.paddingTop = padding;
      }
      document.querySelector('.counter_type_wrapper .sk-form-body-section').style.paddingBottom = padding;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-contracts__amendments {
  padding: 10px 30px 0;
  margin-bottom: 20px;
}

.sk-form__row--reverse-direction-flex {
  display: flex;
  flex-direction: row-reverse;
}

.full-contract__content__form__label {
  font-size: $fs-text-s;
  height: 15px;
  color: $sk-grey;
  text-align: center;
  padding-top: 5px;
  padding-left: 10px;
}

.full-contract__form-tooltip {
  display: flex;
  align-items: center;
}

// all the css should be re-do to prevent patch like that
// SkSpitForm is very useful but sometimes lacks of flexibility
.full-contract__contact__amendment-ongoing {
  top: -3px;
}

.full-contract__amendment-tag {
  width: fit-content;
}

.full-contract__unit--disabled {
  color: $sk-grey;
}

.sk-form--no-min-height {
  min-height: initial;
  padding-bottom: 2px !important;
}

.sk-form__row--concatenated {
  margin-top: -30px;
}

.full-contract__content__form-label {
  width: 204px;
}

.full-contract__variable_hours__weekly-tag {
  position: absolute;
  top: 12px;
  left: -25px;
}

.content__form__section--read-only--cyclic_amendments {
  padding: 5px 40px 3px !important;
}

.row--cyclic-amendments {
  padding: 1px 0 17px;
}

.annualization__icon-title__wrapper {
  display: flex;

  .new-tag__wrapper {
    padding-right: 16px;
  }
}

.hourly_volume_of_presence_time_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hourly_volume_of_presence_time_tooltip_wrapper svg {
  position: absolute;
  margin-top: 13px;
  right: 18px;
}
</style>
