export default {
  fr: {
    basic: { monthly: 89.0, annual: 961.2 },
    success: { monthly: 109.0, annual: 1177.2 },
    premium: { monthly: 162.0, annual: 1749.6 },
    'Timeclock Standalone Success': { monthly: 79.0, annual: 853.0 },
    options: {
      timeclock: { monthly: 40, annual: 432 },
    },
  },
  es: {
    basic: { monthly: 69.0, annual: 745.2 },
    success: { monthly: 79.0, annual: 853.2 },
    premium: { monthly: 119.0, annual: 1285.2 },
    'Timeclock Standalone Success': { monthly: 69.0, annual: 745.0 },
    options: {
      timeclock: { monthly: 30, annual: 324 },
    },
  },
};
