<template>
  <div>
    <template v-if="!loading">
      <div class="current-contract__header__wrapper">
        <div class="employees__content-wrapper">
          <div class="current-contract__header">
            <div class="current-contract__header__title">
              <ContractSpaceIcon />
              <span class="ml-3">
                {{ $t('employees.tabs.contracts.header.label') }}
              </span>
            </div>
            <div
              v-if="canEditContract"
              v-tooltip.left="addButtonTooltip"
              class="current-contract__header__actions"
            >
              <SkPopover
                :disabled="isMulticontractsEnabled"
                placement="left"
                as-tooltip
              >
                <template #anchor>
                  <DropdownButton
                    v-if="isUpgradeMultiContractsEnabled"
                    :disabled="!isMulticontractsEnabled || !canCreateVersion && !canCreateContract"
                    :button-tooltip="multicontractDropdownButtonTooltip"
                    :button-tooltip-modifiers="['left']"
                    :items="multicontractsDropdownItems"
                    :items-tooltip-modifiers="['left']"
                    :prevent-overflow="false"
                    fill="outlined"
                    placement="bottom-start"
                    trigger="click"
                  >
                    {{ $t('employees.tabs.contracts.actions.add_contract.upgrade.label') }}
                  </DropdownButton>
                  <SkOroraButton
                    v-else
                    v-track="'multicontract_new_contract_button'"
                    icon="PlusSignV2Icon"
                    :disabled="!isCreateContractAllowed"
                    @click="displayNewContractMode"
                  >
                    {{ $t('employees.tabs.contracts.actions.add_contract.label') }}
                  </SkOroraButton>
                </template>
                <template #content>
                  {{ $t('employees.tabs.contracts.actions.add_contract.pack_tooltip.your_pack') }}
                  <PackTag :type="packType" />
                  {{ $t('employees.tabs.contracts.actions.add_contract.pack_tooltip.not_allowed') }}
                  <br>
                  <br>
                  <SkOroraButton @click="requestUpsell">
                    {{ $t('employees.tabs.contracts.actions.add_contract.pack_tooltip.upgrade_pack') }}
                  </SkOroraButton>
                </template>
              </SkPopover>
            </div>
          </div>
        </div>
      </div>
      <div class="employees__content-wrapper">
        <div
          v-if="isUniqIncompleteContract"
          class="current-contract__no-contract--wrapper"
        >
          <div class="current-contract__no-contract">
            <CircledExclamationMarkIcon
              class="current-contract__no-contract__warning"
              fill=""
            />
            {{ $t('employees.tabs.contracts.current.no_contract') }}
          </div>
        </div>
        <div
          v-else
          class="current-contract__wrapper"
        >
          <template>
            <div class="current-contract__container__wrapper">
              <div class="current-contract__container__main">
                <div class="current-contract__container__header">
                  <div>
                    <ContractIcon />
                  </div>
                  <div class="current-contract__container__header--without-icon">
                    <div class="current-contract__container__title">
                      {{ contractTypeName }}
                    </div>
                    <div>
                      <div class="current-contract__subtitle">
                        <span class="current-contract__poste_name">
                          {{ posteName }}
                        </span>
                        <div
                          v-if="isActive"
                          class="current-contract__bullet-separator"
                        />
                        <SkTag
                          v-if="isActive"
                          variant="blue"
                          with-bullet-point
                        >
                          {{ $t('employees.tabs.contracts.current.attributes.status.active') }}
                        </SkTag>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="displayMissingInformationNotification('user_contracts')"
                  class="current-contract__container__notification"
                >
                  <MissingInformationNotification />
                </div>
                <div class="current-contract__container__content">
                  <div>
                    <div class="current-contract__content__wrapper">
                      <div class="current-contract__content">
                        <ContractAttribute
                          :value="effectivePeriod"
                          :label="$t('employees.tabs.contracts.current.attributes.effective_period')"
                        />

                        <ContractAttribute
                          v-tooltip="amendmentTooltip"
                          :class="isContractCounterTypeDailyRate ?
                            'current-contract__content--disabled' :
                            ''
                          "
                          :value="amendmentsCountDisplay"
                          :label="$t('employees.tabs.contracts.current.attributes.amendments_contract_hours')"
                          with-action
                        >
                          <template #action>
                            <div
                              v-if="canAddAmendments"
                              v-track="'multicontract_quick_add_contracted_hours_amendment'"
                              class="current-contract__content__amendment-action"
                              data-test="quick_add_admendment"
                            >
                              <SkOroraButton
                                v-if="amendmentsCount === 0"
                                variant="tertiary"
                                size="small"
                                :loading="areAnnualizationConfigsLoading"
                                @click="(event) => {
                                  openCreateAmendmentModal(
                                    event,
                                    'quick_add_contracted_hours_amendment'
                                  )
                                }"
                              >
                                {{ $t('employees.tabs.contracts.current.attributes.add_amendment') }}
                              </SkOroraButton>
                              <SkOroraButton
                                v-else
                                variant="secondary"
                                size="x-small"
                                class="current-contract__content__amendment-action-display"
                                :loading="areAnnualizationConfigsLoading"
                                @click="(event) => {
                                  openCreateAmendmentModal(
                                    event,
                                    'annualization_quick_add_contracted_hours_amendment'
                                  );
                                }"
                              >
                                {{ $t('employees.tabs.contracts.current.attributes.add_amendment') }}
                              </SkOroraButton>
                            </div>
                          </template>
                        </ContractAttribute>
                      </div>

                      <div class="current-contract__content">
                        <ContractAttribute
                          v-if="!canEditSensitiveData"
                          v-tooltip.top="calulationTypeTooltip"
                          :value="salaryCalculationValue"
                          :label="$t(`contract.calculation_types.${salaryCalculationType}`)"
                        />
                        <div
                          v-else
                          class="current-contract__wage-salary__wrapper"
                        >
                          <div class="current-contract__wage-salary__label">
                            <SkSelectV2
                              v-model="salaryCalculationType"
                              :options="salaryCalculationTypes"
                            />
                          </div>
                          <div class="current-contract__wage-salary__content-wrapper">
                            <div v-if="showAddValueAction">
                              <SkPopover
                                ref="wageOrSalaryPopover"
                                placement="bottom-start"
                                has-footer
                                :title="$t(`employees.tabs.contracts.full_contract.data.salary.${salaryCalculationType}.label`)"
                                :cancel-button-label="$t('employees.tabs.contracts.actions.quick_add.cancel')"
                                :submit-button-label="$t('employees.tabs.contracts.actions.quick_add.submit')"
                                :submit-button-disabled="!localWageOrSalary"
                                @submit="submitWageOrSalary"
                                @cancel="cancelQuickAdd"
                              >
                                <template #anchor>
                                  <div
                                    class="current-contract__wage-salary__action"
                                    @click="trackerAction"
                                  >
                                    {{ $t('employees.tabs.contracts.actions.quick_add.add') }}
                                  </div>
                                </template>
                                <template #content>
                                  <div class="current-contract__wage-salary__content">
                                    <CalculatorIcon />
                                    <SkInputGroup>
                                      <SkInput
                                        v-model="localWageOrSalary"
                                        class="current-contract__wage-salary__input"
                                        placeholder="0"
                                        min="0"
                                        type="number"
                                      />
                                      <template #append>
                                        {{ currencySymbol }}
                                      </template>
                                    </SkInputGroup>
                                  </div>
                                </template>
                              </SkPopover>
                            </div>
                            <div
                              v-else
                            >
                              {{ salaryCalculationValue }}
                            </div>
                          </div>
                        </div>

                        <ContractAttribute
                          :label="$t('employees.tabs.contracts.current.attributes.created_by')"
                          :value="creatorName"
                        />
                      </div>

                      <div class="current-contract__content">
                        <ContractAttribute
                          v-if="isContractCounterTypeDailyRate"
                          :value="workDaysPerYear"
                          :label="$t('employees.tabs.contracts.current.attributes.worked_days_per_year.label')"
                        />
                        <ContractAttribute
                          v-else
                          v-tooltip="contractHoursTooltip"
                          :value="contractHours"
                          :label="$t(`employees.tabs.contracts.current.attributes.contract_hours.${arePaidBreaksActivatedForEmployee ? 'label_presence_time' : 'label'}`)"
                        />

                        <ContractAttribute
                          :label="$t('employees.tabs.contracts.current.attributes.created_at')"
                          :value="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="!!$t('employees.tabs.contracts.actions.show_infos.all')"
                  class="current-contract__container__footer"
                  @click="showFullContractInfos"
                >
                  {{ $t('employees.tabs.contracts.actions.show_infos.all') }}
                </div>
              </div>
            </div>
          </template>

          <ContractSidebar v-if="canEditContract" />
        </div>
      </div>

      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <CreateAmendmentModal
          :employee-annualization-config="employeeAnnualizationConfig"
        />
      </MountingPortal>
    </template>
    <div
      v-else
      class="current-contract__spinner"
    >
      <SkLoader size="large" />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';

import { arrayToSelectOptions } from '@skello-utils/form';
import { getContractTypeKeyText } from '@app-js/users/shared/utils/contract_types_helper';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';
import { FEATURES } from '@app-js/shared/constants/features';

import DropdownButton from '@app-js/shared/components/DropdownButton';
import PackTag from '@app-js/shared/components/PackTag';
import MissingInformationNotification from '@app-js/shared/components/PayrollPreparation/MissingInformationNotification';

import CalculatorIcon from './CalculatorIcon';
import ContractAttribute from './ContractAttribute';
import ContractSidebar from './ContractSidebar';
import CreateAmendmentModal from './FullContract/Amendments/CreateAmendmentModal';

import {
  ContractSpaceIcon,
  ContractIcon,
} from '../svg';

export default {
  name: 'CurrentContract',
  components: {
    ContractSidebar,
    ContractAttribute,
    ContractSpaceIcon,
    ContractIcon,
    CalculatorIcon,
    DropdownButton,
    PackTag,
    CreateAmendmentModal,
    MissingInformationNotification,
  },
  mixins: [payrollPreparationMixins],
  data() {
    return {
      isAnnualizationLoading: false,
      localWageOrSalary: null,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('config', ['config']),
    ...mapState('contracts', ['loading', 'contract']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'upsellRequested']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('annualization', ['employeeAnnualizationConfigs']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('currentShop', [
      'defaultHourlyWageRate',
      'isAnnualizedWorkingTimeAvailable',
      'isDevFlagEnabled',
      'arePaidBreaksActivated',
    ]),
    ...mapGetters('contracts', [
      'isActive',
      'isContractCounterTypeDailyRate',
      'isSalaryCalculationTypeHourlyWage',
      'isContractInterim',
      'contractHasBothLimits',
      'hourlyWageWithCostsEstimation',
    ]),
    ...mapGetters('amendments', [
      'hasActivePermanentAmendment',
      'activePermanentAmendment',
    ]),
    ...mapState('amendments', ['amendments']),
    ...mapGetters('currentLicense', [
      'canReadEmployeePersonalInfosAndBankData',
      'canEditEmployeePersonalInfosAndBankData',
      'canEditEmployeeInfo',
      'canManageEmployeeAmendments',
    ]),
    ...mapGetters('amendments', ['amendmentsCount']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrStrictSubordinate']),
    ...mapGetters('features', ['isFeatureEnabled']),

    isMulticontractsEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_MULTI_CONTRACTS,
        this.employee.attributes.shopId,
      );
    },
    currencySymbol() {
      return this.employee.relationships.shop.attributes.currencySymbol;
    },
    trackingOptions() {
      return {
        submit: 'multicontract_add_contracted_hours_amendment_validate',
        cancel: 'add_amendment_cancel',
      };
    },
    /*
      when "no contract" is displayed on current contract:
      1/ multicontract is required
      2/ contract must not be active
      3/ contract must have either startDate or endDate
        WHY: contract without any dates will not be displayed in contracts table (history nor future)
        therefore, a user in multicontract with only one contract without dates would never be displayed anywhere.
        Thus, in multicontracts, this kind of contract is displayed on CurrentContract
    */
    isUniqIncompleteContract() {
      return this.isMulticontractsEnabled && !this.isActive &&
        (!!this.contract.attributes.startDate || !!this.contract.attributes.endDate);
    },
    isCreateContractAllowed() {
      return this.isMulticontractsEnabled && this.contractHasBothLimits();
    },
    isCurrentContractActive() {
      // If you create an employee it doesn't have any startDate and endDate BUT it still is active.
      if (!this.contract.attributes.startDate && !this.contract.attributes.endDate) return true;
      return this.isActive;
    },
    canCreateVersion() {
      const isCurrentContract = this.contract.attributes?.current;

      return !!isCurrentContract && !!this.contract.attributes?.startDate;
    },
    canCreateContract() {
      return this.contractHasBothLimits() || !this.isCurrentContractActive;
    },
    multicontractDropdownButtonTooltip() {
      if (!this.isMulticontractsEnabled) return '';

      return this.$t('employees.tabs.contracts.actions.add_contract.tooltip.contract');
    },
    multicontractsDropdownItems() {
      return [{
        callback: () => {
          if (this.employeeAnnualizationConfig) {
            this.$skAnalytics.track('add_contract_amendment');
          }
          this.openVersionModalContract();
        },
        disabled: !this.canCreateVersion || !this.canManageAmendments,
        label: this.$t('employees.tabs.contracts.actions.add_contract.upgrade.amend'),
        tooltip: this.$t(`employees.tabs.contracts.actions.add_contract.tooltip.amend.${!this.canCreateVersion ? 'no_version' : 'forbidden'}`),
      }, {
        callback: this.displayNewContractMode,
        dataTest: 'new_contract_button',
        disabled: !this.canCreateContract,
        label: this.$t('employees.tabs.contracts.actions.add_contract.upgrade.new'),
        tooltip: this.$t('employees.tabs.contracts.actions.add_contract.tooltip.contract'),
        tracker: 'multicontract_new_contract_button',
      }];
    },
    addButtonTooltip() {
      if (!this.isMulticontractsEnabled || this.contractHasBothLimits() || this.isUpgradeMultiContractsEnabled) return '';

      return this.$t('employees.tabs.contracts.actions.add_contract.tooltip.contract');
    },
    // Pack Offer
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },

    startDate() {
      if (!this.contract.attributes.startDate) return '';

      return skDate.utc(this.contract.attributes.startDate).format('DD MMMM YYYY');
    },
    endDate() {
      if (!this.contract.attributes.endDate) return '';

      return skDate.utc(this.contract.attributes.endDate).format('DD MMMM YYYY');
    },
    contractTypeName() {
      if (!this.contract.attributes.contractTypeId) return '-';

      const contractType = this.config.contract_data.contract_types
        .find(ct => ct.id === this.contract.attributes.contractTypeId);
      const contractTypeCountry = contractType.country;
      const contractTypeKey = contractType.key;

      return getContractTypeKeyText(contractTypeCountry, contractTypeKey);
    },
    amendmentsCountDisplay() {
      if (!this.canManageAmendments || !this.isContractEligibleForAmendment) return '-';

      return this.$tc('employees.tabs.contracts.current.attributes.amendments', this.amendmentsCount, { count: this.amendmentsCount });
    },
    isContractEligibleForAmendment() {
      return !this.isContractInterim &&
        !this.isContractCounterTypeDailyRate &&
        this.isFeatureEnabled(
          FEATURES.FEATURE_CONTRACTS_AMENDMENTS,
          this.employee.relationships.shop.id,
        );
    },
    amendmentTooltip() {
      if (this.canManageAmendments) {
        if (this.isContractCounterTypeDailyRate) {
          return this.$t('employees.tabs.contracts.current.attributes.amendments_for_daily_rate');
        }

        if (this.isContractInterim) {
          return this.$t('employees.tabs.contracts.current.attributes.amendments_for_interim');
        }

        return '';
      }

      return this.$t('employees.tabs.contracts.current.attributes.amendment_missing_permission');
    },
    effectivePeriod() {
      return `${this.startDate} - ${this.endDate}`;
    },
    createdAt() {
      return skDate.utc(this.contract.attributes.createdAt).format('DD MMMM YYYY');
    },
    contractHours() {
      if (this.isContractInterim) return '-';

      if (this.hasActivePermanentAmendment) {
        return this.$t(
          'employees.tabs.contracts.current.attributes.contract_hours.value',
          { hours: this.activePermanentAmendment.attributes.hours },
        );
      }

      return this.$t(
        'employees.tabs.contracts.current.attributes.contract_hours.value',
        { hours: this.contract.attributes.contractHours },
      );
    },
    contractHoursTooltip() {
      return this.isContractInterim ?
        this.$t('employees.tabs.contracts.current.attributes.amendments_for_interim') :
        '';
    },
    employeeAnnualizationConfig() {
      return this.employeeAnnualizationConfigs[this.employee.id] || {};
    },
    workDaysPerYear() {
      if (!this.contract.attributes.workDays) return '-';

      return this.$t(
        'employees.tabs.contracts.current.attributes.worked_days_per_year.value',
        { days: this.contract.attributes.workDays },
      );
    },
    salaryInfo() {
      if (!this.canReadEmployeePersonalInfosAndBankData) return '-';

      return this.$t('employees.tabs.contracts.actions.quick_add.add');
    },
    salaryCalculationType: {
      get() {
        return this.contract.attributes.salaryCalculationType;
      },
      set(newSalaryCalculationType) {
        if (newSalaryCalculationType === this.contract.attributes.salaryCalculationType) return;
        this.localWageOrSalary = null;

        this.setContractAttributes({ salaryCalculationType: newSalaryCalculationType });
        this.updateContract({
          employeeId: this.$route.params.user_id,
          contractId: this.contract.id,
          clusterNodeId: this.navContext.clusterNodeId,
        })
          .catch(error => {
            this.$skToast({
              message: this.$t('employees.update_contract.toast.error'),
              variant: 'error',
              containerId: 'employees__container',
            });
          });
      },
    },
    salaryCalculationTypes() {
      return arrayToSelectOptions(
        this.config.contract_data.calculation_collection,
        value => this.$t(`contract.calculation_types.${value}`,
        ));
    },
    salaryCalculationValue() {
      if (!this.canReadEmployeePersonalInfosAndBankData) return '-';

      if (this.isSalaryCalculationTypeHourlyWage) {
        return this.$tc('employees.tabs.contracts.full_contract.data.salary.hourly_wage.value',
          this.contract.attributes.hourlyWage, {
            currency_symbol: this.currencySymbol,
          });
      }

      return this.$tc('employees.tabs.contracts.full_contract.data.salary.monthly_salary.value',
        this.contract.attributes.monthlySalary, {
          currency_symbol: this.currencySymbol,
        });
    },
    showAddValueAction() {
      return (
        this.isSalaryCalculationTypeHourlyWage && this.contract.attributes.hourlyWage === 0
      ) ||
        (
          !this.isSalaryCalculationTypeHourlyWage &&
          (!this.contract.attributes.monthlySalary || this.contract.attributes.monthlySalary === 0)
        );
    },
    creatorName() {
      if (!this.contract.relationships.creator) return '-';

      return this.fullName(this.contract.relationships.creator);
    },
    posteName() {
      return this.contract.attributes.posteName ||
        this.$t('employees.tabs.contracts.current.attributes.poste_name_missing');
    },
    canEditSensitiveData() {
      return this.canEditEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    canEditContract() {
      return this.canEditEmployeeInfo && this.isCurrentUserOrStrictSubordinate;
    },
    canManageAmendments() {
      return this.canManageEmployeeAmendments && this.isCurrentUserOrStrictSubordinate;
    },
    canAddAmendments() {
      return this.isContractEligibleForAmendment && this.canManageAmendments;
    },
    calulationTypeTooltip() {
      return this.canReadEmployeePersonalInfosAndBankData ?
        '' :
        this.$t('employees.tabs.contracts.current.attributes.amendment_missing_permission');
    },
    hourlyWageRate() {
      return this.currentShop.id === 'all' ? this.defaultHourlyWageRate : this.currentShop.attributes.hourlyWageRate;
    },
    areAnnualizationConfigsLoading() {
      return this.isAnnualizationLoading;
    },
    isUpgradeMultiContractsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_UPGRADE_MULTICONTRACTS');
    },
    arePaidBreaksActivatedForEmployee() {
      return this.arePaidBreaksActivated({
        shop: this.employee.relationships.shop,
        convention: this.employee.relationships.convention,
      });
    },
  },
  created() {
    // We need to use the user_id from the route
    // To avoid problems when fast switching between employees
    const employeeId = this.$route.params.user_id;

    this.fetchCurrentContract({ employeeId })
      .then(() => {
        this.fetchAmendments({
          employeeId,
          contractId: this.contract.id,
        });
      });
  },
  methods: {
    ...mapActions('annualization', [
      'fetchEmployeeAnnualizationConfigs',
      'fetchShopAnnualizationConfig',
    ]),
    ...mapActions('contracts', [
      'fetchCurrentContract',
      'updateContract',
      'updateContractHourlyWage',
      'updateContractMonthlySalary',
    ]),
    ...mapActions('currentOrganisation', ['requestPackUpsell']),
    ...mapActions('amendments', ['fetchAmendments']),
    ...mapActions('selectedEmployee', ['fetchEmployeeAnnualizationConfig']),
    ...mapMutations('annualization', ['resetAnnualizationState']),
    ...mapMutations('contracts', [
      'setContractAttributes',
      'setUpsellRequested',
    ]),
    displayNewContractMode() {
      this.$router.push({ name: 'user_full_contract_new' });
    },
    async fetchAnnualizationsConfigs() {
      if (!this.employee.attributes.annualizable) {
        return;
      }

      const isShopAnnualized = await this.isShopAnnualized();

      if (!isShopAnnualized) {
        this.resetAnnualizationState();
        return;
      }

      const shopId = this.employee.attributes.shopId;
      const queries = [
        this.fetchEmployeeAnnualizationConfigs({ shopId, userId: this.employee.id }),
      ];

      // shop config has been already fetched when selectedShop is not user main shop
      if (this.currentShop.id === shopId) {
        queries.push(this.fetchShopAnnualizationConfig({ shopId }));
      }

      try {
        await Promise.all(queries);
      } catch (error) {
        if (error.response?.status === 404) {
        // svc-employees returns a 404 when employee does not have an annualization config
          return;
        }

        this.resetAnnualizationState();
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });

        throw error;
      }
    },
    async isShopAnnualized() {
      if (!this.isAnnualizedWorkingTimeAvailable({ shop: this.employee.relationships.shop })) {
        return false;
      }

      if (this.currentShop.id !== this.employee.attributes.shopId) {
        try {
          await this.fetchShopAnnualizationConfig({
            shopId: this.employee.attributes.shopId,
          });

          return true;
        } catch (error) {
          if (error.response.status === 404) return false;

          throw error;
        }
      }

      return this.currentShop.attributes.isAnnualizationV2Active;
    },
    showFullContractInfos() {
      this.$skAnalytics.track('multicontract_show_all_information');
      this.$router.push({ name: 'user_full_contract_show', params: { contract_id: this.contract.id } });
    },
    trackerAction() {
      if (this.salaryCalculationType === 'hourly_wage') {
        this.$skAnalytics.track('multicontract_quick_add_hourly_rate');
      } else if (this.salaryCalculationType === 'monthly_salary') {
        this.$skAnalytics.track('multicontract_quick_add_monthly_salary');
      }
    },
    cancelQuickAdd() {
      this.localWageOrSalary = null;
      this.$refs.wageOrSalaryPopover.hide();
    },
    setHourlyWageWithCosts() {
      const estimatedHourlyWageWithCosts =
        this.hourlyWageWithCostsEstimation(this.hourlyWageRate);

      // if the hourlyWageWithCosts from the contract is 0 (not set) or the same as the estimation
      // it means hourlyWageWithCosts is linked to hourlyWage so we want to update it
      if (this.contract.attributes.hourlyWageWithCosts === 0 ||
        this.contract.attributes.hourlyWageWithCosts === estimatedHourlyWageWithCosts
      ) {
        this.setContractAttributes({
          hourlyWageWithCosts: estimatedHourlyWageWithCosts,
        });
      }
    },
    submitWageOrSalary() {
      if (this.isSalaryCalculationTypeHourlyWage) {
        this.$skAnalytics.track('multicontract_add_hourly_rate_from_current_contract');
        this.updateContractHourlyWage({ hourlyWage: this.localWageOrSalary });
      } else {
        this.$skAnalytics.track('multicontract_add_gross_wage_from_current_contract');
        this.updateContractMonthlySalary({ monthlySalary: this.localWageOrSalary });
      }

      // We update the HourlyWageWithCosts of the contract in this quick action,
      // to replicate the behavior from the contract's edit page
      this.setHourlyWageWithCosts();

      this
        .updateContract({
          employeeId: this.$route.params.user_id,
          contractId: this.contract.id,
          clusterNodeId: this.navContext.clusterNodeId,
        })
        .then(() => {
          this.$skToast({
            message: this.$t('employees.update_contract.toast.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('employees.update_contract.toast.error'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    requestUpsell() {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('employees.tabs.contracts.pack_offers.upsell_already_requested'),
          variant: 'success',
          containerId: 'employees__container',
        });
        return;
      }

      const params = {
        buttonClicked: this.$t('employees.tabs.contracts.pack_offers.new'),
        currentPage: this.$t('employees.tabs.contracts.pack_offers.tab_name'),
        upsellType: this.$t('employees.tabs.contracts.pack_offers.action'),
        action: this.$t('employees.tabs.contracts.pack_offers.action'),
      };

      this.requestPackUpsell(params)
        .then(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.pack_offers.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    async openCreateAmendmentModal(event, analyticsEventName) {
      this.$skAnalytics.track(analyticsEventName);

      try {
        this.isAnnualizationLoading = true;
        await this.fetchAnnualizationsConfigs();
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-amendment-modal');
      } finally {
        this.isAnnualizationLoading = false;
      }
    },
    openVersionModalContract(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-version-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.employees__content-wrapper {
  margin-bottom: 30px;
}

.current-contract__header__wrapper {
  background-color: $sk-white;
}

.current-contract__header {
  background: $sk-white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -40px;
  height: 70px;
  border-bottom: 1px solid $sk-grey-10;
}

.current-contract__no-contract--wrapper {
  width: 100%;
  height: 100px;
  margin: 20px 0 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: $fs-text-l;
  background-color: $sk-white;
  border-radius: 4px;
}

.current-contract__no-contract {
  background: $sk-error-10;
  border-radius: 2px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
}

.current-contract__no-contract__warning {
  fill: $sk-error;
  margin-right: 15px;
}

.current-contract__header__title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $fs-text-l;
}

.current-contract__wrapper {
  display: flex;
}

.current-contract__subtitle {
  display: flex;
  justify-content: flex-start;

  .current-contract__poste_name {
    display: flex;
    align-items: center;
    font-size: $fs-text-s;
    color: $sk-grey;
  }

  .current-contract__bullet-separator {
    margin: 0 10px;
    height: 24px;
    width: 3px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      top: 50%;
      transform: perspective(1px) translateY(-50%);
      background: $sk-grey-10;
    }
  }
}

.current-contract__content__wrapper {
  display: flex;
  justify-content: space-between;
}

.current-contract__content--disabled {
  color: $sk-grey;
  cursor: not-allowed;
}

.current-contract__content {
  display: flex;
  flex-direction: column;
  min-width: 100px;

  .data-display__wrapper:first-of-type {
    margin-bottom: 23px;
  }
}

.current-contract__content__amendment-action {
  display: flex;
  align-items: center;
  cursor: pointer;

  .current-contract__content__amendment-text-as-action {
    color: $sk-blue;
  }

  .current-contract__content__amendment-action-display {
    margin-left: 4px;
  }
}

.current-contract__amendment-action__display {
  margin-left: 0;
}

.current-contract__footer {
  color: $sk-blue;
}

.current-contract__spinner {
  height: 338px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $sk-blue;
}

.current-contract__wage-salary__label {
  // we're using deep here to overwrite SkSelectV2
  // into a simple text with caret opening dropdown
  ::v-deep .sk-select__selected-option.sk-select__selected-option--filled {
    background-color: white;
    display: flex;
    align-items: center;
    font-size: $fs-text-s;
    line-height: 15px;
    color: $sk-grey;
    margin-bottom: 5px;
    padding: 0;
    height: auto;
  }

  ::v-deep .sk-select__caret {
    margin: 6px 0 auto 3px;
  }

  ::v-deep .sk-dropdown__menu {
    width: 180px;
  }
}

.current-contract__wage-salary__content-wrapper {
  display: flex;
  font-size: $fs-text-l;
  line-height: 22px;
}

// ensures proper spacing between input and icon on safari
.current-contract__wage-salary__input {
  width: 158px;
}

.current-contract__wage-salary__title {
  margin-bottom: 25px;
}

.current-contract__wage-salary__content {
  width: 300px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  // deep is needed to owerwrite SkGroupInput class
  ::v-deep .sk-input-group__append {
    width: 20%;
  }
}

.current-contract__wage-salary__action {
  cursor: pointer;
  color: $sk-blue;
}

.current-contract__wage-salary__footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.current-contract__container__wrapper {
  display: flex;
  box-shadow: 0 18px 44px rgba(185, 193, 204, .16);
  flex-grow: 1;
  border-bottom-left-radius: 6px;
}

.current-contract__container__main {
  background: white;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.current-contract__container__header {
  display: flex;
  margin: 20px 0 20px 26px;
}

.current-contract__container__header--without-icon {
  margin-left: 17px;
}

.current-contract__container__title {
  font-weight: $fw-semi-bold;
  font-size: $fs-heading-xs;
}

.current-contract__container__notification {
  padding: 0 80px 15px 86px;
  margin-top: -12px;
}

.current-contract__container__content {
  padding: 0 80px 10px 86px;
}

.current-contract__container__footer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $sk-grey-10;
  color: $sk-blue;

  &:hover {
    background: $sk-blue-10;
    cursor: pointer;
    transition: all .3s ease-out;
  }
}

.current-contract__container__sidebar {
  background: $sk-grey-10;
  width: 61px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
</style>
