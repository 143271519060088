<!-- eslint-disable vue/no-v-html -->
<template>
  <SkModalSectionV2
    border-bottom="none"
    :padding-bottom="isLoading"
  >
    <div
      v-if="isLoading"
      class="hours-volume-section__loader"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <div class="hours-volume-section__title">
        <h3 class="sk-header--3">
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.title') }}
        </h3>
      </div>
      <div
        class="hours-volume-section__subtitle"
        v-html="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.subtitle', realizedDates)"
      />
      <div class="hours-volume-section__period-reminders">
        <div>
          <span>{{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.reference_period.label') }}</span>
          <SkTag variant="light">
            {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.reference_period.content', periodDates) }}
          </SkTag>
        </div>
        <div>
          <span>{{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.initialization_date.label') }}</span>
          <SkTag variant="blue">
            {{ initializationDateLabel }}
          </SkTag>
        </div>
      </div>
      <div class="hours-volume-section__user-input-headers">
        <div class="hours-volume-section__user-input-headers--column">
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.theoretical_balance.label') }}
          <span class="hours-volume-section__user-input-subtitles">
            {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.date_range', theoreticalDates) }}
          </span>
        </div>
        <div class="hours-volume-section__user-input-headers--column">
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.realized_balance.label') }}
          <span class="hours-volume-section__user-input-subtitles">
            {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.date_range', realizedDates) }}
          </span>
        </div>
        <div class="hours-volume-section__user-input-headers--column">
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.remaining_balance.label') }}
          <span class="hours-volume-section__user-input-subtitles">
            {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.date_range', remainingDates) }}
          </span>
        </div>
      </div>
      <div>
        <UserAnnualizationRow
          v-for="user in annualizedUsers"
          :key="user.id"
          :contract-hours="userContractHours(user)"
          :initials="userInitials(user)"
          :full-name="fullName(user)"
          :theoretical-balance="computeTheoreticalBalance(user)"
          @update-theoretical="value => onTheoreticalUpdate(user.id, value)"
          @update-realized="value => onRealizedUpdate(user.id, value)"
        />
      </div>
    </div>
  </SkModalSectionV2>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import {
  AnnualizationManager,
  theoreticalBalance,
} from '@skelloapp/skello-annualization';

import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

import UserAnnualizationRow from '@app-js/shared/components/UserAnnualizationRow';

const DEFAULT_SOLIDARITY_DAY_ENABLED = true;

export default {
  name: 'HoursVolumeSection',
  components: { UserAnnualizationRow },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetchedEmployees: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName', 'userInitials']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopAnnualizationConfig', ['initializationDate', 'periodStartDate']),
    ...mapState('shopEmployees', ['annualizedUserIds', 'employees', 'userAnnualizationConfigs']),
    initializationDateLabel() {
      return skDate(this.initializationDate).format('DD/MM/YYYY');
    },
    annualizedUsers() {
      const users = this.fetchedEmployees;

      return users.filter(({ id }) => this.annualizedUserIds.includes(id));
    },
    periodDates() {
      const startDate = skDate(this.periodStartDate);
      const endDate = startDate.clone().add(1, 'year').subtract(1, 'day');

      return {
        start_date: startDate.format('DD/MM/YYYY'),
        end_date: endDate.format('DD/MM/YYYY'),
      };
    },
    theoreticalDates() {
      const startDate = skDate(this.periodStartDate);
      const endDate = startDate.clone().add(1, 'year').subtract(1, 'day');

      return {
        start_date: startDate.format('DD/MM/YY'),
        end_date: endDate.format('DD/MM/YY'),
      };
    },
    remainingDates() {
      const startDate = skDate(this.initializationDate);
      const endDate = skDate(this.periodStartDate).clone().add(1, 'year').subtract(1, 'day');

      return {
        start_date: startDate.format('DD/MM/YY'),
        end_date: endDate.format('DD/MM/YY'),
      };
    },
    realizedDates() {
      const startDate = skDate(this.periodStartDate);
      const initDate = skDate(this.initializationDate);
      const endDate = startDate.isSame(initDate) ? initDate : initDate.subtract(1, 'day');

      return {
        start_date: startDate.format('DD/MM/YY'),
        end_date: endDate.format('DD/MM/YY'),
      };
    },
  },
  async mounted() {
    await this.fetchEmployees();
  },
  methods: {
    ...mapMutations('shopEmployees', ['setUserAnnualizationConfigs']),
    onTheoreticalUpdate(userId, value) {
      const previousUserConfig = this.userAnnualizationConfigs[userId];

      this.setUserAnnualizationConfigs({
        ...this.userAnnualizationConfigs,
        [userId]: {
          ...previousUserConfig,
          theoretical: value,
        },
      });
    },
    onRealizedUpdate(userId, value) {
      const previousUserConfig = this.userAnnualizationConfigs[userId];

      this.setUserAnnualizationConfigs({
        ...this.userAnnualizationConfigs,
        [userId]: {
          ...previousUserConfig,
          initializationRealizedBalance: value,
        },
      });
    },
    computeTheoreticalBalance(user) {
      const theoretical = this.userAnnualizationConfigs[user.id]?.theoretical;

      if (theoretical) return theoretical;

      if (!this.currentShop.attributes.isFrenchShop) return null;

      return theoreticalBalance(
        user.attributes.permanentContractHoursAt,
        DEFAULT_SOLIDARITY_DAY_ENABLED,
      );
    },
    async fetchEmployees() {
      this.isLoading = true;

      const params = {
        shop_id: this.currentShop.id,
        permanent_contract_hours_at_date: this.periodStartDate,
      };

      // We are doing this specific fetch to get permanentContractHoursAt value for the annualization periodStartDate
      // This is needed to handle contract hours amendments when initailizing the annualization feature
      try {
        const { data } = await httpClient.get('/v3/api/users/shop_employees', { params });
        this.fetchedEmployees = data.data;

        this.annualizedUsers.forEach(user => {
          this.setUserAnnualizationConfigs({
            ...this.userAnnualizationConfigs,
            [user.id]: {
              contractHours: user.attributes.permanentContractHoursAt,
            },
          });
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    userContractHours(user) {
      return user.attributes.permanentContractHoursAt;
    },
  },
};
</script>

<style lang="scss" scoped>
.hours-volume-section {
  &__loader {
    display: flex;
    justify-content: center;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__subtitle {
    color: $sk-grey;
    margin-bottom: 30px;
  }

  &__period-reminders {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    span {
      margin-right: 10px;
      color: $sk-black;
      font-weight: $fw-semi-bold;
      font-size: $fs-text-s;
    }
  }

  &__user-input-headers {
    margin-left: 162px;
    margin-bottom: 10px;
    color: $sk-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--column {
      width: 130px;
      display: flex;
      flex-direction: column;
    }
  }

  &__user-input-subtitles {
    color: $sk-grey;
    font-weight: 700;
    font-size: 10px;
  }
}
</style>
