<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features';
import GenericDropdownItem from './GenericDropdownItem';

export default {
  name: 'UndoRedoAction',
  extends: GenericDropdownItem,
  props: {
    sourceType: {
      type: String, // 'Month' | 'Week'
      required: false,
      default: 'Week',
    },
    type: {
      type: String, // 'undo' | 'redo'
      required: false,
      default: 'undo',
      validator(value) {
        return ['undo', 'redo'].includes(value);
      },
    },
  },
  computed: {
    ...mapState('planningsState', ['undoRedoLoading']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsState', [
      'isAtBeginningOfHistory',
      'isAtEndOfHistory',
      'periodDates',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    title() {
      return this.$t(`plannings.toolbar.actions_bar.icons_labels.${this.type}.label`);
    },
    icon() {
      return this.type === 'undo' ? 'BackArrowV2Icon' : 'ForwardArrowV2Icon';
    },
    visibleIn() {
      return 'toolbar';
    },
    disabled() {
      return this.undoRedoLoading ||
        (this.type === 'undo' && this.isAtBeginningOfHistory) ||
        (this.type === 'redo' && this.isAtEndOfHistory);
    },
    canDisplayUndoRedo() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNINGS_UNDO_REDO, this.currentShop.id);
    },
    isVisible() {
      return this.canDisplayUndoRedo;
    },
    dataTest() {
      return `planning-toolbar__${this.type}`;
    },
  },
  methods: {
    ...mapActions('planningsState', ['undoRedoAction']),
    handler() {
      const dates = this.periodDates(this.sourceType.toLowerCase());

      this.undoRedoAction({
        periodStartsAt: dates.startsAt,
        periodEndsAt: dates.endsAt,
        isRedo: this.type === 'redo',
      })
        .then(() => {
          this.$skAnalytics.track(`${this.type}_btn_planning`, { source: this.sourceType });
        })
        .catch(() => this.onFailure());
    },
  },
};
</script>
