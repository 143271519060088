<template>
  <div class="badgings__wrapper">
    <ToolbarCanvas v-show="toolbarLoading" />
    <Toolbar
      v-show="!toolbarLoading"
      :key="currentShop.id"
    />
    <div class="badgings__main">
      <WeekSidebar v-if="!$route.params.user_id" />
      <UsersSidebar v-if="$route.params.user_id" />
      <div
        id="badgings__container"
        class="badgings__container"
      >
        <div
          v-if="loading || usersLoading"
          class="badgings__spinner-wrapper"
        >
          <SkLoader size="large" />
        </div>
        <div v-else>
          <router-view />
        </div>
      </div>
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <TimeclockWelcomeVideo
        v-if="shouldShowTimeclockWelcomeVideo"
      />
      <PunchClockUpsellModal />
      <ManageShiftNoteModal />
      <BadgingRulesModal />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import {
  MODAL_HIDE_EVENT,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';

import { FEATURES } from '@app-js/shared/constants/features';
import TimeclockWelcomeVideo from '@app-js/timeclock_onboarding/components/TimeclockWelcomeVideo';
import Toolbar from './shared/components/Toolbar';
import ToolbarCanvas from './shared/components/Toolbar/ToolbarCanvas';
import WeekSidebar from './shared/components/WeekSidebar';
import UsersSidebar from './shared/components/UsersSidebar';
import PunchClockUpsellModal from './shared/components/PunchClockUpsellModal';
import ManageShiftNoteModal from './shared/components/ManageShiftNoteModal';
import BadgingRulesModal from './shared/components/BadgingRulesModal';

export default {
  name: 'Badgings',
  components: {
    Toolbar,
    WeekSidebar,
    UsersSidebar,
    ToolbarCanvas,
    PunchClockUpsellModal,
    ManageShiftNoteModal,
    BadgingRulesModal,
    TimeclockWelcomeVideo,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Redirect if user is not authorized to access punchclock to restrain url access
      if (!vm.currentLicense.attributes.canReadPunchClockData) vm.redirectToHome();

      const allShopsView =
        from.params.shop_id === 'all' || (from.name && from.name.includes('organisation_settings'));

      // Check if user came from all shops view and if shop TO display hasPunchClock
      const hasShopPunchClock = allShopsView ?
        to.params.hasPunchClock :
        vm.isFeatureEnabled(
          vm.FEATURES.FEATURE_BADGING,
          to.params.shop_id,
        );

      // Show punch clock upsell modal if shop has no punch clock
      if (!hasShopPunchClock) {
        vm.emitOnRoot(MODAL_SHOW_EVENT, event, 'punch-clock-upsell-modal');
      }

      if (vm.shouldShowTimeclockWelcomeVideo) {
        vm.emitOnRoot(MODAL_SHOW_EVENT, event, 'welcome-video-modal');
      }

      const date = to.query.date !== undefined && skDate(to.query.date).isValid() ?
        to.query.date :
        skDate().format('YYYY-MM-DD');

      const startDate = skDate(date).startOf('isoWeek').format('YYYY-MM-DD');
      const endDate = skDate(date).endOf('isoWeek').format('YYYY-MM-DD');

      if (to.params.shop_id !== vm.currentShop.id) {
        vm.updateAndSelectShop(to.params.shop_id)
          .then(() => {
            vm.updateCurrentDate({ date });
            vm.fetchWeeklyOptions({ shopId: to.params.shop_id, date });
            vm.fetchTeams({ shopId: to.params.shop_id });
            vm.fetchUsers({
              shopId: to.params.shop_id,
              startDate,
              endDate,
            });
          });
      } else {
        vm.updateCurrentDate({ date });
        vm.fetchWeeklyOptions({ shopId: to.params.shop_id, date });
        vm.fetchTeams({ shopId: to.params.shop_id });
        vm.fetchUsers({
          shopId: to.params.shop_id,
          startDate,
          endDate,
        })
          .then(() => {
            vm.initDayBadgingData({
              shopId: to.params.shop_id,
              startDate,
              endDate,
              date,
            });
          });
      }
    });
  },

  beforeRouteUpdate(to, from, next) {
    const navOnSameShop = Number(from.params.shop_id) === Number(to.params.shop_id);

    // Remove punch clock upsell modal if next shop has punch clock
    const hasNextShopPunchClock = this.isFeatureEnabled(
      this.FEATURES.FEATURE_BADGING,
      to.params.shop_id,
    );
    if (hasNextShopPunchClock) {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'punch-clock-upsell-modal');
    // Show punch clock upsell modal if next shop has no punch clock
    } else if (!hasNextShopPunchClock && !navOnSameShop) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'punch-clock-upsell-modal');
    }

    if (this.unsavedChangesToMatchedBadging && to.query.team_ids === from.query.team_ids) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashMatchedBadging();
          this.continueNavigationHandler(to, from, next);
        },
      });
    } else {
      this.continueNavigationHandler(to, from, next);
    }
  },
  beforeRouteLeave(to, from, next) {
    // Remove punch clock upsell modal before leave page
    this.emitOnRoot(MODAL_HIDE_EVENT, event, 'punch-clock-upsell-modal');

    if (this.unsavedChangesToMatchedBadging) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashMatchedBadging();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      loading: false,
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentUser', ['currentUser', 'currentUserUserLicenses']),
    ...mapState('navContext', ['navContext']),
    ...mapState('badgings', ['currentDate', 'usersLoading', 'users']),
    ...mapGetters('currentLicense', ['isSystemAdmin', 'canReadPunchClockData', 'canEditPunchClockSettings']),
    ...mapGetters('currentShop', ['isLoaded']),
    ...mapGetters('badgings', ['unsavedChangesToMatchedBadging']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('timeclockOnboarding', ['shouldShowTimeclockWelcomeVideo']),

    toolbarLoading() {
      return !this.isLoaded || !this.currentDate;
    },
  },
  created() {
    this.setBrowserTabTitle();
  },
  destroyed() {
    this.resetBrowserTabTitle();
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', ['selectShop']),
    ...mapActions('badgings', [
      'fetchWeeklyOptions',
      'fetchUsers',
      'initDayBadgingData',
      'fetchTeams',
    ]),
    ...mapMutations('badgings', ['updateCurrentDate', 'squashMatchedBadging']),

    checkAuthorizedTab() {
      const canAccessNodeIds = this.currentUserUserLicenses.map(
        uL => uL.attributes.descendantIds.concat(uL.attributes.clusterNodeId),
      ).flat();

      if (this.isSystemAdmin ||
        canAccessNodeIds.includes(this.currentOrganisation.attributes.rootNodeId)) {
        this.loading = false;

        return;
      }

      if (this.canReadPunchClockData &&
        canAccessNodeIds.includes(Number(this.navContext.clusterNodeId))) {
        this.loading = false;

        return;
      }

      this.redirectToHome();
    },
    updateAndSelectShop(shopId) {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.updateCurrentShop({ shopId })
          .then(() => {
            this.selectShop(this.currentShop)
              .then(() => {
                this.checkAuthorizedTab();
                this.setBrowserTabTitle();
                resolve();
              })
              .catch(() => reject());
          });
      });
    },
    setBrowserTabTitle() {
      this.$nextTick(() => {
        document.title = this.$t('badgings.tab_title', {
          shopName: this.currentShop.attributes.name,
        });
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
    redirectToHome() {
      this.$skToast({
        message: this.$t('errors.unauthorized'),
        variant: 'error',
        containerId: 'badgings__container',
      });

      this.updateCurrentShop({ shopId: 'all' });
      // if not allowed in any tab, redirect to home and raise error
      // to stop execution
      setTimeout(() => {
        window.location = '/';
      }, 1000);
      throw new Error('Unauthorized');
    },
    continueNavigationHandler(to, from, next) {
      const changeShop = String(to.params.shop_id) !== this.currentShop.id;
      const fromWeek = skDate(from.query.date).startOf('isoWeek').format();
      const toWeek = skDate(to.query.date).startOf('isoWeek').format();
      const newDate = changeShop ? from.query.date : to.query.date;

      const startDate = skDate(toWeek).startOf('isoWeek').format('YYYY-MM-DD');
      const endDate = skDate(toWeek).endOf('isoWeek').format('YYYY-MM-DD');

      if (changeShop) {
        this.updateAndSelectShop(to.params.shop_id);
      }

      this.updateCurrentDate({ date: newDate });

      if ((fromWeek !== toWeek) || changeShop || !from.name.startsWith('badgings')) {
        this.fetchWeeklyOptions({ shopId: to.params.shop_id, date: newDate });
        this.fetchTeams({ shopId: to.params.shop_id });
        this.fetchUsers({
          shopId: to.params.shop_id,
          startDate,
          endDate,
        })
          .then(() => {
            if (to.name === 'badgings_shifts_users') {
              this.$router.replace({
                name: this.$route.name,
                query: this.$route.query,
                params: {
                  user_id: to.params.user_id || this.users[0]?.id,
                  shop_id: to.params.shop_id,
                  badging: this.currentShop.attributes.hasPunchClock,
                },
              });
            }
            this.initDayBadgingData({
              shopId: to.params.shop_id,
              startDate,
              endDate,
              date: newDate,
            });
          });
      }

      next({ query: { date: newDate } });
    },
  },
};
</script>

<style lang="scss" scoped>
div.badgings__wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .badgings__main {
    flex: 1;
    display: flex;
    overflow-y: hidden;

    div.badgings__container {
      flex: 1;
      overflow: auto;

      div.badgings__spinner-wrapper {
        display: flex;
        justify-content: center;
        color: $sk-blue;
        font-size: 2em;
        padding-top: 4em;
      }
    }
  }
}
</style>

<style lang="scss">
.badgings__sidebar::-webkit-scrollbar {
  display: none;
}

.badgings__sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $sk-grey-10;
  width: 225px;
  height: calc(100vh - 110px);
  overflow: scroll;

  .badgings__sidebar-search {
    height: 60px;
    padding: 20px;
  }

  .badgings__sidebar__list_item {
    list-style-type: none;
    margin: 0;
    padding: 20px;

    .item__router-link {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

// Used in pages/Days and pages/Users
div.badgings__fixed-inner {
  padding: 0 30px;
}

.absences__table {
  ::v-deep th:first-child {
    padding-left: 10px;
  }

  ::v-deep th:last-child {
    .table-column__label {
      justify-content: flex-end;
      padding-right: 25px;
    }
  }

  ::v-deep .table-column__filled-section {
    &:first-child {
      width: 30%;
    }
  }

  ::v-deep .sk-table > tbody td {
    border: none;
  }

  .absences__table__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;

    .absences__table__empty__message {
      margin-top: 50px;
      color: $sk-black;
      font-size: $fs-heading-xs;
    }
  }

  // Prevent to display matched badging row on intercom bubble
  table.sk-table {
    margin-bottom: 90px;
  }
}
</style>
