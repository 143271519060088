<template>
  <div
    class="header__wrapper"
    :style="zoomRange.headerWrapper"
  >
    <div class="header__sidebar__wrapper cell--shadow cell--shadow-right">
      <SkNav
        class="header__sidebar"
        :style="zoomRange.headerSidebar"
      >
        <SkNavItem
          v-if="isWeeklyEmployeeViewAllowed"
          :to="{ name: 'plannings_weeks_employees', query: $route.query }"
          data-test="sidebar__employees"
        >
          {{ $t('plannings.table.header.navigation.employees') }}
        </SkNavItem>
        <SkNavItem
          v-if="isPositionsViewAllowed"
          :to="{ name: 'plannings_weeks_postes', query: $route.query }"
          data-test="sidebar__postes"
          @click.native="toggleTab('total')"
        >
          {{ $t('plannings.table.header.navigation.postes') }}
        </SkNavItem>
      </SkNav>
    </div>

    <div class="header__days">
      <HeaderDay
        v-for="day in visibleDays"
        :key="day.date"
        :day="day"
      />
    </div>
    <div class="header__sidebar__wrapper">
      <div :class="counterWrapperClasses">
        <SkNav
          class="header__counters"
          :style="{ fontSize: zoomRange.headerSidebar.fontSize }"
        >
          <SkNavItem
            :class="classForTotalColumn"
            :active="isActiveItem('total')"
            :style="zoomRange.headerCounterLinkLeft"
            data-test="counters__total"
            @click.native="toggleTab('total')"
          >
            {{ $t('plannings.table.header.counters.total') }}
          </SkNavItem>
          <SkNavItem
            v-if="isCountersTabDisplayed"
            :class="secondTabClass"
            :active="isActiveItem('counters')"
            :style="zoomRange.headerCounterLinkRight"
            data-test="counters__counters"
            @click.native="toggleTab('counters')"
          >
            {{ secondTabTitle }}
          </SkNavItem>
        </SkNav>
      </div>
      <TotalPeriodTabHeader />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';

import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import TotalPeriodTabHeader from '@app-js/plannings/shared/components/TotalPeriodTabHeader';

import { FEATURES } from '@app-js/shared/constants/features.js';
import HeaderDay from './HeaderDay';

export default {
  name: 'Header',
  components: { HeaderDay, TotalPeriodTabHeader },
  computed: {
    ...mapGetters('annualization', ['isAnnualizationCurrentlyActive']),
    ...mapGetters('currentUser', [
      'planningZoom',
      'fullName',
      'impersonate',
    ]),
    ...mapGetters('currentShop', ['isAnnualizedWorkingTimeAvailable', 'isDevFlagEnabled']),
    ...mapGetters('planningsState', [
      'visibleDays',
      'isActiveItem',
      'isEmployeesView',
      'monday',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('planningsState', ['displayTotalPeriodTab']),

    zoomRange() {
      const {
        headerWrapper,
        headerSidebar,
        headerCounterLinkLeft,
        headerCounterLinkRight,
      } = zoomPlanningWeek(this.planningZoom);
      return {
        headerWrapper,
        headerSidebar,
        headerCounterLinkLeft,
        headerCounterLinkRight,
      };
    },
    classForTotalColumn() {
      return { 'counters-cell__nav__single-column': !this.isCountersTabDisplayed };
    },
    secondTabClass() {
      return {
        header__counters__counters_annualization:
          this.currentShop.attributes.modulation &&
            this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
            this.isAnnualizationCurrentlyActive,
      };
    },
    secondTabTitle() {
      if (this.currentShop.attributes.modulation) {
        if (
          this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
            this.isAnnualizationCurrentlyActive
        ) {
          return this.$t('plannings.table.header.counters.counters_annualization');
        }
        return this.$t('plannings.table.header.counters.counters');
      }

      return this.$t('plannings.table.header.counters.annualization');
    },
    counterWrapperClasses() {
      return {
        header__counters__wrapper: true,
        'cell--shadow': !this.displayTotalPeriodTab,
        'cell--shadow-left': !this.displayTotalPeriodTab,
      };
    },
    isCountersTabDisplayed() {
      return this.isEmployeesView &&
      (this.currentShop.attributes.modulation ||
      (
        this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.isAnnualizationCurrentlyActive
      ));
    },
    isPositionsViewAllowed() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_PLANNING_WEEKLY_POSITION_VIEW, this.currentShop.id,
      );
    },
    isWeeklyEmployeeViewAllowed() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_PLANNING_WEEKLY_EMPLOYEE_VIEW, this.currentShop.id,
      );
    },
  },
  mounted() {
    if (!this.isWeeklyEmployeeViewAllowed) {
      this.$router.push({ name: 'plannings_weeks_postes', query: this.$route.query });
      this.toggleTab('total');
    }
  },
  methods: {
    ...mapMutations('planningsState', ['setAsSelectedTab']),
    // legacy name for the counters store
    ...mapActions('employeeCounters', ['fetchUsersHoursCounters']),
    ...mapActions('annualization', ['fetchAndComputeAnnualizationData']),
    toggleTab(tabName) {
      if (tabName === 'counters') {
        this.fetchUsersHoursCounters({ allUserCounters: true, shopId: this.currentShop.id });

        if (
          this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
          this.isAnnualizationCurrentlyActive
        ) {
          this.fetchAndComputeAnnualizationData({
            shopId: this.currentShop.id,
            untilPlanningEndDate: true,
          }).catch(error => {
            this.$skToast({
              message: this.$t('errors.standard_message'),
              variant: 'error',
            });
            throw error;
          });
        }
      }
      this.setAsSelectedTab(tabName);
    },
  },
};
</script>

<style lang="scss" scoped>
.header__wrapper {
  width: 100%;
  display: flex;
  z-index: 7;
}

.header__sidebar {
  padding: 13px 0 0;
  width: 160px;
  background-color: white;
  align-items: flex-end;
  justify-content: space-around;
  overflow: hidden;
}

::v-deep .header__sidebar {
  align-items: flex-end;

  .sk-nav__item {
    font-size: 12.6px;
  }
}

::v-deep .sk-nav {
  border: none;
}

// This and the following rules are some product specs
// in order to center skNav blue bar

::v-deep .sk-nav__item .sk-nav__item__link {
  margin: 0;
  padding: 0px 4px 16px;
}

.header__days {
  width: 100%;
  display: flex;
  background-color: white;
}

.header__counters__wrapper {
  flex: 0 0 146px;
  min-width: 0;
  z-index: 3;
  display: flex;
}

.header__counters {
  width: 146px;
  background-color: white;
  justify-content: center;
  align-items: end;
  overflow: hidden;
  gap: 16px;
}

::v-deep .header__counters {
  .sk-nav__item__link {
    font-size: 12.6px;
  }
}

// This rule display all messages on counters and annualization in several lines
::v-deep .header__counters__counters_annualization {
  .sk-nav__item__link {
    white-space: normal;
  }
}

.header__sidebar__wrapper {
  display: flex;
  position: relative;
  border-bottom: solid 1px $sk-grey-10;
  z-index: 1;
}

.header__counters-cell__nav {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid $sk-grey-10;
  -webkit-border-radius: 4px 0 0;
  -moz-border-radius: 4px 0 0;
  border-radius: 4px 0 0;
  z-index: 1;
  font-size: 1.4em;
}

.header__counters-cell__title {
  padding: 28px 0 26px;
  text-align: center;
  font-weight: bold;
}

.slide-counters-enter-active,
.slide-counters-leave-active,
.slide-counters-button-out-enter-active,
.slide-counters-button-in-enter-active {
  transition: all 1s ease;
}

.slide-counters-enter,
.slide-counters-leave-to,
.slide-counters-button-out-enter {
  transform: translateX(68px);
}

.slide-counters-button-in-enter {
  transform: translateX(-68px);
}

.counters-cell__nav__single-column {
  width: 100%;
  text-align: center;
}

.header__counters__wrapper.cell--shadow-left::after {
  height: 37px !important;
  margin-top: 23px;
}
</style>
