<template>
  <SkModal
    id="validate-day-modal"
    ref="validateDayModal"
    :modal-title="$t('badgings.toolbar.validate_day_modal.title', { day: formattedDateWithYear })"
    @submit="handleSubmit"
    @show="handleShow"
  >
    <template #body>
      <div
        v-if="realRevenuesLoading"
        class="validate-day-modal__loader"
      >
        <SkLoader size="large" />
      </div>
      <SkModalSection
        v-else
        border-bottom="none"
      >
        <div
          v-if="showEsignatureUpsellBanner"
          class="validate-day-modal__upsell-banner-wrapper"
        >
          <UpsellEsignatureBanner source="badging" />
        </div>
        <span>{{ $t('badgings.toolbar.validate_day_modal.description') }}</span>
        <span class="validate-day-modal__bold-text">
          {{ $t('badgings.toolbar.validate_day_modal.day', { day: formattedDateWithoutYear }) }}
        </span>
        <pre />
        <div v-if="canEditKpis">
          <span>{{ $t('badgings.toolbar.validate_day_modal.turnover_prepend') }}</span>
          <span class="validate-day-modal__bold-text">
            {{ $t('badgings.toolbar.validate_day_modal.turnover_bold_text') }}
          </span>
          <span>{{ $t('badgings.toolbar.validate_day_modal.turnover_append') }}</span>
          <div class="validate-day-modal__input-line row px-3">
            <span class="validate-day-modal__bold-text col-5 pl-0">
              {{ $t('badgings.toolbar.validate_day_modal.revenue_input.title') }}
            </span>
            <div class="col-6 pr-0">
              <SkInputGroup
                :errored="emptyRealRevenue"
                :error-message="$t('badgings.toolbar.validate_day_modal.errors.input')"
              >
                <SkInput
                  v-model="dayRevenue"
                  :label="$t('badgings.toolbar.validate_day_modal.revenue_input.label')"
                  type="number"
                  min="0"
                  step="0.01"
                  class="validate-day-modal__input"
                />
                <template #append>
                  {{ currentShop.attributes.currencySymbol }}
                </template>
              </SkInputGroup>
            </div>
          </div>
          <div
            v-if="canUpdateRealVolume"
            class="validate-day-modal__input-line row px-3"
          >
            <div class="validate-day-modal__bold-text validate-day-modal__input-title col-5 pl-0">
              <span class="validate-day-modal__title">
                {{ $t('badgings.toolbar.validate_day_modal.volume_input.title') }}
              </span>
              <CircledQuestionMarkIcon
                v-tooltip.bottom="$t('badgings.toolbar.validate_day_modal.volume_input.tooltip')
                "
              />
            </div>
            <div class="col-6 pr-0">
              <SkInputGroup
                :errored="emptyRealVolume"
                :error-message="$t('badgings.toolbar.validate_day_modal.errors.input')"
              >
                <SkInput
                  v-model="dayVolume"
                  :label="$t('badgings.toolbar.validate_day_modal.volume_input.label')"
                  type="number"
                  min="0"
                  step="1"
                  class="validate-day-modal__input"
                  is-integer
                />
              </SkInputGroup>
            </div>
          </div>
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="submitLoading"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import UpsellEsignatureBanner from '@app-js/shared/components/UpsellEsignatureBanner';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'ValidateDayModal',
  components: { UpsellEsignatureBanner },
  data() {
    return {
      submitLoading: false,
      realRevenuesLoading: false,
      realRevenues: [],
      realVolumes: [],
      dayRevenue: 0,
      dayVolume: 0,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('badgings', ['currentDate']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('badgings', ['matchedBadgingsPerDay']),
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled', 'isEsignatureTimeSheetEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    canEditKpis() {
      return this.currentLicense.attributes.canEditManagementIndicators;
    },
    dayIndex() {
      return skDate(this.currentDate).isoWeekday() - 1;
    },
    formattedDateWithYear() {
      return skDate(this.currentDate).format('dddd LL');
    },
    formattedDateWithoutYear() {
      return skDate(this.currentDate).format('dddd DD MMMM');
    },
    emptyRealRevenue() {
      return !this.dayRevenue && this.dayRevenue !== 0;
    },
    emptyRealVolume() {
      return !this.dayVolume && this.dayVolume !== 0;
    },
    canUpdateRealVolume() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_KPI_REAL_ADVANCE, this.currentShop.id, null,
      );
    },
    isSubmitDisabled() {
      if (!this.canUpdateRealVolume) return this.emptyRealRevenue;

      return this.emptyRealRevenue || this.emptyRealVolume;
    },
    showEsignatureUpsellBanner() {
      return this.checkFeatureFlag('FEATURE_ESIGNATURE') &&
        this.currentLicense.attributes.canPublishPlanning &&
        !this.isEsignatureTimeSheetEnabled() &&
        this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_UPSELL');
    },
  },
  methods: {
    ...mapActions('currentShop', ['updateShop']),
    ...mapActions('badgings', ['updateMatchedBadging']),

    async handleSubmit() {
      this.submitLoading = true;

      this.$skAnalytics.track('badgings_validate', { source: 'day' });

      const componentParams = {
        rows: this.matchedBadgingsPerDay(),
        start_date: this.currentDate,
        date: this.currentDate,
        shop_id: this.currentShop.id,
        team_ids: this.$route.query.team_ids,
        user_id: this.$route.params.user_id || null,
      };

      try {
        await Promise.all([
          this.updateRealRevenue(),
          this.updateMatchedBadging({ componentParams }),
        ]);

        this.$refs.validateDayModal.hide();
        this.$skToast({
          message: this.$t('badgings.toolbar.validate_day_modal.success_message'),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('shop_settings.update_shop.error_message'),
          variant: 'error',
        });
      } finally {
        this.submitLoading = false;
      }
    },
    updateRealRevenue() {
      if (!this.canEditKpis) return true;

      this.realRevenues.splice(this.dayIndex, 1, this.dayRevenue.toFixed(2));

      const weeklyOptionsParams = {
        real_revenues: this.realRevenues,
        shop_id: this.currentShop.id,
        date: this.currentDate,
      };

      if (this.canUpdateRealVolume) {
        this.realVolumes.splice(this.dayIndex, 1, this.dayVolume);
        weeklyOptionsParams.real_volumes = this.realVolumes;
      }

      return httpClient.patch('/v3/api/weekly_options', weeklyOptionsParams);
    },
    handleShow() {
      if (this.canEditKpis) {
        this.fetchRealRevenues();
      }
    },
    fetchRealRevenues() {
      this.realRevenuesLoading = true;
      const params = {
        shop_id: this.currentShop.id,
        date: this.currentDate,
      };
      httpClient
        .get('/v3/api/weekly_options', { params })
        .then(response => {
          const { realRevenues, realVolumes } = response.data.data.attributes;
          this.realRevenues = realRevenues.map(revenue => (!revenue ? 0 : revenue));
          this.dayRevenue = this.realRevenues[this.dayIndex];
          this.realVolumes = realVolumes.map(volume => (!volume ? 0 : volume));
          this.dayVolume = this.realVolumes[this.dayIndex];
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.realRevenuesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.validate-day-modal__bold-text {
  font-weight: $fw-semi-bold;
}

.validate-day-modal__input-title {
  display: flex;
  align-items: center;
}

.validate-day-modal__title {
  margin-right: 10px;
}

.validate-day-modal__input-line {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.validate-day-modal__input {
  width: 220px !important;
}

.validate-day-modal__loader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 146px;
}

.sk-modal__section {
  padding: 24px 0 0 !important;
}

::v-deep .sk-modal__header {
  border-bottom: 1px solid $sk-grey-10;
}

::v-deep .sk-modal__footer {
  padding: 30px 24px 24px !important;
}

::v-deep .sk-input__error-label {
  z-index: 1000;
}

.validate-day-modal__upsell-banner-wrapper {
  margin-bottom: 24px;
}
</style>
