<template>
  <div class="pos-partners-wrapper">
    <p class="sk-subtitle--large mt-3">
      {{ $t('organisation_settings.tabs.pos_partners.title') }}
    </p>
    <p class="sk-subtitle--large mt-3">
      {{ $t('organisation_settings.tabs.pos_partners.subtitle') }}
    </p>

    <MissingPartnerCard
      :title="$t('organisation_settings.tabs.pos_partners.missing.title')"
      :subtitle="$t('organisation_settings.tabs.pos_partners.missing.subtitle')"
    >
      <MissingPosPartnerIcon />
    </MissingPartnerCard>

    <h2 class="sk-header--2 mb-3">
      {{ $t('organisation_settings.tabs.pos_partners.software_list') }}
    </h2>

    <div
      v-if="isLoading"
      class="pos-partners--loader"
    >
      <SkLoader size="large" />
    </div>

    <div
      v-for="posIntegration in sortedIntegrationProviders"
      v-else
      :key="posIntegration.name"
      class="mb-3"
    >
      <PosPartnerCard
        :ref="posIntegration.ref || getProviderCardRefKey(posIntegration.id)"
        :value="posIntegration.value || posIntegration.active || posIntegration.pending"
        :disabled="posIntegration.disabled"
        :loading="posIntegration.loading"
        :tooltip-on-disabled="tootlipMessage(posIntegration.name)"
        :icon-url="posIntegration.logo"
        :icon-name="posIntegration.iconName"
        :name="posIntegration.name"
        :pending="posIntegration.pending"
        :toggle-disabled="posIntegration.pending"
        @input="updatePosIntegration(posIntegration, $event)"
      >
        <component
          :is="posIntegration.component"
          v-if="posIntegration.component"
        />
        <GenericPartner
          v-else
          :ref="getGenericPartnerRefKey(posIntegration.id)"
          :integration="posIntegration"
          @submit-clicked="closeProviderCard(posIntegration.id)"
        />
      </PosPartnerCard>
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ZeltyAuthenticationModal
        ref="zeltyAuthenticationModal"
        @success="openZeltyCard"
      />
      <LightspeedAuthenticationModal ref="lightspeedAuthenticationModal" />
      <LadditionAuthenticationModal
        ref="ladditionAuthenticationModal"
        @success="openLadditionCard"
      />
      <GenericAuthenticationModal
        ref="genericAuthenticationModal"
        @continue="handleContinueToLink"
        @cancel="cancelPosIntegrationActivation"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import {
  httpClient,
  svcPosClient,
} from '@skello-utils/clients';

import GenericAuthenticationModal from './GenericPartner/Modals/AuthenticationModal/GenericAuthenticationModal';
import GenericPartner from './GenericPartner';
import Laddition from './Laddition';
import LadditionAuthenticationModal from './Laddition/LadditionAuthenticationModal';
import Lightspeed from './Lightspeed';
import LightspeedAuthenticationModal from './Lightspeed/LightspeedAuthenticationModal';
import PosPartnerCard from './PosPartnerCard';
import Tiller from './Tiller';
import Zelty from './Zelty';
import ZeltyAuthenticationModal from './Zelty/ZeltyAuthenticationModal';
import MissingPartnerCard from '../shared/MissingPartnerCard';
import { MissingPosPartnerIcon } from '../shared/icons/index';

export default {
  name: 'PosPartners',
  components: {
    MissingPartnerCard,
    MissingPosPartnerIcon,
    PosPartnerCard,
    GenericPartner,
    Zelty,
    Lightspeed,
    Tiller,
    Laddition,
    ZeltyAuthenticationModal,
    LadditionAuthenticationModal,
    LightspeedAuthenticationModal,
    GenericAuthenticationModal,
  },
  data() {
    return {
      shopIds: [],
      isLoading: false,
      providerIdToExpand: null,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('partnersTools', [
      'lightspeedAuthenticating',
      'ladditionMatchingShopsList',
      'ladditionShopsLoading',
      'ladditionActivated',
      'integrations',
    ]),
    ...mapGetters('partnersTools', [
      'retrieveActiveIntegration',
      'integrationProviders',
      'isAnyOtherIntegrationEnabled',
      'isActiveIntegrationFromChift',
      'shouldDisableIntegration',
      'isZeltyIntegrationEnabled',
      'isTillerIntegrationEnabled',
    ]),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ladditionIntegrationActivated() {
      return !this.ladditionShopsLoading &&
        (this.ladditionActivated || Object.keys(this.ladditionMatchingShopsList).length > 0);
    },
    lightspeedIntegrationActivated() {
      return !!this.currentOrganisation.attributes.lightspeedKey;
    },
    hideTillerFeatureFlagActivated() {
      return this.isDevFlagEnabled('FEATUREDEV_POS_HIDE_TILLER_LEGACY');
    },
    sortedIntegrationProviders() {
      let sortedList = [...this.integrationProviders];
      if (!this.hideTillerFeatureFlagActivated) {
        sortedList = sortedList.filter(
          integration => integration.chiftId !== 9020, // tiller id on chift side
        );
      }
      sortedList = sortedList.concat(this.partnerTools)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (!this.chiftPosProvidersV2FFEnabled) {
        return sortedList.filter(
          integration => typeof integration.isSupportingLocations === 'undefined' ||
            integration.isSupportingLocations === true,
        );
      }

      return sortedList;
    },
    isLadditionDisabled() {
      return this.shouldDisableIntegration('ADDITION');
    },
    isZeltyDisabled() {
      return this.shouldDisableIntegration('ZELTY');
    },
    isLightspeedDisabled() {
      return this.shouldDisableIntegration('LIGHTSPEED');
    },
    isTillerDisabled() {
      return this.shouldDisableIntegration('TILLER');
    },
    chiftPosProvidersFFEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_CHIFT');
    },
    chiftPosProvidersV2FFEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_CHIFT_V2');
    },
    partnerTools() {
      const integrations = [
        {
          ref: 'zeltyCard',
          iconName: 'Zelty',
          name: 'Zelty',
          value: this.isZeltyIntegrationEnabled,
          loading: false,
          disabled: this.isZeltyDisabled,
          component: Zelty,
          type: 'custom',
          activate: this.activateZelty,
          deactivate: this.deactivateZeltyIntegration,
        },
        {
          ref: 'lightspeedCard',
          iconName: 'Lightspeed',
          name: 'Lightspeed (K Series)',
          value: this.lightspeedIntegrationActivated,
          loading: this.lightspeedAuthenticating,
          disabled: !this.lightspeedAuthenticating && this.isLightspeedDisabled,
          component: Lightspeed,
          type: 'custom',
          activate: this.activateLightSpeed,
          deactivate: this.deactivateLightspeedIntegration,
        },
        {
          ref: 'ladditionCard',
          iconName: 'Laddition',
          name: "L'Addition",
          value: this.ladditionIntegrationActivated,
          loading: this.ladditionShopsLoading,
          disabled: this.isLadditionDisabled,
          testingOptions: { toggle: 'partner-tools-pos-addition__toggle' },
          component: Laddition,
          type: 'custom',
          activate: this.activateLaddition,
          deactivate: this.deactivateLadditionIntegration,
        },
      ];
      if (!this.hideTillerFeatureFlagActivated) {
        integrations.push({
          ref: 'tillerCard',
          iconName: 'Tiller',
          name: 'Tiller',
          value: this.isTillerIntegrationEnabled,
          loading: false,
          disabled: this.isTillerDisabled,
          component: Tiller,
          type: 'custom',
          activate: this.activateTiller,
          deactivate: this.onDeactivateTiller,
        });
      }

      return integrations;
    },
  },
  async created() {
    // Lightspeed authorization callback comes back to this page with a code in the params.
    // If there is the code params in the url then we can go on for authentication
    // to get the token and then fetch the lightspeed shops
    if (this.$router.currentRoute.query.code) {
      await this.handleLightspeedCallback();
    }

    this.shopIds = await this.fetchShops();
    await this.fetchLadditionShops(this.shopIds);
    this.cleanLinkedShopFromStore();
  },
  async mounted() {
    this.isLoading = true;

    try {
      if (this.chiftPosProvidersFFEnabled) {
        await this.fetchChiftProviders();
      }

      await this.fetchPosProviders(this.currentOrganisation.id);
      const activeProviderId = this.retrieveActiveIntegration?.id;
      if (activeProviderId) {
        if (!this.isActiveIntegrationFromChift) {
          await this.fetchProviderRequirements(activeProviderId);
        }
        // Check if shopIds is empty fetch shops again
        if (!this.shopIds.length) {
          this.shopIds = await this.fetchShops();
        }
        await this.fetchRegisteredIntegrations({ shopIds: this.shopIds });
        if (this.$router.currentRoute.query?.referrer === 'chift') {
          this.providerIdToExpand = activeProviderId;
        }
      }
    } catch (error) {
      console.error(error.message);
      this.$skToast({
        message: this.$t('organisation_settings.tabs.pos_partners.generic.errors.fetch_providers'),
        variant: 'error',
      });
    } finally {
      this.isLoading = false;
      this.$nextTick(() => {
        if (this.providerIdToExpand) {
          this.openAndScrollToProvider(this.providerIdToExpand);
        }
      });
    }
  },
  methods: {
    ...mapMutations('currentOrganisation', ['setOrganisationAttributes']),
    ...mapMutations('partnersTools', [
      'setLadditionActivated',
      'activatingPosIntegration',
      'cancelledPosIntegrationActivation',
      'completePosIntegrationActivation',
      'cleanLinkedShopFromStore',
      'recomputeDisabledState',
    ]),
    ...mapActions('partnersTools', [
      'activateTillerIntegration',
      'deactivateTillerIntegration',
      'authenticateLightspeed',
      'deleteLightspeedIntegration',
      'deleteZeltyIntegration',
      'deleteLadditionIntegrations',
      'fetchLadditionShops',
      'fetchProviderRequirements',
      'fetchPosProviders',
      'fetchRegisteredIntegrations',
      'activatePosIntegration',
      'deactivatePosIntegration',
      'fetchChiftProviders',
    ]),
    getProviderCardRefKey(providerId) {
      const providerKey = providerId.toLowerCase();
      return `${providerKey}Card`;
    },
    getGenericPartnerRefKey(providerId) {
      return `partner${providerId}`;
    },
    notifyGenericPartnerOfOutsideClick(providerId) {
      const providerRefKey = this.getGenericPartnerRefKey(providerId);
      const partnerCardContentRef = this.$refs[providerRefKey];
      if (partnerCardContentRef && partnerCardContentRef[0]) {
        this.$refs[providerRefKey][0].handleOutsideClick();
      }
    },
    openProviderCard(providerId) {
      const providerCardRefKey = this.getProviderCardRefKey(providerId);

      this.$refs[providerCardRefKey][0].$refs.collapse.openCollapse();
    },
    closeProviderCard(providerId) {
      const providerCardRefKey = this.getProviderCardRefKey(providerId);

      this.$refs[providerCardRefKey][0].$refs.collapse.closeCollapse();
    },
    async activateIntegrationAndOpenProviderCard(provider, config = {}) {
      await this.activatePosIntegration({
        provider,
        config,
        organisationId: this.currentOrganisation.id,
      });

      this.openProviderCard(provider.id);
    },
    updatePosIntegration(posIntegration, newValue) {
      if (newValue) {
        this.handleActivatePosIntegration(posIntegration);
      } else {
        if (posIntegration.type === 'custom') {
          this.displayConfirmationModal(posIntegration);
          return;
        }
        this.notifyGenericPartnerOfOutsideClick(posIntegration.id);
        this.handleEnsureProviderDeactivation(posIntegration);
      }
    },
    cancelPosIntegrationActivation({ providerId }) {
      this.cancelledPosIntegrationActivation({ providerId });
    },
    handleActivatePosIntegration(posIntegration) {
      if (posIntegration.type === 'custom') {
        posIntegration.activate();
      } else {
        this.handlePOSIntegration(posIntegration);
      }
    },
    async handlePOSIntegration(posIntegration) {
      try {
        if (posIntegration.chiftId) {
          this.handleChiftIntegration(posIntegration);
        } else {
          this.activatingPosIntegration({ providerId: posIntegration.id });
          const requirements = await this.fetchProviderRequirements(posIntegration.id);
          if (requirements.organisation.actions.length) {
            this.handleIntegrationRequirements(posIntegration);
          } else {
            this.activateIntegrationAndOpenProviderCard(posIntegration);
          }
        }
      } catch (error) {
        console.error(error.message);
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.generic.errors.activation'),
          variant: 'error',
        });
      }
    },
    async chiftOrganisationActivation(posIntegration) {
      try {
        const {
          id,
          attributes: { name },
        } = this.currentOrganisation;

        const activateProviderResponse = await svcPosClient.activateProvider({
          name: `${name} (${id})`,
          organisationId: id,
          providerId: posIntegration.chiftId,
          providerName: posIntegration.name,
        });
        window.location = activateProviderResponse.url;
        this.completePosIntegrationActivation(
          { providerId: posIntegration.id, activationSuccess: true },
        );
      } catch (error) {
        console.error(error.message);
        this.completePosIntegrationActivation(
          { providerId: posIntegration.id, activationSuccess: false },
        );
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.chift.errors.activation'),
          variant: 'error',
        });
      }
    },
    async handleChiftIntegration(posIntegration) {
      this.activatingPosIntegration({ providerId: posIntegration.id });
      if (posIntegration.isSupportingLocations) {
        this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
          description: this.$t('organisation_settings.tabs.pos_partners.chift.activate_modal.description'),
          submitLabel: this.$t('organisation_settings.tabs.pos_partners.chift.activate_modal.submit_label'),
          title: this.$t('organisation_settings.tabs.pos_partners.chift.activate_modal.title', { name: posIntegration.name }),
          submitColor: this.$skColors.skBlue50,
          onConfirm: () => this.chiftOrganisationActivation(posIntegration),
          onCancel: async () => {
            this.cancelledPosIntegrationActivation({ providerId: posIntegration.id });
          },
        });
      } else {
        await this.activateIntegrationAndOpenProviderCard(posIntegration, {
          providerId: posIntegration.chiftId,
          providerName: posIntegration.name,
          shopAndConsumerIdsMapping: [],
        });
      }
    },
    activateTiller() {
      this.activateTillerIntegration()
        .then(() => {
          this.openTillerCard();
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.tiller.modal.authenticate_success'),
            variant: 'success',
          });
        })
        .catch(error => {
          console.error(error.message);
          const message = error.response?.data?.message ?? this.$t('organisation_settings.error_message');
          this.$skToast({
            message,
            variant: 'error',
          });
        });
    },
    activateLightSpeed() {
      this.$refs.lightspeedAuthenticationModal.openModal();
    },
    activateZelty() {
      this.$refs.zeltyAuthenticationModal.openModal();
    },
    activateLaddition() {
      this.setLadditionActivated(true);
      this.recomputeDisabledState();
      this.openLadditionCard();
    },
    displayConfirmationModal(posIntegration) {
      const name = posIntegration.name;
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.body', { name }),
        onConfirmAsync: posIntegration.deactivate,
        submitLabel: this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.submit'),
        title: this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.title', { name }),
      });
    },
    handleEnsureProviderDeactivation(integration) {
      const name = integration.name;
      const description = this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.body', { name });
      const submitLabel = this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.submit');
      const title = this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.title', { name });

      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description,
        onConfirmAsync: () => this.handleDeactivatePosIntegration(integration.id),
        submitLabel,
        title,
      });
    },
    async handleDeactivatePosIntegration(providerId) {
      const organisationId = this.currentOrganisation.id;

      try {
        await this.deactivatePosIntegration({ providerId, organisationId });

        this.closeProviderCard(providerId);
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.generic.success.deactivation', { name: providerId }),
          variant: 'success',
        });
      } catch (error) {
        console.error(error.message);
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.generic.errors.deactivation'),
          variant: 'error',
        });
      }
    },
    handleIntegrationRequirements(posIntegration) {
      this.$refs.genericAuthenticationModal.openModal({
        authenticationType: 'organisation',
        integration: posIntegration,
      });
    },
    handleLinkErrorOnModal(error) {
      this.$refs.genericAuthenticationModal.handleInputError(error.response?.data?.message ||
        this.$t('organisation_settings.tabs.pos_partners.generic.authentication_modal.error.connection'));
    },

    handleCloseLinkModal() {
      this.$refs.genericAuthenticationModal.closeModal();
    },
    handleContinueToLink({ providerId, config, organisationId }) {
      this.activatePosIntegration({
        config,
        providerId,
        organisationId,
      })
        .then(() => {
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.generic.success.link_shop'),
            variant: 'success',
          });

          this.handleCloseLinkModal();
          this.openProviderCard(providerId);
        })
        .catch(error => {
          console.error(error.message);
          this.handleLinkErrorOnModal(error);
        });
    },
    async fetchShops() {
      try {
        const response = await httpClient.get('/v3/api/shops', { params: { skip_pagination: true } });
        const shops = response.data.data;
        return shops.map(shop => shop.id);
      } catch (error) {
        console.error('Failed to fetch shops:', error.message);
        this.$skToast({
          message: this.$t('shop_settings.update_shop.error_message'),
          variant: 'error',
        });
        return [];
      }
    },
    tootlipMessage(name) {
      let activePosName = null;
      if (this.isTillerIntegrationEnabled && name !== 'Tiller') {
        activePosName = 'Tiller';
      } else if (this.isZeltyIntegrationEnabled && name !== 'Zelty') {
        activePosName = 'Zelty';
      } else if (this.ladditionIntegrationActivated && name !== 'Laddition') {
        activePosName = "L'Addition";
      } else if (this.lightspeedIntegrationActivated && name !== 'Lightspeed') {
        activePosName = 'Lightspeed';
      } else {
        activePosName = this.retrieveActiveIntegration?.name;
      }

      if (!activePosName || activePosName === name) return '';

      return this.$t(
        'organisation_settings.tabs.pos_partners.disabled_tooltip',
        { activePosName, name },
      );
    },
    // Tiller
    openTillerCard() {
      if (this.$refs.tillerCard) {
        this.$refs.tillerCard[0].$refs.collapse.handleLabelClick();
      }
    },
    onDeactivateTiller() {
      // return is needed for the confirm modal callback
      return this.deactivateTillerIntegration()
        .then(() => {
          this.$refs.tillerCard[0].$refs.collapse.closeCollapse();
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.generic.modal.deactivate_success', { name: 'Tiller' }),
            variant: 'success',
          });
        })
        .catch(e => {
          console.error(error.message);
          const message = error.response?.data?.message ?? this.$t('organisation_settings.error_message');
          this.$skToast({
            message,
            variant: 'error',
          });
        });
    },
    // Lightspeed
    openLightspeedCard() {
      if (this.$refs.lightspeedCard) {
        this.$refs.lightspeedCard[0].$refs.collapse.handleLabelClick();
      }
    },
    deactivateLightspeedIntegration() {
      // return is needed for the confirm modal callback
      return this.deleteLightspeedIntegration()
        .then(() => {
          this.$refs.lightspeedCard[0].$refs.collapse.closeCollapse();
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.generic.modal.deactivate_success', { name: 'Lightspeed (K Series)' }),
            variant: 'success',
          });
        })
        .catch(e => {
          console.error(error.message);
          const message = error.response?.data?.message ?? this.$t('organisation_settings.error_message');
          this.$skToast({
            message,
            variant: 'error',
          });
        });
    },
    // Zelty
    openZeltyCard() {
      if (this.$refs.zeltyCard) {
        this.$refs.zeltyCard[0].$refs.collapse.handleLabelClick();
      }
    },
    deactivateZeltyIntegration() {
      // return is needed for the confirm modal callback
      return this.deleteZeltyIntegration()
        .then(() => {
          this.$refs.zeltyCard[0].$refs.collapse.closeCollapse();
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.generic.modal.deactivate_success', { name: 'Zelty' }),
            variant: 'success',
          });
        })
        .catch(error => {
          console.error(error.message);
          const message = error.response?.data?.message ?? this.$t('organisation_settings.error_message');
          this.$skToast({
            message,
            variant: 'error',
          });
        });
    },
    openLadditionCard() {
      if (this.$refs.ladditionCard) {
        this.$refs.ladditionCard[0].$refs.collapse.openCollapse();
      }
    },
    deactivateLadditionIntegration() {
      // return is needed for the confirm modal callback
      return this.deleteLadditionIntegrations()
        .then(() => {
          this.$refs.ladditionCard[0].$refs.collapse.closeCollapse();
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.generic.modal.deactivate_success', { name: 'L’Addition' }),
            variant: 'success',
          });
        })
        .catch(error => {
          console.error(error.message);
          this.$skToast({
            message: this.$t('organisation_settings.error_message'),
            variant: 'error',
          });
        });
    },
    openAndScrollToProvider(providerId) {
      const providerCardRefKey = this.getProviderCardRefKey(providerId);
      const providerCardElement = this.$refs[providerCardRefKey];
      if (providerCardElement && providerCardElement[0]) {
        providerCardElement[0].$refs.collapse.openCollapse();
        providerCardElement[0].$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    async handleLightspeedCallback() {
      try {
        await this.authenticateLightspeed(this.$router.currentRoute.query.code);
        this.openLightspeedCard();
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.lightspeed.modal.authenticate_success'),
          variant: 'success',
        });
      } catch (error) {
        console.error(error.message);
        throw error;
      } finally {
        this.$router.replace('');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pos-partners-wrapper {
  padding-bottom: 104px;
}

.pos-partners--loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
