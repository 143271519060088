<template>
  <div
    ref="pageContainer"
    class="self-serve__page-container"
  >
    <section
      v-if="isBannerVisible"
      class="self-serve__banner"
    >
      <SideBlock class="self-serve__banner-background" />
      <SkelloLogo class="self-serve__banner-icon" />
    </section>
    <section :class="rightContainerClasses">
      <main class="self-serve__main-content">
        <header class="self-serve__main-content__header">
          <div
            v-if="shouldRenderBackButton"
            class="self-serve__main-content__back-button"
          >
            <CircledNextArrowIcon
              width="35"
              height="35"
              fill="#000000"
              @click.native="goBack"
            />
          </div>
          <ProgressBar
            v-if="progressBarStep"
            :current-step="progressBarStep"
            :step-count="5"
          />
          <p class="sk-heading-large-semibold self-serve__page-title">
            <span>
              {{ currentStepConfig.title }}
            </span>
          </p>
        </header>
        <router-view :next="goNext" />
      </main>
    </section>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { CircledNextArrowIcon } from '@skelloapp/skello-ui';
import { listenIntercomInit } from '@skello-utils/listen_intercom_init';
import SidebarSvg from '../shared/assets/images/sidebar.svg';
import ProgressBar from '../shared/components/ProgressBar/index.vue';
import SkelloLogo from '../shared/assets/svg/SkelloLogo.vue';
import SideBlock from '../shared/assets/svg/SideBlock.vue';

export default {
  name: 'SelfServe',
  components: {
    ProgressBar,
    SideBlock,
    SkelloLogo,
    CircledNextArrowIcon,
  },
  data() {
    return {
      intercomInstance: null,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState('onboarding', ['currentUser', 'sector']),
    ...mapGetters('onboarding', ['queryRawBundle']),

    stepsConfig() {
      return {
        step_one: {
          title: this.$t('self_serve.step_one.title'),
          communicationImage: SidebarSvg,
          progressBarStep: null,
        },
        step_two: {
          title: this.$t('self_serve.step_two.title'),
          communicationImage: SidebarSvg,
          progressBarStep: null,
        },
        step_three: {
          title: this.$t('self_serve.step_three.title'),
          progressBarStep: 2,
        },
        step_four: {
          title: this.$t('self_serve.v5.step_four.title'),
          communicationText: this.$t('self_serve.step_four.communication_text'),
          progressBarStep: 3,
        },
        step_five: {
          title: this.$t('self_serve.step_five.title'),
          progressBarStep: 4,
          shouldHideBanner: true,
        },
        step_six: {
          title: this.$t('self_serve.v5.step_six.content_title'),
          progressBarStep: 5,
          shouldHideBanner: true,
        },
      };
    },
    currentStepConfig() {
      const config = this.stepsConfig[this.$route.name];
      this.setIntercomPadding();
      return config;
    },
    progressBarStep() {
      return this.currentStepConfig.progressBarStep;
    },
    isAfterStepTwo() {
      return !this.isStep('step_one') && !this.isStep('step_two');
    },
    shouldRenderBackButton() {
      return this.isAfterStepTwo && !this.isStep('step_three');
    },
    getCommunicationTextSize() {
      if (this.isStep('step_five')) {
        if (this.screenWidth > 1340) return '46px';
        return '42px';
      }
      if (this.screenWidth > 1340) return '42px';
      return '38px';
    },
    isBannerVisible() {
      return !this.currentStepConfig.shouldHideBanner;
    },
    rightContainerClasses() {
      return {
        'self-serve__right-container': true,
        'banner-invisible': !this.isBannerVisible,
      };
    },
  },
  async created() {
    window.addEventListener('resize', this.updateResize);
    document.title = this.$t('self_serve.title');

    this.intercomInstance = await listenIntercomInit();

    this.updateResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateResize);
  },
  mounted() {
    this.initializeGoogleAnalytics();
  },
  methods: {
    initializeGoogleAnalytics() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = document.getElementsByTagName('script')[0];
      const j = document.createElement('script');
      j.async = true;
      j.src = 'https://msr.skello.io/jqcl9h2tsurhym3.js?id=GTM-PG2X6MS';
      f.parentNode.insertBefore(j, f);
    },
    isStep(step) {
      return this.$route.name.includes(step);
    },
    scrollToTop(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].scrollTo({ top: 0 });
      }
    },
    goNext(params = {}) {
      const nextStepMap = {
        step_one: 'step_two',
        step_two: 'step_three',
        step_three: 'step_four',
        step_four: this.queryRawBundle ? 'step_six' : 'step_five',
        step_five: 'step_six',
      };

      const currentStep = this.$route.name;
      const nextStep = nextStepMap[currentStep];
      if (nextStep) {
        this.$router.push({
          name: nextStep,
          query: {
            token: this.$route.query.token,
            lang: this.$i18n.locale,
          },
          params,
        });
        this.scrollToTop('pageContainer');
      }
    },
    goBack() {
      const currentStep = this.$route.name;
      this.$skAnalytics.track(`ss_back_button_${currentStep}`);
      const previousStepMap = {
        step_six: this.queryRawBundle ? 'step_four' : 'step_five',
        step_five: 'step_four',
        step_four: 'step_three',
        step_three: 'step_two',
        step_two: 'step_one',
      };

      const previousStep = previousStepMap[currentStep];
      if (previousStep) {
        this.$router.replace({
          name: previousStep,
          query: {
            token: this.$route.query.token,
            lang: this.$i18n.locale,
          },
        });
        this.scrollToTop('pageContainer');
      }
    },
    updateResize() {
      this.screenWidth = window.innerWidth;
      this.setIntercomPadding();
    },
    setIntercomPadding() {
      if (!this.intercomInstance) {
        return;
      }

      // Set this padding only for all step except 2 and 5 and when on mobile
      if (this.screenWidth <= 576 && !(this.isStep('step_two') || this.isStep('step_five'))) {
        this.intercomInstance.style.bottom = '78px';
      } else {
        this.intercomInstance.style.bottom = '20px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.self-serve__page-container {
  overflow: hidden;
}

.self-serve__right-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  padding-left: 432px;

  &.banner-invisible {
    padding-left: 0;
  }

  @media (max-width: 1023px) {
    padding-left: 0;
  }
}

.self-serve__main-content {
  padding-block: var(--padding-block);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  width: calc(100% - var(--padding-inline) * 2);
  max-width: var(--max-width);
  --gap: 32px;

  @media (min-width: 1024px) {
    --max-width: 464px;
    --padding-inline: 64px;
    --padding-block: 80px;

    .banner-invisible > & {
      --max-width: 916px;
    }
  }

  @media (max-width: 1023px) {
    --padding-inline: 32px;
    --padding-block: 48px;
    --max-width: 382px;
    --gap: 24px;
  }
  @media (max-width: 454px) {
    --padding-inline: 16px;
    --padding-block: 16px;
    --max-width: 358px;
  }
}

.self-serve__banner {
  min-height: 100vh;
  width: 432px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  @media (max-width: 1023px) { display: none }
}

.self-serve__banner-background {
  min-height: max(100vh, 810px);
}

.self-serve__banner-icon {
  position: absolute;
  top: 48px;
  right: 48px;
}

.self-serve__main-content__header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.self-serve__main-content__back-button svg {
  cursor: pointer;
  rotate: 180deg;
  ::v-deep circle{
    fill: $sk-grey-10;
  }
}

.self-serve__page-title {
  margin: 0;
}
</style>
