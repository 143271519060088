<script>
import GenericToolbarAction from './GenericToolbarAction';
import PlanningFilters from '../../PlanningFilters';

export default {
  name: 'PlanningFiltersAction',
  components: {
    PlanningFilters,
  },
  extends: GenericToolbarAction,
  props: {
    isDataLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    popovComponent() {
      return 'PlanningFilters';
    },
    icon() {
      return 'FiltersV2Icon';
    },
    visibleIn() {
      return 'toolbar-sm';
    },
    isPopov() {
      return true;
    },
    isVisible() {
      return true;
    },
  },
  methods: {},
};
</script>
