<template>
  <div class="flex-row">
    <div class="flex-m6 flex-row">
      <div class="flex-m6">
        <TrialsEndsCard />
      </div>
      <div class="flex-m6">
        <DocsExpiringCard />
      </div>
      <div class="flex-m6">
        <TurnoverCard />
      </div>
      <div class="flex-m6">
        <DeparturesArrivalsCard />
      </div>
    </div>
    <div class="flex-m6">
      <ActualityChart />
      <StaffCostsCard />
    </div>
  </div>
</template>

<script>
import ActualityChart from './Charts/ActualityChart.vue';
import TrialsEndsCard from './Cards/TrialsEndsCard.vue';
import DocsExpiringCard from './Cards/DocsExpiringCard.vue';
import TurnoverCard from './Cards/TurnoverCard.vue';
import DeparturesArrivalsCard from './Cards/DeparturesArrivalsCard.vue';
import StaffCostsCard from './Cards/StaffCostsCard.vue';

export default {
  name: 'DashboardSecondLine',
  components: {
    ActualityChart,
    DeparturesArrivalsCard,
    DocsExpiringCard,
    TrialsEndsCard,
    TurnoverCard,
    StaffCostsCard,
  },
};
</script>

<style lang="scss" scoped>
</style>
