<template>
  <div class="badgings__fixed-inner">
    <div
      v-if="isPlanningFeatureOn"
      class="user-resume__title"
    >
      {{ $t('badgings.users.cards.title') }}
    </div>
    <div
      :class="[isPlanningFeatureOn
        ? 'user-resume__cards-container'
        : 'user-resume__cards-container-noplanning']"
    >
      <div class="user-resume__card">
        <div class="user-resume__card-title">
          {{ $tc('badgings.users.cards.worked_hours', Math.ceil(getTotalHours)) }}
        </div>
        <SkDivCanvas
          v-if="loadingUsers"
          :width="190"
          :height="40"
          background-color="#296ced"
          gradient-color="#3176f7"
          animated
        />
        <div
          v-else
          class="user-resume__card-data"
        >
          {{ totalWorkedHours }} h
        </div>
      </div>
      <div
        v-if="isPlanningFeatureOn"
        class="user-resume__card"
        data-test="delay__card"
      >
        <div class="user-resume__card-title">
          {{ $tc('badgings.users.cards.delay', totalDelays) }}
        </div>
        <SkDivCanvas
          v-if="loadingUsers"
          :width="190"
          :height="40"
          background-color="#296ced"
          gradient-color="#3176f7"
          animated
        />
        <div
          v-else
          class="user-resume__card-data"
        >
          {{ totalDelays }}
        </div>
      </div>
    </div>
    <div
      :class="[isPlanningFeatureOn
        ? 'validation__card'
        : 'validation__card-noplanning']"
    >
      <div class="validation__card__left">
        <div class="validation__card__icon">
          <CircledIIcon
            fill="#2b66fe"
            width="14"
            height="14"
          />
        </div>
        <strong>{{ $t('badgings.users.cards.validation.title') }}</strong>
        &nbsp; {{ validatedMatchedBadgings.length }} / {{ matchedBadgingsPerUser().length }}
        {{ $tc('badgings.users.cards.validation.day_validated', matchedBadgingsPerUser().length) }}
      </div>
      <div>
        {{ $t('badgings.users.cards.validation.description') }}
        <strong>{{ $t('badgings.users.cards.validation.action') }}</strong>
      </div>
    </div>
    <ShiftsAbsencesTabs
      v-if="showShiftsAbsencesTabs"
      data-test="ShiftsAbsencesTabs"
    />
    <router-view />
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';
import skDate from '@skello-utils/dates';
import ShiftsAbsencesTabs from '@app-js/badgings/shared/components/ShiftsAbsencesTabs';

export default {
  name: 'Users',
  components: { ShiftsAbsencesTabs },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('badgings', ['loadingUsers', 'shifts', 'users']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('badgings', ['matchedBadgingsPerUser']),
    ...mapGetters('features', ['isFeatureEnabled']),
    isPlanningFeatureOn() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id);
    },
    getTotalHours() {
      return this.totalWorkedHours < 1 && this.totalWorkedHours > 0 ? 2 : this.totalWorkedHours;
    },
    validatedMatchedBadgings() {
      return this.matchedBadgingsPerUser().filter(matchedBadging => matchedBadging.validated);
    },
    totalWorkedHours() {
      const totalSeconds = (
        this.matchedBadgingsPerUser().filter(
          matchedBadging => matchedBadging.absenceCalculation === '',
        )
          .reduce((accumulator, currentValue) => (
            accumulator + currentValue.workDuration
          ), 0)
      );

      return Number.parseFloat((totalSeconds / 3600).toFixed(2));
    },
    totalDelays() {
      return this.validatedMatchedBadgings.filter(
        matchedBadging => skDate(matchedBadging.predictedStartsAt).utc().format('HH:mm') < matchedBadging.selectedStartsAt,
      ).length;
    },
    showShiftsAbsencesTabs() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id);
    },
  },
};
</script>

<style lang="scss">
.user-resume__title {
  font-size: $fs-text-m;
  font-style: normal;
  font-weight: $fw-semi-bold;
  text-align: left;
  padding-top: 27px;
}

.user-resume__cards-container {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-resume__cards-container-noplanning {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-resume__card {
  width: 272px;
  height: 100px;
  border-radius: 6px;
  border: 1px solid #dfe3ec;
  padding: 15px 20px 16px;
  margin-left: 25px;

  &:first-of-type {
    margin: 0;
    background-color: $sk-blue;
    border: none;

    .user-resume__card-title {
      color: white;
      font-weight: $fw-semi-bold;
    }

    .user-resume__card-data {
      color: white;
    }
  }
}

.user-resume__card-title {
  color: $sk-grey;
  font-size: $fs-text-m;
}

.user-resume__card-data {
  color: $sk-black;
  font-size: $fs-display-xs;
  font-weight: $fw-semi-bold;
}

.validation__card {
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;
  font-size: $fs-text-m;
  color: $sk-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  &-noplanning {
    margin: 24px 0;
    border-radius: 4px;
    border: 1px solid $sk-grey-10;
    font-size: $fs-text-m;
    color: $sk-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
}

.validation__card__left {
  display: flex;
  align-items: center;
}

.validation__card__icon {
  padding-right: 16px;
  margin-top: 5px;
}
</style>
