<template>
  <div class="shift-form__wrapper">
    <div
      v-if="!isWorkShiftUpdate"
      class="shift-form__content__left-border"
      :style="contentLeftBorderStyle"
    />
    <div class="shift-form__content__wrapper">
      <SkModalSection
        class="shift-form__content"
        @keydown.native.enter.stop=""
      >
        <div :class="timeTitleClasses">
          {{ $t('plannings.table.manage_shift_modal.tabs.commun.time') }}
        </div>
        <div class="shift-form__content-input">
          <div class="shift-form__content-input__timepicker-wrapper">
            <SkInputGroup class="shift-form__content-sk-time">
              <div class="shift-form__content-sk-time__wrapper">
                <ShiftTimePicker
                  v-model="shift.attributes.startsAt"
                  :current-shop="currentShop"
                  :reference-date="date"
                  :interval-in-minutes="15"
                  :hide-last-option="is24hShop"
                  x-offset="-6px"
                  data-test="shift-form__starts-at"
                  center
                  @input="updateShiftHours"
                  @errored="handleStartsAtError"
                />
                <span
                  v-if="shopPlanningConfig.attributes.customShift"
                  class="shift-form__content-sk-time__icon"
                >
                  <component
                    :is="shift.attributes.showStartTime ? 'OpenEyeIcon' : 'ClosedEyeIcon'"
                    width="18px"
                    :height="shift.attributes.showStartTime ? '12px' : '13px'"
                    @click.native="toggleVisibility('showStartTime', $event)"
                  />
                </span>
              </div>
            </SkInputGroup>
            <div class="shift-form__content-input__separator">
              -
            </div>
            <SkInputGroup class="shift-form__content-sk-time">
              <div class="shift-form__content-sk-time__wrapper">
                <ShiftTimePicker
                  v-model="shift.attributes.endsAt"
                  :current-shop="currentShop"
                  :reference-date="date"
                  :interval-in-minutes="15"
                  x-offset="-6px"
                  data-test="shift-form__ends-at"
                  center
                  @input="updateShiftHours"
                  @errored="handleEndsAtError"
                />
                <span
                  v-if="shopPlanningConfig.attributes.customShift"
                  class="shift-form__content-sk-time__icon"
                >
                  <component
                    :is="shift.attributes.showEndTime ? 'OpenEyeIcon' : 'ClosedEyeIcon'"
                    width="18px"
                    :height="shift.attributes.showEndTime ? '12px' : '13px'"
                    @click.native="toggleVisibility('showEndTime', $event)"
                  />
                </span>
              </div>
            </SkInputGroup>
          </div>
          <div>
            <SkInput
              v-model="pauseTime"
              class="shift-form__content-sk-input mr-2"
              data-test="shift-form__pause-time"
              :label="$t('plannings.table.manage_shift_modal.tabs.shift.duration.break')"
              type="number"
              min="0"
              max="86400"
            />
            <div
              v-if="shift.relationships.poste.attributes
                && shift.relationships.poste.attributes.percentagePauseTime"
              class="shift-form__content-pause-percent"
            >
              <!-- eslint-disable next-line max-len -->
              {{ $t('plannings.table.manage_shift_modal.tabs.shift.duration.pause_percent', { pausePercent: pauseTimePercentDisplay }) }}
            </div>
          </div>
          <div class="shift-form__content-duration__wrapper">
            <div class="shift-form__content-duration">
              {{ $t('plannings.table.manage_shift_modal.tabs.shift.duration.label') }}
              <div class="shift-form__content-duration-number__wrapper">
                <div class="shift-form__content-duration-number">
                  {{ displayedFormattedDuration }}
                </div>
                <div
                  v-if="shopPlanningConfig.attributes.customShift"
                  class="shift-form__content-duration-icon"
                >
                  <component
                    :is="shift.attributes.showDuration ? 'OpenEyeIcon' : 'ClosedEyeIcon'"
                    width="18px"
                    :height="shift.attributes.showDuration ? '12px' : '13px'"
                    @click.native="toggleVisibility('showDuration', $event)"
                  />
                </div>
              </div>
            </div>
          </div>
          <SkInput
            v-if="displayDelayInput"
            v-model="shift.attributes.delay"
            class="shift-form__delay mr-2 mt-2"
            :label="$t('plannings.table.manage_shift_modal.tabs.shift.duration.delay')"
            type="number"
            min="0"
          />
        </div>
      </SkModalSection>
      <SkModalSection
        class="shift-form__content"
        border-bottom="normal"
      >
        <div class="shift-form__content-title">
          {{ selectUserOrPosteTitle }}
        </div>
        <div class="shift-form__content-input">
          <ShiftEmployeeSelector
            v-if="isPostesView && isUpdateModal"
            :can-display-unassigned-shift="areUnassignedShiftsAllowed"
            :is-update-modal="isUpdateModal"
            :shift-user-id="shiftUserId"
            :users="formattedPlanningUsers"
            data-test="shift-form__user-select"
            display-options-subtext
            @update-user="updateUser"
            @keydown.native.enter.stop=""
          />
          <PlanningEmployeesSelect
            v-else-if="isPostesView && !isUpdateModal"
            :custom-label="employeesSelectLabel"
            :teams="teams"
            :users="formattedPlanningUsers"
            data-test="shift-form__user-select"
            display-options-subtext
            @update-users="updateMultipleUsers"
            @show="$emit('update-modal-state', true)"
            @hide="$emit('update-modal-state', false)"
            @keydown.native.enter.stop=""
          />
          <div
            v-else
            class="shift-form__poste-container"
          >
            <SkSelectV2
              v-model="selectedPosteId"
              :options="orderedPostesMapped"
              :width="skSelectWidth"
              :no-results-label="$t('search_bar.no_result')"
              :search-placeholder="$t('labels.search')"
              data-test="shift-form__poste-select"
              @keydown.native.enter.stop=""
            >
              <template #selected-option>
                <span class="shift-form__content-text">
                  {{ selectedPoste.attributes.name }}
                </span>
                <!-- Style is mandatory to add a color -->
                <span
                  class="shift-form__content-color shift-form__content-color--absolute"
                  :style="{ background: selectedPoste.attributes.color }"
                />
              </template>
              <template #option="{ option }">
                <span class="shift-form__content-text">
                  {{ option.text }}
                </span>
                <!-- Style is mandatory to add a color -->
                <span
                  class="shift-form__content-color mr-4"
                  :style="{ background: option.color }"
                  data-test="shift-form__poste-options"
                />
              </template>
            </SkSelectV2>
            <div>
              <SkOroraButton
                v-if="canCreatePoste"
                variant="tertiary"
                size="small"
                @click="openCreatePosteModal"
              >
                {{ $t('plannings.table.manage_shift_modal.tabs.shift.poste.create_poste') }}
              </SkOroraButton>
            </div>
          </div>
        </div>
      </SkModalSection>
      <SkModalSection
        v-if="hasMealRule"
        class="shift-form__content"
      >
        <div class="shift-form__content-title">
          {{ $t('plannings.table.manage_shift_modal.tabs.shift.meal.taken') }}
        </div>
        <div>
          <SkInput
            v-model="shift.attributes.nbMeal"
            :label="$t('plannings.table.manage_shift_modal.tabs.shift.meal.label')"
            type="number"
            min="0"
            data-test="shift-form__meal"
            @keydown.native.enter.stop=""
          />
        </div>
      </SkModalSection>
      <SkModalSection
        :class="shiftActivitiesSectionClasses"
        :border-bottom="isShiftActivitiesSectionBottomBorderDisplayed ? 'normal' : 'none'"
      >
        <ShiftActivitiesSection
          :shift="shift"
          :work-shift-key="workShiftKey"
          @keydown.native.enter.stop=""
        />
      </SkModalSection>
      <div
        v-if="isRemoveButtonDisplayed"
        class="shift-form__content__remove-button-container"
      >
        <SkOroraButton
          variant="secondary"
          :variant-color="$skColors.skError"
          icon="TrashCanV2Icon"
          data-test="shift-form__remove"
          @click="removeShiftForm"
          @keydown.native.enter.stop=""
        >
          {{ $t('actions.delete') }}
        </SkOroraButton>
      </div>
    </div>
    <CreatePosteModal
      @create-poste="handleCreatePoste"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import Vue from 'vue';

import { FEATURES } from '@app-js/shared/constants/features';
import { workingShiftsDuration } from '@skelloapp/skello-planning-helper';

import {
  getOverlappingShifts,
  formatDurationToHoursAndMinutes,
  openingAndClosingTimeAt,
  updateShiftEndsAtFor24hShop,
} from '@app-js/plannings/shared/utils/planning_helpers';

import ShiftActivitiesSection from '@app-js/plannings/shared/components/ShiftActivities/ShiftActivitiesSection';
import CreatePosteModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/ManageShiftModal/CreatePosteModal';
import {
  adjustedShiftDuration,
  formattedDuration,
  shiftDuration,
} from '@app-js/plannings/shared/utils/shift_helpers';
import PlanningEmployeesSelect from '@app-js/plannings/shared/components/PlanningEmployeesSelect';

import ShiftTimePicker from './ShiftTimePicker';
import ShiftEmployeeSelector from './ShiftEmployeeSelector';

export default {
  name: 'WorkShiftForm',
  components: {
    ShiftTimePicker,
    ShiftEmployeeSelector,
    ShiftActivitiesSection,
    PlanningEmployeesSelect,
    CreatePosteModal,
  },
  props: {
    date: {
      type: Object,
      default: () => {},
    },
    workShiftKey: {
      type: String,
      required: true,
    },
    isRemoveButtonDisplayed: {
      type: Boolean,
      default: true,
    },
    rowItem: {
      type: Object,
      default: () => {},
    },
    isUpdateModal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsShifts', ['workShifts', 'shiftUserIds']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsPostes', ['postes']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('mealRule', ['mealRule']),
    ...mapState('config', ['config']),
    ...mapState('shopTeams', ['teams']),
    ...mapState('planningsState', ['shopPlanningConfig']),
    ...mapGetters('planningsShifts', ['shiftsForUser', 'shiftsForCurrentWeek']),
    ...mapGetters('currentShop', ['is24hShop', 'hasMealRule']),
    ...mapGetters('planningsState', ['areUnassignedShiftsAllowed', 'isPostesView']),
    ...mapGetters('features', ['isFeatureEnabled']),
    shift() {
      return this.workShifts[this.workShiftKey];
    },
    selectedUserIds() {
      return this.shiftUserIds[this.workShiftKey];
    },
    shiftUserId() {
      return Number(this.selectedUserIds[0]);
    },
    isWorkShiftUpdate() {
      return !!this.shift.id;
    },
    timeTitleClasses() {
      return {
        'shift-form__content-title': true,
        'shift-form__content-title--up': this.shift.relationships.poste.attributes?.percentagePauseTime,
      };
    },
    formattedPlanningUsers() {
      return this.users.map(user => ({
        ...user,
        subtext: this.computeUserSubtext(user),
      }));
    },
    openingTime() {
      return openingAndClosingTimeAt(
        this.currentShop.attributes.openingTime,
        this.currentShop.attributes.closingTime,
        this.date.format(),
      ).openingTime;
    },
    orderedPostesMapped() {
      return this.orderedPostes().map(poste => (
        {
          id: poste.id,
          text: poste.attributes.name,
          color: poste.attributes.color,
        }
      ));
    },
    pauseTime: {
      get() {
        if (this.shift.attributes.pauseTime === '') {
          return '';
        }
        // pauseTime is saved in seconds in DB
        return Math.round(this.shift.attributes.pauseTime / 60);
      },

      set(newValue) {
        if (newValue === null || newValue === '') {
          this.shift.attributes.pauseTime = '';
          return;
        }
        if (newValue !== this.pauseTime) {
          // need to save pauseTime rounded in seconds in DB
          this.shift.attributes.pauseTime = Math.round(newValue * 60);
        }
      },
    },
    pauseTimePercentDisplay() {
      if (this.shiftDuration.asMinutes() === 0) return 0;

      // Formula based off of https://skello.atlassian.net/browse/DEV-20591
      const pauseTimeFraction = -this.pauseTime / (this.pauseTime - this.shiftDuration.asMinutes());
      return (pauseTimeFraction * 100).toFixed(0);
    },
    selectedPosteId: {
      get() {
        const currentPosteId = this.shift.relationships.poste.id;
        return this.postes.some(poste => poste.id === currentPosteId) ?
          currentPosteId :
          this.orderedPostes()[0].id;
      },

      set(newValue) {
        this.setPoste(newValue);
      },
    },
    selectedPoste() {
      return this.isPostesView ? this.rowItem : this.shift.relationships.poste;
    },
    displayDelayInput() {
      return this.isWorkShiftUpdate &&
        this.openingTime.isBefore(skDate()) &&
        !this.currentShop.attributes.hasPunchClock;
    },
    shiftDuration() {
      return shiftDuration(this.shift, this.currentShop.attributes.timezone);
    },
    adjustedShiftDuration() {
      const duration = adjustedShiftDuration(
        this.shift,
        this.currentShop,
        this.date,
      );

      this.$set(this.shift.errors, 'duration', this.isNegativeDuration(duration));
      this.$set(this.shift.errors, 'durationOverADay', this.isOverADayDuration(duration));

      return duration;
    },
    displayedFormattedDuration() {
      return formattedDuration(
        this.shift,
        this.currentShop,
        this.date,
      );
    },
    shiftHoursValues() {
      // Computed to watch all 3 properties
      return `${this.shift.attributes.startsAt}|${this.shift.attributes.endsAt}|${this.shift.attributes.pauseTime}`;
    },
    contentLeftBorderStyle() {
      return this.isWorkShiftUpdate && !this.isPostesView ?
        '' :
        `background-color: ${this.selectedPoste.attributes?.color};`;
    },
    shiftActivitiesSectionClasses() {
      return {
        'shift-form__content': true,
        'shift-form__content--no-bottom-padding': !this.isShiftActivitiesSectionBottomBorderDisplayed,
      };
    },
    skSelectWidth() {
      return this.isWorkShiftUpdate ? '410px' : '386px';
    },
    isShiftActivitiesSectionBottomBorderDisplayed() {
      return this.isWorkShiftUpdate || this.isRemoveButtonDisplayed;
    },
    selectUserOrPosteTitle() {
      return this.isPostesView ?
        this.$t('plannings.table.manage_shift_modal.tabs.absence.select_user') :
        this.$t('plannings.table.manage_shift_modal.tabs.shift.poste.title');
    },
    canCreatePoste() {
      const shiftShopId = this.shift.attributes.shopId || this.currentShop.id;
      return this.currentLicense.attributes.canCreatePosteAndTeam &&
      this.isFeatureEnabled(FEATURES.FEATURE_CREATE_POSITION, shiftShopId);
    },
    employeesSelectLabel() {
      // If list is empty or just contains one null element -> unassigned shift
      return this.selectedUserIds.length === 0 ||
              (this.selectedUserIds.length === 1 && this.selectedUserIds[0] === null) ?
        this.$t('plannings.table.manage_shift_modal.tabs.shift.unassigned') :
        this.$t('plannings.toolbar.modal.shared.select.employees');
    },
  },
  watch: {
    shiftHoursValues() {
      this.processNbMeal();
    },
  },
  mounted() {
    if (!this.isWorkShiftUpdate) {
      this.processNbMeal();
      this.updatePauseTime();
    }
    this.$emit('work-shift-form-mounted');
  },
  methods: {
    ...mapMutations('planningsShifts', ['setShiftUserIds']),
    computeUserSubtext(user) {
      let userShifts = this.shiftsForUser(user.id);

      if (this.isUpdateModal) {
        // Remove the current shift from the list of shifts to check for overlapping
        // when the user is linked to the current shift
        userShifts = userShifts.filter(shift => shift.id !== this.shift.id);
      }

      const overlappingShifts = getOverlappingShifts(userShifts, this.shift);

      if (overlappingShifts.length > 0) {
        return this.$t('plannings.table.manage_shift_modal.tabs.shift.user_conflict');
      }

      return this.$t('dates.hours_format', formatDurationToHoursAndMinutes(
        workingShiftsDuration(this.shiftsForUser(user.id)),
      ));
    },
    orderedPostes() {
      const postes = [...this.postes].sort((posteA, posteB) => (
        posteA.attributes.name.toLowerCase().localeCompare(posteB.attributes.name.toLowerCase())
      ));

      const shiftPoste = this.shift.relationships.poste;

      // If poste was deleted after shift was created,
      // We still want the Select to load and show the old poste,
      // So we need to add the old poste in the list
      if (!this.postes.find(poste => shiftPoste.id === poste.id)) {
        postes.unshift({ ...shiftPoste });
      }

      return postes;
    },
    handleStartsAtError(errored) {
      this.$set(this.shift.errors, 'startsAtFormat', errored);
    },
    handleEndsAtError(errored) {
      this.$set(this.shift.errors, 'endsAtFormat', errored);
    },
    isNegativeDuration(duration) {
      return duration.asMinutes() < 0;
    },
    isOverADayDuration(duration) {
      return duration.asSeconds() > 86400;
    },
    processNbMeal() {
      if (!this.hasMealRule) return;
      const mealAdditionTriggers = this.mealRule.relationships?.mealAdditionTriggers
        .filter(mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor ===
          this.config.meal_rules.meal_steps.taken_meals,
        );
      if (!mealAdditionTriggers) return;

      const triggerType = mealAdditionTriggers[0].attributes.triggerType;

      this.shift.attributes.nbMeal =
        mealAdditionTriggers.reduce((nbMeal, mealAdditionTrigger) => {
          if (this.shouldAddMeal(mealAdditionTrigger, triggerType)) {
            nbMeal += mealAdditionTrigger.attributes.mealCount;
          }
          return nbMeal;
        }, 0);
    },
    shouldAddMeal(mealAdditionTrigger, triggerType) {
      switch (triggerType) {
        case this.config.meal_rules.trigger_types.work_duration:
          return this.checkIfDurationTriggersMeal(mealAdditionTrigger);
        case this.config.meal_rules.trigger_types.hours:
          return this.checkIfHoursTriggersMeal(mealAdditionTrigger);
        case this.config.meal_rules.trigger_types.work_duration_and_hours:
          return this.checkIfDurationTriggersMeal(mealAdditionTrigger) &&
            this.checkIfHoursTriggersMeal(mealAdditionTrigger);
        default:
          return false;
      }
    },
    checkIfDurationTriggersMeal(mealAdditionTrigger) {
      const timeWorked = this.adjustedShiftDuration.asHours();
      const { workDurationMin, workDurationMax } = mealAdditionTrigger.attributes;

      return (timeWorked > workDurationMin) && (timeWorked <= workDurationMax);
    },
    checkIfHoursTriggersMeal(mealAdditionTrigger) {
      const startsAt = skDate(this.shift.attributes.startsAt);
      const endsAt = skDate(this.shift.attributes.endsAt);

      const dates = [startsAt, endsAt];

      return dates.some(date => {
        const rangeStart = skDate(date).utc(true)
          .hours(mealAdditionTrigger.attributes.rangeStartsAtHour)
          .minutes(mealAdditionTrigger.attributes.rangeStartsAtMin)
          .seconds(0)
          .milliseconds(0);

        const rangeEnd = skDate(date).utc(true)
          .hours(mealAdditionTrigger.attributes.rangeEndsAtHour)
          .minutes(mealAdditionTrigger.attributes.rangeEndsAtMin)
          .seconds(0)
          .milliseconds(0);

        if (rangeEnd.isBefore(rangeStart)) {
          rangeEnd.add(24, 'h');
        }

        return startsAt.isBetween(rangeStart, rangeEnd, undefined, '[)') ||
          endsAt.isBetween(rangeStart, rangeEnd, undefined, '(]') ||
          (startsAt.isBefore(rangeStart) && endsAt.isAfter(rangeEnd));
      });
    },
    updatePauseTime() {
      if (this.shiftDuration.asHours() <= this.currentShop.attributes.maxHoursWithoutPause) {
        this.shift.attributes.pauseTime = 0;
      } else if (this.shift.relationships.poste.attributes?.percentagePauseTime) {
        const pauseTimePercent = this.shift.relationships.poste.attributes.pauseTime / 6000;
        const shiftDurationAsMinutes = this.shiftDuration.asMinutes();

        // exemple : Break time = [(presence hours * 60) / (1 + % break time)] * % break time
        // Formula based off of https://skello.atlassian.net/browse/DEV-20591
        this.shift.attributes.pauseTime =
          Math.round(((shiftDurationAsMinutes / (1 + pauseTimePercent)) * pauseTimePercent) * 60);
      } else {
        this.shift.attributes.pauseTime =
          this.shift.relationships.poste.attributes.pauseTime;
      }
    },
    updateShiftHours() {
      // if shop opening hours don't have a 24h range, endsAt must be in between shop
      // opening hours -> therefore, can't add extra day to fix duration
      if (this.is24hShop) {
        updateShiftEndsAtFor24hShop(this.shift);
      }
      this.updatePauseTime();
    },
    removeShiftForm(event) {
      event.stopPropagation();
      this.$emit('remove-shift-form');
    },
    toggleVisibility(attribute, event) {
      event.stopPropagation();
      this.shift.attributes[attribute] = !this.shift.attributes[attribute];
    },
    updateUser(userId) {
      this.shift.attributes.userId = userId;
      this.setShiftUserIds({ userIds: [userId], type: this.workShiftKey });
      Vue.set(this.workShifts, this.workShiftKey, { ...this.shift });
    },
    updateMultipleUsers(userIds) {
      const sanitizedUserIds = userIds.map(userId => (userId === 'unassigned' ? null : userId));
      this.setShiftUserIds({ userIds: sanitizedUserIds, type: this.workShiftKey });
    },
    setPoste(posteId) {
      this.shift.relationships.poste = this.postes.find(poste => poste.id === posteId);
      this.updatePauseTime();
    },
    openCreatePosteModal() {
      this.emitOnRoot(
        'init-create-poste-modal',
        event,
        {
          shift: this.shift,
        },
      );
    },
    handleCreatePoste(posteId) {
      this.setPoste(posteId);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-input-group .sk-input .sk-input__input {
  border-bottom: 0;
}

::v-deep .sk-list-item__middle {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.shift-form__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
}

.shift-form__content__wrapper {
  flex-grow: 1;
}

.shift-form__content {
  display: flex;
  flex-direction: row;
  padding: 8px 0 !important;
  margin: 0 !important;

  &:first-of-type {
    padding-top: 0 !important;
  }

  &--no-bottom-padding {
    padding-bottom: 0 !important;
  }
}

.shift-form__content__left-border {
  width: 8px;
  margin-right: 16px;
  border-radius: 8px;
}

.shift-form__content-title {
  width: 162px;
  display: flex;
  align-items: center;
  font-weight: 700;

  &--up {
    margin-bottom: 20px;
  }
}

.shift-form__content-input {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  &__timepicker-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__separator {
    padding: 4px;
  }
}

.shift-form__content-duration-number__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shift-form__content-duration {
  width: fit-content;

  &-number {
    font-size: $fs-text-m;
  }
}

.shift-form__content-sk-input {
  width: 94px;
}

.shift-form__content-pause-percent {
  font-size: $fs-text-s;
  margin-left: 10px;
  color: $sk-grey;
}

.shift-form__content-sk-time {
  width: 82px;
  justify-content: center;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 9px;
    margin-top: 3px;
    cursor: pointer;
  }
}

.shift-form__content-duration-icon {
  cursor: pointer;
  margin-top: 3px;
}

.shift-form__content-duration__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $fs-text-s;
  color: $sk-grey;
}

.shift-form__content--disabled {
  color: #b9bfc4;
  pointer-events: none;
}

.shift-form__content-color {
  width: 19px;
  height: 19px;
  border-radius: 50%;

  &--absolute {
    position: absolute;
    right: 38px;
  }
}

.shift-form__content-text {
  // 44px is to leave space for the poste color circle
  width: calc(100% - 44px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.shift-form__content__remove-button-container {
  text-align: right;
  padding-top: 30px;
}

.shift-form__delay {
  width: 166px;
}

.shift-form__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fs-text-l;
}

.shift-form__label__meal-icon {
  margin-left: 18px;
  width: 14px;
  height: 14px;
}

.shift-form__label__shift {
  margin-right: 38px;
  font-weight: bold;
  font-size: $fs-heading-xs;
}

.shift-form__label__dot-separator {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin: 0 12px;
  background-color: $sk-grey;
}

// Need to add truncation when select has a long poste name
::v-deep .sk-select__selected-option > .text-truncate {
  width: 310px;
}

.shift-form__tasks-task__container {
  flex: 1;
}

.shift-form__poste-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
