// eslint-disable-next-line
import linkifyStr from 'linkify-string';
import { sanitize } from '@skelloapp/skello-ui';

const LINK_TRUNCATE_LENGTH = 45;
const LINK_ELLIPSIS_SEPARATOR = '[...]';

export const truncateString = (string, charLimit) => {
  const limit = charLimit || 50;
  return string.length > limit ? `${string.substring(0, limit)}...` : string;
};

export const truncateWithEllipsis = (string, charLimit = 50, ellipsisSeparator = '...') => {
  if (string.length > charLimit) {
    const halfLength = Math.floor((charLimit - ellipsisSeparator.length) / 2);
    const start = string.slice(0, halfLength);
    const end = string.slice(-halfLength);
    string = `${start}${ellipsisSeparator}${end}`;
  }
  return string;
};

const linkifyDefaultOptions = {
  target: '_blank',
  format: (value, type) => {
    if (type === 'url') {
      value = truncateWithEllipsis(value, LINK_TRUNCATE_LENGTH, LINK_ELLIPSIS_SEPARATOR);
    }
    return value;
  },
  attributes: () => ({
    style: 'font-weight: 600; color: black; text-decoration: underline;', // Add inline styles
  }),
};

export function linkify(
  text,
  options = linkifyDefaultOptions,
  className = null,
  style = null,
) {
  if (className) {
    options.className = className;
  }

  if (style) {
    options.attributes = () => ({
      style,
    });
  }

  const sanitizedText = sanitize(text, {
    allowList: { a: ['href'] },
  });

  return linkifyStr(sanitizedText, options);
}

// Elegant capitalize names
// Capitalize first letter, lower everything else
// Captialize after hyphens and spaces
// Examples: capitalize('jean-clément') > 'Jean-Clément'
// capitalize('JEAN-CLÉMENT') > 'Jean-Clément
export const capitalize = string => {
  if (!string || string.length < 1) return '';

  const substring = Array.from(string.toLowerCase().substring(1));
  for (let i = 0; i < substring.length - 1; i += 1) {
    if (substring[i] === '-' || substring[i] === ' ') {
      substring[i + 1] = substring[i + 1].toUpperCase();
    }
  }

  return string.charAt(0).toUpperCase() + substring.join('');
};

export const nlToBr = text => {
  if (typeof text !== 'string') return '';

  return text.replace(/\n/g, '<br>');
};

export const camelToSnake = string => string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const employeeFullName = (firstName, lastName) => `${capitalize(firstName) } ${capitalize(lastName)}`;

export const toSnakeCase = string => string.replace(/\s+/g, '_').toLowerCase();
