<template>
  <div class="col-md-4 offset-md-4 mx-auto organisation-setting__page-content">
    <div class="organisation-setting__header">
      <h1 class="sk-header--1">
        {{ $t('organisation_creation.organisation_billing_info.title') }}
      </h1>
    </div>
    <div class="organisation-setting__subtitle">
      <p class="sk-subtitle--large">
        {{ $t('organisation_creation.organisation_billing_info.subtitle') }}
      </p>
    </div>
    <h3 class="sk-header--3 billing-info__header">
      {{ $t('organisation_creation.organisation_billing_info.form_title') }}
    </h3>

    <StripeBox />

    <div
      v-if="!isPaymentTypeTransfer"
      class="billing-info__iban"
    >
      <SkInput
        v-model.trim="billingInfo.iban"
        :errored="errors.iban"
        :error-message="ibanErrorMessage"
        :label="$t('organisation_creation.billing_form.iban')"
        :debounce="validateIban"
        :debounce-ms="500"
      />
    </div>

    <div :class="billingEmailClasses">
      <SkInput
        v-model.trim="billingInfo.email"
        :errored="errors.email"
        :error-message="billingEmailErrorMessage"
        :label="$t('organisation_creation.billing_form.billing_email')"
        :debounce="validateEmail"
        :debounce-ms="500"
      >
        <template #icon>
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('organisation_creation.billing_form.tooltip.billing_email')"
            class="organisation-setting__helper"
          />
        </template>
      </SkInput>
    </div>

    <div class="row-separator" />
    <h3 class="sk-header--3">
      {{ $t('organisation_creation.billing_info.billing_form_title') }}
    </h3>

    <div class="billing-form__billing-address">
      <div>
        <SkInput
          v-model="billingAddress"
          :label="$t('organisation_creation.shop_form.address_2')"
          :error-message="$t('organisation_creation.error.mandatory')"
          :errored="errors.address"
          @keyup="validateMandatoryField('address')"
        />
        <div class="billing-form__city row">
          <div class="col">
            <SkInput
              v-model="billingZipcode"
              :label="$t('organisation_creation.shop_form.zip_code')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.zipcode"
              @keyup="validateMandatoryField('zipcode')"
            />
          </div>
          <div class="col">
            <SkInput
              v-model="billingCity"
              :label="$t('organisation_creation.shop_form.city')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.city"
              @keyup="validateMandatoryField('city')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <SkSelectV2
              v-model="billingCountry"
              :options="countries"
              :label="$t('organisation_creation.shop_form.country')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row billing-form__legal-section">
      <div class="col">
        <div>
          {{ $t("organisation_creation.billing_form.legal_infos_1") }}
        </div>
        <div class="billing-form__legal-info">
          {{ $t("organisation_creation.billing_form.legal_infos_2") }}
        </div>
      </div>
    </div>
    <div class="row billing-form__navigation-buttons">
      <div class="col">
        <div
          class="billing-form__button billing-form__blank_button billing-form_button"
        >
          <SkOroraButton
            variant="tertiary"
            @click="goBack"
          >
            {{ $t('organisation_creation.shop_form.back') }}
          </SkOroraButton>
        </div>
      </div>
      <div class="col">
        <div class="billing-form__navigation-buttons__submit-wrapper">
          <SkOroraButton
            :disabled="disabledSubmit"
            :loading="loading"
            class="billing-form__button billing-form_button"
            @click="submitForm"
          >
            {{ $t('organisation_creation.shop_form.finish') }}
          </SkOroraButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
} from 'vuex';
import IBAN from 'iban';

import {
  SkInput,
  SkOroraButton,
  SkSelectV2,
  CircledQuestionMarkIcon,
} from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { isValidEmail } from '@skello-utils/validators';
import { arrayToSelectOptions } from '@skello-utils/form';
import {
  countryKeys,
  languageToCountryCode,
} from '@skello-utils/country-keys.js';

import StripeBox from '../shared/components/StripeBox';

export default {
  name: 'OrganisationBillingForm',
  components: {
    StripeBox,
    SkInput,
    SkOroraButton,
    SkSelectV2,
    CircledQuestionMarkIcon,
  },
  data() {
    return {
      loading: false,
      billingEmailErrorMessage: '',
      ibanErrorMessage: '',
      errors: {
        address: false,
        city: false,
        zipcode: false,
        email: false,
        iban: false,
      },
      billingInfo: {
        iban: '',
        email: '',
        address: '',
        city: '',
        country: null,
        zipcode: '',
      },
    };
  },
  computed: {
    ...mapState('onboarding', ['currentOrganisation', 'currentUser']),
    ...mapState('onboarding', ['userCreation']),
    ...mapState('config', ['config']),

    billingAddress: {
      get() {
        return this.billingInfo.address;
      },
      set(newValue) {
        this.billingInfo.address = newValue;
      },
    },

    billingZipcode: {
      get() {
        return this.billingInfo.zipcode;
      },
      set(newValue) {
        this.billingInfo.zipcode = newValue;
      },
    },

    billingCity: {
      get() {
        return this.billingInfo.city;
      },
      set(newValue) {
        this.billingInfo.city = newValue;
      },
    },

    billingCountry: {
      get() {
        return this.billingInfo.country ? this.billingInfo.country : this.defaultUserCountryCode;
      },
      set(newValue) {
        this.billingInfo.country = newValue;
      },
    },
    billingEmailClasses() {
      return {
        'billing-info__billing-email': true,
        'billing-info__billing-email--with-iban': !this.isPaymentTypeTransfer,
        'billing-info__billing-email--without-iban': this.isPaymentTypeTransfer,
      };
    },
    isPaymentTypeTransfer() {
      if (!this.userCreation.prospect) return null;

      return this.userCreation.prospect.attributes.paymentType ===
        this.config.payment_types[1];
    },
    defaultUserCountryCode() {
      return languageToCountryCode(this.currentUser.attributes.lang);
    },
    countries() {
      const countryList = countryKeys(this.defaultUserCountryCode);
      return arrayToSelectOptions(countryList, value => this.$t(`countries.${value}`));
    },
    isAddressValid() {
      return this.billingAddress && this.billingCity &&
        this.billingCountry && this.billingZipcode;
    },
    isFormValid() {
      // transfer means no iban
      if (this.isPaymentTypeTransfer) {
        return this.isAddressValid && this.billingInfo.email && !this.errors.email;
      }

      return this.isAddressValid && this.billingInfo.iban &&
        this.billingInfo.email && !this.errors.email &&
        !this.errors.iban;
    },
    disabledSubmit() {
      return !this.isFormValid;
    },
  },
  methods: {
    ...mapMutations('onboarding', ['updateOrganisationStep']),
    goBack() {
      this.updateOrganisationStep('organisation');
      this.$router.back();
    },
    submitForm() {
      this.$skAnalytics.track('account_creation_billing_submit');

      this.loading = true;

      let params = {
        organisation_id: this.currentOrganisation.id,
        cluster_node_id: this.currentOrganisation.attributes.rootNodeId,
        billing_infos: {
          email: this.billingInfo.email,
          address: this.billingAddress,
          zipcode: this.billingZipcode,
          city: this.billingCity,
          country: this.billingCountry,
        },
      };

      if (!this.isPaymentTypeTransfer) {
        const iban = {
          bank_info: {
            iban: this.billingInfo.iban,
          },
        };

        params = { ...params, ...iban };
      }

      httpClient.post('/v3/api/billing_infos', params)
        .then(() => {
          this.$router.push({ name: 'shop_form' });
        })
        .catch(error => {
          const errorMessage = error.response?.data?.message;
          const isIBANError = error.response?.status === 422 && errorMessage.includes('IBAN');
          const toastMessage = isIBANError ?
            this.$t('organisation_creation.error.iban') : this.$t('organisation_creation.error.generic');
          this.$skToast({
            message: toastMessage,
            variant: 'error',
          });
        })
        .finally(() => { this.loading = false; });
    },
    validateIban() {
      this.ibanErrorMessage = null;

      if (!this.billingInfo.iban) {
        this.ibanErrorMessage = this.$t('organisation_creation.error.mandatory');
      } else if (!IBAN.isValid(this.billingInfo.iban)) {
        this.ibanErrorMessage = this.$t('organisation_creation.billing_form.error.iban');
      }
      this.errors.iban = !!this.ibanErrorMessage;
    },
    validateEmail() {
      this.billingEmailErrorMessage = null;

      if (!this.billingInfo.email) {
        this.billingEmailErrorMessage = this.$t('organisation_creation.error.mandatory');
      } else if (!isValidEmail(this.billingInfo.email)) {
        this.billingEmailErrorMessage = this.$t('organisation_creation.billing_form.error.email');
      }

      this.errors.email = !!this.billingEmailErrorMessage;
    },
    validateMandatoryField(attribute) {
      if (attribute === 'iban') {
        this.validateIban();
      } else if (attribute === 'email') {
        this.validateEmail();
      } else {
        this.errors[attribute] = !this.billingInfo[attribute];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-form__billing-address {
  width: 100%;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 30px;
}

.organisation-setting__page-content {
  margin-top: 40px;
  max-width: 650px;
}

.organisation-setting__header {
  margin-bottom: 9px;
}

.organisation-setting__subtitle {
  max-width: 370px;
  margin: auto;
}

.billing-info__iban {
  margin-top: 30px;
  width: 100%;
}

.billing-info__billing-email {
  margin-bottom: 20px;
  width: 50%;
}

.billing-info__billing-email--with-iban {
  margin-top: 10px;
}

.billing-info__billing-email--without-iban {
  margin-top: 30px;
}

.billing-info__header {
  padding-top: 40px;
  padding-bottom: 15px;
}

.billing-form__social-denomination {
  font-weight: $fw-semi-bold;
}

.billing-form__address,
.billing-form__social-denomination {
  width: 100%;
}

.billing-form__button {
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

.billing-form__blank_button {
  text-align: left;
  margin-left: -8px;

  ::v-deep .sk-button--primary:not([disabled]).sk-button--blank {
    outline: none;
    color: $sk-grey;

    &:hover {
      opacity: .8;
    }
  }
}

.billing-form__navigation-buttons__submit-wrapper {
  display: flex;
  justify-content: flex-end;
}

.billing-form__legal-section {
  color: $sk-grey;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: $fs-text-s;
  line-height: 22px;
}

.billing-form__navigation-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}

.billing-form__legal-info {
  margin-top: 15px;
}

.billing-form__switch {
  display: inline-block;
}

.billing-form__city {
  margin-top: 15px;
  margin-bottom: 15px;
}

.billing-form__input-error {
  padding-bottom: 10px;
}
</style>
