<template>
  <div>
    <div class="punch-clock__container">
      <div class="punch-clock-section__text__description">
        <h1 class="sk-header--1">
          {{ $t('shop_settings.tabs.punch_clock.title') }}
        </h1>
        <p class="sk-subtitle--large">
          {{ $t('shop_settings.tabs.punch_clock.subtitle') }}
        </p>
      </div>
      <div class="punch-clock-rules__head">
        <div class="punch-clock-rules__pin_code">
          <SkMedallion
            icon="ShieldWithCheckIcon"
            color="#2b66fe"
            background-color="#d9e6ff"
            size="small"
          />
          {{ $t('shop_settings.tabs.punch_clock.pin_code') }} :
          <span class="punch-clock-rules__pin_code__label">
            {{ shopPin }}
          </span>
        </div>
        <div class="punch-clock-rules__punchclock-link">
          <SkOroraButton
            variant="tertiary"
            icon="RightArrowV2Icon"
            icon-position="right"
            @click="punchClockLinkClick"
          >
            {{ $t('shop_settings.tabs.punch_clock.punchclock_link') }}
          </SkOroraButton>
        </div>
      </div>
      <SkCard class="punch-clock-rules__card-wrapper">
        <div class="punch-clock-rules__card-container">
          <h2 class="sk-header--2 punch-clock-rules__card-title">
            {{ $t('shop_settings.tabs.punch_clock.generic_parameters_card.title') }}
          </h2>

          <div
            v-if="isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, currentShop.id)"
            data-test="worked_hours_actualization"
          >
            <h3>
              {{ $t('shop_settings.tabs.punch_clock.generic_parameters_card.subtitle') }}
            </h3>
            <p>
              {{ $t('shop_settings.tabs.punch_clock.generic_parameters_card.description') }}
            </p>
            <SkTable
              :columns="rulesTableHeader"
              class="punch-clock-rules__table"
            >
              <PunchClockRuleRow
                v-for="punchClockRuleRow in genericRules"
                :key="punchClockRuleRow.id"
                :punch-clock-rule-row="punchClockRuleRow"
                :include-input="isRuleWithInput(punchClockRuleRow)"
                @change="handleOnChange"
              />
            </SkTable>
          </div>
          <div class="punch-clock-rules__clock-in-out-settings">
            <ClockInOutSettings
              :punch-on-affiliated-shop-value="!!punchClockSettings.punchOnAffiliatedShop"
              :day-clocks-in-out-value="!!punchClockSettings.dayClocksInOut"
              @change="handleOnChange"
            />
          </div>
          <h3>
            {{ $t('shop_settings.tabs.punch_clock.generic_parameters_card.delay_settings_subtitle') }}
          </h3>
          <SkTable
            v-if="isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, currentShop.id)"
            :columns="rulesTableHeader"
            class="punch-clock-rules__table"
            data-test="notify-schedulers-sms"
            :style="{ marginBottom: '0px' }"
          >
            <PunchClockRuleRow
              :key="latenessNotificationRule.attributes.kind"
              :punch-clock-rule-row="latenessNotificationRule"
              :include-input="isRuleWithInput(latenessNotificationRule)"
              @change="handleOnChange"
            />
          </SkTable>
          <div
            v-if="latenessNotificationRule.attributes.active &&
              isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, currentShop.id)"
            class="punch-clock-rules__generic__lateness-notification-rule__select-container"
          >
            <span>{{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.lateness_sms_notification.select.description') }}</span>
            <SkSelect
              v-model="latenessSmsRecipientsIds"
              :options="plannerOptions"
              :disabled-options="disabledPlannerOptions"
              :label="$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.lateness_sms_notification.select.label')"
              width="320px"
              multi
              select-all
            >
              <template #select-all-label>
                {{ $t('search_bar.all') }}
              </template>
              <template #option="{ option }">
                <span v-tooltip.top="shopNoPhoneNumberTooltip(option)">
                  {{ option.text }}
                </span>
              </template>
              <template #empty-option>
                {{ $t('search_bar.no_result') }}
              </template>
              <template #selected-option="{ value }">
                {{ $tc('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.lateness_sms_notification.select.planners_count', value.length) }}
              </template>
            </SkSelect>
          </div>
        </div>
      </SkCard>
      <SkCard class="punch-clock-rules__card-wrapper">
        <div class="punch-clock-rules__card-container__tablet">
          <h2 class="sk-header--2 punch-clock-rules__card-title">
            {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.title') }}
          </h2>
          <h3>
            {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.subtitle') }}
          </h3>
          <SkTable
            class="punch-clock-rules__table"
            :columns="rulesTableHeader"
            :style="{ borderTop: '1px solid #dddddd' }"
          >
            <PunchClockRuleRow
              v-for="punchClockRuleRow in tabletRules"
              :key="punchClockRuleRow.attributes.kind"
              :punch-clock-rule-row="punchClockRuleRow"
              :include-input="isRuleWithInput(punchClockRuleRow)"
              @change="handleOnChange"
            />
          </SkTable>
          <!-- eslint-disable max-len -->
          <WarningCard
            v-if="currentShop.attributes.isFrenchShop"
            :title="$t('shop_settings.tabs.punch_clock.tablet_parameters_card.privacy_warning.title')"
            :content="$t('shop_settings.tabs.punch_clock.tablet_parameters_card.privacy_warning.content')"
          />
        </div>
      </SkCard>
      <SkCard
        v-if="canEnableMobilePunchClock"
        class="punch-clock-rules__card-wrapper"
      >
        <div class="punch-clock-rules__card-container punch-clock__rules-mobile-card">
          <h2 class="sk-header--2 punch-clock-rules__card-title">
            {{ $t('shop_settings.tabs.punch_clock.mobile_parameters_card.title') }}
          </h2>
          <div class="punch-clock-rules__enable-mobile-container">
            <SkSwitch
              v-model="enabledMobile"
            />
            <div class="punch-clock-rules__enable-mobile-desciption">
              {{ $t('shop_settings.tabs.punch_clock.mobile_parameters_card.enable_mobile_time_clock') }}
            </div>
          </div>
          <div
            v-if="enabledMobile"
            class="punch-clock-rules__mobile-wrapper"
          >
            <div v-if="isDevFlagEnabledPauseMobilePunchClock">
              <h3>
                {{ $t('shop_settings.tabs.punch_clock.tablet_parameters_card.subtitle') }}
              </h3>
              <SkTable
                :columns="rulesTableHeader"
                class="punch-clock-rules__table"
              >
                <PunchClockRuleRow
                  v-for="punchClockRuleRow in mobileRules"
                  :key="punchClockRuleRow.attributes.kind"
                  :punch-clock-rule-row="punchClockRuleRow"
                  :include-input="isRuleWithInput(punchClockRuleRow)"
                  @change="handleOnChange"
                />
              </SkTable>
            </div>
            <div class="punch-clock-rules__mobile-employees">
              <h3>
                {{ $t('shop_settings.tabs.punch_clock.mobile_parameters_card.subtitle') }}
              </h3>
              <div v-if="loading" />
              <div
                v-else
              >
                <SkSelectV2
                  v-model="mobileClocksInOutActivatedUserIds"
                  :options="userOptions"
                  :label="$t('shop_settings.tabs.punch_clock.mobile_parameters_card.select.employees')"
                  width="320px"
                  multi
                  select-all
                >
                  <template #select-all-label>
                    {{ $t('shop_settings.tabs.punch_clock.mobile_parameters_card.select.select_all') }}
                  </template>
                  <template #group-count="{ count }">
                    {{ $tc('shop_settings.tabs.punch_clock.mobile_parameters_card.select.employee_count', count) }}
                  </template>
                  <template #selected-option="{ value }">
                    {{ $tc('shop_settings.tabs.punch_clock.mobile_parameters_card.select.employee_count', value.length) }}
                  </template>
                </SkSelectV2>
              </div>
            </div>
            <WarningCard
              :title="$t('shop_settings.tabs.punch_clock.mobile_parameters_card.geolocation_data_warning.title')"
              :content="$t('shop_settings.tabs.punch_clock.mobile_parameters_card.geolocation_data_warning.content')"
            />
          </div>
        </div>
      </SkCard>
    </div>
    <StickyBar
      :visible="showStickyBar"
      :submit-button-label="$t('shop_settings.sticky_bar.submit')"
      :submit-spinner="loadingUpdate"
      :tracking-options="trackingOptions"
      container-scroll-id="shop-settings__container"
      class="shop-settings-rules__container__sticky-bar"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapState,
} from 'vuex';
import WarningCard from '@app-js/shared/components/WarningCard';
import StickyBar from '@app-js/shared/components/Stickybar';
import { capitalize } from '@skello-utils/formatting/strings';
import { httpClient } from '@skello-utils/clients';
import { FEATURES } from '@app-js/shared/constants/features';
import PunchClockRuleRow from './PunchClockRuleRow';
import ClockInOutSettings from './ClockInOutSettings';

export default {
  name: 'PunchClock',
  components: { PunchClockRuleRow, WarningCard, ClockInOutSettings, StickyBar },
  data() {
    return {
      punchClockSettings: {},
      originalPunchClockSettings: null,
      punchClockSettingsUpdatedFrom: {},
      planners: [],
      loadingUpdate: false,
      loading: false,
      FEATURES,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('punchClock', ['punchClockUsers']),
    mobileClocksInOutActivatedUserIds: {
      get() {
        // Some users may not be in the punchClockUsers list anymore, so we filter them out
        return this.punchClockUsers
          .map(user => user.userId)
          .filter(
            userId => (this.punchClockSettings.mobileClocksInOutActivatedUsers ?? [])
              .includes(userId),
          );
      },
      set(newValue) {
        const sortedUserIds = this.sortUserIds(newValue);
        this.handleOnChange({ kind: 'mobileClocksInOutActivatedUsers', newValue: sortedUserIds });
      },
    },
    latenessSmsRecipientsIds: {
      get() {
        return this.planners
          .map(planner => planner.id)
          .filter(plannerId => (
            this.punchClockSettings.latenessSmsRecipientsIds ?? []).includes(plannerId),
          );
      },
      set(newValue) {
        // when selecting all, SkSelect returns an array of object [{ id: 1, text: 'text', ...}]
        // instead of an array of id as usual
        const flattenValue = newValue.map(element => element.id ?? element);
        const sortedUserIds = this.sortUserIds(flattenValue);
        this.handleOnChange({ kind: 'latenessSmsRecipientsIds', newValue: sortedUserIds });
      },
    },
    enabledMobile: {
      get() {
        return this.punchClockSettings.enabledMobile;
      },
      set(newValue) {
        this.handleOnChange({ kind: 'enabledMobile', newValue });
      },
    },
    rulesTableHeader() {
      return [
        { title: this.$t('shop_settings.tabs.punch_clock.table.headers.activate') },
        { title: this.$t('shop_settings.tabs.punch_clock.table.headers.rule_type') },
      ];
    },
    trackingOptions() {
      return {
        update: 'punch_clock_settings_updated',
      };
    },
    genericRules() {
      return [
        {
          id: 1,
          attributes: {
            active: this.punchClockSettings.lateArrivalTakesPlannedDate,
            description: this.$t('shop_settings.tabs.punch_clock.generic_parameters_card.settings.late_arrival_takes_planned_date'),
            kind: 'lateArrivalTakesPlannedDate',
          },
        },
        {
          id: 2,
          attributes: {
            active: this.punchClockSettings.earlyArrivalTakesPlannedDate,
            description: this.$t('shop_settings.tabs.punch_clock.generic_parameters_card.settings.early_arrival_takes_planned_date'),
            kind: 'earlyArrivalTakesPlannedDate',
          },
        },
        {
          id: 3,
          attributes: {
            active: this.punchClockSettings.lateDepartureCountedAsLate,
            description: this.$t('shop_settings.tabs.punch_clock.generic_parameters_card.settings.late_departure_counted_as_late'),
            kind: 'lateDepartureCountedAsLate',
          },
        },
        {
          id: 4,
          attributes: {
            active: this.punchClockSettings.earlyDepartureCountedAsEarly,
            description: this.$t('shop_settings.tabs.punch_clock.generic_parameters_card.settings.early_departure_counted_as_early'),
            kind: 'earlyDepartureCountedAsEarly',
          },
        },
      ];
    },
    latenessNotificationRule() {
      return {
        id: 1,
        attributes: {
          active: this.punchClockSettings.latenessNotificationEnabled,
          description: {
            description1: this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.lateness_sms_notification.description1'),
            description2: this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.lateness_sms_notification.description2'),
          },
          kind: 'latenessNotificationEnabled',
          context: 'tablet',
          input: {
            value: this.punchClockSettings.latenessNotification,
            kind: 'latenessNotification',
          },
        },
      };
    },
    tabletRules() {
      return [
        {
          id: 2,
          attributes: {
            active: this.punchClockSettings.allowPunchPause,
            description: this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.allow_pauses'),
            kind: 'allowPunchPause',
          },
        },
        {
          id: 3,
          attributes: {
            active: this.punchClockSettings.signatureValidation,
            description: this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.sign_when_badging'),
            kind: 'signatureValidation',
          },
        },
        {
          id: 4,
          attributes: {
            active: this.punchClockSettings.photoValidation,
            description: this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.photo_when_badging'),
            kind: 'photoValidation',
          },
        },
      ];
    },
    mobileRules() {
      return [
        {
          id: 1,
          attributes: {
            active: this.punchClockSettings.allowPunchPauseMobile,
            description: this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.allow_pauses'),
            kind: 'allowPunchPauseMobile',
          },
        },
      ];
    },
    optionHeaders() {
      const optionHeaders = [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
      ];
      return optionHeaders;
    },
    userOptions() {
      const enabledUsers = this.punchClockUsers
        .filter(punchClockUser => (
          this.mobileClocksInOutActivatedUserIds.includes(punchClockUser.userId)
        ));

      const disabledUsers = this.punchClockUsers
        .filter(punchClockUser => (
          !this.mobileClocksInOutActivatedUserIds.includes(punchClockUser.userId)),
        );

      return [
        ...this.sortAndFormatPunchUsers(enabledUsers),
        ...this.sortAndFormatPunchUsers(disabledUsers),
      ];
    },
    plannerOptions() {
      return [...this.planners]
        .sort((a, b) => (a.lastName <= b.lastName ? -1 : 1))
        .map(enabledUser => ({
          id: enabledUser.id,
          text: `${capitalize(enabledUser.attributes.firstName)} ${capitalize(enabledUser.attributes.lastName)}`,
        }));
    },
    disabledPlannerOptions() {
      return [...this.planners]
        .filter(planner => !planner.attributes.phone)
        .sort((a, b) => (a.lastName <= b.lastName ? -1 : 1))
        .map(enabledUser => enabledUser.id);
    },
    showStickyBar() {
      if (!this.originalPunchClockSettings) return false;
      return this.originalPunchClockSettings !== JSON.stringify(this.punchClockSettings);
    },
    isDevFlagEnabledPauseMobilePunchClock() {
      return this.isDevFlagEnabled('FEATUREDEV_PUNCH_PAUSE_MOBILE');
    },
    canEnableMobilePunchClock() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_CAN_ENABLE_MOBILE_PUNCH_CLOCK,
        this.currentShop.id,
      );
    },
    shopPin() {
      return this.punchClockSettings.shopPin ?? '-';
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.fetchPunchClockUsers(this.currentShop.id),
      this.fetchPunchClockSettings(this.currentShop.id).then(response => {
        this.punchClockSettings = this.formatResponseData(response);
        this.originalPunchClockSettings = JSON.stringify(this.formatResponseData(response));
      }),
      this.fetchPlanners(),
    ]).finally(this.loading = false);
  },
  methods: {
    ...mapActions('punchClock', ['fetchPunchClockSettings', 'updatePunchClockSettings', 'fetchPunchClockUsers']),
    fetchPlanners() {
      httpClient.get('/v3/api/users/planners', { params: { shop_id: this.currentShop.id } })
        .then(response => {
          this.planners = response.data.data;
        });
    },
    sortAndFormatPunchUsers(users) {
      return users.sort((a, b) => (a.lastName <= b.lastName ? -1 : 1))
        .map(enabledUser => ({
          id: enabledUser.userId,
          text: `${capitalize(enabledUser.firstName)} ${capitalize(enabledUser.lastName)}`,
        }));
    },
    isRuleWithInput(rule) {
      return !!rule.attributes.input;
    },
    punchClockLinkClick() {
      this.$router.push({
        name: 'badgings',
        query: {
          date: this.currentDate,
        },
        replace: true,
      });
    },
    handleOnChange({ kind, newValue }) {
      if (['photoValidation', 'signatureValidation'].includes(kind)) {
        this.punchClockSettings.photoValidation = false;
        this.punchClockSettings.signatureValidation = false;
      }
      this.punchClockSettings = { ...this.punchClockSettings, [kind]: newValue };
      this.punchClockSettingsUpdatedFrom[`${kind}UpdatedFrom`] = 'saas';

      if (this.originalPunchClockSettings === JSON.stringify(this.punchClockSettings)) {
        this.punchClockSettingsUpdatedFrom[`${kind}UpdatedFrom`] = this.punchClockSettings[`${kind}UpdatedFrom`];
      }
    },
    handleSubmit() {
      this.loadingUpdate = true;
      this.updatePunchClockSettings({
        shopId: this.currentShop.id,
        params: {
          ...this.punchClockSettings,
          ...this.punchClockSettingsUpdatedFrom,
        },
      })
        .then(response => {
          this.punchClockSettings = this.formatResponseData(response);
          this.originalPunchClockSettings = JSON.stringify(this.formatResponseData(response));
          this.punchClockSettingsUpdatedFrom = {};

          this.$skToast({
            message: this.$t('shop_settings.tabs.punch_clock.success_message'),
            variant: 'success',
          });
        })
        .catch(() => this.$skToast({
          message: this.$t('shop_settings.update_shop.error_message'),
          variant: 'error',
        }))
        .finally(() => {
          this.loadingUpdate = false;
        });
    },
    formatResponseData(data) {
      return {
        ...data,
        mobileClocksInOutActivatedUserIds: (
          data.mobileClocksInOutActivatedUserIds ?
            this.sortUserIds(data.mobileClocksInOutActivatedUserIds) :
            []
        ),
      };
    },
    sortUserIds(data) {
      return [...data].sort((a, b) => (Number(a) < Number(b) ? -1 : 1));
    },
    shopNoPhoneNumberTooltip(option) {
      if (this.disabledPlannerOptions.includes(option.id)) {
        return this.$t('shop_settings.tabs.punch_clock.tablet_parameters_card.settings.lateness_sms_notification.select.no_phone_tooltip');
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.shop-settings-rules__container__sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.punch-clock__container {
  padding-bottom: 45px;

  .sk-header--2 {
    margin-bottom: 32px;
  }
}

.punch-clock-rules__card-wrapper {
  margin-bottom: 40px;
  padding: 24px;
}

.punch-clock-rules__table {
  margin-bottom: 32px;
}

.punch-clock-rules__head {
  display: flex;
}

.punch-clock-rules__pin_code {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 10px 0;
  gap: 6px;

  &__label {
    font-weight: $fw-semi-bold;
  }
}

.punch-clock-rules__punchclock-link {
  margin-left: auto;
  justify-content: flex-end;
  font-weight: 700;
  display: flex;
  padding: 10px 0;
}

.punch-clock-rules__enable-mobile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.punch-clock-rules__enable-mobile-desciption {
  margin-right: 32px;
  color: $sk-grey;
}

.punch-clock-rules__clock-in-out-settings {
  padding-bottom: 32px;
}

.punch-clock-rules__generic__lateness-notification-rule__select-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.punch-clock-rules__mobile-employees {
  margin-bottom: 32px;
}

.punch-clock__rules-mobile-card {
  .sk-header--2 {
    margin-bottom: 8px;
  }
}
</style>
