<template>
  <div class="employees-annualization__counter-section__wrapper">
    <div class="employees-annualization__counter-section__balance">
      <div
        v-if="annualizationConfig && canEditShopRulesAndAbsences"
        class="deactivate-button-container"
      >
        <SkOroraButton
          id="deactivate-annualization-button"
          variant="secondary"
          :variant-color="$skColors.skError"
          @click="deactivateAnnualizationModal"
        >
          {{ $t('employees.tabs.contracts.full_contract.data.salary.annualization.deactivate') }}
        </SkOroraButton>
      </div>
      <SkColumnInfoCard
        :columns="balances"
        variant="blue"
        class="employee-annualization-counter__counter-section__card"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { employeeFullName } from '@app-js/shared/utils/formatting/strings';

export default {
  name: 'CounterSection',
  props: {
    impactedTheoreticalBalance: {
      type: Number,
      default: undefined,
    },
    realizedBalance: {
      type: Number,
      default: undefined,
    },
    remainingBalance: {
      type: Number,
      default: undefined,
    },
    theoreticalImpactingAbsences: {
      type: Object,
      default: () => {},
    },
    annualizationConfig: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('amendments', [
      'activeTemporaryAmendment',
      'hasActivePermanentAmendment',
    ]),
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    balances() {
      const sickLeaveDuration = this.theoreticalImpactingAbsences?.sick_leave;
      const theoreticalBalanceTooltip = sickLeaveDuration !== undefined ? this.$t(
        'employees.tabs.counters.annualization_counter.counter_section.balance.theoretical_tooltip',
        { sickLeave: Math.round(sickLeaveDuration * 100) / 100 },
      ) : null;

      return [
        {
          content: this.impactedTheoreticalBalance !== undefined ? `${this.impactedTheoreticalBalance}h` : '-',
          title: this.$t('employees.tabs.counters.annualization_counter.counter_section.balance.theoretical_balance'),
          tooltip: theoreticalBalanceTooltip,
        },
        {
          content: this.realizedBalance !== undefined ? `${this.realizedBalance}h` : '-',
          title: this.$t('employees.tabs.counters.annualization_counter.counter_section.balance.realized_balance'),
          tooltip: this.$t('employees.tabs.counters.annualization_counter.counter_section.balance.realized_tooltip'),
        },
        {
          content: this.remainingBalance !== undefined ? `${this.remainingBalance}h` : '-',
          title: this.$t('employees.tabs.counters.annualization_counter.counter_section.balance.remaining_balance'),
        },
      ];
    },
  },
  methods: {
    async deactivateAnnualizationModal() {
      const { firstName, lastName } = this.selectedEmployee.attributes;
      const fullName = employeeFullName(firstName, lastName);
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        title: this.$t('employees.tabs.counters.annualization_counter.confirm_modal.actions.confirm', { employee: fullName }),
        description: this.$t('employees.tabs.counters.annualization_counter.confirm_modal.description'),
        onConfirm: async () => {
          await this.deactivateAnnualization();
        },
        submitColor: this.$skColors.skError,
        submitLabel: this.$t('actions.deactivate'),
      });
    },

    async deactivateAnnualization() {
      await svcEmployeesClient.deleteAnnualizationConfig(this.selectedEmployee.attributes.shopId,
        this.selectedEmployee.id);
      this.$emit('reload-counter-section');
    },
  },
};
</script>

<style lang="scss" scoped>
.employees-annualization__counter-section__wrapper {
  display: flex;
  justify-content: space-between;
}

.employees-annualization__counter-section__title {
  font-weight: $fw-semi-bold;
}

#deactivate-annualization-button {
  top: 25px;
  right: 20px;
  height: 36px;
  border: 1px solid #ddd;
}

.deactivate-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

</style>
