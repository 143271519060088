import orderBy from 'lodash/orderBy';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';
import { FEATURES } from '@app-js/shared/constants/features';

const initialState = {
  originalShopAbsences: [],
  shopAbsences: [],
  originalModulationShopAbsences: [],
  modulationShopAbsences: [],
  error: null,
  loadingAbsences: false,
  updateAbsences: false,
  updatingAbsenceCounter: false,
  dirty: false,
};

function sortByName(array) {
  return array.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
}

function isDirty(state) {
  function absenceIds(array) {
    return array.map(a => a.id).sort();
  }

  const dirtyOffCounterIds = absenceIds(state.modulationShopAbsences.offCounterAbsences);
  const dirtyInCounterIds = absenceIds(state.modulationShopAbsences.inCounterAbsences);
  const originalOffCounterIds = absenceIds(state.originalModulationShopAbsences.offCounterAbsences);
  const originalInCounterIds = absenceIds(state.originalModulationShopAbsences.inCounterAbsences);

  // Sticky bar visible only if new absences is drag between lists, not in sort.
  state.dirty =
    (!isEqual(originalOffCounterIds, dirtyOffCounterIds)) ||
    (!isEqual(originalInCounterIds, dirtyInCounterIds));
}

const mutations = {
  performingRequest(state) {
    state.loadingAbsences = true;
  },

  performingAbsenceUpdate(state) {
    state.updateAbsences = true;
  },

  performingAbsenceCounterUpdate(state) {
    state.updatingAbsenceCounter = true;
  },

  updateAbsenceCounterComplete(state) {
    state.updatingAbsenceCounter = false;
  },

  squashOriginalModulationShopAbsences(state) {
    state.originalModulationShopAbsences = cloneDeep(state.modulationShopAbsences);
    isDirty(state);
  },

  fetchModulationAbsencesSuccess(state, payload) {
    state.originalShopAbsences = sortByName(payload.data);

    const offCounterAbsences = state.originalShopAbsences.filter(absence => (
      absence.attributes.absenceType === 'off_counter'
    ));

    // Keep draggable absences first (they appear on top)
    const inCounterAbsences = orderBy(
      state.originalShopAbsences.filter(absence => (
        absence.attributes.absenceType === 'in_counter'
      )),
      ['attributes.draggable'], ['desc'],
    );

    const neutralAbsences = state.originalShopAbsences.filter(absence => (
      absence.attributes.absenceType === 'neutral'
    ));

    state.shopAbsences = clone(state.originalShopAbsences);

    // Set the main object for modulationShopAbsences:
    state.originalModulationShopAbsences = {
      offCounterAbsences,
      inCounterAbsences,
      neutralAbsences,
    };
    Vue.set(state, 'modulationShopAbsences', cloneDeep(state.originalModulationShopAbsences));
  },

  fetchRegularAbsencesSuccess(state, payload) {
    state.originalShopAbsences = sortByName(payload.data);
    state.shopAbsences = clone(state.originalShopAbsences);
  },

  updateAbsencesSuccess(state, payload) {
    const updatedIndex = state.shopAbsences.findIndex(
      absence => absence.id === payload.data.id,
    );
    state.shopAbsences.splice(updatedIndex, 1, payload.data);
  },

  updateAbsencesCounterSuccess(state, payload) {
    state.shopAbsences = payload.data;
  },

  requestComplete(state) {
    state.loadingAbsences = false;
  },

  absencesUpdateComplete(state) {
    state.updateAbsences = false;
  },

  catchAbsencesError(state, payload) {
    state.error = payload;
  },

  addDraggableAbsence(state, payload) {
    const absence = payload.data.added.element;
    const inCounter = payload.targetList === 'absencesInCounter';
    /*
      If drag items to 'in counter' list,
      we unshift to keep the dragged item on the top of the list
      If drag item to 'off counter list' we push to keep element at the bottom
    */
    if (inCounter) {
      state.modulationShopAbsences.inCounterAbsences.unshift(absence);
    } else {
      state.modulationShopAbsences.offCounterAbsences.push(absence);
    }
  },

  removeDraggableAbsence(state, payload) {
    const absenceIndex = payload.data.removed.oldIndex;
    const inCounter = payload.targetList === 'absencesInCounter';

    if (inCounter) {
      state.modulationShopAbsences.inCounterAbsences.splice(absenceIndex, 1);
    } else {
      state.modulationShopAbsences.offCounterAbsences.splice(absenceIndex, 1);
    }
    isDirty(state);
  },
};

const actions = {
  fetchAbsences({ commit, rootGetters }, shop) {
    commit('performingRequest');

    const fetchAbsencesFromApi = () => httpClient.get('/v3/api/absences', { params: { shop_id: shop.id } });

    const isModulationWithFeatureEnabled = () => shop.attributes.modulation &&
      rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_HOURS_COUNTER, shop.id);

    return fetchAbsencesFromApi()
      .then(response => {
        const mutation = isModulationWithFeatureEnabled() ?
          'fetchModulationAbsencesSuccess' :
          'fetchRegularAbsencesSuccess';
        commit(mutation, response.data);
      })
      .catch(error => {
        commit('catchAbsencesError', error);
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  updateAbsence({ commit }, { shopId, absence }) {
    const params = {
      shop_id: shopId,
      poste: {
        active: absence.attributes.active,
        triggers_owed_meal: absence.attributes.triggersOwedMeal,
      },
    };

    commit('performingAbsenceUpdate');

    return httpClient
      .patch(`/v3/api/absences/${absence.id}`, params)
      .then(response => {
        commit('updateAbsencesSuccess', response.data);
      })
      .catch(error => {
        commit('catchAbsencesError', error);
      })
      .finally(() => {
        commit('absencesUpdateComplete');
      });
  },

  updateAbsenceCounterModulation({ commit }, payload) {
    const params = {
      shop_id: payload.shopId,
      absences_off_counter_ids: payload.absencesOffCounterIds,
      absences_in_counter_ids: payload.absencesInCounterIds,
      start_date: payload.recalculationDate,
    };

    commit('performingAbsenceCounterUpdate');

    return httpClient
      .patch('/v3/api/absences/update_counter_status', params)
      .then(response => {
        commit('updateAbsencesCounterSuccess', response.data);
      })
      .catch(error => {
        commit('catchAbsencesError', error);
        throw error;
      })
      .finally(() => {
        commit('updateAbsenceCounterComplete');
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
