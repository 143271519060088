<template>
  <div
    v-tooltip="dayCellTooltip"
    :class="planningRowWrapperClasses"
    @click="handleClickOnDayCell($event)"
    @contextmenu.prevent="showPopoverMenu"
    @mouseenter="isHoveringCell = true"
    @mouseleave="isHoveringCell = false"
  >
    <div
      v-if="isAnyShiftDragging && !isSelfShiftDragging && canCreateShifts"
      class="planning-row__day-cell__drop-area"
    >
      <div
        v-show="showDropzone"
        :class="moveDisplayAreaClasses"
      >
        {{ $t('plannings.table.actions.move') }}
      </div>
      <div
        v-if="globalConfig.shiftDragging"
        v-show="showDropzone"
        :class="copyDisplayAreaClasses"
      >
        {{ $t('plannings.table.actions.copy') }}
      </div>
      <div
        v-if="globalConfig.shiftDragging"
        class="planning-row__day-cell__drop-area__update-shift"
        @dragenter="hoverMove = true"
        @dragleave="hoverMove = false"
        @dragover.prevent
        @drop="handleShiftDrop('updateShift', $event)"
      />
      <div
        :class="createShiftDropAreaClasses"
        @dragenter="hoverCopy = true"
        @dragleave="hoverCopy = false"
        @dragover.prevent
        @drop="handleShiftDrop('createShift', $event)"
      />
    </div>
    <Availabilities
      v-if="isAvailabilitiesComponentDisplayed"
      :availabilities="availabilities"
      :day-cell-has-shifts="dayCellHasShifts"
      :is-last-visible-day="day.isLastVisibleDay"
      @mouseenter.native="handleAvailabilitiesMouseEnter"
    />
    <div class="planning-row__day-cell__row">
      <div class="planning-row__day-cell__left-side">
        <div
          v-for="shift in cellShifts"
          :key="shift.id"
          :class="shiftWrapperClasses(shift.id)"
        >
          <Shift
            :ref="`shift_${shift.id}`"
            :shift="shift"
            :global-config="globalConfig"
            :class="shiftClasses(shift.id)"
            :is-draggable="isDraggable(shift)"
            :draggable="isDraggable(shift)"
            :is-pending-leave-request="shift.attributes.isPendingLeaveRequest"
            @mouseenter.native="showMenu(shift)"
            @mouseleave.native="handleShiftMouseLeave"
            @click.native="($event) => handleClickOnShift(shift, $event)"
            @dragstart.native="handleDragStart(shift, $event)"
            @dragend.native="handleDragEnd"
          />
        </div>

        <!--
        we can't use native css :hover because on Safari it creates issue
        with drag and drop feature (hover state get stuck after dropping a shift)
        -->
        <div
          v-if="canDisplayAddShift"
          :class="addShiftWrapperClasses"
          @mouseenter="isHoveringAddShift = true"
          @mouseleave="isHoveringAddShift = false"
          @click="openModal"
        >
          <div :class="addShiftClasses">
            <div class="planning-row__day-cell__add-shift-icon">
              <PlusSignV2Icon
                fill="#2B66FE"
                height="20"
                width="20"
              />
            </div>
          </div>
        </div>

        <LoadingBrainShift
          :row-item="rowItem"
          :day="day"
          :day-cell-shifts="dayCellShifts"
          :is-unassigned-shifts-row="isUnassignedShiftsRow"
          :is-cell-disabled="!canCreateShifts"
          :loading-brain-shift-config="loadingBrainShiftConfig"
        />
      </div>
      <div
        v-if="globalConfig.displayBulkEditShifts"
        class="planning-row__day-cell__right-side"
        @mouseenter="handleRightSideMouseEnter"
        @mouseleave="isHoveringAddShift = false"
      />
    </div>
    <DailyTotal
      v-if="globalConfig.isPostesView"
      :shifts="dayCellShifts"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapActions,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { isEmpty } from '@skello-utils/array';

import {
  computeShiftTimes,
  getValidShiftTimes,
  isContractStartedForUserAtDate,
  sanitizeShift,
} from '@app-js/plannings/shared/utils/planning_helpers';
import Shift from '@app-js/plannings/shared/components/Shift';

import { getDragDataFormat } from '@skello-utils/drag_and_drop';
import {
  isMacOS,
  isSafariBrowser,
} from '@app-js/shared/utils/browser';
import Availabilities from './Availabilities';
import DailyTotal from './DailyTotal.vue';
import LoadingBrainShift from './LoadingBrainShift';

export default {
  name: 'DayCell',
  components: {
    Availabilities,
    DailyTotal,
    LoadingBrainShift,
    Shift,
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    dayCellShifts: {
      type: Array,
      required: true,
    },
    maxWeeklyShiftElements: {
      type: Number,
      required: true,
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    globalConfig: {
      type: Object,
      required: true,
    },
    pendingLeaveRequests: {
      type: Array,
      default: () => [],
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    multipleSelectionEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverMove: false,
      hoverCopy: false,
      defaultPopoverOptions: [
        {
          callback: this.handleClickOnAddShift,
          iconLeft: 'PlusSignV2Icon',
          id: 'add_shift',
          label: this.$t('plannings.table.actions.add_shift'),
        },
        {
          callback: this.handleClickOnUnassignCellsShifts,
          iconLeft: 'UserWithCrossmarkIcon',
          id: 'unassigned',
          label: this.$t('plannings.table.actions.unassigned'),
        },
        {
          callback: this.handleClickOnCopyCells,
          iconLeft: 'FilesV2Icon',
          id: 'copy',
          label: this.$t('plannings.table.actions.copy'),
          keyShortcuts: isMacOS() ? ['Cmd + C'] : ['Ctrl + C'],
        },
        {
          callback: this.handleClickOnPasteCopiedCells,
          iconLeft: 'ClipboardV2Icon',
          id: 'paste',
          label: this.$t('plannings.table.actions.paste'),
          keyShortcuts: isMacOS() ? ['Cmd + V'] : ['Ctrl + V'],
        },
        {
          iconLeft: 'TrashCanV2Icon',
          id: 'delete',
          label: this.$t('plannings.table.actions.delete'),
          variant: 'error',
          callback: this.handleClickOnDeleteCellsShifts,
          keyShortcuts: isMacOS() ? ['⌫'] : ['⌫'],
        },
        {
          id: 'lock_day',
          label: this.$t('plannings.table.actions.lock_day'),
          disabled: true,
        },
      ],
      noActionPopoverOption: {
        id: 'no_actions',
        label: this.$t('plannings.table.actions.no_actions'),
        disabled: false,
      },
      availabilitiesDisplayAddShift: false,
      isHoveringCell: false,
      isHoveringShift: false,
      isHoveringAddShift: false,
      shiftDraggingId: null,
    };
  },
  computed: {
    planningRowWrapperClasses() {
      const isCurrentDay = skDate(this.day.date).isToday();

      return {
        'planning-row__day-cell__wrapper': true,
        'planning-row__day-cell__wrapper--disabled': !this.canEditShifts,
        'planning-row__day-cell__wrapper--disabled-cursor': !this.canCreateShifts,
        'planning-row__day-cell__wrapper--current-day': !this.day.isLocked && isCurrentDay,
        'planning-row__day-cell__wrapper--drag-over': true,
        'hover-day-cell': this.displaySelectHover,
        'day-cell-selected': this.globalConfig.displayBulkEditShifts && this.isSelected,
      };
    },
    isAddShiftDisplayed() {
      return this.isHoveringAddShift || this.availabilitiesDisplayAddShift;
    },
    dayCellHasShifts() {
      return this.cellShifts.length > 0;
    },
    addShiftWrapperClasses() {
      return {
        'planning-row__day-cell__add-shift-wrapper': true,
        hover: this.isAddShiftDisplayed && !this.isSelected,
      };
    },
    canCreateShifts() {
      return !this.day.isLocked &&
        this.globalConfig.currentLicense.attributes.canCreateShifts &&
        (this.isUnassignedShiftsRow ||
          (this.hasUserStartedContract && !this.isArchivedUser)
        );
    },
    canEditShifts() {
      return !this.day.isLocked &&
        this.globalConfig.currentLicense.attributes.canEditShifts &&
        (this.isUnassignedShiftsRow ||
          (this.hasUserStartedContract && !this.isArchivedUser));
    },
    hasUserStartedContract() {
      return isContractStartedForUserAtDate(this.rowItem, this.day.date);
    },
    isArchivedUser() {
      if (!this.globalConfig.isEmployeesView) return false;

      return skDate(this.day.date).isAfter(this.rowItem.attributes.archivedAt);
    },
    dayCellTooltip() {
      if (this.isUnassignedShiftsRow) {
        return '';
      }
      if (this.isArchivedUser) {
        return this.$t('plannings.table.cells.disable_cell_tooltip.archived_employee');
      }
      if (!this.hasUserStartedContract) {
        return this.$t('plannings.table.cells.disable_cell_tooltip.employee_has_not_started');
      }
      return '';
    },
    dateForShiftsModal() {
      return skDate(this.day.date).utc(true);
    },
    defaultShiftTimes() {
      if (this.isUnassignedShiftsRow || this.globalConfig.isPostesView) {
        return {};
      }

      const defaultShiftTimes = computeShiftTimes({
        user: this.rowItem,
        shop: this.globalConfig.currentShop,
        shifts: this.dayCellShifts,
        date: this.day.date,
      });

      return defaultShiftTimes;
    },
    displaySelectHover() {
      const noHoveringShiftOrAddShift = !this.isHoveringShift && !this.isHoveringAddShift;

      return this.globalConfig.displayBulkEditShifts && this.isHoveringCell &&
      (this.multipleSelectionEnabled || noHoveringShiftOrAddShift);
    },
    moveDisplayAreaClasses() {
      return {
        'planning-row__day-cell__drop-area__cell': true,
        'planning-row__day-cell__drop-area__cell--move': true,
        'planning-row__day-cell__drop-area__cell--hover': this.hoverMove,
        'planning-row__day-cell__drop-area__cell--popular': this.globalConfig.popularShiftDragging,
      };
    },
    copyDisplayAreaClasses() {
      return {
        'planning-row__day-cell__drop-area__cell': true,
        'planning-row__day-cell__drop-area__cell--copy': true,
        'planning-row__day-cell__drop-area__cell--hover': this.hoverCopy,
      };
    },
    createShiftDropAreaClasses() {
      return {
        'planning-row__day-cell__drop-area__create-shift': true,
        'planning-row__day-cell__drop-area__create-shift--popular': this.globalConfig.popularShiftDragging,
      };
    },
    showDropzone() {
      return this.hoverCopy || this.hoverMove;
    },
    addShiftClasses() {
      return {
        'planning-row__day-cell': true,
        'planning-row__day-cell--below-shift': this.cellShifts.length > 0,
      };
    },
    canDisplayAddShift() {
      return this.hasEnoughSpaceForAddShift &&
        this.addShiftHandler &&
        !this.isAnyShiftDragging &&
        this.canCreateShifts;
    },
    hasEnoughSpaceForAddShift() {
      return this.cellShifts.length === 0 || this.cellShifts.length < this.maxWeeklyShiftElements;
    },
    isSelfShiftDragging() {
      return this.shiftDraggingId !== null;
    },
    isAnyShiftDragging() {
      return this.globalConfig.shiftDragging || this.globalConfig.popularShiftDragging;
    },
    isAvailabilitiesComponentDisplayed() {
      if (this.isAddShiftDisplayed) return false;
      return (this.availabilities.length > 0 && !this.isSelfShiftDragging);
    },
    cellShifts() {
      return this.dayCellShifts.concat(this.pendingLeaveRequests);
    },
    loadingBrainShiftConfig() {
      return {
        brainLoading: this.globalConfig.brainLoading,
        brainShifts: this.globalConfig.brainShifts,
      };
    },
    userId() {
      return this.rowItem.id;
    },
    isSafariOnMac() {
      const macOS = isMacOS();
      const safari = isSafariBrowser();
      return macOS && safari;
    },
    popoverMenuConf() {
      let options = this.defaultPopoverOptions.filter(option => {
        if (this.day.isLocked && this.canCopySelectedCells) {
          return option.id === 'copy';
        }
        if (option.id === 'add_shift') {
          return this.canCreateShifts;
        }
        if (option.id === 'copy') {
          return this.globalConfig.canCopySelectedCells;
        }
        if (option.id === 'paste') {
          return this.globalConfig.canPasteCopiedCells;
        }
        if (option.id === 'delete') {
          return this.globalConfig.canDeleteSelectedCells;
        }
        if (option.id === 'unassigned') {
          return this.globalConfig.canUnassignSelectedCells;
        }
        if (option.id === 'lock_day') {
          return this.day.isLocked && !this.canCopySelectedCells;
        }
        return true;
      });

      if (!this.day.isLocked && options.length < 1) {
        options = [
          this.noActionPopoverOption,
        ];
      }

      return {
        placement: 'right-start',
        options,
      };
    },
    addShiftHandler() {
      return !this.multipleSelectionEnabled;
    },
  },
  mounted() {
    // Handle menu actions here for consistency and to avoid code duplication
    this.listenOnRoot('shift-menu-clicked', e => {
      const selectedShift = this.cellShifts.find(shift => e.shiftId === shift.id);
      const isCurrentCell = `${this.day.date}-${this.rowItem.id}` === e.id;

      if (selectedShift && isCurrentCell) {
        if (e.action === 'ADD_NEW_SHIFT') {
          this.openModal();
        } else if (e.action === 'MOVE_TO_UNASSIGNED') {
          this.updateShift({
            shifts: [
              { ...selectedShift, attributes: { ...selectedShift.attributes, userId: null } },
            ],
            shopId: this.globalConfig.currentShop.id,
            periodStartsAt: this.globalConfig.monday,
            periodEndsAt: this.globalConfig.sunday,
          })
            .then(() => {
              this.hideMenu();
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.unassigned.success'),
                variant: 'success',
              });
            })
            .catch(() => {
              this.emitOnRoot('shift-menu-action-failed');
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error'),
                variant: 'error',
              });
            });
        } else if (e.action === 'DELETE_SHIFT') {
          this.destroyShift({
            shiftId: selectedShift.id,
            shopId: this.globalConfig.currentShop.id,
            periodStartsAt: this.globalConfig.monday,
            periodEndsAt: this.globalConfig.sunday,
          })
            .then(() => {
              this.hideMenu();
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.destroy.success'),
                variant: 'success',
              });
            })
            .catch(() => {
              this.emitOnRoot('shift-menu-action-failed');
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error'),
                variant: 'error',
              });
            });
        }
      }
    });

    if (this.isSafariOnMac) {
      document.addEventListener('contextmenu', this.handleContextMenu, true);
    }
  },
  beforeDestroy() {
    // Cleanup listener on destroy
    if (this.isSafariOnMac) {
      document.removeEventListener('contextmenu', this.handleContextMenu, true);
    }
  },
  methods: {
    ...mapActions('planningsShifts', [
      'destroyShift',
      'updateShift',
      'createShift',
    ]),
    ...mapActions('planningsBulkEdit', [
      'copySelectedCells',
      'pasteCopiedCells',
      'deleteSelectedCells',
    ]),
    ...mapMutations('planningsState', [
      'setShiftDragging',
    ]),
    ...mapMutations('planningsBulkEdit', [
      'addToSelectedCells',
      'removeFromSelectedCells',
      'resetSelectedCells',
    ]),

    handleAvailabilitiesMouseEnter() {
      if (!this.dayCellHasShifts) {
        // Dissociating from isHoveringAddShift to prevent lag related rendering errors
        this.availabilitiesDisplayAddShift = true;
        setTimeout(() => {
          this.availabilitiesDisplayAddShift = false;
        }, 0);
      }
    },
    handleShiftMouseLeave() {
      this.isHoveringShift = false;
      this.hideMenu();
    },
    isShiftOnDayLocked(shift) {
      const { startsAt, endsAt } = shift.attributes;
      const shiftDays = [skDate(startsAt).format('YYYY-MM-DD'), skDate(endsAt).format('YYYY-MM-DD')];

      return this.globalConfig.visibleDays
        .filter(day => shiftDays.includes(day.date))
        .some(day => day.isLocked);
    },
    handleClickOnCopyCells() {
      this.copySelectedCells();
      this.$skAnalytics.track('bulk_edit_copy_shift', { cells_selected: this.globalConfig.selectedCellsCount });
      this.emitOnRoot('hide-popover-menu');
    },
    async handleClickOnPasteCopiedCells() {
      try {
        await this.pasteCopiedCells();
        this.$skAnalytics.track('bulk_edit_paste_shift', {
          cells_selected: this.globalConfig.selectedShiftsCount,
        });
      } catch {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }

      this.emitOnRoot('hide-popover-menu');
    },
    handleClickOnAddShift() {
      setTimeout(() => {
        this.openModal();
      }, 0);
      this.emitOnRoot('hide-popover-menu');
      this.$skAnalytics.track('bulk_edit_add_shift', { cells_selected: this.globalConfig.selectedCellsCount });
    },
    async handleClickOnDeleteCellsShifts() {
      try {
        await this.deleteSelectedCells();
        this.$skAnalytics.track('bulk_edit_delete_shift', { cells_selected: this.globalConfig.selectedCellsCount });
      } catch {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }
      this.emitOnRoot('hide-popover-menu');
    },
    async handleClickOnUnassignCellsShifts() {
      const shiftsToUpdate = this.globalConfig.selectedCellsShifts
        .filter(shift => !!shift.attributes?.userId && !this.isShiftOnDayLocked(shift));

      if (isEmpty(shiftsToUpdate)) return;

      try {
        await this.updateShift({
          shifts: shiftsToUpdate.map(
            shift => ({ ...shift, attributes: { ...shift.attributes, userId: null } }),
          ),
          shopId: this.globalConfig.currentShop.id,
          periodStartsAt: this.globalConfig.monday,
          periodEndsAt: this.globalConfig.sunday,
        });
        this.$skAnalytics.track('bulk_edit_unassigned_shift', { cells_selected: this.globalConfig.selectedCellsCount });
        this.resetSelectedCells();
      } catch {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }
      this.emitOnRoot('hide-popover-menu');
    },
    openModal(event) {
      this.emitOnRoot('hide-popover-menu');
      if (!this.canCreateShifts) return;

      if (this.isUnassignedShiftsRow) this.$skAnalytics.track('click_on_add_unassigned_shift');

      this.emitOnRoot(
        'manageShiftModal',
        event,
        {
          ...this.defaultShiftTimes,
          rowItem: this.rowItem,
          isUnassignedShift: this.isUnassignedShiftsRow,
          date: this.dateForShiftsModal,
          dayCellShifts: this.dayCellShifts,
          availabilities: this.availabilities,
          lastUserShift: this.dayCellShifts
            .sort((s1, s2) => s1.id - s2.id)[this.dayCellShifts.length - 1],
        },
      );
    },
    handleRightSideMouseEnter() {
      if (this.cellShifts.length > 0) return;
      this.isHoveringAddShift = true;
    },
    handleClickOnShift(shift, event) {
      if (
        shift.attributes.isPendingLeaveRequest &&
        this.globalConfig.currentLicense.attributes.canManageEmployeeRequests
      ) {
        const shiftId = shift.id.replace('tmp', '');
        this.emitOnRoot(
          'open-manage-request-modal',
          this.globalConfig.pendingLeaveRequestShifts.find(({ id }) => id === shiftId).attributes,
        );
      }
      if (event.metaKey || event.ctrlKey) {
        return;
      }
      this.openShiftModal(shift, event);
    },
    handleClickOnDayCell(event) {
      this.notifyClickOnLockedDay(event);
      this.handleClickOnCell(event);
    },
    openShiftModal(shift, event) {
      // On position view -> we dont want to open shift modal when the user clicks on the employee selector
      const targetClassList = Array.from(event.target.classList);
      if (targetClassList
        .some(className => className.startsWith('planning-row__shift-quick-select')) ||
        event.target.nodeName === 'path' // click on SVG path
      ) {
        return;
      }

      if (this.isShiftFromAnotherShop(shift)) return;

      if (!this.canEditShifts) {
        this.emitOnRoot('init-read-only-shift-modal', null, { shift, isUnassignedShift: !shift.attributes.userId });
        return;
      }

      this.emitOnRoot('hide-alerts-for-shift', { shiftId: shift.id });
      this.emitOnRoot(
        'manageShiftModal',
        event,
        {
          shift,
          rowItem: this.rowItem,
          isUnassignedShift: !shift.attributes.userId,
          date: this.dateForShiftsModal,
          availabilities: this.availabilities,
          isPendingLeaveRequest: shift.attributes.isPendingLeaveRequest,
        },
      );
    },
    showMenu(shift) {
      this.isHoveringShift = true;
      if (!this.canCreateShifts) return;

      // Calculate shift menu position, to be placed at the bottom right of the shift
      const shiftElRect = this.$refs[`shift_${shift.id}`][0].$el.getBoundingClientRect();
      const menuPositionLeft = shiftElRect.left + shiftElRect.width - 10; // offset the menu from the alerts (design validated)
      const menuPositionTop = shiftElRect.top + shiftElRect.height;
      this.emitOnRoot('show-shift-menu', {
        shiftId: shift.id,
        isShiftFromAnotherShop: this.isShiftFromAnotherShop(shift),
        isPendingLeaveRequest: shift.attributes?.isPendingLeaveRequest || false,
        isUnassignedShiftsRow: this.isUnassignedShiftsRow,
        top: menuPositionTop,
        left: menuPositionLeft,
        id: `${this.day.date}-${this.rowItem.id}`,
      });
    },
    hideMenu() {
      this.emitOnRoot('hide-shift-menu');
    },
    notifyClickOnLockedDay() {
      if (!this.day.isLocked) return;
      this.emitOnRoot('hide-popover-menu');

      this.emitOnRoot(
        'click-on-locked-day',
        event,
        this.day.date,
      );
    },
    handleDragStart(shift, event) {
      /* NOTE: though this can seem useless, those lines are necessary due to a weird
      ** behavior with trackpads allowind to start dragging even if the :draggable attribute
      ** is set to false */
      if (!this.isDraggable(shift)) {
        event.preventDefault();
        event.stopImmediatePropagation();
        return;
      }

      this.isHoveringCell = false;
      this.isHoveringShift = false;

      this.emitOnRoot('hide-alerts-for-shift', { shiftId: shift.id });
      this.shiftDraggingId = shift.id;
      event.dataTransfer.clearData();
      event.dataTransfer.setData(getDragDataFormat('shift'),
        JSON.stringify({ shift, user: this.rowItem }));
      this.setShiftDragging(true);
    },
    handleDragEnd() {
      this.shiftDraggingId = null;
      this.setShiftDragging(false);
    },
    handleShiftDrop(action, event) {
      if (this.globalConfig.popularShiftDragging) {
        this.$skAnalytics.track('shift_pasted_from_popular');
      }
      this.hoverMove = false;
      this.hoverCopy = false;

      const obj = JSON.parse(event.dataTransfer.getData(getDragDataFormat('shift')));
      const shift = obj.shift;
      const oldUser = obj.user;

      const shopId = parseInt(shift.attributes.shopId, 10);
      const localShift = cloneDeep(shift);

      this.shiftDroppingId = shift.id;
      // [BADGING] in case absenceShift is validated into a workshift, nullify absence params.
      if (shift.relationships.previsionalPoste &&
        shift.relationships.previsionalPoste.attributes.absenceKey &&
        shift.relationships.poste &&
        !shift.relationships.poste.attributes.absenceKey) {
        localShift.attributes.absenceCalculation = '';
        localShift.attributes.hoursWorth = 0;
        localShift.attributes.dayAbsence = false;
      }

      const { startsAt, endsAt } =
        getValidShiftTimes(localShift.attributes, this.globalConfig.currentShop, this.day.date);
      localShift.attributes.startsAt = startsAt;
      localShift.attributes.endsAt = endsAt;

      localShift.attributes.delay = 0;
      localShift.attributes.previsionalStart = null;
      localShift.attributes.previsionalEnd = null;
      localShift.attributes.previsionalSaved = null;
      localShift.attributes.previsionalPosteId = null;

      if (this.globalConfig.isPostesView) {
        localShift.relationships.poste = { ...this.rowItem };
      } else {
        const userId = this.isUnassignedShiftsRow ? null : parseInt(this.rowItem.id, 10);
        localShift.attributes.userId = userId;
      }

      sanitizeShift(localShift, true, { oldUser, newUser: this.rowItem }, this.globalConfig);

      if (action === 'createShift') {
        localShift.id = null;
        localShift.attributes.provenance = 'duplicate_week';
        const tracker = this.globalConfig.isPostesView ? 'shift_pasted_poste' : 'shift_pasted_week';
        this.$skAnalytics.track(tracker);
      } else {
        const tracker = this.globalConfig.isPostesView ? 'shift_moved_poste' : 'shift_moved_week';
        this.$skAnalytics.track(tracker);
      }

      this[action]({
        shiftId: localShift.id,
        shifts: [localShift],
        shopId,
        periodStartsAt: this.globalConfig.monday,
        periodEndsAt: this.globalConfig.sunday,
        isFromDragAndDrop: true,
      })
        .catch(error => {
          let message;
          if (error.response) {
            message = error.response.data.message === this.$t('plannings.table.cells.error.backend') ?
              this.$t('plannings.table.cells.error.no_postes') :
              error.response.data.message;
          } else {
            message = this.$t('errors.standard_message');
          }

          this.$skToast({
            message,
            variant: 'error',
          });
        })
        .finally(() => {
          this.shiftDroppingId = null;
        });
    },
    isShiftFromAnotherShop(shift) {
      return (
        parseInt(shift.attributes.shopId, 10) !== parseInt(this.globalConfig.currentShop.id, 10)
      );
    },
    shiftWrapperClasses(shiftId) {
      return { 'planning-row__day-cell__shift-wrapper--wait': shiftId === 'tmp' };
    },
    shiftClasses(shiftId) {
      return {
        'planning-row__day-cell__shift--hidden': this.shiftDraggingId === shiftId,
        'planning-row__day-cell__shift--wait': shiftId === 'tmp',
        'planning-row__day-cell__shift--opacity': shiftId.includes('brain'),
      };
    },
    isDraggable(shift) {
      if (this.multipleSelectionEnabled) return false;
      return this.canCreateShifts &&
        !shift.attributes.isPendingLeaveRequest &&
        !this.isShiftFromAnotherShop(shift) &&
        this.shiftDroppingId !== shift.id;
    },
    handleClickOnCell(event) {
      if (!this.globalConfig.displayBulkEditShifts) return;

      const selectedCellKey = this.globalConfig.getSelectedCellKey(this.rowItem.id, this.day.date);

      // Desactivate Safari context menu
      if (this.isSafariOnMac && event.ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
        this.addToSelectedCells(selectedCellKey);
        this.emitOnRoot('show-popover-menu', event, this.popoverMenuConf);
        return;
      }

      // Detect if user is on macOS by checking userAgent
      const isMac = isMacOS();

      // Right click
      // - Standard right click (event.button === 2)
      // - Mac special behaviors
      //    Ctrl+Click = right click
      //    Ctrl+Command+Click = right click (Ctrl is pressed)
      const isRightClick = event.button === 2 || (isMac && event.ctrlKey);

      // Multi-select
      // - Mac Command key (metaKey)
      // - Windows/Linux Control key (ctrlKey)
      const isKeyClicked = isMac ? (event.metaKey && !event.ctrlKey) : event.ctrlKey;

      if (isKeyClicked && this.isSelected && !isRightClick) {
        this.removeFromSelectedCells(selectedCellKey);
        return;
      }

      // Skip selection logic if it's a right click on an already selected cell
      if (isRightClick && this.isSelected) {
        return;
      }

      if (!isKeyClicked || this.isSelected) {
        this.resetSelectedCells();
      }

      this.addToSelectedCells(selectedCellKey);
    },
    showPopoverMenu(event) {
      if (!this.globalConfig.displayBulkEditShifts) return;

      this.handleClickOnCell(event);
      this.$nextTick(() => {
        this.emitOnRoot('show-popover-menu', event, this.popoverMenuConf);
      });
      this.$skAnalytics.track('bulk_edit_right_click', { cells_selected: this.globalConfig.selectedCellsCount });
    },
    handleContextMenu(event) {
      // if it's a ctrl+click on safari, prevent the native context menu
      if (event.ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__day-cell__wrapper {
  width: 100%;
  border-right: 1px solid $sk-grey-10;
  border-bottom: 1px solid $sk-grey-10;
  position: relative;
  min-width: 0; // Used to authorize elements to be smaller than their content
  display: flex;
  flex-direction: column;

  &.hover-day-cell:hover,
  &:not(
    :has(.planning-row__day-cell:hover)
  ):has(.planning-row__day-cell__add-shift-wrapper.hover):hover {
    background-color: $sk-grey-5 !important;
  }
  // Handle the shift when we add a border to selected cell
  box-sizing: border-box;

  &.day-cell-selected {
    box-shadow: inset 0 0 0 2px $sk-blue;
  }

  .planning-row__day-cell__click-handler {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
  }

  .planning-row__day-cell {
    cursor: pointer;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border: 2px solid $sk-blue;
    border-radius: 6px;
    margin: 3px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 10;

    &--below-shift {
      // remove double margin when a cell contains multiple shifts
      margin-top: 0;
      height: calc(100% - 3px);
    }

    &__shift--hidden {
      // workaround to hide element while keeping it visible on drag
      // https://stackoverflow.com/a/36379727
      transition: .01s;
      opacity: .3;
    }

    &__shift-wrapper--wait {
      cursor: wait;
    }

    &__shift--wait {
      pointer-events: none;
    }

    &__shift--opacity {
      opacity: .4;
    }
  }

  &.planning-row__day-cell__wrapper--current-day {
    background-color: rgba($sk-blue-5, .4);
  }

  &.planning-row__day-cell__wrapper--disabled {
    // if daycell is locked, we apply opacity to all direct children
    & > div {
      opacity: .7;
    }
  }

  &.planning-row__day-cell__wrapper--disabled-cursor {
    cursor: not-allowed;
  }
}

.planning-row__day-cell__left-side {
  display: flex;
  width: calc(100% - 9px);
  flex-direction: column;
  flex: 1;
}

.planning-row__day-cell__right-side {
  max-width: 9px;
  min-width: 9px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.planning-row__day-cell__row {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  flex: 1;
}

.planning-row__day-cell__wrapper:last-child {
  border-right: none;
}

.planning-row__day-cell__add-shift-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.planning-row__day-cell__drop-area {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
}

.planning-row__day-cell__drop-area__cell {
  border: 2px solid $sk-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 3px);
  height: calc(100% - 6px);
  background: $sk-white;
  font-size: $fs-text-s;
  font-weight: $fw-semi-bold;
  color: $sk-blue;
  opacity: 1;

  &--copy {
    border-radius: 0 6px 6px 0;
  }

  &--move {
    border-radius: 6px 0 0 6px;
  }

  &--hover {
    color: $sk-white;
    background: $sk-blue;
  }

  &--popular {
    width: calc(100% - 6px);
    border-radius: 6px;
  }
}

.planning-row__day-cell__drop-area__update-shift {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  opacity: 0;
}

.planning-row__day-cell__drop-area__create-shift {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  opacity: 0;

  &--popular {
    left: 0;
    width: 100%;
  }
}

.planning-row__day-cell__wrapper:hover .planning-row__day-cell__add-shift-wrapper.hover {
  .planning-row__day-cell {
    flex-grow: 1;
    display: flex;
  }
}

.planning-row__day-cell__add-shift-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
