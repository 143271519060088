<template>
  <tr class="request__row">
    <td
      v-if="isAllRequestsView"
      ref="shopNameLabel"
      v-tooltip="shopNameTooltip"
      class="request__shopname-row"
      @click="handleOpenModal(request)"
    >
      <span v-tooltip="shopNameTooltip">{{ requestShopName }}</span>
    </td>
    <td
      v-if="columns.employee.active"
      ref="employeeLabel"
      class="request__employee-row"
      @click="handleOpenModal(request)"
    >
      <span v-tooltip="employeeTooltip">{{ requestUserName }}</span>
    </td>
    <td
      v-if="columns.duration.active"
      ref="durationLabel"
      v-tooltip="durationTooltip"
      class="request__duration-row"
      @click="handleOpenModal(request)"
    >
      {{ requestDuration }}
    </td>
    <td
      v-if="columns.dates.active"
      ref="datesLabel"
      class="request__dates-row"
      @click="handleOpenModal(request)"
    >
      <span v-tooltip="datesTooltip">{{ displayDate }}</span>
    </td>
    <td
      v-if="columns.type.active"
      ref="typeLabel"
      v-tooltip="typeTooltip"
      class="request__type-row"
      @click="handleOpenModal(request)"
    >
      {{ absenceName }}
    </td>
    <template v-if="displayValidatorColumn">
      <td
        ref="managerLabel"
        class="request__manager-row"
        @click="handleOpenModal(request)"
      >
        <span v-tooltip="managerTooltip">
          <template v-if="request.attributes.status === 'pending'">
            {{ receiverName }}
          </template>
          <template v-else>
            {{ validatorName }}
          </template>
        </span>
      </td>
    </template>
    <td
      v-if="columns.sent_at.active"
      ref="askedLabel"
      class="request__asked-row"
      @click="handleOpenModal(request)"
    >
      <span v-tooltip="askedTooltip">{{ askedDate }}</span>
    </td>
    <td
      v-if="columns.status.active"
      class="request__row_tag"
      @click="handleOpenModal(request)"
    >
      <span v-tooltip="statusTooltip">
        <SkTag :variant="statusVariant">
          {{ $t('requests.statuses.' + request.attributes.status) }}
        </SkTag>
      </span>
    </td>
    <td class="request__actions-row">
      <div class="request__actions-row--button">
        <DropdownCircleButton
          ref="actionsButtonDropdown"
          placement="bottom-start"
          size="small"
          trigger="click"
          :button-tooltip="actionButtonTooltip"
          :button-tooltip-modifiers="['left']"
          :icon="actionButtonIcon"
          :items="actionItems"
          :prevent-overflow="false"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import DropdownCircleButton from '@app-js/shared/components/DropdownCircleButton';

import RowMixins from '@app-js/requests/mixins/RowMixins';

export default {
  name: 'LeaveRequestsTeamRow',
  components: { DropdownCircleButton },
  mixins: [RowMixins],
  methods: {
    handleOpenModal(request) {
      this.openManageLeaveRequestModal(request);
      this.$emit('hide-action-dropdowns');
    },
    hideDropdown() {
      this.$refs.actionsButtonDropdown.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
  .request__row {
    &:hover {
      background: $sk-blue-5;
    }

    td {
      padding: 14.5px 8px;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
</style>
