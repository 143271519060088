<template>
  <div>
    <h1 class="sk-header--1">
      {{ $t('shop_settings.tabs.rules.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.rules.subtitle') }}
    </p>
    <div class="shop-settings-rules__container">
      <SkNav
        v-if="anyCounterEnabled(currentShop.id)"
        class="sk-nav__wrapper"
      >
        <SkNavItem :to="{ name: 'shop_settings_main_rules' }">
          {{ $t('shop_settings.tabs.rules.main_rules.title') }}
        </SkNavItem>
        <SkNavItem :to="{ name: 'shop_settings_counter_rules' }">
          {{ $t('shop_settings.tabs.rules.counter_rules.title') }}
        </SkNavItem>
      </SkNav>
      <div class="rules__container">
        <router-view />
      </div>
    </div>
    <StickyBar
      :visible="showStickyBar"
      :submit-button-label="$t('shop_settings.sticky_bar.submit')"
      :submit-spinner="loadingUpdate"
      :tracking-options="trackingOptions"
      container-scroll-id="shop-settings__container"
      class="shop-settings-rules__container__sticky-bar"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

import { FEATURES } from '@app-js/shared/constants/features';
import StickyBar from '../../../shared/components/Stickybar';

export default {
  name: 'ShopRules',
  components: { StickyBar },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToCurrentShop) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashCurrentShop();
          this.resetError();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', [
      'currentShop',
      'error',
      'loadingUpdate',
    ]),
    ...mapGetters('currentShop', [
      'changesMadeToLegalWeeklyHours',
      'changesMadeToShopOpeningHours',
      'switchingToNonHealthConvention',
      'isAnnualizedWorkingTimeAvailable',
      'isRttCounterAvailable',
      'showStickyBar',
      'unsavedChangesToCurrentShop',
    ]),
    ...mapGetters('features', ['anyCounterEnabled']),

    trackingOptions() {
      return {
        update: 'rules_updated',
      };
    },
  },
  mounted() {
    if (
      !this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
      !this.isRttCounterAvailable
    ) return;

    this.fetchEmployees({ shopId: this.currentShop.id });
  },
  methods: {
    ...mapActions('currentShop', ['updateShop']),
    ...mapMutations('currentShop', [
      'squashCurrentShop',
      'squashOriginalCurrentShopData',
      'resetError',
      'resetShopOpeningHours',
      'resetLegalWeeklyHours',
      'resetConvention',
    ]),
    ...mapActions('currentOrganisation', ['fetchCurrentOrganisation']),
    ...mapActions('shopEmployees', ['fetchEmployees']),
    handleSubmit() {
      if (!this.unsavedChangesToCurrentShop) {
        this.$skToast({
          message: this.$t('shop_settings.update_shop.no_change_message'),
          variant: 'information',
        });
        return;
      }
      if (this.changesMadeToShopOpeningHours) {
        this.$root.$emit('confirm', event, {
          title: this.$t('shop_settings.update_shop.change_to_opening_hours.title'),
          description: this.$t('shop_settings.update_shop.change_to_opening_hours.description'),
          actionText: this.$t('actions.continue'),
          onConfirm: this.checkChangeSectorBeforeUpdate,
          onCancel: this.resetShopOpeningHours,
        });
      } else if (this.changesMadeToLegalWeeklyHours) {
        this.$root.$emit('confirm', event, {
          title: this.$t('shop_settings.update_shop.change_to_legal_weekly_hours.title'),
          description: this.$t('shop_settings.update_shop.change_to_legal_weekly_hours.description'),
          actionText: this.$t('actions.continue'),
          onConfirm: this.checkChangeSectorBeforeUpdate,
          onCancel: this.resetLegalWeeklyHours,
        });
      } else {
        this.checkChangeSectorBeforeUpdate();
      }
    },
    checkChangeSectorBeforeUpdate() {
      if (this.switchingToNonHealthConvention) {
        this.$nextTick(() => {
          this.$root.$emit('confirm', event, {
            title: this.$t('shop_settings.update_shop.switch_to_non_health_convention.title'),
            description: this.$t('shop_settings.update_shop.switch_to_non_health_convention.description'),
            actionText: this.$t('actions.continue'),
            onConfirm: this.shopUpdater,
            onCancel: this.resetConvention,
          });
        });
      } else {
        this.shopUpdater();
      }
    },
    shopUpdater() {
      this.updateShop({ shopId: this.currentShop.id })
        .then(() => {
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_BASIC_RULES_UPDATED);
          this.updateShopCallbacks();
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    updateShopCallbacks() {
      this.squashOriginalCurrentShopData();
      this.fetchCurrentOrganisation();
      this.$skToast({
        message: this.$t('shop_settings.update_shop.success_message'),
        variant: 'success',
      });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings-rules__container__sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.shop-settings-rules__container {
  position: relative;
  padding-top: 20px;
}

.rules__container {
  margin-top: 30px;
}

.base_rules__container {
  margin-top: 30px !important;
}

.meal_rules__container {
  padding-bottom: 40px;
}

// Edge case, no hover effect for settings child routes
::v-deep .router-link-exact-active:hover {
  color: $sk-black;
}

.sk-nav__wrapper {
  overflow: visible;
}
</style>
