<script>
import skDate from '@skello-utils/dates';
import { mapState } from 'vuex';

export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('requests', ['availabilityUsers']),
    startDate() {
      return skDate(this.request.attributes.startsAt);
    },
    endDate() {
      return skDate(this.request.attributes.endsAt);
    },
    askedDate() {
      return skDate(this.request.attributes.createdAt).format('L');
    },
    hours() {
      return `${this.startDate.format('HH:mm')} - ${this.endDate.format('HH:mm')}`;
    },
    displayDate() {
      const isRecurrent = this.request.attributes.recurrence !== 'none';
      if (this.startDate.isSame(this.endDate, 'day') && !isRecurrent) {
        return `${this.startDate.format('DD MMM YYYY')}`;
      }

      if (this.startDate.isSame(this.endDate, 'month') && !isRecurrent) {
        return `${this.startDate.date()} - ${this.endDate.date()} ${this.endDate.format('MMM YYYY')}`;
      }

      const exceptionalDate = `${this.startDate.format('DD MMM')} - ${this.endDate.format('DD MMM YYYY')}`;
      return !isRecurrent ? exceptionalDate : this.$t(`requests.week_days.${this.request.attributes.dayOfWeek}`);
    },
    statusVariant() {
      switch (this.request.attributes.requestStatus) {
        case 'accepted':
          return 'green';
        case 'refused':
          return 'danger';
        default:
          return 'orange';
      }
    },
    employee() {
      const user = this.availabilityUsers
        .find(u => Number(u.id) === this.request.attributes.userId);
      return !user ? '-' : `${user.attributes.firstName} ${user.attributes.lastName}`;
    },
  },
};
</script>
