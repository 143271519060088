<template>
  <div class="automatic-planning__wrapper sk-body">
    <SkSidePanel
      id="automatic-planning-side-panel"
      medallion-content-component="MagicWandIcon"
      medallion-background-color="#f3f3f3"
      medallion-content-color="#727272"
      :title="$t('automatic_planning.sidebar.title')"
      :subtitle="$t('automatic_planning.sidebar.subtitle')"
      @closed="closeSidebar"
    >
      <template #body>
        <div
          v-if="areRulesLoaded"
          :class="sidepanelWrapperClasses"
        >
          <AutomaticPlanningUpsell
            v-if="!canAccessSmartPlanner"
            class="automatic-planning__sidepanel__upsell-wrapper"
            @upsell="requestUpsell"
          />
          <template
            v-else
            class="automatic-planning__sidepanel-wrapper"
          >
            <!-- BEGIN - SUGGESTED PLANNING -->
            <!-- TEMP CODE TO TEST FEATUREFLAGDEV_CANARY_SUGGESTED_PLANNING CANARY FLAG -->
            <span v-if="isSuggestedPlanningAvailable">
              SUGGESTED PLANNING AVAILABLE FOR THIS SHOP
              <br>(id: {{ currentShop.id }})
            </span>
            <!-- END -->
            <AutomaticPlanningIntro
              v-if="isStep('intro')"
              :automatic-planning-rules-url="automaticPlanningRulesUrl"
              :automatic-planning-competencies-url="automaticPlanningCompetenciesUrl"
              @update-step="updateStep"
            />
            <ProvenanceChoice
              v-else-if="isStep('provenance')"
              :is-planning-data-completed="isPlanningDataCompleted"
              :automatic-planning-rules-url="automaticPlanningRulesUrl"
              @update-step="updateStep"
            />
            <ResultInfos
              v-else-if="isStep('result-infos')"
              :result-infos="resultInfos"
              :automatic-planning-rules-url="automaticPlanningRulesUrl"
              @close-side-panel="closeFromStep"
            />
            <StepForm
              v-else
              :automatic-planning-rules-url="automaticPlanningRulesUrl"
              :current-step="step"
              :submitting-assignment="submittingAssignment"
              @update-step="updateStep"
              @submit="submitAssignment"
            />
          </template>
        </div>
        <LoaderContainer v-else />
      </template>
    </SkSidePanel>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { displayDelightedSurvey } from '@skello-utils/delighted_survey.js';
import { httpClient } from '@skello-utils/clients';
import ActionCableWebsocketClient from '@skello-utils/websocket_client/action_cable_websocket_client';
import ShiftAnimationStack from '@app-js/plannings/shared/utils/automatic_planning/shift_animation_stack';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';

import {
  SIDEPANEL_SHOW_EVENT, SIDEPANEL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import AutomaticPlanningIntro from './AutomaticPlanningIntro';
import ProvenanceChoice from './ProvenanceChoice';
import StepForm from './StepForm';
import ResultInfos from './ResultInfos';
import AutomaticPlanningUpsell from './AutomaticPlanningUpsell';

const ASSIGNMENT_TIMEOUT_DELAY = 45000; // 45 seconds
const SURVEY_TIMEOUT_DELAY = 60000; // 1 minute
let assignmentTimeout;
let surveyTimeout;
const websocketClient = new ActionCableWebsocketClient();
let shiftAnimationStack;

export default {
  name: 'AutomaticPlanningSidePanel',
  components: {
    AutomaticPlanningIntro,
    ProvenanceChoice,
    StepForm,
    ResultInfos,
    AutomaticPlanningUpsell,
    LoaderContainer,
  },
  data() {
    return {
      showSidebar: false,
      step: 'intro',
      submittingAssignment: false,
      resultInfos: {},
      upsellAlreadyRequested: false,
      upsellRequested: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('planningsAutomaticPlanning', ['config']),
    ...mapState('planningsShifts', ['shifts']),
    ...mapState('planningsLoading', ['planningDataStatus']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isLoadingCompleted', 'isFirstBatchLoaded']),
    ...mapGetters('planningsUsers', ['filteredUsers']),
    ...mapGetters('currentUser', ['impersonate']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('planningsState', ['monday', 'sunday', 'isMonthlyView']),
    ...mapGetters('planningsAutomaticPlanning', ['provenanceSteps']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    sidepanelWrapperClasses() {
      return {
        'automatic-planning__sidepanel__body': this.config.quota.current >= this.config.quota.max,
        'automatic-planning__sidepanel-wrapper': !this.isStep('result-infos'),
      };
    },
    areRulesLoaded() {
      return Object.keys(this.config).length > 0;
    },
    automaticPlanningRulesUrl() {
      return `/v3/shops/${this.currentShop.id}/settings/automatic_planning/rules?automatic_planning_source=v3`;
    },
    automaticPlanningCompetenciesUrl() {
      return `/v3/shops/${this.currentShop.id}/settings/automatic_planning/competencies?automatic_planning_source=v3`;
    },
    canAccessSmartPlanner() {
      return this.currentShop.attributes.canAccessAutomaticPlanning;
    },
    fakeShiftIds() {
      return [...new Set(this.shifts.concat(this.monthShifts))]
        .filter(shift => shift?.attributes?.automaticPlanningId)
        .map(shift => shift.attributes.automaticPlanningId.toString());
    },
    isSuggestedPlanningAvailable() {
      return this.isDevFlagEnabled('FEATUREFLAGDEV_CANARY_SUGGESTED_PLANNING');
    },
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
  },
  watch: {
    planningDataStatus(newValue, oldValue) {
      if (
        this.isProgressiveLoadingEnabled &&
        this.showSidebar &&
        oldValue === PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED &&
        newValue === PLANNING_DATA_STATUS.FIRST_BATCH_LOADED
      ) {
        this.$root.$emit('need-all-planning-data');
      }
    },
  },
  mounted() {
    this.listenOnRoot('open-automatic-planning', this.openSidebar);
    this.listenOnRoot('close-automatic-planning', () => {
      this.$nextTick(() => {
        this.closeSidebar();
      });
    });
    shiftAnimationStack =
      new ShiftAnimationStack(this.processShift, this.onAnimationStart, this.onAnimationEnd);
  },
  beforeDestroy() {
    websocketClient.unsubscribeAll();
  },
  methods: {
    ...mapActions('planningsTemplates', ['fetchTemplates']),
    ...mapActions('planningsAutomaticPlanning', ['fetchConfig', 'fetchPredictionModelStatus', 'submitAutomaticPlanning']),
    ...mapActions('planningsShifts', ['fetchShiftAlerts']),
    ...mapActions('planningsLoading', ['fetchPlanningEssentialData']),
    ...mapMutations('planningsShifts', ['upsertShift', 'removeShifts']),
    ...mapMutations('planningsKpis', ['holdKpis', 'resumeKpis']),
    ...mapMutations('planningsState', [
      'automaticPlanningAssignmentPending',
      'automaticPlanningAssignmentComplete',
    ]),
    ...mapActions('employeeCounters', ['fetchUsersHoursCounters']),
    onAnimationStart() {
      this.holdKpis();
    },
    onAnimationEnd() {
      this.resumeKpis();

      this.fetchShiftAlerts({
        shop_id: this.currentShop.id,
        starts_at: this.monday,
        ends_at: this.sunday,
        user_ids: this.filteredUsers.map(user => user.id),
      });
    },
    isStep(step) {
      return this.step && this.step === step;
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    async openSidebar() {
      // don't re-fetch on double click button
      if (this.showSidebar) return;
      if (this.isProgressiveLoadingEnabled && this.isFirstBatchLoaded) {
        this.$root.$emit('need-all-planning-data');
      }
      this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'automatic-planning-side-panel');
      this.uuid = uuidv4();
      await this.subscribeToActionCable(this.uuid);

      this.showSidebar = true;
      this.fetchConfig({ shopId: this.currentShop.id, date: this.monday });
      this.fetchTemplates({ shopId: this.currentShop.id, activePlanning: 'week' });
      this.fetchPredictionModelStatus(this.currentShop.id);

      // Get automatic planning results from the localStorage
      const automaticPlanningResults =
        localStorage.getItem(`automatic_planning_last_results_${this.currentShop.id}`);

      if (automaticPlanningResults) {
        /*
          results are structured this way in the localstorage
          automatic_planning_last_results_${shopId}: {
            [userId_1]: {
              date: monday1,
              results: {results},
            },
            [userId_2]: {
              date: monday2,
              results: {results},
            }
          }
        */
        const parsedResults = JSON.parse(automaticPlanningResults);

        // We redirect to the results view
        // if the user has a result for the current week in the localstorage
        if (parsedResults[this.currentUser.id] &&
          parsedResults[this.currentUser.id].date === this.monday) {
          this.resultInfos = parsedResults[this.currentUser.id].results;
          this.step = 'result-infos';
        }
      }
    },
    closeSidebar() {
      if (!this.showSidebar) return;

      const tracker =
        this.step === 'result-infos' ? 'automatic_planning_close_sidebar' : 'automatic_planning_aborted';
      this.$skAnalytics.track(tracker);
      this.resetData();
    },
    closeFromStep() {
      this.$root.$emit(SIDEPANEL_HIDE_EVENT, 'automatic-planning-side-panel');
    },
    updateStep(newStep) {
      this.step = newStep;
    },
    requestUpsell() {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('automatic_planning.sidebar.upsell_infos.upsell_already_requested'),
          variant: 'success',
          containerId: 'automatic_planning',
        });

        return;
      }

      this.$skAnalytics.track('automatic_planning_upsell', { shop_id: this.currentShop.id });

      const params = {
        shop_id: this.currentShop.id,
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('automatic_planning.sidebar.upsell_infos.button_clicked'),
          upsell_type: 'automatic_planning',
          current_page: this.$t('automatic_planning.sidebar.upsell_infos.current_page'),
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.upsellRequested = true;
          this.$skToast({
            message: this.$t('automatic_planning.sidebar.upsell_infos.submit_confirm'),
            variant: 'success',
            containerId: 'automatic_planning',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('automatic_planning.sidebar.upsell_infos.submit_error'),
            variant: 'error',
            containerId: 'automatic_planning',
          });
        });
    },
    submitAssignment({ templateId, selectedPostes, selectedEmployees, weekSelected }) {
      if (this.submittingAssignment) return;

      this.submittingAssignment = true;
      this.$skAnalytics.track('automatic_planning_generate');

      clearTimeout(assignmentTimeout);
      assignmentTimeout = setTimeout(this.manageAssignmentTimeout, ASSIGNMENT_TIMEOUT_DELAY);

      const selectedPosteIds = selectedPostes.map(poste => poste.id);
      const selectedEmployeeIds = selectedEmployees.map(employee => employee.id);

      this.submitAutomaticPlanning({
        monday: this.monday,
        templateId,
        selectedPosteIds,
        selectedEmployeeIds,
        weekSelected,
        step: this.step,
        uuid: this.uuid,
      })
        .then(result => {
          if (!result.success) throw result.error;

          this.$skToast({
            message: this.$t('automatic_planning.sidebar.submit_success'),
            variant: 'success',
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log({ error, step: 'assignment failed' });
          this.submittingAssignment = false;
          this.automaticPlanningAssignmentComplete();
          this.showErrorToast();
          throw error;
        })
        .finally(() => {
          this.submittingAssignment = false;
          clearTimeout(assignmentTimeout);
        });
    },
    async subscribeToActionCable(uuid) {
      await websocketClient.init();

      websocketClient.unsubscribeAll();

      websocketClient.subscribe({
        channel: 'V3::AutomaticPlanningsChannel',
        channelOptions: { uuid },
        callback: this.broadcastReceived,
      });
    },
    broadcastReceived(data) {
      clearTimeout(assignmentTimeout);
      this.setSurveyTimeout();

      if (data.shifts) {
        // eslint-disable-next-line no-console
        console.log({ data, step: 'broadcast received' });
        this.assignmentBroadcastReceived(data);
      } else if (data.result_infos) {
        // eslint-disable-next-line no-console
        console.log({ data, step: 'result infos received' });
        this.resultInfosBroadcastReceived(data.result_infos);
      } else {
        // eslint-disable-next-line no-console
        console.log({ data, step: 'error received' });
        this.errorBroadcastReceived(data.error);
      }
    },
    assignmentBroadcastReceived({ shifts, included }) {
      const formattedShifts = shifts.map(shift => ({ shift, included }));
      // eslint-disable-next-line no-console
      console.log({ formattedShifts });
      shiftAnimationStack.addShifts(formattedShifts);
      this.automaticPlanningAssignmentPending();

      if (!this.showSidebar) return;

      // Don't close modal if currently showing results
      if (this.step !== 'result-infos') {
        this.resetData();
      }

      this.$skToast({
        message: this.$t('automatic_planning.sidebar.assignment_success'),
        variant: 'success',
      });
    },
    processShift({ shift, included }) {
      const payload = { data: [shift], included };
      this.$store.commit('planningsShifts/upsertShift', { payload, isMonthlyView: this.isMonthlyView });
    },
    async resultInfosBroadcastReceived(resultInfos) {
      try {
        this.resultInfos = resultInfos;
        const userId = this.currentUser.id;
        let parsedResults = null;

        // We get the automatic_planning results from the shop in the localstorage
        const automaticPlanningResults = localStorage.getItem(`automatic_planning_last_results_${this.currentShop.id}`);
        if (automaticPlanningResults) {
          parsedResults = JSON.parse(automaticPlanningResults);

          // We parse the results to check if there a user has already a result on the current week
          Object.keys(parsedResults).forEach(key => {
            if (parsedResults[key].date === this.monday) {
              // If a user has a result we delete it
              delete parsedResults[key];
            }
          });
        }

        localStorage.setItem(
          `automatic_planning_last_results_${this.currentShop.id}`,
          JSON.stringify(
            { ...parsedResults,
              [userId]:
            { date: this.monday, results: resultInfos } },
          ),
        );

        await Promise.all([
          this.fetchPlanningEssentialData({
            shopId: this.currentShop.id,
          }),
          this.fetchUsersHoursCounters({
            shopId: this.currentShop.id,
            allUserCounters: true,
          }),
        ]);

        this.automaticPlanningAssignmentComplete();
        if (!this.showSidebar) await this.openSidebar();
        this.step = 'result-infos';
        // eslint-disable-next-line no-console
        console.log('All data updates completed successfully');
      } catch (error) {
        console.error('Error in resultInfosBroadcastReceived:', error);
        this.automaticPlanningAssignmentComplete();
      }
    },
    errorBroadcastReceived(errorData) {
      const errorType =
        errorData.error === 'NO_OPTIMAL_SOLUTION' ? 'assignment_unsuccessful' : 'request_error';

      this.automaticPlanningAssignmentComplete();
      this.resetData();
      this.$skToast({
        message: this.$t(`automatic_planning.sidebar.${errorType}`),
        variant: 'error',
      });
    },
    manageAssignmentTimeout() {
      this.automaticPlanningAssignmentComplete();
      this.resetData();
      this.$skToast({
        message: this.$t('automatic_planning.sidebar.assignment_unsuccessful'),
        variant: 'error',
      });
    },
    showErrorToast() {
      this.$skToast({
        message: this.$t('automatic_planning.sidebar.request_error'),
        variant: 'error',
      });
    },
    setSurveyTimeout() {
      // Don't double-show survey
      if (this.impersonate || surveyTimeout) return;

      surveyTimeout = setTimeout(
        () => {
          this.showCesSurvey();
          surveyTimeout = null;
        },
        SURVEY_TIMEOUT_DELAY,
      );
    },
    showCesSurvey() {
      const userParams = {
        email: this.currentUser.attributes.email,
        name: this.fullName,
        properties: {
          id: this.currentUser.id,
        },
      };

      displayDelightedSurvey('automaticPlanningCesKey', userParams);
    },

  },
};
</script>

<style lang="scss" scoped>
.automatic-planning__wrapper {

  // A legacy bit of code in v2 modifies all svg to add margin
  ::v-deep svg {
    margin: 0;
  }

  // sk-select class defined in v2 and in v3 differently.
  // Reset v2 version here so that v3 takes over
  ::v-deep .sk-select {
    font-size: 1em;
    border: 0 !important; // v2 definition includes !important
    padding: 0;
    max-width: none;
    min-width: 0;

    &:focus {
      box-shadow: none;
    }

    .sk-select__caret {
      margin: 20px 5px auto auto;
    }
  }
}

// Planning v3 is using a lot of z-index
// We need to make sure that the sidepanel wont be impacted
::v-deep .sk-sidepanel__wrapper {
  z-index: 10 !important;
}

::v-deep .sk-sidepanel__wrapper .sk-sidepanel__container {
  pointer-events: auto;
  line-height: 1.4;
  background-image: url('./shared/svg/wave_background.png');
  background-position: right bottom;
  background-repeat: no-repeat;
}

::v-deep .sk-sidepanel__container {
  display: flex;
  flex-direction: column;
}

::v-deep .sk-sidepanel__body {
  display: flex;
  flex: 1;
}

// separator is taking too much height
::v-deep .sk-sidepanel__header__separator {
  height: 0;
}

.automatic-planning__sidepanel-header {
  display: flex;
  margin-top: 5px;
}

.automatic-planning__sidepanel-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  ::v-deep .automatic-planning__rules-box__submit {
    .sk-button:last-child {
      margin-left: auto;
    }
  }
}

.automatic-planning__sidepanel__body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.automatic-planning__sidepanel__upsell-wrapper {
  height: 100%;
}

.loader-container {
  width:100%;
}
</style>
