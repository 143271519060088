<template>
  <img
    src="./images/cegedim-evp-export.svg"
    alt="Illustration of payroll variable elements being calculated and formatted for Cegedim"
    width="255px"
    height="160px"
  >
</template>

<script>
export default {
  name: 'CegedimEvpExport',
};
</script>
