<template>
  <SkModal
    id="send-legacy-punch-clock-modal"
    :modal-title="$t('employees.tabs.personal_data.profile.identity.modal.title')"
    :submit-disabled="submitDisabled"
    :tracking-options="trackingOptions"
    @cancel="handleCancel"
    @submit="handleSubmit"
  >
    <template #title-icon>
      <div class="send-punch-clock-token__modal__icon">
        <PaperAirplaneIcon fill="#fff" />
      </div>
    </template>
    <template #body>
      <SkModalSection
        :title="$t('employees.tabs.personal_data.profile.identity.modal.subtitle')"
        border-bottom="extended"
      >
        <div :style="{ marginBottom: '8px' }">
          <SkCheckBox
            id="sms-select-option"
            v-model="sendBySms"
            v-track="'check_sms_pin_code'"
            :label="$t('employees.tabs.personal_data.profile.identity.modal.delivery_options.sms')"
          />
        </div>
        <SkCheckBox
          id="email-select-option"
          v-model="sendByEmail"
          v-track="'check_mail_pin_code'"
          :label="$t('employees.tabs.personal_data.profile.identity.modal.delivery_options.email')"
        />
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';

import { getErrorMessage } from '@skello-utils/errors';

export default {
  name: 'SendLegacyPunchClockModal',
  data() {
    return {
      sendBySms: false,
      sendByEmail: false,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    submitDisabled() {
      return !this.sendBySms && !this.sendByEmail;
    },
    trackingOptions() {
      return {
        submit: 'send_pin_code_validate',
        cancel: 'send_pin_code_cancel',
      };
    },
  },
  methods: {
    ...mapActions('selectedEmployee', ['sendPunchClockToken']),
    handleSubmit() {
      this.sendPunchClockToken({
        employee_id: this.employee.id,
        send_by_sms: this.sendBySms,
        send_by_email: this.sendByEmail,
      })
        .then(() => {
          this.$skToast({
            message: this.$t('employees.tabs.personal_data.profile.identity.modal.success', { name: this.employee.attributes.firstName }),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          this.$skToast({
            message: getErrorMessage(error),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
      this.sendBySms = false;
      this.sendByEmail = false;
    },
    handleCancel() {
      this.sendBySms = false;
      this.sendByEmail = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.send-punch-clock-token__modal__icon {
  color: white;
  background: $sk-blue;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
</style>
