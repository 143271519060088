// See associated translation at v3/app/shared/lang/lib
const REASONS_OF_LEAVING = [
  'other_economic_reasons',
  'employer_death',
  'employee_death',
  'resignation',
  'resignation_conscience_clause',
  'employee_retirement',
  'end_fixed_term_or_placement',
  'end_apprenticeship',
  'end_support_contract',
  'end_mandate',
  'end_temp_assignment',
  'end_trial_employer',
  'end_trial_employee',
  'cats_dismissal',
  'parliamentary_dismissal',
  'other_dismissal',
  'serious_misconduct_dismissal',
  'gross_misconduct_dismissal',
  'project_completion',
  'force_majeure_contract_termination',
  'unfitness_non_occupational',
  'unfitness_occupational',
  'economic_layoff',
  'economic_refusal_security_contract',
  'specific_reason_dismissal',
  'admin_decision_dismissal',
  'closure_dismissal',
  'liquidation_dismissal',
  'employer_retirement',
  'transfer',
  'acknowledgment_termination',
  'judicial_termination',
  'child_withdrawal',
  'mobility_leave_termination',
  'early_termination_employer',
  'early_termination_employee',
  'unfitness_termination',
  'serious_misconduct_early_termination',
  'film_shutdown_termination',
  'gross_misconduct_early_termination',
  'mutual_agreement',
  'collective_agreement',
  'mutual_fixed_term_apprenticeship',
  'mutual_agreement_pap_entry',
  'collective_performance_agreement',
  'force_majeure_admin_ruling',
  'economic_security_contract',
  'transfer_without_termination',
];

export { REASONS_OF_LEAVING as default };
