<template>
  <div
    class="bundle-offer-card"
    :style="cardDynamicStyles"
  >
    <div class="bundle-offer-card__header">
      <img :src="bundleImgSrc">

      <div class="bundle-offer-card__title">
        <SkOroraTag
          v-if="showPopularTag"
          size="medium"
          color="skWarning"
          icon="FireV2Icon"
          variant="orange"
        >
          {{ $t('self_serve.step_five.bundle_offers.planning-timeclock.popular') }}
        </SkOroraTag>

        <span class="sk-heading-small-semibold">
          {{ title }}
        </span>
      </div>
    </div>

    <p class="sk-text-large-regular bundle-offer-card__description">
      {{ description }}
    </p>

    <SkOroraButton
      :id="submitId"
      :loading="isLoading"
      variant="highlighted"
      @click="handleSubmit(packName)"
    >
      {{ $t('self_serve.step_five.pack_offers.choose_cta') }}
    </SkOroraButton>

    <div class="bundle-offer-card__benefits-container">
      <div
        v-for="(benefit, index) in benefits"
        :key="index"
        class="bundle-offer-card__benefits"
      >
        <CheckMarkV2Icon
          fill="#2B66FE"
          height="20"
          width="20"
        />

        <span class="sk-text-medium-regular bundle-offer-card__grey-text">
          {{ benefit }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SkOroraButton, SkOroraTag, CheckMarkV2Icon,
} from '@skelloapp/skello-ui';
import PlanningPack from '../assets/images/planning_pack.png';
import TimeclockPack from '../assets/images/timeclock_pack.png';
import PlanningTimeclockPack from '../assets/images/planning_timeclock_pack.png';

export default {
  name: 'BundleOfferCard',
  components: {
    SkOroraButton, SkOroraTag, CheckMarkV2Icon,
  },
  props: {
    packName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    benefits: {
      type: Array,
      required: true,
    },
    submitForm: {
      type: Function,
      required: true,
    },
    maxDescriptionHeight: {
      type: Number,
      default: 0,
    },
    showPopularTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    bundleImgSrc() {
      switch (this.packName) {
        case 'planning':
          return PlanningPack;
        case 'timeclock':
          return TimeclockPack;
        case 'planning-timeclock':
          return PlanningTimeclockPack;
        default:
          return null;
      }
    },
    cardDynamicStyles() {
      const dynamicStyles = {};

      if (this.maxDescriptionHeight) {
        dynamicStyles['--max-description-height'] = `${this.maxDescriptionHeight}px`;
      }

      return dynamicStyles;
    },
    submitId() {
      return `self-serve-step-five-submit-${this.packName}`;
    },
  },
  methods: {
    async handleSubmit(packName) {
      this.isLoading = true;
      await this.submitForm(packName);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bundle-offer-card {
  width: 300px;
  height: 100%;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid $sk-grey-10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  @media (max-width: 350px) {
    width: 238px;
  }

  @media (min-width: 350px) and (max-width: 390px) {
    width: 292px;
  }
}

.bundle-offer-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  & > img {
    width: 72px;
    height: 72px;
    margin-left: -12px;
  }
}

.bundle-offer-card__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  position: relative;
}

.bundle-offer-card__title > .sk-tag {
  position: absolute;
  top: -16px;
}

.bundle-offer-card__description {
  height: var(--max-description-height);
  margin: 0;
}

.bundle-offer-card__benefits-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 115px;
  gap: 8px;
}

.bundle-offer-card__benefits {
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > svg {
    min-width: 20px;
  }
}

.bundle-offer-card__grey-text {
  color: $sk-grey-50;
}

</style>
