<template>
  <div class="progress-bar__step-container">
    <div :class="['progress-bar__step', `progress-bar__step-${state}`]" />
  </div>
</template>

<script>
export default {
  name: 'ProgressBarCell',
  props: {
    state: {
      required: true,
      validator(value, props) {
        return ['past', 'current', 'future'].includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.progress-bar__step-container {
  --height: 4px;
  width: 100%;
  height: var(--height);
  border-radius: calc(var(--height) / 2);
  background: $sk-grey-10;
  overflow: hidden;

  .progress-bar__step {
    width: var(--width);
    height: 100%;
    border-radius: calc(var(--height) / 2);
    background: $sk-blue;

    &-past {
      --width: 100%;
    }

    &-current {
      --width: 50%;
      transition: width 0.25s ease-in-out;
    }

    &-future {
      --width: 0%;
    }
  }
}
</style>
