<template>
  <div
    :class="wrapperClasses"
    @mouseenter="selectCellDate"
    @mouseleave="clearCellDate"
  >
    <div
      class="wrapper__day-info"
      @click="handleHeaderDayClick"
    >
      <div class="day-name">
        {{ dayName }}
      </div>
      <div class="day-number">
        {{ dayNumber }}
      </div>
    </div>
    <div
      :class="{
        'icons-section': true,
        'fade-item': isMonthlySkeletonEnabled
      }"
    >
      <div class="icons-section--left">
        <span
          v-if="dayInfo.holiday && dayInfo.holiday.name"
          v-tooltip.bottom.offset="holidayTooltip"
        >
          {{ $t('plannings.table.header.days.emoji.tada') }}
        </span>
        <BirthdayTag :day="dayInfo" />
      </div>

      <div class="icons-section--right">
        <CloseLockIcon
          v-if="dayInfo.isLocked"
          v-tooltip.bottom.offset="closedLockIconTooltip"
          v-track="'month_view_V3_unlock_day'"
          :class="lockIconClass"
          fill=""
          height="12px"
          width="12px"
          @click.native="handleValidateDayClick(false, $event)"
        />
        <OpenLockIcon
          v-else-if="isSelected"
          v-track="'month_view_V3_lock_day'"
          v-tooltip.bottom.offset="openLockIconTooltip"
          height="12px"
          width="12px"
          :class="lockIconClass"
          @click.native="handleValidateDayClick(true, $event)"
        />
      </div>
      <!--
          Here will go the BankHolidays (DEV-17390) icons
      -->
    </div>
    <MountingPortal
      v-if="shouldUnlockRequestModalBeMounted"
      mount-to="#modals-portal"
      append
    >
      <UnlockRequestModal
        :date="dayInfo.date"
        type="month"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import UnlockRequestModal from '@app-js/plannings/shared/components/modal/UnlockRequestModal';
import BirthdayTag from '@app-js/plannings/shared/components/BirthdayTag';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'HeaderDay',
  components: {
    UnlockRequestModal,
    BirthdayTag,
  },
  props: {
    dayInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsShifts', ['usersWithWeeklyBlockingAlert']),
    ...mapState('planningsState', ['selectedDate']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('planningsState', ['currentDate']),

    isMonthlySkeletonEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_MONTHLY_SKELETON');
    },
    isDayPlanningEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_DAILY_VIEW, this.currentShop.id);
    },
    shouldUnlockRequestModalBeMounted() {
      if (this.isLockDisabled) return false;

      if (!this.dayInfo.intermediateLocked || this.isSystemAdmin) return false;

      return true;
    },
    canTmpLockPlanning() {
      return this.currentLicense.attributes.canTmplockPlanning;
    },
    canLockPlanning() {
      return this.currentLicense.attributes.canLockPlanning;
    },
    holidayTooltip() {
      return this.dayInfo.holiday.name;
    },
    closedLockIconTooltip() {
      if (this.dayInfo.permanentLocked) {
        if (this.canLockPlanning) {
          return this.$t(
            'plannings.table.header.actions.day_validation.permanent_unlock.label',
          );
        }
        return this.$t(
          'plannings.table.header.actions.day_validation.permanent_unlock.unauthorized',
        );
      }

      if (this.canTmpLockPlanning) {
        return this.$t(
          'plannings.table.header.actions.day_validation.unvalidate.label',
        );
      }
      return this.$t(
        'plannings.table.header.actions.day_validation.unvalidate.unauthorized',
      );
    },
    openLockIconTooltip() {
      if (this.canTmpLockPlanning) {
        return this.$t(
          'plannings.table.header.actions.day_validation.validate.label',
        );
      }
      return this.$t(
        'plannings.table.header.actions.day_validation.validate.unauthorized',
      );
    },
    lockIconClass() {
      return {
        'header-day__footer-lock': true,
        'header-day__footer-lock--validated': this.dayInfo.validated,
        'header-day__footer-lock--intermediate': this.dayInfo
          .intermediateLocked,
        'header-day__footer-lock--permanent': this.dayInfo.permanentLocked,
        'header-day__footer-lock--disabled': this.isLockDisabled,
      };
    },
    isLockDisabled() {
      return (
        !this.canTmpLockPlanning ||
        this.dayInfo.permanentLocked ||
        this.undoRedoLoading
      );
    },
    isSelected() {
      return this.dayInfo.date === this.selectedDate;
    },
    day() {
      return skDate(new Date(this.dayInfo.date));
    },
    dayName() {
      const day = this.day
        .utc()
        .locale('en')
        .format('dddd')
        .toLowerCase();
      return this.$t(`plannings.table.header.short_day.${day}`);
    },
    dayNumber() {
      return this.day
        .utc()
        .locale('en')
        .format('DD');
    },
    isToday() {
      // this is a hack to fix the issue with the timezones because of negative offsets
      const today = skDate().utcOffset(this.day.utcOffset());
      const monthOfDateParam = this.day
        .utc()
        .locale('en')
        .format('MM');
      const yearOfDateParam = this.day
        .utc()
        .locale('en')
        .format('YYYY');

      return (
        today.format('DD') === this.dayNumber &&
        today.format('MM') === monthOfDateParam &&
        today.format('YYYY') === yearOfDateParam
      );
    },
    isInMonth() {
      return (
        skDate(this.currentDate)
          .utcOffset(this.day.utcOffset())
          .format('MM') ===
        this.day
          .utc()
          .locale('en')
          .format('MM')
      );
    },
    wrapperClasses() {
      const { isLocked: isDayLocked } = this.dayInfo;
      return {
        wrapper: true,
        'wrapper--locked': isDayLocked,
        'wrapper--clickable': this.isDayPlanningEnabled,
        'not-in-month': !this.isInMonth,
        today: this.isToday && !isDayLocked,
        today__locked: this.isToday && isDayLocked,
      };
    },
  },
  methods: {
    ...mapActions('planningsState', ['validatePeriod']),
    ...mapGetters('planningsState', ['isOnlyOneDayUnlocked']),
    ...mapMutations('planningsShifts', ['setUsersWithWeeklyBlockingAlert']),
    ...mapMutations('planningsState', ['setSelectedDate']),
    handleHeaderDayClick() {
      if (this.isDayPlanningEnabled) {
        this.$router.push({
          name: 'plannings_days',
          query: { date: this.dayInfo.date },
        });
      }
    },
    validationSuccessMessage(validation) {
      if (validation) {
        return this.$t(
          'plannings.table.header.actions.day_validation.validate.success',
        );
      }
      return this.$t(
        'plannings.table.header.actions.day_validation.unvalidate.success',
      );
    },
    validationErrorMessage(validation) {
      if (validation) {
        return this.$t(
          'plannings.table.header.actions.day_validation.validate.error',
        );
      }
      return this.$t(
        'plannings.table.header.actions.day_validation.unvalidate.error',
      );
    },
    handleValidateDayClick(validation, event) {
      if (this.isLockDisabled) {
        return;
      }
      // Only system administrator can intermediate unvalidate
      // Other users can do "unlock request"
      if (
        this.dayInfo.intermediateLocked &&
        !this.isSystemAdmin &&
        !validation
      ) {
        this.emitOnRoot(
          MODAL_SHOW_EVENT,
          event,
          `unlock-request-modal-month-${this.dayInfo.date}`,
        );
        return;
      }

      this.validatePeriod({
        currentDate: this.dayInfo.date,
        startDate: this.dayInfo.date,
        endDate: this.dayInfo.date,
        shopId: this.currentShop.id,
        validationLevel: this.dayInfo.intermediateLocked ?
          'intermediate_locked_days' :
          'validated_days',
        validationValue: validation,
        dayLock: !this.dayInfo.intermediateLocked,
      })
        .then(() => {
          this.$skToast({
            message: this.validationSuccessMessage(validation),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.validationErrorMessage(validation),
            variant: 'error',
          });
        });
    },
    selectCellDate() {
      if (this.dayInfo.date === this.selectedDate) {
        this.setSelectedDate(this.dayInfo.date);
        return;
      }
      this.setSelectedDate(null);

      const showLockDelay = 100;
      this.delayedSelect = setTimeout(() => {
        this.setSelectedDate(this.dayInfo.date);
      }, showLockDelay);
    },

    clearCellDate() {
      clearTimeout(this.delayedSelect);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44px;
  padding: 8px 0;

  &--locked {
    color: $sk-grey-30;
  }

  &--clickable {
    cursor: pointer;
  }

  &__day-info {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .day-name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .day-number {
    margin-top: 2px;
    font-size: 16px;
    font-weight: 600;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }

  &.today {
    .day-name {
      color: $sk-blue-50;
    }

    .day-number {
      color: $sk-white;
      border-radius: 100%;
      background-color: $sk-blue-50;
    }
  }

  &.today__locked {
    .day-name {
      color: $sk-grey-30;
    }

    .day-number {
      color: $sk-white;
      border-radius: 100%;
      background-color: $sk-grey-30;
    }
  }

  &.not-in-month {
    color: $sk-grey-30;
  }
}

.icons-section {
  position: absolute;
  width: 100%;
  bottom: 2px;
  display: flex;
  justify-content: space-between;
  cursor: default;

  &--right {
    display: flex;
    align-items: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-item {
  opacity: 0;
  animation: fadeIn .3s ease-in forwards;
}

.header-day__footer-lock {
  cursor: pointer;
  margin-left: auto;

  &--validated {
    fill: $sk-blue;
  }

  &--intermediate {
    fill: $sk-success;
  }

  &--permanent {
    fill: $sk-error;
  }

  &--disabled {
    cursor: not-allowed;
  }
}
</style>
