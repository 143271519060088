import { FEATURES } from '@app-js/shared/constants/features';

/**
 * @namespace
 */
const initialState = {
  /**
   * @type {boolean}
   */
  downloadTimeclock: JSON.parse(localStorage.getItem('downloadTimeclock')) || false,
  /**
   * @type {boolean}
   */
  employeesAdded: JSON.parse(localStorage.getItem('employeesAdded')) || false,
  /**
   * @type {boolean}
   */
  videoTimeclockIntroduction: JSON.parse(localStorage.getItem('videoTimeclockIntroduction')) || false,
  /**
   * @type {boolean}
   */
  manageBadge: JSON.parse(localStorage.getItem('manageBadge')) || false,
  /**
   * @type {boolean}
   */
  experienceEmployee: JSON.parse(localStorage.getItem('experienceEmployee')) || false,
  /**
   * @type {boolean}
   */
  inviteEmployees: false,

  /**
   * @type {boolean}
   * @description Whether the checklist is collapsed or not
   * @default true
   */
  checklistCollapsed: true,

  /**
   * @type {boolean}
   * @description Whether the checklist is visible or not
   * @default true
   */
  checklistVisible: true,
};

const getCommonTimeclockChecks = (_state, rootState, selfGetters, rootGetters) => {
  const isTimeclockOnboardingEnabled = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_TIMECLOCK_ONBOARDING');
  const isPlanningDisabled = !rootGetters['features/isFeatureEnabled'](
    FEATURES.FEATURE_PLANNING_ACCESS,
    rootState.currentShop.currentShop.id,
  );
  const isTimeclockEnabled = rootGetters['features/isFeatureEnabled'](
    FEATURES.FEATURE_TIMECLOCK,
    rootState.currentShop.currentShop.id,
  );
  const hasFinishedTimeclockDemo = selfGetters.hasFinishedTimeclockDemo;
  const hasFinishedTimeclockOnboarding = selfGetters.hasFinishedTimeclockOnboarding;
  const isCurrentShopSelected = rootGetters['currentShop/isCurrentShopSelected'];

  return {
    isTimeclockOnboardingEnabled,
    isPlanningDisabled,
    isTimeclockEnabled,
    hasFinishedTimeclockOnboarding,
    isCurrentShopSelected,
    hasFinishedTimeclockDemo,
  };
};

/**
 * @type {import('vuex').Module<initialState>}
 */
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setStep(state, { key, value }) {
      state[key] = value;
    },
    toggleChecklistCollapsed(state) {
      state.checklistCollapsed = !state.checklistCollapsed;
    },
    setChecklistVisible(state, value) {
      state.checklistVisible = value;
    },
  },
  actions: {
    updateStep({ commit }, { key, value }) {
      commit('setStep', { key, value });
      localStorage.setItem(key, value);
    },
    async updateStepCompletion({ state, rootState, getters, dispatch }, { id, completed }) {
      switch (id) {
        case 'download_timeclock_step':
          state.downloadTimeclock = completed;
          localStorage.setItem('downloadTimeclock', completed);
          break;
        case 'manage_badges_step':
          state.manageBadge = completed;
          localStorage.setItem('manageBadge', completed);
          break;
        case 'explore_employee_experience_step':
          state.experienceEmployee = completed;
          localStorage.setItem('experienceEmployee', completed);
          break;
        case 'add_employees_step':
          state.employeesAdded = completed;
          localStorage.setItem('employeesAdded', completed);
          break;
        case 'invite_employees_step':
          state.inviteEmployees = completed;
          localStorage.setItem(`inviteEmployeesForShop_${rootState.currentShop.currentShop.id}`, completed);
          break;
        default:
          break;
      }

      const allStepCompleted = getters.checklistSteps.every(s => s.completed || !s.visible);
      if (!allStepCompleted) return;

      await dispatch('currentShop/updateShop', {
        shopId: rootState.currentShop.currentShop.id,
        overrideParams: { onboarding_status: { timeclock_demo: true } },
      }, { root: true });
    },
  },
  getters: {
    shouldShowTimeclockDemoChecklist(_state, selfGetters, rootState, rootGetters) {
      const {
        isTimeclockOnboardingEnabled,
        isPlanningDisabled,
        isTimeclockEnabled,
        hasFinishedTimeclockOnboarding,
        hasFinishedTimeclockDemo,
      } = getCommonTimeclockChecks(_state, rootState, selfGetters, rootGetters);

      const isTimeclockDemoChecklistEnabled = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_TIMECLOCK_DEMO');
      const notInTimeclockOnboarding = !window.location.pathname.includes('timeclock-onboarding');

      return (
        !hasFinishedTimeclockDemo &&
        notInTimeclockOnboarding &&
        isTimeclockDemoChecklistEnabled &&
        isTimeclockOnboardingEnabled &&
        isPlanningDisabled &&
        isTimeclockEnabled &&
        hasFinishedTimeclockOnboarding
      );
    },
    shouldShowTimeclockOnboarding(_state, selfGetters, rootState, rootGetters) {
      const {
        isTimeclockOnboardingEnabled,
        isPlanningDisabled,
        isTimeclockEnabled,
        hasFinishedTimeclockOnboarding,
        isCurrentShopSelected,
      } = getCommonTimeclockChecks(_state, rootState, selfGetters, rootGetters);

      return (
        isTimeclockOnboardingEnabled &&
        isPlanningDisabled &&
        isTimeclockEnabled &&
        !hasFinishedTimeclockOnboarding &&
        isCurrentShopSelected
      );
    },
    shouldShowTimeclockWelcomeVideo: (state, selfGetters, _rootState, _rootGetters) => {
      const hasFinishedWelcomeVideo = state.videoTimeclockIntroduction;

      const shouldShowTimeclockDemoChecklist = selfGetters.shouldShowTimeclockDemoChecklist;

      return shouldShowTimeclockDemoChecklist && !hasFinishedWelcomeVideo;
    },
    hasFinishedTimeclockDemo: (_state, _selfGetters, rootState) => rootState
      .currentShop
      .currentShop
      ?.attributes
      ?.onboardingStatus
      ?.timeclock_demo,
    hasFinishedTimeclockOnboarding: (_state, _selfGetters, rootState) => rootState
      .currentShop
      .currentShop
      ?.attributes
      ?.onboardingStatus
      ?.timeclock_onboarding,
    checklistSteps: (state, selfGetters, rootState) => {
      const badgingUsers = rootState.badgings.users;
      const employees = rootState.employees.employees
        .filter(user => user.attributes.currentLicenseType !== 'system_admin');

      const employeesAdded = badgingUsers.length > 0 || employees.length > 0;

      const atLeastOneEmployeeWithEmail =
        badgingUsers.some(user => user.attributes.email) ||
          employees.some(employee => employee.attributes.email &&
            employee.attributes.currentLicenseType !== 'system_admin',
          );

      const atLeastOneBadgingValidated =
          rootState.badgings.badgingsHistory.length > 0;

      return [
        {
          id: 'download_timeclock_step',
          labelKey: 'badgings.timeclock_demo.checklist.download_timeclock',
          completed: !!rootState
            .currentShop
            .currentShop
            ?.attributes
            ?.tabletLastSeenAt || state.downloadTimeclock,
          enabled: true,
          visible: !!rootState
            .currentShop
            .currentShop
            ?.attributes
            ?.onboardingStatus
            .tablet_selected,
        },
        {
          id: 'manage_badges_step',
          labelKey: 'badgings.timeclock_demo.checklist.manage_badges',
          completed: state.manageBadge || atLeastOneBadgingValidated,
          enabled: true,
          visible: true,
        },
        {
          id: 'explore_employee_experience_step',
          labelKey: 'badgings.timeclock_demo.checklist.explore_employee_experience',
          completed: state.experienceEmployee,
          enabled: true,
          visible: true,
        },
        {
          id: 'add_employees_step',
          labelKey: 'badgings.timeclock_demo.checklist.add_employees',
          completed: state.employeesAdded || employeesAdded,
          enabled: true,
          visible: true,
        },
        {
          id: 'invite_employees_step',
          labelKey: 'badgings.timeclock_demo.checklist.invite_employees',
          tooltipKey: employeesAdded && atLeastOneEmployeeWithEmail ? '' : 'badgings.timeclock_demo.checklist.missing_add_employees_step_tooltip',
          completed: selfGetters.inviteEmployeesCompleted,
          enabled: employeesAdded && atLeastOneEmployeeWithEmail,
          visible: true,
        },
      ];
    },
    allStepsCompleted: (_state, getters) => (
      getters.checklistSteps.every(s => s.completed || !s.visible)
    ),
    inviteEmployeesCompleted: (state, _getters, rootState) => (
      JSON.parse(localStorage.getItem(`inviteEmployeesForShop_${rootState.currentShop.currentShop.id}`)) || state.inviteEmployees
    ),
  },
};
