<template>
  <VueDraggableResizable
    v-if="shouldShowShift"
    ref="shift"
    :style="shiftStyle"
    :class="shiftClasses"
    h="auto"
    :w="shiftWidth"
    :handles="['mr','ml']"
    :grid="[quarterWidth, 0]"
    active
    enable-native-drag
    :resizable="resizable"
    :draggable="false"
    prevent-deactivation
    :min-width="quarterWidth / 2"
    :data-test="dataTest"
    @resizing="onResizeStart"
    @mouseenter.native.prevent="showShiftTooltip"
    @mouseleave.native.prevent="hideShiftTooltip"
    @resizestop="onResizeStop"
  >
    <div
      v-if="isDelayed"
      class="planning-row__shift-delay"
    />
    <div :class="planningRowDayShiftContentClasses">
      <div class="planning-row__day-shift-row--content">
        <span
          v-if="isOtherShopNameDisplayed"
          ref="otherShopName"
          v-tooltip.top="otherShopNameTooltip"
          class="planning-row__day-shift-other-shop"
        >
          {{ shift.attributes.shopName }}
        </span>
        <span
          ref="poste"
          v-tooltip.top="posteTooltip"
          class="planning-row__day-shift-poste"
          :style="zoomRange.poste"
        >
          {{ shift.relationships.poste.attributes.name }}
        </span>
      </div>
      <span
        v-if="isDurationDisplayed"
        class="planning-row__day-shift-duration"
        :style="zoomRange.duration"
      >
        {{ shiftDurationInHours }}h
      </span>
    </div>
    <div class="planning-row__day-shift-row">
      <span
        class="planning-row__day-shift-col planning-row__day-shift-col--left"
      >
        <div
          v-if="shift.attributes.previsionalSaved"
          :class="shiftValidatedClasses"
        >
          <div
            v-if="isPrevisionalDisplayed"
            class="planning-row__shift-validated__container"
            :style="zoomRange.validated"
          >
            <div class="planning-row__shift-validated__previsional_shift_container">
              <div class="planning-row__shift-validated__previsional-time">
                {{ previsionalTime }}
              </div>
            </div>
            <span class="planning-row__shift-validated__real-time">
              {{ formattedShiftHours }}
            </span>
          </div>
        </div>
        <div
          v-else
          :class="shiftHoursClasses"
        >
          {{ formattedShiftHours }}
        </div>
      </span>
      <div
        v-if="isRightColumnDisplayed"
        :class="planningRowDayActionsClasses"
      >
        <ActivityIcon
          v-if="shouldDisplayActivityIcon"
          :style="activityIconStyle"
          :note="note"
          :tasks="shift.attributes.tasks"
          :comments="shift.attributes.comments"
          :attachments-count="attachmentsCount"
          :shift-color="shift.attributes.color"
          width="12px"
          height="12px"
        />
        <div
          v-if="hasMeal"
          v-tooltip.top="mealTooltip"
          :class="mealClasses"
        />
      </div>
    </div>
    <span
      v-if="hasShiftAlert"
      :class="alertClasses"
    >
      <CircledExclamationMarkIcon
        :background-color="isAlertBlocking ? '#d03e50' : '#fff'"
        :fill="shift.attributes.color"
        width="12px"
        height="12px"
        @click.native.stop="openShiftAlertPopup"
      />
    </span>
    <div
      v-if="hasLeftEarly"
      class="planning-row__shift-left-early"
    />
  </VueDraggableResizable>
</template>

<script>
import { isEmpty } from '@skello-utils/array';
import skDate from '@skello-utils/dates';
import VueDraggableResizable from 'vue-draggable-resizable';
import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
} from '@app-js/shared/constants/shift';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import { zoomPlanningDay } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import { MAPPING_ZOOM_TO_DISPLAY } from '@app-js/plannings/shared/utils/zoom/zoom_day_values';
import ActivityIcon from '@app-js/plannings/shared/components/ShiftActivities/ActivityIcon';
import { MINIMUM_PIXEL_BY_QUARTER_HOURS } from '@app-js/shared/store/modules/plannings/plannings-state';

export default {
  name: 'Shift',
  components: { VueDraggableResizable, ActivityIcon },
  props: {
    shift: {
      type: Object,
      required: true,
    },
    globalConfig: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    resizable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShiftAlertDisplayed: false,
      startTime: skDate.utc(this.shift.attributes.startsAt),
      previsionalStartTime: skDate.utc(this.shift.attributes.previsionalStart),
      previsionalEndTime: skDate.utc(this.shift.attributes.previsionalEnd),
      isShopNameCut: false,
      isPosteCut: false,
      isResizing: false,
      lastWidth: this.shiftWidth,
    };
  },
  computed: {
    attachmentsCount() {
      return this.isWorkShift ?
        this.globalConfig.getDocumentsCountByShiftId(this.shift.id) :
        0;
    },
    quarterWidth() {
      return this.globalConfig.dayViewPlanningSizeVariables.pixelPerQuarterHours;
    },
    shiftClasses() {
      return {
        'planning-row__day-shift-wrapper': true,
        'planning-row__day-shift-wrapper--opacited': !this.globalConfig.isShiftInFilters(this.shift),
        'planning-row__day-shift-wrapper--absence': this.isAbsence,
        'planning-row__day-shift-wrapper--other-shop': this.isFromAnotherShop,
        'planning-row__day-shift-wrapper--left-early': this.hasLeftEarly,
        'planning-row__day-shift-wrapper--nowrap': !this.wrapHours,
        'planning-row__day-shift-col--left-small': this.shiftDurationInHours < 3,
      };
    },
    dataTest() {
      if ((this.isWorkShift && !this.isFromAnotherShop) ||
        this.wasAbsencePlanned) {
        return 'shift-work_shift';
      }
      if (this.isFromAnotherShop) return 'shift__another-shop';
      if (this.isAbsence) return 'shift__absence';
      return 'shift-no-data-test';
    },
    shiftValidatedClasses() {
      return {
        'planning-row__shift-validated': true,
        'planning-row__shift-validated__previsional_shift': !this.isPrevisionalDate,
      };
    },
    shiftHoursClasses() {
      return {
        'planning-row__day-shift-hours': true,
        'planning-row__day-shift-hours__absence': this.isAbsenceDayOrHalf,
      };
    },
    shiftHeight() {
      const size = zoomPlanningDay(this.globalConfig.planningZoom).planningRow.height;

      return parseInt(size, 10) - 1;
    },
    mealClasses() {
      return {
        'planning-row__day-shift-meals': true,
        [this.globalConfig.currentShop.attributes.mealEmoji]: true,
        em: true,
        'planning-row__day-shift-meals--margin': this.hasShiftAlert,
      };
    },
    alertClasses() {
      return {
        'planning-row__day-shift-alert': true,
        'planning-row__day-shift-alert--small': this.shiftDurationInMinutes <= 30,
      };
    },
    zoomRange() {
      const zoomPlanning = zoomPlanningDay(this.globalConfig.planningZoom);

      return {
        duration: zoomPlanning.shift.duration,
        poste: zoomPlanning.shift.poste,
        validated: zoomPlanning.shift.previsionalValidated,
      };
    },
    endTime() {
      if (
        [ABSENCE_TYPE_DAY, ABSENCE_TYPE_HALF_DAY].includes(this.shift.attributes.absenceCalculation)
      ) {
        const duration = (this.shift.attributes.absenceDurationInSeconds / 60).toFixed(2);
        return this.startTime.clone().add(duration, 'm');
      }
      return skDate.utc(this.shift.attributes.endsAt);
    },
    hasShiftAlert() {
      return !this.isFromAnotherShop && this.shift.relationships.alerts?.length;
    },
    isAlertBlocking() {
      return this.shift.relationships.alerts?.some(alert => alert.attributes.blocking);
    },
    shouldShowShift() {
      const { openingTime, closingTime } = this.openingAndClosingTime;

      return (
        this.endTime.isSameOrAfter(openingTime) &&
        this.startTime.isBefore(closingTime)
      );
    },
    isWorkShift() {
      return !this.shift.relationships.poste.attributes.absenceKey;
    },
    isAbsence() {
      return !this.isWorkShift;
    },
    isAbsenceDayOrHalf() {
      return this.shift.attributes.absenceCalculation &&
      [ABSENCE_TYPE_DAY, ABSENCE_TYPE_HALF_DAY].includes(this.shift.attributes.absenceCalculation);
    },
    isDelayed() {
      if (this.globalConfig.currentShop.attributes.badging) {
        return this.shift.attributes.lateness > 0;
      }
      return this.shift.attributes.delay > 0;
    },
    hasLeftEarly() {
      return this.globalConfig.currentShop.attributes.badging &&
        this.endTime.isBefore(this.previsionalEndTime) &&
        !this.wasAnotherType;
    },
    wasWorkShiftPlanned() {
      return this.shift.relationships.previsionalPoste &&
        !this.shift.relationships.previsionalPoste.attributes.absenceKey &&
        this.shift.relationships.poste.attributes.absenceKey;
    },
    wasAbsencePlanned() {
      // case when validated absence shift is afterward updated in working shift
      return this.shift.relationships.previsionalPoste?.attributes.absenceKey &&
        !this.shift.relationships.poste.attributes.absenceKey;
    },
    wasNothingPlanned() {
      return !this.shift.relationships.previsionalPoste;
    },
    wasAnotherType() {
      return this.wasWorkShiftPlanned || this.wasAbsencePlanned || this.wasNothingPlanned;
    },
    isFromAnotherShop() {
      return this.shift.attributes.shopId !== parseInt(this.globalConfig.currentShop.id, 10);
    },
    isPrevisionalDisplayed() {
      // We can't display the previsionnal time of shifts with less than 30mins
      // when the zoom is lower than 75% because there is no place to display it
      // but it could be a text instead ("!isPrevisionalDate" can be "Shift prévu") that
      // we want to display
      return this.shift.attributes.previsionalSaved &&
        (
          !(this.shiftDurationInMinutes <= 45 && this.globalConfig.planningZoom > 75) ||
          !this.isPrevisionalDate
        );
    },
    isPrevisionalDate() {
      return this.previsionalStartTime.isValid() && this.previsionalEndTime.isValid();
    },
    previsionalTime() {
      if (this.wasWorkShiftPlanned) {
        return this.$t('plannings.shift.badging.shift_planned');
      }

      if (this.wasAbsencePlanned) {
        return this.$t('plannings.shift.badging.absence_planned');
      }

      if (this.wasNothingPlanned) {
        return this.$t('plannings.shift.badging.no_shift_planned');
      }

      const displayedPrevisionalStartAt = this.shift.attributes.showStartTime ? this.previsionalStartTime.format('HH:mm') : '.';
      const displayedPrevisionalEndsAt = this.shift.attributes.showEndTime ? this.previsionalEndTime.format('HH:mm') : '.';

      return `${displayedPrevisionalStartAt} -\xa0${displayedPrevisionalEndsAt}`;
    },
    formattedShiftHours() {
      if (this.shift.attributes.absenceCalculation) {
        if (this.shift.attributes.absenceCalculation === ABSENCE_TYPE_DAY) return this.$t('plannings.shift.time.day');
        if (this.shift.attributes.absenceCalculation === ABSENCE_TYPE_HALF_DAY) return this.$t('plannings.shift.time.half_day');
      }

      const start = this.shift.attributes.showStartTime || this.isAbsence ? this.startTime.format('HH:mm') : '.';
      const end = this.shift.attributes.showEndTime || this.isAbsence ? this.endTime.format('HH:mm') : '.';

      return `${start} - ${end}`;
    },
    shiftStyle() {
      return {
        height: `${this.shiftHeight}px`,
        marginLeft: `${this.shiftMarginLeft}px`,
        backgroundColor: this.shift.attributes.color,
      };
    },
    activityIconStyle() {
      return { width: '12px', height: '12px', marginRight: '5px' };
    },
    shiftWidth() {
      // Minimal shift displayed duration is 15 mn
      const adjustedShiftDuration =
        Math.max(MINIMUM_PIXEL_BY_QUARTER_HOURS, this.shiftDurationInMinutes);
      // We substract 1px to account for border
      return adjustedShiftDuration *
        this.globalConfig.dayViewPlanningSizeVariables.pixelPerMinute - 1;
    },
    shiftMarginLeft() {
      const adjustedShiftOffset = Math.max(0, this.shiftStartOffsetInMinutes);
      return adjustedShiftOffset *
        this.globalConfig.dayViewPlanningSizeVariables.pixelPerMinute + 1;
    },
    openingAndClosingTime() {
      return openingAndClosingTimeAt(
        this.globalConfig.currentShop.attributes.openingTime,
        this.globalConfig.currentShop.attributes.closingTime,
        skDate(this.date).utc(true).format(),
      );
    },
    shiftStartOffsetInMinutes() {
      const { openingTime } = this.openingAndClosingTime;
      return this.startTime.diff(openingTime, 'm');
    },
    shiftEndOffsetInMinutes() {
      const { closingTime } = this.openingAndClosingTime;
      return this.endTime.diff(closingTime, 'm');
    },
    shiftDurationInMinutes() {
      const { closingTime } = this.openingAndClosingTime;
      const duration = skDate.duration(this.endTime.diff(this.startTime)).asMinutes();
      const minutesAfterClosingTime = Math.max(
        0, skDate.duration(this.endTime.diff(closingTime)).asMinutes(),
      );
      // Compensate negative offset if the shift starts before opening or ends after closing
      return duration -
        minutesAfterClosingTime -
        Math.abs(Math.min(0, this.shiftStartOffsetInMinutes));
    },
    shiftDurationInHours() {
      return parseFloat((this.shift.attributes.durationInSeconds / 3600).toFixed(2));
    },
    hasMeal() {
      return !this.isFromAnotherShop &&
        this.shift.attributes.nbMeal !== 0 &&
        this.shift.attributes.benefitInKind;
    },
    mealTooltip() {
      return this.$t('plannings.shift.meal_tooltip', {
        nbMeal: this.shift.attributes.nbMeal,
      });
    },
    note() {
      return !this.isFromAnotherShop ? this.shift.attributes.note : null;
    },
    otherShopNameTooltip() {
      return this.isShopNameCut ? this.shift.attributes.shopName : null;
    },
    posteTooltip() {
      return this.isPosteCut ? this.shift.relationships.poste.attributes.name : null;
    },
    isRightColumnDisplayed() {
      return this.shouldDisplayActivityIcon || this.hasMeal || this.isDurationDisplayed;
    },
    isIconDisplayedForZoomValue() {
      return this.shiftDurationInMinutes >= MAPPING_ZOOM_TO_DISPLAY[this.globalConfig.planningZoom];
    },
    isDurationDisplayed() {
      // Only show duration when shift duration is 45 or more
      return this.canShowDuration && this.shiftDurationInMinutes >= 45;
    },
    canShowDuration() {
      return !this.globalConfig.customShift || this.shift.attributes.showDuration;
    },
    isOtherShopNameDisplayed() {
      // Only show other shop name when shift duration is 1h15 or more
      // but also depends on zoom value (you can check the mapping table MAPPING_ZOOM_TO_DISPLAY)
      return this.isFromAnotherShop && this.isIconDisplayedForZoomValue;
    },
    wrapHours() {
      // Only wrap hours when shift duration is 45mn or less
      return this.shiftDurationInMinutes <= 45;
    },
    planningRowDayActionsClasses() {
      return {
        'planning-row__day-shift-col': true,
        'planning-row__day-shift-col--right': true,
        'planning-row__day-shift-col--right__with-alert': this.hasShiftAlert,
      };
    },
    planningRowDayShiftContentClasses() {
      return {
        'planning-row__day-shift-row': true,
        'planning-row__day-shift-row--higher': true,
        'planning-row__day-shift-row__other-shop': this.isOtherShopNameDisplayed,
      };
    },
    shouldDisplayActivityIcon() {
      const { note, tasks, comments, shopId } = this.shift.attributes;

      return !!note ||
      this.globalConfig.shouldDisplayComments(comments, shopId) ||
        (!isEmpty(tasks) && this.globalConfig.canDisplayTasks) ||
        this.attachmentsCount > 0;
    },
  },
  watch: {
    isShiftAlertDisplayed(newVal) {
      if (newVal) {
        this.listenOnRoot('modal-alert-scroll', this.openShiftAlertPopup);
      } else {
        this.$root.$off('modal-alert-scroll', this.openShiftAlertPopup);
      }
    },
  },
  created() {
    this.listenOnRoot('modal-alert-close', this.handleCloseNotification);
  },
  mounted() {
    this.isPosteCut = !this.$refs.poste ||
      this.$refs.poste.offsetWidth < this.$refs.poste.scrollWidth;

    this.isShopNameCut = !this.$refs.otherShopName ||
      this.$refs.otherShopName.offsetWidth < this.$refs.otherShopName.scrollWidth;
  },
  methods: {
    // lib provides a on-resize-start event but it can be triggered
    // without triggering @resizestop by clicking without dragging
    // The @resizing event doesn't have this issue
    onResizeStart() {
      if (!this.isResizing) {
        this.isResizing = true;
        this.$emit('resizestart');
      }
    },
    onResizeStop(left, _top, width) {
      const right = (width + left) - this.shiftWidth;
      this.$emit('resizestop', { left, right });
      this.isResizing = false;
    },
    handleCloseNotification(value) {
      this.isShiftAlertDisplayed = value;
    },
    openShiftAlertPopup() {
      this.isShiftAlertDisplayed = true;
      // These 31px have been validated with design team
      const shiftOffset = 31;
      const shiftElRect = this.$refs.shift.$el.getBoundingClientRect();
      // Ajust div position
      const menuPositionLeft = shiftElRect.left + shiftElRect.width - 25;
      const menuPositionTop = shiftElRect.top - shiftElRect.height / 2;
      this.emitOnRoot('show-alerts', {
        shift: this.shift,
        top: menuPositionTop - shiftOffset,
        left: menuPositionLeft,
      });
    },
    showShiftTooltip() {
      if (this.globalConfig.shiftDragging || this.isResizing) return;

      const shiftElRect = this.$refs.shift.$el.getBoundingClientRect();

      this.emitOnRoot('show-day-shift-tooltip', {
        shiftStyle: this.shiftStyle,
        globalConfig: this.globalConfig,
        posteName: this.shift.relationships.poste.attributes.name,
        shopName: this.isFromAnotherShop ? this.shift.attributes.shopName : null,
        formattedShiftHours: this.formattedShiftHours,
        shiftDurationInHours: this.shiftDurationInHours,
        shiftDurationInMinutes: this.shiftDurationInMinutes,
        displayIcons:
          this.shiftDurationInMinutes < MAPPING_ZOOM_TO_DISPLAY[this.globalConfig.planningZoom],
        meals: this.shift.attributes.nbMeal,
        note: this.note,
        // previsionalTime can have different type of values ("10:00 - 11:00" or "Shift prévu/Scheduled shift")
        //  which are not displayed the same way in the tooltip so we differenciate them here (previsionalTime/previsionalType)
        previsionalTime: this.isPrevisionalDate && !this.wasAnotherType ?
          this.previsionalTime :
          null,
        previsionalType: this.isPrevisionalDate && this.wasAnotherType ?
          this.previsionalTime :
          null,
        shiftElRect,
        tasks: this.shift.attributes.tasks,
        comments: this.shift.attributes.comments,
        attachmentsCount: this.attachmentsCount,
      });
    },
    hideShiftTooltip() {
      this.emitOnRoot('hide-day-shift-tooltip');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .handle {
  height: 100%;
  top: 5px;
  background: rgba(0, 0, 0, .15);
  border: none;
  opacity: 0;
  width: 8px;

  &-mr {
    margin-right: 10px;
    border-radius: 0 6px 6px 0;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &-ml {
    margin-left: 10px;
    border-radius: 0 6px 6px 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &:hover,
  &:active {
    opacity: 100;
  }
}

.planning-row__day-shift-wrapper--opacited {
  opacity: .3;
}

.planning-row__day-shift-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: auto;
  justify-content: space-between;
  height: calc(100% - 1px);
  color: white;
  border-radius: 6px;
  border: none;
  z-index: 1 !important; // allow drag & create to go under
  padding-bottom: 8px;

  &:hover {
    cursor: pointer;
  }

  // Classes used to manage drop-through in DailyViewRow.vue
  &.planning-row__day-shift-wrapper--drag-started {
    pointer-events: none;

    &.planning-row__day-shift-wrapper--currently-dragging {
      pointer-events: auto;
    }
  }

  .planning-row__shift-validated {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1em;
    z-index: 1;

    &__container {
      width: 100%;
    }

    &__previsional-time {
      margin: 0 auto -2px 0;
      font-size: .8em;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    &__previsional_shift {
      width: 100%;

      &_container {
        width: 100%;
        margin-bottom: 2px;
      }
    }

    &__real-time {
      font-weight: 700;
      margin-top: 3px;
    }
  }

  .planning-row__day-shift-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-inline: 6px;
    overflow: hidden;

    &--higher {
      padding-top: 8px;
    }

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      min-width: 90px;
      margin-right: 8px;
    }

    &__other-shop {
      padding-top: 4px;
    }
  }

  .planning-row__day-shift-col {
    display: flex;

    &--left {
      width: 100%;
      white-space: nowrap;
      min-width: 70px;
    }

    &--right {
      height: 100%;
      display: flex;
      align-items: flex-end;

      &__with-alert {
        margin-right: 10px;
      }
    }
  }

  .planning-row__day-shift {
    &-hours {
      line-height: 1.1em;
      font-size: 1.1em;
      margin-top: 2px;

      &__absence {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &-name {
      margin: 0 0 0 auto;
    }

    &-meals {
      display: flex;
      margin-right: 0;
      height: 12px;
      width: 12px;

      &--margin {
        margin-right: 5px;
      }
    }

    &-alert {
      position: absolute;
      right: 4px;
      bottom: 4px;

      &--small {
        right: 1px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-poste {
      width: 100%;
      text-align: left;
      line-height: 14px;
      overflow: hidden;
      font-weight: $fw-semi-bold;
      height: 14px;
      text-overflow: ellipsis;
    }

    &-other-shop {
      font-size: 8px;
      line-height: 9px;
      color: $sk-grey-50;
    }
  }

  &--absence {
    color: white;
    background:
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8px,
        rgba(#000, .05) 8px,
        rgba(#000, .05) 16px
      ),
      $sk-grey !important;
  }

  &--other-shop {
    border: 1px dashed $sk-grey;
    background-color: $sk-grey-10 !important;
    color: $sk-grey;

    svg > :nth-child(2) { fill: transparent; }
    svg > :nth-child(3) { fill: transparent; }
  }

  &--nowrap {
    .planning-row__day-shift-col.planning-row__day-shift-col--left {
      white-space: nowrap;
    }
  }

  .planning-row__shift-delay,
  .planning-row__shift-left-early {
    background:
      linear-gradient(
        45deg,
        rgba($sk-grey, .85) 15%,
        rgba($sk-grey, 1) 15% 30%,
        rgba($sk-grey, .85) 30% 45%,
        rgba($sk-grey, 1) 45% 60%,
        rgba($sk-grey, .85) 60% 75%,
        rgba($sk-grey, 1) 75% 90%,
        rgba($sk-grey, .85) 90%
      );
    width: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    // Used so it doesn't hide hours and duration values
    z-index: -1;
  }

  .planning-row__shift-delay {
    border-radius: 6px 0 0 6px;
    left: 0;
  }

  .planning-row__shift-left-early {
    border-radius: 0 6px 6px 0;
    right: 0;
  }
}
</style>
