<template>
  <div
    :ref="suggestedCompanyRegistrationReference"
    class="suggested-company-registration"
  >
    <div class=" suggested-company-registration__name text-truncate">
      <template v-for="labelPart in company.displayLabel">
        <!--
      the next "labelPart.text" mustaches are stuck inbetween their span tags
      because Vue adds a whitespace otherwise
    -->
        <span
          v-if="labelPart.isHighlighted"
          :key="`${company.shopRegistrationNumber}-${labelPart.text}`"
          class="sk-text-medium-semibold"
        >{{ labelPart.text }}</span>
        <span
          v-else
          :key="`${company.shopRegistrationNumber}-${labelPart.text}s`"
          class="sk-text-medium-regular"
        >{{ labelPart.text }}</span>
      </template>

      <span class="sk-text-medium-regular">
        {{ zipCodeAndDateLabel }}
      </span>
    </div>

    <span class="sk-text-small-regular suggested-company-registration--grey">
      {{ siretLabel }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'SuggestedCompanyRegistration',
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      suggestedCompanyRegistrationReference: `company_suggestion_${this.company.shopRegistrationNumber}`,
    };
  },
  computed: {
    ...mapGetters('onboarding', ['isScrollingThroughCompanyRegistrations']),

    tooltipText() {
      return `${this.company.name} ${this.zipCodeAndDateLabel} ${this.siretLabel}`;
    },
    siretLabel() {
      return `${this.$t('self_serve.step_four.siret_fetching.siret_label')} ${
        this.company.shopRegistrationNumber
      }`;
    },
    zipCodeLabel() {
      return this.company.zipCode === null ? '' : `(${this.company.zipCode})`;
    },
    zipCodeAndDateLabel() {
      const zipCode = this.zipCodeLabel;
      const createdAt = skDate(this.company.creationDate, 'DD-MM-YYYY').format(
        'MMM YYYY',
      );
      const capitalizedCreatedAt =
        createdAt.charAt(0).toUpperCase() + createdAt.slice(1);

      return `${zipCode} ${this.$t(
        'self_serve.step_four.siret_fetching.created_at',
      )} ${capitalizedCreatedAt}`;
    },
  },
  mounted() {
    this.$nextTick(() => this.computeSearchResultsTooltips());
  },
  methods: {
    computeSearchResultsTooltips() {
      const element = this.$refs[this.elementReference];
      if (!element) return;

      element.tooltip = this.tooltipText;

      // triggers "reactivity", since we add the tooltip to the element,
      // vue needs to trigger the component update to effectively
      // add the tooltip to the element in the DOM
      this.company = { ...this.company };
    },
  },
};
</script>

<style lang="scss" scoped>
.suggested-company-registration {
  display: flex;
  align-items: center;
  height: 36px;
  margin: 0 4px;
  padding: 10px 12px;
  gap: 8px;
  line-height: normal;

  &__name {
    flex: 1;
  }

  &--grey {
    flex-shrink: 0;
    color: $sk-grey-50;
  }

  &:hover {
    cursor: pointer;
    background: $sk-grey-5;
  }

  &:first-of-type {
    margin-top: 4px;
  }

  &:last-child {
    margin-bottom: 4px;
  }
}
</style>
