import selfServeVersions from './shared/constants/selfServeVersions';

import V5SelfServe from './v5/V5.vue';
import V5StepOne from './v5/pages/StepOne.vue';
import V5StepTwo from './v5/pages/StepTwo.vue';
import V5StepThree from './v5/pages/StepThree.vue';
import V5StepFour from './v5/pages/StepFour.vue';
import V5StepFive from './v5/pages/StepFive.vue';
import V5StepSix from './v5/pages/StepSix.vue';
import V5ExpiredToken from './v5/pages/ExpiredToken.vue';

// Set in app/javascript/src/v3/onboarding/app.vue
// A reload will ensure here that we have the good version
const version = localStorage.getItem('self-serve-version')?.toLowerCase() || selfServeVersions.DEFAULT;

const getChild = (component, label) => ({
  component,
  path: label,
  name: label,
});

const V5_CHILDREN = [
  getChild(V5StepOne, 'step_one'),
  getChild(V5StepTwo, 'step_two'),
  getChild(V5StepThree, 'step_three'),
  getChild(V5StepFour, 'step_four'),
  getChild(V5StepFive, 'step_five'),
  getChild(V5StepSix, 'step_six'),
];

const CHILDREN = {
  v5: V5_CHILDREN,
};

const SELF_SERVE = {
  v5: V5SelfServe,
};

const EXPIRED_TOKEN_COMPONENT = {
  v5: V5ExpiredToken,
};

const allSelfServeRoutes = [
  {
    path: '/v3/self_serve',
    component: SELF_SERVE[version],
    children: CHILDREN[version],
    props: route => ({ next: V5SelfServe.methods.goNext }),
  },
  {
    path: '/v3/self_serve/expired_token',
    component: EXPIRED_TOKEN_COMPONENT[version],
    name: 'expired_token',
  },
];

export default allSelfServeRoutes;
