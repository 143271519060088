<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';

import GenericToolbarAction from './GenericToolbarAction';
import PopularShiftsPopover from '../../PopularShiftsPopover';

export default {
  name: 'PopularShiftsAction',
  components: {
    PopularShiftsPopover,
  },
  extends: GenericToolbarAction,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('planningsState', ['isDailyView']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.popular_shifts.label');
    },
    icon() {
      return 'FireV2Icon';
    },
    visibleIn() {
      return 'toolbar-m-dropdown-s';
    },
    isPopov() {
      return true;
    },
    popovComponent() {
      return 'PopularShiftsPopover';
    },
    disabled() {
      return this.isDataLoading;
    },
    tooltip() {
      if (this.isDailyView) return this.$t('plannings.toolbar.actions_bar.tooltip_soon.title');

      return '';
    },
    isVisible() {
      return this.currentLicense.attributes.canCreateShifts && !this.isDailyView &&
        this.isFeatureEnabled(FEATURES.FEATURE_SHIFT_POPULAR, this.currentShop.id);
    },
  },
  methods: {},
};
</script>
