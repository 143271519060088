<template>
  <div :class="packoOfferOverlayWrapperClasses">
    <div :class="packOfferOverlayClasses">
      <div class="pack-offer-overlay__text">
        <span>{{ $t('kpis.pack_offers.tooltip.introduction') }}</span>
        <span>&nbsp;{{ $t('kpis.pack_offers.tooltip.access_limited_one') }}</span>
        <span style="font-weight:bold;">
          &nbsp;{{ $t('kpis.pack_offers.tooltip.access_limited_two') }}
        </span>
      </div>

      <div class="pack-offer-overlay__button">
        <SkOroraButton
          size="small"
          @click="clickOnUpsell"
        >
          {{ $t('kpis.pack_offers.tooltip.upsell_button') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import PackTag from '@app-js/shared/components/PackTag';

export default {
  name: 'PackOfferOverlay',
  // eslint-disable-next-line vue/no-unused-components
  components: { PackTag },
  props: {
    inLine: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  computed: {
    packOfferOverlayClasses() {
      return {
        'pack-offer-overlay__content': true,
        'pack-offer-overlay__content--block': !this.inLine,
        'pack-offer-overlay__content--inline': this.inLine,
      };
    },
    packoOfferOverlayWrapperClasses() {
      return {
        'pack-offer-overlay__wrapper': true,
        'pack-offer-overlay__wrapper--block': !this.inLine,
        'pack-offer-overlay__wrapper--inline': this.inLine,
        'pack-offer-overlay__wrapper--predicted': this.isPredictedTab,
        'pack-offer-overlay__wrapper--real': !this.isPredictedTab,
      };
    },
    isPredictedTab() {
      return this.tab === 'PredictedTab';
    },
  },
  methods: {
    clickOnUpsell(event) {
      this.emitOnRoot('click-on-upsell');
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-pack-tag {
  line-height: 22px;
}

.pack-offer-overlay__wrapper {
  position: absolute;
  right: 0;
  left: 177px;
  display: flex;
  justify-content: center;
  z-index: 1;

  &--block {
    top: 103px;
  }

  &--inline {
    top: 63px;
  }
}

.pack-offer-overlay__content {
  font-size: $fs-text-m;
  color: $sk-black;

  &--block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 475px;
    height: 110px;

    .pack-offer-overlay__text {
      text-align: center;
      line-height: 22px;
    }

    .pack-offer-overlay__button {
      margin-top: 10px;
    }
  }

  &--inline {
    align-items: center;
    display: inline-flex;
    margin: 0 50px;
  }
}
</style>
