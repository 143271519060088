<template>
  <!-- eslint-disable max-len -->
  <tr>
    <td :class="nodeRowNameClasses">
      <div class="node-item">
        <SkMedallion
          :icon="isClusterType ? 'NetworkIcon' : 'ShopIcon'"
          background-color="#d9e6ff"
          class="node-item__icon"
          color="#2b66fe"
        />
        <div
          v-if="isPrimaryNode"
          v-tooltip="handleNodeTypeTooltip"
          class="node-item__thumbtack"
        >
          <ThumbtackV2Icon
            width="12"
            height="12"
            fill=""
            class="shops-list__item-thumbtack__fill"
          />
        </div>
        <span class="node-item__name">
          {{ node.attributes.name }}
        </span>
      </div>
    </td>
    <td :class="nodeRowLicenseClasses">
      <SkSelect
        v-model="localLicense"
        :class="skSelectWidthClasses"
        :disabled="isSelectLicensesDisabled"
        :append-to-body="isNewEmployee"
        :height="isNewEmployee ? '200px' : null"
        :ajax-format-params="licenseAjaxFormatParams"
        :ajax-format-response="licenseAjaxFormatResponse"
        :ajax-client="ajaxClient"
        :ajax-url="fetchLicensesUrl"
        :label="$t('employees.add_employee_modal.affiliations.user_license_table.fields.licenses.title')"
        :x-offset="isNewEmployee ? '-200px' : null"
        :search-placeholder="searchPlaceholder"
      >
        <template #selected-option="{ value }">
          {{ value.text }}
        </template>
        <template #empty-option>
          {{ $t('search_bar.no_result') }}
        </template>
        <template #option="{ option, index }">
          <SkListItem>
            <template #left>
              <div class="node-row__license__list-checked-icon">
                <CheckMarkIcon
                  v-if="selectedLicense(option)"
                  fill=""
                  class="check-mark-icon"
                />
              </div>
            </template>
            <SkMedallion
              :content-text="index + 1"
              :color="positionContentTextColor(option)"
              :background-color="positionBackgroundColor(option)"
              class="node-row__license-medallion"
            />
            <template #right>
              {{ option.text }}
            </template>
          </SkListItem>
        </template>
      </SkSelect>
    </td>
    <td
      v-if="!isClusterType"
      class="node-row__planning"
    >
      <SkSwitch
        v-model="isEmployeeInPlanning"
        :disabled="!canEditTeamsOrPostes"
      />
    </td>
    <td
      v-if="!isClusterType"
      :class="nodeRowTeamsClasses"
    >
      <SkSelect
        v-model="localTeams"
        :class="skSelectWidthClasses"
        :append-to-body="isNewEmployee"
        :height="isNewEmployee ? '200px' : null"
        :tracking-options="teamSelectTrackingOptions"
        :label="$t('employees.add_employee_modal.affiliations.user_license_table.fields.teams.title')"
        :ajax-format-params="teamsAjaxFormatParams"
        :ajax-format-response="teamsAjaxFormatResponse"
        :ajax-client="ajaxClient"
        :ajax-url="fetchTeamsUrl"
        :disabled-options="disabledTeamIds"
        :disabled="!canEditTeamsOrPostes"
        :x-offset="isNewEmployee ? '-140px' : null"
        :search-placeholder="searchPlaceholder"
        multi
        object-value
      >
        <template #empty-option>
          <div class="node-row__teams__empty-option">
            {{ $t('employees.add_employee_modal.affiliations.user_license_table.fields.teams.no_available_team') }}
          </div>
        </template>
        <template #selected-option="{ value }">
          {{ formattedTeamName(value) }}
        </template>
        <template #option="{ option }">
          <div v-tooltip="teamOptionTooltip(option)">
            {{ option.text }}
          </div>
        </template>
      </SkSelect>
    </td>
    <td
      v-else
      class="node-row__shops-count"
    >
      <SkSelect
        v-model="inPlanningShopNodes"
        class="select-view-planning"
        :ajax-client="ajaxClient"
        :ajax-format-response="inPlanningAjaxFormatResponse"
        :ajax-format-params="inPlanningAjaxFormatParams"
        :label="nodeSelectLabel"
        :disabled="!canEditNodes"
        :tracking-options="trackingOptions"
        :search-placeholder="$t('labels.search')"
        object-value
        ajax-url="/v3/api/cluster_nodes"
        select-all
        multi
        paginated
      >
        <template #empty-option>
          {{ $t('employees.add_employee_modal.affiliations.no_search_results') }}
        </template>
        <template #selected-option="slotProps">
          {{ slotProps.value.length }}
          <!-- eslint-disable-next-line  max-len -->
          {{ $t('employees.tabs.personal_data.assignment.assignment_card.fields.employee_assignment_append') }}
        </template>
        <template #select-all-label>
          {{ $t('employees.add_employee_modal.affiliations.select_all_shops') }}
        </template>
        <template #option="slotProps">
          <div
            class="nodes-list__item"
          >
            <SkMedallion
              icon="ShopIcon"
              background-color="#d9e6ff"
              class="nodes-list__item-icon"
              color="#2b66fe"
            />
            <div
              v-if="isPrimaryShop(slotProps.option)"
              class="nodes-list__item-thumbtack"
            >
              <ThumbtackV2Icon
                width="12"
                height="12"
                fill=""
                class="nodes-list__item-thumbtack__fill"
              />
            </div>
            <span>
              {{ slotProps.option.text }}
            </span>
          </div>
        </template>
      </SkSelect>
    </td>
    <td
      v-if="!isClusterType && showOptiCell"
      class="node-row__postes"
    >
      <CompetenciesInput
        v-if="canAccessAutomaticPlanning"
        v-model="competencies"
        :shop-id="Number(node.attributes.shopId)"
        :size="skSelectSize"
        :disabled="!canEditTeamsOrPostes"
      />
    </td>
    <td class="text-right pr-0">
      <SkCircleButton
        v-track="'add_employee_remove_shop'"
        :disabled="!canEditNode || isPrimaryNode || isSelectedUserSystemAdmin || currentUserSelfEdit"
        icon="CrossV2Icon"
        size="small"
        @click="deleteRow"
      />
    </td>
  </tr>
</template>

<script>
import orderBy from 'lodash/orderBy';
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import { LICENSE_COLORS } from '@app-js/shared/constants/colors';
import { FEATURES } from '@app-js/shared/constants/features';
import { httpClient } from '@skello-utils/clients';
import { associationMatcherCollection } from '@skello-utils/association_matchers';

import CompetenciesInput from './CompetenciesInput';

export default {
  name: 'NodeRow',
  components: { CompetenciesInput },
  props: {
    node: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    shopsCount: {
      type: Number,
      default: null,
    },
    licenseOnClusterId: {
      type: String,
      default: null,
    },
    showOrganisationRow: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    showOptiCell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ajaxClient: () => httpClient,
      teamWithScheduleIds: [],
      selectedShopsInNetwork: [],
    };
  },
  computed: {
    ...mapMutations('selectedEmployee', ['updatePrimaryNode']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['currentUser', 'currentUserUserLicenses', 'organisationLicenses']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('amendments', ['cyclicAmendments']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo', 'isSystemAdmin']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('selectedEmployee', [
      'activeUserLicenses',
      'unsavedChangesToEmployeeLicense',
      'activeNodes',
      'isSystemAdmin',
      'primaryShopNode',
      'hasTeamWithScheduleSelectedFor',
      'isStrictSubordinateOfCurrentUser',
    ]),
    ...mapGetters('currentShop', ['isVariableContractHoursAvailable']),
    ...mapGetters({
      isNewEmployeeSystemAdmin: 'newEmployee/isSystemAdmin',
    }),
    trackingOptions() {
      return {
        openChoices: 'in_planning_select',
        submit: 'in_planning_select_validate',
        cancel: 'in_planning_select_cancel',
      };
    },
    nodeSelectLabel() {
      return this.$t('employees.tabs.personal_data.assignment.assignment_card.fields.employee_assignment');
    },
    canEditNodes() {
      if (this.isCurrentUserSystemAdmin) return true;
      if (!this.canEditEmployeeInfo) return false;

      return this.isNewEmployee || this.isStrictSubordinateOfCurrentUser;
    },
    inPlanningShopNodes: {
      get() {
        return this.user.relationships.memberships
          .filter(membership => !membership._destroy &&
            membership.attributes.inPlanning &&
            this.node.attributes.descendantIds.includes(membership.attributes.clusterNodeId),
          ).map(membership => ({
            id: membership.attributes.clusterNodeId.toString(),
            text: membership.attributes.shopName,
            attributes: membership.attributes,
          }));
      },
      set(selectedNodes) {
        if (this.isNewEmployee) {
          this.manageNewEmployeeInPlanningShopNodeMemberships(
            { clusterNode: this.node, selectedNodes },
          );
        } else {
          this.manageInPlanningShopNodeMemberships(
            { clusterNode: this.node, selectedNodes },
          );
        }
      },
    },
    localLicense: {
      get() {
        if ((!this.isNewEmployee && this.isSystemAdmin) ||
          (this.isNewEmployee && this.isNewEmployeeSystemAdmin)) {
          const systemAdminUserLicense = this.user.relationships.userLicenses[0];

          return {
            id: systemAdminUserLicense.attributes.licenseId,
            text: systemAdminUserLicense.attributes.licenseName,
            attributes: {
              position: systemAdminUserLicense.attributes.licensePosition,
            },
          };
        }

        return {
          id: this.license.attributes.licenseId,
          text: this.license.attributes.licenseName,
          attributes: {
            position: this.license.attributes.licensePosition,
          },
        };
      },

      set(newLicense) {
        if (newLicense.attributes.position === this.localLicense.attributes.position) return;

        if (this.localLicense.attributes.position === 0 && newLicense.attributes.position !== 0) {
          this.handleDemoteFromSytemAdmin(newLicense);
          // eslint-disable-next-line max-len
        } else if (this.localLicense.attributes.positition !== 0 && newLicense.attributes.position === 0) {
          this.handlePromoteToSystemAdmin(newLicense);
        } else {
          this.handleUpdateUserLicense(newLicense);
        }

        if (this.isPrimaryNode && !this.isNewEmployee) {
          this.setEmployeeAttributes({
            currentLicensePosition: newLicense.attributes.position,
            currentLicenseType: newLicense.attributes.originalType,
            currentLicenseName: newLicense.text,
          });
        }
      },
    },
    competencies: {
      get() {
        const competencies = this.user.relationships.automaticPlanningCompetencies;
        const shopId = Number(this.node.attributes.shopId);

        return competencies
          .filter(competency => competency.attributes.shopId === Number(shopId))
          .map(competency => (
            {
              primary: competency.attributes.primary,
              id: competency.attributes.posteId,
            }
          ));
      },
      set(selectedPostes) {
        const shopId = Number(this.node.attributes.shopId);

        if (this.isNewEmployee) {
          this.manageNewEmployeeCompetencies({ shopId, selectedPostes });
        } else {
          this.manageCompetencies({ shopId, selectedPostes });
        }
      },
    },
    isEmployeeInPlanning: {
      get() {
        if (!this.membership) return false;

        return this.membership.attributes.inPlanning;
      },

      set(newValue) {
        if (this.isNewEmployee) {
          this.membership.attributes.inPlanning = newValue;
        } else {
          this.updateMembership({ node: this.node, inPlanning: newValue });
        }
      },
    },
    localTeams: {
      get() {
        return this.teamMemberships.map(teamMembership => (
          {
            id: teamMembership.attributes.teamId.toString(),
            text: teamMembership.attributes.name,
          }
        ));
      },

      set(selectedTeams) {
        const { shopId } = this.node.attributes;

        if (this.isVariableContractHoursAvailable) {
          // since each shop has a different NodeRow component and we need to share the information
          // of whether or not the user already has a team schedule selected: we use
          // isTeamWithScheduleSelected data in selectedEmployee store to share the information
          // between all NodeRows
          // ==> if a team with schedule is selected (or unselected), we want to disable (resp. enable)
          // other teams with schedule for all shops
          const selectedTeamsWithSchedule =
            selectedTeams.filter(team => this.teamWithScheduleIds.includes(team.id));

          if (selectedTeamsWithSchedule.length > 1) {
            /* eslint-disable max-len */
            this.$skToast({
              message: this.$t('employees.tabs.personal_data.membership.team_assignment.variable_hours.max_one_team_with_schedule'),
              variant: 'error',
            });
            /* eslint-enable max-len */
            return;
          }

          const wasEmployeeInTeamWithSchedule = this.teamMemberships.some(teamMembership => (
            this.teamWithScheduleIds.includes(teamMembership.attributes.teamId.toString())
          ));

          if (selectedTeamsWithSchedule.some(team => this.teamWithScheduleIds.includes(team.id))) {
            if (!wasEmployeeInTeamWithSchedule) {
              // if selected teams include team with schedule and didn't previously
              // => indicate that team with schedule has been selected for other shop NodeRows
              this.setEmployeeWithTeamScheduleSelected({
                employeeId: this.employeeId,
                value: true,
              });
            }
          } else if (wasEmployeeInTeamWithSchedule) {
            // if selected teams don't include team with schedule and did previously
            // => indicate that team with schedule is no longer selected for other shop NodeRows
            this.setEmployeeWithTeamScheduleSelected({
              employeeId: this.employeeId,
              value: false,
            });
          }
        }

        if (this.isNewEmployee) {
          this.manageNewEmployeeTeamMemberships({ shopId, selectedTeams });
        } else {
          this.manageTeamMemberships({ shopId, selectedTeams });
        }
      },
    },
    nodeRowNameClasses() {
      return {
        'node-row__name': true,
        'node-row__name--small': this.appendToBody,
        'node-row__name--x-small': this.isSelectXSmall,
      };
    },
    nodeRowLicenseClasses() {
      return {
        'node-row__license': true,
        'node-row__license--small': this.appendToBody,
        'node-row__license--x-small': this.isSelectXSmall,
      };
    },
    nodeRowTeamsClasses() {
      return {
        'node-row__teams': true,
        'node-row__teams--x-small': this.isSelectXSmall,
      };
    },
    skSelectWidthClasses() {
      return {
        'sk-select__selected-option--x-small': this.isSelectXSmall,
      };
    },
    isSelectXSmall() {
      return this.showOptiCell && this.isNewEmployee;
    },
    skSelectSize() {
      return this.isNewEmployee && this.canAccessAutomaticPlanning ?
        'x-small' : null;
    },
    currentUserSelfEdit() {
      return this.user.id === this.currentUser.id;
    },
    handleNodeTypeTooltip() {
      return !this.isClusterType ?
        this.$t('employees.tabs.personal_data.membership.shop_assignment.tooltip') :
        this.$t('employees.tabs.personal_data.membership.node_assignment.tooltip');
    },
    isClusterType() {
      return this.type === 'cluster';
    },
    clustersOrganisation() {
      return this.currentOrganisation.attributes.clusters;
    },
    fetchTeamsUrl() {
      return `/v3/api/teams?shop_id=${this.node.attributes.shopId}`;
    },
    fetchLicensesUrl() {
      return `/v3/api/licenses?cluster_node_id=${this.node.attributes.clusterNodeId}`;
    },
    isPrimaryNode() {
      if (String(this.node.attributes.shopId) === String(this.user.attributes.shopId)) return true;

      if (this.showOrganisationRow) return true;

      if (!this.membership && !this.isClusterType) return false;

      if (!this.node.attributes.descendantIds) return false;

      if (!this.primaryShopNode) return false;

      return this.node.attributes.descendantIds.includes(Number(this.primaryShopNode.id)) ||
        this.licenseOnClusterId === this.node.attributes.clusterNodeId ||
        this.node.attributes.descendantIds.includes(Number(this.licenseOnClusterId));
    },
    isNewEmployee() {
      return !this.user.id;
    },
    employeeId() {
      return this.isNewEmployee ? -1 : this.user.id;
    },
    canAccessAutomaticPlanning() {
      const { clusterNodeId } = this.node.attributes;
      const clusterNode = this.activeNodes.find(node => Number(node.id) === Number(clusterNodeId));
      return clusterNode.attributes.canAccessAutomaticPlanning;
    },
    isSelectedUserSystemAdmin() {
      if (this.isNewEmployee) return false;

      return this.isSystemAdmin;
    },
    isCurrentUserSystemAdmin() {
      return this.currentUserUserLicenseOnThisRow.attributes.position === 0;
    },
    gotHigherLicenseOnTree() {
      return this.currentUserUserLicenseOnThisRow.attributes.depth < this.node.attributes.depth;
    },
    canEditTeamsOrPostes() {
      if (this.currentUser.attributes.superAdmin || this.isNewEmployee) { return true; }
      // return false if currentUser has no license applicable for this clusterNode
      if (!this.currentUserUserLicenseOnThisRow) { return false; }

      if (this.currentUserSelfEdit &&
        this.organisationLicenses.find(
          license => license.id === String(
            this.currentUserUserLicenseOnThisRow.attributes.licenseId,
          ),
        ).attributes.canEditEmployeeInfo) { return true; }

      if (this.isCurrentUserSystemAdmin) { return true; }
      // return false if currentuser license doesn't allow to edit license
      if (
        !this.organisationLicenses.find(
          license => license.id === String(
            this.currentUserUserLicenseOnThisRow.attributes.licenseId,
          ),
        ).attributes.canEditEmployeeInfo
      ) { return false; }
      // return false if user can only see him/her-self on this node
      if (
        this.organisationLicenses.find(
          license => license.id === String(
            this.currentUserUserLicenseOnThisRow.attributes.licenseId,
          ),
        ).attributes.canReadSelfOnly
      ) { return false; }
      // return true if currentUser has a license higher in tree
      if (this.gotHigherLicenseOnTree) { return true; }

      // return false if employee userLicense is higher in tree than currentuser
      if (
        this.currentUserUserLicenseOnThisRow.attributes.depth >
        this.node.attributes.depth
      ) { return false; }
      // compare currentUser License with employee license on this node
      return (
        this.currentUserUserLicenseOnThisRow.attributes.position <
        this.license.attributes.licensePosition
      );
    },
    canEditNode() {
      if (this.currentUser.attributes.superAdmin) { return true; }
      if (this.isNewEmployee) { return true; }
      // return false if currentUser has no license applicable for this clusterNode
      if (!this.currentUserUserLicenseOnThisRow) { return false; }
      if (this.currentUserSelfEdit &&
        this.organisationLicenses.find(
          license => license.id === String(
            this.currentUserUserLicenseOnThisRow.attributes.licenseId,
          ),
        ).attributes.canEditEmployeeInfo) { return true; }
      if (this.isCurrentUserSystemAdmin) { return true; }
      // return false if currentuser license doesn't allow to edit license
      if (
        !this.organisationLicenses.find(
          license => license.id === String(
            this.currentUserUserLicenseOnThisRow.attributes.licenseId,
          ),
        ).attributes.canEditEmployeeInfo
      ) { return false; }
      // return true if currentUser has a license higher in tree
      if (this.gotHigherLicenseOnTree) { return true; }
      // return false if employee userLicense is higher in tree than currentuser
      if (
        this.currentUserUserLicenseOnThisRow.attributes.depth >
        this.node.attributes.depth
      ) { return false; }
      // compare currentUser License with employee license on this node
      return (
        this.currentUserUserLicenseOnThisRow.attributes.position <
        this.license.attributes.licensePosition
      );
    },
    currentUserUserLicenseOnThisRow() {
      let currentUserUserLicense = this.currentUserUserLicenses.find(
        userLicense => String(userLicense.attributes.clusterNodeId) ===
          String(this.node.attributes.clusterNodeId),
      );
      if (currentUserUserLicense) { return currentUserUserLicense; }
      currentUserUserLicense = this.currentUserUserLicenses.find(
        userLicense => userLicense.attributes.descendantIds.includes(
          Number(this.node.attributes.clusterNodeId),
        ),
      );

      return currentUserUserLicense;
    },
    isSelectLicensesDisabled() {
      if (this.isNewEmployee) {
        return this.isNewEmployeeSystemAdmin && !this.isPrimaryNode;
      }

      if (this.unsavedChangesToEmployeeLicense) return false;

      if (!this.canEditNode) return true;

      return this.isSystemAdmin && !this.isPrimaryNode;
    },
    activeMemberships() {
      return this.user.relationships.memberships.filter(membership => (
        [undefined, false, null].includes(membership._destroy)
      ));
    },
    membership() {
      return this.activeMemberships.find(membership => (
        String(membership.attributes.shopId) === String(this.node.attributes.shopId)
      ));
    },
    teamMemberships() {
      return this.user.relationships.teamMemberships.filter(
        teamMembership => teamMembership._destroy !== true &&
          Number(teamMembership.attributes.shopId) === Number(this.node.attributes.shopId),
      );
    },
    license() {
      return this.user.relationships.userLicenses.find(userLicense => (
        userLicense.attributes.clusterNodeId === this.node.attributes.clusterNodeId
      ));
    },
    teamSelectTrackingOptions() {
      return {
        openChoices: this.isNewEmployee ? 'add_employee_team' : 'team_select',
        submit: this.isNewEmployee ? 'add_employee_validate_team' : 'team_select_validate',
        cancel: this.isNewEmployee ? 'add_employee_cancel_team' : 'team_select_validate',
      };
    },
    disabledTeamIds() {
      // If team with schedule is already selected -> disable all other teams with schedule
      if (this.hasTeamWithScheduleSelectedFor(this.employeeId)) {
        // Remove employee teams from list of disabled
        return this.teamWithScheduleIds
          .filter(teamId => (
            !this.teamMemberships.some(membership => (
              membership.attributes.teamId.toString() === teamId
            ))
          ));
      }

      return [];
    },
    searchPlaceholder() {
      return this.$t('labels.search');
    },
  },
  methods: {
    ...mapMutations('newEmployee', [
      'manageNewEmployeeInPlanningShopNodeMemberships',
      'manageNewEmployeeTeamMemberships',
      'updateNewEmployeeUserLicense',
      'manageNewEmployeeCompetencies',
      'promoteNewEmployeeToSystemAdmin',
      'demoteNewEmployeeFromSystemAdmin',
    ]),
    ...mapMutations('selectedEmployee', [
      'updateExistingUserLicense',
      'updateMembership',
      'manageTeamMemberships',
      'manageInPlanningShopNodeMemberships',
      'manageCompetencies',
      'promoteToSystemAdmin',
      'demoteFromSystemAdmin',
      'setEmployeeAttributes',
      'setEmployeeWithTeamScheduleSelected',
    ]),
    isPrimaryShop(option) {
      return Number(option.attributes.shopId) === Number(this.employee.attributes.shopId);
    },
    inPlanningAjaxFormatResponse(response) {
      return orderBy(response.data.map(clusterNode => ({
        id: clusterNode.id.toString(),
        text: clusterNode.attributes.name,
        attributes: {
          shopCancelled: clusterNode.attributes.shopCancelled,
          clusterNodeId: clusterNode.id.toString(),
          shopId: clusterNode.attributes.shopId,
          shopName: clusterNode.attributes.shopName,
        },
      })), 'attributes.name');
    },
    nodeHoverTooltip(node) {
      if (node.attributes.shopCancelled) {
        return this.$t('organisation_settings.shop_cancelled');
      }
      return null;
    },
    inPlanningAjaxFormatParams(params) {
      params.root_child = false;
      params.parent_id = this.node.attributes.clusterNodeId;
      params.intermediate_node = true;
      params.shop_level = true;
      params.node_row_nodes = true;

      return { ...params };
    },
    handlePromoteToSystemAdmin(newLicense) {
      if (this.isNewEmployee) {
        this.promoteNewEmployeeToSystemAdmin({
          newLicense,
          inClusters: this.clustersOrganisation,
          rootNodeId: this.currentOrganisation.attributes.rootNodeId,
        });
      } else {
        this.promoteToSystemAdmin({
          newLicense,
          nodes: this.activeNodes,
          currentOrganisation: this.currentOrganisation,
        });
      }
    },
    handleDemoteFromSytemAdmin(newLicense) {
      if (this.isNewEmployee) {
        this.demoteNewEmployeeFromSystemAdmin({ newLicense });
      } else if (!this.isClusterType) {
        this.demoteFromSystemAdmin({ newLicense, nodes: this.activeNodes });
      } else {
        this.handleUpdateUserLicense(newLicense);
      }
    },
    handleUpdateUserLicense(newLicense) {
      const { clusterNodeId } = this.node.attributes;

      if (this.isNewEmployee) {
        this.updateNewEmployeeUserLicense({ clusterNodeId, newLicense });
      } else {
        this.updateExistingUserLicense({ clusterNodeId, newLicense });
      }
    },
    deleteRow() {
      this.$emit('delete-row', this.node);
    },
    formattedTeamName(value) {
      if (value.length === 0) {
        const label = this.isNewEmployee ? 'no_team_selected_short' : 'no_team_selected';
        return this.$t(`employees.tabs.personal_data.assignment.assignment_card.fields.teams.${label}`);
      }
      if (value.length === 1) return value[0].text;
      return this.$t('employees.tabs.personal_data.assignment.assignment_card.fields.teams.multiple_teams', { count: value.length });
    },
    selectedLicense(option) {
      if (this.license) {
        return Number(option.id) === Number(this.license.attributes.licenseId);
      }

      return option.attributes.position === 0;
    },
    positionBackgroundColor(option) {
      return LICENSE_COLORS[option.attributes.originalType].backgroundColor;
    },
    positionContentTextColor(option) {
      return LICENSE_COLORS[option.attributes.originalType].color;
    },
    teamsAjaxFormatParams(params) {
      return {
        shop_id: this.node.attributes.shopId,
        with_team_schedules: this.isVariableContractHoursAvailable,
        ...params,
      };
    },
    teamsAjaxFormatResponse(response) {
      if (this.isVariableContractHoursAvailable) {
        this.teamWithScheduleIds = response.included
          .reduce((teamIds, currentIncluded) => {
            if (currentIncluded.type === 'teamSchedule') {
              const teamId = currentIncluded.attributes.teamId.toString();
              if (!this.teamWithScheduleIds.includes(teamId)) {
                teamIds.push(teamId);
              }
            }
            return teamIds;
          }, this.teamWithScheduleIds);
      }

      return response.data.map(team => {
        let teamSchedules = null;
        if (this.isVariableContractHoursAvailable) {
          teamSchedules =
            associationMatcherCollection(team, response.included, { key: 'teamSchedules', type: 'teamSchedule' });
        }

        return {
          id: team.id,
          text: team.attributes.name,
          attributes: {
            shopId: team.attributes.shopId,
            teamSchedules,
          },
        };
      });
    },
    licenseAjaxFormatParams(params) {
      return {
        organisation_id: this.currentOrganisation.id,
        ...params,
      };
    },
    licenseAjaxFormatResponse(response) {
      const licenses = response.data.map(license => (
        {
          id: license.id,
          text: license.attributes.name,
          attributes: {
            position: license.attributes.position,
            originalType: license.attributes.originalType,
          },
        }
      ));

      let filteredLicenses;

      if (this.showOrganisationRow) {
        filteredLicenses = licenses;
      } else if (this.gotHigherLicenseOnTree &&
        this.clustersOrganisation) {
        filteredLicenses = licenses.filter(
          license => license.attributes.position !== 0,
        );
      } else if (this.clustersOrganisation) {
        filteredLicenses = licenses.filter(
          license => this.currentUserUserLicenseOnThisRow.attributes.position <=
            license.attributes.position &&
            license.attributes.position !== 0,
        );
      } else {
        filteredLicenses = licenses.filter(
          license => this.currentUserUserLicenseOnThisRow.attributes.position <=
            license.attributes.position,
        );
      }

      // Check licenses dependencies as in LicensesAndActions component
      const licenseDependencies = {
        system_admin: null,
        advanced_master: null,
        standard_employee: null,
        standard_master: FEATURES.FEATURE_PLANNING_ACCESS,
        advanced_planner: FEATURES.FEATURE_PLANNING_ACCESS,
        standard_planner: FEATURES.FEATURE_PLANNING_ACCESS,
        advanced_read_only: FEATURES.FEATURE_PLANNING_ACCESS,
        standard_read_only: FEATURES.FEATURE_PLANNING_ACCESS,
        advanced_employee: FEATURES.FEATURE_PLANNING_ACCESS,
      };

      filteredLicenses = filteredLicenses.filter(license => {
        const licenseType = license.attributes.originalType;
        return !licenseDependencies[licenseType] ||
          this.isFeatureEnabled(licenseDependencies[licenseType], 'all');
      },
      );

      return orderBy(filteredLicenses, 'attributes.position');
    },
    teamOptionTooltip(team) {
      return this.disabledTeamIds.includes(team.id) ?
        this.$t('employees.tabs.personal_data.membership.team_assignment.variable_hours.max_one_team_with_schedule') :
        '';
    },
  },
};

</script>

<style lang="scss" scoped>
.node-row__name {
  width: 320px;

  &--small {
    width: 200px;
  }
}

.node-row__license {
  min-width: 240px;
  padding-right: 20px;

  &--small {
    width: 250px;
  }

  &--x-small {
    text-overflow: ellipsis;
    min-width: 128px;
  }
}

.node-row__teams {
  padding-right: 20px;
  min-width: 240px;

  .sk-dropdown__menu {
    width: 200px;
  }

  &--x-small {
    text-overflow: ellipsis;
    min-width: 128px;
  }
}

// Using width props on SkSelect puts witdh on every element
// We just need to force width on selected-options
::v-deep .sk-select__selected-option--x-small {
  width: 128px !important;
}

.node-row__postes {
  padding-right: 13px;
}

.node-row__teams__empty-option {
  min-width: 137px;
}

.node-row__empty {
  width: 70px;
}

.node-row__planning {
  min-width: 86px;
}

.node-row__shops-count {
  width: 262px;
}

.node-row__license-menu {
  width: 280px;
}

.node-row__license-medallion {
  margin-right: 15px;
}

.check-mark-icon {
  fill: $sk-blue;
  margin-right: 15px;
}

.node-row__license__list-checked-icon {
  width: 35px;
}

.select-view-planning {
  width: 262px !important;
}

// Need to remove display flex for text ellipsis
::v-deep .selected-option-name {
  display: initial;
  overflow: visible;
}

.node-item {
  display: flex;
  position: relative;
  align-items: center;

  .node-item__icon {
    margin-right: 15px;
  }

  .node-item__thumbtack {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $sk-white;
    background-color: $sk-blue-5;
  }

  .shops-list__item-thumbtack__fill {
    fill: $sk-blue;
  }

  .node-item__name {
    font-weight: bold;
  }
}

.sk-select__affiliated-shop__title {
  display: flex;
  align-items: center;
  position: relative;

  .sk-select__affiliated-shop__title__icon {
    margin-right: 15px;
  }

  .sk-select__affiliated-shop__thumbtack {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $sk-white;
    background-color: $sk-blue-5;
  }

  .sk-select__affiliated-shop-thumbtack__fill {
    fill: $sk-blue;
  }
}
</style>
