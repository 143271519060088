<template>
  <div>
    <div class="employee-secondary-shop__section">
      <SkForm split>
        <template #title>
          <div>
            <b>
              {{ $t('employees.tabs.personal_data.membership.shop_assignment.secondary_title') }}
            </b>
          </div>
          <label class="sk-form-section__secondary-shop-description">
            {{ $t('employees.tabs.personal_data.membership.shop_assignment.secondary_subtitle') }}
          </label>
        </template>
        <template>
          <ClusterAffiliationSelect
            ref="clusterNodeSelect"
            v-model="localActiveNodes"
            :primary-node="primaryShopNode"
            :disabled="!canEditNodes"
            :label="$t('employees.tabs.personal_data.cluster_assignment.title')"
            :user="employee"
            @new-primary-node-selected="setNewPrimaryNodeId"
          />
        </template>
      </SkForm>
    </div>
    <div>
      <SkTable
        v-if="clusterNodeSelected"
        :columns="clusterNodeHeader"
        class="add-employee-modal__affiliation-table"
      >
        <NodeRow
          v-for="node in activeClusterNodes"
          :key="node.id"
          :node="node"
          :user="employee"
          :license-on-cluster-id="licenseOnClusterId"
          :show-organisation-row="showOrganisationRow"
          :shops-count="shopsCount(node)"
          type="cluster"
          @delete-row="handleDelete"
        />
      </SkTable>
      <SkTable
        v-if="shopNodeSelected"
        :columns="nodeHeader"
        class="employee-nodes-table"
      >
        <NodeRow
          v-for="node in sortedActiveNodesThroughUserLicenses"
          :key="node.id"
          :node="node"
          :user="employee"
          :show-opti-cell="isAutomaticPlanningEnabled"
          type="shop"
          @delete-row="handleDelete"
        />
      </SkTable>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import ClusterAffiliationSelect from '@app-js/shared/components/ClusterAffiliationSelect';

import NodeRow from '../../shared/components/NodeRow';

export default {
  name: 'SecondaryClusterNodeSection',
  components: { ClusterAffiliationSelect, NodeRow },
  data() {
    return {
      licenseOnClusterId: null,
      allClustersSelected: false,
      clusterNodeHeader: [
        { title: this.$t('employees.tabs.personal_data.cluster_assignment.cluster_node') },
        { title: this.$t('employees.tabs.personal_data.cluster_assignment.header.license') },
        { title: this.$t('employees.tabs.personal_data.cluster_assignment.header.in_plannings') },
        { title: null },
      ],
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee', 'memberships', 'currentUserVisibleNodes']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters({ isCurrentUserSystemAdmin: 'currentLicense/isSystemAdmin' }),
    ...mapGetters('selectedEmployee', [
      'activeUserLicenses',
      'isSystemAdmin',
      'isStrictSubordinateOfCurrentUser',
      'activeMemberships',
      'activeNodes',
    ]),
    nodeHeader() {
      const columns = [
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.shop') },
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.license') },
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.in_planning') },
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.teams') },
      ];

      if (this.isAutomaticPlanningEnabled) {
        columns.push(
          {
            title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.title'),
            icon: {
              icon: 'CircledQuestionMarkIcon',
              tooltip: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.tooltip'),
            },
          },
        );
      }

      columns.push({ title: null }); // Closing x column

      return columns;
    },
    isAutomaticPlanningEnabled() {
      const activeNodeIds =
        this.activeUserLicenses.map(license => Number(license.attributes.clusterNodeId));
      const activeNodes = this.currentUserVisibleNodes.filter(
        node => activeNodeIds.includes(Number(node.id)),
      );

      return !!activeNodes.find(node => node.attributes.canAccessAutomaticPlanning);
    },
    showOrganisationRow() {
      return (this.isCurrentUserSystemAdmin && this.allClustersSelected) ||
        (String(this.currentOrganisation.attributes.rootNodeId) ===
          String(this.activeUserLicenses[0].attributes.clusterNodeId));
    },
    activeClusterNodes() {
      const clusterNodes = this.activeUserLicenses.filter(
        clusterNode => clusterNode.attributes.shopId === null,
      );

      return orderBy(clusterNodes, 'attributes.name');
    },
    activeShopNodes() {
      const shopNodes = this.activeUserLicenses.filter(
        clusterNode => clusterNode.attributes.shopId !== null,
      );

      return orderBy(shopNodes, 'attributes.name');
    },
    sortedActiveNodesThroughUserLicenses() {
      const primaryShopId = this.employee.attributes.shopId;

      return sortBy(this.activeShopNodes, license => (
        Number(license.attributes.shopId) === Number(primaryShopId) ? 0 : 1
      ));
    },
    clusterNodeSelected() {
      return this.activeClusterNodes.length > 0;
    },
    shopNodeSelected() {
      return this.activeShopNodes.length > 0;
    },
    canEditNodes() {
      if (!this.canEditEmployeeInfo || this.isSystemAdmin) return false;

      return this.isStrictSubordinateOfCurrentUser;
    },
    localActiveNodes: {
      get() {
        const nodes = this.activeUserLicenses.map(activeNode => ({
          id: String(activeNode.attributes.clusterNodeId),
          text: activeNode.attributes.name,
          attributes: {
            name: activeNode.attributes.name,
            shopId: activeNode.attributes.shopId ? String(activeNode.attributes.shopId) : null,
            parentId: String(activeNode.attributes.parentId),
            depth: activeNode.attributes.depth,
            ancestry: activeNode.attributes.ancestry,
            ancestorIds: activeNode.attributes.ancestorIds,
            descendantIds: activeNode.attributes.descendantIds,
          },
        }));

        return nodes;
      },
      set(options) {
        let selectedNodes = options.values.map(node => ({
          id: node.id,
          attributes: {
            shopId: node.attributes.shopId ? String(node.attributes.shopId) : null,
            name: node.attributes.name,
            parentId: String(node.attributes.parentId),
            depth: node.attributes.depth,
            ancestry: node.attributes.ancestry,
            descendantIds: node.attributes.descendantIds,
            ancestorIds: node.attributes.ancestorIds,
          },
        }));

        this.allClustersSelected = options.allChecked;

        if (this.isCurrentUserSystemAdmin && this.allClustersSelected) {
          selectedNodes = [{
            id: String(this.currentOrganisation.attributes.rootNodeId),
            type: 'organisationNode',
            attributes: {
              name: this.currentOrganisation.attributes.name,
              shopId: null,
              descendantIds: options.values.map(node => node.attributes.descendantIds).flat(),
            },
          }];
        }

        this.manageNodes({ selectedNodes });
      },
    },
    primaryShopNode() {
      const employeeShopId = this.employee.attributes.shopId;

      const primary = this.activeNodes.find(node => (
        String(node.attributes.shopId) === String(employeeShopId)
      ));

      if (!primary) return null;
      return primary;
    },
    trackingOptions() {
      return {
        openChoices: 'shop_select',
        submit: 'shop_select_validate',
        cancel: 'shop_select_cancel',
      };
    },
  },
  methods: {
    ...mapMutations('selectedEmployee', ['manageNodes']),
    handleDelete(nodeToDelete) {
      this.manageNodes({
        selectedNodes: this.localActiveNodes.filter(node => (
          node.id !== nodeToDelete.attributes.clusterNodeId
        )),
      });
    },
    shopsCount(node) {
      return this.activeNodes.filter(option => option.attributes.shopId !== null &&
        !option.attributes.shopCancelled &&
        node.attributes.descendantIds.includes(Number(option.id)),
      ).length;
    },
    setNewPrimaryNodeId(id) {
      this.licenseOnClusterId = id;
    },
  },
};
</script>

<style lang="scss">
.employee-secondary-shop__section {
  padding-top: 40px;

  .sk-form__header__title {
    padding-top: 17px;
    color: $sk-grey;
  }

  .sk-form-body-section {
    padding-top: 20px;
  }
}

.nodes-list__item {
  display: flex;
  position: relative;
}

.nodes-list__item-icon {
  margin-right: 15px;
}

.nodes-list__item-thumbtack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $sk-white;
  background-color: $sk-blue-5;
}

.nodes-list__item-thumbtack__fill {
  fill: $sk-blue;
}

.sk-form-section__secondary-shop-description {
  width: 350px;
}

.employee-nodes-table {
  margin-top: 20px;

  th {
    padding: 0 !important;
  }

  th:nth-child(4),
  th:nth-child(5) {
    &:last-child {
      padding-left: 0 !important;
    }
  }
}
</style>
