<template>
  <div class="page--center">
    <div class="navbar">
      <a
        href="/"
        class="align-middle link--big"
      >
        <SkLogo />
      </a>
    </div>
    <div
      v-if="loading"
      class="account-creation__spinner-wrapper"
    >
      <SkLoader size="large" />
    </div>
    <div
      v-else-if="userCreation.prospect"
      class="content"
    >
      <UserForm
        v-if="isUserForm"
        :prospect="userCreation.prospect"
      />
      <CodeVerification
        v-else
        :prospect="userCreation.prospect"
      />
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';
import { SkLogo } from '@skelloapp/skello-ui';
import CodeVerification from './components/CodeVerification';
import UserForm from './components/UserForm';

export default {
  name: 'UserCreation',
  components: {
    UserForm,
    CodeVerification,
    SkLogo,
  },
  data() {
    return {
      steps: ['UserForm', 'CodeVerification'],
      prospect: null,
      loading: false,
    };
  },
  computed: {
    ...mapState('onboarding', ['userCreation']),

    currentStep() {
      return this.userCreation.userCreationCurrentStep;
    },
    currentStepComponent() {
      return this.steps[this.currentStep];
    },
    isUserForm() {
      return this.currentStepComponent === 'UserForm';
    },
  },
  created() {
    this.setToken(this.$route.query.token);

    this.loading = true;

    this.getProspect(this.$route.query.token)
      .then(prospect => {
        if (prospect.data.attributes.accountValidated) {
          this.$router.push({ name: 'Login', query: { account_validated: true } });
        }
      })
      .catch(() => {
        this.$skToast({
          message: this.$t('organisation_creation.error.generic'),
          variant: 'error',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapMutations('onboarding', ['setToken', 'setProspect', 'setUserCreationCurrentStep']),
    ...mapActions('onboarding', ['getProspect']),
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 80px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-size: $fs-text-m;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 2;
}

.account-creation__spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  color: $sk-blue;
  font-size: 2em;
}

.account-creation__spinner-wrapper,
.content {
  flex-grow: 1;
  z-index: 1;
}

.page--center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding-bottom: 30px;
}

.content {
  flex-grow: 1;
}

.link--big {
  height: 32px;
}
</style>
