<template>
  <div class="contract-sidebar__main">
    <div :class="contractSidebarClasses">
      <div>
        <!-- EDIT -->
        <SkCircleButton
          v-tooltip.right-end="editTooltip"
          :disabled="!canEditContract"
          :class="contractSidebarIconClasses"
          icon="PencilV2Icon"
          data-test="modify_on_current_contract"
          @click="displayEditMode"
        />

        <!-- DUPLICATE -->
        <SkPopover
          v-if="!isUpgradeMultiContractsEnabled"
          v-tooltip.right-end="duplicateTooltipText"
          :disabled="isMulticontractsEnabled"
          class="upsell-popover__wrapper"
          placement="left-start"
          as-tooltip
        >
          <template #anchor>
            <SkCircleButton
              v-tooltip.right-end="$t(
                'employees.tabs.contracts.actions.duplicate.tooltips.enabled'
              )"
              v-track="'multicontract_add_amendment_current_contract'"
              :disabled="isDuplicateButtonDisabled"
              :class="contractSidebarIconClasses"
              icon="DuplicateV2Icon"
              @click="openDuplicateContractModal"
            />
          </template>
          <template #content>
            {{ $t('employees.pack_offers_upsell.tooltip.introduction') }}
            <PackTag :type="packType" />
            {{ $t('employees.tabs.contracts.actions.duplicate.tooltips.sentence_negation') }}
            <SkOroraButton @click="requestUpsell('duplicate')">
              {{
                $t('employees.pack_offers_upsell.tooltip.sentence_upsell',
                   { pack_name: $t('employees.pack_offers.premium') }
                )
              }}
            </SkOroraButton>
          </template>
        </SkPopover>

        <!-- APPLY TEMPLATE -->
        <SkPopover
          v-if="hasShopDocumentTemplatesAccess(employee.attributes.shopId)"
          v-tooltip.right-end="applyTemplateTooltipText"
          data-test="apply-template"
          :disabled="isMulticontractsEnabled"
          class="upsell-popover__wrapper"
          placement="left-start"
          as-tooltip
        >
          <template #anchor>
            <SkCircleButton
              v-tooltip.right-end="
                $t('employees.tabs.contracts.actions.apply_template.tooltips.enabled')
              "
              :class="contractSidebarIconClasses"
              class="align-on-circle"
              icon="ScrollWithPlusIcon"
              :disabled="!isMulticontractsEnabled || !isAllowedToApplyTemplate"
              @click="openTextDocumentModal"
            />
          </template>
          <template #content>
            {{ $t('employees.pack_offers_upsell.tooltip.introduction') }}
            <PackTag :type="packType" />
            {{ $t('employees.tabs.contracts.actions.apply_template.tooltips.sentence_negation') }}
            <SkOroraButton @click="requestUpsell('apply_template')">
              {{
                $t('employees.pack_offers_upsell.tooltip.sentence_upsell',
                   { pack_name: $t('employees.pack_offers.premium') }
                )
              }}
            </SkOroraButton>
          </template>
        </SkPopover>
      </div>
      <div v-if="isMulticontractsEnabled && !isActive && isAllowedToDeleteContract">
        <!-- DELETE -->
        <SkCircleButton
          v-tooltip.right-end="$t('employees.tabs.contracts.actions.delete_contract.tooltip')"
          :disabled="!isMulticontractsEnabled || !contract.attributes.startDate"
          :class="contractSidebarIconClasses"
          icon="TrashCanV2Icon"
          @click="handleDelete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features.js';
import PackTag from '@app-js/shared/components/PackTag';

export default {
  name: 'ContractSidebar',
  components: { PackTag },
  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapGetters('contracts', [
      'contractHasBothLimits',
      'isOver',
      'isActive',
      'isComingUp',
    ]),
    ...mapGetters('currentLicense', [
      'canManageEmployeeDocuments',
      'canReadEmployeePersonalInfosAndBankData',
      'canEditEmployeeInfo',
      'canEditEmployeePersonalInfosAndBankData',
    ]),
    ...mapState('currentOrganisation', ['currentOrganisation', 'upsellRequested']),
    ...mapState('contracts', ['contract', 'contractsCount']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrStrictSubordinate']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'hasShopDocumentTemplatesAccess']),
    ...mapGetters('features', ['isFeatureEnabled']),
    isMulticontractsEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_MULTI_CONTRACTS,
        this.employee.attributes.shopId,
      );
    },
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    isAllowedToApplyTemplate() {
      return this.canManageDocument && this.canReadSensitiveData;
    },
    isAllowedToDeleteContract() {
      return this.canEditContract && this.contractsCount > 1;
    },
    editTooltipText() {
      return this.canEditSensitiveData ?
        this.$t('employees.tabs.contracts.actions.edit.tooltips.enabled') :
        this.$t('employees.tabs.contracts.actions.edit.tooltips.disabled');
    },
    duplicateTooltipText() {
      if (!this.isMulticontractsEnabled) return '';

      if (!this.canEditSensitiveData) {
        return this.$t('employees.tabs.contracts.actions.unauthorized');
      }

      return !this.contractHasBothLimits() ?
        this.$t('employees.tabs.contracts.actions.duplicate.tooltips.limits_warning') :
        '';
    },
    applyTemplateTooltipText() {
      if (!this.isMulticontractsEnabled) return '';

      return !this.isAllowedToApplyTemplate ?
        this.$t('employees.tabs.contracts.actions.unauthorized') :
        '';
    },
    editTooltip() {
      return this.canEditContract ?
        this.$t('employees.tabs.contracts.actions.edit.tooltips.enabled') :
        this.$t('employees.tabs.contracts.actions.edit.tooltips.disabled');
    },
    contractSidebarClasses() {
      return {
        'contract-sidebar__wrapper': true,
        'contract-sidebar__wrapper--sticky': this.isSticky,
      };
    },
    isDuplicateButtonDisabled() {
      return !this.isMulticontractsEnabled || !this.contractHasBothLimits() ||
        !this.canEditSensitiveData;
    },
    canEditContract() {
      return this.canEditEmployeeInfo && this.isCurrentUserOrStrictSubordinate;
    },
    canManageDocument() {
      return this.canManageEmployeeDocuments && this.isCurrentUserOrStrictSubordinate;
    },
    canEditSensitiveData() {
      return this.canEditEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    canReadSensitiveData() {
      return this.canReadEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    isUpgradeMultiContractsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_UPGRADE_MULTICONTRACTS');
    },
  },
  created() {
    // fetchContracts here is needed when page is reloaded in browser
    // since count is mandatory to check if user isAllowedToDeleteContract
    if (this.isMulticontractsEnabled && !this.isActive && this.canEditContract &&
      this.contractsCount === 0) {
      this.fetchContracts({
        employeeId: this.employee.id,
        filter: this.$router.currentRoute.name,
      });
    }
  },
  methods: {
    ...mapMutations('contracts', [
      'setSelectedContract',
    ]),
    ...mapActions('currentOrganisation', ['requestPackUpsell']),
    ...mapActions('contracts', ['deleteContract', 'fetchContracts']),

    displayEditMode() {
      this.handleTracker('multicontract_modify');
      this.$router.push({ name: 'user_full_contract_edit', params: { contract_id: this.contract.id } });
    },
    openTextDocumentModal(event) {
      this.handleTracker('multicontract_apply_document_template');
      this.setSelectedContract(this.contract);
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-text-document-modal');
    },
    openDuplicateContractModal(event) {
      if (this.isUpgradeMultiContractsEnabled) return;

      this.handleTracker('multicontract_duplicate');
      this.setSelectedContract(this.contract);
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'duplicate-contract-modal');
    },
    handleDelete(event) {
      this.handleTracker('multicontract_delete');
      this.$root.$emit('confirm', event, {
        title: this.$t('employees.tabs.contracts.actions.delete_contract.label'),
        description: this.$t('employees.tabs.contracts.actions.delete_contract.description'),
        actionText: this.$t('employees.tabs.contracts.actions.delete_contract.action_text'),
        submitLabel: this.$t('employees.tabs.contracts.actions.delete_contract.label'),
        submitIcon: 'TrashCanIcon',
        variant: 'danger',
        icon: 'TrashCanIcon',
        iconColorSchema: { fill: '#d03e50', background: '#fce8e7' },
        // eslint-disable-next-line arrow-body-style
        onConfirmSuccess: () => {
          return this.deleteContract({ employeeId: this.employee.id, contractId: this.contract.id })
            .then(() => {
              if (this.isComingUp) {
                this.$router.push({ name: 'user_contracts_future' });
              } else {
                this.$router.push({ name: 'user_contracts' });
              }

              this.$skToast({
                message: this.$t('employees.tabs.contracts.actions.delete_contract.success'),
                variant: 'success',
                containerId: 'employees__container',
              });
            })
            .catch(() => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.actions.delete_contract.error'),
                variant: 'error',
              });
            });
        },
      });
    },
    contractSidebarIconClasses() {
      return {
        'contract-sidebar__icon--short': !this.isSticky,
        'contract-sidebar__icon--long': this.isSticky,
      };
    },
    requestUpsell(action) {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('employees.tabs.contracts.pack_offers.upsell_already_requested'),
          variant: 'success',
          containerId: 'employees__container',
        });
        return;
      }

      const params = {
        buttonClicked: this.$t(`employees.tabs.contracts.pack_offers.${action}`),
        currentPage: this.$t('employees.tabs.contracts.pack_offers.tab_name'),
        upsellType: this.$t('employees.tabs.contracts.pack_offers.action'),
        action: this.$t('employees.tabs.contracts.pack_offers.action'),
      };

      this.requestPackUpsell(params)
        .then(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.pack_offers.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    handleTracker(trackerName) {
      if (this.isOver) this.$skAnalytics.track(`${trackerName}_on_past_contract`);
      if (this.isComingUp) this.$skAnalytics.track(`${trackerName}_on_future_contract`);
      if (this.isActive) this.$skAnalytics.track(`${trackerName}_on_current_contract`);
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-sidebar__main {
  background: $sk-white;
  width: 61px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.contract-sidebar__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1; // needed for tooltip over header
  height: 100%;
  max-height: 400px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.contract-sidebar__wrapper--sticky {
  position: sticky;
  top: 0;
  padding-top: 80px;
}

.contract-sidebar__icon--long {
  margin-top: 10px;

  &:first-child {
    margin-top: 85px;
  }

  &:last-child {
    margin-top: 170px;
  }
}

.contract-sidebar__icon--short {
  &:first-child {
    margin-top: 10px;
  }
}

.upsell-popover__wrapper {
  font-size: $fs-text-m;
  line-height: 26px;
  font-weight: $fw-regular;

  .sk-button {
    margin-top: 12px;
    font-weight: $fw-regular;
  }
}

.align-on-circle {
  padding-top: 5px;
}
</style>
