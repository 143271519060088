<template>
  <div class="shift-activities__container">
    <div
      v-if="canDisplayTasks"
      class="shift-activities__section-container shift-activities__task-container"
    >
      <div class="shift-form__content-title shift-form__content-title__task">
        {{ $t('plannings.table.manage_shift_modal.tabs.shift.tasks.title') }}
      </div>
      <div class="shift-form__tasks-task__container">
        <TasksManager
          v-model="shift.attributes.tasks"
          :disabled="disabled"
        />
      </div>
    </div>
    <div
      v-if="!disabled"
      class="shift-activities__section-container"
    >
      <div class="shift-form__content-title">
        <div>
          {{
            $t(
              'plannings.table.manage_shift_modal.tabs.commun.other_actions.notes'
            )
          }}
        </div>
        <SkMedallion
          icon="ClipboardV2Icon"
          background-color="#d9e6ff"
          color="#2b66fe"
          size="small"
        />
      </div>
      <div
        v-if="!isTextToLinkEnabled || isFocused || !shift.attributes.note"
        class="shift-form__content-input"
      >
        <SkTextarea
          ref="textarea"
          v-model="shift.attributes.note"
          :label="
            $t(
              'plannings.table.manage_shift_modal.tabs.commun.other_actions.add_note'
            )
          "
          :min-height="54"
          rows="3"
          auto-grow
          data-test="shift-form__note"
          :disabled="disabled"
          @focus-changed="handleFocusChanged"
        />
      </div>
      <div
        v-else-if="isTextToLinkEnabled"
        class="shift-form__content-note-not-focused"
        @click="focusTextarea"
      >
        <div class="shift-form__content-note-not-focused__label">
          {{
            $t(
              'plannings.table.manage_shift_modal.tabs.commun.other_actions.add_note'
            )
          }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="shift-form__content-note-not-focused__content"
          v-html="formattedNote"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <div
      v-else-if="shift.attributes.note"
      class="shift-activities__section-container"
    >
      <div class="shift-form__content-title">
        <div>
          {{
            $t(
              'plannings.table.manage_shift_modal.tabs.commun.other_actions.notes'
            )
          }}
        </div>
        <SkMedallion
          icon="ClipboardV2Icon"
          background-color="#d9e6ff"
          color="#2b66fe"
          size="small"
        />
      </div>
      <div class="shift-form__content-input">
        <ReadOnlyNote :note="shift.attributes.note" />
      </div>
    </div>
    <div
      v-if="shouldDisplayComments(shift.attributes.comments, shift.attributes.shopId)"
      class="shift-activities__section-container"
    >
      <div class="shift-form__content-title">
        <div>
          {{
            $t('plannings.table.manage_shift_modal.tabs.shift.comments.title')
          }}
        </div>
      </div>
      <CommentsManager
        :comments="shift.attributes.comments"
        :user="shift.attributes.userId"
      />
    </div>
    <div
      v-if="shouldDisplayDocuments"
      class="shift-activities__section-container"
    >
      <div
        v-if="(!disabled) || (disabled && getDocuments(shift.id).length > 0)"
        class="shift-form__content-title"
      >
        <span>{{ $t('plannings.table.manage_shift_modal.tabs.shift.documents.title') }}</span>
      </div>
      <DocumentsManager
        :shift="shift"
        :work-shift-key="workShiftKey"
        :read-only="disabled"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';
import TasksManager from '@app-js/plannings/shared/components/ShiftActivities/Tasks/TasksManager';
import DocumentsManager from '@app-js/plannings/shared/components/ShiftActivities/Documents/DocumentsManager';
import ReadOnlyNote from '@app-js/plannings/pages/Weeks/shared/PlanningTable/ReadOnlyShiftModal/ReadOnlyNote';
import CommentsManager from '@app-js/plannings/shared/components/ShiftActivities/Comments/CommentsManager';
import { isEmpty } from '@skello-utils/array';
import { linkify } from '@skello-utils/formatting/strings';

export default {
  name: 'ShiftActivitiesSection',
  components: {
    TasksManager,
    ReadOnlyNote,
    DocumentsManager,
    CommentsManager,
  },
  props: {
    shift: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    workShiftKey: {
      type: String,
      default: '',
    },
    hideDocuments: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    ...mapState('currentShop', ['currentShop']),

    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('shiftsDocuments', ['isLoadingDocuments', 'getDocuments']),
    ...mapGetters('planningsShifts', ['shouldDisplayComments']),
    ...mapGetters('features', ['isFeatureEnabled']),

    shiftNote: {
      get() {
        return this.shift.attributes.note;
      },
      set(newValue) {
        this.shift.attributes.note = newValue;
      },
    },
    formattedNote() {
      return linkify(this.shift.attributes.note);
    },
    canDisplayTasks() {
      return this.isFeatureEnabled(FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id) &&
        (!this.disabled || !isEmpty(this.shift.attributes.tasks));
    },
    isTextToLinkEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_TEXT_TO_LINK');
    },
    isAttachmentsLoading() {
      return this.isLoadingDocuments;
    },
    isAttachDocumentsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_ATTACH_DOCUMENTS');
    },
    shouldDisplayDocuments() {
      return this.isAttachDocumentsEnabled && !this.hideDocuments;
    },
  },
  methods: {
    handleFocusChanged(focused) {
      this.isFocused = focused;
    },
    focusTextarea() {
      this.isFocused = true;
      this.$nextTick(() => {
        this.$refs.textarea?.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-activities__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-gap: 16px;
  margin-top: 8px;
}

.shift-activities__section-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.shift-form__content-title {
  width: 162px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  font-weight: 700;

  &--up {
    margin-bottom: 20px;
  }

  &__task {
    display: flex;
    height: 36px;
    width: 138px;
    align-items: center;
  }
}

.shift-form__content-input {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  &__timepicker-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__separator {
    padding: 4px;
  }
}

.shift-form__content-note-not-focused {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $sk-grey-5;
  padding: 6px 66px 4px 10px;
  border-radius: 4px;

  &__label {
    font-size: $fs-text-s;
    color: $sk-grey-50;
    margin-bottom: 6px;
  }

  .shift-activities__task-container {
    padding-bottom: 12px;
    border-bottom: 1px solid $sk-grey-10;
  }

  .shift-form__tasks-task__container {
    flex: 1;
  }
}

.shift-form__tasks-task__container {
  flex: 1;
}
</style>
