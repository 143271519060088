<template>
  <div class="planning__toolbar-datepicker">
    <ToolbarDatePicker
      :current-date="currentDate"
      :disabled-date="disabledDate"
      :is-day-range-navigation="isDailyView"
      :is-loading="isDatePickerLoading"
      :is-extra-small-screen="isExtraSmallScreen"
      :date-type="datePickerType"
      :additional-query-params="additionalQueryParams"
      tracker-prefix="planning"
    />
    <div class="planning__toolbar-buttons">
      <div
        v-if="isHolidayEmojiDisplayed"
        v-tooltip.bottom.offset="holidayName"
        class="planning__toolbar-emoji-tada"
      >
        {{ $t('plannings.table.header.days.emoji.tada') }}
      </div>
      <div
        v-if="isNoteTakerDisplayed"
        v-tooltip.bottom.offset="noteTooltip"
      >
        <SkCircleButton
          :disabled="eventsLoading || !currentLicense.attributes.canCreateShifts"
          :active="isNoteActive"
          icon="ClipboardV2Icon"
          @click="showNoteModal"
        />
      </div>
      <div
        v-if="isLockDisplayed"
        v-tooltip.bottom.offset="lockTooltip"
        :class="lockWrapperClass"
      >
        <div
          v-if="!isProgressiveLoadingEnabled && isLockWeekLoading"
          class="sk-icon-button__spinner"
        >
          <SkLoader size="small" />
        </div>
        <SkCircleButton
          v-else
          :icon="lockIcon"
          :icon-color="lockColor"
          :disabled="isLockDisabled"
          :disabled-color="lockColor"
          :class="lockButtonClass"
          data-test="planning-toolbar__lock"
          @click="handleValidateWeekClick"
        />
      </div>
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ManageNoteModal :day="currentDay" />
      <UnlockRequestModal
        :date="isDailyView ? currentDate : monday"
        :type="periodScope"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import ToolbarDatePicker from '@app-js/shared/components/Toolbar/ToolbarDatePicker';
import { uniq } from '@skello-utils/collection';
import skDate from '@skello-utils/dates';
import ManageNoteModal from '../modal/ManageNoteModal.vue';
import UnlockRequestModal from '../modal/UnlockRequestModal.vue';

export default {
  name: 'DatePicker',
  components: {
    ToolbarDatePicker,
    ManageNoteModal,
    UnlockRequestModal,
  },
  data() {
    return {
      lockIconShaking: false,
      isExtraSmallScreen: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', [
      'currentDate',
      'monday',
      'sunday',
      'isDailyView',
      'isWeeklyView',
      'isMonthlyView',
      'currentEvent',
      'currentDayHoliday',
      'isOnlyOneDayUnlocked',
      'isDayVisible',
    ]),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek']),
    ...mapGetters('currentShop', ['checkFeatureFlag']),
    ...mapGetters('planningsLoading', ['isLoadingInitialData', 'isProgressiveLoadingEnabled']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsShifts', ['usersWithWeeklyBlockingAlert']),
    ...mapState('planningsUsers', ['users', 'usersLoading']),
    ...mapState('planningsState', [
      'weeklyOptions',
      'weeklyOptionsLoading',
      'validatePeriodLoading',
      'shopPlanningConfigLoading',
      'undoRedoLoading',
      'eventsLoading',
      'holidayLoading',
      'holidays',
    ]),
    periodScope() {
      return this.isDailyView ? 'day' : 'week';
    },
    isWeekValidated() {
      return this.weeklyOptions.attributes.validatedDays.every(day => !!day);
    },
    isWeekIntermediateLocked() {
      return this.weeklyOptions.attributes.intermediateLockedDays.every(day => !!day);
    },
    isWeekPermanentLocked() {
      return this.weeklyOptions.attributes.permanentLockedDays.every(day => !!day);
    },
    dayIndex() {
      return skDate.utc(this.currentDate).dayIndex();
    },
    isDayValidated() {
      return this.weeklyOptions.attributes.validatedDays[this.dayIndex];
    },
    isDayIntermediateLocked() {
      return this.weeklyOptions.attributes.intermediateLockedDays[this.dayIndex];
    },
    isDayPermanentLocked() {
      return this.weeklyOptions.attributes.permanentLockedDays[this.dayIndex];
    },
    isDayLocked() {
      return this.isDayValidated || this.isDayIntermediateLocked || this.isDayPermanentLocked;
    },
    datePickerType() {
      if (this.isMonthlyView) return 'month';

      return 'date';
    },
    lockWrapperClass() {
      return {
        'planning__toolbar-lockwrapper--not-allowed': this.undoRedoLoading,
        'planning__toolbar-lockwrapper--shakeit': this.isDailyView && this.lockIconShaking,
      };
    },
    lockColor() {
      if (this.isWeekPermanentLocked || (this.isDailyView && this.isDayPermanentLocked)) return '#d03e50'; // red
      if (this.isWeekIntermediateLocked || (this.isDailyView && this.isDayIntermediateLocked)) return '#188377'; // green
      if (this.isWeekValidated || (this.isDailyView && this.isDayValidated)) return '#2b66fe'; // blue
      return this.isLockDisabled ? this.$skColors.skGrey30 : undefined; // default black
    },
    lockIcon() {
      return this.isWeekLocked || (this.isDailyView && this.isDayLocked) ? 'LockV2Icon' : 'OpenLockV2Icon';
    },
    lockTooltip() {
      if (this.isLockWeekLoading) return '';

      // we have to double check since this component is shared between day and week view
      // therefore the icon changes depending on the locked days AND the view we are on
      const isWeekLocked = !this.isDailyView && this.isWeekLocked;
      const isDayLocked = this.isDailyView && this.isDayLocked;
      return (isWeekLocked || isDayLocked) ? this.closedLockToolTip : this.openLockToolTip;
    },
    noteTooltip() {
      if (this.currentEvent?.attributes.note) {
        return this.currentEvent.attributes.note;
      }
      if (this.currentLicense.attributes.canCreateShifts) {
        return this.$t('plannings.table.add_note_modal.header.add_note_title');
      }
      return this.$t('plannings.table.add_note_modal.header.unauthorized');
    },
    isLockWeekLoading() {
      return this.weeklyOptionsLoading ||
        this.validatePeriodLoading ||
        this.usersLoading;
    },
    isDatePickerLoading() {
      return this.isDailyView && this.shopPlanningConfigLoading;
    },
    isWeekLocked() {
      return this.isWeekValidated || this.isWeekPermanentLocked || this.isWeekIntermediateLocked;
    },
    isHolidayEmojiDisplayed() {
      return this.isDailyView && !this.holidayLoading && this.currentDayHoliday;
    },
    holidayName() {
      return this.currentDayHoliday.name;
    },
    currentDayHoliday() {
      return this.holidays.find(holiday => holiday.date === this.currentDate);
    },
    isNoteTakerDisplayed() {
      return this.isDailyView;
    },
    isLockDisplayed() {
      return !this.isMonthlyView && !this.isExtraSmallScreen;
    },
    isLockDisabled() {
      if (
        this.isWeekPermanentLocked ||
        (this.isDailyView && this.isDayPermanentLocked) ||
        (this.isWeeklyView &&
          this.isLoadingInitialData &&
          this.isProgressiveLoadingEnabled
        )) {
        return true; // lock is disabled in this case since it should do nothing if clicked on it.
      }
      return !this.currentLicense.attributes.canTmplockPlanning;
    },
    lockButtonClass() {
      return {
        'planning__toolbar__lock-fade': !this.isLockWeekLoading && this.isProgressiveLoadingEnabled,
      };
    },
    closedLockToolTip() {
      if (this.isWeekPermanentLocked || (this.isDailyView && this.isDayPermanentLocked)) {
        if (this.currentLicense.attributes.canLockPlanning) {
          return this.$t(`plannings.table.header.actions.${this.periodScope}_validation.permanent_unlock.label`);
        }
        return this.$t(`plannings.table.header.actions.${this.periodScope}_validation.permanent_unlock.unauthorized`);
      }

      if (this.currentLicense.attributes.canTmplockPlanning) {
        return this.$t(`plannings.table.header.actions.${this.periodScope}_validation.unvalidate.label`);
      }
      return this.$t(`plannings.table.header.actions.${this.periodScope}_validation.unvalidate.unauthorized`);
    },
    openLockToolTip() {
      if (this.currentLicense.attributes.canTmplockPlanning) {
        return this.$t(`plannings.table.header.actions.${this.periodScope}_validation.validate.label`);
      }
      return this.$t(`plannings.table.header.actions.${this.periodScope}_validation.validate.unauthorized`);
    },
    currentDay() {
      return { event: this.currentEvent, date: this.currentDate };
    },
    isNoteActive() {
      return this.currentEvent && this.currentEvent.attributes.note;
    },
    additionalQueryParams() {
      return this.$route.query;
    },
  },
  created() {
    this.detectExtraSmallScreen();
    window.addEventListener('resize', this.detectExtraSmallScreen);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectExtraSmallScreen);
  },
  mounted() {
    this.listenOnRoot('day-view-shake-lock', this.shakeLockIcon);
  },
  methods: {
    ...mapActions('planningsState', ['validatePeriod']),
    ...mapMutations('planningsShifts', ['setUsersWithWeeklyBlockingAlert']),
    detectExtraSmallScreen() {
      // When screen size is below 1024px, actionsBar collapse causing lock/unlock
      // button to move to the dropdown options
      this.isExtraSmallScreen = window.innerWidth <= 1024;
    },
    handleValidateWeekClick(event) {
      if (
        this.isWeekPermanentLocked ||
        this.undoRedoLoading
      ) return;

      const validation =
        (!this.isDailyView && !this.isWeekValidated) || (this.isDailyView && !this.isDayValidated);
      const analyticsKey = validation ? `lock_${this.periodScope}` : `unlock_${this.periodScope}`;
      this.$skAnalytics.track(analyticsKey);

      // Only system administrator can intermediate unvalidate
      // Other users can do "unlock request"
      if (!this.isSystemAdmin && !validation) {
        if (!this.isDailyView && this.isWeekIntermediateLocked) {
          this.emitOnRoot(MODAL_SHOW_EVENT, event, `unlock-request-modal-week-${this.monday}`);
          return;
        }
        if (this.isDailyView && this.isDayIntermediateLocked) {
          this.emitOnRoot(MODAL_SHOW_EVENT, event, `unlock-request-modal-day-${this.currentDate}`);
          return;
        }
      }

      // Behind this condition there are 3 different cases:
      // 1. Are we locking or unlocking? (if unlocking stop here)
      // 2. Are we locking the whole week or just one day? If locking from daily view it's just
      //    one day, if it's from weekly view it's the whole week. If locking the whole week we
      //    need to trigger the alert.
      // 3. If locking one day, is this the last day to be locked on the week? If it is,
      //    trigger the alert.
      if (validation && (!this.isDailyView || this.isOnlyOneDayUnlocked)) {
        // TODO : Factorise with code in matchFrontAlertsToShifts
        // for this edge case (alert: minimum_weekly_work_time)
        this.setUsersWithWeeklyBlockingAlert(uniq(
          this.shiftsForCurrentWeek
            .filter(shift => (
              this.hasShiftBlockingMinWeeklyWorkingTimeAlert(shift) &&
              !this.getUserById(shift.attributes.userId).attributes.onExtra
            ))
            .map(shift => shift.attributes.userId),
        ));

        if (this.usersWithWeeklyBlockingAlert.length > 0) {
          this.emitOnRoot(MODAL_SHOW_EVENT, null, 'blocking-alert-for-week-lock-modal');
          return;
        }
      }

      const isIntermediateLocked =
        (!this.isDailyView && this.isWeekIntermediateLocked) ||
        (this.isDailyView && this.isDayIntermediateLocked);
      this.validatePeriod({
        startDate: this.isDailyView ? this.currentDate : this.monday,
        endDate: this.isDailyView ? this.currentDate : this.sunday,
        currentDate: this.currentDate,
        shopId: this.currentShop.id,
        validationLevel: isIntermediateLocked ? 'intermediate_locked_days' : 'validated_days',
        validationValue: validation,
        dayLock: !this.isDayIntermediateLocked,
      }).then(() => {
        this.$skToast({
          message: this.validationToastMessage(validation, 'success', this.periodScope),
          variant: 'success',
        });
      }).catch(error => {
        this.$skToast({
          message: this.validationToastMessage(validation, 'error', this.periodScope),
          variant: 'error',
        });
      });
    },
    validationToastMessage(validation, variant, scope) {
      if (validation) {
        return this.$t(`plannings.table.header.actions.${scope}_validation.validate.${variant}`);
      }
      return this.$t(`plannings.table.header.actions.${scope}_validation.unvalidate.${variant}`);
    },
    getUserById(userId) {
      return this.users.find(user => parseInt(user.id, 10) === userId);
    },
    hasShiftBlockingMinWeeklyWorkingTimeAlert(shift) {
      return shift.relationships.alerts &&
        shift.relationships.alerts.some(alert => (
          alert.attributes.blocking && alert.attributes.name === 'minimum_weekly_work_time'
        ));
    },
    showNoteModal(event) {
      if (!this.currentLicense.attributes.canCreateShifts) return;

      this.emitOnRoot(MODAL_SHOW_EVENT, event, `manage-note-modal-${this.currentDate}`);
    },
    shakeLockIcon() {
      this.lockIconShaking = true;
      setTimeout(() => {
        this.lockIconShaking = false;
      }, 1000);
    },
    disabledDate(date) {
      if (!this.isDailyView) return false;

      return !this.isDayVisible(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.planning__toolbar-datepicker {
  display: flex;
  align-items: center;

  .planning__toolbar-buttons {
    display: flex;
    align-items: center;

    &:first-child {
      margin-left: 12px;
    }
  }

  .datepicker__back-button {
    display: flex;
  }

  .sk-icon-button__spinner {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    color: $sk-grey-50;
    background: $sk-grey-10;
    border-radius: 50%;
  }

  .planning__toolbar-lockwrapper {
    &--not-allowed {
      // asked by product, when undoing/redoing, there is no
      // direct visual feedback (no disable) but if you hover on the button
      // it shows the not allowed cursor
      ::v-deep .sk-icon-button:hover:not([disabled]) {
        cursor: not-allowed;
      }
    }

    &--shakeit {
      animation-name: small_shake;
      animation-duration: 1s;

      @keyframes small_shake {
        from,
        to {
          transform: translate3d(0, 0, 0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
          transform: translate3d(-2px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
          transform: translate3d(2px, 0, 0);
        }
      }
    }
  }

  .planning__toolbar__vertical-border {
    height: 25px;
    margin: 0 13px;
    border: .1px solid $sk-grey-10;
  }

  .planning__toolbar-emoji-tada {
    margin: 0 4px 0 8px;
  }
}
</style>
