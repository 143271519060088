<template>
  <div
    v-if="isVisible"
  >
    <SkPopover
      v-if="isPopoverDisplayed"
      placement="left"
      as-tooltip
    >
      <template #anchor>
        <a
          :class="dropdownItemClasses"
          :data-test="dataTest"
        >
          <component
            :is="icon"
            fill="#b0b0b0"
            width="15"
            height="15"
            class="settings-dropdown__item-icon"
          />
          {{ title }}
        </a>
      </template>
      <template #content>
        <template v-if="isFeatureComingSoon">
          <strong>
            <i class="em em-drum_with_drumsticks" />
            {{ $t('plannings.toolbar.actions_bar.tooltip_soon.title') }}
          </strong>
          <p>{{ $t('plannings.toolbar.actions_bar.tooltip_soon.content') }}</p>
        </template>
        <p v-else>
          {{ tooltip }}
        </p>
      </template>
    </SkPopover>
    <a
      v-else-if="!isPopov"
      :class="dropdownItemClasses"
      :data-test="dataTest"
      @click="handler($event)"
    >
      <component
        :is="icon"
        fill="#727272"
        width="15"
        height="15"
        class="settings-dropdown__item-icon"
      />
      {{ title }}
    </a>
    <a
      v-else
      :class="dropdownItemClasses"
      :data-test="dataTest"
    >
      <component
        :is="popovComponent"
        class="settings-dropdown__item-icon"
        is-dropdown-menu-item
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'GenericDropdownItem',
  computed: {
    disabled() {
      return false;
    },
    tooltip() {
      return '';
    },
    visibleIn() {
      return '';
    },
    title() {
      return '';
    },
    dataTest() {
      return '';
    },
    popovComponent() {
      return '';
    },
    icon() {
      return '';
    },
    isVisible() {
      return false;
    },
    isFeatureComingSoon() {
      return this.tooltip === 'soon';
    },
    isPopov() {
      return false;
    },
    isPopoverDisplayed() {
      return this.disabled && this.tooltip;
    },
    dropdownItemClasses() {
      return {
        'settings-dropdown__item': true,
        'settings-dropdown__item--disabled': this.disabled,
        'settings-dropdown__item--popov': this.isPopov,
        'display-on-small-screen': this.visibleIn === 'toolbar-m-dropdown-s',
        'display-on-x-small-screen': this.visibleIn === 'toolbar',
        'settings-dropdown__item-icon': true,
      };
    },
  },
  methods: {
    handler() {},
  },
};
</script>

<style lang="scss" scoped>

// Dropdown hidden menu
.planning__toolbar__settings-menu {
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;
  padding: 10px 0;

  &--hidden {
    visibility: hidden;
  }

  .settings-dropdown__item {
    display: flex;
    align-items: center;
    color: $sk-black;
    padding: 10px 15px;
    width: 100%;

    // we want to force the smart planner icon from dropdown to be gray (like the others)
    ::v-deep svg > path {
      fill: $sk-grey;
    }

    &:hover {
      background: $sk-grey-10;
      border-radius: 3px;
      text-decoration: none;
    }

    &--disabled {
      color: $sk-grey-30;
      cursor: default;
    }

    &--popov {
      height: 41px;
      padding: 0;
    }
  }

  .settings-dropdown__item-icon {
    margin-right: 15px;
  }
}
</style>
