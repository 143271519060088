<template>
  <div class="shop-container">
    <div class="shop-separator" />
    <div
      id="shop-settings"
      :class="[
        'shop-settings__wrapper',
        {'shop-settings__wrapper--waved': isAutomaticPlanningSettings},
      ]"
    >
      <Sidebar />
      <div
        id="shop-settings__container"
        :class="[
          'shop-settings__container',
          {'shop-settings__container--details': isCounterRuleDetails},
        ]"
      >
        <div
          v-if="loading"
          class="shop-settings__spinner-wrapper"
        >
          <SkLoader size="large" />
        </div>
        <div
          v-else
          id="shop-settings__fixed-inner"
          class="shop-settings__fixed-inner"
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features';

import Sidebar from './shared/components/Sidebar';

export default {
  name: 'ShopSettings',
  components: { Sidebar },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.checkAuthorizedTab(to.name));
  },
  beforeRouteUpdate(to, from, next) {
    if (String(to.params.shop_id) !== this.currentShop.id) {
      this.updateAndSelectShop(to.params.shop_id);
    }

    next();

    this.checkAuthorizedTab(to.name);
  },
  data() {
    return {
      selectedShopLoaded: true,
      licenseAuthorized: false,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentLicense', [
      'isSystemAdmin',
      'canManageShopRulesAndAbsences',
      'canAccessShopSettingsTab',
      'canEditLegalAlertsConfig',
      'canEditShopBillingData',
      'canEditPunchClockSettings',
      'canManageDocumentTemplates',
    ]),
    ...mapGetters('currentShop', ['isClockRulesVisible', 'hasShopDocumentTemplatesAccess']),
    ...mapGetters('features', ['isFeatureEnabled']),
    loading() {
      return !this.selectedShopLoaded || !this.licenseAuthorized;
    },
    flexibleConvention() {
      return this.currentShop.relationships &&
        this.currentShop.relationships.convention &&
        this.currentShop.relationships.convention.attributes.flexible;
    },
    isAutomaticPlanningSettings() {
      return this.$route.name.includes('automatic_planning');
    },
    isCounterRuleDetails() {
      return this.$route.name.includes('details');
    },
  },
  watch: {
    currentShop() {
      this.setBrowserTabTitle();
    },
    currentLicense() {
      this.checkAuthorizedTab(this.$router.currentRoute.name);
    },
  },
  created() {
    this.setBrowserTabTitle();
    const shopId = this.$router.currentRoute.params.shop_id;
    const { absencesCountry: shopAbsencesCountry } = this.currentShop.attributes;

    if (this.navContext.shopId !== shopId || !shopAbsencesCountry) {
      this.updateAndSelectShop(shopId);
    }
  },
  destroyed() {
    this.resetBrowserTabTitle();
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', ['selectShop']),

    redirectToHome() {
      this.$skToast({
        message: this.$t('errors.unauthorized'),
        variant: 'error',
      });

      setTimeout(() => {
        window.location = '/';
      }, 1000);
    },

    checkAuthorizedTab(toRoute) {
      this.licenseAuthorized = false;

      // If no current license - still loading
      // return for now, once license is fetched, it will retrigger this method
      if (!this.currentLicense.id) {
        return;
      }

      // Find first available tab
      const firstAvailableTab = this.getFirstAvailableTab();

      // If you're on the main 'settings' route, with no tab selected
      // redirect to the first tab (rules)
      if (toRoute === 'shop_settings') {
        this.$router.push({ name: firstAvailableTab });
        return;
      }

      if (!this.canAccessShopSettingsTab && this.isClockRulesVisible) {
        this.$router.push({ name: 'shop_settings_punch_clock' });
        this.licenseAuthorized = true;
        return;
      }

      // If you're not allowed in the page at all (no tab allowed)
      // redirect to home
      if (!this.canAccessShopSettingsTab) {
        this.redirectToHome();
        return;
      }

      // Check if the user can access smart planner's rules
      if (!this.currentShop.attributes.canAccessAutomaticPlanning && (
        toRoute === 'automatic_planning_rules' ||
        toRoute === 'automatic_planning_competencies'
      )) {
        this.redirectToHome();
        return;
      }

      // Check every tab permission one by one
      // if allowed: remove loader
      // if not allowed, redirect to whatever tab you _are_ allowed to see
      if (this.isAllowedOnTab(toRoute)) {
        this.licenseAuthorized = true;
      } else {
        this.$router.push({ name: firstAvailableTab });
      }
    },
    getFirstAvailableTab() {
      if (this.canManageShopRulesAndAbsences) {
        return 'shop_settings_main_rules';
      }

      if (this.isFeatureEnabled(FEATURES.FEATURE_COLLECTIVE_AGREEMENT_ALERT, this.currentShop.id)
      ) {
        return 'shop_settings_alerts';
      }

      return this.redirectToHome();
    },
    isAllowedOnTab(toRoute) {
      if (toRoute === 'shop_settings_main_rules' || toRoute === 'shop_settings_counter_rules') {
        return this.canManageShopRulesAndAbsences;
      }
      if (toRoute === 'shop_settings_report_rules') {
        return this.canEditLegalAlertsConfig && this.flexibleConvention;
      }
      if (toRoute === 'shop_settings_absence') {
        return this.canManageShopRulesAndAbsences;
      }
      if (toRoute === 'shop_settings_shop') {
        return this.canEditShopBillingData;
      }
      if (toRoute === 'shop_settings_documents_management') {
        return this.hasShopDocumentTemplatesAccess() && this.canManageDocumentTemplates;
      }
      if (toRoute === 'shop_settings_punch_clock') {
        return this.isClockRulesVisible;
      }
      if (toRoute === 'shop_settings_alerts') {
        return this.isFeatureEnabled(
          FEATURES.FEATURE_COLLECTIVE_AGREEMENT_ALERT,
          this.currentShop.id,
        );
      }

      return this.canAccessShopSettingsTab;
    },
    updateAndSelectShop(shopId) {
      this.selectedShopLoaded = false;
      this.updateCurrentShop({ shopId })
        .then(() => {
          this.selectedShopLoaded = true;
          this.selectShop(this.currentShop);
        });
    },
    setBrowserTabTitle() {
      this.$nextTick(() => {
        if (this.currentShop.attributes) {
          document.title = this.$t('shop_settings.tab_title', {
            shopName: this.currentShop.attributes.name,
          });
        }
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  .shop-separator {
    height: 1px;
    background-color: $sk-grey-10;
  }

  .shop-settings__wrapper {
    flex: 1;
    overflow-y: auto;
    display: flex;

    div.shop-settings__container {
      flex: 1;
      overflow: auto;
      padding: 24px 0 0 40px;
      margin-bottom: 40px;

      &--details {
        padding: 0;
        background-color: $sk-grey-5;

        // little hack to increase specificity
        div.shop-settings__fixed-inner.shop-settings__fixed-inner {
          max-width: none;
        }
      }

      div.shop-settings__fixed-inner {
        max-width: 900px;
        height: 100%;
      }

      div.shop-settings__spinner-wrapper {
        display: flex;
        justify-content: center;
        font-size: 2em;
        padding-top: 4em;
      }
    }
  }
}

.shop-settings__wrapper--waved {
  background-image: url('./shared/images/wave_background_down.png');
  background-position: right top;
  background-repeat: no-repeat;
}
</style>
