<template>
  <SkModal
    v-if="isPlanningDataCompleted"
    id="erase-shifts-modal"
    ref="eraseShiftsModal"
    :modal-title="modalTitle"
    :modal-subtitle="modalSubtitle"
    size="ds-medium"
    @show="handleShow"
    @cancel="hide"
    @close="hide"
  >
    <template #title-icon>
      <TrashCanV2Icon
        width="30"
        height="30"
        fill="#d03e50"
        class="erase-shift-modal__icon-trash"
      />
    </template>
    <template #body>
      <SkModalSection border-bottom="none">
        <template v-if="isAnyDayLocked">
          <div class="erase-shift-modal__caption">
            <CircledExclamationMarkIcon
              fill="#d03e50"
              class="mr-1"
            />
            {{ $t('plannings.toolbar.modal.clear_shifts.caution') }}
          </div>
          <!-- eslint-disable-next-line -->
          <div class="erase-shift-modal__text" v-html="$t(`plannings.toolbar.modal.clear_shifts.cannot_erase_locked_day.${viewName}`)" />
        </template>
        <template v-else>
          <div class="erase-shift-modal__body">
            {{ textConfirmationContent }}
          </div>
          <div class="erase-shift-modal__select-line">
            <span class="erase-shift-modal__select-line__description">
              {{ selectLineDescription }}
            </span>
            <template v-if="displaySelectors">
              <PosteAndAbsenceSelector
                v-if="isPostesView"
                :selected-ids="selectedPosteAndAbsenceIds"
                :poste-and-absence-options="posteAndAbsenceOptions"
                :disable-select="false"
                @select-postes-and-absences="handleSelectPostesAndAbsences"
              />
              <PlanningEmployeesSelect
                v-else
                :initial-user-ids="selectedUserIds"
                :disabled="disableSelect"
                :users="filteredUsers"
                :teams="filteredTeams"
                :can-display-unassigned-shift="canDisplayUnassignedShift"
                @update-users="updateUsers"
              />
            </template>
          </div>
        </template>
      </SkModalSection>
    </template>

    <template #footer>
      <div v-if="isAnyDayLocked">
        <SkOroraButton @click="hide">
          {{ $t('plannings.toolbar.modal.clear_shifts.action.dismiss') }}
        </SkOroraButton>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-track="'click_on_delete_planning'"
        :loading="shiftsDeleting"
        :disabled="disableSubmit"
        :variant-color="$skColors.skError"
        icon="TrashCanV2Icon"
        data-test="erase-shift-modal__submit"
        @click="handleSubmit"
      >
        {{ $t('plannings.toolbar.modal.clear_shifts.action.submit-button') }}
      </SkOroraButton>
    </template>
  </SkModal>
  <!-- Planning loading -->
  <SkModal
    v-else
    id="erase-shifts-modal"
    ref="eraseShiftsModal"
    :modal-title="modalTitle"
    size="ds-medium"
    :display-footer="isPlanningDataCompleted"
    @show="handleShow"
    @cancel="hide"
    @close="hide"
  >
    <template #title-icon>
      <SortV2Icon
        class="sort_modal__icon"
        width="30"
        height="30"
        fill="#727272"
      />
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
      >
        <LoaderContainer />
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';

import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';
import PlanningEmployeesSelect from '@app-js/plannings/shared/components/PlanningEmployeesSelect';
import { FEATURES } from '@app-js/shared/constants/features';
import PosteAndAbsenceSelector from './PosteAndAbsenceSelector';

export default {
  name: 'EraseShiftModal',
  components: { PosteAndAbsenceSelector, PlanningEmployeesSelect, LoaderContainer },
  data() {
    return {
      selectedUserIds: [],
      selectedPosteAndAbsenceIds: [],
      displaySelectors: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', [
      'currentDate',
      'monday',
      'sunday',
      'isAnyDayLocked',
      'isDailyView',
      'isPostesView',
    ]),
    ...mapGetters('planningsShifts', [
      'unassignedShifts',
      'unassignedShiftsForCurrentDay',
      'dayCellShifts',
      'shiftsForCurrentWeek',
    ]),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsShifts', ['shiftsDeleting']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('shopTeams', ['teams']),
    ...mapState('planningsPostes', ['absences', 'postes']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isLoadingCompleted']),
    ...mapGetters('annualization', ['isAnnualizationCurrentlyActiveForCurrentShop']),
    ...mapGetters('features', ['isFeatureEnabled']),
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
    modalTitle() {
      return this.$t(`plannings.toolbar.modal.clear_shifts.header.${this.viewName}.title`);
    },
    viewName() {
      return this.isDailyView ? 'day' : 'week';
    },
    modalSubtitle() {
      return this.isDailyView ?
        capitalize(this.displayCurrentDate) :
        this.$t('plannings.toolbar.modal.clear_shifts.header.week.subtitle', {
          week: this.displayWeek,
          first_day: this.displayMonday,
          last_day: this.displaySunday,
        });
    },
    textConfirmationContent() {
      return this.isDailyView ?
        this.$t('plannings.toolbar.modal.clear_shifts.select.confirmation.day', { day: this.displayCurrentDate }) :
        this.$t('plannings.toolbar.modal.clear_shifts.select.confirmation.week', { week: this.displayWeek });
    },
    filteredUsers() {
      // Only keep shifts from current shop
      const activeShifts = this.shiftsForView.filter(shift => (
        shift.attributes.shopId === parseInt(this.currentShop.id, 10)
      ));

      // Get ids of users with shifts
      const usersWithShiftsIds = new Set(activeShifts.map(shift => shift.attributes.userId));

      // Get user objects from user ids
      return this.users.filter(user => {
        const id = parseInt(user.id, 10);
        return usersWithShiftsIds.has(id);
      });
    },
    posteAndAbsenceOptions() {
      // Only keep shifts from current shop
      const activeShifts = this.shiftsForView.filter(shift => (
        shift.attributes.shopId === parseInt(this.currentShop.id, 10)
      ));

      // Get ids of postes and absences with shifts
      const posteWithShiftsIds = [
        ...new Set(activeShifts.map(shift => shift.relationships.poste.id)),
      ];

      const posteOptions = this.postes
        .map(poste => ({ id: poste.id, text: poste.attributes.name }));
      const absenceOptions = this.absences
        .map(absence => ({ id: absence.id, text: absence.attributes.name }));
      return posteOptions.concat(absenceOptions)
        .filter(option => posteWithShiftsIds.includes(option.id));
    },
    filteredTeams() {
      // don't show teams without users in it
      return this.teams.filter(currentTeam => this.userIds
        .some(userId => currentTeam.relationships.users.map(teamUser => teamUser.id)
          .includes(userId),
        ),
      );
    },
    displayWeek() {
      return skDate(this.currentDate).isoWeek();
    },
    displayMonday() {
      return skDate(this.monday).format('DD MMMM');
    },
    displaySunday() {
      return skDate(this.sunday).format('DD MMMM YYYY');
    },
    displayCurrentDate() {
      return skDate(this.currentDate).format('dddd DD MMMM');
    },
    disableSubmit() {
      return !this.areAnyItemsSelected || this.shiftsDeleting;
    },
    disableSelect() {
      return !this.areAnyOptionsAvailable || this.shiftsDeleting;
    },
    areAnyItemsSelected() {
      return (this.isPostesView ? this.selectedPosteAndAbsenceIds : this.selectedUserIds)
        .length > 0;
    },
    areAnyOptionsAvailable() {
      return (this.isPostesView ? this.posteAndAbsenceOptions : this.userIds)
        .length > 0;
    },
    canDisplayUnassignedShift() {
      return this.unassignedShiftsForModal.length > 0 &&
        this.isFeatureEnabled(FEATURES.FEATURE_UNASSIGNED_SHIFTS, this.currentShop.id);
    },
    unassignedShiftsForModal() {
      return this.isDailyView ?
        this.unassignedShiftsForCurrentDay :
        this.unassignedShifts;
    },
    shiftsForCurrentDay() {
      return this.dayCellShifts(this.currentDate, this.shiftsForCurrentWeek);
    },
    shiftsForView() {
      return this.isDailyView ?
        this.shiftsForCurrentDay :
        this.shiftsForCurrentWeek;
    },
    selectLineDescription() {
      return this.isPostesView ?
        this.$t('plannings.toolbar.modal.clear_shifts.select.description.postes') :
        this.$t('plannings.toolbar.modal.clear_shifts.select.description.employees');
    },
    shiftIdsToDelete() {
      let shiftsToDelete = [];
      if (this.isPostesView) {
        shiftsToDelete = this.shiftsForView.filter(shift => (
          this.selectedPosteAndAbsenceIds.includes(shift.relationships.poste.id)
        ));
      } else {
        let userIds = this.selectedUserIds;
        // if unassigned is checked -> remove from array and add unassigned shift ids to list
        if (userIds.includes('unassigned')) {
          // remove the unassigned element from the array so parseInt don't fail
          userIds = userIds.filter(userId => userId !== 'unassigned');
          shiftsToDelete.push(...this.unassignedShiftsForModal);
        }
        userIds = userIds.map(id => parseInt(id, 10));

        shiftsToDelete.push(...this.shiftsForView.filter(shift => (
          userIds.includes(shift.attributes.userId) &&
          shift.attributes.shopId === parseInt(this.currentShop.id, 10)
        )));
      }

      return shiftsToDelete.map(shift => shift.id);
    },
    userIds() {
      const ids = this.filteredUsers.map(u => u.id);

      if (this.canDisplayUnassignedShift) {
        ids.unshift('unassigned');
      }
      return ids;
    },
  },
  methods: {
    ...mapActions('planningsShifts', ['deleteShifts']),
    handleShow() {
      // select all users or postes and absences by default
      if (this.isPostesView) {
        this.selectedPosteAndAbsenceIds = this.posteAndAbsenceOptions
          .map(posteOrAbsence => posteOrAbsence.id);
      } else {
        this.selectedUserIds = this.userIds;
      }

      this.displaySelectors = true;
    },
    hide() {
      this.displaySelectors = false;
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'erase-shifts-modal');
    },
    handleSelectPostesAndAbsences(event) {
      this.selectedPosteAndAbsenceIds = event.selectedPosteAndAbsenceIds;
    },
    updateUsers(userIds) {
      this.selectedUserIds = userIds;
    },
    handleSubmit() {
      // check if unassigned is checked
      const shiftIdsToDelete = this.shiftIdsToDelete;
      if (shiftIdsToDelete.length === 0) return;

      this.deleteShifts({
        shop_id: this.currentShop.id,
        shift_ids: shiftIdsToDelete,
        starts_at: this.isDailyView ? this.currentDate : this.monday,
        ends_at: this.isDailyView ? this.currentDate : this.sunday,
      })
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.clear_shifts.action.success'),
            variant: 'success',
          });
          this.hide();
          this.selectedUserIds = []; // clear select
        })
        .catch(() => {
          // Todo handle error
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.clear_shifts.action.fail'),
            variant: 'error',
          });
        });
    },
  },
};

</script>

<style lang="scss" scoped>

// Product asked for a specfic modal size
// Changing small margins and paddings on some elements
// allow the modal to be the exact right size.
#erase-shifts-modal {
  ::v-deep .sk-modal__subtitle {
    margin-top: 0;
  }

  ::v-deep .sk-modal__footer {
    padding-top: 25px;
  }

  ::v-deep .sk-modal__section {
    padding: 24px 0;
  }
}

.erase-shift-modal__icon-trash {
  display: flex;
  padding: 9px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: $sk-error-10;
}

.erase-shift-modal__body {
  margin-bottom: 23px;
}

.erase-shift-modal__select-line {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .erase-shift-modal__select-line__description {
    font-weight: $fw-semi-bold;
    padding-right: 14px;
  }
}

.erase-shift-modal__caption {
  color: $sk-error;
  font-weight: $fw-semi-bold;
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.erase-shift-modal__text {
  line-height: 22px;
  margin-bottom: -1px;
}
</style>
