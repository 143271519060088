<template>
  <SkModalV2
    id="read-only-shift-modal"
    ref="read-only-shift-modal"
    :title="modalTitle"
    :subtitle="modalSubtitle"
    :header-color="headerColor"
    title-color="white"
    subtitle-color="white"
    size="ds-medium"
    @hidden="handleHide"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <div
        v-if="!!shift"
        class="read-only-shift-modal__body-container"
      >
        <div class="read-only-shift-modal__section">
          <div class="read-only-shift-modal__section-title">
            {{ $t('plannings.table.manage_shift_modal.tabs.commun.time') }}
          </div>
          <div class="read-only-shift-modal__section-content__container">
            <div class="read-only-shift-modal__openings">
              <ReadOnlyInput :value="startsAt" />
              <span>-</span>
              <ReadOnlyInput :value="endsAt" />
            </div>
            <ReadOnlyInput
              :label="$t('plannings.table.manage_shift_modal.tabs.shift.duration.break')"
              :value="pause"
            />
            <ReadOnlyInput
              :label="$t('plannings.table.manage_shift_modal.tabs.shift.duration.label')"
              :value="formattedDuration"
            />
          </div>
        </div>
        <div
          v-if="isPostesView"
          class="read-only-shift-modal__section"
        >
          <div class="read-only-shift-modal__section-title">
            {{ $t('plannings.table.read_only_shift_modal.employee') }}
          </div>
          <ReadOnlyInput :value="shiftUserName" />
        </div>
        <div
          v-else
          class="read-only-shift-modal__section"
        >
          <div class="read-only-shift-modal__section-title">
            {{ $t('plannings.table.manage_shift_modal.tabs.shift.poste.title') }}
          </div>
          <ReadOnlyInput :value="posteName" />
        </div>
        <div
          v-if="hasMealRule"
          class="read-only-shift-modal__section"
          :style="{ borderBottom: hasShiftActivity ? '1px solid #dddddd' : 'none' }"
        >
          <div class="read-only-shift-modal__section-title">
            {{ $t('plannings.table.manage_shift_modal.tabs.shift.meal.taken') }}
          </div>
          <ReadOnlyInput :value="nbMeal" />
        </div>
        <div
          class="read-only-shift-modal__section"
        >
          <ShiftActivitiesSection
            :shift="shift"
            :work-shift-key="WORK_SHIFT_KEY.MAIN"
            disabled
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="read-only-shift-modal__footer">
        <div class="read-only-shift-modal__footer__lock-warning">
          <LockV2Icon fill="#727272" />
          <span>{{ $t('plannings.table.read_only_shift_modal.unlock_day') }}</span>
        </div>
        <SkOroraButton
          data-test="shift-modal__cancel"
          @click="handleCancel"
        >
          {{ $t('actions.close') }}
        </SkOroraButton>
      </div>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import { FEATURES } from '@app-js/shared/constants/features.js';
import { isEmpty } from '@skello-utils/array';
import skDate from '@skello-utils/dates';
import ShiftActivitiesSection from '@app-js/plannings/shared/components/ShiftActivities/ShiftActivitiesSection';
import ReadOnlyInput from '@app-js/plannings/pages/Weeks/shared/PlanningTable/ReadOnlyShiftModal/ReadOnlyInput';
import { WORK_SHIFT_KEY } from '@app-js/shared/constants/shift';
import { ERROR_TYPES } from '@skello-store/modules/plannings/shifts-documents';
import { formattedDuration } from '@app-js/plannings/shared/utils/shift_helpers';

export default {
  name: 'ReadOnlyShiftModal',
  components: { ShiftActivitiesSection, ReadOnlyInput },
  data() {
    return {
      shift: undefined,
      modalHidden: true,
      isUnassignedShift: false,
      WORK_SHIFT_KEY,
      options: {
        ERROR_TYPES,
      },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsUsers', ['users']),

    ...mapGetters('currentShop', ['hasMealRule', 'isDevFlagEnabled']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('planningsState', ['isPostesView']),
    ...mapGetters('planningsShifts', ['shouldDisplayComments']),
    ...mapGetters('features', ['isFeatureEnabled']),

    isAttachDocumentsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_ATTACH_DOCUMENTS');
    },
    shiftUserName() {
      if (this.isUnassignedShift) return this.$t('plannings.table.read_only_shift_modal.unassigned');

      const shiftUser = this.users.find(user => user.id === String(this.shift?.attributes.userId));

      if (!shiftUser) return '';

      return this.fullName(shiftUser);
    },
    modalTitle() {
      if (this.isUnassignedShift) return this.$t('plannings.table.read_only_shift_modal.unassigned_shift_title');
      if (this.isPostesView) return this.posteName;

      return this.$t('plannings.table.read_only_shift_modal.title', { userName: this.shiftUserName });
    },
    modalSubtitle() {
      return skDate(this.shift?.attributes.startsAt).format('dddd Do MMMM YYYY');
    },
    headerColor() {
      return this.shift?.attributes.color;
    },
    nbMeal() {
      return String(this.shift?.attributes.nbMeal);
    },
    posteName() {
      return this.shift?.relationships.poste.attributes.name;
    },
    coloredModalClass() {
      return {
        'read-only-shift-modal--colored': this.headerColor,
        'read-only-shift-modal--sidepanel-opened': this.absenceSidePanelOpeningOrigin === 'shift-modal',
      };
    },
    startsAt() {
      return this.timeLabel(this.shift.attributes.startsAt);
    },
    endsAt() {
      return this.timeLabel(this.shift.attributes.endsAt);
    },
    formattedDuration() {
      return formattedDuration(
        this.shift,
        this.currentShop,
        skDate(this.shift.attributes.startsAt),
      );
    },
    pause() {
      return String((this.shift.attributes.pauseTime || 0) / 60);
    },
    hasShiftActivity() {
      const { note, tasks, comments, shopId } = this.shift.attributes;
      return !!note ||
        this.shouldDisplayComments(comments, shopId) ||
        (
          !isEmpty(tasks) &&
          this.isFeatureEnabled(FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id)
        );
    },
  },
  created() {
    this.listenOnRoot('init-read-only-shift-modal', (event, props) => {
      this.shift = props.shift;
      this.isUnassignedShift = props.isUnassignedShift;

      this.modalHidden = false;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'read-only-shift-modal');
    });
  },
  methods: {
    ...mapActions('shiftsDocuments', ['fetchDocuments']),
    ...mapMutations('shiftsDocuments', ['resetDocuments']),

    handleCancel() {
      this.modalHidden = true;
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'read-only-shift-modal');
    },
    handleHide() {
      this.modalHidden = true;
    },
    async handleShow() {
      this.modalHidden = false;
      if (this.isAttachDocumentsEnabled) {
        this.resetDocuments();
        try {
          await this.fetchDocuments({
            shiftId: this.shift.id?.toString(),
            shopId: this.currentShop.id,
          });
        } catch {
          this.displayDocumentError(this.options.ERROR_TYPES.DOCUMENT_LOAD);
        }
      }
    },
    displayDocumentError(errorType) {
      const errorMessages = {
        [this.options.ERROR_TYPES.DOCUMENT_LOAD]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
      };

      this.$skToast({
        message: errorMessages[errorType] ?? errorMessages[this.options.ERROR_TYPES.INVALID],
        variant: 'error',
      });
    },
    timeLabel(time) {
      return this.strToSkDate(time).format('HH:mm');
    },
    strToSkDate(timeStr) {
      return skDate.utc(timeStr);
    },
  },
};
</script>

<style lang="scss" scoped>
.read-only-shift-modal__body-container {
  padding: 16px 24px 0 40px;
}

.read-only-shift-modal__section {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid $sk-grey-10;

  &:last-child {
    border-bottom: none;
  }
}

.read-only-shift-modal__section-title {
  width: 162px;
  font-weight: 700;
}

.read-only-shift-modal__section-content__container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.read-only-shift-modal__openings {
  display: flex;
  align-items: center;
  gap: 8px;
}

.read-only-shift-modal__input__container {
  width: 82px;
}

.read-only-shift-modal__input-label {
  font-size: 12px;
  color: $sk-grey-50;
}

.read-only-shift-modal__poste {
  flex: 1;
  padding-right: 8px;
}

.read-only-shift-modal__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.read-only-shift-modal__footer__lock-warning {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $sk-grey-50;
}
</style>
