<template>
  <div>
    <div
      v-if="setupComplete"
      class="app-wrapper"
    >
      <SkBanner
        v-for="platformAlert in platformAlerts"
        :key="platformAlert.id"
        :level="platformAlert.attributes.level"
        :message="platformAlert.attributes.message"
        :link-to-label="platformAlert.attributes.linkToLabel"
        :link-to="platformAlert.attributes.linkTo"
      />
      <UnpaidBanner
        v-if="displayUnpaidBanner"
        :key="'unpaidBanner'"
      />
      <Navbar v-if="navbarVisible" />
      <router-view />
      <AbsenceSidePanel />
      <ChecklistContainer />
    </div>
    <ErrorPage v-else-if="error" />
    <BootLoader v-else />
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import moment from 'moment';
import { captureException } from '@sentry/vue';
import { loadLanguageAsync } from '@plugins/i18n';
import { impersonateClient } from '@skello-utils/clients';
import { isRouteAllowed } from '@app-js/shared/utils/is_route_allowed';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
  SIDEPANEL_HIDE_EVENT,
  SIDEPANEL_SHOW_EVENT,
} from '@skelloapp/skello-ui';
import AbsenceSidePanel from './shared/components/AbsenceSidePanel';
import Navbar from './shared/components/Navbar/index';
import BootLoader from './shared/components/BootLoader';
import ErrorPage from './shared/components/ErrorPage/index';
import ChecklistContainer from './shared/components/Checklists/ChecklistContainer';
import UnpaidBanner from './shared/components/Banners/UnpaidBanner';

export default {
  name: 'App',
  components: {
    Navbar,
    BootLoader,
    ErrorPage,
    AbsenceSidePanel,
    ChecklistContainer,
    UnpaidBanner,
  },
  computed: {
    ...mapState(['setupComplete', 'error', 'navbarVisible']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('config', ['config']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('platformAlerts', ['platformAlerts']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'shouldShowAdminOnboarding']),
    ...mapGetters('currentUser', ['isSystemAdmin']),
    ...mapGetters('timeclockOnboarding', ['shouldShowTimeclockOnboarding']),
    ...mapGetters('currentLicense', ['isEmployee']),
    displayUnpaidBanner() {
      return !this.isEmployee &&
        (
          this.currentShop.attributes?.shopUnpaidBanner ||
          this.currentOrganisation.attributes?.organisationUnpaidBanner ||
          (this.currentShop.id === 'all' && this.currentOrganisation.attributes?.shopUnpaidBanner)
        );
    },
    isUserRedirectedToAdminOnboarding() {
      return this.shouldShowAdminOnboarding && this.shouldRedirectToOnboarding('admin_onboarding');
    },
    isUserRedirectedToTimeclockOnboarding() {
      return this.shouldShowTimeclockOnboarding && this.shouldRedirectToOnboarding('timeclock_onboarding');
    },
  },
  watch: {
    $route(to, from) {
      let shopId = this.currentShop.id;
      let accountUid = this.currentUser.attributes.shopId;
      // update of the stdProperties for shop id and salesmachine `account_uid` in case it has changed
      // in the case of salesmachine, if we don't have a contextual shop id or if the shop id is 'all',
      // we attach the tracking event to the user affiliated shop
      if (to.params.shop_id) {
        shopId = to.params.shop_id;

        if (to.params.shop_id !== 'all') {
          accountUid = to.params.shop_id;
        }
      }

      this.$skAnalytics.stdProperties.shop_id = shopId;
      this.$skAnalytics.stdProperties.account_uid = accountUid;
      document.getElementById('sk-toast')?.remove();
      this.$skAnalytics.page();
    },
    currentOrganisation() {
      // We need the organisation for handleShopCancelled,
      // so if currentOrganisation arrives after currentShop
      // we need to double-check again
      this.handleShopCancelled();
    },
    currentShop(currentShop, oldShop) {
      // When currentShop is updated, check that this shop is cancelled or not
      this.handleShopCancelled();

      // Onboarding redirection
      if (oldShop.id !== currentShop.id) {
        this.handleOnboardingRedirection();
      }
    },
  },
  created() {
    this.setupApp();
  },
  mounted() {
    if (this.navbarVisible) {
      this.setNavbarVisibility(true);
    }
    this.listenOnRoot(MODAL_SHOW_EVENT, () => {
      this.setIsAnyModalOpen(true);
    });
    this.listenOnRoot(MODAL_HIDE_EVENT, () => {
      this.setIsAnyModalOpen(false);
    });
    this.listenOnRoot(SIDEPANEL_SHOW_EVENT, () => {
      this.setIsAnySidePanelOpen(true);
    });
    this.listenOnRoot(SIDEPANEL_HIDE_EVENT, () => {
      this.setIsAnySidePanelOpen(false);
    });
  },
  methods: {
    ...mapMutations([
      'performingSetup',
      'setupSuccess',
      'setupError',
      'setNavbarVisibility',
      'setupAnalytics',
    ]),
    ...mapMutations('overlays', ['setIsAnyModalOpen', 'setIsAnySidePanelOpen']),
    ...mapActions('navContext', [
      'selectShop',
      'selectOrganisation',
      'selectClusterNode',
      'fetchCurrentClusterNode',
    ]),
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('currentUser', ['fetchCurrentUser', 'fetchCurrentUserAccess']),
    ...mapActions('config', ['fetchConfig']),
    ...mapActions('currentOrganisation', ['fetchCurrentOrganisation']),
    ...mapActions('features', ['fetchFeatures']),

    async setupApp() {
      this.performingSetup();

      if (this.navbarVisible) {
        this.setNavbarVisibility(true);
      }

      this.loadInitialData();
    },

    loadInitialData() {
      const queries = [
        this.fetchCurrentUserAndSetLang(),
        this.fetchCurrentUserAccess(),
        this.fetchConfig(),
        this.fetchFeatures(),
      ];

      // FIXME DEV-7908: do not send current shop to backend
      const shopId = this.$router.currentRoute.params.shop_id;
      if (shopId !== 'all' && shopId !== undefined) {
        queries.push(this.updateCurrentShop({ shopId }));
        queries.push(this.fetchCurrentOrganisation(shopId));
      } else {
        queries.push(this.fetchCurrentOrganisation());
      }

      const clusterNodeId = this.$router.currentRoute.query.cluster_node_id;

      Promise.all(queries)
        .then(() => {
          if (!isRouteAllowed(this.$route.name)) {
            this.handleRedirectToHome();
            return;
          }

          this.handleOnboardingRedirection();

          this.setupAnalytics();
          if ((shopId === 'all' || shopId === undefined) && !clusterNodeId) {
            this.selectOrganisation(this.currentOrganisation)
              .then(() => {
                this.setupSuccess();
              });
          } else if (clusterNodeId) {
            this.fetchCurrentClusterNode(clusterNodeId)
              .then(() => {
                this.setupSuccess();
              });
          } else {
            this.selectShop(this.currentShop)
              .then(() => {
                this.setupSuccess();
              });
          }
        })
        .catch(error => {
          this.setupError({ error });

          throw error;
        });
    },
    async fetchCurrentUserAndSetLang() {
      // No user id in params because we are fecthing user with DEVISE back-end authentification
      // Should fetch with userId from JWT
      try {
        const response = await this.fetchCurrentUser();

        const { lang } = response.data.data.attributes;

        loadLanguageAsync(lang);
        moment.locale(lang);
      } catch (error) {
        captureException(error);
        throw error;
      }
    },
    // When shop is cancelled, the only accessible page is the report
    handleShopCancelled() {
      if (
        this.$route.name === 'reports' ||
        !this.currentShop.attributes.cancelled
      ) {
        return;
      }
      // When shop is cancelled, user can only access the report page
      this.$router.push({
        name: 'reports',
        params: { shop_id: this.currentShop.id },
      });
    },
    handleRedirectToHome() {
      setTimeout(() => {
        window.location = '/';
      }, 100);
    },
    handleOnboardingRedirection() {
      if (this.isUserRedirectedToAdminOnboarding) {
        this.redirectToAdminOnboarding();
      }

      if (this.isUserRedirectedToTimeclockOnboarding) {
        this.redirectToTimeclockOnboarding();
      }
    },
    shouldRedirectToOnboarding(routeName) {
      return this.$route.name !== routeName && this.isSystemAdmin && this.config;
    },
    redirectToOnboarding(routeName) {
      const shopId = this.currentShop.id;
      const shopIdToRedirect = (shopId === 'all' || shopId === undefined) ? this.currentUser.attributes.shopId : shopId;
      this.$router.push({
        name: routeName,
        params: { shop_id: shopIdToRedirect },
      });
    },
    redirectToAdminOnboarding() {
      this.redirectToOnboarding('admin_onboarding');
    },
    redirectToTimeclockOnboarding() {
      this.redirectToOnboarding('timeclock_onboarding');
    },
  },
};
</script>

<style lang="scss" scoped>
div.app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>

<style lang="scss">
.intercom-lightweight-app-launcher {
  opacity: .4;

  &:hover {
    opacity: 1;
  }
}
</style>
