<template>
  <div>
    <div class="counters__upsell-overlay">
      <div class="counters__upsell-overlay-icon">
        <component
          :is="icon"
          height="18"
          width="18"
        />
      </div>
      <div class="counters__upsell-overlay-title">
        <h1 class="sk-header--1">
          {{ title }}
        </h1>
        <div class="counters__upsell-overlay-description">
          {{ $t('employees.tabs.counters.day_rate_counter.tooltip.upsell_1') }}
          <PackTag
            type="basic"
            shadow
          />
          {{ $t('employees.tabs.counters.day_rate_counter.tooltip.upsell_2') }}
          <div class="counters__upsell-overlay-description-end">
            {{ $t('employees.tabs.counters.day_rate_counter.tooltip.upsell_3') }}
            <b>{{ $t('employees.tabs.counters.day_rate_counter.tooltip.upsell_4') }}</b>
          </div>
        </div>
      </div>
    </div>
    <div class="counters__upsell-overlay-background" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PackTag from '@app-js/shared/components/PackTag';

export default {
  name: 'PackOfferUpsellOverlay',
  components: { PackTag },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.counters__upsell-overlay-icon {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba($sk-blue, .15);
  vertical-align: middle;
}

.counters__upsell-overlay-background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, .2);
}

.counters__upsell-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 13%;
  left: 25%;
  opacity: 1;
  z-index: 1;
  width: 420px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  border-radius: 6px;
  padding: 25px;
  background-color: white;
}

.counters__upsell-overlay-title {
  text-align: center;
  margin: 10px 40px !important;
}

.counters__upsell-overlay-item {
  width: 211px;
  height: 51px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid $sk-grey-10;
  box-sizing: border-box;
  border-radius: 4px;
}

.counters__upsell-overlay-description {
  margin-top: 10px;
}

.counters__upsell-overlay-full-name {
  padding-left: 15px;
}

.counters__upsell-overlay-button {
  padding: 11.5px 120px;
}

.counters__upsell-overlay-description-end {
  padding-top: 25px;
}
</style>
