<template>
  <thead>
    <tr class="employee-shifts-table__title sk-subtitle--small">
      <th class="employee-shifts-table__column__date" />
      <th
        v-if="isPositionsFeatureEnabled"
        class="employee-shifts-table__column__poste"
      >
        {{ $t('employees.employee_shifts_table.poste') }}
      </th>
      <th class="employee-shifts-table__column__shop">
        {{ $t('employees.employee_shifts_table.shop') }}
      </th>
      <th class="employee-shifts-table__column__start">
        {{ $t('employees.employee_shifts_table.start') }}
      </th>
      <th class="employee-shifts-table__column__end">
        {{ $t('employees.employee_shifts_table.end') }}
      </th>
      <th class="employee-shifts-table__column__pause">
        {{ $t('employees.employee_shifts_table.pause') }}
      </th>
      <th
        v-if="isShiftsFeatureEnabled"
        class="employee-shifts-table__column__delay"
      >
        {{ $t('employees.employee_shifts_table.delay') }}
      </th>
      <th class="employee-shifts-table__column__note">
        {{ $t('employees.employee_shifts_table.note') }}
      </th>
      <th class="employee-shifts-table__column__working-time">
        {{ workingTimeHeaderText }}
      </th>
    </tr>
  </thead>
</template>

<script>
import { FEATURES } from '@app-js/shared/constants/features';
import {
  mapState, mapGetters,
} from 'vuex';

export default {
  name: 'EmployeeShiftsTableHeader',
  props: {
    onDayRate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    workingTimeHeaderText() {
      if (this.onDayRate === true) {
        return this.$t('employees.employee_shifts_table.days_worked');
      }
      return this.$t('employees.employee_shifts_table.hours_worked');
    },
    isPositionsFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_CREATE_POSITION, this.currentShop.id);
    },
    isShiftsFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id);
    },
  },
};
</script>

<style lang="scss" scoped>
thead {
  .employee-shifts-table__title {
    th {
      font-weight: $fw-regular;
      font-size: $fs-text-s;
      color: $sk-grey;
      vertical-align: bottom;
      padding-bottom: 15px;
    }
  }
}
</style>
