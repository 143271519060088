<template>
  <SkModal
    id="create-from-template-modal"
    ref="createFromTemplateModal"
    :class="modalClass"
    :modal-title="modalTitle"
    :display-footer="false"
    size="ds-medium"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template
      v-if="isSelectParametersSectionActive"
      #title-icon
    >
      <CreateFromTemplateIcon class="modal__title__icon" />
    </template>
    <!-- Data loaded -->
    <template
      v-if="isPlanningDataCompleted"
      #body
    >
      <SelectParametersSection
        v-show="isSelectParametersSectionActive"
        ref="selectParametersSection"
        @submit-tab="handleParametersSubmit"
        @cancel-tab="abortDuplicatePlanning"
      />
      <ConflictResolutionSection
        v-if="!isSelectParametersSectionActive"
        @submit-tab="handleConflictResolutionSubmit"
        @cancel-tab="handleConflictResolutionCancel"
      />
    </template>
    <!-- Data being loaded -->
    <template
      v-else
      #body
    >
      <LoaderContainer />
    </template>
    <SkConfirmationModal />
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import {
  CreateFromTemplateIcon,
  SkModal,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import { uniq } from '@skello-utils/collection';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';

import skDate from '@skello-utils/dates';

import { FEATURES } from '@app-js/shared/constants/features';
import SelectParametersSection from './SelectParametersSection';
import ConflictResolutionSection from './ConflictResolutionSection';

export default {
  name: 'CreateFromTemplateModal',
  components: {
    ConflictResolutionSection,
    CreateFromTemplateIcon,
    SelectParametersSection,
    SkModal,
    LoaderContainer,
  },
  data() {
    return {
      activeSection: 'select_parameters', // select_parameters or resolve_conflicts
      selectedParams: {},
    };
  },
  computed: {
    ...mapState('planningsLoading', ['planningDataStatus']),
    ...mapState('planningsTemplates', ['isConflictSidePanelOpen', 'templates']),
    ...mapState('config', ['config']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek']),
    ...mapGetters('currentShop', ['checkFeatureFlag']),
    ...mapGetters('planningsState', [
      'monday',
      'sunday',
      'isDailyView',
      'isWeeklyView',
      'isPostesView',
      'currentDate',
    ]),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isLoadingCompleted']),
    ...mapGetters('features', ['isFeatureEnabled']),
    modalTitle() {
      return this.isSelectParametersSectionActive ?
        this.$t('plannings.toolbar.modal.create_from.select_parameters.header.title') :
        this.$t('plannings.toolbar.modal.create_from.conflict_resolution.header.title');
    },
    mondaySkDate() {
      return skDate(this.monday, 'YYYY-MM-DD').utc(true);
    },
    sundaySkDate() {
      return skDate(this.sunday, 'YYYY-MM-DD').utc(true);
    },
    isSelectParametersSectionActive() {
      return this.activeSection === 'select_parameters';
    },
    keepAssignment() {
      return !this.selectedParams.convertToUnassigned;
    },
    isCreateFromTemplateSelected() {
      return this.selectedParams.selectedCreateFrom === 'template';
    },
    isIncludeUnassignedSelected() {
      return this.isPostesView || this.selectedParams.selectedUserIds.includes('unassigned');
    },
    selectedUserIds() {
      return this.selectedParams.selectedUserIds.filter(userId => userId !== 'unassigned');
    },
    selectedPosteAndAbsenceIds() {
      return this.selectedParams.selectedPosteAndAbsenceIds;
    },
    selectedWeeks() {
      return this.selectedParams.selectedWeeks.map(
        weekIndex => this.mondayForWeek(weekIndex).format(),
      );
    },
    selectedPreviousWeek() {
      return this.mondayForWeek(this.selectedParams.selectedPreviousWeek).format();
    },
    selectedTemplateId() {
      return this.selectedParams.selectedTemplateId;
    },
    modalClass() {
      return {
        'create-from__modal-container--select-parameters': this.isSelectParametersSectionActive,
        'create-from__modal-container--resolve-conflicts': !this.isSelectParametersSectionActive,
        'create-from__modal-container--sidepanel-opened': this.isConflictSidePanelOpen,
      };
    },
    startDates() {
      return this.isDailyView ?
        [skDate(this.currentDate).utc(true).format()] :
        this.selectedWeeks;
    },
    getSourceTemplateApplied() {
      if (this.selectedParams.selectedTemplateId) {
        return this.templates.filter(
          template => template.id === this.selectedTemplateId.toString(),
        )[0].attributes.name;
      }

      return this.isDailyView ?
        this.selectedParams.selectedPreviousDay :
        this.selectedPreviousWeek;
    },
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations('planningsTemplates', ['toggleConflictSidePanel', 'fetchConflictsSuccess']),
    ...mapActions('planningsShifts', ['fetchShifts', 'fetchShiftAlerts']),
    ...mapActions('planningsTemplates', ['fetchConflicts', 'duplicatePlanning', 'fetchTemplates']),
    ...mapMutations('planningsState', ['closeTotalPeriodTab']),

    dateForWeek(date, weekIndex) {
      const newDate = date.clone();

      if (weekIndex === 0) return newDate;

      return newDate.add(weekIndex, 'isoWeeks');
    },
    mondayForWeek(weekIndex) {
      return this.dateForWeek(this.mondaySkDate, weekIndex);
    },
    sundayForWeek(weekIndex) {
      return this.dateForWeek(this.sundaySkDate, weekIndex);
    },
    handleParametersSubmit(params) {
      this.selectedParams = params;
      if (
        this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_TEMPLATE_CONFLICTS, this.currentShop.id)
      ) {
        this.getPlanningTemplateConflicts();
      } else {
        this.$root.$emit('confirm', event, {
          description: this.$t('plannings.toolbar.modal.create_template.confirm_modal.description'),
          onConfirm: () => this.handleConflictResolutionSubmit(null),
        });
      }
    },
    handleConflictResolutionSubmit(conflictResolution) {
      this.$skAnalytics.track('number_weeks_duplicated', {
        number_of_weeks_selected: this.selectedWeeks.length,
      });

      this.handleDuplicatePlanning(conflictResolution);
    },
    handleDuplicatePlanning(conflictResolution) {
      let activePlanning;
      if (this.isDailyView) {
        activePlanning = this.config.planning_types.ACTIVE_PLANNING_DAY;
      } else if (this.isPostesView) {
        activePlanning = this.config.planning_types.ACTIVE_PLANNING_POSTE;
      } else {
        activePlanning = this.config.planning_types.ACTIVE_PLANNING_WEEK;
      }

      const date = this.isDailyView ?
        skDate(this.currentDate).utc(true).format() :
        this.mondaySkDate.format();

      const params = {
        shop_id: this.currentShop.id,
        keep_assignment: this.keepAssignment,
        weeks_checked: this.selectedWeeks,
        date,
        active_planning: activePlanning,
        include_unassigned: this.isIncludeUnassignedSelected,
        select_weeks: this.isDailyView ? null : 'multiple_weeks',
        conflict_resolution: conflictResolution,
        // Need quarter(s) starts_at & ends_at for fetchQuarterDataForAlerts
        starts_at: skDate(this.monday).startOf('quarter').format('YYYY-MM-DD'),
        ends_at: skDate(this.sunday).endOf('quarter').format('YYYY-MM-DD'),
      };

      if (this.isPostesView) {
        params.postes_checked = this.selectedPosteAndAbsenceIds;
      } else {
        params.users_checked = this.selectedUserIds;
      }

      if (this.isCreateFromTemplateSelected) {
        this.$skAnalytics.track('click_on_duplicate_planning_submit');
        params.templateId = this.selectedTemplateId;
      } else {
        this.$skAnalytics.track('click_on_duplicate_week_submit');
        // Date to copy
        params.given_date = this.isDailyView ?
          this.selectedParams.selectedPreviousDay :
          this.selectedPreviousWeek;
      }

      this.duplicatePlanning(params)
        .then(() => {
          this.onRequestSuccess();
          this.sendEventToSvcEvents(conflictResolution);
        })
        .catch(() => this.onRequestFailure());
    },
    onRequestSuccess() {
      this.closeTotalPeriodTab();
      // If template was applied on current week : fetch new shifts
      const fetchParams = {
        shop_id: this.currentShop.id,
        starts_at: this.monday,
        ends_at: this.sunday,
        isRefetch: true,
      };

      this.fetchShifts({ params: fetchParams }).then(() => {
        fetchParams.user_ids = this.getUserIdsWithShifts();

        this.fetchShiftAlerts(fetchParams);
      });

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'create-from-template-modal');
      this.closeSidePanel();

      this.$skToast({
        message: this.$t('plannings.toolbar.modal.create_from.action.apply.success'),
        variant: 'success',
      });
    },
    onRequestFailure() {
      this.$skToast({
        message: this.$t('plannings.toolbar.modal.create_from.action.common.failure'),
        variant: 'error',
      });
    },
    getPlanningTemplateConflicts() {
      this.conflictsLoading = true;

      const params = {
        shop_id: this.currentShop.id,
        is_day_template: this.isDailyView.toString(),
        keep_assignment: this.keepAssignment.toString(),
        include_unassigned: this.isIncludeUnassignedSelected.toString(),
        template: {
          start_dates: this.startDates,
        },
      };

      if (this.isPostesView) {
        params.template.poste_ids = this.selectedPosteAndAbsenceIds;
      } else {
        params.template.user_ids = this.selectedUserIds;
      }

      if (this.isCreateFromTemplateSelected) {
        params.template.id = this.selectedTemplateId;
      } else if (this.isDailyView) {
        params.given_date = this.selectedParams.selectedPreviousDay;
      } else {
        params.given_date = this.selectedPreviousWeek;
      }

      this.fetchConflicts(params)
        .then(response => this.handleConflictsCalculated(response.data))
        .catch(() => this.onRequestFailure());
    },
    handleConflictsCalculated(conflicts) {
      if (conflicts.length > 0) {
        this.activeSection = 'resolve_conflicts';
      } else {
        // No conflicts detected -> submit create from request directly
        this.handleConflictResolutionSubmit(null);
      }
    },
    handleShow() {
      if (this.isWeeklyView) this.fetchTemplates({ shopId: this.currentShop.id, activePlanning: 'week' });
      this.activeSection = 'select_parameters';
      this.selectedParams = {};
      this.$refs.selectParametersSection?.resetData();
      this.fetchConflictsSuccess([]);
    },
    handleCancel(event) {
      event.preventDefault();

      if (this.isSelectParametersSectionActive) {
        this.abortDuplicatePlanning();
        return;
      }

      this.displayConfirmCancelPopup();
    },
    handleConflictResolutionCancel() {
      this.displayConfirmCancelPopup();
    },
    displayConfirmCancelPopup() {
      this.$root.$emit('confirm', event, {
        title: this.$t('plannings.toolbar.modal.create_from.confirm_abort.continue'),
        description: this.$t('plannings.toolbar.modal.create_from.confirm_abort.description'),
        actionText: this.$t('plannings.toolbar.modal.create_from.confirm_abort.action'),
        cancelLabel: this.$t('actions.cancel'),
        submitLabel: this.$t('actions.submit'),
        onConfirm: () => {
          this.abortDuplicatePlanning();
        },
      });
    },
    abortDuplicatePlanning() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'create-from-template-modal');
      this.closeSidePanel();
    },
    closeSidePanel() {
      this.toggleConflictSidePanel(false);
    },
    getUserIdsWithShifts() {
      if (!this.shiftsForCurrentWeek || this.shiftsForCurrentWeek.length === 0) return [];

      // Only keep shifts from current shop
      const activeShifts = this.shiftsForCurrentWeek.filter(shift => (
        shift.attributes.shopId === parseInt(this.currentShop.id, 10)
      ));

      return uniq(activeShifts.map(shift => shift.attributes.userId));
    },
    sendEventToSvcEvents(conflictResolution) {
      const params = {
        dates: this.startDates,
        templateType: this.selectedParams.selectedCreateFrom,
        sourceTemplateApplied: this.getSourceTemplateApplied,
        conflictResolutionChoice: conflictResolution ?? 'no_conflict',
      };

      if (this.isPostesView) {
        params.posteIds = this.selectedPosteAndAbsenceIds;
      } else {
        params.userIds = this.selectedUserIds;
      }

      this.$svcEvents.create(
        EVENT_SUBTYPE_ACTION.PLANNING_APPLY_TEMPLATE,
        params,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
/* Some minor changes to stick with product requirements */
#create-from-template-modal ::v-deep {
  .sk-modal__subtitle {
    margin-top: 0;
  }

  /* TranslateX change overlay width */
  .sk-modal__wrapper {
    transition: transform .5s ease;

    .sk-modal__backdrop {
      width: calc(100% + 133px);
      background: rgba(0, 0, 0, .5) !important;
      animation: fadeIn .4s;
    }
  }
}

.create-from__modal-container--sidepanel-opened {
  transform: translateX(-133px);
}

/* Design changes specific to SelectParametersSection */
.create-from__modal-container--select-parameters ::v-deep {
  .sk-modal__section {
    padding: 19px 24px 0;
    margin: 0;
  }

  .sk-switch-v3 {
    width: 320px;
  }
}

/* Design changes specific to ConflictResolutionSection */
.create-from__modal-container--resolve-conflicts ::v-deep {
  .sk-modal__section {
    padding: 24px;
    margin: 0;
  }

  .sk-modal__header__icon {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.sk-sidepanel--opened + .sk-modal__wrapper {
  transform: translateX(-133px);
}

.modal__title__icon {
  vertical-align: middle;
}

.loader-container {
    padding: 24px 0;
}
</style>
