<!-- eslint-disable-next-line max-len -->
<template>
  <div class="sk-form-section">
    <h3 class="sk-form-section__title">
      {{ $t('employees.tabs.personal_data.profile.title') }}
    </h3>
    <div
      v-if="displayMissingInformationNotification('user_personal')"
      class="form__notification"
    >
      <MissingInformationNotification />
    </div>
    <SkForm
      :title="$t('employees.tabs.personal_data.profile.identity.title')"
      split
    >
      <div class="row sk-form__row">
        <div class="col-md-6 sk-form__col">
          <SkInput
            v-model.trim="firstName"
            v-tooltip="identityBlockedTooltip"
            :label="$t('employees.attributes.first_name.label')"
            :errored="hasFirstNameError"
            :error-message="$t('employees.attributes.first_name.error_message')"
            :disabled="!canModifyIdentity"
            data-test="employee-first_name"
            :is-highlighted="showHighlightForField(firstName)"
            @keyup="validateFirstName"
          />
        </div>
        <div class="col-md-6 sk-form__col">
          <SkInput
            v-model.trim="lastName"
            v-tooltip="identityBlockedTooltip"
            :label="$t('employees.attributes.last_name.label')"
            :errored="hasLastNameError"
            :error-message="$t('employees.attributes.last_name.error_message')"
            :disabled="!canModifyIdentity"
            data-test="employee-last_name"
            :is-highlighted="showHighlightForField(lastName)"
            @keyup="validateLastName"
          />
        </div>
      </div>
      <div class="row sk-form__row">
        <div
          v-if="hasAccesToPayIdentificationNumber"
          data-test="payroll-number"
          :class="payIdentificationInputClass"
        >
          <SkInput
            v-model="payIdentificationNumber"
            v-tooltip="identityBlockedTooltip"
            :disabled="!canModifyIdentity"
            :label="$t('employees.attributes.pay_identification_number.label')"
            data-test="employee-pay_identification_number"
          />
        </div>
        <div
          v-if="isBirthNameActive"
          :class="birthNameInputClass"
        >
          <SkInput
            v-model="birthName"
            class="personal_information__input"
            :label="$t('profile.personal_information.birth_name.label')"
            data-test="personal_information__birth_name"
          />
        </div>
      </div>
      <div
        v-if="showPunchClockPin"
        class="row sk-form__row"
      >
        <div class="col-md-6 sk-form__col d-flex align-items-center">
          <SkInput
            :value="punchClockTokenComputedValue"
            :label="punckClockTockenComputedLabel"
            class="punch-clock-input"
            disabled
          />
          <div v-if="!isCurrentlyArchived">
            <SkCircleButton
              v-if="isLegacyOrganisation"
              v-modal.send-legacy-punch-clock-modal
              v-track="'send_pin_code'"
              v-tooltip="$t('employees.tabs.personal_data.profile.identity.tooltip.send_pin')"
              class="punch-clock-input__send-pin-button"
              icon="PaperAirplaneV2Icon"
            />
            <SkCircleButton
              v-else
              v-modal.send-punch-clock-modal
              v-track="'send_pin_code'"
              v-tooltip="$t('employees.tabs.personal_data.profile.identity.tooltip.send_pin')"
              class="punch-clock-input__send-pin-button"
              icon="PaperAirplaneV2Icon"
            />
          </div>
        </div>
        <div
          v-if="canEditUser && !isCurrentlyArchived"
          class="col-md-6 sk-form__col d-flex align-items-center pl-2"
        >
          <SkOroraButton
            v-track="'reset_pin_code'"
            variant="tertiary"
            size="medium"
            data-test="employee-reset-pin-code"
            @click="resetPunchClock"
          >
            {{
              this.$t('employees.tabs.personal_data.profile.identity.links.reset_punch_clock_token')
            }}
          </SkOroraButton>
        </div>
      </div>
      <div class="row sk-form__row">
        <SkCheckBox
          v-model="isBirthNameActiveAttribute"
          class="sk-form__col d-flex align-items-center pl-2 personal_information__checkbox"
          data-test="personal_information__birth_name_checkbox"
          :label="$t('profile.personal_information.birth_name.checkbox')"
        />
      </div>
    </SkForm>
    <SkForm
      :title="$t('employees.tabs.personal_data.profile.contact.title')"
      :last="!canReadEmployeePersonalInfosAndBankData"
      split
    >
      <div class="row sk-form__row pb-1">
        <div class="col-md-12 sk-form__col">
          <SkInput
            v-model="email"
            :disabled="!canEditUser"
            :label="$t('employees.attributes.email.label')"
            :errored="hasEmailError"
            :error-message="emailErrorMessage"
            :loading="isCheckingEmailUniqueness"
            :debounce="validateEmail"
            :debounce-ms="1000"
            data-test="employee-email"
            @keyup="handleEmailKeyup"
          />
          <span
            v-if="!email && !!employee.attributes.archivedAt"
            class="archived-email-removed-warning"
          >
            {{ $t('employees.tabs.personal_data.profile.contact.email.archived_email_removed_warning') }}
          </span>
        </div>
      </div>
      <div class="row sk-form__row">
        <div class="col-md-12 sk-form__col">
          <SkPhoneInput
            v-model="phone"
            :disabled="!canEditUser"
            :default-country="defaultCountry"
            :error-message="$t('employees.attributes.phone_number.error_message')"
            :label="$t('employees.tabs.personal_data.profile.contact.fields.phone')"
            data-test="employee-phone_number"
            @input="handlePhoneInput"
          />
        </div>
      </div>
    </SkForm>
    <SkForm
      v-if="displayStaffRegister"
      :title="$t('employees.tabs.personal_data.profile.staff_register.title')"
      last
      split
    >
      <template #title-icon>
        <CircledQuestionMarkIcon
          v-tooltip.right.text-align-left="$t('employees.tabs.personal_data.profile.staff_register.tooltip')"
          width="18"
          height="18"
        />
      </template>
      <div class="row sk-form__row">
        <div class="col-md-12 sk-form__col">
          <SkSwitch
            v-model="inStaffRegister"
            :disabled="!canEditEmployeePersonalInfosAndBankData"
          />
        </div>
      </div>
    </SkForm>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <SendLegacyPunchClockModal />
      <SendPunchClockModal />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { isValidEmail } from '@skello-utils/validators';
import { httpClient } from '@skello-utils/clients';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';
import { FEATURES } from '@app-js/shared/constants/features.js';

import MissingInformationNotification from '@app-js/shared/components/PayrollPreparation/MissingInformationNotification';
import SendLegacyPunchClockModal from './SendLegacyPunchClockModal';
import SendPunchClockModal from './SendPunchClockModal';

function mapFormInputs(fields) {
  return fields.reduce((computed, field) => {
    computed[field] = {
      get() {
        return this.employee.attributes[field];
      },
      set(newValue) {
        this.setEmployeeAttributes({ [field]: newValue });
      },
    };
    return computed;
  }, {});
}

export default {
  name: 'ProfileSection',
  components: {
    SendLegacyPunchClockModal,
    SendPunchClockModal,
    MissingInformationNotification,
  },
  mixins: [payrollPreparationMixins],
  data() {
    const { employee } = this.$store.state.selectedEmployee;

    return {
      phoneObject: null,
      isBirthNameActive: false,
      birthNameValue: '',
      hasPhoneError: !!employee.attributes.phone,
      isCheckingEmailUniqueness: false,
      hasEmailError: false,
      emailErrorMessage: null,
      displayStaffRegister: false,
      FEATURES,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee', 'originalEmployeeData']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('employees', ['showIncompleteEmployees']),
    ...mapGetters('currentLicense', [
      'canEditEmployeeInfo',
      'canEditEmployeePersonalInfosAndBankData',
      'canReadEmployeeInfo',
      'canReadEmployeePersonalInfosAndBankData',
    ]),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters({ isCurrentUserSystemAdmin: 'currentLicense/isSystemAdmin' }),
    ...mapGetters('currentOrganisation', ['atLeastOneShopWithPunchClock']),
    ...mapGetters('selectedEmployee', [
      'isCurrentUserOrSystemAdmin',
      'isStrictSubordinateOfCurrentUser',
      'isCurrentlyArchived',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapFormInputs([
      'firstName',
      'lastName',
      'payIdentificationNumber',
      'punchClockToken',
      'email',
      'phone',
      'inStaffRegister',
    ]),

    // For Key Accounts that have an automatic integration to the User API (HRIS Integration),
    // We don't want managers (outside of system admin) to make changes to identity
    // Without going through the API
    canModifyIdentity() {
      if (!this.canEditUser) return false;

      const hasHrisIntegration = this.currentOrganisation.attributes.hrisIntegration;

      return !hasHrisIntegration || this.isCurrentUserSystemAdmin;
    },
    identityBlockedTooltip() {
      // No Tooltip if input not disabled
      if (this.canModifyIdentity) return null;

      // If input is disabled because of license:
      // nothing to do with integration, so no tooltip
      if (!this.canEditUser) return null;

      return this.$t('employees.tabs.personal_data.profile.identity.identity_blocked_tooltip');
    },
    canEditUser() {
      if (!this.canEditEmployeeInfo) return false;
      return (this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser);
    },
    birthName: {
      get() {
        if (this.birthNameValue !== this.originalEmployeeData.attributes.birthName) {
          return this.originalEmployeeData.attributes.birthName;
        }
        return this.employee.attributes.birthName;
      },
      set(value) {
        this.setEmployeeAttributes({ birthName: value });
      },
    },
    isBirthNameActiveAttribute: {
      get() {
        return this.isBirthNameActive;
      },
      set(value) {
        if (value) {
          this.birthName = this.originalEmployeeData.attributes.birthName;
        } else {
          this.birthName = null;
        }
        this.isBirthNameActive = value;
      },
    },
    birthNameInputClass() {
      return this.hasAccesToPayIdentificationNumber() && this.isBirthNameActive ? 'col-md-6 sk-form__col' : 'col-md-12 sk-form__col';
    },
    payIdentificationInputClass() {
      return this.isBirthNameActive ? 'col-md-6 sk-form__col' : 'col-md-12 sk-form__col';
    },
    showPunchClockPin() {
      // If there's a current shop: show punch clock token if shop enables it.
      // Otherwise, show punch clock token if at least one shop from the current
      // organisation enables it.
      const hasPunchClock = !this.navContext.viewAllShops ?
        this.currentShop.attributes.hasPunchClock :
        this.atLeastOneShopWithPunchClock;

      if (this.isCurrentUserOrSystemAdmin && hasPunchClock) return true;

      return this.isStrictSubordinateOfCurrentUser && this.canReadEmployeeInfo && hasPunchClock;
    },
    hasFirstNameError() {
      return this.firstName === null;
    },
    hasLastNameError() {
      return this.lastName === null;
    },
    punchClockTokenComputedValue() {
      return this.isCurrentlyArchived ? null : this.punchClockToken;
    },
    punckClockTockenComputedLabel() {
      return this.isCurrentlyArchived ?
        this.$t('employees.attributes.punch_clock_token.archived_employee_label') :
        this.$t('employees.attributes.punch_clock_token.label');
    },
    isLegacyOrganisation() {
      // @see https://skello.atlassian.net/browse/DEV-14131
      return skDate(this.currentOrganisation.attributes.createdAt).isBefore(skDate('2023-02-01'));
    },
    defaultCountry() {
      // To determine what country to set by default multiple options are considered in that order:
      // 1. If phone number is already set and valid, use current phone number country
      // 2. If shop country is available use it
      // 3. If organisation country is available use it (used on 'all shops' view)
      // 4. Try to determine country based on language, en -> gb is hardcoded and it doesn't work
      //    for Switzerland and Belgium
      // 5. Default to France
      return this.currentShop.attributes.country ||
        this.currentOrganisation.attributes.headquartersCountry ||
        (this.$i18n.locale === 'en' ? 'gb' : this.$i18n.locale);
    },
    hasAccesToPayIdentificationNumber() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_PAYROLL,
        this.employee.attributes.shopId,
      ) || this.isFeatureEnabled(
        FEATURES.FEATURE_PLANNING_ACCESS,
        this.employee.attributes.shopId,
      );
    },
  },
  mounted() {
    this.displayStaffRegister =
      this.isFeatureEnabled(FEATURES.FEATURE_STAFF_REGISTER, this.currentShop.id) &&
      this.isCurrentUserSystemAdmin;
    this.birthNameValue = this.originalEmployeeData.attributes.birthName;
    this.isBirthNameActive = !!this.birthName;
  },
  methods: {
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes', 'setInputEmployeeError']),
    ...mapActions('selectedEmployee', ['resetPunchClockToken']),

    resetPunchClock() {
      this.resetPunchClockToken({ employee_id: this.employee.id })
        .then(() => {
          this.$skToast({
            message: this.$t('employees.tabs.personal_data.profile.identity.links.punch_clock_token_success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.error_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    validateFirstName() {
      this.setInputEmployeeError({ firstName: this.firstName === null });
    },
    validateLastName() {
      this.setInputEmployeeError({ lastName: this.lastName === null });
    },
    handlePhoneInput(_number, phoneObject) {
      this.phoneObject = phoneObject;
      this.phone = phoneObject.number || '';
      const erroredPhone = this.phone && !this.phoneObject.valid;

      this.setInputEmployeeError({ phone: erroredPhone });
    },
    handleEmailKeyup() {
      // Disable submit button of stickyBar until debounce check the uniqueness of email
      // The debounce will activate the submit
      const submitDisabled = !!this.email;
      this.setInputEmployeeError({ email: submitDisabled });
    },
    validateEmail() {
      this.hasEmailError = this.email && !isValidEmail(this.email);
      this.emailErrorMessage = this.$t('employees.attributes.email.error_message');
      if (this.email === this.originalEmployeeData.attributes.email) return;

      if (isValidEmail(this.employee.attributes.email)) {
        this.isCheckingEmailUniqueness = true;
        httpClient
          .get('/v3/api/email_validation', { params: { email: this.employee.attributes.email } })
          .then(() => {
            this.hasEmailError = false;
            this.emailErrorMessage = null;
          })
          .catch(error => {
            this.hasEmailError = true;
            this.emailErrorMessage = error.response.data.message;
          })
          .finally(() => {
            this.isCheckingEmailUniqueness = false;
            this.setInputEmployeeError({ email: this.hasEmailError });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.punch-clock-input {
  flex: 1;
}

.form__notification {
  padding: 0 0 10px;
  margin-top: -19px;
}

.punch-clock-input__send-pin-button {
  margin-left: 7px;
}

.archived-email-removed-warning {
  color: $sk-blue-50;
  font-size: $fs-text-s;
}

.personal_information__checkbox {
  margin-left: 3px;
}
</style>
