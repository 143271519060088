import OrganisationCreation from './organisation_creation';
import UserCreation from './user_creation';
import OrganisationForm from './organisation_creation/pages/OrganisationForm.vue';
import ShopForm from './organisation_creation/pages/ShopForm.vue';
import ShopBillingForm from './organisation_creation/pages/ShopBillingForm.vue';
import OrganisationBillingForm from './organisation_creation/pages/OrganisationBillingForm.vue';

const onboardingRoutes = [
  {
    path: '',
    component: OrganisationCreation,
    children: [
      {
        path: '',
        component: OrganisationForm,
        name: 'organisation_creation',
      },
      {
        path: 'shop_form',
        component: ShopForm,
        name: 'shop_form',
        props: route => ({ parentClusterNodeId: route.query.parentClusterNodeId }),
      },
      {
        path: 'shop_billing_form',
        component: ShopBillingForm,
        name: 'shop_billing_form',
      },
      {
        path: 'organisation_billing_form',
        component: OrganisationBillingForm,
        name: 'organisation_billing_form',
      },
    ],
  },
  {
    path: '/v3/user-creation',
    component: UserCreation,
    name: 'user_creation',
  },
];

export default onboardingRoutes;
