<template>
  <div class="requests__wrapper">
    <div class="requests__navbar-separator" />
    <div class="requests__container">
      <router-view name="sidebar" />
      <div class="requests__content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';

import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'Requests',
  mixins: [RoutesMixins],
  beforeRouteEnter(to, _from, next) {
    next(async vm => {
      if (!vm.isAvailabilityRequestFeatureEnabled && !vm.isLeaveRequestsFeatureEnabled) {
        vm.redirectToHome();
      }

      vm.checkAuthorizedTab(to);

      if (to.name.includes('leave_requests') && !!to.query.show) {
        vm.$skAnalytics.track('leave_request_show_through_url');
      }
    });
  },
  beforeRouteUpdate(to, _from, next) {
    next();
    this.checkAuthorizedTab(to);
  },
  computed: {
    ...mapGetters('currentLicense', [
      'canManageEmployeeRequests',
      'canCreateSelfLeaveRequests',
      'isSystemAdmin',
      'canCreateSelfAvailabilities',
    ]),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'currentNodeShops']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('navContext', ['navContext']),
    ...mapState('requests', ['pagination']),
    availabilityRequestsDefaultRoute() {
      return {
        name: this.canManageEmployeeRequests ?
          'availability_requests_teams' :
          'availability_requests_own',
      };
    },
    leaveRequestsDefaultRoute() {
      const routeName = this.canManageEmployeeRequests ? 'leave_requests_teams' : 'leave_requests_own';

      if (this.isAllRequestsView) {
        return {
          name: routeName,
          params: {
            cluster_node_id: this.navContext.clusterNodeId ?? this.$route.params.cluster_node_id,
            shop_id: 'all',
          },
        };
      }

      return {
        name: routeName,
        params: {
          shop_id: this.currentShop.id,
        },
      };
    },
    isAvailabilityRequestFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_AVAILABILITY_REQUEST, this.currentShop.id);
    },
    isLeaveRequestsFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_LEAVE_REQUESTS, this.currentShop.id);
    },
  },
  watch: {
    currentLicense() {
      this.checkAuthorizedTab(this.$router.currentRoute);
    },
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', ['selectShop', 'fetchCurrentClusterNode']),
    ...mapMutations('requests', ['setGrantingAccess', 'setPagination']),
    async checkAuthorizedTab(toRoute) {
      this.setGrantingAccess({ isLicenseAuthorized: false });

      // when no current license: still loading, method will be retriggered once license is fetched
      if (!this.currentLicense.id) {
        return;
      }

      if (toRoute.name === 'requests' || toRoute.name === 'leave_requests') {
        this.$router.replace(this.leaveRequestsDefaultRoute);

        return;
      }

      if (toRoute.name === 'availability_requests') {
        await this.updateCurrentShop({ shopId: toRoute.params.shop_id });
        this.selectShop(this.currentShop);
        this.$router.replace(this.availabilityRequestsDefaultRoute);

        return;
      }

      // when not allowed on the page redirect to home
      if (!this.isAllowedOnTab(toRoute.name)) {
        window.location = '/';

        return;
      }

      this.setGrantingAccess({ isLicenseAuthorized: true });
    },
    isAllowedOnTab(toRoute) {
      if (toRoute === 'leave_requests_teams' || toRoute === 'availability_requests_teams') {
        return this.isSystemAdmin || this.canManageEmployeeRequests;
      }

      if (toRoute === 'leave_requests_own') {
        return this.isSystemAdmin || this.canCreateSelfLeaveRequests;
      }

      if (toRoute === 'availability_requests_own') {
        return this.isSystemAdmin || this.canCreateSelfAvailabilities;
      }

      return false;
    },
    redirectToHome() {
      window.location = '/';
    },
  },
};
</script>

<style lang="scss" scoped>
.requests__wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.requests__navbar-separator {
  border-top: 1px solid $sk-grey-10;
}

.requests__loader {
  display: flex;
  justify-content: center;
  font-size: 2em;
  padding-top: 4em;
}

.requests__container {
  display: flex;
  height: 100%;
}

.requests__content {
  padding: 32px 32px 0 0;
  flex: 1;
  overflow-y: auto;
}
</style>
