<template>
  <div>
    <div class="counters__initialization-overlay">
      <div class="counters__initialization-overlay-icon">
        <component
          :is="icon"
          height="28"
          width="28"
          :fill="$skColors.skBlue50"
        />
      </div>
      <div class="counters__initialization-overlay-title">
        <h1 class="counters__initialization-overlay-title-header sk-heading-small-semibold">
          {{ title }}
        </h1>
        <p
          v-if="hoursCounterEnabled"
          class="counters__initialization-overlay-description sk-text-medium-regular"
        >
          {{ description }}
        </p>
        <div
          v-else
          class="counters__initialization-overlay-description sk-text-medium-regular"
        >
          {{ $t('employees.tabs.counters.hours_counter.tooltip.upsell_1') }}
          <PackTag
            type="basic"
            shadow
          />
          {{ $t('employees.tabs.counters.hours_counter.tooltip.upsell_2') }}
          <div class="counters__initialization-overlay-description-end">
            {{ $t('employees.tabs.counters.hours_counter.tooltip.upsell_3') }}
            <b>{{ $t('employees.tabs.counters.hours_counter.tooltip.upsell_4') }}</b>
          </div>
        </div>
      </div>
      <template v-if="hoursCounterEnabled">
        <SkOroraButton
          type="information"
          class="counters__initialization-overlay-button"
          @click="handleInitUserClick"
        >
          {{ $t('employees.tabs.counters.hours_counter.initialization.button') }}
        </SkOroraButton>
      </template>
    </div>
    <div class="counters__initialization-overlay-background" />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import PackTag from '@app-js/shared/components/PackTag';
import { FEATURES } from '@app-js/shared/constants/features';
import InitHoursCounterModal from './InitHoursCounterModal';

export default {
  name: 'InitializationOverlay',
  components: { InitHoursCounterModal, PackTag },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo']),
    ...mapGetters('features', ['isFeatureEnabled']),

    hoursCounterEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_HOURS_COUNTER, this.employee.attributes.shopId);
    },
  },
  methods: {
    handleInitUserClick(event) {
      if (!this.canEditEmployeeInfo) {
        this.$skToast({
          message: this.$t('employees.tabs.counters.hours_counter.unauthorized'),
          variant: 'error',
        });

        return;
      }

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'init-hours-counter-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.counters__initialization-overlay-icon {
  width: 44px;
  height: 44px;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba($sk-blue, .15);
  vertical-align: middle;
}

.counters__initialization-overlay-background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, .2);
}

.counters__initialization-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 13%;
  left: 25%;
  opacity: 1;
  z-index: 1;
  width: 420px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  border-radius: 6px;
  padding: 60px 25px;
  background-color: white;
}

.counters__initialization-overlay-title {
  text-align: center;
  margin: 10px 20px !important;
}

.counters__initialization-overlay-item {
  width: 211px;
  height: 51px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid $sk-grey-10;
  box-sizing: border-box;
  border-radius: 4px;
}

.counters__initialization-overlay-description {
  margin-top: 10px;
  align-self: stretch;
  color: #000;
  text-align: center;
}

.counters__initialization-overlay-full-name {
  padding-left: 15px;
}

.counters__initialization-overlay-button {
  padding: 11.5px 120px;
}

.counters__initialization-overlay-description-end {
  padding-top: 25px;
}
.counters__initialization-overlay-title-header {
  align-self: stretch;
  color: #000;
  text-align: center;
}
</style>
