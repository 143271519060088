<!-- eslint-disable max-len -->
<template>
  <svg
    width="149"
    height="44"
    viewBox="0 0 149 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10952_9308)">
      <path
        d="M47.1047 38.2934C52.3245 38.2934 56.0465 35.2523 56.0465 31.1219C56.0465 23.1333 44.2452 24.5404 44.2452 21.8624C44.2452 20.9546 45.0622 20.3646 46.2877 20.3646C47.9218 20.3646 51.326 21.0908 53.2777 22.5433L55.638 17.5958C53.4139 15.8256 50.0551 15.054 46.9686 15.054C41.6126 15.054 37.8907 18.0951 37.8907 22.4525C37.8907 30.668 49.6012 28.4439 49.6012 31.3488C49.6012 32.2566 48.5118 32.9374 46.9686 32.9374C44.4722 32.9374 41.0226 31.8481 39.3431 30.3049L36.9375 35.0708C39.2524 37.0225 43.292 38.2934 47.1047 38.2934Z"
        :fill="fill"
      />
      <path
        d="M72.9622 15.5079L65.246 24.7219V6.02148H58.8461V37.7941H65.246V27.8084L73.3253 37.7941H81.5408L71.5551 26.2652L80.8146 15.5079H72.9622Z"
        :fill="fill"
      />
      <path
        d="M92.1049 38.248C96.7346 38.248 100.457 36.5232 102.091 33.5729L97.0523 30.4864C96.2807 31.8027 94.2382 32.7559 92.241 32.7559C89.2453 32.7559 87.2028 31.2126 86.6127 28.3531H102.544C102.681 27.4907 102.726 26.4468 102.726 25.6751C102.726 19.7291 98.0055 15.054 91.9687 15.054C85.478 15.054 80.3944 20.0468 80.3944 26.5375C80.3944 33.1644 85.5688 38.248 92.1049 38.248ZM86.6581 23.9957C87.339 21.6809 89.0184 20.3646 91.651 20.3646C94.0112 20.3646 95.963 21.8624 96.2807 23.9957H86.6581Z"
        :fill="fill"
      />
      <path
        d="M112.022 6.02148H105.622V37.7941H112.022V6.02148Z"
        :fill="fill"
      />
      <path
        d="M122.29 6.02148H115.89V37.7941H122.29V6.02148Z"
        :fill="fill"
      />
      <path
        d="M136.557 15.054C129.975 15.054 124.982 20.0468 124.982 26.6737C124.982 33.3006 129.975 38.248 136.557 38.248C143.093 38.248 148.131 33.3006 148.131 26.6737C148.131 20.0468 143.093 15.054 136.557 15.054ZM131.201 26.6737C131.201 23.5418 133.516 21.1816 136.557 21.1816C139.552 21.1816 141.913 23.5418 141.913 26.6737C141.913 29.7602 139.552 32.1204 136.557 32.1204C133.516 32.1204 131.201 29.7602 131.201 26.6737Z"
        :fill="fill"
      />
      <g clip-path="url(#clip1_10952_9308)">
        <path
          d="M7.53351 6.95278L12.6015 2.03301C15.4004 -0.684103 19.8721 -0.617763 22.5892 2.18119C25.3063 4.98013 25.2399 9.45178 22.441 12.1689L17.3731 17.0887L7.53351 6.95278Z"
          :fill="fill"
        />
        <path
          d="M2.02102 41.8357C-0.696093 39.0367 -0.629752 34.5651 2.1692 31.848L17.3731 17.0887L22.7767 22.6551C25.2266 25.1787 25.1668 29.2106 22.6431 31.6604L12.0087 41.9839C9.20979 44.701 4.73813 44.6346 2.02102 41.8357Z"
          :fill="fill"
        />
        <path
          d="M5.50613 8.92087L1.96707 12.3565C-0.556569 14.8063 -0.616384 18.8381 1.83347 21.3618L5.26925 24.901L13.3778 17.0296L5.50613 8.92087Z"
          :fill="fill"
        />
        <path
          d="M15.4051 15.0615L7.53351 6.95278L7.53331 6.95296L15.4049 15.0617L15.4051 15.0615Z"
          :fill="fill"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_10952_9308">
        <rect
          width="148.211"
          height="44"
          :fill="fill"
        />
      </clipPath>
      <clipPath id="clip1_10952_9308">
        <rect
          width="24.6632"
          height="44"
          :fill="fill"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SkelloLogo',
  props: {
    fill: {
      type: String,
      default: 'white',
    },
  },
};
</script>
