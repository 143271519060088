<template>
  <div class="annualization-user-row">
    <div class="annualization-user-row__user-info-container">
      <SkMedallion
        :text="initials"
        background-color="#2b66fe"
        color="white"
        class="annualization-user-row__medallion"
      />
      <div class="annualization-user-row__user-info">
        <span class="annualization-user-row__user-fullname">
          {{ fullName }}
        </span>
        <span class="annualization-user-row__contract-hours">
          {{ contractHours }}h
        </span>
      </div>
    </div>
    <div class="annualization-user-row__theoretical-balance">
      <SkInputGroup>
        <SkInput
          v-model="theoretical"
          :min="0"
          :placeholder="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.theoretical_balance.placeholder')"
          class="annualization-user-row__input"
          is-integer
          type="number"
          step="1"
        />
        <template #append>
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.theoretical_balance.unit') }}
        </template>
      </SkInputGroup>
    </div>
    <div class="annualization-user-row__realized-balance">
      <SkInputGroup>
        <SkInput
          v-model="realized"
          :placeholder="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.realized_balance.placeholder')"
          class="annualization-user-row__input"
          type="number"
          step="1"
        />
        <template #append>
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.theoretical_balance.unit') }}
        </template>
      </SkInputGroup>
    </div>
    <div class="annualization-user-row__remaining-balance">
      {{ remaining }}h
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserAnnualizationRow',
  props: {
    contractHours: {
      type: Number,
      required: true,
    },
    initials: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    theoreticalBalance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localTheoretical: null,
      localRealized: null,
    };
  },
  computed: {
    theoretical: {
      get() {
        return this.localTheoretical;
      },
      set(value) {
        this.localTheoretical = value;
        this.$emit('update-theoretical', value);
      },
    },

    realized: {
      get() {
        return this.localRealized;
      },
      set(value) {
        this.localRealized = value || null;
        this.$emit('update-realized', this.localRealized);
      },
    },
    remaining() {
      if (!this.realized) {
        return this.theoretical;
      }
      return this.theoretical - this.realized;
    },
  },
  watch: {
    theoreticalBalance: {
      immediate: true,
      handler(newValue) {
        this.theoretical = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-user-row {
  display: flex;
  align-items: center;
  border-bottom: 1px $sk-grey-10 solid;
  padding: 10px 0;

  &:first-child {
    border-top: 1px $sk-grey-10 solid;
  }

  &__user-info-container {
    display: flex;
    width: 162px;
  }

  &__medallion {
    margin-right: 8px;
    flex-shrink: 0;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    max-width: 120px;
    line-height: 1.2;
  }

  &__user-fullname {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__contract-hours {
    color: $sk-red-light-2;
  }

  &__theoretical-balance,
  &__realized-balance,
  &__remaining-balance {
    width: 130px;
  }

  &__realized-balance {
    margin: 0 10px;
  }

  &__remaining-balance {
    font-weight: 700;
    color: $sk-black;
    display: flex;
    justify-content: center;
  }

  &__input {
    // #append part of SkInputGroup is ~28px wide
    width: 102px;
  }
}
</style>
