<template>
  <!-- eslint-disable  max-len -->
  <div class="employee-counters__wrapper">
    <div
      v-if="loading"
      class="counters-spinner__wrapper"
    >
      <SkLoader />
    </div>
    <div
      v-if="showDeactivateButton"
      class="employee-counters_actions-wrapper"
    >
      <SkOroraButton
        variant="secondary"
        :variant-color="$skColors.skError50"
        data-test="counter-deactivate"
        @click="handleDeactivateCounter"
      >
        {{ $t('employees.tabs.counters.hours_counter.actions.deactivate') }}
      </SkOroraButton>
    </div>
    <div class="employee-counters__table-wrapper">
      <TitlesColumn :show-variable-contract-hours-line="showVariableContractHoursLine" />
      <div
        ref="countersTable"
        :style="{ width: tableWidth }"
        :class="countersTableScrollClasses"
      >
        <div :class="countersTableClasses">
          <YearColumn
            v-for="year in yearsToDisplay"
            :key="year.year"
            :year="year"
            :show-variable-contract-hours-line="showVariableContractHoursLine"
            @click="handleClickOnYearColumn(year)"
          >
            <MonthColumn
              v-for="month in year.months"
              :ref="openMonthColumn(month)"
              :key="month.month"
              :month="month"
              :show-variable-contract-hours-line="showVariableContractHoursLine"
              @click="handleClickOnMonthColumn(month)"
            >
              <WeekColumn
                v-for="week in month.weeks"
                :key="week.week"
                :week="week"
                :show-variable-contract-hours-line="showVariableContractHoursLine"
              />
            </MonthColumn>
          </YearColumn>
        </div>
      </div>
    </div>
    <InitializationOverlay
      v-if="!initializationEnabled"
      :title="$t('employees.tabs.counters.hours_counter.welcome', { name: employeeFullName })"
      :description="$t('employees.tabs.counters.hours_counter.helper')"
      icon="HistoryV2Icon"
    />
    <SkInfoCard
      v-if="majoration && hoursCounterInitialized"
      class="employee-counters__info-card"
    >
      <div class="employee-counters__info-card__title">
        {{ $t('employees.tabs.counters.hours_counter.description_title') }}
      </div>
      <div>{{ $t('employees.tabs.counters.hours_counter.majoration_description.p1') }}</div>
      <div>{{ $t('employees.tabs.counters.hours_counter.majoration_description.p2') }}</div>
      <br>
      <div>
        {{ $t('employees.tabs.counters.hours_counter.majoration_description.p3') }}
        <a
          :href="$t('employees.tabs.counters.hours_counter.article_link')"
          target="_blank"
        >
          {{ $t('employees.tabs.counters.hours_counter.intercom_link') }}
        </a>
      </div>
    </SkInfoCard>
    <SkInfoCard
      v-else-if="hoursCounterInitialized"
      class="employee-counters__info-card"
    >
      <div class="employee-counters__info-card__title">
        {{ $t('employees.tabs.counters.hours_counter.description_title') }}
      </div>
      <div>{{ $t('employees.tabs.counters.hours_counter.description.p1') }}</div>
      <div>{{ $t('employees.tabs.counters.hours_counter.description.p2') }}</div>
      <br>
      <div>
        {{ $t('employees.tabs.counters.hours_counter.description.p3') }}
        <SkOroraLink
          :href="$t('employees.tabs.counters.hours_counter.article_link')"
          target="_blank"
        >
          <span>{{ $t('employees.tabs.counters.hours_counter.intercom_link') }}</span>
        </SkOroraLink>.
      </div>
    </SkInfoCard>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <InitHoursCounterModal />
    </MountingPortal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import range from 'lodash/range';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import {
  SkOroraButton,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';

import { FEATURES } from '@app-js/shared/constants/features';
import InitHoursCounterModal from './InitHoursCounterModal';
import InitializationOverlay from './InitializationOverlay';
import MonthColumn from './MonthColumn';
import TitlesColumn from './TitlesColumn';
import WeekColumn from './WeekColumn';
import YearColumn from './YearColumn';

export default {
  name: 'HoursCounter',
  components: {
    InitHoursCounterModal,
    InitializationOverlay,
    MonthColumn,
    TitlesColumn,
    WeekColumn,
    YearColumn,
    SkOroraButton,
  },
  data() {
    return {
      tableWidth: null,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('employeeCounters', [
      'loading',
      'years',
      'majoration',
    ]),
    ...mapGetters('currentShop', [
      'isVariableContractHoursAvailable',
      'isDevFlagEnabled',
    ]),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('features', ['isFeatureEnabled']),

    hoursCounterInitialized() {
      return this.employee.attributes.hoursCounterInitialized;
    },
    countersTableClasses() {
      return {
        'employee-counters__table': true,
        'employee-counters__table--with-majoration': this.majoration,
        'employee-counters__table--with-variable-contract-hours': this.showVariableContractHoursLine,
      };
    },
    countersTableScrollClasses() {
      return {
        'employee-counters__table-scroll': true,
        'employee-counters__table-scroll-with-deactivation':
          this.hoursCounterInitialized && this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER'),
      };
    },
    yearsToDisplay() {
      return this.initializationEnabled ? this.years : this.templateYears;
    },
    templateYears() {
      const templateMonths = [];
      const initMoment = skDate().startOf('year');
      range(10).forEach(_value => templateMonths.push({ month: initMoment.add(1, 'month').locale(this._i18n._vm.locale).format('MMM'), show_dash: true, closed: true },
      ));
      return [
        {
          year: skDate().format('YYYY'),
          months: templateMonths,
        },
      ];
    },
    initializationEnabled() {
      return this.hoursCounterInitialized &&
        this.isFeatureEnabled(FEATURES.FEATURE_HOURS_COUNTER, this.employee.attributes.shopId);
    },
    showVariableContractHoursLine() {
      return this.isVariableContractHoursAvailable && this.years.some(
        year => year.months?.some(
          month => month.weeks?.some(
            week => week.variable_contract_hours?.cycles,
          ),
        ),
      );
    },
    employeeFullName() {
      return this.fullName(this.employee);
    },
    showDeactivateButton() {
      return this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER') && this.initializationEnabled;
    },
  },
  watch: {
    employee(newValue) {
      if (newValue && !newValue.attributes.onDayRate) {
        this.fetchSelectedEmployeeHoursCounter({
          user_id: newValue.id,
          date: skDate().format(),
          silentLoading: false,
        });
      }
    },
    loading(isLoading) {
      if (!isLoading) {
        this.$nextTick(() => {
          if (!this.simplebar) {
            this.simplebar = new SimpleBar(this.$refs.countersTable);
          }

          this.scrollToMonth();
        });
      }
    },
  },
  mounted() {
    if (this.initializationEnabled) {
      this.fetchSelectedEmployeeHoursCounter({
        user_id: this.employee.id,
        date: skDate().format(),
        silentLoading: false,
      });

      this.simplebar = new SimpleBar(this.$refs.countersTable);
    }

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    ...mapActions('employeeCounters', ['fetchSelectedEmployeeHoursCounter']),
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),
    openMonthColumn(month) {
      return month.closed ? '' : 'openMonthColumn';
    },
    scrollToMonth() {
      const monthPosition = this.$refs.openMonthColumn[0].$el.getClientRects()[0].left;
      const offSet = this.$refs.countersTable.getClientRects()[0].left;

      this.simplebar.getScrollElement().scrollTo({
        left: monthPosition - offSet,
        behavior: 'smooth',
      });
    },
    // eslint-disable-next-line func-names
    handleWindowResize: debounce(function () {
      const table = this.$refs.countersTable;
      this.tableWidth = `${table.parentElement.clientWidth - (table.offsetLeft + 20)}px`;
    }, 300),
    handleClickOnYearColumn(column) {
      if (!column.closed) return;

      this.fetchSelectedEmployeeHoursCounter({
        user_id: this.employee.id,
        date: column.link,
        silentLoading: true,
      })
        .then(() => {
          this.scrollToMonth();
        });
    },
    handleClickOnMonthColumn(column) {
      if (!column.closed) return;

      this.fetchSelectedEmployeeHoursCounter({
        user_id: this.employee.id,
        date: column.link,
        silentLoading: true,
      });
    },
    handleDeactivateCounter() {
      this.emitOnRoot(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t('employees.tabs.counters.hours_counter.deactivate_modal.description'),
        onConfirmAsync: async () => {
          this.$skAnalytics.track('deactivate_user_hour_tracker');
          await httpClient.patch(`/v3/api/users/${this.employee.id}/initial_counters`, {
            deactivate_counter: true,
            shop_id: this.employee.attributes.shopId,
            user_id: this.employee.id,
          });
          this.$skToast({
            message: this.$t('employees.tabs.counters.hours_counter.deactivate_modal.toaster.success'),
            variant: 'success',
          });
          this.setEmployeeAttributes({
            hoursCounterInitialized: false,
            counterInitializationDoneAt: null,
          });
        },
        submitLabel: this.$t('actions.deactivate'),
        title: this.$t(
          'employees.tabs.counters.hours_counter.deactivate_modal.title',
          { name: this.employeeFullName },
        ),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-sidepanel-container {
  position: sticky;
  top: 150px;
}

.employee-counters__table--with-majoration,
.employee-counters__table--with-variable-contract-hours {
  height: 555px;
}

.employee-counters__table--with-majoration.employee-counters__table--with-variable-contract-hours {
  height: 615px;
}

.employee-counters_actions-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
  padding: 12px;
  background: #FFF;
  border-bottom: 1px solid #DDD;
}
</style>
