<template>
  <div
    :class="kpiTooltipWrapperClass"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <template v-if="!!tooltipData && isPerformanceTooltip">
      <div class="kpi-tooltip__title">
        {{ tooltipData.title }}
      </div>

      <div class="kpi-tooltip__numbers__wrapper">
        <span class="kpi-tooltip__numbers">
          <span class="kpi-tooltip__numbers__label">
            {{ $t('kpis.performance_tooltip.predicted') }}
          </span>
          <span class="kpi-tooltip__numbers__value">
            {{ tooltipData.predictedValue }}
          </span>
          <span class="kpi-tooltip__numbers__unit">
            {{ tooltipData.unit }}
          </span>
        </span>

        <span class="kpi-tooltip__numbers__seperator" />

        <span class="kpi-tooltip__numbers">
          <span class="kpi-tooltip__numbers__label">
            {{ $t('kpis.performance_tooltip.real') }}
          </span>
          <span class="kpi-tooltip__numbers__value">
            {{ tooltipData.realValue }}
          </span>
          <span class="kpi-tooltip__numbers__unit">
            {{ tooltipData.unit }}
          </span>
        </span>
      </div>

      <div class="kpi-tooltip__performance__wrapper">
        <PerformanceArrow
          :performance="tooltipData.performance"
          :arrow="tooltipData.arrow"
        />
        <div
          :class="performanceClassName"
          class="kpi-tooltip__performance__difference"
        >
          {{ $t('kpis.performance_tooltip.difference') }}
          <span class="kpi-tooltip__performance__difference--numbers">
            <span>{{ formatNumber(tooltipData.differenceComputation) }}</span>
            <span v-if="!infinitePerformance">
              ({{ formatNumber(tooltipData.differencePercentage) }}%)
            </span>
          </span>
        </div>
      </div>
    </template>
    <template v-if="!isPerformanceTooltip">
      <div class="pack-offer-tooltip__description">
        <span>{{ $t('kpis.pack_offers.tooltip.introduction') }}
          {{ packLimitationText }}
        </span>
      </div>

      <div class="pack-offer-tooltip__button">
        <SkOroraButton
          size="small"
          @click.stop="clickOnUpsell"
        >
          {{ $t('kpis.pack_offers.tooltip.upsell_button') }}
        </SkOroraButton>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'KpiTooltip',
  props: {
    tooltipData: {
      type: Object,
      default: null,
    },
    tooltipType: {
      type: String,
      default: null,
    },
    packLimitationText: {
      type: String,
      default: '',
    },
  },
  computed: {
    performanceClassName() {
      return `kpi-tooltip__performance__difference-${this.tooltipData.performance}`;
    },
    infinitePerformance() {
      return this.tooltipData.predictedValue === 0 && this.tooltipData.realValue !== 0;
    },
    kpiTooltipWrapperClass() {
      return {
        'pack-offer-tooltip__wrapper': !this.isPerformanceTooltip,
        'kpi-tooltip__wrapper': this.isPerformanceTooltip,
      };
    },
    isPerformanceTooltip() {
      return this.tooltipType === 'performance';
    },
  },
  methods: {
    // Display numbers like this : -100.01 / 0.00 / +100.01
    formatNumber(num) {
      let display = num.toFixed(2);
      if (num > 0) display = `+${display}`;
      return display;
    },
    clickOnUpsell() {
      this.emitOnRoot('click-on-upsell');
    },
    onMouseEnter() {
      this.$emit('mouse-enter');
    },
    onMouseLeave() {
      this.$emit('mouse-leave');
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-tooltip__wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 260px;
  height: 110px;
  padding: 20px;
  background: black;
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .16);
  z-index: 200;
}

.kpi-tooltip__title {
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
}

.kpi-tooltip__numbers__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.kpi-tooltip__numbers {
  display: flex;
  align-items: baseline;
}

.kpi-tooltip__numbers__label {
  margin-right: 3px;
  font-size: $fs-text-s;
}

.kpi-tooltip__numbers__value {
  font-size: $fs-text-m;
}

.kpi-tooltip__numbers__unit {
  font-size: $fs-text-xs;
  margin-left: 4px;
}

.kpi-tooltip__numbers__seperator {
  margin: 0 12px;
  border: 1px solid rgba(255, 255, 255, .24);
  height: 15px;
}

.kpi-tooltip__performance__wrapper {
  display: flex;
}

.kpi-tooltip__performance__difference {
  margin-left: 10px;
  font-size: $fs-text-s;
}

.kpi-tooltip__performance__difference--numbers {
  margin-left: 4px;
}

.kpi-tooltip__performance__difference-positive {
  color: $sk-success;
}

.kpi-tooltip__performance__difference-neutral {
  color: $sk-grey;
}

.kpi-tooltip__performance__difference-negative {
  color: $sk-error;
}

.pack-offer-tooltip__wrapper {
  text-align: start;
  width: 320px;
  padding: 20px;
  background: black;
  font-size: $fs-text-m;
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .16);
  z-index: 250;
}

.pack-offer-tooltip__description {
  display: flex;
  line-height: 2;
  margin-bottom: 15px;
}

.pack-offer-tooltip__button {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 36px;
  font-weight: bold;
  background: $sk-blue;
  border-radius: 4px;
  z-index: 2;
}
</style>
