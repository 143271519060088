export const KEY_AUTH_ACCESS_TOKEN = 'SkelloApp_AccessToken';
export const KEY_AUTH_REFRESH_TOKEN = 'SkelloApp_RefreshToken';
export const SUPERADMIN_REFRESH_TOKEN = 'SkelloApp_Superadmin_RefreshToken';
export const KEY_SIGNING_OUT = 'signing_out';
// delete this when https://skello.atlassian.net/browse/REAPER-365 ready
export const KEY_AUTH_ADMIN_REFRESH_TOKEN = 'SkelloApp_SkelloUser_RefreshToken';
export const KEY_AUTH_IMPERSONATE_USER_ID = 'SkelloApp_ImpersonateUserId';

export const BaseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Source-Client': 'skelloApp:web',
};
