<template>
  <div
    ref="rowContainer"
    :class="kpiRowClass"
  >
    <KpiLabel
      :key="kpiName + '_label'"
      :label="label"
      :kpi-name="kpiName"
      :current-shop="currentShop"
      :collapse-state="collapseState"
      :label-collapsable="labelCollapsable"
      :predicted="predicted"
      :icon-label-tooltip="iconLabelTooltip"
      label-icon="CircledQuestionMarkIcon"
      class="kpi-row__cell kpi-row__cell--first"
    />
    <div class="kpi-row__middle-wrapper">
      <!-- change key and remove index if data got a uniq id -->
      <KpiCell
        v-for="(data, index) in filteredDays"
        :id="cellIdentifier(index)"
        :key="index"
        :label="label"
        :number-of-employees="numberOfEmployeesDay(index)"
        :kpi-name="kpiName"
        :date="dateForCell(index)"
        :is-last-row="lastRow"
        :predicted-kpi="computeFilteredPredictedKpis(index)"
        :real-kpi="computeFilteredRealKpis(index)"
        :current-shop="currentShop"
        :unit="unit"
        :icon="icon"
        :predicted="predicted"
        :readonly="isCellReadonly(index)"
        :tooltip-message="computeTooltipMessage(index)"
        :kpis-feature-states="kpisFeatureStates"
        class="kpi-row__cell kpi-row__data-cell"
        @focus-grab="onFocusGrab"
      />
    </div>

    <KpiCell
      v-if="showTotalCell"
      :key="kpiName + '_total'"
      :label="label"
      :kpi-name="kpiName"
      :predicted-total="predictedKpiTotal"
      :real-total="realKpiTotal"
      :over-hours="overHours"
      :unit="unit"
      :icon="icon"
      :predicted="predicted"
      :kpis-feature-states="kpisFeatureStates"
      total
      class="kpi-row__cell kpi-row__total-cell"
    />
    <div
      v-else
      class="kpi-row__cell kpi-row__total-cell"
    />
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import uniq from 'lodash/uniq';
import KpiLabel from '../KpiLabel';
import KpiCell from '../KpiCell';

export default {
  name: 'KpiRow',
  components: {
    KpiLabel,
    KpiCell,
  },
  props: {
    kpiName: {
      type: String,
      required: true,
    },
    numberOfEmployees: {
      type: Array,
      default: null,
    },
    date: {
      type: Object,
      default: null,
    },
    predictedKpis: {
      type: Object,
      default: null,
    },
    realKpis: {
      type: Object,
      default: null,
    },
    currentShop: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelCollapsable: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    predicted: {
      type: Boolean,
      default: false,
    },
    firstRow: {
      type: Boolean,
      default: false,
    },
    lastRow: {
      type: Boolean,
      default: false,
    },
    collapseState: {
      type: Boolean,
      default: false,
    },
    iconLabelTooltip: {
      type: Object,
      required: true,
    },
    kpisFeatureStates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedCells: [],
      isSelecting: false,
    };
  },
  computed: {
    ...mapState('planningsState', ['shopPlanningConfig']),
    ...mapGetters('planningsState', ['isPostesView']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek', 'dayCellShifts']),
    ...mapGetters('planningsUsers', ['dayFilteredUsers']),
    ...mapGetters('currentShop', ['isShopLinkedToPosPartner']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),
    kpiRowClass() {
      return {
        'kpi-row__container': true,
        'kpi-row__first': this.firstRow,
        'kpi-row__last': this.lastRow,
        'kpi-row__blurred': this.isBlurredRow,
        'kpi-row__fade-in': this.isProgressiveLoadingEnabled,
      };
    },
    visibleDays() {
      return this.shopPlanningConfig.attributes.visibleDays;
    },
    filteredDays() {
      return this.visibleDays.filter(visibleDay => visibleDay);
    },
    filteredPredictedKpis() {
      return this.predictedKpis.daily.filter((data, index) => this.visibleDays[index]);
    },
    filteredRealKpis() {
      return this.realKpis.daily.filter((data, index) => this.visibleDays[index]);
    },
    predictedKpiTotal() {
      // Note : no check on this.predicted because we need the data in both tabs
      return this.predictedKpis ? this.predictedKpis.total : 0;
    },
    realKpiTotal() {
      return (!this.predicted && this.realKpis) ? this.realKpis.total : 0;
    },
    overHours() {
      return (!this.predicted && this.realKpis) ? this.realKpis.total_over_hours : null;
    },
    isEditableRow() {
      return ['revenue', 'volume'].includes(this.kpiName);
    },
    isBlurredRow() {
      if (this.kpiName === 'worked_hours') return false;

      const predictedOrReal = this.predicted ? 'predicted' : 'real';

      const kpiName = [
        'total_salary_mass',
        'total_salary_mass_with_costs',
        'salary_mass_productive_with_costs',
        'salary_mass_unproductive_with_costs',
      ].includes(this.kpiName) ? 'salary_mass' : this.kpiName;
      return !this.kpisFeatureStates[predictedOrReal][kpiName];
    },
    today() {
      return skDate().utc(true).startOf('day');
    },
    yesterday() {
      return this.today.clone().subtract(1, 'day');
    },
    currentDateHours() {
      return skDate().utc(true).hours();
    },
    showTotalCell() {
      return !this.isPostesView;
    },
  },
  mounted() {
    this.listenOnRoot('mouse-over-content', this.onMouseOverContent);
    this.listenOnRoot('mouse-up-content', this.onFocusRelease);
  },
  methods: {
    ...mapActions('partnersTools', ['fetchLadditionShops']),

    numberOfEmployeesDay(index) {
      if (this.isProgressiveLoadingEnabled) {
        return this.numberOfEmployees ? this.numberOfEmployees[index] : 0;
      }
      return this.numberOfEmployeesOnPlanningDay(index);
    },
    numberOfEmployeesOnPlanningDay(index) {
      const cellDate = this.dateForCell(index);
      if (!cellDate) return null;
      // date
      const date = skDate(cellDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const isAbsence = shift => shift.relationships.poste.attributes.absenceKey !== null;
      const isFromCurrentShop = shift => shift.attributes.shopId === Number(this.currentShop.id);

      const filteredShifts = this.dayCellShifts(date, this.shiftsForCurrentWeek).filter(
        shift => !!shift.attributes.userId && // unassigned shifts
          !isAbsence(shift) &&
          isFromCurrentShop(shift),
      );

      const usersWithShifts = uniq(filteredShifts.map(shift => shift.attributes.userId));

      return this.dayFilteredUsers(date)
        .filter(user => usersWithShifts.includes(Number(user.id))).length;
    },
    dateForCell(dayIndex) {
      if (this.date) {
        return skDate(this.date).add(this.daysToAdd(dayIndex), 'days').format('DD-MM-YYYY');
      }
      return null;
    },
    computeFilteredPredictedKpis(index) {
      return this.predictedKpis ?
        this.filteredPredictedKpis[index] :
        null;
    },
    computeFilteredRealKpis(index) {
      return this.realKpis ?
        this.filteredRealKpis[index] :
        null;
    },
    computeTooltipMessage(index) {
      if (!this.isCellReadonly(index) || !this.isEditableRow) return '';

      if (!this.isShopLinkedToPosPartner) return this.$t('kpis.cell.tooltip.without_pos');

      const hours = this.currentShop.attributes.openingTime.split(':')[0];
      return this.$t(`kpis.cell.tooltip.with_pos.${this.cellStatus(index)}`, { shop_opening_time: hours });
    },
    isCellReadonly(dayIndex) {
      // Only these two KPIs are editable
      if (!this.isEditableRow) return true;

      // Predicted TAB is always editable
      if (this.predicted) return false;

      // we need to check when the cell's date is compared to the current date
      // since we need a check for each day, we have to do a comparison between
      // today and cell's date

      // Real TAB
      // If any of the tills integrations is activated:
      if (this.isShopLinkedToPosPartner) {
        const cellStatus = this.cellStatus(dayIndex);
        // Cell is always editable if it is more than 24h before (in the past)
        return !(cellStatus === 'past' || cellStatus === 'editable');
      }

      // If cell is in the future => non editable
      // In all other cases, revenue & volume are editable
      return this.cellDate(dayIndex) > this.today;
    },
    cellStatus(dayIndex) {
      const cellDate = this.cellDate(dayIndex);

      if (cellDate.isBefore(this.yesterday)) return 'past';
      if (cellDate.isAfter(this.today)) return 'future';
      if (cellDate.isSame(this.today)) return 'today';

      // last case, cell **is** yesterday
      // TODO: DEV-9158 -> remove the hard coded time to 8am

      const hours = Number(this.currentShop.attributes.openingTime.split(':')[0]);
      if (this.currentDateHours < hours) return 'editable_soon';

      return 'editable';
    },
    daysToAdd(dayIndex) {
      const dayOffsets = this.visibleDays.reduce((acc, item, index) => {
        if (item) acc.push(index);
        return acc;
      }, []);

      return dayOffsets[dayIndex]; // Days to add based on visible days on planning
    },
    cellDate(dayIndex) {
      return skDate(this.date).utc(true).startOf('day').add(this.daysToAdd(dayIndex), 'day');
    },
    batchUpdateSelectedCells() {
      // If we only have the first cell selected
      if (this.selectedCells.length === 1) return;

      const indexOfValueToCopy = this.selectedCells[0].split(':').slice(-1);
      const valueToCopy = this.predicted ?
        this.computeFilteredPredictedKpis(indexOfValueToCopy) :
        this.computeFilteredRealKpis(indexOfValueToCopy);

      const kpisToUpdate = [];
      this.selectedCells.forEach(cellIdentifier => {
        const [kpiName, date] = cellIdentifier.split(':');
        const [target, name] = kpiName.split('-');

        kpisToUpdate.push({
          date, // Date used by the server to update this kpi for this date
          predicted_or_real: target,
          kpi_name: name,
          kpi_new_value: valueToCopy,
        });
      });

      httpClient
        .patch('/v3/api/plannings/kpis', {
          shop_id: this.currentShop.id,
          kpis: kpisToUpdate,
        })
        .catch(() => {
          this.emitOnRoot('kpi-request-failure');
        });
    },
    selectCell(cellId) {
      // cellId already selected => no need to alter the array
      if (this.selectedCells.includes(cellId)) return;

      this.selectedCells.push(cellId);
    },
    selectCells(cellId) {
      const dataCells = Array.from(this.$refs.rowContainer.querySelectorAll('.kpi-row__data-cell'));
      const dataCellsIdMap = dataCells.map(cellNode => cellNode.id);
      const originalCellId = this.selectedCells[0];
      const indexOfOriginalCell = dataCellsIdMap.indexOf(originalCellId);
      const indexOfTargetCell = dataCellsIdMap.indexOf(cellId);

      // Dont allow selection of orginal cell or the ones on the left of it
      if (indexOfTargetCell < indexOfOriginalCell) return;

      // Dont allow selection of readonly cells
      if (Array.from(dataCells[indexOfTargetCell].classList).includes('kpi-cell__readonly')) return;

      // Clean slate
      this.clearAllSelectionClasses();

      // Select all cells between original cell & target cell
      dataCells.slice(indexOfOriginalCell, indexOfTargetCell + 1).forEach(cell => {
        this.selectCell(cell.id);
      });

      // Unselect all cells after target cell
      dataCells.slice(indexOfTargetCell + 1, dataCells.length).forEach(cell => {
        this.deselectCell(cell.id);
      });

      // Update the DOM classes accordingly
      this.updateSelectionClasses();
    },
    deselectCell(cellId) {
      // cellId not selected => no need to alter the array
      if (!this.selectedCells.includes(cellId)) return;
      this.selectedCells.splice(this.selectedCells.indexOf(cellId), 1);
    },
    deselectAllCells() {
      this.clearAllSelectionClasses();
      this.selectedCells = [];
    },
    clearAllSelectionClasses() {
      this.selectedCells.forEach(cellId => {
        document.getElementById(cellId).classList.remove('copyselected');
        document.getElementById(cellId).classList.remove('copyselected__last');
      });
    },
    updateSelectionClasses() {
      const cellsToStyle = this.selectedCells.slice(1, this.selectedCells.length);
      if (cellsToStyle.length === 0) return;

      const lastCellIndex = cellsToStyle.length - 1;

      cellsToStyle.forEach((cellId, index) => {
        if (index === lastCellIndex) {
          document.getElementById(cellId).classList.add('copyselected__last');
        } else {
          document.getElementById(cellId).classList.add('copyselected');
        }
      });
    },
    onMouseOverContent(event) {
      if (!this.isSelecting) return;
      if (!['revenue', 'volume'].includes(this.kpiName)) return;

      const targetedCell = event.target.closest('.kpi-row__data-cell');
      if (!targetedCell) return;

      // Only target cells of the current row
      if (!targetedCell.id || !targetedCell.id.includes(this.kpiName)) return;

      this.selectCells(String(targetedCell.id));
    },
    onFocusGrab(targetedCell) {
      this.selectCell(String(targetedCell.id));
      this.isSelecting = true;
    },
    onFocusRelease() {
      if (!this.isSelecting) return;
      this.isSelecting = false;
      this.batchUpdateSelectedCells();
      this.deselectAllCells();
    },
    cellIdentifier(index) {
      if (!['revenue', 'volume'].includes(this.kpiName) || !this.date) return null;
      const predictedOrReal = this.predicted ? 'predicted' : 'real';

      return `${predictedOrReal}-${this.kpiName}:${this.dateForCell(index)}:${index}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-row__container {
  display: flex;
  font-style: normal;
  font-weight: normal;
  height: 34px;
}

.kpi-row__fade-in {
  animation: fadeIn 0.3s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.kpi-row__cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $sk-grey-10;
  border-right: 1px solid $sk-grey-10;

  &--first {
    border-left: 0;
    border-bottom: 0;
    justify-content: left;
    padding-left: 15px;
  }
}

.copyselected {
  border-top: 1px solid $sk-blue;
  border-bottom: 1px solid $sk-blue;
}

.copyselected__last {
  border-top: 1px solid $sk-blue;
  border-bottom: 1px solid $sk-blue;
  border-right: 1px solid $sk-blue;
}

.kpi-row__first {
  height: 56px;

  ::v-deep .kpi-label__envelope {
    background-color: $sk-blue-5;
    border-radius: 3px 0 0 3px;

    .kpi-label__text {
      padding: 5px 5px 5px 15px;
    }
  }

  ::v-deep .kpi-cell__wrapper {
    background-color: $sk-blue-5;
  }

  ::v-deep .kpi-row__total-cell .kpi-cell__wrapper {
    background-color: $sk-blue-5;
    border-radius: 0 3px 3px 0;

    /* TODO : Find a way to remove this
     * Simplebar & border affects this when it shouldnt
     */
    .kpi-cell__performance-arrow-wrapper {
      width: 15px;
      height: 12px;
    }
  }

  ::v-deep .kpi-cell__value {
    font-weight: $fw-semi-bold;
  }

  ::v-deep .kpi-cell__unit {
    font-weight: $fw-semi-bold;
  }

  ::v-deep .kpi-row__total-cell {
    border-bottom: 0;
  }
}

.kpi-row__last {
  bottom: 0;
  height: 44px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, .08);
  width: 100%;

  .kpi-row__cell {
    border-bottom: 0;
  }

  .kpi-row__total-cell {
    box-shadow: none;
    outline: 1px solid $sk-grey-10;
  }
}

.kpi-row__data-cell {
  flex-grow: 1;
  flex-basis: 0;
}

.kpi-row__total-cell {
  font-weight: $fw-semi-bold;
  flex: 0 0 145px;
}

@media screen and (min-width: 1200px) {
  .kpi-row__total-cell {
    flex: 0 0 145px;
  }
}

::v-deep .kpi-row__total-cell .kpi-cell__svg-wrapper {
  right: 8px;
}

::v-deep .kpi-row__total-cell .kpi-cell__unit--right {
  right: 10px;
}

/*
 * When no planning is displayed
 */
.kpis__no-planning-data {
  .kpi-row__container {
    .kpi-row__data-cell,
    .kpi-row__total-cell {
      visibility: hidden;
    }
  }

  .kpi-row__container.kpi-row__last .kpi-row__data-cell,
  .kpi-row__container.kpi-row__last .kpi-row__total-cell {
    visibility: visible;
  }

  .kpis__sticky-section__container .kpi-row__data-cell,
  .kpis__sticky-section__container .kpi-row__total-cell {
    visibility: visible;
  }
}

/*
 * When a pack offer requires a blurred row
 */
.kpi-row__blurred {
  ::v-deep .kpi-cell__wrapper {
    filter: blur(5px);
  }

  ::v-deep .kpi-cell__readonly {
    background: white;
  }
}

.kpi-row__middle-wrapper {
  display: flex;
  width: 100%;
  padding: 0;
}
</style>
