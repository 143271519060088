<template>
  <SkModal
    id="create-text-document-modal"
    ref="createTextDocumentModal"
    :modal-title="modaleTitle"
    :tracking-options="trackingOptions"
    class="create-text-document-modal"
    size="ds-medium"
    @show="fetchData"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSection
        v-if="!loadingFetch && hasTextDocumentTemplatesEnabled && isMissingBodyVariables"
        class="missing-fields-message"
      >
        <div class="missing-fields-message__medallion-wrapper">
          <SkMedallion
            icon="ExclamationMarkIcon"
            background-color="#d03e50"
            color="white"
            border-color="white"
          />
        </div>
        <div class="missing-fields-message__tooltip">
          {{ $t('employees.tabs.documents.create_text_document_modal.missing_values_message',
                { employee: selectedEmployeeFullName }) }}
        </div>
      </SkModalSection>

      <SkModalSection
        :class="bodySectionClasses"
        border-bottom="none"
      >
        <!-- Initial spinner -->
        <div
          v-if="loadingFetch"
          class="create-text-document-modal__spinner-container"
        >
          <SkLoader
            class="create-text-document-modal__spinner"
            size="large"
          />
        </div>
        <template v-else>
          <!-- NO TEMPLATE -->
          <NoVisibleTextDocumentTemplates
            v-if="!hasTextDocumentTemplatesEnabled"
            :can-create-text-document="hasShopDocumentTemplatesAccess(selectedEmployeeShopId)"
            :tracking-options="trackingOptions"
          />
          <!-- LIST OF TEMPLATES -->
          <div v-else>
            <p>
              {{
                $t('employees.tabs.documents.create_text_document_modal.helptext',
                   { employee: selectedEmployeeFullName })
              }}
            </p>
            <table class="text-document-templates__table">
              <tr
                v-for="textDocumentTemplate in textDocumentTemplateEnabled"
                :key="textDocumentTemplate.id"
                :class="{
                  table__row: true,
                  'table__row--selected': selectedStatus(textDocumentTemplate),
                  'table__row--inactive': !canBeSelected(textDocumentTemplate)
                }"
              >
                <td
                  class="table__cell"
                  @click="toggleSelect(textDocumentTemplate)"
                >
                  <SkMedallion
                    icon="FileIcon"
                    background-color="#FFF2D9"
                    color="#FDBF3F"
                    :errored="!canBeSelected(textDocumentTemplate)"
                    :error-tooltip="missingFieldsTooltip(textDocumentTemplate)"
                  />
                </td>
                <td
                  class="table__cell"
                  @click="toggleSelect(textDocumentTemplate)"
                >
                  <div class="template_infos">
                    <div class="template_infos__title">
                      {{ textDocumentTemplate.attributes.title }}
                    </div>
                    <div class="template_infos__labels">
                      <div>
                        {{ $t('shop_settings.text_document_templates.table.rows.updated_on',
                              { date: formattedDate(textDocumentTemplate.attributes.updatedAt) })
                        }}
                      </div>
                      <template v-if="hasShopDocumentTemplatesAccess(selectedEmployeeShopId)">
                        <div class="dot-link__dot" />
                        <div>
                          {{ getCreator(textDocumentTemplate) }}
                        </div>
                      </template>
                    </div>
                  </div>
                </td>
                <td
                  class="table__cell table__cell--right"
                  @click.prevent="toggleSelect(textDocumentTemplate)"
                >
                  <CheckMarkIcon
                    v-if="selectedStatus(textDocumentTemplate)"
                    fill=""
                    class="check-mark-icon__fill"
                  />
                  <SkOroraButton
                    v-else
                    v-track="'hris_select_template'"
                    variant="tertiary"
                    :disabled="!canBeSelected(textDocumentTemplate)"
                    @click.stop="toggleSelect(textDocumentTemplate)"
                  >
                    {{ selectLabel(textDocumentTemplate) }}
                  </SkOroraButton>
                </td>
              </tr>
            </table>
          </div>
        </template>
      </SkModalSection>
    </template>

    <template
      v-if="!hasTextDocumentTemplatesEnabled"
      #footer
    >
      <div />
    </template>

    <template #submit-btn>
      <SkOroraButton
        v-track="'multicontract_validate_on_contract_creation_document'"
        :loading="loadingNewTextDocument || localLoadingNewTextDocument"
        :disabled="submitDisabled"
        @click="handleSubmit"
      >
        {{ $t('employees.tabs.documents.create_text_document_modal.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

import NoVisibleTextDocumentTemplates from '@app-js/shop_settings/pages/DocumentsManagement/TextDocumentTemplates/NoVisibleTextDocumentTemplates.vue';

export default {
  name: 'CreateTextDocumentModal',
  components: { NoVisibleTextDocumentTemplates },
  props: {
    folder: {
      type: String,
      default: null,
    },
    trackingOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * When set to true
     * The TextDocumentTemplate data is fetched
     * for a specific contract by sending contract_id
     * Otherwise, the backend uses employee.active_contract
     */
    applyOnSelectedContract: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTemplateId: null,
      localLoadingNewTextDocument: false,
    };
  },
  computed: {
    ...mapState('config', {
      templateVariablesKeys: state => state.config.text_document_template_variables_keys,
    }),
    ...mapState('selectedEmployee', {
      selectedEmployeeId: state => state.employee.id,
      selectedEmployeeShopId: state => state.employee.attributes.shopId,
    }),
    ...mapState('contracts', ['selectedContract']),
    ...mapState('textDocumentTemplates', [
      'loadingFetch',
      'loadingNewTextDocument',
    ]),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('textDocumentTemplates', [
      'hasTextDocumentTemplatesEnabled',
      'textDocumentTemplateEnabled',
      'missingBodyVariables',
      'isMissingBodyVariables',
    ]),
    ...mapGetters('currentShop', ['hasShopDocumentTemplatesAccess']),
    ...mapGetters('currentLicense', ['canManageEmployeeDocuments']),
    ...mapGetters({
      fullName: 'employees/fullName',
      selectedEmployeeFullName: 'selectedEmployee/fullName',
    }),
    modaleTitle() {
      return this.applyOnSelectedContract ?
        this.$t('employees.tabs.documents.create_text_document_modal.title.apply') :
        this.$t('employees.tabs.documents.create_text_document_modal.title.create');
    },
    bodySectionClasses() {
      return {
        'create-text-document-modal__body': true,
        'flex-center': this.fetchingData,
      };
    },
    selectLabel() {
      return textDocumentTemplate => {
        if (this.selectedStatus(textDocumentTemplate)) {
          return this.$t('shop_settings.text_document_templates.table.rows.selected');
        }
        return this.$t('shop_settings.text_document_templates.table.rows.select');
      };
    },
    missingFieldsTooltip() {
      return textDocumentTemplate => {
        const missingFields = this.missingBodyVariables(textDocumentTemplate);
        if (missingFields.length === 0) return null;

        return [
          this.$t('employees.tabs.documents.create_text_document_modal.missing_values_tooltip'),
          ...missingFields.map(field => `- ${this.templateVariablesKeys.tooltips[field]}`),
        ].join('<br>');
      };
    },
    fetchingData() {
      return this.loadingFetch || this.loadingNewTextDocument;
    },
    canBeSelected() {
      return textDocumentTemplate => {
        const missingFields = this.missingBodyVariables(textDocumentTemplate);
        return missingFields.length === 0;
      };
    },
    submitDisabled() {
      return this.selectedTemplateId === null;
    },
  },
  methods: {
    ...mapActions('textDocumentTemplates', ['fetchTextDocumentTemplates']),
    ...mapMutations('contracts', ['unsetSelectedContract']),
    getCreator(textDocumentTemplate) {
      return textDocumentTemplate.relationships.creator ?
        this.$t(
          'shop_settings.text_document_templates.table.rows.created_by',
          { creator: this.fullName(textDocumentTemplate.relationships.creator) },
        ) : '-';
    },
    fetchData() {
      if (this.hasShopDocumentTemplatesAccess(this.selectedEmployeeShopId) &&
      this.canManageEmployeeDocuments) {
        const params = {
          employee_id: this.selectedEmployeeId,
          cluster_node_id: this.navContext.clusterNodeId,
          shopId: this.selectedEmployeeShopId,
        };
        if (this.applyOnSelectedContract) params.contract_id = this.selectedContract.id;
        this.fetchTextDocumentTemplates(params)
          .catch(() => {
            // FIXME: handle redirect if API returns 404 (not allowed to see selectedEmployeeId)
            this.$skToast({
              message: 'Cannot read this employee',
              variant: 'error',
              dimension: 'short',
              containerId: 'employees__container',
            });
          });
      }
    },
    handleCancel() {
      this.$skAnalytics.track('multicontract_cancel_on_contract_creation_document');
      this.reset();
    },
    reset() {
      if (this.applyOnSelectedContract) this.unsetSelectedContract();
      this.selectedTemplateId = null;
    },
    formattedDate(dateString) {
      if (!dateString) return '';

      return skDate(dateString).format('ll');
    },
    selectedStatus(textDocumentTemplate) {
      if (!this.selectedTemplateId) return null;
      return textDocumentTemplate.id === this.selectedTemplateId;
    },
    toggleSelect(textDocumentTemplate) {
      if (!this.canBeSelected(textDocumentTemplate)) return;

      if (this.selectedStatus(textDocumentTemplate)) {
        this.selectedTemplateId = null;
      } else {
        this.selectedTemplateId = textDocumentTemplate.id;
      }
    },
    handleSubmit() {
      if (!this.selectedTemplateId) return;
      this.localLoadingNewTextDocument = true;

      const query = {
        hris_active: this.hasShopDocumentTemplatesAccess(this.selectedEmployeeShopId),
        user_id: this.selectedEmployeeId,
        shop_id: this.selectedEmployeeShopId,
        text_document_template_id: this.selectedTemplateId,
        folder: this.folder,
        cluster_node_id: this.navContext.clusterNodeId,
      };

      if (this.applyOnSelectedContract) { query.contract_id = this.selectedContract.id; }

      this.reset();

      this.$router.push({
        name: 'text_document_new',
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-text-document-modal {
  .dot-link__dot {
    background: $sk-grey-10;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: block;
    margin: 0 7px;
  }

  .missing-fields-message {
    background: $sk-error;
    color: white;
    margin: 0;
    padding: 10px 32px;
    display: flex;

    .missing-fields-message__medallion-wrapper {
      width: 50px;
    }

    .missing-fields-message__tooltip {
      margin-left: 10px;
      line-height: 24px;
    }
  }

  .text-document-templates__table {
    width: 100%;

    .table__row {
      cursor: pointer;
    }

    .table__row:first-child {
      &:not(.table__row--selected) {
        border-top: 1px solid $sk-grey-10;
      }
    }

    .table__row--inactive {
      cursor: not-allowed;

      td:first-child {
        padding-top: 19px;
      }
    }

    .table__row--selected {
      background-color: $sk-blue;
      color: white;

      .table__cell:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .table__cell:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .check-mark-icon__fill {
        fill: white;
      }

      .table__cell--inactive {
        color: inherited;
      }

      .template_infos {
        .template_infos__labels {
          color: white;
        }
      }
    }

    .table__cell {
      height: 73px;
      padding: 0 10px;

      &:not(.table__row--selected) {
        border-bottom: 1px solid $sk-grey-10;
      }
    }

    .table__cell--right {
      text-align: right;
    }

    .table__cell--inactive {
      color: $sk-grey;
      font-size: .9em;
    }

    .template_infos {
      .template_infos__title {
        display: flex;
        align-items: center;
        font-size: $fs-text-l;
        font-weight: bold;
        line-height: 19px;
      }

      .template_infos__labels {
        display: flex;
        align-items: center;
        color: $sk-grey;
        font-size: $fs-text-m;
        line-height: 17px;
        padding-top: 6px;
      }
    }
  }

  ::v-deep .sk-modal__footer {
    height: 70px;
  }
}

.create-text-document-modal__body {
  min-height: 378px;
}

.create-text-document-modal__input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.create-text-document-modal__spinner-container {
  height: 64px;
  color: $sk-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.create-text-document-modal__spinner {
  color: $sk-blue;
}

.create-text-document-item__medallion-icon {
  padding-left: 2px;
}
</style>
