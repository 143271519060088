<template>
  <div>
    <h1 class="sk-header--1">
      {{ $t('shop_settings.tabs.shop.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.shop.subtitle') }}
    </p>
    <div
      v-if="!loading"
      class="shop-info__container"
    >
      <div class="sk-form-section">
        <SkForm
          :title="$t('shop_settings.tabs.shop.name.section')"
          split
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('shop_settings.tabs.shop.name.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="name"
                :label="$t('shop_settings.tabs.shop.name.label')"
                :errored="errors['name']"
                :error-message="errorMessage"
                @keyup="validateNoMandatoryEmptyFields('name')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('shop_settings.tabs.shop.corporate_name.section')"
          split
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('shop_settings.tabs.shop.corporate_name.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>

          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="denominationSociale"
                :label="$t('shop_settings.tabs.shop.corporate_name.label')"
                :errored="errors['denominationSociale']"
                :error-message="errorMessage"
                @keyup="validateNoMandatoryEmptyFields('denominationSociale')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('shop_settings.tabs.shop.office_address.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="address"
                :label="$t('shop_settings.tabs.shop.address.labels.address')"
              />
            </div>
          </div>

          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="zipcode"
                :label="$t('shop_settings.tabs.shop.address.labels.zipcode')"
              />
            </div>
            <div class="col-6">
              <SkInput
                v-model="city"
                :label="$t('shop_settings.tabs.shop.address.labels.city')"
              />
            </div>
          </div>
          <SkSelectV2
            v-model="country"
            :label="$t('shop_settings.tabs.shop.address.labels.country')"
            :options="countries"
            :no-results-label="$t('search_bar.no_result')"
            :search-placeholder="$t('labels.search')"
          />
          <SkSelectV2
            v-if="isRegionDisplayed"
            v-model="countryRegion"
            class="sk-form__select--region"
            :label="$t('shop_settings.tabs.shop.address.labels.region')"
            :options="regionsFor(country)"
            :search-placeholder="$t('labels.search')"
            :no-results-label="$t('search_bar.no_result')"
          />
        </SkForm>

        <SkForm
          :title="$t('shop_settings.tabs.shop.timezone.section')"
          split
        >
          <template>
            <SkSelectV2
              v-model="timezone"
              :label="$t('shop_settings.tabs.shop.timezone.label')"
              :options="timezones"
              :no-results-label="$t('search_bar.no_result')"
              :search-placeholder="$t('labels.search')"
            >
              <template #selected-option="{ value }">
                <span v-tooltip="showTimezoneTooltip(value)">
                  {{ truncateTimezone(value) }}
                </span>
              </template>
              <template #option="{ option }">
                <span v-tooltip="showTimezoneTooltip(option)">
                  {{ truncateTimezone(option) }}
                </span>
              </template>
            </SkSelectV2>
          </template>
        </SkForm>

        <SkForm
          :title="$t('shop_settings.tabs.shop.siret_number.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="siret"
                :label="$t('shop_settings.tabs.shop.siret_number.label')"
                :errored="errors['siret']"
                :error-message="invalidSiretMessage"
                @keyup="validateSiret"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('shop_settings.tabs.shop.vat_number.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="tvaNumber"
                :label="$t('shop_settings.tabs.shop.vat_number.label')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          v-if="hasAccesToPayIdentificationNumber"
          data-test="payroll-skForm"
          :title="$t('shop_settings.tabs.shop.pay_identification_number.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="payIdentificationNumber"
                :label="$t('shop_settings.tabs.shop.pay_identification_number.label')"
              />
            </div>
          </div>
        </SkForm>

        <div v-if="isFrenchShop">
          <SkForm
            :title="$t('shop_settings.tabs.shop.naf_code.section')"
            split
          >
            <div class="row sk-form__row">
              <div class="col-12">
                <SkSelectV2
                  v-model="nafCode"
                  :label="$t('shop_settings.tabs.shop.naf_code.label')"
                  :options="nafCodesOptions"
                  :no-results-label="$t('search_bar.no_result')"
                  :search-placeholder="$t('labels.search')"
                >
                  <template #selected-option="{ value }">
                    <span v-tooltip="showCodeTooltip(value, 'nafCodes')">
                      {{ truncateCode(value, 'nafCodes') }}
                    </span>
                  </template>
                  <template #option="{ option }">
                    <span v-tooltip="showCodeTooltip(option, 'nafCodes')">
                      {{ truncateCode(option, 'nafCodes') }}
                    </span>
                  </template>
                </SkSelectV2>
              </div>
            </div>
          </SkForm>

          <SkForm
            :title="$t('shop_settings.tabs.shop.urssaf_code.section')"
            split
          >
            <div class="row sk-form__row">
              <div class="col-12">
                <SkSelectV2
                  v-model="urssafCode"
                  :label="$t('shop_settings.tabs.shop.urssaf_code.label')"
                  :options="urssafCodesOptions"
                  :no-results-label="$t('search_bar.no_result')"
                  :search-placeholder="$t('labels.search')"
                />
              </div>
            </div>
          </SkForm>

          <SkForm
            :title="$t('shop_settings.tabs.shop.sst_code.section')"
            :last="!isBillingInfoOnShop"
            split
          >
            <div class="row sk-form__row">
              <div class="col-12">
                <SkSelectV2
                  v-model="sstCode"
                  :label="$t('shop_settings.tabs.shop.sst_code.label')"
                  :options="sstCodesOptions"
                  :no-results-label="$t('search_bar.no_result')"
                  :search-placeholder="$t('labels.search')"
                >
                  <template #selected-option="{ value }">
                    <span v-tooltip="showCodeTooltip(value, 'sstCodes')">
                      {{ truncateCode(value, 'sstCodes') }}
                    </span>
                  </template>
                  <template #option="{ option }">
                    <span v-tooltip="showCodeTooltip(option, 'sstCodes')">
                      {{ truncateCode(option, 'sstCodes') }}
                    </span>
                  </template>
                </SkSelectV2>
              </div>
            </div>
          </SkForm>
        </div>

        <SkForm
          v-if="isBillingInfoOnShop"
          :title="$t('shop_settings.tabs.shop.billing_info.address.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="billingAddress"
                data-test="billing-adress__input"
                :label="$t('shop_settings.tabs.shop.address.labels.address')"
                :errored="errors['billingAddress']"
                :error-message="errorMessage"
                @keyup="validateNoMandatoryEmptyFields('billingAddress')"
              />
            </div>
          </div>

          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="billingZipcode"
                :label="$t('shop_settings.tabs.shop.address.labels.zipcode')"
              />
            </div>
            <div class="col-6">
              <SkInput
                v-model="billingCity"
                :label="$t('shop_settings.tabs.shop.address.labels.city')"
              />
            </div>
          </div>

          <SkSelectV2
            v-model="billingCountry"
            :label="$t('shop_settings.tabs.shop.address.labels.country')"
            :options="countries"
            :no-results-label="$t('search_bar.no_result')"
            :search-placeholder="$t('labels.search')"
          />
        </SkForm>

        <SkForm
          v-if="isBillingInfoOnShop"
          :title="$t('shop_settings.tabs.shop.billing_info.email.section')"
          split
          last
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('shop_settings.tabs.shop.billing_info.email.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="billingEmail"
                data-test="billing-email__input"
                :label="$t('shop_settings.tabs.shop.billing_info.email.label')"
                :errored="errors['billingEmail']"
                :error-message="billingEmailErrorMessage"
                @keyup="validateEmail"
              />
            </div>
          </div>
        </SkForm>
      </div>
    </div>

    <StickyBar
      :submit-button-label="$t('shop_settings.sticky_bar.submit')"
      :visible="isStickyBarVisible"
      :submit-spinner="loadingUpdate"
      :disabled="disableSubmit"
      class="shop-settings-rules__container__sticky-bar"
      container-scroll-id="shop-settings__container"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import { arrayToSelectOptions } from '@skello-utils/form';
import { truncateString } from '@skello-utils/formatting/strings';
import { countryKeys } from '@skello-utils/country-keys.js';
import { REGION_KEYS } from '@app-js/shared/constants/region-keys.js';
import { httpClient } from '@skello-utils/clients';
import { isValidEmail } from '@skello-utils/validators';
import { FEATURES } from '@app-js/shared/constants/features.js';

import StickyBar from '@app-js/shared/components/Stickybar';

import nafCodes from '@app-js/shared/constants/naf-codes-v2.json';
import urssafCodes from '@app-js/shared/constants/urssaf-codes.json';
import sstCodes from '@app-js/shared/constants/sst-codes.json';

const SIRET_REGEX = /^\d{14}$/;

export default {
  name: 'ShopInfo',
  components: { StickyBar },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters['currentLicense/canEditShopBillingData']) {
        vm.$router.push({ name: 'shop_settings' });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToCurrentShop) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashCurrentShop();
          this.resetError();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      loading: true,
      errors: {
        name: false,
        denominationSociale: false,
        siret: false,
        billingEmail: false,
        billingAddress: false,
      },
      billingInfo: {},
      originalBillingInfo: {},
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop', 'error', 'loadingUpdate', 'originalCurrentShopData']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('config', ['config']),
    ...mapGetters('currentShop', ['showStickyBar', 'unsavedChangesToCurrentShop']),
    ...mapGetters('currentLicense', ['canEditShopBillingData']),
    ...mapGetters('features', ['isFeatureEnabled']),

    name: {
      get() {
        return this.currentShop.attributes.name;
      },

      set(newValue) {
        this.setShopAttributes({ name: newValue });
      },
    },

    payIdentificationNumber: {
      get() {
        return this.currentShop.attributes.payIdentificationNumber;
      },

      set(newValue) {
        this.setShopAttributes({ payIdentificationNumber: newValue });
      },
    },

    denominationSociale: {
      get() {
        return this.currentShop.attributes.denominationSociale;
      },

      set(newValue) {
        this.setShopAttributes({ denominationSociale: newValue });
      },
    },

    address: {
      get() {
        return this.currentShop.attributes.address;
      },

      set(newValue) {
        this.setShopAttributes({ address: newValue });
      },
    },

    zipcode: {
      get() {
        return this.currentShop.attributes.zipcode;
      },

      set(newValue) {
        this.setShopAttributes({ zipcode: newValue });
      },
    },

    city: {
      get() {
        return this.currentShop.attributes.city;
      },

      set(newValue) {
        this.setShopAttributes({ city: newValue });
      },
    },

    country: {
      get() {
        return this.currentShop.attributes.country;
      },
      set(value) {
        // reset region when country changes
        this.setShopAttributes({ country: value });
        this.setShopAttributes({ countryRegion: null });

        // When country changes, we need to validate the siret format to prevent
        // the user from entering a wrong siret value
        this.$nextTick(this.validateSiret);
      },
    },

    countryRegion: {
      get() {
        return this.currentShop.attributes.countryRegion ?? 'default';
      },
      set(value) {
        this.setShopAttributes({ countryRegion: value });
      },
    },

    timezone: {
      get() {
        return this.currentShop.attributes.timezone;
      },

      set(newValue) {
        this.setShopAttributes({ timezone: newValue });
      },
    },

    siret: {
      get() {
        return this.currentShop.attributes.siret;
      },

      set(newValue) {
        this.setShopAttributes({ siret: newValue });
      },
    },

    tvaNumber: {
      get() {
        return this.currentShop.attributes.tvaNumber;
      },

      set(newValue) {
        this.setShopAttributes({ tvaNumber: newValue });
      },
    },

    urssafCode: {
      get() {
        const urssafCode = this.currentShop.attributes.urssafCode;
        if (urssafCode in urssafCodes) {
          return urssafCode;
        }
        return null;
      },

      set(newValue) {
        this.setShopAttributes({ urssafCode: newValue });
      },
    },

    nafCode: {
      get() {
        return this.currentShop.attributes.nafCode;
      },

      set(newValue) {
        this.setShopAttributes({ nafCode: newValue });
      },
    },

    sstCode: {
      get() {
        return this.currentShop.attributes.sstCode;
      },

      set(newValue) {
        this.setShopAttributes({ sstCode: newValue });
      },
    },

    billingAddress: {
      get() {
        return this.billingInfo?.attributes?.address;
      },

      set(newValue) {
        this.setBillingInfoAttribute('address', newValue);
      },
    },

    billingZipcode: {
      get() {
        return this.billingInfo?.attributes?.zipcode;
      },

      set(newValue) {
        this.setBillingInfoAttribute('zipcode', newValue);
      },
    },

    billingCity: {
      get() {
        return this.billingInfo?.attributes?.city;
      },

      set(newValue) {
        this.setBillingInfoAttribute('city', newValue);
      },
    },

    billingCountry: {
      get() {
        return this.billingInfo?.attributes?.country;
      },
      set(newValue) {
        this.setBillingInfoAttribute('country', newValue);
      },
    },

    billingEmail: {
      get() {
        return this.billingInfo?.attributes?.email;
      },

      set(newValue) {
        this.setBillingInfoAttribute('email', newValue);
      },
    },

    isRegionDisplayed() {
      return ['ES', 'DE'].includes(this.country);
    },

    isFrenchShop() {
      return this.config.dom_tom.includes(this.country.toLowerCase());
    },

    invalidEmailMessage() {
      return this.$t('employees.attributes.email.error_message');
    },

    billingEmailErrorMessage() {
      if (!this.errors.billingEmail) return '';

      return this.billingEmail ? this.invalidEmailMessage : this.errorMessage;
    },

    countries() {
      return arrayToSelectOptions(countryKeys('FR'), value => this.$t(`countries.${value}`));
    },

    timezones() {
      return this.config.timezones.map(timezone => ({
        id: timezone[1],
        text: timezone[0],
      }));
    },

    nafCodesOptions() {
      return Object.keys(nafCodes).map(
        key => ({ id: key, text: `${key} - ${nafCodes[key].name}` }),
      );
    },

    urssafCodesOptions() {
      return Object.keys(urssafCodes).map(
        key => ({ id: key, text: `${key} - ${urssafCodes[key].name}` }),
      );
    },

    sstCodesOptions() {
      return Object.keys(sstCodes).sort((a, b) => a - b).map(
        key => ({ id: key, text: sstCodes[key].name }),
      );
    },

    invalidSiretMessage() {
      return this.$t('shop_settings.tabs.shop.siret_number.error');
    },

    errorMessage() {
      return this.$t('shop_settings.tabs.shop.error.empty_field');
    },

    disableSubmit() {
      return Object.values(this.errors).includes(true);
    },
    unsavedChangesToBillingInfo() {
      if (this.loading) return false;

      return JSON.stringify(this.billingInfo) !== JSON.stringify(this.originalBillingInfo);
    },
    isStickyBarVisible() {
      return this.currentLicense.attributes.canEditShopBillingData &&
        (this.showStickyBar || this.unsavedChangesToBillingInfo);
    },
    isBillingInfoOnShop() {
      // FIXME remove next line once billing migration is done
      // prevent blank shop info page on demo account with billing_on_shop and no billing_info
      if (this.currentOrganisation.attributes.doNotPay) return false;
      if (this.isOrganisationDemo && !this.billingInfo) return false;

      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[1];
    },
    isOrganisationDemo() {
      return this.currentOrganisation.attributes.status === this.config.organisation_statuses[0];
    },
    hasAccesToPayIdentificationNumber() {
      return (this.isFeatureEnabled(FEATURES.FEATURE_PAYROLL, this.currentShop.id) ||
      this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id)
      );
    },
  },
  watch: {
    // allow to handle unsavedChanges on billingInfo
    billingInfo: {
      deep: true,
      // handler is mandatory
      handler() {},
    },
  },
  mounted() {
    this.fetchBillingInfo();
    this.validateSiret();
  },
  methods: {
    ...mapActions('currentShop', ['updateShop']),
    ...mapMutations('currentShop', [
      'setShopAttributes',
      'currentShopErrors',
      'squashCurrentShop',
      'squashOriginalCurrentShopData',
      'resetError',
    ]),

    fetchBillingInfo(shopId) {
      if (!this.isBillingInfoOnShop || this.currentOrganisation.attributes.doNotPay) {
        this.loading = false;
        return;
      }

      httpClient
        .get(`/v3/api/billing_infos?shop_id=${this.currentShop.id}`)
        .then(response => {
          this.billingInfo = response.data.data;
          this.originalBillingInfo = cloneDeep(this.billingInfo);
          this.loading = false;
        });
    },
    handleSubmit() {
      if (!this.unsavedChangesToCurrentShop && !this.unsavedChangesToBillingInfo) {
        this.$skToast({
          message: this.$t('shop_settings.update_shop.no_change_message'),
          variant: 'information',
        });
        return;
      }

      if (
        this.originalCurrentShopData.attributes.payIdentificationNumber !==
        this.currentShop.attributes.payIdentificationNumber
      ) {
        this.$skAnalytics.track('fortify_silae_shop_pin_submit', { source: 'shop_settings' });
      }

      this.updateShop({ shopId: this.currentShop.id })
        .then(() => {
          this.squashOriginalCurrentShopData();
          this.updateBillingInfo();

          this.$skToast({
            message: this.$t('shop_settings.update_shop.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    updateBillingInfo() {
      if (!this.isBillingInfoOnShop) return;

      const params = {
        shop_id: this.currentShop.id,
        billing_infos: {
          email: this.billingInfo?.attributes?.email,
          address: this.billingInfo?.attributes?.address,
          zipcode: this.billingInfo?.attributes?.zipcode,
          city: this.billingInfo?.attributes?.city,
          country: this.billingInfo?.attributes?.country,
        },
      };
      httpClient
        .patch('/v3/api/billing_infos', params)
        .then(response => {
          this.billingInfo = response.data.data;
          this.originalBillingInfo = cloneDeep(this.billingInfo);
        });
    },
    validateEmail() {
      // If empty: use the standard empty field process
      if (this.validateNoMandatoryEmptyFields('billingEmail')) return;

      this.errors.billingEmail = !isValidEmail(this.billingEmail);
    },

    validateSiret() {
      this.errors.siret = this.isFrenchShop && this.siret ?
        !SIRET_REGEX.test(this.siret) :
        false;
    },

    validateNoMandatoryEmptyFields(field) {
      // billingField removes billing prefix
      // e.g: billingEmail becomes email
      const billingField = field.slice(7).toLowerCase();

      this.errors[field] = !(
        this.currentShop.attributes[field] || this.billingInfo.attributes[billingField]
      );
    },

    utcFormattedTimezone(selectedTimezone) {
      const arr = this.config.timezones.filter(
        timezone => timezone[1] === selectedTimezone,
      );

      return arr.reduce((acc, val) => acc.concat(val), [])[0];
    },

    truncateCode(string, codeAttribute) {
      return truncateString(this.getCodeText(string, codeAttribute));
    },

    truncateTimezone(string) {
      return truncateString(this.getTimezoneText(string));
    },

    showCodeTooltip(string, codeAttribute) {
      const codeText = this.getCodeText(string, codeAttribute);

      return codeText.length > 50 ? codeText : null;
    },

    showTimezoneTooltip(string) {
      const timezoneText = this.getTimezoneText(string);

      return timezoneText.length > 50 ? timezoneText : null;
    },

    getCodeText(string, codeAttribute) {
      return string.text ? string.text : this[`${codeAttribute}Options`].find(
        code => code.id === string,
      ).text;
    },

    getTimezoneText(string) {
      return string.text ? string.text : this.timezones.find(
        timezone => timezone.id === string,
      ).text;
    },

    regionsFor(country) {
      return [
        ...arrayToSelectOptions(REGION_KEYS[country], value => this.$t(`regions.${country}.${value}`)),
        { id: 'default', text: this.$t('shop_settings.tabs.shop.address.region_default_value') },
      ];
    },

    setBillingInfoAttribute(key, value) {
      if (this.billingInfo && this.billingInfo.attributes) {
        this.billingInfo.attributes[key] = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings-rules__container__sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.shop-info__container {
  margin-top: 30px !important;
  padding-bottom: 40px;
}

.sk-form__select--region {
  margin-top: 10px;
}
</style>
