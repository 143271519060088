<template>
  <div
    v-show="visible"
    id="notifications-wrapper"
    class="part dashboard-section"
  >
    <div class="dashboard-title">
      <h2 class="text-center">
        {{ $t('home_dashboard.newsfeed.title') }}
      </h2>
    </div>
    <div
      id="dashboard-actuality-container"
      class="dashboard-content"
    >
      <ul class="notifications-list">
        <li
          v-for="(notification, index) in notifications"
          :key="index"
          class="notification-item"
        >
          <a
            :href="notification.url"
            class="notification-link"
            target="blank"
            data-seg="DBoardActu"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="notification-content"
              v-html="$t(notification.key, notification.context)"
            />
            <div class="notification-timestamp">
              {{ notification.createdAt }}
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import {
  mapGetters, mapState,
} from 'vuex';
import { registerFormatEvent } from '@skello-utils/events/format_event_service.js';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

const DASHBOARD_SUBTYPE_EVENT = [
  EVENT_SUBTYPE_ACTION.ACTIVATE_OPTIMISATION,
  EVENT_SUBTYPE_ACTION.CREATE_AMENDMENT,
  EVENT_SUBTYPE_ACTION.CREATE_SHIFT,
  EVENT_SUBTYPE_ACTION.EMP_PROFILE_UPDATED,
  EVENT_SUBTYPE_ACTION.UNVALIDATE_ALL_DAYS,
  EVENT_SUBTYPE_ACTION.UNVALIDATE_DAY,
  EVENT_SUBTYPE_ACTION.VALIDATE_ALL_DAYS,
  EVENT_SUBTYPE_ACTION.VALIDATE_DAY,
];

export default {
  name: 'ActualityChart',
  data() {
    return {
      visible: true,
      notifications: [],
      usersFromMyTeams: [],
      next: undefined,
      locked: false,
    };
  },

  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    isSingleShop() {
      return this.currentShop.id !== 'all';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    async initialLoad() {
      try {
        this.notifications = [];
        this.next = undefined;
        await this.fetchTeams();
        do {
          // eslint-disable-next-line no-await-in-loop
          await this.fetchEvents(this.next);
          // The above is intended, eslint enforces parallelism but here we want to wait for the request to set the `next` token
        } while (this.next && this.notifications.length < 10);
        this.attachScrollEvent();
      } finally {
        this.handleReloadDone();
      }
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'ActualityChart');
    },
    attachScrollEvent() {
      const container = document.getElementById('dashboard-actuality-container');

      container.addEventListener('scroll', () => {
        // Removing 350px from the trigger threshold so the lazy loading is a bit smoother
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 350) {
          if (this.next && !this.locked) this.fetchEvents(this.next);
        }
      });
    },
    async fetchEvents(next = undefined) {
      this.locked = true;
      const findAllByIdType = this.isSingleShop ? 'getEventsForShop' : 'getEventsForClusterNode';
      const id = this.isSingleShop ? this.currentShop.id :
        this.currentOrganisation.attributes.rootNodeId;

      let events;
      try {
        events = await this.$svcEvents[findAllByIdType](id, {
          subtypeFilters: DASHBOARD_SUBTYPE_EVENT,
          limit: 50,
          next,
        });

        this.next = events.next;
      } catch (error) {
        throw Error(this.$t('home_dashboard.notification.errors.svc_errors'));
      } finally {
        this.locked = false;
      }

      if (!Array.isArray(events.data)) throw Error(this.$t('home_dashboard.notification.errors.reponse_as_array'));

      this.notifications.push(...this.formatSvcEventsResponse(events.data));
    },
    formatSvcEventsResponse(events) {
      const formatEvent = registerFormatEvent(this.$i18n.locale, 'home_dashboard');

      return events
        .filter(event => {
          if (!event) return false;
          if (!this.currentLicense.attributes.canReadAllEmployees) {
            return event.userId === undefined || this.usersFromMyTeams.includes(event.userId);
          }
          return true;
        })
        .map(event => {
          try {
            return formatEvent(event);
          } catch (error) {
            captureException(error);
            return undefined;
          }
        });
    },
    async fetchTeams() {
      const shopId = this.currentUser.attributes.shopId;
      const response = await httpClient.get(`/v3/api/teams?shop_id=${shopId}`);
      this.formatTeamsResponse(response.data);
    },
    formatTeamsResponse(payload) {
      const currentUserTeamIds = this.currentUser.relationships.teams.data.map(team => team.id);
      this.usersFromMyTeams = [
        // add current user for user with master advance role (without team)
        this.currentUser.id,
        ...payload.data
          .filter(team => currentUserTeamIds.includes(team.id))
          .flatMap(team => team.relationships.users.data)
          .map(user => user.id),
      ];
    },
  },
};
</script>
