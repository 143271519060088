<template>
  <SkOroraDialog
    id="init-hours-counter-modal"
    ref="initHoursCounterModal"
    :title="modalTitle"
    size="medium"
    :submit-button-label="$t('employees.tabs.counters.hours_counter.modal.submit')"
    :is-submit-disabled="!validForm"
    @submit="handleSubmit"
    @show="handleShow"
    @cancel="resetLocalData"
    @close="resetLocalData"
  >
    <template #body>
      <main class="init-hours-counter-modal-body">
        <div class="init-hours-counter-modal__date">
          <div class="init-date-section__init-date">
            <div class="init-date-section__datepicker-title">
              <span>{{ $t('employees.tabs.counters.hours_counter.modal.init_date.title') }}</span>
              <span class="init-date-section__datepicker-title__tooltip">
                <QuestionMarkV2Icon
                  v-tooltip.longform="$t('employees.tabs.counters.hours_counter.modal.init_date.tooltip')"
                  width="16"
                  height="16"
                  fill="black"
                />
              </span>
            </div>
            <div class="init-date-section__datepicker-wrapper">
              <SkDatePicker
                ref="datepicker"
                v-model="employeeCounter.initDate"
                :disabled-date="disabledDays"
                :placeholder="$t('employees.tabs.counters.hours_counter.modal.init_date.placeholder')"
                :errored="!isInitDateSunday"
                :error-message="$t('employees.tabs.counters.hours_counter.modal.init_date.not_sunday')"
                :lang="$i18n.locale"
                input-moment-format="dddd DD MMM YYYY"
                append-to-body
                hide-footer
              />
              <p class="sk-subtitle--small">
                {{ $t('employees.tabs.counters.hours_counter.modal.init_date.hint') }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="!isEditCounter"
          class="init-hours-counter-modal__balance-section"
        >
          <div class="balance-section__details">
            <div class="balance-section__details-title">
              {{ $t('employees.tabs.counters.hours_counter.modal.balance.title') }}
            </div>
            <div class="balance-section__details-wrapper">
              <SkSwitch
                v-model="inheritBalance"
                class="balance-section__switch"
              />
            </div>
          </div>
          <div class="balance-section__explanation">
            {{ $t('employees.tabs.counters.hours_counter.modal.balance.explanation') }}
          </div>
          <section v-if="inheritBalance">
            <SkInfoCard class="individual-hours-counter-initialization-modal__info-card">
              {{ $t('employees.tabs.counters.hours_counter.modal.balance.card.explanation') }}
            </SkInfoCard>
            <EmployeeRow
              :key="employee.id"
              v-model="employeeCounter"
              :unit="$t('employees.tabs.counters.hours_counter.modal.balance.unit')"
              :tracking-options="trackingOptions"
              @keyup="checkBalanceError(employee)"
            />
          </section>
        </div>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import EmployeeRow from '@app-js/shared/components/CountersInitializationModal/EmployeeRow';

export default {
  name: 'InitHoursCounterModal',
  components: { EmployeeRow },
  data() {
    return {
      employeeCounter: {
        initDate: null,
        balance: null,
        balanceModifier: '+',
        attributes: null,
      },
      initSpinner: false,
      inheritBalance: false,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('selectedEmployee', ['initials']),

    isEditCounter() {
      return !!this.employee.attributes.counterInitializationDoneAt;
    },
    isInitDateSunday() {
      if (!this.employeeCounter.initDate) return true;

      return skDate(this.employeeCounter.initDate).isSunday();
    },
    validForm() {
      if (this.inheritBalance) {
        if (!this.employeeCounter.balance &&
            !!this.employeeCounter.initDate &&
            this.isInitDateSunday) {
          // In case the balance switch has been activated but not filled
          return true;
        }
        return this.employeeCounter.balance != null &&
          !this.balanceInputError &&
          !!this.employeeCounter.initDate &&
          this.isInitDateSunday;
      }

      return this.isInitDateSunday && !!this.employeeCounter.initDate;
    },
    balanceInputError() {
      return !this.employeeCounter.balance;
    },
    modalTitle() {
      if (this.isEditCounter) {
        return this.$t('employees.tabs.counters.hours_counter.modal.title.edit');
      }

      return this.$t('employees.tabs.counters.hours_counter.modal.title.initialization');
    },
    trackingOptions() {
      return {
        cancel: 'hours_counter_initialization_cancel',
        plus: 'init_counter_plus',
        minus: 'init_counter_minus',
      };
    },
  },
  watch: {
    inheritBalance(newValue) {
      if (!newValue) this.employeeCounter.balance = '';
    },
  },
  methods: {
    ...mapActions('employeeCounters', ['initEmployeeHoursCounter', 'fetchSelectedEmployeeHoursCounter']),
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),
    disabledDays(date) {
      return !skDate(date).isSunday();
    },
    selectModifier(modifier) {
      if (!this.inheritBalance) return;
      this.employeeCounter.balanceModifier = modifier;
    },
    isModifierSelected(modifier) {
      return modifier === this.employeeCounter.balanceModifier;
    },
    resetLocalData() {
      this.$refs.datepicker.resetModified();
      this.employeeCounter.initDate = null;
      this.employeeCounter.balance = '';
      this.employeeCounter.balanceModifier = '+';
      this.inheritBalance = false;
    },
    handleSubmit(event) {
      this.initSpinner = true;
      this.$skAnalytics.track('activate_hour_tracker', { source: 'employee_space' });

      if (this.isEditCounter) {
        this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
          title: this.$t('employees.tabs.counters.hours_counter.initialization.confirm.title'),
          description: this.$t('employees.tabs.counters.hours_counter.initialization.confirm.description'),
          submitLabel: this.$t('employees.tabs.counters.hours_counter.initialization.confirm.submit_label'),
          onConfirmAsync: async () => {
            this.$skAnalytics.track('edit_initialisation_date_hour_tracker');
            await this.initializeCounters();
            this.resetLocalData();
          },
          onCancel: () => { this.initSpinner = false; },
        });
      } else {
        this.initializeCounters();
        this.resetLocalData();
      }
    },
    async initializeCounters() {
      const balance = String(this.employeeCounter.balance) || '0';
      const { initDate, balanceModifier } = this.employeeCounter;
      await this.initEmployeeHoursCounter({
        shopId: this.employee.attributes.shopId,
        params: {
          employee_id: this.employee.id,
          init_date: initDate,
          balance,
          balance_modifier: balanceModifier,
        },
      });
      this.initSpinner = false;

      // Set toaster message before employee initialization gets set
      const toasterMessage = this.isEditCounter ?
        this.$t('employees.tabs.counters.hours_counter.modal.toaster.edit') :
        this.$t('employees.tabs.counters.hours_counter.modal.toaster.initialization');

      // The employee init date is the monday of the first initialization week
      // The date input takes the previous sunday as input
      const counterInitializationDoneAt = skDate.utc(initDate).add(1, 'd').format();

      this.setEmployeeAttributes({
        counterInitializationDoneAt,
        hoursCounterInitialized: true,
      });

      this.fetchSelectedEmployeeHoursCounter({
        user_id: this.employee.id,
        date: skDate().format(),
      });

      this.$refs.initHoursCounterModal.hide();

      this.$skToast({
        message: toasterMessage,
        variant: 'success',
      });
    },
    handleShow() {
      this.employeeCounter.attributes = {
        firstName: this.employee.attributes.firstName,
        lastName: this.employee.attributes.lastName,
      };

      if (!this.isEditCounter) return;

      const previousInitDate = skDate.utc(this.employee.attributes.counterInitializationDoneAt);
      // The employee init date is the monday of the first initialization week
      // The date input takes the previous sunday as input
      previousInitDate.subtract(1, 'd');
      this.employeeCounter.initDate = previousInitDate.format('YYYY-MM-DD');
    },
    checkBalanceError(employee) {
      this.setEmployeeAttributes({ balanceError: employee.balance && !employee.balanceModifier });
    },
  },
};
</script>

<style lang="scss" scoped>
.init-hours-counter-modal-body {
  padding: 8px 24px;
}
.init-hours-counter-modal__date {
  .init-date-section__init-date {
    display: flex;
    justify-content: space-between;
  }

  .init-date-section__datepicker-title {
    width: 230px;
    font-weight: $fw-semi-bold;
    line-height: 44px;
  }

  .init-date-section__datepicker-title__tooltip {
    display: inline;
    position: absolute;
    margin-left: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .init-date-section__datepicker-wrapper {
    width: 300px;
  }
}

.init-hours-counter-modal__balance-section {
  position: relative;

  .balance-section__card {
    margin-top: 40px;
    border-radius: 4px;
    border: 1px solid $sk-grey-10;
    font-size: $fs-text-m;
    color: $sk-grey;
    display: flex;
    justify-content: flex-start;
    padding: 10px 70px 10px 10px;
  }

  .balance-section__card__icon {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .balance-section__form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 10px;

    &__header {
      width: 85%;
      display: flex;
      margin-top: 9px;
      align-items: center;
    }
  }

  .balance-section__medallion {
    margin-right: 20px;
  }

  .balance-section__modifiers {
    margin-right: 10px;
    margin-left: auto;
    cursor: pointer;
  }

  .balance-section__modifier {
    margin-left: 3px;
  }

  .balance-section__form__input {
    margin-right: 0;
    width: 200px;
  }
}

.balance-section__details {
  display: flex;
  .balance-section__details-title {
    width: 230px;
    font-weight: $fw-semi-bold;
    line-height: 44px;
  }
  .balance-section__details-wrapper {
    margin-left: 40px;
  }
}

.balance-section__explanation {
  color: #727272;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.init-hours-counter-modal__balance-section--disabled {
  .balance-section__card,
  .balance-section__details,
  .balance-section__form {
    opacity: .3;
  }

  .balance-section__modifiers {
    cursor: not-allowed;
  }
}
</style>
