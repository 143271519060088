<template>
  <div>
    <div class="col-md-4 user_form__page">
      <div class="user_form__header">
        <div class="user_form__header__title">
          <h1 class="sk-header--1">
            {{ $t('account_creation.form.title') }}
          </h1>
        </div>
        <p class="sk-subtitle--large">
          {{ $t('account_creation.form.subtitle') }}
        </p>
      </div>
      <div class="user_form__required">
        <p class="sk-subtitle--medium">
          {{ $t('account_creation.form.form_helper_beggining') }}
          <span class="text--blue">*</span>
          {{ $t('account_creation.form.form_helper_end') }}
        </p>
      </div>
      <form
        class="user_form__form"
        @submit="submitForm"
      >
        <SkInput
          v-model="firstName"
          :label="$t('account_creation.form.first_name')"
        />
        <SkInput
          v-model="lastName"
          :label="$t('account_creation.form.last_name')"
        />
        <SkPhoneInput
          v-model="phone"
          :default-country="defaultCountry"
          :error-message="$t('account_creation.error.phone')"
          :label="$t('account_creation.form.phone')"
          @input="handlePhoneInput"
        />
        <SkInput
          v-model="email"
          :errored="emailError"
          :error-message="$t('account_creation.error.mail')"
          :debounce="validateEmail"
          :label="$t('account_creation.form.mail')"
          type="email"
        />
        <SkInput
          v-model="password"
          :type="currentInputType"
          :label="$t('account_creation.form.password')"
        >
          <template #icon>
            <div
              class="user_form__form__eye"
              @click="togglePasswordDisplay"
            >
              <OpenEyeIcon
                v-if="passwordHidden"
                fill=""
              />
              <ClosedEyeIcon
                v-else
                fill=""
              />
            </div>
          </template>
        </SkInput>
        <div class="user_form__form__password-container">
          <span
            v-for="element in passwordValidationElements"
            :key="element"
          >
            <p :class="passwordCheckClasses(element)">
              <component
                :is="passwordCheckIcon(element)"
                :fill="passwordCheckColor(element)"
                width="40px"
              />
              {{ $t(`account_creation.form.password_helper.${element}`) }}
            </p>
          </span>
        </div>
      </form>
      <SkOroraButton
        :disabled="isDisabled"
        :loading="loading"
        class="user_form__button"
        @click="submitForm"
      >
        {{ $t('account_creation.form.submit') }}
      </SkOroraButton>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';

import {
  SkOroraButton,
  SkInput,
  SkPhoneInput,
  OpenEyeIcon,
  ClosedEyeIcon,
  ClosingXIcon,
  CheckMarkIcon,
} from '@skelloapp/skello-ui';

import {
  isValidEmail,
  isValidPasswordLength,
  isValidPasswordUpper,
  isValidPasswordLower,
  isValidPasswordDigit,
} from '@skello-utils/validators';

export default {
  name: 'UserForm',
  components: {
    SkOroraButton,
    SkInput,
    SkPhoneInput,
    OpenEyeIcon,
    ClosedEyeIcon,
    ClosingXIcon,
    CheckMarkIcon,
  },
  props: {
    prospect: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: this.prospect.attributes.email,
      phone: null,
      phoneObject: null,
      password: '',
      emailError: null,
      phoneError: null,
      passwordHidden: true,
      loading: false,
      passwordValidationElements: ['length', 'upper', 'lower', 'digit'],
      defaultCountry: this.$i18n.locale === 'en' ? 'gb' : this.$i18n.locale, // Try to determine country based on language
    };
  },
  computed: {
    ...mapState('onboarding', ['userCreation']),
    isDisabled() {
      return !(
        this.firstName &&
        this.lastName &&
        this.email && !this.emailError &&
        this.phone && !this.phoneError &&
        this.isPasswordValid
      );
    },
    currentInputType() {
      return this.passwordHidden ? 'password' : 'text';
    },
    isPasswordValid() {
      return isValidPasswordLength(this.password) &&
        isValidPasswordUpper(this.password) &&
        isValidPasswordLower(this.password) &&
        isValidPasswordDigit(this.password);
    },
  },
  methods: {
    ...mapMutations('onboarding', ['setUserCreationCurrentStep']),
    ...mapActions('onboarding', ['createUser']),

    togglePasswordDisplay() {
      this.passwordHidden = !this.passwordHidden;
    },
    handlePhoneInput(_number, phoneObject) {
      this.phoneObject = phoneObject;
      this.phoneError = this.phone && !this.phoneObject.valid;
    },
    validateEmail() {
      this.emailError = this.email && !isValidEmail(this.email);
    },
    validatedForm() {
      if (this.isDisabled) return false;
      this.emailError = !isValidEmail(this.email);
      this.phoneError = !this.phoneObject.valid;

      return !this.emailError && !this.phoneError;
    },
    checkElementValidity(element) {
      switch (element) {
        case 'length':
          return isValidPasswordLength(this.password);
        case 'upper':
          return isValidPasswordUpper(this.password);
        case 'lower':
          return isValidPasswordLower(this.password);
        case 'digit':
          return isValidPasswordDigit(this.password);
        default:
          console.error(`${element} is not checked`);
          return false;
      }
    },
    passwordCheckIcon(element) {
      if (!this.password) return CheckMarkIcon;
      return this.checkElementValidity(element) ? CheckMarkIcon : ClosingXIcon;
    },
    passwordCheckClasses(element) {
      return {
        password: true,
        'password--inactive': !this.password,
        'password-check--valid': this.password && this.checkElementValidity(element),
        'password-check--invalid': this.password && !this.checkElementValidity(element),
      };
    },
    passwordCheckColor(element) {
      if (!this.password) return '#727272';
      return this.checkElementValidity(element) ? '#188377' : '#d03e50';
    },
    submitForm() {
      if (!this.validatedForm()) return;
      this.loading = true;

      this.$skAnalytics.track('account_creation_user_submit');

      const user = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phoneObject.number,
        password: this.password,
        lang: this.$i18n.locale,
      };

      this.createUser({ params: user, token: this.userCreation.token })
        .then(() => {
          this.prospect.attributes.email = this.email;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('organisation_creation.error.generic'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user_form__form__eye {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: relative;
}

.user_form__form .sk-input,
.sk-phone-input {
  margin-top: 10px;
}

.user_form__form {
  margin-bottom: 8px;
}

.user_form__header {
  margin-bottom: 28px;
}

.user_form__header__title {
  margin-bottom: 9px;
}

.user_form__page {
  margin-top: 70px;
  max-width: 400px;
}

.user_form__button {
  width: 84%;
  justify-content: center;
}

.user_form__required {
  text-align: left;
}

.user_form__form__password-container {
  column-count: 2;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 15px;
  white-space: nowrap;

  .password {
    font-size: $fs-text-s;
    font-weight: 400;
    align-items: baseline;
    margin-bottom: .5em;
  }

  .password--inactive {
    color: $sk-grey-50;
  }

  .password-check--valid {
    color: $sk-success;
  }

  .password-check--invalid {
    color: $sk-error;
  }
}

</style>
