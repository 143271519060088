<template>
  <div>
    <div
      v-if="loading"
      class="onboarding__spinner-wrapper"
    >
      <SkLoader size="large" />
    </div>
    <div
      v-else
      id="main-body"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import { SkLoader } from '@skelloapp/skello-ui';
import { getUtmParams } from '@app-js/shared/utils/self_serve/utils';
import moment from 'moment';
import { loadLanguageAsync } from '@plugins/i18n';
import selfServeVersions from './self_serve/shared/constants/selfServeVersions';

export default {
  name: 'Onboarding',
  components: {
    SkLoader,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
  },
  beforeCreate() {
    const localStorageBundle = localStorage.getItem('query_bundle')?.toLowerCase();
    const queryBundle = this.$route.query.pack?.toLowerCase();
    const bundle = localStorageBundle || queryBundle || '';
    localStorage.setItem('query_bundle', bundle);

    const previousVersion = localStorage.getItem('self-serve-version')?.toLowerCase();
    let version = this.$route.query.version?.toLowerCase();

    if (!version && !previousVersion) {
      localStorage.setItem('self-serve-version', selfServeVersions.DEFAULT);
      window.location.reload();
    }
    if (version && previousVersion !== version) {
      if (!selfServeVersions.VALID_VERSIONS.includes(version)) {
        version = selfServeVersions.DEFAULT;
      }

      localStorage.setItem('self-serve-version', version);

      const lang = new URLSearchParams(window.location.search).get('lang');

      const utmParams = getUtmParams();
      const filteredUtmParams = Object.fromEntries(
        Object.entries(utmParams).filter(([, value]) => value !== null),
      );

      this.$router.replace({
        path: this.$route.path,
        query: { version, ...filteredUtmParams, lang },
      });

      window.location.reload();
    }
  },
  created() {
    const navigatorLang = navigator.language || navigator.userLanguage;
    const urlParamsLang = new URLSearchParams(window.location.search).get('lang');
    loadLanguageAsync(urlParamsLang || navigatorLang.substring(0, 2));
    moment.locale(urlParamsLang || navigatorLang.substring(0, 2));
    this.setupApp();
  },
  methods: {
    ...mapMutations('onboarding', ['setupAnalytics']),
    ...mapActions('config', ['fetchConfig']),

    setupApp() {
      this.loading = true;

      this.fetchConfig().then(() => {
        const version = localStorage.getItem('self-serve-version')?.toLowerCase();
        if (version === 'v5' && !this.isDevFlagEnabled('FEATUREDEV_V5_SELF_SERVE')) {
          window.location.href = `/v3/self_serve/step_one?version=${selfServeVersions.DEFAULT}`;
        }

        this.setupAnalytics({ config: this.config });

        this.loading = false;
      }).catch(() => {
        this.$skToast({
          message: this.$t('organisation_creation.error.generic'),
          variant: 'error',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#main-body {
  background: $sk-white;
  min-height: 100vh;
}

.onboarding__spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $sk-blue;
  font-size: 2em;
  padding-top: 4em;
}
</style>
