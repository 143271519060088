<template>
  <SkListItem
    :key="employee.id"
    :class="listItemClass"
    :to="routeParams"
  >
    <span
      v-if="showIncompleteEmployees"
      class="sidebar__employee__vertical-line"
    />
    <template #left>
      <div class="sidebar__employee__medallion-wrapper">
        <AvatarMedallion
          :avatar-url="getAvatarUrlForUser(employee.id)"
          :medallion-text="userInitials(employee)"
          :medallion-background="medallionBackgroundColor"
          :medallion-content-color="medallionContentColor"
          :errored="displaySignalMedallion"
          :is-highlighted="hasMissingPayrollInformation"
          :error-tooltip="errorTooltipMessage"
        />
      </div>
    </template>
    <div class="sidebar__employee__full-name">
      {{ fullName(employee) }}
    </div>
    <template #subtitle>
      <div
        :style="licenseBadgeColor"
        class="sidebar__employee__license-name"
      >
        {{ licenseName }}
      </div>
    </template>
    <template #right>
      <!-- eslint-disable max-len -->
      <CircledBellIcon
        v-if="isEmployeeTrialSoon"
        v-tooltip.top="$t('employees.tabs.contract_and_salary.contract_data.trial_period_end.alert_icon.tooltip')"
      />
      <SkTag
        v-if="displayArchivedTag"
        variant="danger"
      >
        {{ $t('employees.archived') }}
      </SkTag>
    </template>
  </SkListItem>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { LICENSE_COLORS } from '@app-js/shared/constants/colors';
import { FEATURES } from '@app-js/shared/constants/features';

import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';

export default {
  name: 'EmployeeListItem',
  components: {
    AvatarMedallion,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signalBorderColor: this.isSelected ? '#2b66fe' : '#fff',
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('employees', ['showIncompleteEmployees']),
    ...mapGetters('employees', [
      'fullName',
      'userInitials',
      'isCurrentlyArchived',
      'isTrialEndSoon',
      'getAvatarUrlForUser',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    isPlanningEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id);
    },
    listItemClass() {
      return {
        'sidebar__employee--selected': this.isSelected,
        sidebar__employee: true,
      };
    },
    licenseName() {
      return this.$t(`employees.licenses.name.${this.employee.attributes.currentLicenseType}`);
    },
    medallionBackgroundColor() {
      return this.isSelected ? '#fff' : '#2b66fe';
    },
    medallionContentColor() {
      return this.isSelected ? '#2b66fe' : '#fff';
    },
    isInterim() {
      if (!this.employee.relationships.contract.attributes.contractTypeId) return false;

      return this.config.contract_data.contract_types
        .find(ct => ct.id === this.employee.relationships.contract.attributes.contractTypeId)
        .category === 'interim';
    },
    contractHoursMissing() {
      const counterTypeDaily = this.config.contract_data.counter_type_daily;
      const employeeContract = this.employee.relationships.contract.attributes;
      const permanentContractHours = this.employee.attributes.currentPermanentContractHours;
      return Number(permanentContractHours) === 0 &&
             employeeContract.counterType !== counterTypeDaily &&
             !this.isInterim;
    },
    displaySignalMedallion() {
      const { contractTypeId } = this.employee.relationships.contract.attributes;
      return this.isPlanningEnabled && (!contractTypeId || this.contractHoursMissing);
    },
    medaillonTooltipMessage() {
      const contractTypeMissing = !this.employee.relationships.contract.attributes.contractTypeId;
      if (this.contractHoursMissing && contractTypeMissing) {
        return this.$t('employees.sidebar.tooltip.enter_all_infos');
      }
      if (contractTypeMissing) {
        return this.$t('employees.sidebar.tooltip.enter_contract_type');
      }
      return this.$t('employees.sidebar.tooltip.enter_hourly_wage');
    },
    displayArchivedTag() {
      return this.isCurrentlyArchived(this.employee);
    },
    isEmployeeTrialSoon() {
      return this.isTrialEndSoon(this.employee) && !this.displayArchivedTag;
    },
    licenseBadgeColor() {
      return {
        color: this.isSelected ? '#fff' : LICENSE_COLORS[this.employee.attributes.currentLicenseType].color,
        opacity: this.isSelected ? 0.6 : 1,
      };
    },
    routeParams() {
      if (this.$route.name.includes('user_full_contract')) {
        return { name: 'user_contracts', params: { user_id: this.employee.id } };
      }
      return { params: { user_id: this.employee.id } };
    },
    hasMissingPayrollInformation() {
      return this.showIncompleteEmployees &&
        this.employee.attributes.payrollMissingAttributes?.length > 0;
    },
    missingInformationTooltip() {
      if (!this.hasMissingPayrollInformation) return '';

      const tooltipTitle = this.$i18n.t('employees.tabs.payroll_missing_data_labels.tooltip_title');

      const payrollMissingAttributes = this.employee.attributes.payrollMissingAttributes
        .map(payrollMissingAttribute => this.$i18n.t(`employees.tabs.payroll_missing_data_labels.${payrollMissingAttribute}`));

      if (payrollMissingAttributes.length > 6) {
        const keys = payrollMissingAttributes.splice(0, 6).join('<br>');
        const todoTranslation = this.$t('employees.tabs.payroll_missing_data_labels.to_do');

        return `${tooltipTitle}<br>${keys}<br>... + ${payrollMissingAttributes.length} ${todoTranslation}`;
      }
      return `${tooltipTitle}<br>${payrollMissingAttributes.join('<br>')}`;
    },
    errorTooltipMessage() {
      if (this.displaySignalMedallion) return this.medaillonTooltipMessage;
      if (this.hasMissingPayrollInformation) return this.missingInformationTooltip;
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar__employee__avatar {
  height: 32px;
  width: 32px;
  border-radius: 32px;
}

.sidebar__employee__medallion-wrapper {
  position: relative;
}

.sidebar__employee {
  display: flex;
  padding: 10px;
  margin: 2px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  padding-left: 16px;
  position: relative;

  &:hover {
    background: $sk-grey-10;

    .sidebar__employee-signal {
      border: 2px solid $sk-grey-10;
    }
  }

  .sidebar__employee__license-name {
    margin-left: 15px;
    font-size: $fs-text-s;
    color: $sk-grey-50;
  }
}

.sidebar__employee--selected {
  display: flex;
  padding: 10px;
  margin: 2px;
  border-radius: 4px;
  align-items: center;
  color: #fff;
  padding-left: 16px;
  background-color: $sk-blue-50;

  .sidebar__employee-signal {
    border: 2px solid $sk-blue-50;
  }

  &:hover {
    background: $sk-blue-50;
    color: #fff;

    .sidebar__employee-signal {
      border: 2px solid $sk-blue-50;
    }
  }

  .sidebar__employee__license-name {
    margin-left: 15px;
    font-size: $fs-text-s;
    color: rgba(255, 255, 255, .6);
  }
}

.sidebar__employee__full-name {
  font-size: $fs-text-m;
  margin-left: 15px;
  text-transform: capitalize;
}

.sidebar__employee-signal {
  position: absolute;
  top: 18px;
  left: 18px;
  border: 2px solid white;
}

.sidebar__employee__vertical-line {
  border-left: 2px solid $sk-blue-50;
  display: inline-block;
  height: calc(100% + 2px);
  margin: 0 20px;
  position: absolute;
  left: -15px;
  top: 0;
}
</style>
