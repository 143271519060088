<template>
  <SkModalV2
    id="text-document-created-modal"
    ref="modal"
    :title="$t('text_document_templates.text_document_created_modal.title')"
    :icon-options="modalIconOptions"
  >
    <template #title-icon>
      <div class="text-document-created-modal__icon">
        <CheckMarkIcon fill="#000" />
      </div>
    </template>
    <template #body>
      <SkModalSection
        class="text-document-created-modal__body"
        border-bottom="none"
      >
        <div
          v-if="isDevFlagEnabled('FEATUREDEV_ESIGNATURE_DOCS_ESINGATURE_UPSELL')
            && !isEsignatureTimeSheetEnabled()"
          class="document-card doc-esignature-upsell-content"
        >
          <div class="doc-esignature-image">
            <UpsellBannerIllustration />
          </div>
          <div class="doc-esignature-upsell">
            <div class="doc-esignature-upsell-title">
              <div class="doc-esignature-upsell-title-part-1">
                {{ $t('text_document_templates.text_document_created_modal.upsell.title.part_1') }}
                <em class="doc-esignature-upsell-title-part-2">
                  {{ $t('text_document_templates.text_document_created_modal.upsell.title.part_2') }}
                </em>
              </div>
            </div>
            <div class="doc-esignature-upsell-subtitle">
              {{ $t('text_document_templates.text_document_created_modal.upsell.subtitle') }}
            </div>
            <div
              class="doc-esignature-upsell-discover"
              @click="showDocEsignatureUpsellModal"
            >
              <div>
                {{ $t('text_document_templates.text_document_created_modal.upsell.discover') }}
              </div>
              <ChevronRightIcon fill="#2b66fe" />
            </div>
          </div>
        </div>
        <div :class="congratulationClasses">
          <strong>
            {{ $t('text_document_templates.text_document_created_modal.congratulations') }}
          </strong>
          <br>
          {{ $t('text_document_templates.text_document_created_modal.your_document') }}
          <strong>
            {{ textDocument.attributes.title }}
          </strong>
          {{ $t('text_document_templates.text_document_created_modal.has_been_saved_in') }}
          <strong>
            {{ folderName(textDocument.attributes.folder) || $t('employees.tabs.documents.title') }}
          </strong>
        </div>
        <div
          v-if="isEsignatureTimeSheetEnabled()"
          class="document-card"
        >
          <div>
            <img
              src="@app-js/static/shared/assets/img/DocumentSignatureIcon.svg"
              alt="img"
            >
          </div>
          <div class="text-section">
            <strong>
              {{ $t('text_document_templates.text_document_created_modal.share_the_document_title') }}
            </strong>
            <NewTag />
            <p>
              {{ $t('text_document_templates.text_document_created_modal.share_the_document_description') }}
            </p>
            <div>
              <SkOroraButton
                v-track="'esignature_documents_generation_request_signature'"
                v-tooltip.bottom="esignatureMaintenanceTooltip"
                icon="SignIcon"
                :disabled="!isEsignatureTimeSheetEnabled() || !canSendEmail
                  || isEsignatureDocumentFeatureMaintenance"
                @click="sendWithSignature"
              >
                {{ $t('text_document_templates.text_document_created_modal.send_with_signature_button') }}
              </SkOroraButton>
              <SkOroraButton
                v-tooltip.top="sendEmailTooltip"
                v-track="'hris_send_by_email'"
                icon="EnvelopeV2Icon"
                variant="secondary"
                :disabled="!canSendEmail"
                @click="openMailModal"
              >
                {{ $t('text_document_templates.text_document_created_modal.send_without_signature_button') }}
              </SkOroraButton>
            </div>
          </div>
        </div>
        <div
          v-else
          class="document-card-share"
        >
          <span class="document-card-share-title">
            {{ $t('text_document_templates.text_document_created_modal.share_the_document_title') }}
          </span>
          <span class="document-card-share-subtitle">
            {{ $t('text_document_templates.text_document_created_modal.share.subtitle') }}
          </span>
          <SkOroraButton
            v-tooltip.top="sendEmailTooltip"
            v-track="'hris_send_by_email'"
            icon="EnvelopeV2Icon"
            :disabled="!canSendEmail"
            @click="openMailModal"
          >
            {{ $t('text_document_templates.text_document_created_modal.send_email') }}
          </SkOroraButton>
        </div>
      </SkModalSection>
    </template>
    <template #footer>
      <div class="back-to-documents">
        <SkOroraButton
          variant="tertiary"
          @click="handleBack"
        >
          {{ $t('text_document_templates.text_document_created_modal.back_to_documents') }}
        </SkOroraButton>
      </div>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import NewTag from '@app-js/shared/components/NewTag';
import { bottom } from '@popperjs/core';
import UpsellBannerIllustration from '../../shared/components/UpsellEsignatureBanner/UpsellBannerIllustration.vue';

export default {
  name: 'TextDocumentCreatedModal',
  components: { NewTag, UpsellBannerIllustration },
  emits: ['sign-document'],
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'isEsignatureTimeSheetEnabled']),
    ...mapGetters('foldersManagement', ['folderName']),
    ...mapState('textDocumentTemplates', {
      textDocument: state => state.selectedTextDocument,
    }),
    employee() {
      if (this.textDocument && this.textDocument.relationships) {
        return this.textDocument.relationships.user;
      }
      return null;
    },
    document() {
      if (!this.textDocument || !this.textDocument.relationships) {
        return null;
      }

      return this.textDocument.relationships.document;
    },
    canSendEmail() {
      return this.employee && !!this.employee.attributes.email;
    },
    isEsignatureDocumentFeatureMaintenance() {
      return this.isDevFlagEnabled('FEATUREDEV_DOCS_ESIGNATURE_MAINTENANCE');
    },
    esignatureMaintenanceTooltip() {
      if (!this.isEsignatureDocumentFeatureMaintenance) return false;
      return this.$t('employees.signature_followup.maintenance');
    },
    sendEmailTooltip() {
      if (this.canSendEmail) return false;
      return this.$t('text_document_templates.text_document_created_modal.send_email_missing');
    },
    modalIconOptions() {
      return { color: '#fff', backgroundColor: '#2b66fe', name: 'CheckMarkIcon' };
    },
    congratulationClasses() {
      return {
        'doc-esignature-upsell-congratulations': !this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_DOCS_ESINGATURE_UPSELL'),
      };
    },
  },
  methods: {
    openMailModal(event) {
      if (!this.canSendEmail) return;

      this.$refs.modal.hide();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, `document-send-email-modal-${this.document.id}`);
    },
    handleBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'shop_settings_documents_management' });
      }
    },
    sendWithSignature() {
      this.$refs.modal.hide();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'sign-document-modal');
    },
    showDocEsignatureUpsellModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'text-document-created-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'doc-esignature-upsell-modal');
    },
  },
};
</script>

<style lang="scss" scoped>

.doc-esignature-upsell-content {
  gap: 11px;
}

.doc-esignature-upsell {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.doc-esignature-upsell-title {
  font-style: normal;
  font-weight: 700;
  font-size: $fs-text-l;
  line-height: 24px;
  align-self: stretch;
}

.doc-esignature-upsell-title-part-1 {
  color: $sk-black;
}

.doc-esignature-upsell-title-part-2 {
  color: $sk-blue;
  font-style: normal;
}

.doc-esignature-upsell-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: $fs-text-m;
  line-height: 18px;
  color: $sk-grey;
}

.doc-esignature-upsell-discover {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: $sk-blue;
  font-family: Gellix, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: $fs-text-m;
  line-height: 18px;
  width: fit-content;
}

.text-document-created-modal__body {
  padding: 24px 0 0;
}

.document-card {
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  margin: 1rem 0;

  .text-section {
    padding-left: .5rem;

    p {
      margin-top: .5rem;
    }
  }
}

.document-card-share {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $sk-grey-5;
  border-radius: 4px;
  padding: 20px;
  margin: 24px 0 0;

  .text-section {
    padding-left: .5rem;

    p {
      margin-top: .5rem;
    }
  }
}

.document-card-share-title {
  font-weight: 700;
  font-size: $fs-text-m;
  line-height: 18px;
  text-align: center;
  color: $sk-black;
  margin-bottom: 8px;
}

.document-card-share-subtitle {
  font-weight: 400;
  font-size: $fs-text-m;
  line-height: 18px;
  text-align: center;
  color: $sk-grey;
  margin-bottom: 24px;
}

.back-to-documents {
  flex-grow: 1;
  text-align: center;
}

.sk-new-tag {
  display: inline-block;
}

.document-card.doc-esignature-upsell-content {
  margin: 20px 0;
}

.sk-modal__section.text-document-created-modal__body {
  padding: 0;
}

.doc-esignature-upsell-congratulations {
  margin: 24px 0 0;
}
</style>
