import Users from './Users';
import Personal from './pages/personal';
import Document from './pages/documents';
import HrFile from './pages/hr_file';
import Contracts from './pages/contracts/index.vue';
import ContractsTable from './pages/contracts/components/ContractsTable/index.vue';
import FullContract from './pages/contracts/components/FullContract/index.vue';

import Counter from './pages/counters';
import DayRateCounter from './pages/counters/DayRateCounter';
import HolidaysCounter from './pages/counters/HolidaysCounter';
import HoursCounter from './pages/counters/HoursCounter';
import PaidLeaveCounter from './pages/counters/PaidLeaveCounter';
import ShiftsHistory from './pages/counters/ShiftsHistory';
import RttCounter from './pages/counters/RttCounter';
import AnnualizationCounter from './pages/counters/AnnualizationCounter';

const usersRoutes = [
  {
    path: '/v3/shops/:shop_id/employees',
    component: Users,
    name: 'users',
    children: [
      {
        path: ':user_id/personal',
        name: 'user_personal',
        component: Personal,
      },
      {
        path: ':user_id/documents',
        name: 'user_documents',
        component: Document,
      },
      {
        path: ':user_id/counters',
        name: 'user_counters',
        component: Counter,
        children: [
          {
            path: 'user_day_rate',
            name: 'user_day_rate',
            component: DayRateCounter,
          },
          {
            path: 'user_holidays',
            name: 'user_holidays',
            component: HolidaysCounter,
          },
          {
            path: 'user_hours',
            name: 'user_hours',
            component: HoursCounter,
          },
          {
            path: 'user_paid_leave',
            name: 'user_paid_leave',
            component: PaidLeaveCounter,
          },
          {
            path: 'user_shift_history',
            name: 'user_shift_history',
            component: ShiftsHistory,
          },
          {
            path: 'user_rtt',
            name: 'user_rtt',
            component: RttCounter,
          },
          {
            path: 'user_annualization',
            name: 'user_annualization',
            component: AnnualizationCounter,
          },
        ],
      },
      {
        path: ':user_id/hr_file',
        name: 'user_hr_file',
        component: HrFile,
      },
      {
        path: ':user_id/contracts',
        redirect: { name: 'user_contracts_history' },
        name: 'user_contracts',
        component: Contracts,
        children: [
          {
            path: 'history',
            name: 'user_contracts_history',
            component: ContractsTable,
          },
          {
            path: 'future',
            name: 'user_contracts_future',
            component: ContractsTable,
          },
        ],
      },
      // Need to put /new route before /:contract_id route (show) to prevent 'new' to be considered as a :contract_id
      {
        path: ':user_id/contract/new',
        name: 'user_full_contract_new',
        component: FullContract,
        meta: { removeEmployeeHeader: true },
      },
      {
        path: ':user_id/contract/:contract_id/show',
        name: 'user_full_contract_show',
        component: FullContract,
        meta: { removeEmployeeHeader: true },
      },
      {
        path: ':user_id/contract/:contract_id/edit',
        name: 'user_full_contract_edit',
        component: FullContract,
        meta: { removeEmployeeHeader: true },
      },
      {
        path: ':user_id/contract/version_contract',
        name: 'user_full_contract_version',
        component: FullContract,
        meta: { removeEmployeeHeader: true },
      },
    ],
  },
];

export default usersRoutes;
