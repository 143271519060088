import fr from './onboarding.fr.yml';
import en from './onboarding.en.yml';
import es from './onboarding.es.yml';
import de from './onboarding.de.yml';
import it from './onboarding.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
