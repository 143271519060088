<template>
  <SkDropdown
    ref="dropdown"
    :class="dropdownClasses"
    placement="bottom-start"
    y-offset="5px"
    trigger="click"
  >
    <template #anchor>
      <SkCircleButton
        :disabled="isDataLoading"
        :disabled-color="$skColors.skGrey30"
        icon="KebabV2Icon"
        data-test="planning_toolbar-kebab_menu"
        @click="handleClick"
      />
    </template>
    <template #menu>
      <div :class="settingsMenuClasses">
        <component
          :is="item.name"
          v-for="(item, index) of items"
          :key="index"
          v-bind="item.props"
          :is-data-loading="isDataLoading"
        />
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';

import SmartPlannerAction from './Dropdown/SmartPlannerAction';
import PopularShiftsAction from './Dropdown/PopularShiftsAction';
import EsignatureAction from './Dropdown/EsignatureAction';
import PrintAction from './Dropdown/PrintAction';
import SortAction from './Dropdown/SortAction';
import LockPeriodAction from './Dropdown/LockPeriodAction';
import CreateFromTemplateAction from './Dropdown/CreateFromTemplateAction';
import SaveAsTemplateAction from './Dropdown/SaveAsTemplateAction';
import EraseShiftsAction from './Dropdown/EraseShiftsAction';
import DisplayOptionsAction from './Dropdown/DisplayOptionsAction';
import OptimizationAction from './Dropdown/OptimizationAction';
import UndoRedoAction from './Dropdown/UndoRedoAction';
import LockAction from './Dropdown/LockAction';

export default {
  name: 'ToolbarDropdownActionItems',
  components: {
    SmartPlannerAction,
    PopularShiftsAction,
    EsignatureAction,
    PrintAction,
    SortAction,
    LockPeriodAction,
    CreateFromTemplateAction,
    SaveAsTemplateAction,
    EraseShiftsAction,
    DisplayOptionsAction,
    OptimizationAction,
    UndoRedoAction,
    LockAction,
  },
  props: {
    isDataLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isMenuVisible: true,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('planningsState', [
      'isPostesView',
      'isDailyView',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isFirstBatchLoaded']),
    isSchedulePrintFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_SCHEDULE_PRINT, this.currentShop.id);
    },
    isPublishDisplayed() {
      return this.currentLicense.attributes.canPublishPlanning &&
        this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_PUBLICATION, this.currentShop.id);
    },
    isScheduleOptimizationFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_OPTIMISATON, this.currentShop.id);
    },
    items() {
      const actionItems = [];

      actionItems.push(
        { name: 'LockAction' },
        { name: 'UndoRedoAction', props: { type: 'undo' } },
        { name: 'UndoRedoAction', props: { type: 'redo' } },
      );

      if (!this.isPostesView) {
        actionItems.push(
          { name: 'SmartPlannerAction' },
          { name: 'PopularShiftsAction' },
          { name: 'EsignatureAction' },
        );
      }

      if (this.isSchedulePrintFeatureEnabled) {
        actionItems.push({ name: 'PrintAction' });
      }

      actionItems.push(
        { name: 'SortAction' },
        { name: 'LockPeriodAction' },
        { name: 'CreateFromTemplateAction' },
        { name: 'SaveAsTemplateAction' },
        { name: 'EraseShiftsAction' },
        { name: 'DisplayOptionsAction' },
      );

      if (this.isDailyView) return actionItems;

      if (this.isScheduleOptimizationFeatureEnabled) {
        actionItems.splice(5, 0, { name: 'OptimizationAction' });
      }

      return actionItems;
    },
    dropdownClasses() {
      return {
        planning__toolbar__dropdown: true,
        'planning__toolbar__dropdown--no-publish-button': !this.isPublishDisplayed,
      };
    },
    settingsMenuClasses() {
      return {
        'planning__toolbar__settings-menu': true,
        'planning__toolbar__settings-menu--hidden': !this.isMenuVisible,
      };
    },
  },
  mounted() {
    this.listenOnRoot(MODAL_SHOW_EVENT, this.closePops);
    this.listenOnRoot('setMenuVisibility', value => this.setMenuVisibility(value));
  },
  methods: {
    handleClick() {
      if (this.isProgressiveLoadingEnabled && this.isFirstBatchLoaded) {
        this.$root.$emit('need-all-planning-data');
      }
    },
    closePops() {
      this.$nextTick(() => {
        this.$refs.dropdown.hide();
      });
    },
    setMenuVisibility(value) {
      if (value) this.$refs.dropdown.hide();
      // setTimeout to avoid "flickering" when visiblity and display
      // are set at the exact same time
      setTimeout(() => {
        this.isMenuVisible = value;
      }, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
// Dropdown hidden menu
.planning__toolbar__settings-menu {
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;
  padding: 10px 0;
  max-height: 304px;
  overflow-y: scroll;

  &--hidden {
    visibility: hidden;
  }

  .settings-dropdown__item {
    display: flex;
    align-items: center;
    color: $sk-black;
    padding: 10px 15px;
    width: 100%;

    // we want to force the smart planner icon from dropdown to be gray (like the others)
    ::v-deep svg > path {
      fill: $sk-grey;
    }

    &:hover {
      background: $sk-grey-10;
      border-radius: 3px;
      text-decoration: none;
    }

    &--disabled {
      color: $sk-grey-30;
      cursor: default;
    }

    &--popov {
      height: 41px;
      padding: 0;
    }
  }

  .settings-dropdown__item-icon {
    margin-right: 15px;
  }
}

// Sometimes popover isn't displayed at the correct position (fixed when resizing)
// We manualy set it's position to avoid this problem
.planning__toolbar__dropdown {
  ::v-deep .sk-dropdown__menu {
    transform: translate3d(calc(100vw - 440px), 100px, 0) !important;
  }
}

/* Shift dropdown menu to the left when publish button isn't displayed */
.planning__toolbar__dropdown--no-publish-button {
  ::v-deep .sk-dropdown__menu {
    transform: translate3d(calc(100vw - 266px), 100px, 0) !important;
  }
}

.planning__toolbar-publish-button {
  width: 90px;
  height: 17px;
  justify-content: center;

  &:disabled {
    background-color: #ddd !important;
  }

  .planning__toolbar-publish-button {
    &--text {
      display: block;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--subtext {
      font-size: $fs-text-xs;
      line-height: 12px;
    }

    &--icon {
      margin-right: 10px;
      vertical-align: text-top;
    }
  }
}
</style>
