<template>
  <div>
    <div
      v-if="canSeeAvailabilityTooltip && availabilities.length > 0"
      class="manage-shift-modal__availabilities"
    >
      <IconInfo class="manage-shift-modal__availabilities-icon" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="availabilitiesDisplayText" />
    </div>
    <SkModalSection
      :class="mainShiftFormClasses"
      border-bottom="none"
    >
      <WorkShiftForm
        ref="workShiftForm"
        :date="date"
        :work-shift-key="WORK_SHIFT_KEY.MAIN"
        :is-remove-button-displayed="isMultipleWorkShiftsActive"
        :row-item="rowItem"
        :is-update-modal="isUpdateModal"
        v-on="$listeners"
        @remove-shift-form="removeShiftForm(true)"
      />
    </SkModalSection>
    <div
      v-if="!isWorkShiftUpdate"
      class="manage-shift-modal__full_separator_line"
    />
    <SkModalSection
      v-if="!isWorkShiftUpdate"
      :class="splitShiftSectionClasses"
      border-bottom="none"
    >
      <WorkShiftForm
        v-if="isMultipleWorkShiftsActive"
        :date="date"
        :work-shift-key="WORK_SHIFT_KEY.SECONDARY"
        :row-item="rowItem"
        :is-update-modal="isUpdateModal"
        v-on="$listeners"
        @remove-shift-form="removeShiftForm(false)"
      />
      <SkOroraButton
        v-else
        variant="tertiary"
        @click="displaySecondWorkShiftForm"
        @keydown.native.enter.stop=""
      >
        {{ $t('plannings.table.manage_shift_modal.tabs.commun.other_actions.add_another_shift') }}
      </SkOroraButton>
    </SkModalSection>
    <div
      v-if="isMultipleWorkShiftsActive"
      class="manage-shift-modal__full_separator_line"
    />
    <DaySelection
      v-if="!isWorkShiftUpdate && selectedCellsCount <= 1"
      is-work-shifts-tab
      :date="date"
      :row-item="rowItem"
      :possible-selection-dates="possibleSelectionDates"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { computeShiftTimes } from '@app-js/plannings/shared/utils/planning_helpers';
import { WORK_SHIFT_KEY } from '@app-js/shared/constants/shift';
import DaySelection from './DaySelection';
import WorkShiftForm from './WorkShiftForm';

export default {
  name: 'WorkShiftsTab',
  components: { DaySelection, WorkShiftForm },
  props: {
    date: {
      type: Object,
      default: () => {},
    },
    dayCellShifts: {
      type: Array,
      default: () => [],
    },
    rowItem: {
      type: Object,
      default: () => {},
    },
    availabilities: {
      type: Array,
      required: true,
    },
    isUpdateModal: {
      type: Boolean,
      required: true,
    },
    possibleSelectionDates: {
      type: Array,
      default: () => [],
    },
    canSeeAvailabilityTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      WORK_SHIFT_KEY,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsShifts', ['workShifts', 'isMultipleWorkShiftsActive']),
    ...mapGetters('planningsState', ['isDailyView', 'isPostesView']),
    ...mapGetters('planningsBulkEdit', ['selectedCellsCount']),

    isUnassignedShiftsRow() {
      return !this.rowItem.id;
    },
    isWorkShiftUpdate() {
      return !!this.workShifts.main.id;
    },
    availabilitiesDisplayText() {
      const unavailabilityHoursText = this.getAvailabilityTextForStatus('unavailable');
      let availabilityHoursText = this.getAvailabilityTextForStatus('available');

      // If availabilities and unavailabilities are present, add 'and' conjunction before availabilities
      if (availabilityHoursText.length > 0 && unavailabilityHoursText.length > 0) {
        availabilityHoursText = `${this.$t('plannings.table.manage_shift_modal.tabs.shift.availability.and')} ${availabilityHoursText}`;
      }

      // text prefix: '{user_first_name} is'
      const userIs = this.$t(
        'plannings.table.manage_shift_modal.tabs.shift.availability.user_is',
        { userName: this.rowItem.attributes.firstName },
      );

      return `${userIs} ${unavailabilityHoursText} ${availabilityHoursText}.`;
    },
    mainShiftFormClasses() {
      return {
        'manage-shift-modal__content': true,
        'manage-shift-modal__content--main-form': !this.isPostesView,
        'manage-shift-modal__content--postes-view': this.isPostesView,
        'manage-shift-modal__content--no-padding': this.isWorkShiftUpdate,
        'manage-shift-modal__content--no-top-padding': !this.isWorkShiftUpdate,
        'manage-shift-modal__content--create-shift-only-main-form-opened':
          !this.isWorkShiftUpdate && !this.isMultipleWorkShiftsActive,
      };
    },
    splitShiftSectionClasses() {
      return {
        'manage-shift-modal__content': true,
        'manage-shift-modal__content--no-bottom-padding': !this.isMultipleWorkShiftsActive,
      };
    },
    defaultSecondaryShiftTimes() {
      if (
        this.isWorkShiftUpdate || this.isUnassignedShiftsRow ||
        this.isPostesView || this.isDailyView
      ) {
        return {};
      }

      const defaultShiftTimes = computeShiftTimes({
        user: this.rowItem,
        shop: this.currentShop,
        shifts: this.dayCellShifts.concat(this.workShifts.main),
        date: this.date.format('YYYY-MM-DD'),
      });

      return defaultShiftTimes;
    },
  },
  methods: {
    ...mapMutations('planningsShifts', [
      'resetSecondWorkShift',
      'setSecondWorkShiftAsMain',
      'setSecondaryWorkShiftTimes',
      'toggleIsMultipleWorkShiftsActive',
    ]),
    getAvailabilityTextForStatus(status) {
      // Get all availabilities / unavailabilities for user
      const availabilitiesForStatus = this.availabilities
        .filter(({ attributes }) => attributes.status === status);

      if (availabilitiesForStatus.length === 0) return '';

      // for each availability matching status -> get display text for time range
      // and join them with 'and' conjunction
      const hoursText = availabilitiesForStatus
        .map(({ attributes }) => (
          `<b>${this.$t(
            'plannings.table.manage_shift_modal.tabs.shift.availability.time_range',
            {
              startTime: attributes.startTime,
              endTime: attributes.endTime,
            },
          )}</b>`
        ))
        .join(
          ` ${this.$t('plannings.table.manage_shift_modal.tabs.shift.availability.and')} `,
        );

      // available / unavailable
      const statusText = this.$tc(
        'plannings.table.manage_shift_modal.tabs.shift.availability.status',
        status === 'available',
      );

      // available status text color is green, unavailable is red
      const statusColor = status === 'available' ? '#00d231' : '#fe0056';

      return `<span style="color: ${statusColor};">${statusText}</span> ${hoursText}`;
    },
    displaySecondWorkShiftForm(event) {
      event.stopPropagation();
      this.$skAnalytics.track('click_on_add_second_shift');
      this.setSecondaryWorkShiftTimes({
        ...this.defaultSecondaryShiftTimes,
        date: this.date,
        currentShop: this.currentShop,
      });
      this.toggleIsMultipleWorkShiftsActive(true);
    },
    removeShiftForm(isMainWorkShiftForm) {
      this.toggleIsMultipleWorkShiftsActive(false);
      if (isMainWorkShiftForm) {
        this.setSecondWorkShiftAsMain();
      }
      this.resetSecondWorkShift();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal {
  /* Shrink tooltip */
  .sk-popover--tooltip {
    padding: 5px 8px;
    font-size: $fs-text-s;
    text-align: center;
    max-width: 155px;

    * {
      margin: 0;
      padding: 0;
    }
  }
}

::v-deep .sk-modal__section.manage-shift-modal__content {
  padding-bottom: 24px;
}

.manage-shift-modal__content {
  &--flex {
    padding: 8px 0 !important;
    display: flex;
    flex-direction: row;
  }

  &--disabled {
    color: #b9bfc4;
    pointer-events: none;
  }

  &--main-form {
    margin-top: 24px !important;
  }

  &--postes-view {
    margin-top: 24px !important;
  }

  &--create-shift-only-main-form-opened {
    padding-bottom: 20px !important;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--no-top-padding {
    padding-top: 0 !important;
  }

  &--no-bottom-padding {
    padding-bottom: 0 !important;
  }
}

.manage-shift-modal__availabilities {
  display: flex;
  width: calc(100% - 48px);
  padding: 9px 17px;
  margin: 13px 24px 16px;
  box-sizing: border-box;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
}

.manage-shift-modal__availabilities-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 11px;
}

.manage-shift-modal__full_separator_line {
  width: 100%;
  height: 1px;
  background-color: $sk-grey-10;
}
</style>
