<template>
  <div class="progress-bar__container">
    <ProgressBarCell
      v-for="step in stepCount"
      :key="`step-${step}`"
      :state="getState(step)"
    />
  </div>
</template>

<script>
import ProgressBarCell from './ProgressBarCell.vue';

export default {
  name: 'ProgressBar',
  components: {
    ProgressBarCell,
  },
  props: {
    stepCount: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    getState(step) {
      if (this.currentStep === step) {
        return 'current';
      }

      return this.currentStep < step ? 'future' : 'past';
    },
  },
};
</script>

<style lang="scss" scoped>

.progress-bar__container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 464px;
}

</style>
