import Cookies from 'js-cookie';
import {
  AuthWebClient, TokenWebModel,
} from '@skelloapp/skello-auth-client';
import {
  authApiBaseUrl,
  cookieDomain,
  forceSvcUser,
  svcUsersUrl,
  superadminFrontendServerUrl,
  skelloApiBaseUrl,
} from '@config/env';
import axios from 'axios';
import { redirectToSuperadmin } from '@skello-utils/redirection';
import {
  BaseHeaders,
  KEY_AUTH_ACCESS_TOKEN,
  KEY_AUTH_ADMIN_REFRESH_TOKEN,
  KEY_AUTH_IMPERSONATE_USER_ID,
  KEY_AUTH_REFRESH_TOKEN,
  KEY_SIGNING_OUT,
  SUPERADMIN_REFRESH_TOKEN,
} from './constant_client';

import { INCLUDED_DOMAINS_REGEXP } from './svc_user/canary_domains.js';

class AuthClient {
  constructor() {
    this.authClient = new AuthWebClient({
      headers: BaseHeaders,
      baseURL: authApiBaseUrl,
    });

    /* TODO: [Clean Jwts Auth] */
    this.forceSvcUser = forceSvcUser;
    this.svcUserAuthClient = new AuthWebClient({
      headers: BaseHeaders,
      baseURL: svcUsersUrl,
    });

    this.cookieDomain = cookieDomain;

    this.getAuthTokenFromStorage();
  }

  async login({ email, password, rememberMe }) {
    Cookies.remove(KEY_SIGNING_OUT);

    let data;
    if (this.useSvcUserAuthClient(email)) {
      data = await this.svcUserAuthClient.login({
        email,
        password,
        rememberMe,
      });
    } else {
      data = await this.authClient.login({ email, password, rememberMe });
    }

    this.setAuthToken(data);

    // keep it in local for going back to superadmin when using impersonate
    if (this.authToken.tokenData.superAdmin) {
      localStorage.setItem(SUPERADMIN_REFRESH_TOKEN, this.authToken.refreshToken);
    }
  }

  async getAuthToken() {
    this.getAuthTokenFromStorage();

    if (!this.authToken) {
      return null;
    }

    await this.refreshToken();

    if (!this.authToken) {
      throw new Error('Token is empty.');
    }
    return this.authToken;
  }

  async refreshToken() {
    if (!this.authToken.refreshToken || this.authToken.refreshTokenIsExpired()) {
      await this.logOut();

      return null;
    }

    if (this.authToken.tokenIsExpired()) {
      let data;

      if (this.useSvcUserAuthClient()) {
        data = await this.svcUserAuthClient.refreshToken({
          refreshToken: this.authToken.refreshToken,
        });
      } else {
        data = await this.authClient.refreshToken({
          refreshToken: this.authToken.refreshToken,
        });
      }

      this.setAuthToken(data);
    }

    return this.authToken;
  }

  async forceRefreshToken() {
    let data;

    if (this.useSvcUserAuthClient()) {
      data = await this.svcUserAuthClient.refreshToken({
        refreshToken: this.authToken.refreshToken,
      });
    } else {
      data = await this.authClient.refreshToken({
        refreshToken: this.authToken.refreshToken,
      });
    }

    this.setAuthToken(data);
  }

  setAuthToken(data) {
    this.authToken = data;

    localStorage.setItem(KEY_AUTH_ACCESS_TOKEN, this.authToken.token);
    localStorage.setItem(KEY_AUTH_REFRESH_TOKEN, this.authToken.refreshToken);

    // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
    Cookies.set(KEY_AUTH_REFRESH_TOKEN, this.authToken.refreshToken, {
      expires: new Date(data.refreshExp * 1000),
    });

    // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
    if (!data.tokenData.impersonating) {
      Cookies.set(KEY_AUTH_ADMIN_REFRESH_TOKEN, this.authToken.refreshToken, {
        domain: this.cookieDomain,
        expires: new Date(data.refreshExp * 1000),
      });
    }

    return this.authToken;
  }

  deleteAuthToken() {
    localStorage.removeItem(KEY_AUTH_ACCESS_TOKEN);
    localStorage.removeItem(KEY_AUTH_REFRESH_TOKEN);

    // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
    Cookies.remove(KEY_AUTH_IMPERSONATE_USER_ID);
    Cookies.remove(KEY_AUTH_REFRESH_TOKEN);
  }

  getLogoutHttpClient() {
    if (this.logoutHttpClient) {
      return this.logoutHttpClient;
    }
    // it should not be another axios client but for now: we create a new one each logout
    // It does not use the one in http client for circular dep
    this.logoutHttpClient = axios.create({
      headers: BaseHeaders,
      baseURL: skelloApiBaseUrl,
    });
    this.logoutHttpClient.defaults.headers.common.Authorization = `Bearer ${this.authToken.token}`;
    return this.logoutHttpClient;
  }

  async logOut() {
    try {
      const logoutClient = this.getLogoutHttpClient();
      await logoutClient.delete('/v3/users/sign_out');
    } catch (e) {
      console.error('Error while logging out', e);
    } finally {
      this.destroySession();
    }
  }

  useSvcUserAuthClient(email) {
    if (this.forceSvcUser) {
      return true;
    }

    if (!window.featureFlag?.FEATUREDEV_SVC_USERS) return false;

    let subEmail = email;

    if (!subEmail) {
      this.getAuthTokenFromStorage();
      subEmail = this.authToken?.tokenData?.data?.email;
    }

    return this.isSvcUserEmail(subEmail);
  }

  isSvcUserEmail(email) {
    // match whatever+jwt-alias10k@skello.io
    const msUsersEmails = /^.+\+jwt(-[a-z\d\-_]*)*@skello\.io$/gi;
    // match qa-team+whatever@skello.io
    const qaTeamEmails = /^qa-team(\+.*)*@skello\.io$/gi;

    return msUsersEmails.test(email) ||
      INCLUDED_DOMAINS_REGEXP.test(email) ||
      (qaTeamEmails.test(email) && window.featureFlag?.FEATUREDEV_SVC_USERS_QA);
  }

  destroySession() {
    this.deleteAuthToken();
    Cookies.set(KEY_SIGNING_OUT, true);
    localStorage.removeItem(SUPERADMIN_REFRESH_TOKEN);

    // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
    Cookies.remove(KEY_AUTH_ADMIN_REFRESH_TOKEN, { domain: this.cookieDomain });
  }

  // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
  async logOutLegacyImpersonate() {
    this.deleteAuthToken();
    const superAdminRefreshToken = Cookies.get(KEY_AUTH_ADMIN_REFRESH_TOKEN, {
      domain: this.cookieDomain,
    });

    if (!superAdminRefreshToken) {
      this.logOut();
    } else {
      let superAdminTokens;

      if (this.useSvcUserAuthClient()) {
        superAdminTokens = await this.svcUserAuthClient.refreshToken({
          refreshToken: superAdminRefreshToken,
        });
      } else {
        superAdminTokens = await this.authClient.refreshToken({
          refreshToken: superAdminRefreshToken,
        });
      }

      this.setAuthToken(superAdminTokens);

      const logoutClient = this.getLogoutHttpClient();

      logoutClient.get('/corporate/stop_impersonating', {
        params: {
          user_id: superAdminTokens.userId,
        },
      }).then(response => {
        redirectToSuperadmin(response.data.url);
      });
    }
  }

  async logOutImpersonate() {
    this.deleteAuthToken();
    const logoutClient = this.getLogoutHttpClient();

    try {
      const refreshToken = localStorage.getItem(SUPERADMIN_REFRESH_TOKEN);

      await logoutClient.get('/v3/users/sessions/stop_impersonating');

      redirectToSuperadmin(`${superadminFrontendServerUrl}?authent_with_token=${refreshToken}`);
    } catch (error) {
      console.error('Error while stopping impersonating', error);
      this.logOut();
    }
  }

  getAuthTokenFromStorage() {
    if (!this.authToken) {
      const token = localStorage.getItem(KEY_AUTH_ACCESS_TOKEN);
      const refreshToken = localStorage.getItem(KEY_AUTH_REFRESH_TOKEN);

      if (token || refreshToken) {
        this.authToken = new TokenWebModel({ token, refreshToken });
      }
    }
  }
}

export const authClient = new AuthClient();
