<template>
  <!-- Employee view -->
  <component
    :is="!isUnassignedShiftsRow ? 'router-link' : 'div'"
    v-if="storeInfos.isEmployeesView"
    v-track="!isUnassignedShiftsRow ? 'click_on_employee_name_week' : null"
    class="planning-row__sidebar-cell__wrapper cell--shadow cell--shadow-right"
    :to="routeTarget"
  >
    <div class="planning-row__sidebar-cell__content">
      <AvatarMedallion
        :size="zoomRange.avatar.size"
        :avatar-url="avatarUrl"
        :medallion-text="medallionText"
        :errored="!!contractWarningText"
        :error-tooltip="contractWarningText"
        class="planning-row__sidebar-cell__content--avatar"
      />
      <div :class="sidebarNameClasses">
        <template v-if="!isUnassignedShiftsRow">
          <div class="planning-row__sidebar-name__additional-info">
            <div
              v-if="isEmployeeBirthday"
              class="planning-row__sidebar-name__additional-info--is-birthday"
            >
              {{ $t('plannings.table.header.days.emoji.cake') }}
            </div>
            <div
              v-if="isEmployeeUnderage"
              class="planning-row__sidebar-name__additional-info--is-underage"
            >
              {{ $t('plannings.sidebar.underage') }}
            </div>
          </div>
          <div
            ref="firstName"
            v-tooltip="firstNameTooltip"
            :class="sidebarNameEllipsisClasses"
          >
            {{ rowItem.attributes.firstName }}
          </div>
          <div
            ref="lastName"
            v-tooltip="lastNameTooltip"
            :class="sidebarNameEllipsisClasses"
          >
            {{ rowItem.attributes.lastName }}
          </div>
        </template>
        <div
          v-else
          class="planning-row__sidebar-name--unassigned"
        >
          {{ $t('plannings.sidebar.unassigned.label') }}
        </div>
      </div>
      <div
        v-if="!isUnassignedShiftsRow"
        :class="sidebarContractClasses"
      >
        <SkPopover
          v-if="cyclicAmendments.length > 0"
          y-offset="6"
          as-tooltip
        >
          <template #anchor>
            <VariableContractHoursTag
              class="planning-row__sidebar-contract-tag"
              :nb-weeks="cyclicAmendments.length"
              :specific-week-index="weekIndex"
            />
          </template>
          <template #content>
            <div
              v-for="cyclicAmendment in cyclicAmendments"
              :key="cyclicAmendment.id"
              class="planning-row__sidebar-variable-row"
            >
              <VariableContractHoursTag
                class="planning-row__sidebar-variable-row-tag"
                :nb-weeks="cyclicAmendments.length"
                :specific-week-index="cyclicAmendment.schedule_week_index"
              />
              <div class="planning-row__sidebar-variable-hours">
                {{ cyclicAmendment.hours }}h
              </div>
            </div>
          </template>
        </SkPopover>
        <div
          ref="contractHoursLabel"
          v-tooltip.oneLine="contractHoursTooltip"
          class="planning-row__sidebar-contract-info"
          :style="contractHoursColor"
        >
          <SkLoader
            v-if="isEmployeeAnnualized &&
              isAnnualizationLoading"
            size="small"
          />
          <template v-else>
            {{ contractInfo }}
          </template>
        </div>
        <template v-if="isEmployeeAnnualized">
          <SkLoader
            v-if="isAnnualizationLoading"
            size="small"
          />
          <div
            v-else
            class="
              planning-row__sidebar-contract-info planning-row__sidebar-contract-info--annualized
            "
            :style="contractHoursColor"
          >
            {{ $t('plannings.sidebar.annualized') }}
          </div>
        </template>
      </div>
    </div>
  </component>
  <!-- Poste view -->
  <span
    v-else
    class="planning-row__sidebar-cell__wrapper cell--shadow cell--shadow-right"
  >
    <div class="planning-row__sidebar-poste__wrapper">
      <span
        class="planning-row__sidebar-poste__medallion"
        :style="{ background: rowItem.attributes.color }"
      />
      <div
        ref="posteName"
        v-tooltip="posteNameTooltip"
        class="planning-row__sidebar-poste__name"
      >
        {{ rowItem.attributes.name }}
      </div>
    </div>
  </span>
</template>

<script>
import skDate from '@skello-utils/dates';
import { LICENSE_COLORS } from '@app-js/shared/constants/colors';
import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';
import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

export default {
  name: 'SidebarCell',
  components: {
    AvatarMedallion,
    VariableContractHoursTag,
  },
  props: {
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    storeInfos: {
      type: Object,
      required: true,
    },
    contractWarningText: {
      type: String,
      required: true,
    },
    isMouseInsideRow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isContractHoursLabelCut: false,
      isFirstNameLabelCut: false,
      isLastNameLabelCut: false,
      isPosteNameLabelCut: false,
    };
  },
  computed: {
    medallionText() {
      return this.isUnassignedShiftsRow ?
        this.$t('plannings.sidebar.unassigned.initials') :
        this.storeInfos.userInitials(this.rowItem);
    },
    contractHoursColor() {
      return {
        color:
          LICENSE_COLORS[this.rowItem.relationships.highestLicense.attributes.originalType].color,
      };
    },
    contractInfo() {
      if (this.rowItem.attributes.onExtra) {
        return this.$t('plannings.sidebar.extra');
      }
      if (this.rowItem.attributes.onDayRate) {
        return this.$t('plannings.sidebar.day_rate');
      }

      return `${Math.round(this.rowItem.attributes.currentContractHours * 100) / 100}h`;
    },
    avatarUrl() {
      if (this.isUnassignedShiftsRow) return '';

      return this.storeInfos.getAvatarUrlForUser(this.rowItem.id);
    },
    // change styles given the value of zoom range
    zoomRange() {
      const { avatarMedaillon } = zoomPlanningWeek(this.storeInfos.planningZoom);
      return avatarMedaillon;
    },
    routeTarget() {
      if (this.storeInfos.currentLicense.attributes.canReadEmployeeInfo) {
        return { name: 'user_personal', params: { user_id: this.rowItem.id } };
      }

      return '';
    },
    contractHoursTooltip() {
      return this.isContractHoursLabelCut ? this.contractInfo : null;
    },
    firstNameTooltip() {
      return this.isFirstNameLabelCut ? this.rowItem.attributes.firstName : null;
    },
    lastNameTooltip() {
      return this.isLastNameLabelCut ? this.rowItem.attributes.lastName : null;
    },
    posteNameTooltip() {
      return this.isPosteNameLabelCut ? this.rowItem.attributes.name : null;
    },
    startOfMonday() {
      return skDate(this.storeInfos.monday).startOf('day');
    },
    weekIndex() {
      const userTeamIds = this.rowItem.relationships.teams.data.map(team => parseInt(team.id, 10));

      const teamSchedule = this.storeInfos.teamSchedules.find(ts => {
        const { teamId, endDate, weekCycleStartIndex } = ts.attributes;
        const isUserTeam = userTeamIds.includes(teamId);
        const isNotExpired = !endDate || !skDate(endDate).isSameOrBefore(this.startOfMonday);

        const matchesLastAmendment = this.lastAmendment &&
          ts.id === this.lastAmendment.team_schedule_id.toString() &&
          weekCycleStartIndex === this.lastAmendment.schedule_week_index;

        return (isUserTeam && isNotExpired) || matchesLastAmendment;
      });

      if (!teamSchedule) return 0;
      const startDate = skDate(teamSchedule.attributes.startDate).startOf('day');

      return (this.startOfMonday.diff(startDate, 'weeks') + teamSchedule.attributes.weekCycleStartIndex) %
        teamSchedule.attributes.weekCycle;
    },
    lastAmendment() {
      return this.rowItem.attributes.cyclicAmendments.reduce((latest, cyclicAmendment) => {
        const startDate = skDate(cyclicAmendment.starts_at).startOf('day');
        const endDate = cyclicAmendment.ends_at ? skDate(cyclicAmendment.ends_at).startOf('day') : null;

        const isValid = startDate.isSameOrBefore(this.startOfMonday) &&
          (!endDate || endDate.isSameOrAfter(this.startOfMonday));

        if (isValid && (!latest || startDate.isAfter(skDate(latest.starts_at)))) {
          return cyclicAmendment;
        }

        return latest;
      }, null);
    },
    cyclicAmendments() {
      if (!this.rowItem.attributes.cyclicAmendments) return [];

      return this.rowItem.attributes.cyclicAmendments.filter(
        cyclicAmendment => skDate(this.storeInfos.monday).isSameOrAfter(skDate(cyclicAmendment.starts_at), 'day'),
      );
    },
    sidebarContractClasses() {
      return {
        'planning-row__sidebar-contract': true,
        'planning-row__sidebar-contract--shrinked': !this.storeInfos.isDailyView && this.isMouseInsideRow,
      };
    },
    sidebarNameClasses() {
      return {
        'planning-row__sidebar-name': true,
        'planning-row__sidebar-name--shrinked': !this.storeInfos.isDailyView && this.isMouseInsideRow,
      };
    },
    sidebarNameEllipsisClasses() {
      return {
        'planning-row__sidebar-name--ellipsis': true,
        'planning-row__sidebar-name--ellipsis-shrinked': this.isEmployeeAnnualized,
      };
    },
    isAnnualizationLoading() {
      return this.storeInfos.currentShop.attributes.isAnnualizationV2Active &&
        this.storeInfos.areEmployeeAnnualizationConfigsLoading;
    },
    isEmployeeAnnualized() {
      return this.storeInfos
        .isAnnualizedWorkingTimeAvailable({ shop: this.storeInfos.currentShop }) &&
        this.storeInfos.isAnnualizationCurrentlyActive &&
        !this.storeInfos.areEmployeeAnnualizationConfigsLoading &&
        this.storeInfos.employeeAnnualizationConfigs[this.rowItem.id];
    },
    isEmployeeBirthday() {
      if (!this.storeInfos.isDailyView) return false;

      const { birthdayDisplay } = this.storeInfos.shopPlanningConfig.attributes;
      const { birthday: birthdayString } = this.rowItem.attributes;
      const birthday = skDate(birthdayString);
      const selectedDay = skDate(this.storeInfos.currentDate);
      return (
        birthday.isValid() &&
        birthdayDisplay &&
        birthday.month() === selectedDay.month() &&
        birthday.date() === selectedDay.date()
      );
    },
    isEmployeeUnderage() {
      const { birthday: birthdayString } = this.rowItem.attributes;
      if (!birthdayString) return false;
      const birthday = skDate.utc(birthdayString);
      const currentDate = skDate.utc(this.storeInfos.currentDate);
      const age = currentDate.diff(birthday, 'years');
      return age < 18;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isContractHoursLabelCut = !this.$refs.contractHoursLabel ||
        this.$refs.contractHoursLabel.offsetWidth < this.$refs.contractHoursLabel.scrollWidth;

      this.isFirstNameLabelCut = !this.$refs.firstName ||
        this.$refs.firstName.offsetWidth < this.$refs.firstName.scrollWidth;

      this.isLastNameLabelCut = !this.$refs.lastName ||
        this.$refs.lastName.offsetWidth < this.$refs.lastName.scrollWidth;

      this.isPosteNameLabelCut = !this.$refs.posteName ||
        this.$refs.posteName.offsetHeight < this.$refs.posteName.scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__sidebar-cell__wrapper {
  display: flex;
  align-items: center;
  min-width: 160px;
  position: relative;
  background-color: white;
  border-bottom: 1px solid $sk-grey-10;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  ::v-deep .sk-popover--tooltip {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 4px 5px 8px;
    font-size: $fs-text-s;
    text-align: center;
    max-width: 108px;
  }
}

.planning-row__sidebar-name {
  margin-left: 10px;
  font-size: 1.2em;
  width: 100%;
  max-width: 56px;
  line-height: 14px;

  &__additional-info {
    display: flex;
    align-items: baseline;
    font-size: $fs-text-xs;

    &--is-birthday {
      margin-right: 4px;
    }

    &--is-underage {
      color: #727272;
    }
  }

  &--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &--unassigned {
    white-space: normal;
  }

  &--shrinked {
    max-width: 34px;
  }

  &--ellipsis-shrinked {
    max-width: 48px;
  }
}

.planning-row__sidebar-contract-info {
  white-space: nowrap;
  width: 100%;
  font-size: 1.2em;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;

  &--annualized {
    font-size: $fs-text-xs;
  }
}

.planning-row__sidebar-cell__content {
  width: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.planning-row__sidebar-cell__content--avatar {
  flex-shrink: 0;
}

.planning-row__sidebar-contract {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  width: 46px;
  margin-left: 4px;
  line-height: normal;
  gap: 2px;

  &--shrinked {
    margin-left: 4px;
  }
}

.planning-row__sidebar-contract-tag {
  display: flex;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-size: $fs-text-xxs;
  padding: 0;
  border-radius: 2px;
}

.planning-row__sidebar-variable-hours {
  padding-left: 6px;
  padding-right: 5px;
}

.planning-row__sidebar-variable-row {
  max-width: 48px;
  width: 100%;
  display: flex;
  align-items: center;
}

.planning-row__sidebar-variable-row-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 16px;
  font-size: $fs-text-xxs;
  padding: 0;
}

.planning-row__sidebar-poste__wrapper {
  display: flex;
  align-items: baseline;
  padding: 0 16px;
}

.planning-row__sidebar-poste__name {
  // Use ellipsis for text exceeding 2 lines
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 110px;
  font-size: 1.4em;
}

.planning-row__sidebar-poste__medallion {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
