<template>
  <div
    ref="uneditableCellTooltip"
    v-tooltip="tooltipMessage"
    :class="cellClasses"
    :style="cellStyle"
    @dblclick="handleCellEdition"
    @click="handleCellFocus"
  >
    <div :class="wrapperClasses">
      <input
        v-if="editionMode"
        ref="input"
        v-model="cellValue"
        type="text"
        tabindex="-1"
        class="kpi-cell__input"
        :maxlength="maxInputLength"
        @blur="onBlur"
        @keyup.enter="onEnter"
        @keydown="validateInput($event)"
      >
      <div
        v-else
        class="kpi-cell__value"
      >
        <div
          v-if="kpiName === 'worked_hours' && !total"
          class="kpi-cell__value__number-of-employee"
          :style="{ top: isLastRow ? '36%' : '31%' }"
        >
          {{ $t('kpis.attributes.worked_hours.number_of_users', { usersCount: numberOfEmployees }) }}
        </div>
        {{ cellValue }}
      </div>
      <template v-if="displayOverHours">
        <popper
          ref="popover"
          class="kpi-cell__value__overs-hours"
          :options="{
            placement: 'auto',
            modifiers: {
              offset: { offset: '0, 15' },
              preventOverflow: { enabled: true },
            },
          }"
          :delay-on-mouse-over="20"
        >
          <div
            v-if="!hasOverHours"
            class="kpi-cell__value__overs-hours__tooltip"
          >
            {{ $t('kpis.over_hours.tooltip') }}
          </div>
          <div v-else /> <!-- popper needs a div even empty -->

          <div
            slot="reference"
            class="kpi-cell__value__overs-hours__count"
          >
            <span>{{ $t('kpis.over_hours.count.phrase_1') }}</span>
            <span class="kpi-cell__value__overs-hours__count--enhanced">{{ overHoursValue }}</span>
            <span>{{ $t('kpis.over_hours.count.phrase_2') }}</span>
          </div>
        </popper>
      </template>
      <div :class="unitClass">
        {{ unit }}
      </div>
      <div
        v-if="showPerformanceArrow || isCellLoading"
        ref="performanceArrow"
        class="kpi-cell__performance-arrow-wrapper"
        @mouseenter="onArrowEnter"
        @mouseleave="onArrowLeave"
      >
        <div
          v-if="isCellLoading"
          class="kpi-cell__spinner"
        >
          <SkLoader size="small" />
        </div>
        <div v-else>
          <PerformanceArrow
            v-if="displayPerformanceTooltip"
            :performance="performance"
            :arrow="arrow"
            :secondary-color="applySecondaryColor"
          />

          <CircledExclamationMarkIcon
            v-else-if="kpiName !== 'worked_hours'"
            :fill="kpiName === 'revenue' ? '#dddddd' : '#727272'"
            :background-color="kpiName === 'revenue' ? '#727272' : '#dddddd'"
          />
        </div>
      </div>

      <div
        v-if="focused"
        class="kpi-cell__focus-grab"
        @mousedown="onGrabberMouseDown"
      />
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapState,
} from 'vuex';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'KpiCell',
  components: { popper: Popper },
  props: {
    label: {
      type: String,
      required: true,
    },
    numberOfEmployees: {
      type: Number,
      default: null,
    },
    predicted: {
      type: Boolean,
      required: true,
    },
    kpisFeatureStates: {
      type: Object,
      required: true,
    },
    currentShop: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    kpiName: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
    predictedKpi: {
      type: Number,
      default: null,
    },
    realKpi: {
      type: Number,
      default: null,
    },
    total: { // Distinguish value cells from total cells
      type: Boolean,
      default: false,
    },
    isLastRow: {
      type: Boolean,
      default: false,
    },
    predictedTotal: {
      type: Number,
      default: null,
    },
    realTotal: {
      type: Number,
      default: 0,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    overHours: {
      type: Number,
      default: null,
    },
    tooltipMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focused: false,
      editionMode: false,
      originalCellValue: null,
      editedCellValue: null,
      isSelecting: false,
      originalCell: null,
      editedCells: [],
      maxInputLength: 12,
    };
  },
  computed: {
    ...mapState('planningsKpis', [
      'kpisLoading',
      'loadingCells',
    ]),

    isEditableRow() {
      return ['revenue', 'volume'].includes(this.kpiName);
    },
    isShownAsReadonly() {
      return !this.isEditableRow && this.kpiName !== 'worked_hours';
    },
    applySecondaryColor() {
      return this.kpiName === 'revenue';
    },
    cellIdentifier() {
      if (!this.isEditableRow) return null;

      return `${this.kpiName}:${this.date}`;
    },
    isCellLoading() {
      return this.kpisLoading && this.loadingCells.includes(this.cellIdentifier);
    },
    showPerformanceArrow() {
      return !this.predicted && !this.editionMode;
    },
    unitClass() {
      return this.showPerformanceArrow || this.isCellLoading ? 'kpi-cell__unit' : 'kpi-cell__unit--right';
    },
    cellClasses() {
      return {
        'kpi-cell__container': true,
        'kpi-cell__revenue': this.kpiName === 'revenue',
        'kpi-cell__readonly': this.readonly, // Very important for drag & copy feature
        'kpi-cell__focused': this.focused, // Very important for drag & copy feature
        'kpi-cell__greyed-out': this.isShownAsReadonly,
      };
    },
    cellStyle() {
      let cursorStyle = (this.readonly || this.total) ? 'inherit' : 'pointer';

      if (!this.total && this.isEditableRow) {
        cursorStyle = this.readonly ? 'not-allowed' : 'pointer';
      }

      return {
        backgroundColor: this.backgroundColor,
        cursor: cursorStyle,
      };
    },
    wrapperClasses() {
      return {
        'kpi-cell__wrapper': true,
        'kpi-cell__edition-mode': this.editionMode,
      };
    },
    cellValue: {
      get() {
        // If the Cell is a total cell
        if (this.total) {
          return this.predicted ? this.predictedTotal : this.realTotal;
        }

        // If user has edited the value
        if (this.editedCellValue !== null) return this.editedCellValue;

        // Otherwise, show the saved server value
        return this.predicted ? String(this.predictedKpi) : String(this.realKpi);
      },
      set(newCellValue) {
        this.editedCellValue = newCellValue;
      },
    },
    predictedValue() {
      if (this.total) return this.predictedTotal;
      return this.predictedKpi ? this.predictedKpi : 0;
    },
    realValue() {
      if (this.total) return this.realTotal;
      return this.realKpi ? this.realKpi : 0;
    },
    /**
     * For these KPIs the performance arrow logic
     * has to be inverted, because a salary_mass that
     * increases is a bad thing.
     */
    isInvertedPerformanceLogic() {
      return [
        'total_salary_mass',
        'total_salary_mass_with_costs',
        'salary_mass_productive_with_costs',
        'salary_mass_unproductive_with_costs',
        'salary_mass_ratio',
        'salary_mass_ratio_with_costs',
      ].includes(this.kpiName);
    },
    differenceComputation() {
      if (this.predicted) return 0;
      return this.total ?
        this.realTotal - this.predictedTotal :
        this.realKpi - this.predictedKpi;
    },
    differencePercentage() {
      if (this.predicted) return 0;
      const dividend = this.differenceComputation;
      const divisor = this.total ? this.predictedTotal : this.predictedKpi;
      return this.safeDivision(dividend, divisor);
    },
    performance() {
      if (this.differenceComputation === 0) return 'neutral';

      if (this.isInvertedPerformanceLogic) {
        return this.differenceComputation > 0 ? 'negative' : 'positive';
      }

      return this.differenceComputation > 0 ? 'positive' : 'negative';
    },
    arrow() {
      if (this.differenceComputation === 0) return 'neutral';
      return this.differenceComputation > 0 ? 'positive' : 'negative';
    },
    displayOverHours() {
      return this.kpiName === 'worked_hours' && this.total && !this.predicted;
    },
    overHoursValue() {
      if (!this.hasOverHours) return '-';

      return this.overHours;
    },
    hasOverHours() {
      return this.overHours !== null;
    },
    displayPerformanceTooltip() {
      if (this.enabledKpisCount === 0) return false; // pack basic
      if (this.enabledKpisCount > 1) return true; // pack premium

      // for pack success performance tooltip is displayed only for revenue
      return this.kpiName === 'revenue';
    },
    enabledKpisCount() {
      return Object.values(this.kpisFeatureStates.predicted).filter(kpi => kpi).length;
    },
    hasInputFloatingDot() {
      return this.cellValue?.match(/\.|,/g)?.length > 0;
    },
  },
  mounted() {
    // Once kpis are re-fetch, re init editedCellValue, which can be manually modified by user
    this.listenOnRoot('kpi-data-updated', () => {
      this.editedCellValue = null;
      this.setKpisLoading(false);
      this.setLoadingCells([]);
    });

    this.originalCellValue = this.cellValue;

    if (this.isEditableRow) {
      // If another cell than the current one is focused,
      // We want to defocus this one
      this.listenOnRoot('focusing-cell', (kpiName, date) => {
        if (kpiName !== this.kpiName || date !== this.date) {
          this.focused = false;
        }
      });
    }
  },
  methods: {
    ...mapMutations('planningsKpis', [
      'setKpisLoading',
      'setLoadingCells',
    ]),

    onArrowEnter() {
      let tooltipData = {};
      let tooltipType = null;

      // Hide the v-tooltip so that we only have the KpiTooltip shown
      this.$refs.uneditableCellTooltip.instance.visible = false;

      if (this.displayPerformanceTooltip) {
        tooltipType = 'performance';
        tooltipData = {
          title: this.label,
          unit: this.unit,
          arrow: this.arrow,
          performance: this.performance,
          predictedValue: this.predictedValue,
          realValue: this.realValue,
          differenceComputation: this.differenceComputation,
          differencePercentage: this.differencePercentage,
        };
      } else {
        tooltipType = 'upsell';
      }
      // Pass the information to be displayed by KpiTooltip
      this.emitOnRoot('show-kpi-tooltip', {
        anchor: this.$refs.performanceArrow,
        tooltipType,
        tooltipData,
      });
    },
    onArrowLeave() {
      this.emitOnRoot('hide-kpi-tooltip', this.displayPerformanceTooltip);

      // Show v-tooltip again
      this.$refs.uneditableCellTooltip.instance.visible = true;
    },
    onGrabberMouseDown(event) {
      this.$emit('focus-grab', event.target.closest('.kpi-row__data-cell'));
    },
    handleCellFocus() {
      // Total cells and readonly are not allowed to be edited
      if (this.readonly || this.total) return;

      // Avoid focusing if we are trying to edit the cell
      if (this.editionMode) {
        this.focused = false;
        return;
      }

      this.focused = true;
      this.emitOnRoot('focusing-cell', this.kpiName, this.date);
    },
    handleCellEdition() {
      // Total cells and readonly are not allowed to be edited
      if (this.readonly || this.total) return;

      // Remove focus
      this.focused = false;

      // If already editing
      if (this.editionMode) return;

      // Toggle edition
      this.editionMode = !this.editionMode;

      // If cellValue is zero, we want to show an empty cell
      if (this.cellValue === '0') this.cellValue = '';

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    validateInput(event) {
      // Allow BackSpace - Delete - Left & Right Arrow
      if ([8, 46, 37, 39].includes(event.keyCode)) return true;

      // Block everything that is not numeric or . ,
      if (!event.key.match('^[0-9.,]+$')) {
        event.preventDefault();
        return false;
      }

      // Block if there is already a '.' or ','
      if (this.hasInputFloatingDot && event.key.match(/\.|,/)) {
        event.preventDefault();
        return false;
      }

      // We cannot use a computed as cellValue is updated after the keydown event
      this.updateInputMaxLength(event.key);

      return true;
    },
    updateInputMaxLength(inputKey) {
      // Increase input max length to not count the dot or comma as a character
      if (!this.hasInputFloatingDot && inputKey.match(/\.|,/)) {
        this.maxInputLength = 10;
        return;
      }

      // Decrease character count if no dot or comma in the input
      if (!this.hasInputFloatingDot) {
        this.maxInputLength = 9;
      }
    },
    onEnter(event) {
      event.target.blur();
      event.stopPropagation();
    },
    onBlur() {
      // When value is empty, we force zero
      if (this.editedCellValue === '') this.cellValue = '0';

      // Toggle edition
      this.editionMode = !this.editionMode;

      // Edge case : when cell is clicked then blur,
      // But the user never edited the value
      if (this.editedCellValue === null) return;

      // If user edited the value && its different than the original value
      if (this.editedCellValue !== this.originalCellValue) {
        this.editedCellValue = this.stringToRoundTwo(this.editedCellValue);
        this.saveNewKpiValues();
      }
    },
    saveNewKpiValues() {
      this.setKpisLoading(true);
      this.loadingCells.push(this.cellIdentifier);

      httpClient
        .patch('/v3/api/plannings/kpis', {
          shop_id: this.currentShop.id,
          kpis: [{
            date: this.date,
            predicted_or_real: this.predicted ? 'predicted' : 'real',
            kpi_name: this.kpiName,
            kpi_new_value: this.editedCellValue,
          }],
        })
        .catch(() => {
          this.emitOnRoot('kpi-request-failure');
        });
    },
    stringToRoundTwo(string) {
      const stringNum = string.replace(',', '.');
      const num = parseFloat(stringNum);
      if (isNaN(num)) return '0';
      return (Math.round(Math.abs(num) * 100) / 100).toString();
    },
    safeDivision(dividend, divisor) {
      if (divisor === null || divisor === 0) return 0;
      return (dividend / divisor) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-cell__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  color: $sk-black;
}

.kpi-cell__value__number-of-employee {
  color: $sk-grey-50;
  font-size: 10px;
  position: absolute;
  left: 8px;
}

.kpi-cell__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.kpi-cell__greyed-out {
  background: $sk-grey-5;
}

.kpi-cell__edition-mode {
  border: 2px solid $sk-blue;

  .kpi-cell__unit--right {
    right: 8px;
  }
}

.kpi-cell__focused {
  .kpi-cell__wrapper {
    border: 2px solid $sk-blue;
  }

  .kpi-cell__unit--right {
    right: 8px;
  }

  .kpi-cell__performance-arrow-wrapper {
    right: 4px;
  }
}

.kpi-cell__focus-grab {
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 8px;
  height: 8px;
  background: $sk-blue;
  border: 1px solid white;
  z-index: 1;
}

.kpi-cell__value {
  font-size: $fs-text-m;
}

.kpi-cell__value__overs-hours {
  position: absolute;
  left: 15%;
  width: 100%;
  bottom: 0;
}

.kpi-cell__value__overs-hours__tooltip {
  background: $sk-black;
  color: white;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: $fs-text-s;
  z-index: 200;
  max-width: 250px;
  text-align: center;
  font-weight: normal;
}

.kpi-cell__value__overs-hours__count {
  font-size: $fs-text-xs;
  color: $sk-grey;
  position: absolute;
  bottom: 1px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.kpi-cell__value__overs-hours__count--enhanced {
  color: $sk-error;
}

.kpi-cell__unit {
  font-size: $fs-text-xs;
  margin-left: 3px;
  margin-bottom: -3px;
}

.kpi-cell__unit--right {
  position: absolute;
  right: 10px;
  font-size: $fs-text-xs;
  color: $sk-grey;
}

.kpi-cell__performance-arrow-wrapper {
  position: absolute;
  right: 6px;

  /* TODO : Find a way to remove this
   * Simplebar & border affects this when it shouldnt
   * (It should be 14px square)
   */
  height: 12px;
  width: 15px;

  ::v-deep svg {
    margin: 0 !important;
  }
}

.kpi-cell__svg {
  margin: 0;
}

.kpi-cell__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
  height: 12px;
  margin-top: 5px;
}

.kpi-cell__input {
  width: 100%;
  margin: 0 5px;
  padding: 0 5px;
  outline: none;
  border: 0;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: $fs-text-m;
}

.kpi-cell__revenue input {
  background-color: $sk-blue-5;
}

input.kpi-cell__input::-webkit-outer-spin-button,
input.kpi-cell__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
