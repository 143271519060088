<template>
  <div>
    <div class="employee-secondary-shop__section">
      <SkForm split>
        <template #title>
          <div>
            <b>
              {{ $t('employees.tabs.personal_data.membership.shop_assignment.secondary_title') }}
            </b>
          </div>
          <label class="sk-form-section__secondary-shop-description">
            {{ $t('employees.tabs.personal_data.membership.shop_assignment.secondary_subtitle') }}
          </label>
        </template>
        <template>
          <SkSelect
            v-model="accessibleNodes"
            v-tooltip="nodeSelectTooltip"
            :ajax-client="ajaxClient"
            :ajax-format-response="ajaxFormatResponse"
            :ajax-format-params="ajaxFormatParams"
            :label="nodeSelectLabel"
            :disabled-options="disabledOptions"
            :disabled="!canEditNodes"
            :select-all="!isSystemAdmin"
            :tracking-options="trackingOptions"
            :search-placeholder="$t('labels.search')"
            data-test="employee-secondary_shop"
            object-value
            ajax-url="/v3/api/cluster_nodes"
            multi
            paginated
          >
            <template #empty-option>
              {{ $t('employees.add_employee_modal.affiliations.no_search_results') }}
            </template>
            <template #selected-option="slotProps">
              {{ slotProps.value.length }}
              <!-- eslint-disable-next-line  max-len -->
              {{ $t('employees.tabs.personal_data.assignment.assignment_card.fields.employee_assignment_append') }}
            </template>
            <template #select-all-label>
              {{ $t('employees.add_employee_modal.affiliations.select_all_shops') }}
            </template>
            <template #option="slotProps">
              <div
                v-tooltip.right-start="nodeHoverTooltip(slotProps.option)"
                class="nodes-list__item"
              >
                <SkMedallion
                  background-color="#d9e6ff"
                  class="nodes-list__item-icon"
                  color="#2b66fe"
                  icon="ShopIcon"
                />
                <div
                  v-if="isPrimaryShop(slotProps.option)"
                  class="nodes-list__item-thumbtack"
                >
                  <ThumbtackV2Icon
                    width="12"
                    height="12"
                    fill=""
                    class="nodes-list__item-thumbtack__fill"
                  />
                </div>
                <span>
                  {{ slotProps.option.text }}
                </span>
              </div>
            </template>
          </SkSelect>
        </template>
      </SkForm>
    </div>
    <div>
      <SkTable
        :columns="headers"
        class="employee-nodes-table"
      >
        <NodeRow
          v-for="node in sortedActiveNodesThroughUserLicenses"
          :key="node.id"
          :node="node"
          :user="employee"
          :show-opti-cell="planningFeatureEnabled && isAutomaticPlanningEnabled"
          type="shop"
          @delete-row="handleDelete"
        />
      </SkTable>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';
import { FEATURES } from '@app-js/shared/constants/features';

import NodeRow from '../../shared/components/NodeRow';

export default {
  name: 'SecondaryShopSection',
  components: { NodeRow },
  data() {
    return {
      ajaxClient: () => httpClient,
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee', 'memberships', 'currentUserVisibleNodes']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo']),
    ...mapGetters('selectedEmployee', [
      'activeUserLicenses',
      'isSystemAdmin',
      'isCurrentUser',
      'isStrictSubordinateOfCurrentUser',
    ]),
    ...mapGetters('features', ['isFeatureEnabled', 'isBundleSystemActive']),
    planningFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id);
    },
    timeclockFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_BADGING, this.currentShop.id);
    },
    showOnScheduleOrTimeClock() {
      if (!this.isBundleSystemActive(this.currentShop.id)) return 'planning';
      if (this.planningFeatureEnabled && this.timeclockFeatureEnabled) {
        return 'planning_and_timeclock';
      } if (!this.planningFeatureEnabled && this.timeclockFeatureEnabled) {
        return 'timeclock';
      } if (this.planningFeatureEnabled && !this.timeclockFeatureEnabled) {
        return 'planning';
      }
      return '';
    },
    headers() {
      const columns = [
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.shop') },
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.license') },
        { title: this.$t(`employees.tabs.personal_data.assignment.assignment_card.header.in_${this.showOnScheduleOrTimeClock}`) },
        { title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.teams') },
      ];

      // CompetenciesInput is hidden if no shop has SP activated
      if (this.planningFeatureEnabled && this.isAutomaticPlanningEnabled) {
        columns.push(
          {
            title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.title'),
            icon: {
              icon: 'CircledQuestionMarkIcon',
              tooltip: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.tooltip'),
            },
          },
        );
      }

      columns.push({ title: null }); // Closing x column

      return columns;
    },
    isAutomaticPlanningEnabled() {
      const activeNodeIds =
        this.activeUserLicenses.map(license => Number(license.attributes.clusterNodeId));
      const activeNodes = this.currentUserVisibleNodes.filter(
        node => activeNodeIds.includes(Number(node.id)),
      );

      return !!activeNodes.find(node => node.attributes.canAccessAutomaticPlanning);
    },
    disabledOptions() {
      return this.unavailableNodeIdsForLicense.concat(this.cancelledShopNodeIds);
    },
    clustersOrganisation() {
      return this.currentOrganisation.attributes.clusters;
    },
    shopNodes() {
      return this.currentUserVisibleNodes.filter(
        clusterNode => clusterNode.attributes.shopId !== null,
      );
    },
    cancelledShopNodeIds() {
      return this.currentUserVisibleNodes.filter(
        clusterNode => clusterNode.attributes.shopCancelled,
      ).map(node => node.id);
    },
    sortedActiveNodesThroughUserLicenses() {
      const primaryShopId = this.employee.attributes.shopId;

      return sortBy(this.activeUserLicenses, license => (
        Number(license.attributes.shopId) === Number(primaryShopId) ? 0 : 1
      ));
    },
    nodeSelectLabel() {
      return this.$t('employees.tabs.personal_data.assignment.assignment_card.fields.employee_assignment');
    },
    nodeSelectTooltip() {
      if (this.isSystemAdmin) {
        return this.$t('employees.tabs.personal_data.assignment.assignment_card.disabled_tooltip');
      }

      return null;
    },
    canEditNodes() {
      if (!this.canEditEmployeeInfo || this.isSystemAdmin) return false;

      return this.isStrictSubordinateOfCurrentUser;
    },
    accessibleNodes: {
      get() {
        return this.sortedActiveNodesThroughUserLicenses.map(node => ({
          id: String(node.attributes.clusterNodeId),
          text: node.attributes.name,
          attributes: {
            shopId: node.attributes.shopId,
            licenseName: node.attributes.licenseName,
            licenseId: node.attributes.licenseId,
            inPlanning: node.attributes.inPlanning || false,
            parentId: String(node.attributes.parentId),
            depth: node.attributes.depth,
            ancestry: node.attributes.ancestry,
            ancestorIds: node.attributes.ancestorIds,
            descendantIds: node.attributes.descendantIds,
          },
        }));
      },
      set(selectedNodes) {
        this.manageNodes({
          selectedNodes: selectedNodes.map(node => ({
            id: String(node.id),
            attributes: {
              name: node.text,
              shopId: node.attributes.shopId,
              shopCancelled: node.attributes.shopCancelled,
              licenseName: node.attributes.licenseName,
              licenseId: node.attributes.licenseId,
              parentId: String(node.attributes.parentId),
              depth: node.attributes.depth,
              ancestry: node.attributes.ancestry,
              ancestorIds: node.attributes.ancestorIds,
              descendantIds: node.attributes.descendantIds,
            },
          })),
        });
      },
    },
    unavailableNodeIdsForLicense() {
      if (this.isSystemAdmin) {
        return Array.from(this.shopNodes.map(node => Number(node.id)));
      }

      const employeeShopId = Number(this.employee.attributes.shopId);

      let userLicense = this.employee.relationships.userLicenses.find(ul => (
        employeeShopId === Number(ul.attributes.shopId)
      ));

      if (!userLicense) {
        userLicense = this.employee.relationships.memberships.find(mem => (
          employeeShopId === Number(mem.attributes.shopId)
        ));
      }

      return [String(userLicense.attributes.clusterNodeId)];
    },
    trackingOptions() {
      return {
        openChoices: 'shop_select',
        submit: 'shop_select_validate',
        cancel: 'shop_select_cancel',
      };
    },
  },
  methods: {
    ...mapMutations('selectedEmployee', ['manageNodes']),
    isPrimaryShop(option) {
      return Number(option.attributes.shopId) === Number(this.employee.attributes.shopId);
    },
    ajaxFormatParams(params) {
      params.shop_level = true;
      params.configured = true;

      return { ...params };
    },
    ajaxFormatResponse(response) {
      let nodes = response.data.map(node => ({
        id: node.id,
        text: node.attributes.name,
        attributes: {
          shopId: node.attributes.shopId,
          shopCancelled: node.attributes.shopCancelled,
          parentId: String(node.attributes.parentId),
          depth: node.attributes.depth,
          ancestry: node.attributes.ancestry,
          ancestorIds: node.attributes.ancestorIds,
          descendantIds: node.attributes.descendantIds,
        },
      }));

      const primaryShopId = this.employee.attributes.shopId;

      nodes = sortBy(nodes, node => (
        Number(node.attributes.shopId) === Number(primaryShopId) ? 0 : 1
      ));

      return orderBy(nodes, 'text');
    },
    handleDelete(nodeToDelete) {
      this.manageNodes({
        selectedNodes: this.accessibleNodes.filter(node => (
          node.id !== nodeToDelete.attributes.clusterNodeId.toString()
        )),
      });
    },
    nodeHoverTooltip(node) {
      if (node.attributes.shopCancelled) {
        return this.$t('organisation_settings.shop_cancelled');
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.employee-secondary-shop__section {
  padding-top: 40px;

  .sk-form__header__title {
    padding-top: 17px;
    color: $sk-grey;
  }

  .sk-form-body-section {
    padding-top: 20px;
  }
}

.nodes-list__item {
  display: flex;
  align-items: center;
  position: relative;
}

.nodes-list__item-icon {
  margin-right: 15px;
}

.nodes-list__item-thumbtack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $sk-white;
  background-color: $sk-blue-5;
}

.nodes-list__item-thumbtack__fill {
  fill: $sk-blue;
}

.sk-form-section__secondary-shop-description {
  width: 350px;
}

.employee-nodes-table {
  margin-top: 20px;

  th {
    padding: 0 !important;
  }

  th:nth-child(3) {
    padding-right: 20px !important;
  }

  .table-column__label {
    line-height: 1.2em;
    padding-bottom: 4px;
  }
}
</style>
