<template>
  <!-- eslint-disable max-len -->
  <div class="sk-form-section">
    <h3 class="sk-form-section__title">
      {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.section_title') }}
    </h3>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.header_section.subtitle') }}
    </p>

    <div class="row shop-settings-meals__subtitle-row">
      <div class="col-6">
        <p class="sk-subtitle--medium">
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.header_section.label') }}
        </p>
      </div>
      <div class="col-2">
        <div v-if="mealRulesEnabled">
          <SkSwitch
            ref="mealCompensationActivatedSwitch"
            v-model="mealCompensationActivated"
            :disabled="!canEditMealRules"
            data-test="shops-setting__activate-meal-compensation"
          />
        </div>
        <div v-else>
          <SkPopover as-tooltip>
            <template #anchor>
              <SkSwitch
                ref="mealCompensationActivatedSwitch"
                v-model="mealCompensationActivated"
                :disabled="!canEditMealRules"
              />
            </template>
            <template #content>
              <div>
                {{
                  $t('shop_settings.tabs.rules.main_rules.meal_rules.header_section.upsell_tooltip_1')
                }}
                <PackTag type="basic" />
                {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.header_section.upsell_tooltip_2') }}
                <div class="shop-settings-meals__tooltip">
                  {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.header_section.upsell_tooltip_3') }}
                </div>
              </div>
            </template>
          </SkPopover>
        </div>
      </div>
      <PackTag
        v-if="!mealRulesEnabled"
        type="success"
        shadow
      />
    </div>

    <SkCard
      v-if="!isMealCompensationDone"
      :class="disabledCard"
    >
      <h4
        class="shop-settings-meals__title sk-card__padding"
        data-test="shops-settings-meals__title"
      >
        {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.content_section.title') }}
      </h4>
      <div class="shop-settings-meals__description-container sk-card__padding">
        <div class="shop-settings-meals__description-text">
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.content_section.description') }}
        </div>
        <MealIcon class="shop-settings-meals__description-icon" />
      </div>
      <div class="shop-settings-meals__description-footer sk-card__padding">
        <SkOroraButton
          v-track="'meal_compensation_init'"
          v-modal.meal-rules-modal
          :disabled="!mealCompensationActivated"
        >
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.content_section.action') }}
        </SkOroraButton>
      </div>
    </SkCard>
    <SkCard
      v-else-if="isMealCompensationDone && !loading"
      class="sk-form-section__meal-rule"
    >
      <div class="sk-form-section__meal-rule__header">
        <div class="sk-form-section__meal-rule__header__type-and-emoji">
          <p
            class="sk-form-section__meal-rule__text--bold"
            data-test="shops-setting-meal-rule"
          >
            {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${calculationMethod}.label`) }}
          </p>
          <span
            v-if="isCalculationMethodBenefitInKind"
            class="sk-form-section__meal-rule__header__type-and-emoji__emoji"
          >
            <i :class="mealRuleIconClass" />
          </span>
          <p class="sk-form-section__meal-rule__header__users-count">
            {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.nb_employees', { nbEmployees: mealRule.attributes.mealRuleUsersCount }) }}
          </p>
        </div>
        <SkCircleButton
          v-track="'meal_rules_edit'"
          v-modal.meal-rules-modal
          :disabled="!canEditShopRulesAndAbsences"
          class="sk-form-section__meal-rule__header__edit-button"
          icon="PencilV2Icon"
        />
      </div>
      <SkNav v-if="isCalculationMethodBenefitInKind">
        <SkNavItem
          :active="isOwedMealTabSelected"
          @click="selectTab(config.meal_rules.meal_steps.owed_meals)"
        >
          <!-- eslint-disable-next-line max-len -->
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.steps.owed_meals') }}
        </SkNavItem>
        <SkNavItem
          :active="isTakenMealTabSelected"
          @click="selectTab(config.meal_rules.meal_steps.taken_meals)"
        >
          <!-- eslint-disable-next-line max-len -->
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.steps.taken_meals') }}
        </SkNavItem>
      </SkNav>
      <div
        v-if="isOwedMealTabSelected"
        class="sk-form-section__meal-rule__body"
      >
        <div class="sk-form-section__meal-rule__row">
          <p>{{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.calcul_depends_on') }}</p>
          <p class="sk-form-section__meal-rule__text--bold">
            {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.${owedMealsCalculationMethod}.option`) }}
          </p>
        </div>
        <div v-if="isOwedMealsCalculationMethodDaysWorked || isOwedMealsCalculationMethodMonthlyAverage">
          <div class="sk-form-section__meal-rule__row">
            <p>
              {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.${owedMealsCalculationMethod}.method`) }}
            </p>
            <p class="sk-form-section__meal-rule__text--bold">
              <span v-if="isOwedMealsCalculationMethodDaysWorked">
                {{ mealRule.attributes.averageMealsOwedPerDay }}
              </span>
              <span v-if="isOwedMealsCalculationMethodMonthlyAverage">
                {{ mealRule.attributes.averageMealsOwedPerMonth }}
              </span>
            </p>
          </div>
        </div>
        <div v-if="isOwedMealsCalculationMethodHoursWorked">
          <div class="sk-form-section__meal-rule__row">
            <p>{{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.calculate_with') }}</p>
            <p class="sk-form-section__meal-rule__text--bold">
              {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.${owedMealTriggerType}.description`) }}
            </p>
          </div>
          <div
            v-for="(criteria, index) in owedMealAdditionTriggers"
            :key="criteria.id"
          >
            <p class="sk-form-section__meal-rule__text--bold sk-form-section__meal-rule__criteria-title">
              {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.criteria.title', { index: index + 1 }) }}
            </p>
            <MealRuleCriteriaRow :criteria="criteria" />
          </div>
        </div>
      </div>
      <div
        v-else-if="isTakenMealTabSelected"
        class="sk-form-section__meal-rule__body"
      >
        <div v-if="mealRule.attributes.sameRulesOwedAndTaken">
          <div class="sk-form-section__meal-rule__row sk-form-section__meal-rule__text--bold sk-form-section__meal-rule__same-rules">
            {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.same_rules_than_owed_meal') }}
          </div>
        </div>
        <div class="sk-form-section__meal-rule__row">
          <p>{{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.calcul_depends_on') }}</p>
          <p class="sk-form-section__meal-rule__text--bold">
            {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.hours_worked.option`) }}
          </p>
        </div>
        <div class="sk-form-section__meal-rule__row">
          <p>{{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.calculate_with') }}</p>
          <p class="sk-form-section__meal-rule__text--bold">
            {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.${takenMealTriggerType}.description`) }}
          </p>
        </div>
        <div
          v-for="(criteria, index) in takenMealAdditionTriggers"
          :key="criteria.id"
        >
          <p class="sk-form-section__meal-rule__text--bold sk-form-section__meal-rule__criteria-title">
            {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.criteria.title', { index: index + 1 }) }}
          </p>
          <MealRuleCriteriaRow :criteria="criteria" />
        </div>
      </div>
    </SkCard>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <MealRulesModal />
    </MountingPortal>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';

import PackTag from '@app-js/shared/components/PackTag';

import MealIcon from '../../shared/svg/MealIcon';
import MealRulesModal from './MealRulesModal';
import MealRuleCriteriaRow from './MealRuleCriteriaRow';

export default {
  name: 'MealRulesSection',
  components: {
    MealIcon,
    MealRulesModal,
    MealRuleCriteriaRow,
    PackTag,
  },
  data() {
    return {
      selectedTabName: null,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    // made this way so card datas wont be updated when changing things in edit modal
    ...mapState('mealRule', { mealRule: state => state.originalData }),
    ...mapState('mealRule', ['loading']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    ...mapGetters('features', ['isFeatureEnabled']),

    mealCompensationActivated: {
      get() {
        return this.currentShop.attributes.mealCompensationActivated &&
          this.mealRulesEnabled;
      },
      set(newValue) {
        if (newValue) {
          this.setShopAttributes({ mealCompensationActivated: true });
          this.updateShop({ shopId: this.currentShop.id });
          this.$skAnalytics.track('meal_compensation_enable');
          this.emitOnRoot(MODAL_SHOW_EVENT, event, 'meal-rules-modal');
        } else {
          this.$root.$emit('confirm', event, {
            description: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.actions.confirm'),
            onConfirm: () => {
              this.eraseMealRules();
              this.$skAnalytics.track('meal_compensation_disable');
            },
          });
        }
      },
    },
    mealRulesEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_MEAL_RULES, this.currentShop.id);
    },
    disabledCard() {
      return {
        'shop-settings-meals__card-disabled': !this.mealCompensationActivated || !this.canEditShopRulesAndAbsences,
      };
    },
    isMealCompensationDone() {
      return this.currentShop.attributes.mealCompensationDone;
    },
    isOwedMealTabSelected() {
      return !this.selectedTabName ||
        this.selectedTabName === this.config.meal_rules.meal_steps.owed_meals;
    },
    isTakenMealTabSelected() {
      return this.selectedTabName === this.config.meal_rules.meal_steps.taken_meals;
    },
    mealRuleIconClass() {
      return ['em', this.mealRule.attributes.mealEmoji];
    },
    isCalculationMethodBenefitInKind() {
      return this.mealRule.attributes.calculationMethod ===
        this.config.meal_rules.calculation_methods.benefit_in_kind;
    },
    isOwedMealsCalculationMethodHoursWorked() {
      return this.mealRule.attributes.owedMealsCalculationMethod ===
        this.config.meal_rules.owed_meals_calculation_methods.hours_worked;
    },
    isOwedMealsCalculationMethodMonthlyAverage() {
      return this.mealRule.attributes.owedMealsCalculationMethod ===
        this.config.meal_rules.owed_meals_calculation_methods.monthly_average;
    },
    isOwedMealsCalculationMethodDaysWorked() {
      return this.mealRule.attributes.owedMealsCalculationMethod ===
        this.config.meal_rules.owed_meals_calculation_methods.days_worked;
    },
    calculationMethod() {
      return this.mealRule.attributes.calculationMethod;
    },
    owedMealsCalculationMethod() {
      return this.mealRule.attributes.owedMealsCalculationMethod;
    },
    owedMealTriggerType() {
      return this.mealRule.attributes.triggerType.owed_meals;
    },
    takenMealTriggerType() {
      return this.mealRule.attributes.triggerType.taken_meals;
    },
    owedMealAdditionTriggers() {
      return sortBy(this.mealRule.relationships.mealAdditionTriggers.filter(
        mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor ===
          this.config.meal_rules.meal_steps.owed_meals,
      ), mealAdditionTrigger => mealAdditionTrigger.attributes.order,
      );
    },
    takenMealAdditionTriggers() {
      return sortBy(this.mealRule.relationships.mealAdditionTriggers.filter(
        mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor ===
          this.config.meal_rules.meal_steps.taken_meals,
      ), mealAdditionTrigger => mealAdditionTrigger.attributes.order,
      );
    },
    canEditMealRules() {
      return this.canEditShopRulesAndAbsences &&
          this.mealRulesEnabled;
    },
  },
  watch: {
    currentShop() {
      if (this.currentShop.attributes.mealCompensationDone) {
        this.fetchMealRule(this.currentShop.id);
      }
    },
  },
  created() {
    if (this.currentShop.attributes.mealCompensationDone) {
      this.fetchMealRule(this.currentShop.id);
    }
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    ...mapActions('currentShop', ['updateShop']),
    ...mapActions('mealRule', ['fetchMealRule', 'destroyMealRules']),

    selectTab(tabName) {
      this.selectedTabName = tabName;
    },
    eraseMealRules() {
      this.destroyMealRules(this.currentShop.id)
        .then(() => {
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_MEAL_SETTINGS_DISABLED);
          this.setShopAttributes({
            mealCompensationDone: false,
            mealCompensationActivated: false,
          });
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.actions.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-card__container {
  border-radius: 4px;
  padding: 0;
}

.sk-card__padding {
  padding: 0 36px 0 33px;
}

.shop-settings-meals__title {
  margin-top: 25px;
  font-size: $fs-text-l;
}

.shop-settings-meals__subtitle-row {
  align-items: center;
  margin-bottom: 20px;
  margin-top: 25px;

  p {
    font-weight: bold;
  }
}

.shop-settings-meals__description-container {
  display: flex;
  margin-top: 18px;
  margin-bottom: 20px;
  color: $sk-grey;

  .shop-settings-meals__description-text {
    width: 90%;
  }
}

.shop-settings-meals__description-footer {
  height: 70px;
  display: flex;
  align-items: center;
  border-top: 1px solid $sk-grey-10;
}

.shop-settings-meals__description-icon {
  margin-top: -45px;

  svg {
    width: auto;
    margin-top: 10px;
  }
}

.shop-settings-meals__card-disabled {
  opacity: .4;
}

.shop-setting-meals__create-button--force-primary-color {
  background-color: $sk-blue !important;
  color: white !important;
}

.sk-form-section__meal-rule {
  padding: 40px 20px 15px;

  .sk-form-section__meal-rule__text--bold {
    font-weight: $fw-semi-bold;
  }

  .sk-form-section__meal-rule__header {
    display: flex;
    justify-content: space-between;

    .sk-form-section__meal-rule__header__edit-button.sk-icon-button {
      margin-top: -10px;
    }

    .sk-form-section__meal-rule__header__type-and-emoji {
      display: flex;

      .sk-form-section__meal-rule__header__type-and-emoji__emoji {
        padding-left: 20px;
      }
    }

    .sk-form-section__meal-rule__header__users-count {
      padding-left: 40px;
    }
  }

  .sk-form-section__meal-rule__body {
    margin-top: 20px;

    .sk-form-section__meal-rule__criteria-title {
      margin-bottom: 10px;
    }

    .sk-form-section__meal-rule__same-rules {
      margin-bottom: 15px;
    }

    .sk-form-section__meal-rule__row {
      display: flex;
      justify-content: space-between;
    }
  }
}

.shop-settings-meals__tooltip {
  margin-top: 15px;
}
</style>
