<template>
  <div class="intro-step__wrapper">
    <div class="intro-step__big-title__wrapper">
      <i18n
        path="plannings.toolbar.modal.esignature_upsell.big_title.first_line"
        tag="h3"
        class="intro-step__big-title sk-header--3"
      >
        <template #highlightText>
          <br>
          <span class="intro-step__big-title--highlight">{{ highlightText }}</span>
        </template>
      </i18n>
    </div>
    <div class="intro-step___subtitle__wrapper">
      <h4
        class="intro-step__subtitle"
      >
        {{ $t('plannings.toolbar.modal.esignature_upsell.subtitle.1') }}
        <SkOroraLink
          :href="$t('plannings.toolbar.modal.esignature_upsell.subtitle.link')"
          target="_blank"
          rel="noopener noreferrer"
          size="large"
        >
          <span>{{ $t('plannings.toolbar.modal.esignature_upsell.subtitle.2') }}</span>
        </SkOroraLink> {{ $t('plannings.toolbar.modal.esignature_upsell.subtitle.3') }}
      </h4>
    </div>
    <div class="intro-step__three-blocks">
      <div class="intro-step__three-blocks__block">
        <img
          src="./assets/block-one.png"
          class="intro-step__three-blocks__image"
        >
        <div class="intro-step__three-blocks__text">
          {{ $t('plannings.toolbar.modal.esignature_upsell.three_blocks.first_block') }}
        </div>
      </div>

      <div class="intro-step__three-blocks__block">
        <img
          src="./assets/block-two.png"
          class="intro-step__three-blocks__image"
        >
        <div class="intro-step__three-blocks__text">
          {{ $t('plannings.toolbar.modal.esignature_upsell.three_blocks.second_block') }}
        </div>
      </div>

      <div class="intro-step__three-blocks__block">
        <div class="intro-step__three-blocks__image-wrapper">
          <img
            src="./assets/block-three.png"
            class="intro-step__three-blocks__image"
          >
          <span class="intro-step__image-text__timesheet">
            {{ $t('plannings.toolbar.modal.esignature_upsell.three_blocks.timesheet') }}
          </span>
          <span class="intro-step__image-text__month">
            {{ $t('plannings.toolbar.modal.esignature_upsell.three_blocks.feb') }}
          </span>
        </div>
        <div class="intro-step__three-blocks__text">
          {{ $t('plannings.toolbar.modal.esignature_upsell.three_blocks.third_block') }}
        </div>
      </div>
    </div>
    <div
      v-if="isSystemAdmin"
      class="intro-step__submits"
    >
      <SkOroraButton
        v-if="isFrenchShop"
        variant="tertiary"
        class="intro-step__submits__demo"
        data-test="es-request-demo"
        @click.prevent="requestDemo"
      >
        {{ $t('plannings.toolbar.modal.esignature_upsell.request_demo.submit') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import {
  mapGetters,
  mapState,
  mapActions,
} from 'vuex';

import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'IntroStep',
  props: {
    initialStep: {
      type: String,
      default: 'IntroStep',
    },
  },
  data() {
    return {
      requestDemoLoading: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('config', ['config']),
    highlightText() {
      return this.$t('plannings.toolbar.modal.esignature_upsell.big_title.second_line');
    },
    isFrenchShop() {
      return this.config.dom_tom.includes(this.currentShop.attributes.country.toLowerCase());
    },
  },
  methods: {
    requestDemo() {
      if (this.requestDemoLoading) return;
      this.$skAnalytics.track('esignature_upsell_request_demo');

      this.requestDemoLoading = true;

      const params = {
        shop_id: this.currentShop.id,
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('plannings.toolbar.modal.esignature_upsell.request_demo.submit'),
          upsell_type: 'esignature',
          current_page: 'Esignature',
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.esignature_upsell.request_demo.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.esignature_upsell.request_demo.error'),
            variant: 'error',
            containerId: 'employees__container',
          });
        })
        .finally(() => {
          this.requestDemoLoading = false;
          this.emitOnRoot(MODAL_HIDE_EVENT, null, 'esignature-upsell-modal');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-step__big-title__wrapper,
.intro-step__subtitle__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-step__big-title__wrapper {
  margin-bottom: 26px;
  position: relative;
  z-index: 1000;
}

.intro-step__big-title {
  font-size: $fs-heading-m;
  line-height: 36px;
  text-align: center;
}

.intro-step__big-title--highlight {
  color: $sk-blue;
}

.intro-step__subtitle__wrapper {
  margin-bottom: 36px;
}

.intro-step__subtitle {
  font-weight: 400;
  font-size: $fs-text-l;
  line-height: 21px;
  color: $sk-grey;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.intro-step__three-blocks {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  position: relative;
  z-index: 1000;
}

.intro-step__three-blocks__block {
  width: 164px;
}

.intro-step__three-blocks__image {
  margin-bottom: 19px;
  margin-top: 36px;
  height: 102px;
}

.intro-step__three-blocks__text {
  font-size: $fs-text-s;
  line-height: 16px;
  text-align: center;
  color: $sk-grey;
}

.intro-step__submits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  margin-top: 30px;

  .sk-button + .sk-button {
    margin-left: 0px !important;
  }
}

.intro-step__submits__demo {
  margin-top: 20px;
}

// Positioning of text in the 3rd image
.intro-step__three-blocks__image-wrapper {
  position: relative;
}

.intro-step__image-text__timesheet,
.intro-step__image-text__month {
  position: absolute;
  white-space: nowrap;
}

.intro-step__image-text__timesheet {
  font-size: 6px;
  font-weight: 600;
  left: 67px;
  bottom: 60px;
}

.intro-step__image-text__month {
  color: white;
  font-size: 5px;
  font-weight: 600;
  left: 34px;
  bottom: 63px;
  width: 28px;
  text-align: center;
}
</style>
