export function getPackOfferNameFromPack(packName) {
  return packName === 'timeclock' ? 'Timeclock Standalone Success' : 'success';
}

export function getPackFromPackOfferName(packOfferName, badgingEnabled) {
  if (packOfferName === 'success') {
    return badgingEnabled ? 'planning-timeclock' : 'planning';
  }
  return 'timeclock';
}

export function getUtmParams() {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_term: urlParams.get('utm_term'),
    utm_content: urlParams.get('utm_content'),
    gclid: urlParams.get('gclid'),
  };
}
