<template>
  <SkDropdown
    ref="colorPickerDropdown"
    :disabled="disabled"
    trigger="click"
    placement="bottom"
    @show-dropdown="showPicker"
    @hide-dropdown="hidePicker"
  >
    <template #anchor>
      <div class="shop-poste-color__input">
        <input
          v-model="localValue"
          type="hidden"
        >
        <div
          :style="medallionColor"
          class="shop-poste-medallion--label"
        />
        <CaretIcon
          v-if="show"
          class="sk-select__caret caret--down"
        />
        <CaretIcon
          v-else
          class="sk-select__caret caret--up"
        />
      </div>
    </template>
    <template #menu>
      <div class="shop-poste-color__menu">
        <div
          v-for="color in colors"
          :key="color"
          :style="{ background: color }"
          class="shop-poste-medallion"
          @click="selectColor(color)"
        />
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import { SHOP_POSTES_COLORS } from '@app-js/shared/constants/colors';
import { mapGetters } from 'vuex';

export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      localValue: this.value,
      ororaColors: [
        this.$skColors.skRed50,
        this.$skColors.skSangria50,
        this.$skColors.skSangria50,
        this.$skColors.skTomato50,
        this.$skColors.skGinger50,
        this.$skColors.skAmber50,
        this.$skColors.skAmber50,
        this.$skColors.skCider50,
        this.$skColors.skYellow50,
        this.$skColors.skCanary50,
        this.$skColors.skLime50,
        this.$skColors.skMint50,
        this.$skColors.skJade50,
        this.$skColors.skCrocodile50,
        this.$skColors.skCyan50,
        this.$skColors.skOcean50,
        this.$skColors.skSky50,
        this.$skColors.skLagoon50,
        this.$skColors.skBlueNew50,
        this.$skColors.skCerulean50,
        this.$skColors.skIndigo50,
        this.$skColors.skDenim50,
        this.$skColors.skIris50,
        this.$skColors.skIris50,
        this.$skColors.skPalatinate50,
        this.$skColors.skIceberg50,
        this.$skColors.skIceberg50,
        this.$skColors.skAmethyst50,
        this.$skColors.skFuchsia50,
        this.$skColors.skPurpureus50,
        this.$skColors.skPurple50,
        this.$skColors.skMauve50,
        this.$skColors.skPlum50,
        this.$skColors.skOrchid50,
        this.$skColors.skMimosa50,
        this.$skColors.skRose50,
        this.$skColors.skTulip50,
        this.$skColors.skBlush50,
        this.$skColors.skWatermelon50,
        this.$skColors.skFlamingo50,
        this.$skColors.skPink50,
        this.$skColors.skPeach50,
      ],
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    medallionColor() {
      return {
        background: this.localValue,
      };
    },
    colors() {
      if (this.isDevFlagEnabled('FEATUREDEV_ORORA_SHIFT_REDESIGN')) {
        return this.ororaColors;
      }
      return SHOP_POSTES_COLORS;
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      if (newValue !== this.localValue) {
        this.localValue = newValue;
      }
    },
  },
  methods: {
    showPicker() {
      this.show = true;
    },
    hidePicker() {
      this.show = false;
    },
    selectColor(color) {
      this.localValue = color;
      this.$refs.colorPickerDropdown.$refs.popper.doClose();
    },
  },
};
</script>

<style lang="scss">
.shop-poste-medallion {
  width: 18px;
  height: 18px;
  margin: 3px;
  cursor: pointer;
  border-radius: 50%;
}

.shop-poste-medallion--label {
  width: 18px;
  height: 18px;
  margin: 3px;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 8px;
}

.shop-poste-color__menu {
  width: 160px;
  height: 190px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-flow: row wrap;
  padding: 5px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 24px rgba(0, 0, 0, .08);
}

.shop-poste-color__input {
  display: flex;
  align-items: center;
  background: $sk-white;
  border-radius: 4px;
  height: 45px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
}
</style>
