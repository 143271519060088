<template>
  <span
    :class="textClass"
  >
    {{ $t('self_serve.step_four.manual_search_1') }}
    <SkOroraLink
      size="medium"
      :color="linkColor"
      @click.native.prevent="$emit('manual-switch-click', $event)"
    >
      <span>{{ $t('self_serve.step_four.manual_search_2') }}</span>
    </SkOroraLink>
  </span>
</template>

<script>
import { SkOroraLink } from '@skelloapp/skello-ui';

export default {
  name: 'SiretManualInputLink',
  components: {
    SkOroraLink,
  },
  props: {
    isInDropdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textClass() {
      return {
        'sk-text-medium-regular': true,
        'switch-to-manual-search__in-dropdown': this.isInDropdown,
      };
    },
    linkColor() {
      return this.isInDropdown ? 'skGrey' : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-to-manual-search__in-dropdown {
  color: $sk-grey-50;
}
</style>
