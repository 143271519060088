<template>
  <SkModal
    id="congratulation-modal"
    :display-footer="false"
    :dismissable="false"
    @hidden="handleHide"
    @show="handleShow"
  >
    <template #header>
      <div class="congratulation-modal__header">
        <img
          class="congratulation-modal__header-img"
          src="../assets/img/congratulation-modal@2x 1.png"
          width="600"
        >
      </div>
      <div class="congratulation-modal__body-medallion">
        <SkColorSLogo
          height="64"
          width="36"
        />
      </div>
    </template>
    <template #body>
      <div class="congratulation-modal__body">
        <div class="congratulation-modal__body-title">
          {{ $t('organisation_creation.congratulation_modal.title') }}
        </div>
        <div class="congratulation-modal__body-subtitle">
          {{ $t('organisation_creation.congratulation_modal.subtitle') }}
        </div>
        <SkOroraButton
          v-track="'self_serve_start_using_skello'"
          @click="handleSkelloRedirection"
        >
          {{ $t('organisation_creation.congratulation_modal.start_over') }}
        </SkOroraButton>
        <div
          v-if="showAddShopButton"
          class="congratulation-modal__body-link sk-link--medium"
          @click="handleNewShop"
        >
          {{ $t('organisation_creation.congratulation_modal.add_shop') }}
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

import { staticRootUrl } from '@config/env';
import {
  SkModal,
  SkOroraButton,
} from '@skelloapp/skello-ui';
import { SkColorSLogo } from '@app-js/shared/assets/svg/branding';

const CONFETTI_OPTIONS = {
  defaultDropRate: 8,
  particlesPerFrame: 1,
  particles: [
    {
      type: 'image',
      url: `${staticRootUrl}/images/blue_tahjxr.png`,
      size: 20,
    },
    {
      type: 'image',
      url: `${staticRootUrl}/images/red_shpawh.png`,
      size: 20,
    },
    {
      type: 'image',
      url: `${staticRootUrl}/images/pink_idvj0f.png`,
      size: 20,
    },
    {
      type: 'image',
      url: `${staticRootUrl}/images/Vector_egy69s.png`,
      size: 20,
    },
  ],
};

export default {
  name: 'CongratulationModal',
  components: { SkModal, SkOroraButton, SkColorSLogo },
  props: {
    showAddShopButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      oldBodyOverflow: '',
    };
  },
  computed: {
    ...mapState('onboarding', ['originalShopData', 'currentShop']),
  },
  methods: {
    ...mapActions('onboarding', [
      'updateShop',
      'updateOrganisation',
    ]),
    ...mapMutations(['setNavbarVisibility']),

    handleShow() {
      // remove scroll when congratulation modal is shown
      if (document.querySelector('body')) {
        this.oldBodyOverflow = document.querySelector('body').style.overflow;
        document.querySelector('body').style.overflow = 'hidden';
      }
      this.$confetti.start(CONFETTI_OPTIONS);
    },
    handleHide() {
      // revert scroll when congratulation modal is hidden
      if (document.querySelector('body')) {
        document.querySelector('body').style.overflow = this.oldBodyOverflow;
        this.oldBodyOverflow = '';
      }
      this.$confetti.stop();
    },
    handleNewShop(event) {
      // handle source on redirection ticket, add source global settings
      this.$skAnalytics.track('add_shop_btn_click', { source: 'account creation' });

      localStorage.setItem('lastShopId', this.currentShop.id);

      this.$emit('new-shop', event);
    },
    handleSkelloRedirection() {
      this.$skAnalytics.track('account_creation_go_to_skello');

      this.loading = false;

      this.$confetti.stop();

      this.setNavbarVisibility(true);

      window.location = `/v3/shops/${this.originalShopData.id}/plannings`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header {
  padding: 0 !important;
}

::v-deep .sk-modal__body-wrapper {
  z-index: 1;
}

::v-deep .sk-modal {
  height: inherit !important;
}

.congratulation-modal__body-medallion {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 64px 120px rgba(0, 0, 0, .1);
  right: 40%;
  top: 52px;
}

.congratulation-modal__header {
  z-index: 0;
  position: relative;
}

.congratulation-modal__header-img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.congratulation-modal__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  padding-top: 120px;
  padding-bottom: 55px;
}

.congratulation-modal__body-title {
  color: $sk-black;
  font-size: $fs-display-xs;
}

.congratulation-modal__body-subtitle {
  text-align: center;
  color: $sk-grey;
  font-size: $fs-text-l;
  max-width: 250px;
}

.congratulation-modal__body-link {
  font-weight: $fw-semi-bold;
  padding: 11.5px 8px;

  &:hover {
    background: $sk-blue-5;
    color: $sk-blue;
  }
}
</style>
