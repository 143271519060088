import store from '@app-js/shared/store';
import { FEATURES } from '@app-js/shared/constants/features';

/**
 * This function is used to prevent user access to a specific path based on the shop's active features
 *
 * @param {string} pathName The name of the path to prevent access to
 */
export const isRouteAllowed = pathName => {
  const shopId = store.state.currentShop.currentShop.id;
  const isFeatureEnabled = feature => store.getters['features/isFeatureEnabled'](feature, shopId);
  const areFeaturesEnabled = features => store.getters['features/areFeaturesEnabled'](features, shopId);

  switch (pathName) {
    // PLANNINGS
    case 'plannings': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'plannings_days': return areFeaturesEnabled([FEATURES.FEATURE_PLANNING_ACCESS, FEATURES.FEATURE_PLANNING_DAILY_VIEW]);
    case 'plannings_weeks_employees': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_WEEKLY_EMPLOYEE_VIEW);
    case 'plannings_weeks_postes': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_WEEKLY_POSITION_VIEW);
    case 'plannings_months': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'plannings_employee': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'plannings_employee_all': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'plannings_employee_team_schedule': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);

    // BADGINGS
    case 'badgings': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_days': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_shifts_days': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_absences_days': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_users': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_shifts_users': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_absences_users': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'badgings_history': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS) && isFeatureEnabled(FEATURES.FEATURE_BADGING);

    // ANALYTICS
    case 'analytics_dashboard': return isFeatureEnabled(FEATURES.FEATURE_ANALYTICS_MODULE);

    // DASHBOARD
    case 'home_dashboard': return isFeatureEnabled(FEATURES.FEATURE_DASHBOARD);

    // REPORTS
    case 'reports': return isFeatureEnabled(FEATURES.FEATURE_REPORT_SAAS_AND_EXCEL);

    // ADMIN TEMPLATES
    case 'text_document_templates_new': return isFeatureEnabled(FEATURES.FEATURE_DOCUMENT_TEMPLATES);
    case 'text_document_templates_edit': return isFeatureEnabled(FEATURES.FEATURE_DOCUMENT_TEMPLATES);
    case 'text_document_new': return isFeatureEnabled(FEATURES.FEATURE_DOCUMENT_TEMPLATES);

    // REQUESTS
    case 'requests': return isFeatureEnabled(FEATURES.FEATURE_AVAILABILITY_REQUEST) || isFeatureEnabled(FEATURES.FEATURE_LEAVE_REQUESTS);
    case 'leave_requests': return isFeatureEnabled(FEATURES.FEATURE_LEAVE_REQUESTS);
    case 'leave_requests_own': return isFeatureEnabled(FEATURES.FEATURE_LEAVE_REQUESTS);
    case 'leave_requests_teams': return isFeatureEnabled(FEATURES.FEATURE_LEAVE_REQUESTS);
    case 'availability_requests': return isFeatureEnabled(FEATURES.FEATURE_AVAILABILITY_REQUEST);
    case 'availability_requests_own': return isFeatureEnabled(FEATURES.FEATURE_AVAILABILITY_REQUEST);
    case 'availability_requests_teams': return isFeatureEnabled(FEATURES.FEATURE_AVAILABILITY_REQUEST);

    // ORGANISATION SETTINGS
    case 'organisation_settings_partners_tools': return store.getters['features/anyPartnerToolsFeatureEnabled'](shopId);
    case 'organisation_settings_pos_software': return isFeatureEnabled(FEATURES.FEATURE_PARTNER_TOOLS_POS_SOFTWARE);
    case 'organisation_settings_advance_payment_partners': return isFeatureEnabled(FEATURES.FEATURE_PARTNER_TOOLS_ADVANCE_SOFTWARE);

    // SHOP SETTINGS
    case 'shop_settings_counter_rules': return store.getters['features/anyCounterEnabled'](shopId);
    case 'shop_settings_alerts': return isFeatureEnabled(FEATURES.FEATURE_COLLECTIVE_AGREEMENT_ALERT);
    case 'shop_settings_report_rules': return isFeatureEnabled(FEATURES.FEATURE_REPORT_SAAS_AND_EXCEL);
    case 'shop_settings_postes': return isFeatureEnabled(FEATURES.FEATURE_CREATE_POSITION);
    case 'shop_settings_documents_management': return isFeatureEnabled(FEATURES.FEATURE_DOCUMENT_TEMPLATES);
    case 'shop_settings_automatic_planning': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'automatic_planning_rules': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'automatic_planning_competencies': return isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
    case 'shop_settings_punch_clock': return isFeatureEnabled(FEATURES.FEATURE_BADGING);
    case 'shop_settings_details_annualization': return isFeatureEnabled(FEATURES.FEATURE_ANNUALIZATION_COUNTER);

    // TIMECLOCK ONBOARDING
    case 'timeclock_onboarding': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);

    // DEFAULT
    // We're purposefully allowing access to all other paths as it can only prevent direct access through URL
    // and can be triggered with a refresh
    // Normal navigation won't be affected by this file, so if a path must be under restricted access,
    // it must be handled both here and in the component
    default: return true;
  }
};
