<template>
  <!--
    Clones
    app/javascript/src/v3/app/organisation_settings/pages/OrganisationShops/ShopForm/ShopStep.vue
    If you need to make a change make it to both
  -->
  <div>
    <div class="col-md-4 offset-md-4 mx-auto shop-info__page">
      <div class="shop-info__header">
        <div class="shop-info__header__title">
          <h1 class="sk-header--1">
            {{ shopFormTitle }}
          </h1>
        </div>
        <p class="sk-subtitle--large">
          {{ $t('organisation_creation.shop_form.subtitle') }}
        </p>
      </div>
      <div class="shop-info__form">
        <div class="row">
          <div class="col shop-info__form_title">
            <h3 class="sk-header--3">
              {{ $t('organisation_creation.shop_form.form_title_1') }}
            </h3>
          </div>
          <div class="col shop-info__form_helper">
            <p class="sk-subtitle--medium">
              {{ $t('organisation_creation.common.form_helper_beggining') }}
              <span class="shop-info__form_helper-blue">*</span>
              {{ $t('organisation_creation.common.form_helper_end') }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <SkInput
              v-model="name"
              :label="$t('organisation_creation.shop_form.shop_name')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.name"
              @keyup="validateAttribute($event, 'name')"
              @keyup.enter.native="validateForm"
            >
              <template #icon>
                <CircledQuestionMarkIcon
                  v-tooltip="$t('organisation_creation.shop_form.tooltip.name')"
                  class="shop-info__helper"
                />
              </template>
            </SkInput>
          </div>
          <div class="col-6">
            <SkInput
              v-model="denominationSociale"
              :label="$t('organisation_creation.shop_form.shop_social_denomination')"
              :error-message="denominationSocialeErrorMessage"
              :errored="errors.denominationSociale"
              @keyup="validateAttribute($event, 'denominationSociale')"
              @keyup.enter.native="validateForm"
            >
              <template #icon>
                <CircledQuestionMarkIcon
                  v-tooltip="$t('organisation_creation.shop_form.tooltip.social_denomination')"
                />
              </template>
            </SkInput>
          </div>
        </div>
        <div class="row shop-info__form_row">
          <div class="col">
            <SkInput
              v-model="address"
              :label="$t('organisation_creation.shop_form.address')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.address"
              @keyup="validateAttribute($event, 'address')"
            />
          </div>
        </div>
        <div class="row shop-info__form_row">
          <div class="col">
            <SkInput
              v-model="zipcode"
              :label="$t('organisation_creation.shop_form.zip_code')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.zipcode"
              @keyup="validateAttribute($event, 'zipcode')"
            />
          </div>
          <div class="col">
            <SkInput
              v-model="city"
              :label="$t('organisation_creation.shop_form.city')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.city"
              @keyup="validateAttribute($event, 'city')"
            />
          </div>
        </div>
        <div class="row shop-info__form_row">
          <div class="col">
            <SkSelectV2
              v-model="country"
              :options="countries"
              :label="$t('organisation_creation.shop_form.country')"
              :search-placeholder="$t('labels.search')"
              :no-results-label="$t('search_bar.no_result')"
            />
          </div>
          <div
            v-if="isRegionDisplayed"
            class="col"
          >
            <SkSelectV2
              v-model="countryRegion"
              :label="$t('shop_settings.tabs.shop.address.labels.region')"
              :options="regionsFor(country)"
              :search-placeholder="$t('labels.search')"
              :no-results-label="$t('search_bar.no_result')"
            />
          </div>
        </div>
        <div class="row shop-info__form_row">
          <div class="col">
            <SkInput
              v-model="siret"
              :label="$t('organisation_creation.shop_form.siret')"
              :error-message="$t('organisation_creation.error.mandatory')"
              :errored="errors.siret"
              @keyup="validateAttribute($event, 'siret')"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col shop-info__form_title">
          <h3 class="sk-header--3">
            {{ $t('organisation_creation.shop_form.form_title_2') }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <SkSelectV2
            v-model="convention"
            :label="$t('organisation_creation.shop_form.convention')"
            :options="conventions"
            :no-results-label="$t('search_bar.no_result')"
          >
            <template #selected-option="{ value }">
              <span v-tooltip="showConventionTooltip(value)">
                {{ truncateConvention(value, 30) }}
              </span>
            </template>
            <template #option="{ option }">
              <span v-tooltip="showConventionTooltip(option)">
                {{ truncateConvention(option, 60) }}
              </span>
            </template>
          </SkSelectV2>
        </div>
        <div class="col-5">
          <SkInputGroup
            class="shop-info__timepicker-group"
            separator
          >
            <template #prepend>
              <SkTimePicker
                ref="openingTime"
                v-model="openingTime"
                readonly
                :label="$t('organisation_creation.shop_form.working_hours')"
              />
            </template>
            <template>
              <div class="shop-info__timepicker-divider">
                -
              </div>
            </template>
            <template #append>
              <!-- label=" " is a hack of SkInputGroup to share label for two inputs
              the shared label is :label="$t('organisation_creation.shop_form.working_hours')" -->
              <SkTimePicker
                ref="closingTime"
                v-model="closingTime"
                readonly
                label=" "
              />
            </template>
          </SkInputGroup>
        </div>
        <div class="col">
          <CircledQuestionMarkIcon
            v-tooltip="$t('organisation_creation.shop_form.tooltip.opening_hours')"
            class="shop-info__helper shop-info__helper--full_height"
          />
        </div>
      </div>
      <div class="row shop-info__last-row">
        <div class="col">
          <div class="shop-info__last-row__back-btn-wrapper">
            <SkOroraButton
              v-if="!isNewShopStep"
              class="shop-info__button shop-info__blank_button shop-info__form_button"
              variant="tertiary"
              @click="goBack"
            >
              {{ $t('organisation_creation.shop_form.back') }}
            </SkOroraButton>
          </div>
        </div>
        <div class="col">
          <div class="shop-info__last-row__submit-wrapper">
            <SkOroraButton
              :disabled="isDisabled"
              :loading="loading"
              class="shop-info__button shop-info__form_button"
              @click="validateForm"
            >
              {{ $t('organisation_creation.shop_form.submit') }}
            </SkOroraButton>
          </div>
        </div>
      </div>
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <CongratulationModal
          ref="congratulationModal"
          @new-shop="handleNewShopCreation"
        />
      </MountingPortal>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { REGION_KEYS } from '@app-js/shared/constants/region-keys.js';
import {
  SkOroraButton,
  SkInput,
  SkSelectV2,
  SkTimePicker,
  SkInputGroup,
  CircledQuestionMarkIcon,
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import { truncateString } from '@skello-utils/formatting/strings';
import { arrayToSelectOptions } from '@skello-utils/form';
import {
  countryKeys,
  languageToCountryCode,
} from '@skello-utils/country-keys.js';

import CongratulationModal from '../shared/components/CongratulationModal';

export default {
  name: 'ShopForm',
  components: {
    SkOroraButton,
    SkInput,
    SkSelectV2,
    CircledQuestionMarkIcon,
    SkTimePicker,
    SkInputGroup,
    CongratulationModal,
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToShop) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashShop();
          next();
        },
        onCancel: () => {
          this.updateOrganisationStep('shop');
        },
      });
    } else {
      next();
    }
  },
  props: {
    parentClusterNodeId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      errors: {
        name: false,
        denominationSociale: false,
        address: false,
        zipcode: false,
        city: false,
        siret: false,
        openingTime: false,
        closingTime: false,
      },
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('onboarding', ['currentShop', 'currentOrganisation', 'organisationCreation', 'originalShopData', 'currentUser']),
    ...mapState('onboarding', { conventionName: state => state.currentShop.relationships.convention.attributes.name }),
    ...mapGetters('onboarding', ['unsavedChangesToShop']),
    isDisabled() {
      return !(
        this.name && this.denominationSociale &&
        this.denominationSociale.trim().length >= 3 &&
        this.address && this.zipcode &&
        this.city && this.convention && this.siret &&
        !this.errorsOnTimepicker
      );
    },
    name: {
      get() {
        return this.currentShop.attributes.name;
      },
      set(newValue) {
        this.setShopAttributes({ name: newValue });
      },
    },
    denominationSociale: {
      get() {
        return this.currentShop.attributes.denominationSociale;
      },
      set(newValue) {
        this.setShopAttributes({ denominationSociale: newValue });
      },
    },
    siret: {
      get() {
        return this.currentShop.attributes.siret;
      },
      set(newValue) {
        this.setShopAttributes({ siret: newValue });
      },
    },
    country: {
      get() {
        if (this.currentShop.attributes.country) {
          return this.currentShop.attributes.country;
        }
        return this.defaultUserCountryCode;
      },
      set(newValue) {
        this.setShopAttributes({ country: newValue, countryRegion: null });
      },
    },
    countryRegion: {
      get() {
        return this.currentShop.attributes.countryRegion;
      },
      set(value) {
        this.setShopAttributes({ countryRegion: value });
      },
    },
    address: {
      get() {
        return this.currentShop.attributes.address;
      },
      set(newValue) {
        this.setShopAttributes({ address: newValue });
      },
    },
    zipcode: {
      get() {
        return this.currentShop.attributes.zipcode;
      },
      set(newValue) {
        this.setShopAttributes({ zipcode: newValue });
      },
    },
    city: {
      get() {
        return this.currentShop.attributes.city;
      },
      set(newValue) {
        this.setShopAttributes({ city: newValue });
      },
    },
    openingTime: {
      get() {
        return skDate(this.currentShop.attributes.openingTime, 'HH:mm').format();
      },

      set(newValue) {
        this.setShopAttributes({ openingTime: skDate(newValue).format('HH:mm') });
      },
    },

    closingTime: {
      get() {
        return skDate(this.currentShop.attributes.closingTime, 'HH:mm').format();
      },

      set(newValue) {
        this.setShopAttributes({ closingTime: skDate(newValue).format('HH:mm') });
      },
    },
    convention: {
      get() {
        return this.conventionName;
      },
      set(newValue) {
        this.setConvention(newValue);
      },
    },
    shopFormTitle() {
      return this.isNewShopStep ?
        this.$t('organisation_creation.new_shop_form.title') :
        this.$t('organisation_creation.shop_form.title');
    },
    isNewShopStep() {
      return this.organisationCreation.shopCreationCurrentStep === 'new_shop_form';
    },
    conventions() {
      return this.countryConventions(
        this.country,
        this.convention,
      ).map(convention => ({
        id: convention.name,
        text: convention.legible_name,
      }));
    },
    defaultUserCountryCode() {
      // while currentUser is being fetched, it triggers errors
      // empty string is equivalent to false in js !!'' -> false
      if (!this.currentUser.attributes.lang) return '';
      return languageToCountryCode(this.currentUser.attributes.lang);
    },
    countries() {
      const countryList = countryKeys(this.defaultUserCountryCode);
      return arrayToSelectOptions(countryList, value => this.$t(`countries.${value}`));
    },
    errorsOnTimepicker() {
      // When loading the view with navigation keys there is
      // no refs we don't want to throw an error then
      if (!this.$refs.openingTime || !this.$refs.closingTime) { return false; }

      return this.$refs.openingTime.errored && this.$refs.closingTime.errored;
    },
    isBillingOnShop() {
      if (this.currentOrganisation.attributes.doNotPay) return false;

      return this.currentOrganisation.attributes.paymentEntity === 'billing_on_shop';
    },
    denominationSocialeErrorMessage() {
      if (
        this.currentShop.attributes.denominationSociale &&
        this.currentShop.attributes.denominationSociale.trim().length > 0 &&
        this.currentShop.attributes.denominationSociale.trim().length < 3
      ) {
        return this.$t('organisation_creation.error.mandatory_length', { min: 3 });
      }
      return this.$t('organisation_creation.error.mandatory');
    },
    isRegionDisplayed() {
      return ['ES', 'DE'].includes(this.country);
    },
  },
  mounted() {
    this.updateOrganisationStep('shop');
  },
  methods: {
    ...mapMutations('onboarding', [
      'updateOrganisationStep',
      'updateShopStep',
      'setShopAttributes',
      'setConvention',
      'resetCurrentShop',
      'setupAnalytics',
      'squashShop',
      'setDefaultShopAttributes',
    ]),
    ...mapActions('config', ['fetchConfig']),
    ...mapActions('onboarding', ['createShop', 'updateShop', 'getLastCreatedShop', 'updateOrganisation', 'updateProspect']),

    countryConventions(userCountryCode, currentConventionName) {
      let countryCode = userCountryCode;
      // For DOM-TOMS -> return French CC
      if (this.config.dom_tom.includes(countryCode.toLowerCase())) {
        countryCode = 'FR';
      }

      const countryConventions = this.config.conventions_collection.filter(
        convention => convention.countries.includes(countryCode),
      );

      // If no conventions exist for country -> return all
      if (countryConventions.length === 0) return this.config.conventions_collection;

      // If current convention isn't in conventions array -> add it
      if (currentConventionName &&
          !countryConventions.some(convention => convention.name === currentConventionName)
      ) {
        countryConventions.unshift(this.config.conventions_collection.find(convention => (
          convention.name === currentConventionName
        )));
      }

      return countryConventions;
    },
    validateForm(event) {
      if (this.isDisabled) return;

      // handle source on redirection ticket, add source global settings
      this.$skAnalytics.track('account_creation_shop_submit', { source: 'account creation' });
      if (this.loading) return;

      this.loading = true;

      const params = {
        cluster_node_id: this.currentOrganisation.attributes.rootNodeId,
        shop_info: {
          name: this.name,
          denomination_sociale: this.denominationSociale,
          siret: this.siret,
          country: this.country,
          country_region: this.countryRegion,
          address: this.address,
          zipcode: this.zipcode,
          city: this.city,
          parent_cluster_node_id: this.parentClusterNodeId,
        },
        shop: {
          opening_time: this.openingTime,
          closing_time: this.closingTime,
        },
        convention: { name: this.conventionName },
      };

      if (this.currentShop.id) {
        params.id = this.currentShop.id;
        this.updateShop(params)
          .then(() => {
            this.onSavedShop(event);
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          })
          .finally(() => { this.loading = false; });
      } else {
        this.createShop(params)
          .then(() => {
            this.onSavedShop(event);
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          })
          .finally(() => { this.loading = false; });
      }
    },
    goBack() {
      const { paymentEntity } = this.currentOrganisation.attributes;
      // payment_entity values : ['no_entity', 'billing_on_shop', 'billing_on_organsation', 'billing_on_another']
      if (
        paymentEntity === this.config.payment_entities[0] ||
        paymentEntity === this.config.payment_entities[1]
      ) {
        this.updateOrganisationStep('organisation');
        this.$router.push({ name: 'organisation_creation' });
      } else {
        this.$router.push({ name: 'organisation_billing_form' });
        this.updateOrganisationStep('organisation_billing_form');
      }
    },
    truncateConvention(string, length) {
      return truncateString(this.getConventionText(string), length);
    },
    handleNewShopCreation(event) {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, this.$refs.congratulationModal.$el.id);

      this.resetCurrentShop();

      window.location = '/v3/settings/shops/new#onboarding';
    },
    updateShopToConfigured() {
      const params = {
        id: this.currentShop.id,
        shop: {
          configured: true,
        },
      };

      return this.updateShop(params);
    },
    showConventionTooltip(string) {
      const conventionText = this.getConventionText(string);

      return conventionText.length > 50 ? conventionText : null;
    },

    getConventionText(string) {
      return string.text ? string.text : this.conventions.find(
        convention => convention.id === string,
      ).text;
    },
    validateAttribute(event, attribute) {
      // Do not trigger on tab key
      if (event.which === 9) { return; }
      this.errors[attribute] = !this.currentShop.attributes[attribute];
      if (attribute === 'denominationSociale') {
        this.errors[attribute] = !(
          this.currentShop.attributes[attribute] &&
          this.currentShop.attributes[attribute].trim().length >= 3
        );
      } else {
        this.errors[attribute] = !this.currentShop.attributes[attribute];
      }
    },
    onSavedShop(event) {
      if (!this.isNewShopStep) {
        this.fetchConfig()
          .then(response => {
            // we update identify with config because affiliated shop is inside of config object
            const config = response.data.data;
            this.$skAnalytics.setAnalyticsVariables({ config });
          });
      }

      // update stdProperties
      this.$skAnalytics.setAnalyticsVariables({
        currentShop: this.currentShop,
        currentOrganisation: this.currentOrganisation,
      });
      this.$skAnalytics.sendAnalytics();

      if (this.isBillingOnShop) {
        this.$router.push({ name: 'shop_billing_form' });

        const step = this.organisationCreation.shopCreationCurrentStep === 'new_shop_form' ?
          'new_shop_billing_form' :
          'shop_billing_form';

        this.updateShopStep(step);
      } else {
        this.updateShopToConfigured();
        this.emitOnRoot(MODAL_SHOW_EVENT, event, this.$refs.congratulationModal.$el.id);

        this.updateProspect({
          prospect: {
            step: 'registration_fully_completed',
          },
        })
          .then(() => {
            this.updateOrganisation({
              id: this.currentOrganisation.id,
              shop_id: this.originalShopData.id,
              organisation: {
                status: 'active',
              },
            })
              .catch(() => {
                this.$skToast({
                  message: this.$t('organisation_creation.error.generic'),
                  variant: 'error',
                });
              });
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          });
      }
    },
    regionsFor(country) {
      return arrayToSelectOptions(REGION_KEYS[country], value => this.$t(`regions.${country}.${value}`));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-select__dropdown-menu {
  width: 430px !important;
}

.shop-info__form_helper-blue {
  color: $sk-blue;
}

.shop-info__form_title {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 24px;
}

.shop-info__form_helper {
  margin-top: 35px;
  margin-bottom: 20px;
}

.shop-info__header__title {
  margin-bottom: 9px;
}

.shop-info__page {
  margin-top: 50px;
  max-width: 572px;
}

.shop-info__button {
  margin-top: 40px;
}

.shop-info__helper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: relative;
  bottom: 4px;
  top: 0;
}

.shop-info__header {
  padding-left: 10px;
  padding-right: 10px;
}

.shop-info__blank_button {
  outline: none !important;
  color: $sk-grey !important;
  justify-content: start !important;

  &:hover {
    opacity: .8;

    &.sk-button--primary:not([disabled]).sk-button--blank {
      background: none;
    }
  }
}

.shop-info__form {
  padding-bottom: 30px;
  border-bottom: 1px solid $sk-grey-10;
}

.shop-info__form_row {
  margin-top: 10px;
}

.shop-info__form_button {
  align-items: center;
  justify-content: center;
}

.shop-info__last-row {
  margin-bottom: 50px;
}

.shop-info__last-row__back-btn-wrapper {
  display: flex;
  align-items: flex-start;
  margin-left: -8px;
}

.shop-info__last-row__submit-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: -8px;
}

.shop-info__sk-select {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shop-info__helper--full_height {
  height: 100%;
}

.shop-info__timepicker-group {
  ::v-deep .sk-input-group__separator {
    padding: 0;
  }

  ::v-deep .sk-input--filled-in .sk-input__label {
    top: 12px !important;
  }
}

.shop-info__timepicker-divider {
  padding-top: 17px;
  padding-left: 20px;
}
</style>
