<template>
  <div class="user-form__submit">
    <div
      v-if="submitFormError"
      class="user-form__submit-form-error"
    >
      <CircledIIcon
        class="user-form__submit-form-error__icon"
        fill=""
        width="24px"
        height="24px"
      />
      <div class="user-form__submit-form-error__text">
        <p>
          {{ $t(`self_serve.step_one.errors.${submitFormError}`) }}
          <a
            v-if="submitFormError === 'mail_already_taken'"
            href=""
            @click.prevent="trackLoginLinkEmailAlreadyExists"
          >
            {{ $t('self_serve.step_one.click_here') }}
          </a>
        </p>
      </div>
    </div>
    <form class="user-form__form">
      <GlobalEvents @keyup.enter="submitForm" />
      <SkOroraInput
        v-model="firstName"
        :label="$t('self_serve.step_one.first_name')"
      />
      <SkOroraInput
        v-model="lastName"
        :label="$t('self_serve.step_one.last_name')"
      />
      <SkOroraInput
        v-model="email"
        :errored="emailError"
        :hint-message="emailErrorMessage"
        :debounce="validateEmail"
        :debounce-ms="100"
        :label="$t('self_serve.step_one.mail')"
        type="email"
      />
      <SkOroraPhoneInput
        ref="phoneInput"
        v-model="phone"
        :default-country="defaultCountry"
        :error-message="$t('self_serve.step_one.errors.phone')"
        :label="$t('self_serve.step_one.phone')"
        :no-search-result-label="$t('self_serve.step_one.errors.no_result')"
        @input="handlePhoneInput"
      />
      <div class="user-form__password-section">
        <SkOroraPassword
          v-model="password"
          :label="$t('self_serve.step_one.password')"
          :debounce="validateConfirmationPasswordFromFirstInput"
        />
        <div class="user-form__form__password-container">
          <span
            v-for="element in passwordValidationElements"
            :key="element"
          >
            <p :class="passwordCheckClasses(element)">
              <component
                :is="passwordCheckIcon(element)"
                :fill="passwordCheckColor(element)"
                class="user-form__form__password-svg"
                width="18px"
              />
              {{ $t(`self_serve.step_one.password_helper.${element}`) }}
            </p>
          </span>
        </div>
      </div>
      <SkOroraPassword
        v-model="confirmationPassword"
        :label="$t('self_serve.step_one.confirm_password')"
        :errored="confirmationPasswordError"
        :hint-message="confirmationPasswordErrorMessage"
        :debounce="validateConfirmationPassword"
      />
    </form>
    <section class="user-form__button-section">
      <SkOroraButton
        id="self-serve-step-one-submit"
        fill-parent
        :disabled="isSubmitDisabled"
        :loading="loading"
        @click="submitForm"
      >
        {{ $t('self_serve.step_one.submit') }}
      </SkOroraButton>
      <div class="user-form__legal-link">
        <span>
          {{ $t('self_serve.step_one.legal_link_start') }}
          <SkOroraLink
            :href="termsOfService"
            target="_blank"
            size="small"
            @click.native.stop="trackTermsOfServiceLink"
          >
            {{ $t('self_serve.step_one.legal_link') }}
          </SkOroraLink>
        </span>
      </div>
    </section>
    <i18n
      path="self_serve.step_one.signin.text"
      tag="span"
    >
      <template #linkText>
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <SkOroraLink
          @click.native.stop="trackLoginLink"
        >
          {{ $t('self_serve.step_one.signin.link') }}
        </SkOroraLink>
      </template>
    </i18n>
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import {
  CheckMarkIcon,
  CircledIIcon,
  ClosedEyeIcon,
  ClosingXIcon,
  OpenEyeIcon,
  SkOroraButton,
  SkOroraLink,
  SkOroraInput,
  SkOroraPassword,
  SkOroraPhoneInput,
} from '@skelloapp/skello-ui';

import {
  isValidEmail,
  isValidPasswordLength,
  isValidPasswordUpper,
  isValidPasswordLower,
  isValidPasswordDigit,
} from '@skello-utils/validators';
import GlobalEvents from 'vue-global-events';
import { mapGetters } from 'vuex';
import {
  getPackOfferNameFromPack,
  getUtmParams,
} from '@app-js/shared/utils/self_serve/utils';

export default {
  name: 'StepOne',
  components: {
    CheckMarkIcon,
    CircledIIcon,
    ClosedEyeIcon,
    ClosingXIcon,
    OpenEyeIcon,
    SkOroraButton,
    SkOroraLink,
    SkOroraInput,
    SkOroraPassword,
    SkOroraPhoneInput,
    GlobalEvents,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      countryCode: null,
      password: null,
      confirmationPassword: null,
      emailError: null,
      confirmationPasswordError: null,
      submitFormError: null,
      loading: false,
      passwordValidationElements: ['length', 'upper', 'lower', 'digit'],
      defaultCountry: this.$i18n.locale === 'en' ? 'GB' : this.$i18n.locale.toUpperCase(),
    };
  },
  computed: {
    ...mapGetters('onboarding', ['queryRawBundle', 'salesforceBundle']),
    isSubmitDisabled() {
      return !(
        this.firstName &&
        this.lastName &&
        this.email && isValidEmail(this.email) &&
        this.phone && !this.$refs.phoneInput.errored &&
        this.confirmationPassword &&
        this.password === this.confirmationPassword &&
        this.isPasswordValid
      );
    },
    isPasswordValid() {
      return isValidPasswordLength(this.password) &&
        isValidPasswordUpper(this.password) &&
        isValidPasswordLower(this.password) &&
        isValidPasswordDigit(this.password);
    },
    confirmationPasswordErrorMessage() {
      return this.confirmationPasswordError ? this.$t('self_serve.step_one.errors.confirm_password') : null;
    },
    emailErrorMessage() {
      if (!this.emailError) return null;

      const errorMessages = {
        mail_already_taken: 'self_serve.step_one.errors.mail_already_taken_label',
        account_not_validated: 'self_serve.step_one.errors.account_not_validated_label',
      };

      const errorMessageKey = errorMessages[this.submitFormError] || 'self_serve.step_one.errors.mail';
      return this.$t(errorMessageKey);
    },
    termsOfService() {
      return this.$t('self_serve.terms_of_service_link', { lang: this.$i18n.locale });
    },
  },
  mounted() {
    // Google Analytics script for Marketing team
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'self_serve_step_viewed',
      page_type: 'selfserve',
      page_category: 'selfserve',
      selfserve_version: localStorage.getItem('self-serve-version'),
      step_index: 'stepOne',
      step_name: 'stepOne',
      page_path: window.location.pathname,
      referrer: document.referrer,
    });
  },
  methods: {
    submitForm() {
      if (this.isSubmitDisabled) return;
      this.loading = true;
      this.submitFormError = null;

      const user = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        password: this.password,
        lang: this.$i18n.locale,
        country_code: this.countryCode,
      };

      const requestParams = { user };
      requestParams.version = 'v5';
      const utmParams = getUtmParams();
      if (utmParams) {
        requestParams.utm_params = utmParams;
      }

      const packName = this.queryRawBundle;
      if (packName) {
        requestParams.organisation = {
          pack_offer_name: getPackOfferNameFromPack(packName),
          bundle: this.salesforceBundle(packName),
        };
      }

      this.sendSegmentTrackers();

      httpClient
        .post('/v3/api/self_serve/create', requestParams)
        .then(response => {
          this.$skAnalytics.track('ss_account_creation_step1_submit');

          const prospect = response.data.data;
          localStorage.setItem('step_one', JSON.stringify({ country_code: this.countryCode }));

          this.$router.replace({
            name: 'step_two',
            query: {
              token: prospect.attributes.token,
              lang: this.$i18n.locale,
            },
            params: {
              email: prospect.attributes.email,
              id: prospect.id,
            },
          });
        })
        .catch(({ response }) => {
          if ((response.data?.message === 'account_not_validated') ||
            (response.data?.message === 'mail_already_taken')) {
            this.submitFormError = response.data.message;
            this.emailError = true;
          } else {
            this.$skToast({
              message: this.$t('self_serve.errors.generic'),
              variant: 'error',
            });
          }
        })
        .finally(() => { this.loading = false; });
    },
    handlePhoneInput(phoneObject) {
      this.country = phoneObject.country;
      this.phone = phoneObject.phone;
    },
    validateEmail() {
      this.emailError = !!this.email && !isValidEmail(this.email);
    },
    validateConfirmationPassword() {
      this.confirmationPasswordError = this.confirmationPassword &&
        this.password !== this.confirmationPassword;
    },
    validateConfirmationPasswordFromFirstInput() {
      if (!this.confirmationPassword || this.confirmationPassword.length === 0) return;
      this.validateConfirmationPassword();
    },
    checkPasswordElementValidity(element) {
      switch (element) {
        case 'length':
          return isValidPasswordLength(this.password);
        case 'upper':
          return isValidPasswordUpper(this.password);
        case 'lower':
          return isValidPasswordLower(this.password);
        case 'digit':
          return isValidPasswordDigit(this.password);
        default:
          console.error(`${element} is not checked`);
          return false;
      }
    },
    passwordCheckIcon(element) {
      if (!this.password) return CheckMarkIcon;
      return this.checkPasswordElementValidity(element) ? CheckMarkIcon : ClosingXIcon;
    },
    passwordCheckClasses(element) {
      return {
        password: true,
        'password--inactive': !this.password,
        'password-check--valid': this.password && this.checkPasswordElementValidity(element),
        'password-check--invalid': this.password && !this.checkPasswordElementValidity(element),
      };
    },
    passwordCheckColor(element) {
      if (!this.password) return '#727272';
      return this.checkPasswordElementValidity(element) ? '#188377' : '#d03e50';
    },
    sendSegmentTrackers() {
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        country_code: this.countryCode,
        customer_id: Date.now() + Math.random(),
      };

      this.$skAnalytics.track('self_serve_submit_account_creation', params);
    },
    trackLoginLink(event) {
      event.preventDefault();
      this.$skAnalytics.track('ss_account_creation_step1_login_link');
      window.location.href = '/users/sign_in';
    },
    trackLoginLinkEmailAlreadyExists() {
      this.$skAnalytics.track('ss_account_creation_step1_login_link_already_exists');
      window.location.href = '/users/sign_in';
    },
    trackTermsOfServiceLink() {
      this.$skAnalytics.track('ss_account_creation_step1_terms_privacy_policy_link');
      window.open(this.termsOfService, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-form__submit {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.user-form__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user-form__button-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-form__password-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-form__form__password-container {
  text-align: left;
  white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, max(150px, 50%));

  .password {
    font-size: $fs-text-s;
    font-weight: 400;
    align-items: baseline;
    margin: 0;

    .user-form__form__password-svg {
      margin-right: 5px;
    }
  }

  .password--inactive {
    color: $sk-grey;
  }

  .password-check--valid {
    color: $sk-success;
  }

  .password-check--invalid {
    color: $sk-error;
  }
}

/* The design system error color is red by default
 When email error is 'mail_already_taken' or 'account_not_validated'
 we want the erro to be blue */
::v-deep .user-form__input-email-errors .sk-input__error-wrapper .sk-input__error-label,
::v-deep .user-form__input-email-errors .sk-input .sk-input__label {
  color: $sk-blue;
}

.user-form__input-container {
  display: flex;
  justify-content: space-between;
  max-width: 750px;
  margin-top: 26px;
  gap: 8px;

  @media (max-width: $md-width) {
    flex-direction: column;
    gap: 26px;
  }
}

.user-form__button {
  padding: 16px 32px 16px 0;
  background-color: $sk-white;

  @media (min-width: $sm-width) {
    bottom: 0;
    z-index: 1;
  }

  @media (max-width: $sm-width) {
    position: fixed;
    display: flex;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}

.user-form__form-hint {
  display: flex;
  font-weight: $fw-regular;
  color: $sk-grey;
  font-size: $fs-text-s;
  font-style: italic;
  justify-content: flex-end;
  max-width: 750px;
  margin-bottom: 15px;

  @media (max-width: $sm-width) {
    justify-content: flex-start;
  }
}

.user-form__submit-form-error {
  min-height: 50px;
  background-color: $sk-blue-5;
  border-radius: 4px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: $fs-text-l;

  @media (min-width: 355px) and (max-width: 590px) {
    font-size: $fs-text-m;
    line-height: 15px;
  }

  @media (max-width: 355px) {
    font-size: $fs-text-s;
    line-height: 15px;
  }

  .user-form__submit-form-error__icon {
    fill: $sk-blue;
    margin-right: 16px;
  }

  .user-form__submit-form-error__text {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px 0;

    p {
      margin-bottom: 0;
    }
  }
}

.user-form__legal-link {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: $fs-text-s;
  align-items: center;
  color: $sk-grey;

  @media (min-width: $md-width) {
    text-align: center;
  }

  @media (max-width: $sm-width) {
    z-index: 0;
    justify-content: center;
    font-size: $fs-text-xs;
  }

  &__text {
    text-align: center;
  }

  a {
    margin-left: 3px;

    @media (max-width: $sm-width) {
      text-align: center;
      font-size: $fs-text-xs;
    }
  }
}
</style>
