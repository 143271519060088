import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { associationMatcherItem } from '@skello-utils/association_matchers';

import { TokenWebModel } from '@skelloapp/skello-auth-client';

import hardCodedPrices from '@app-js/onboarding/shared/constants/hardCodedprices';
import hardCodedTaxes from '@app-js/onboarding/shared/constants/hardCodedTaxes';

export const BUNDLES = {
  timeclock: ['timeclock_standalone_success'],
  planning: ['planning_success'],
  'planning-timeclock': ['timeclock_standalone_success', 'planning_success'],
};

const initialState = () => ({
  userCreation: {
    token: '',
    userCreationCurrentStep: 0,
    prospect: null,
  },
  organisationCreation: {
    shopCreationCurrentStep: 'shop_form',
    organisationCreationCurrentStep: 'organisation',
  },
  currentLicense: {},
  currentOrganisation: {
    id: null,
    type: 'organisation',
    attributes: {
      name: '',
      businessSector: '',
      siren: '',
      denominationSociale: '',
      paymentEntity: 'billing_on_shop',
    },
  },
  originalOrganisationData: {
    id: null,
    type: 'organisation',
    attributes: {
      name: '',
      businessSector: '',
      siren: '',
      denominationSociale: '',
      paymentEntity: 'billing_on_shop',
    },
  },
  currentShop: {
    id: null,
    type: 'shop',
    attributes: {
      name: '',
      denominationSociale: '',
      siret: '',
      address: '',
      zipcode: '',
      city: '',
      country: '',
      countryRegion: '',
      convention: '',
      openingTime: '00:00',
      closingTime: '00:00',
      configured: false,
    },
    relationships: {
      convention: {
        attributes: {
          name: '',
        },
      },
    },
  },
  originalShopData: {},
  currentUser: {
    id: '',
    type: 'user',
    attributes: {
      firstName: '',
      lastName: '',
      email: '',
      admin: '',
      organisationName: '',
      lang: '',
    },
  },
  taxRatesByCountry: {},
  packOfferPrices: {},
  sector: JSON.parse(localStorage.getItem('step_five')) ?
    JSON.parse(localStorage.getItem('step_five')).sector :
    'health',
  isScrollingThroughCompanyRegistrations: false,
});

const mutations = {
  /**
   * userCreation
   */
  setToken(state, data) {
    state.userCreation.token = data;
  },
  setProspect(state, payload) {
    state.userCreation.prospect = payload.data;
  },
  setUserCreationCurrentStep(state, data) {
    state.userCreation.userCreationCurrentStep = data;
  },

  /**
   * organisationCreation
   */
  updateShopStep(state, payload) {
    state.organisationCreation.shopCreationCurrentStep = payload;
  },

  updateOrganisationStep(state, payload) {
    state.organisationCreation.organisationCreationCurrentStep = payload;
  },

  /**
   * currentOrganisation
   */

  setOrganisation(state, payload) {
    state.currentOrganisation = payload.data;
    state.originalOrganisationData = cloneDeep(state.currentOrganisation);
  },

  setOrganisationAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentOrganisation.attributes[attribute] = payload[attribute];
    });
  },

  squashOrganisation(state) {
    state.currentOrganisation = cloneDeep(state.originalOrganisationData);
  },

  /**
   * currentLicense
   */
  fetchCurrentLicenseSuccess(state, payload) {
    state.currentLicense = payload;
  },

  fetchCurrentLicenseError(state, error) {
    state.error = error;
  },

  /**
   * currentShop
   */
  fetchCurrentShopSuccess(state, payload) {
    state.currentShop = payload.data;
  },

  fetchCurrentShopError(state, error) {
    state.error = error;
  },

  setShop(state, payload) {
    state.currentShop = payload.data;

    const convention = associationMatcherItem(state.currentShop, payload.included, {
      key: 'convention',
      type: 'convention',
    });

    Object.assign(state.currentShop.relationships, { convention });
    state.originalShopData = cloneDeep(state.currentShop);
  },

  syncShopOriginalData(state) {
    state.originalShopData = cloneDeep(state.currentShop);
  },

  squashShop(state) {
    state.currentShop = cloneDeep(state.currentShop);
  },

  setShopAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentShop.attributes[attribute] = payload[attribute];
    });
  },

  setDefaultShopAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentShop.attributes[attribute] = payload[attribute];
    });

    state.originalShopData = cloneDeep(state.currentShop);
  },

  setConvention(state, conventionName) {
    state.currentShop.relationships.convention.attributes.name = conventionName;
  },

  resetCurrentShop(state) {
    const formerConvention = state.currentShop.relationships.convention.attributes.name;
    const formerCountry = state.currentShop.attributes.country;
    const formerOpeningTime = state.currentShop.attributes.openingTime;
    const formerClosingTime = state.currentShop.attributes.closingTime;

    Object.assign(state, { currentShop: initialState().currentShop });

    state.currentShop.relationships.convention.attributes.name = formerConvention;
    state.currentShop.attributes.openingTime = formerOpeningTime;
    state.currentShop.attributes.closingTime = formerClosingTime;
    state.currentShop.attributes.country = formerCountry;
  },

  /**
   * currentUser
   */

  currentUserSuccess(state, payload) {
    state.currentUser = payload.data;
  },

  taxesSuccess(state, payload) {
    state.taxRatesByCountry = payload;
  },

  pricesSuccess(state, payload) {
    state.packOfferPrices = payload;
  },

  setupAnalytics(state, { config }) {
    Vue.prototype.$skAnalytics.setAnalyticsVariables({ config });
    Vue.prototype.$skAnalytics.load();
    Vue.prototype.$skAnalytics.sendAnalytics();
    Vue.prototype.$skAnalytics.page();
  },

  /**
   * sector
   */
  setSector(state, value) {
    state.sector = value;
  },
  setIsScrollingThroughCompanyRegistrations(state, isScrollingThroughCompanyRegistrations) {
    state.isScrollingThroughCompanyRegistrations = isScrollingThroughCompanyRegistrations;
  },
};

const actions = {
  /**
   * userCreation
   */
  // when coming back to account creation or after refresh prospect is not set anymore
  // allows to fetch prospect from current_user to determine payment_type in onboarding
  fetchProspect({ commit }) {
    return httpClient
      .get('/v3/api/prospects/current')
      .then(response => {
        const prospect = response.data;
        commit('setProspect', prospect);

        return prospect;
      });
  },

  getProspect({ commit }, token) {
    return httpClient
      .get(`/v3/api/prospects/${token}`)
      .then(response => {
        const prospect = response.data;
        commit('setProspect', prospect);

        if (prospect.data.attributes.clientId) {
          commit('setUserCreationCurrentStep', 1);
        }

        return prospect;
      });
  },
  updateProspect({ state }, params) {
    return httpClient.patch(`/v3/api/prospects/${state.currentUser.id}`, params);
  },
  createUser({ commit }, { params, token }) {
    return httpClient
      .post(`/v3/api/users/${token}`, { user: params })
      .then(() => {
        commit('setUserCreationCurrentStep', 1);
      });
  },

  /**
   * currentOrganisation
   */
  getFirstOrganisation({ commit }, { organisationId, rootNodeId }) {
    return httpClient
      .get(`/v3/api/onboarding/organisations/${organisationId}?cluster_node_id=${rootNodeId}`)
      .then(response => {
        commit('setOrganisation', response.data);
        return response.data;
      });
  },
  createOrganisation({ commit }, params) {
    return httpClient
      .post('/v3/api/onboarding/organisations', params)
      .then(response => {
        commit('setOrganisation', response.data);
        commit('updateOrganisationStep', 'shop');
        return response;
      });
  },
  updateOrganisation({ commit }, params) {
    return httpClient
      .patch(`/v3/api/onboarding/organisations/${params.id}`, params)
      .then(response => {
        commit('setOrganisation', response.data);
        commit('updateOrganisationStep', 'shop');
        return response;
      });
  },

  /**
   * currentShop
   */

  getLastCreatedShop({ commit }, params) {
    return httpClient
      .get(`/v3/api/shops/${params.shopId}`)
      .then(response => {
        commit('setShop', response.data);
        return response;
      });
  },
  createShop({ commit }, params) {
    return httpClient
      .post('/v3/api/shops', params)
      .then(response => {
        commit('setShop', response.data);
        return response;
      });
  },
  updateShop({ commit }, params) {
    return httpClient
      .patch(`/v3/api/shops/${params.id}`, params)
      .then(response => {
        commit('setShop', response.data);
        return response;
      });
  },

  /**
   * currentUser
   */

  fetchCurrentUser({ commit }) {
    return httpClient
      .get('/v3/api/current_user')
      .then(response => {
        commit('currentUserSuccess', response.data);
        return response;
      });
  },
  fetchCurrentShop({ commit }, shopId) {
    return httpClient
      .get(`/v3/api/shops/${shopId}/show_extended`)
      .then(response => {
        commit('fetchCurrentShopSuccess', response.data);
      })
      .catch(error => {
        commit('fetchCurrentShopError', error);
        throw error;
      });
  },
  fetchCurrentLicense({ commit }, clusterNodeId) {
    return httpClient
      .get('/v3/api/current_license', { params: { cluster_node_id: clusterNodeId } })
      .then(response => {
        commit('fetchCurrentLicenseSuccess', response.data.data);
      })
      .catch(error => {
        commit('fetchCurrentLicenseError', error);
        throw error;
      });
  },
  updateCurrentUser({ commit }, params) {
    return httpClient
      .put('/v3/api/current_user', params)
      .then(response => {
        commit('currentUserSuccess', response.data);
        return response;
      });
  },
  fetchStripeTaxes({ commit }) {
    return httpClient
      .get('/v3/api/self_serve/tax_rates')
      .then(response => {
        commit('taxesSuccess', response.data);
        return response;
      });
  },
  setHardCodedTaxes({ commit }) {
    commit('taxesSuccess', hardCodedTaxes);
  },
  fetchStripePrices({ commit }) {
    return httpClient
      .get('/v3/api/self_serve/prices')
      .then(response => {
        commit('pricesSuccess', response.data);
        return response;
      });
  },
  setHardCodedPricesByLocale({ commit }, locale = 'fr') {
    const country = locale.toLowerCase() === 'es' ? 'es' : 'fr';
    const prices = hardCodedPrices[country];
    commit('pricesSuccess', prices);
  },
};

const getters = {
  initials({ currentUser }) {
    if (currentUser.attributes.firstName === '') {
      return '';
    }
    return `${currentUser.attributes.firstName[0].toUpperCase()}${currentUser.attributes.lastName[0].toUpperCase()}`;
  },
  packOfferPriceWithOptions: (state, _getters) => (packOfferName, options = []) => {
    if (options.length === 0) return state.packOfferPrices[packOfferName];

    const basePrice = state.packOfferPrices[packOfferName];
    const price = {
      monthly: basePrice.monthly,
      annual: basePrice.annual,
    };
    options.forEach(option => {
      price.monthly += state.packOfferPrices.options[option].monthly;
      price.annual += state.packOfferPrices.options[option].annual;
    });
    return price;
  },
  fullName: ({ currentUser }) => `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`,
  queryRawBundle() {
    return localStorage.getItem('query_bundle')?.toLowerCase();
  },
  rawBundle(_state, localGetters) {
    const stepFiveBundle = localStorage.getItem('step_five_bundle')?.toLowerCase();
    return stepFiveBundle || localGetters.queryRawBundle;
  },
  salesforceBundle: (_state, localGetters) => (bundle = null) => {
    if (bundle) {
      return BUNDLES[bundle] || null;
    }

    return BUNDLES[localGetters.rawBundle()] || null;
  },
  unsavedChangesToOrganisation(state) {
    const originalOrganisationState = cloneDeep(state.originalOrganisationData);

    return JSON.stringify(state.currentOrganisation) !== JSON.stringify(originalOrganisationState);
  },
  unsavedChangesToShop(state) {
    const originalShopState = cloneDeep(state.originalShopData);

    return JSON.stringify(state.currentShop) !== JSON.stringify(originalShopState);
  },
  isSystemAdmin: state => state.currentLicense.attributes?.position === 0,
  isScrollingThroughCompanyRegistrations: state => state.isScrollingThroughCompanyRegistrations,
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
