<template>
  <div id="organisation_creation__page_container">
    <div class="organisation_creation__navbar">
      <SkLogo />
      <SkMedallion
        :text="initials"
        background-color="#2b66fe"
        color="white"
      />
    </div>
    <div class="row organisation_creation__row">
      <div class="col-3 organisation_creation__progress_bar">
        <div class="organisation_creation__list">
          <div
            v-if="!isNewShopStep && !isNewShopBillingStep"
            class="organisation_creation__progress_bar_item
            organisation_creation__progress_bar--active"
          >
            {{ $t('organisation_creation.progress_bar.create_organisation') }}
          </div>
          <li
            v-if="isOrganisationCreationBillingStep"
            class="organisation_creation__progress_bar_item
            organisation_creation__progress_bar--active"
          >
            {{ $t('organisation_creation.organisation_billing_info.form_title') }}
          </li>
        </div>
        <div :class="shopStepSidebarClass('shop')">
          {{ newShopLabel }}
        </div>
        <div
          v-if="isShopStep"
          class="organisation_creation__list"
        >
          <li
            class="organisation_creation__progress_bar_item
            organisation_creation__progress_bar--active"
          >
            {{ $t('organisation_creation.progress_bar.shop_data') }}
          </li>
          <li
            v-if="isBillingOnShop"
            :class="shopBillingStepSidebarClass"
          >
            {{ $t('organisation_creation.progress_bar.billing_data') }}
          </li>
        </div>
      </div>
      <div
        v-show="loading"
        class="organisation_creation__spinner-wrapper"
      >
        <SkLoader size="large" />
      </div>
      <div
        v-show="!loading"
        class="col-6"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import {
  SkMedallion,
  SkLoader,
  SkLogo,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';

import { httpClient } from '@skello-utils/clients';

export default {
  name: 'OrganisationCreation',
  components: { SkLogo, SkMedallion, SkLoader },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'user_creation') {
      next(false);
    } else {
      next();
    }
  },
  data() {
    return {
      steps: ['organisation', 'shop'],
      loading: false,
    };
  },
  computed: {
    ...mapState('onboarding', ['organisationCreation', 'currentUser', 'currentOrganisation']),
    ...mapState('config', ['config']),
    ...mapGetters('onboarding', ['initials']),

    currentStep() {
      return this.organisationCreation.organisationCreationCurrentStep;
    },
    isShopStep() {
      return this.currentStep === 'shop';
    },
    isNewShopStep() {
      return this.organisationCreation.shopCreationCurrentStep === 'new_shop_form';
    },
    isNewShopBillingStep() {
      return this.organisationCreation.shopCreationCurrentStep === 'new_shop_billing_form';
    },
    isOrganisationCreationBillingStep() {
      return this.organisationCreation.organisationCreationCurrentStep === 'organisation_billing_form';
    },
    isBillingOnShop() {
      if (this.currentOrganisation.attributes.doNotPay) return false;

      return this.currentOrganisation.attributes.paymentEntity === 'billing_on_shop';
    },
    isBillingOnOrganisation() {
      if (this.currentOrganisation.attributes.doNotPay) return false;

      return this.currentOrganisation.attributes.paymentEntity === 'billing_on_organisation';
    },
    isBillingNoEntity() {
      return this.currentOrganisation.attributes.paymentEntity ===
        this.config.payment_entities[0];
    },
    newShopLabel() {
      return this.isNewShopStep || this.isNewShopBillingStep ?
        this.$t('organisation_creation.progress_bar.new_shop') :
        this.$t('organisation_creation.progress_bar.create_shop');
    },
    shopBillingStepSidebarClass() {
      if (['shop_billing_form', 'new_shop_billing_form'].includes(this.organisationCreation.shopCreationCurrentStep)) {
        return 'organisation_creation__progress_bar--active';
      }

      return 'organisation_creation__progress_bar--inactive';
    },
  },
  created() {
    this.fetchCurrentUser()
      .then(() => {
        this.fetchProspect();
        const { organisationId, rootNodeId } = this.currentUser.attributes;

        if (!organisationId) {
          this.loading = false;
          return;
        }
        this.getFirstOrganisation({ organisationId, rootNodeId })
          .then(response => {
            /**
             * Product specs :
             * - When I come back to v3/onboarding I shall land on my current step
             * hence all router.push
             */
            const organisation = response.data;
            /**
             * - When my organisation name and SIREN is filed in organisation creation
             * the shop form shall be filed with that data -> line 101
             */
            if (!organisation.attributes.lastShopId) {
              this.setDefaultShopAttributes({
                name: organisation.attributes.name,
                siret: organisation.attributes.siren,
              });
              if (this.isBillingNoEntity ||
                this.isBillingOnShop ||
                this.currentOrganisation.attributes.doNotPay ||
                (this.isBillingOnOrganisation &&
                  this.currentOrganisation.attributes.hasBillingInfos)) {
                this.updateOrganisationStep('shop');
                this.$router.push({ name: 'shop_form' });
              } else {
                this.updateOrganisationStep('organisation_billing_form');
                this.$router.push({ name: 'organisation_billing_form' });
              }
              return; // You dont need to get a shop if you don't have a lastShopId
            }

            this.getLastCreatedShop({
              shopId: organisation.attributes.lastShopId,
              organisationId: organisation.id,
            })
              .then(lastShop => {
                const lastCreatedShopId = parseInt(localStorage.getItem('lastShopId'), 10);

                this.updateOrganisationStep('shop');
                // Edge case When refreshing on billing step with do not pay turned to true
                if (this.currentOrganisation.attributes.doNotPay || this.isBillingNoEntity) {
                  this.updateShopToConfigured(lastShop.data.data.id)
                    .then(() => {
                      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'congratulation-modal');
                    });
                } else if (organisation.attributes.lastShopId === lastCreatedShopId) {
                  this.$router.push({ name: 'shop_form' });

                  this.resetCurrentShop();

                  this.updateShopStep('new_shop_form');
                } else {
                  this.$router.push({ name: 'shop_billing_form' });

                  const nextStep = organisation.attributes.moreThanOneShop ?
                    'new_shop_billing_form' :
                    'shop_billing_form';

                  this.updateShopStep(nextStep);
                }
              })
              .catch(() => {
                this.$skToast({
                  message: this.$t('organisation_creation.error.generic'),
                  variant: 'error',
                });
              });
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
  },
  methods: {
    ...mapActions('onboarding', ['fetchCurrentUser', 'fetchProspect', 'getFirstOrganisation', 'getLastCreatedShop']),
    ...mapActions('config', ['fetchConfig']),
    ...mapMutations('onboarding', [
      'setDefaultShopAttributes',
      'updateOrganisationStep',
      'resetCurrentShop',
      'updateShopStep',
    ]),
    shopStepSidebarClass(step) {
      if (step === this.currentStep) {
        return 'organisation_creation__progress_bar_item organisation_creation__progress_bar--active';
      }
      return 'organisation_creation__progress_bar_item organisation_creation__progress_bar--inactive';
    },
    organisationBillingStepSidebarClass(step) {
      if (step === this.organisationCreation.organisationCreationCurrentStep) {
        return 'organisation_creation__progress_bar--active';
      }
      return 'organisation_creation__progress_bar--inactive';
    },
    updateShopToConfigured(shopId) {
      const params = {
        shop: {
          configured: true,
        },
      };

      return httpClient
        .patch(`/v3/api/shops/${shopId}`, params)
        .catch(error => {
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation_creation__navbar {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-size: $fs-text-m;
  border-bottom: 1px solid $sk-grey-10;
  position: sticky;
  top: 0;
  z-index: 2;
}

.organisation_creation__progress_bar {
  font-size: $fs-text-m;
  line-height: 17px;
  text-align: left;
  margin-top: 200px;
  display: table;
  padding-left: 6%;

  .organisation_creation__progress_bar--active {
    color: $sk-blue;
    border-left: 2px solid $sk-blue;
  }

  .organisation_creation__progress_bar--inactive {
    color: $sk-grey;
    border-left: 2px solid $sk-grey-10;
  }
}

.organisation_creation__progress_bar_item {
  padding-bottom: 10px;
  padding-left: 25px;
  padding-top: 10px;
}

.organisation_creation__list {
  list-style-type: disc;
  font-size: $fs-text-m;
  line-height: 17px;
  text-align: left;

  li {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-position: inside;
  }
}

.organisation_creation__row {
  margin-left: 0;
  margin-right: 0;
  z-index: 1;
}

#organisation_creation__page_container {
  align-self: center;
  text-align: center;
}

#link-logo-skello {
  height: 32px;
}

.organisation_creation__spinner-wrapper {
  display: flex;
  justify-content: center;
  color: $sk-blue;
  font-size: 2em;
  padding-top: 4em;
}
</style>
