<template>
  <tr
    class="table-row"
  >
    <th class="empty-cell" />

    <template v-for="(week, weekKey, weekIdx) of monthlyVisibleWeeks">
      <th
        v-for="(day) of week.days"
        :key="`${weekKey + '-' + day.date}`"
        :class="dayCellHeaderClasses"
      >
        <HeaderDay :day-info="day" />
      </th>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <th
        :class="[weeklyCounterClasses(weekKey), `week-${weekIdx + 1}`]"
      >
        <div class="weekly-counter-wrapper">
          <component
            :is="counterColumnIcon(weekKey)"
            :fill="$skColors.skGrey30"
            height="24"
            width="24"
          />
          <span v-show="headerComponentStoreProps.selectedWeeklyCountersInMonthlyView[weekKey]">
            {{ $t('plannings.monthly_v3.counters.weekly.header', { weekIdx: weekTag(weekKey) }) }}
          </span>
        </div>
      </th>
    </template>

    <template>
      <th
        ref="emptyCol"
        :style="{
          width: `max(${remainingWidth - 120}px, 0px)`,
          'border-bottom': 'none'
        }"
      />

      <th v-if="ptoActivated">
        <div class="header-counter header-counter--dropdown">
          <span class="header-counter--text">
            {{ headerDatesText }}
          </span>

          <SkSelectV2
            v-model="counterValue"
            :options="counterSelectOptions"
            :disabled="true"
          />
        </div>
      </th>

      <th v-else>
        <div class="header-counter">
          <span class="header-counter--title">
            {{ $t('plannings.table.header.counters.total') }}

          </span>
          <span class="header-counter--text">
            {{ headerDatesText }}
          </span>
        </div>
      </th>
    </template>
  </tr>
</template>

<script>
import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { FEATURES } from '@app-js/shared/constants/features';
import HeaderDay from './HeaderDay';

export default {
  name: 'TableHeader',
  components: {
    HeaderDay,
  },
  props: {
    headerComponentStoreProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      remainingWidth: 0,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['monthlyVisibleWeeks']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('planningsState', ['selectedWeeklyCountersInMonthlyView']),

    counterValue: {
      get() {
        return this.headerComponentStoreProps.selectedCounter;
      },
      set(newValue) {
        this.setSelectedCounter(newValue);
      },
    },
    hasYearlyDistinction() {
      return this.headerComponentStoreProps.checkFeatureFlag('FEATURE_N1_N_PTO_TRACKER') &&
        this.isFeatureEnabled(
          FEATURES.FEATURE_PTO_COUNTER,
          this.headerComponentStoreProps.currentShop.id,
        );
    },
    ptoActivated() {
      return this.headerComponentStoreProps.currentShop.attributes.plcInitialized &&
             this.headerComponentStoreProps.isPtoCounterEnabled;
    },
    headerDatesText() {
      // NOTE: The second week will always be 100% of the month we're looking at
      const secondWeek = Object.values(this.monthlyVisibleWeeks)[1];

      const firstMonthDay = skDate(secondWeek.days[0].date).startOf('month').format('DD');
      const lastMonthDay = skDate(secondWeek.days[0].date).endOf('month').format('DD');
      const month = skDate(secondWeek.days[0].date).locale('en').format('MMMM').toLowerCase();
      const parsedMonth = this.$t(`plannings.table.header.counters.short_month.${month}`);
      return `${firstMonthDay} - ${lastMonthDay} ${parsedMonth}`;
    },
    dayCellHeaderClasses() {
      return {
        'no-data': this.headerComponentStoreProps.filteredUsers.length === 0,
      };
    },
    counterSelectOptions() {
      const options = [
        {
          id: 'hours_worked',
          text: this.$t('plannings.monthly_v3.counters.user.total'),
        },
      ];

      if (this.headerComponentStoreProps.isPtoCounterEnabled) {
        options.push({
          id: 'pto',
          text: this.headerComponentStoreProps.hasYearlyDistinction ?
            this.$t('plannings.monthly_v3.counters.user.yearly_pto') :
            this.$t('plannings.monthly_v3.counters.user.pto'),
        });
      }
      return options;
    },
    selectedWeeklyCountersInMonthlyView() {
      return this.headerComponentStoreProps.selectedWeeklyCountersInMonthlyView;
    },
  },

  mounted() {
    window.addEventListener('resize', this.updateEmptyColumnWidth);
    this.updateEmptyColumnWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateEmptyColumnWidth);
  },
  methods: {
    ...mapMutations('planningsState', ['toggleWeeklyCounterInMonthlyView']),
    ...mapMutations('monthlyPlanning', ['setSelectedCounter']),
    updateEmptyColumnWidth() {
      if (this.$refs.emptyCol === undefined) return;

      const rect = this.$refs.emptyCol.getBoundingClientRect();
      this.remainingWidth = window.innerWidth - rect.left;
    },
    weeklyCounterClasses(weekStart) {
      return {
        'weekly-counter': true,
        'weekly-counter--selected': this.selectedWeeklyCountersInMonthlyView[weekStart],
      };
    },
    counterColumnIcon(weekStart) {
      return this.selectedWeeklyCountersInMonthlyView[weekStart] ? 'CaretsPinchingIcon' : 'CaretsExpandingIcon';
    },
    weekTag(weekStart) {
      return skDate(weekStart).isoWeek();
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  th {
    z-index: 5;
    width: 45px;
    // Needed so that height: 100% works in weekly-counter-wrapper.
    // The value 0 doesn't mean anything since the height is set by TableRow
    height: 0;
    background-color: $sk-white;
    border-bottom: 1px solid $sk-grey-10;
    position: sticky;
    padding: 0;
    top: 0;

    &:first-child {
      width: 160px;
      border-bottom: none;
      z-index: 10;
      left: 0;
    }

    &.no-data {
      border-bottom: none;
    }

    &.weekly-counter {
      border-bottom: none;
      width: 24px;

      &--selected {
        width: 72px;
      }
    }
  }

  &.table-row {
    th {
      &:last-child {
        width: 120px;
        border-bottom: none;
        z-index: 10;
        right: 0;
      }
    }
  }
}

.weekly-counter-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
  padding-top: 9px;
  row-gap: 6px;
  height: 100%;

  span {
    font-weight: $fw-regular;
    font-size: $fs-text-m;
    line-height: 15.61px;
  }

  svg {
    rotate: 90deg;
  }
}

.header-counter {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  align-items: center;

  &--dropdown {
    gap: 4px;
  }

  &--title {
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
  }

  &--text {
    font-size: $fs-text-s;
    font-weight: $fw-regular;
    color: $sk-grey-30;
  }
}

::v-deep {

  .sk-select__selected-option {
    height: 36px;
    justify-content: space-between;
    gap: 4px;
    font-weight: $fw-regular;
    cursor: auto;
  }
  .sk-select__caret {
    margin: 0;
  }

  .text-truncate {
    color: $sk-grey-30;
  }
}

</style>
