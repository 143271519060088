<template>
  <SkOroraSidebar
    id="license-side-panel"
    :cancel-button-label="footerButtonLabel('cancel')"
    :show-footer="editableLicense"
    :submit-button-label="footerButtonLabel('save')"
    :subtitle="$t('organisation_settings.tabs.licenses.users_count', { count: license.attributes.usersCount },)"
    :title="licenseName"
    @closed="cancelForm"
    @cancel="cancelForm"
    @submit="submitForm"
  >
    <template #body>
      <main class="license-sidepanel">
        <div v-if="license.attributes.description">
          <div class="license-sidepanel__description">
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="license-sidepanel__description__explanation"
              v-html="licenseDescription"
            />
            <!-- eslint-enable vue/no-v-html -->
            <p class="sk-subtitle--medium">
              {{ license.attributes.example }}
            </p>
          </div>
        </div>
        <div class="license-sidepanel__actions">
          <SkCollapse opened-by-default>
            <template #labelLeft>
              <div class="license-sidepanel__actions__label">
                {{ $t('organisation_settings.tabs.licenses.category_names.general') }}
              </div>
            </template>
            <template #content>
              <div class="license-sidepanel__actions__select">
                {{ $t('organisation_settings.tabs.licenses.select_label') }}
                <SkSelectV2
                  v-model="generalAction"
                  :options="generalActionsOptions"
                  :disabled="!editableLicense"
                  :disabled-options="isCanReadSelfOnly"
                />
              </div>
            </template>
          </SkCollapse>
          <SkCollapse
            v-for="licenseSection in filledLicenseSections"
            :key="licenseSection"
            opened-by-default
          >
            <template #labelLeft>
              <div class="license-sidepanel__actions__label">
                {{
                  $t(`organisation_settings.tabs.licenses.category_names.${licenseSection}`)
                }}
              </div>
            </template>
            <template #content>
              <div class="license-sidepanel__actions__content">
                <ActionRow
                  v-for="action in filteredActionLicenses(licenseSection)"
                  :key="action.name"
                  :action="action"
                  :license="license"
                />
              </div>
            </template>
          </SkCollapse>
        </div>
      </main>
    </template>
  </SkOroraSideBar>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';

import { LICENSE_COLORS } from '@app-js/shared/constants/colors';
import { nlToBr } from '@skello-utils/formatting/strings';
import { arrayToSelectOptions } from '@skello-utils/form';
import { FEATURES } from '@app-js/shared/constants/features';
import ActionRow from './ActionRow';

export default {
  name: 'LicenseSidePanel',
  components: { ActionRow },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      licenseSections: [
        'planning',
        'employee',
        'report',
        'settings',
        'punch_clock',
        'dashboard',
      ],
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isBundleSystemActive', 'isFeatureEnabled', 'anyCounterEnabled']),

    editableLicense() {
      if (!this.license.attributes.active) return false;
      return this.currentLicense.attributes.position < this.license.attributes.position;
    },
    licenseDescription() {
      return nlToBr(this.license.attributes.description);
    },
    licenseName() {
      return this.$t(
        `organisation_settings.tabs.licenses.titles.${this.license.attributes.originalType}`,
      );
    },
    filledLicenseSections() {
      return this.licenseSections.filter(
        licenseSection => {
          const actionLicenses = this.filteredActionLicenses(licenseSection);
          return actionLicenses && actionLicenses.length > 0;
        },
      );
    },
    positionBackgroundColor() {
      return LICENSE_COLORS[this.license.attributes.originalType].backgroundColor;
    },
    positionContentTextColor() {
      return LICENSE_COLORS[this.license.attributes.originalType].color;
    },
    isCanReadSelfOnly() {
      // if license is not employee
      if (
        !this.config.can_read_self_only_licenses.includes(this.license.attributes.originalType)
      ) {
        return ['can_read_self_only'];
      }

      return [];
    },
    generalAction: {
      get() {
        return this.license.attributes.actions.general.find(a => a.allowed).name;
      },

      set(newValue) {
        this.license.attributes.actions.general.forEach(action => {
          action.allowed = false;

          if (action.name === newValue) {
            action.allowed = true;
          }
        });
      },
    },
    generalActionsOptions() {
      return arrayToSelectOptions(
        this.license.attributes.actions.general.map(action => action.name),
        value => this.$t(`organisation_settings.tabs.licenses.general_actions.${value}`),
      );
    },
  },

  methods: {
    filteredActionLicenses(licenseSection) {
      let actionLicenses;

      if (this.isBundleSystemActive()) {
        actionLicenses = this.filterActionLicenseByFeature(licenseSection);
      } else {
        actionLicenses = this.filterActionLicenseByPack(licenseSection);
      }

      // Filter by hidden actions (some actions aren't visible in some licenses,
      // like read-only cannot see "edit shift" permission)
      if (this.license.attributes.hideUnavailableActions) {
        actionLicenses = actionLicenses.filter(action => action.available);
      }

      // can_upload_self_documents permission is under dev feature flag
      if (!this.isDevFlagEnabled('FEATUREDEV_DOCUMENTS_UPLOAD_PERMISSION')) {
        actionLicenses = actionLicenses.filter(action => action.name !== 'can_upload_self_documents');
      }

      return actionLicenses;
    },
    filterActionLicenseByPack(licenseSection) {
      const actionLicenses = this.license.attributes.actions[licenseSection];

      if (!actionLicenses) return [];

      const currentPackOfferName = this.currentOrganisation.attributes.packOffer.name;

      // Filter by packOffer (some packs don't have access to features, so don't need permissions)
      return actionLicenses.filter(
        action => !action.hidden_for_packs.includes(currentPackOfferName),
      );
    },
    filterActionLicenseByFeature(licenseSection) {
      const featureEnabled = feature => (
        this.isFeatureEnabled(feature, this.currentShop.id)
      );

      // Filter sections by feature
      if (licenseSection === 'report' && !featureEnabled(FEATURES.FEATURE_REPORT_SAAS_AND_EXCEL)) return [];
      if (licenseSection === 'dashboard' && !featureEnabled(FEATURES.FEATURE_DASHBOARD)) return [];

      // Filter actions by feature
      const actionLicenses = this.license.attributes.actions[licenseSection] || [];
      return actionLicenses.filter(action => {
        switch (action.name) {
          case 'can_create_shifts':
          case 'can_edit_shifts':
          case 'can_create_shift_swap':
          case 'can_lock_planning':
          case 'can_tmplock_planning':
            return featureEnabled(FEATURES.FEATURE_PLANNING_ACCESS);
          case 'can_publish_planning':
            return featureEnabled(FEATURES.FEATURE_PLANNING_PUBLICATION);
          case 'can_create_self_availabilities':
            return featureEnabled(FEATURES.FEATURE_AVALIBITY_REQUESTS);
          case 'can_manage_employee_requests':
          case 'can_create_self_leave_requests':
            return featureEnabled(FEATURES.FEATURE_LEAVE_REQUESTS);
          case 'can_read_hours_counter_and_total':
          case 'can_read_paid_vacation_counter':
            return this.anyCounterEnabled(this.currentShop.id);
          case 'can_edit_legal_alerts_config':
            return featureEnabled(FEATURES.FEATURE_REPORT_SAAS_AND_EXCEL);
          case 'can_manage_document_templates':
            return featureEnabled(FEATURES.FEATURE_DOCUMENT_TEMPLATES);
          default:
            return true;
        }
      });
    },
    cancelForm() {
      this.$emit('cancel');
    },
    submitForm() {
      this.updateLicense();
    },
    updateLicense() {
      this.$emit('update-license', this.license);
    },
    footerButtonLabel(buttonAction) {
      if (this.editableLicense) return this.$t(`organisation_settings.${buttonAction}`);

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.license-sidepanel {
  padding: 8px 24px;
}

.license-sidepanel__description__explanation {
  margin-bottom: 5px;
}

.license-sidepanel__actions__label {
  font-weight: bold;
  padding-left: 10px;
}

.license-sidepanel__actions {
  margin-top: 15px;

  ::v-deep .sk-collapse__label {
    background-color: $sk-grey-5;
    border-radius: 4px;
  }

  ::v-deep .sk-collapse__caret-wrapper path {
    fill: black;
  }
}

.license-sidepanel__actions__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0;
}

.license-sidepanel__actions__select {
  padding: 20px 0;
}
</style>
