<template>
  <SkModalSection border-bottom="none">
    <template v-if="isAnyDayLocked">
      <div class="modal__days-locked__caption">
        <CircledExclamationMarkIcon
          fill="#d03e50"
          class="mr-1"
        />
        {{ $t('plannings.toolbar.modal.create_from.select_parameters.caution.title') }}
      </div>
      <div class="modal__days-locked__text mb-4">
        <!-- eslint-disable-next-line max-len -->
        {{ $t(`plannings.toolbar.modal.create_from.select_parameters.caution.description_${viewName}`) }}
      </div>
    </template>
    <template v-else>
      <div class="modal__body__text">
        {{ $t(`plannings.toolbar.modal.create_from.select_parameters.body.text_${viewName}`) }}
      </div>
      <div
        v-if="templatesLoading"
        class="modal__body__spinner-wrapper"
      >
        <SkLoader size="large" />
      </div>
      <div
        v-else
        class="modal__input-wrapper"
      >
        <div class="modal__select-line">
          <span class="modal__select-line__description">
            <!-- eslint-disable-next-line max-len -->
            {{ $t('plannings.toolbar.modal.create_from.select_parameters.body.select_create_from.label') }}
          </span>
          <!-- eslint-disable max-len -->
          <SkSelectV2
            v-model="createFromSelector"
            :options="selectCreateFromOptions"
            :label="$t(
              'plannings.toolbar.modal.create_from.select_parameters.body.select_template.placeholder'
            )"
            width="320px"
            data-test="create-from-template-modal__select-template-option"
            append-to-body
          />
        </div>
        <div class="modal__select-line">
          <span class="modal__select-line__description">
            <!-- eslint-disable max-len -->
            {{ templateSelected ?
              $t('plannings.toolbar.modal.create_from.select_parameters.body.select_template.label') :
              $t(`plannings.toolbar.modal.create_from.select_parameters.body.select_${viewName}.label`) }}
            <!-- eslint-enable max-len -->
          </span>
          <SkSelectV2
            v-show="templateSelected"
            v-model="selectedTemplateId"
            :options="selectTemplateOptions"
            :disabled="disableSelectTemplate"
            :label="selectTemplateLabel"
            width="320px"
            data-test="create-from-template-modal__select-template"
            append-to-body
          >
            <template #option="{ option }">
              <div class="select_template__option__container">
                <span class="select_template__option__text">
                  {{ option.text }}
                </span>
                <span
                  v-if="deleteTemplatePendingForId[option.id]"
                  class="select_template__spinner-wrapper"
                >
                  <SkLoader size="small" />
                </span>
                <TrashCanV2Icon
                  v-else
                  class="select_template__option__delete-icon"
                  fill="#d03e50"
                  height="16px"
                  width="16px"
                  @click.stop.native="onClickDeleteTemplate(option.id)"
                />
              </div>
            </template>
          </SkSelectV2>
          <div
            v-show="!templateSelected && !isDailyView"
            class="modal__select-line__weeks_selection"
          >
            <!-- eslint-disable max-len -->
            <SkSelectV2
              v-model="selectedPreviousWeek"
              :options="previousWeeksOptions"
              :label="$t('plannings.toolbar.modal.create_from.select_parameters.body.select_week.placeholder')"
              width="320px"
              data-test="create-from-template-modal__select-period"
              append-to-body
            >
              <!-- eslint-enable max-len -->
              <template #option="{ option }">
                <div class="select_template__option__container">
                  <span class="select_template__option__pill">
                    {{ formatLongWeekNumber(option.id) }}
                  </span>
                  <span>
                    <!-- eslint-disable-next-line max-len -->
                    {{ $t('plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.selected.week_days', selectedWeekDays(option.id)) }}
                  </span>
                </div>
              </template>
            </SkSelectV2>
          </div>
          <div
            v-if="!templateSelected && isDailyView"
            class="modal__select-line__days_selection"
          >
            <!-- eslint-disable max-len -->
            <SkSelectV2
              v-model="selectedPreviousDay"
              force-search-bar-behavior="force-hide"
              :options="previousDaysOptions"
              :label="$t('plannings.toolbar.modal.create_from.select_parameters.body.select_day.placeholder')"
              width="320px"
              append-to-body
            />
            <!-- eslint-enable max-len -->
          </div>
        </div>
        <div class="modal__select-line">
          <span class="modal__select-line__description">
            {{ selectUsersOrPostesDescription }}
          </span>
          <PosteAndAbsenceSelector
            v-if="isPostesView"
            :selected-ids="selectedPosteAndAbsenceIds"
            :poste-and-absence-options="posteAndAbsenceOptions"
            :disable-select="false"
            @select-postes-and-absences="handleSelectPostesAndAbsences"
          />
          <PlanningEmployeesSelect
            v-else
            :initial-user-ids="selectedUserIds"
            :users="users"
            :teams="teams"
            :can-display-unassigned-shift="areUnassignedShiftsAllowed"
            data-test="create-from-template-modal__select-employees"
            @update-users="updateUsers"
          />
        </div>
        <div
          v-if="areUnassignedShiftsAllowed"
          class="modal__select-line modal-line__extra-spacing"
        >
          <span class="modal__select-line__description">
            <span class="select-line__description-with-icon">
              <!-- eslint-disable-next-line max-len -->
              {{ $t('plannings.toolbar.modal.create_from.select_parameters.body.toggle_to_unassigned.label') }}
            </span>
            <!-- eslint-disable max-len -->
            <CircledQuestionMarkIcon
              v-tooltip.bottom="$t(
                'plannings.toolbar.modal.create_from.select_parameters.body.toggle_to_unassigned.tooltip'
              )"
              class="select-line__description__details-icon"
              width="18"
              height="18"
              color="#727272"
            />
            <!-- eslint-enable max-len -->
          </span>
          <SkSwitch v-model="convertToUnassigned" />
        </div>
        <template v-if="!isDailyView">
          <div class="modal__separate-line modal-line__extra-spacing">
            <span class="modal__select-line__description">
              <span class="select-line__description-with-icon">
                <!-- eslint-disable-next-line max-len -->
                {{ $t('plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.label') }}
              </span>
              <!-- eslint-disable max-len -->
              <CircledQuestionMarkIcon
                v-tooltip.bottom="$t(
                  'plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.tooltips.max_limit'
                )"
                class="select-line__description__details-icon"
                width="18"
                height="18"
                color="#727272"
              />
              <!-- eslint-enable max-len -->
            </span>
            <div class="select-line__pick-week__container">
              <span
                class="pick-week__nav"
                @click="previousWeeks"
              >
                <!-- eslint-disable max-len -->
                <ChevronLeftIcon
                  v-tooltip.bottom="$t(
                    'plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.tooltips.previous'
                  )"
                  :fill="isMinWeekListPositionReached ? '#B9BFC4' : '#727272'"
                />
                <!-- eslint-enable max-len -->
              </span>
              <span
                v-for="week in displayedWeeks"
                :key="`week-${week}`"
                v-tooltip.bottom="weekTooltip(week)"
                :class="selectWeekButtonClass(week)"
                @click="toggleWeek(week)"
              >
                {{ formatWeekNumber(week) }}
              </span>
              <span
                class="pick-week__nav"
                @click="nextWeeks"
              >
                <!-- eslint-disable max-len -->
                <ChevronRightIcon
                  v-tooltip.bottom="$t(
                    'plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.tooltips.next'
                  )"
                  :fill="isMaxWeekListPositionReached ? '#B9BFC4' : '#727272'"
                />
                <!-- eslint-enable max-len -->
              </span>
            </div>
          </div>
          <div
            v-if="selectedWeeks.length > 0"
            class="modal__pick-week__results"
          >
            <span
              v-for="week in selectedWeeks"
              :key="`selected-week-${week}`"
              class="pick-week__results__week-container"
            >
              <span class="pick-week__results__week-number">
                {{ $t('plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.selected.week_number', { week_number: weekNumber(week) }) }}
              </span>
              <span class="pick-week__results__week-days">
                {{ $t('plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.selected.week_days', selectedWeekDays(week)) }}
              </span>
              <CrossV2Icon
                v-tooltip.top="$t('plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.selected.remove_tooltip')"
                class="pick-week__results__unselect-icon"
                width="16"
                height="16"
                fill="#e12f57"
                @click.native.stop="unselectWeek(week)"
              />
            </span>
          </div>
        </template>
      </div>
      <div
        v-if="!templatesLoading && !isAnyDayLocked"
        class="modal__body__buttons-wrapper"
      >
        <SkOroraButton
          variant="secondary"
          @click="handleCancel"
        >
          {{ $t('actions.cancel') }}
        </SkOroraButton>
        <SkOroraButton
          :loading="isSubmitSpinning"
          :disabled="isSubmitDisabled"
          data-test="create-from-template-modal__submit-btn"
          @click="handleSubmit"
        >
          {{ $t('actions.submit') }}
        </SkOroraButton>
      </div>
    </template>
  </SkModalSection>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import { escape } from 'lodash';

import { templatesMaxSelectableWeeks } from '@config/env';
import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';
import PlanningEmployeesSelect from '@app-js/plannings/shared/components/PlanningEmployeesSelect';
import PosteAndAbsenceSelector from '../PosteAndAbsenceSelector';

export default {
  name: 'SelectParametersSection',
  components: {
    PosteAndAbsenceSelector,
    PlanningEmployeesSelect,
  },
  data() {
    return {
      selectedCreateFrom: 'previous-week-day', // previous-week or template
      selectedTemplateId: null,
      selectedPreviousWeek: -1,
      selectedPreviousDay: '',
      selectedUserIds: [],
      selectedPosteAndAbsenceIds: [],
      convertToUnassigned: false,
      selectedWeeks: [],
      // Index for position of week picker :
      // ...
      // -2 : shows 10-6 weeks before current
      // -1 : shows 5-1 weeks before current
      //  0 : shows current and 2 weeks before and 2 weeks after
      //  1 : shows 1-5 weeks after current
      //  2 : shows 6-10 weeks after current
      // ...
      weekListPosition: 0,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsTemplates', [
      'templates',
      'templatesLoading',
      'conflictsLoading',
      'duplicatingPlanning',
      'deletingTemplate',
      'deleteTemplatePendingForId',
    ]),
    ...mapState('shopTeams', ['teams']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('planningsState', ['shopPlanningConfig']),
    ...mapState('planningsPostes', ['absences', 'postes']),
    ...mapGetters('planningsState', [
      'monday',
      'sunday',
      'isAnyDayLocked',
      'isDailyView',
      'isPostesView',
      'currentDate',
      'areUnassignedShiftsAllowed',
    ]),
    ...mapGetters('planningsTemplates', ['anyDeleteTemplatePending']),
    ...mapGetters('employees', ['fullName']),
    createFromSelector: {
      get() {
        return this.selectedCreateFrom;
      },
      set(createFrom) {
        if (createFrom === this.selectedCreateFrom) return;

        this.selectedCreateFrom = createFrom;

        this.selectedTemplateId = null;
        this.selectedPreviousWeek = -1;
      },
    },
    userIds() {
      return this.users.map(user => user.id);
    },
    mondaySkDate() {
      return skDate(this.monday, 'YYYY-MM-DD').utc(true);
    },
    sundaySkDate() {
      return skDate(this.sunday, 'YYYY-MM-DD').utc(true);
    },
    /**
     * Number of selectable weeks is 2 * displayedSelectorWeeksLimit + 1
     * As we have :
     * - current week
     * - displayedSelectorWeeksLimit weeks before
     * - displayedSelectorWeeksLimit weeks after
     * Key ranges from (- displayedSelectorWeeksLimit) to (+ displayedSelectorWeeksLimit)
     ***
     * Monday and sunday skDates for each selectable week
     * associative array -> key is weekIndex
     * */
    allSkDates() {
      const result = {};
      Array.from(
        { length: 2 * this.displayedSelectorWeeksLimit + 1 },
        (_value, index) => index - this.displayedSelectorWeeksLimit,
      ).forEach(index => {
        result[index] = {
          monday: this.calculateMondayForWeek(index),
          sunday: this.calculateSundayForWeek(index),
        };
      });
      return result;
    },
    displayedWeeksFirstIndex() {
      // If we are navigating in the past from current week, display weeks before by increments of 5
      // For instance, display 5-1 weeks before current planning week if weekListPosition = -1
      if (this.weekListPosition < 0) {
        return this.weekListPosition * 5;
      }

      // Default view
      // Display current planning week + 2 previous and 2 next
      if (this.weekListPosition === 0) {
        return -2;
      }

      // Case weekListPosition > 0
      // If we are navigating in the future from current week, display weeks after by increments of 5
      // For instance, display 1-5 weeks after current planning week if weekListPosition = 1
      return 1 + (this.weekListPosition - 1) * 5;
    },
    // Array representing the indexes of weeks to display :
    // We display 5 weeks, starting with this.displayedWeeksFirstIndex
    displayedWeeks() {
      return Array.from(
        { length: 5 },
        (_value, index) => index + this.displayedWeeksFirstIndex,
      );
    },
    posteAndAbsenceOptions() {
      const posteOptions = this.postes
        .map(poste => ({ id: poste.id, text: poste.attributes.name }));
      const absenceOptions = this.absences
        .map(absence => ({ id: absence.id, text: absence.attributes.name }));
      return posteOptions.concat(absenceOptions);
    },
    selectCreateFromOptions() {
      return [
        {
          id: 'template',
          text: this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_create_from.option.template'),
        },
        {
          id: 'previous-week-day',
          text: this.isDailyView ?
            this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_create_from.option.previous_day') :
            this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_create_from.option.previous_week'),
        },
      ];
    },
    selectTemplateOptions() {
      return this.templates.map(({ attributes }) => ({
        id: attributes.id,
        text: attributes.name,
      })).sort((templateA, templateB) => templateA.text.localeCompare(templateB.text));
    },
    selectTemplateLabel() {
      return this.templates.length > 0 ?
        this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_template.placeholder') :
        this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_template.empty');
    },
    disableSelectTemplate() {
      return this.selectTemplateOptions.length === 0;
    },
    templateSelected() {
      return this.selectedCreateFrom === 'template';
    },
    previousWeeksOptions() {
      // Selectable weeks are 5-1 weeks before current
      return Array.from({ length: 5 }, (_value, index) => index - 5).map(week => ({
        id: week,
        text: `${this.formatLongWeekNumber(week)} - ${this.$t('plannings.toolbar.modal.create_from.select_parameters.body.pick_weeks.selected.week_days', this.selectedWeekDays(week))}`,
      }));
    },
    previousDaysOptions() {
      const skCurrentDate = skDate.utc(this.currentDate);
      const dayOptions = [];

      // Can choose from up to 7 days in the past
      for (let i = 0; i < 7; i += 1) {
        skCurrentDate.subtract(1, 'days');

        dayOptions.push({
          id: skCurrentDate.format(),
          text: capitalize(skCurrentDate.format('dddd D MMMM YYYY')),
        });
      }

      return dayOptions;
    },
    isSubmitDisabled() {
      return this.templatesLoading ||
        this.deletingTemplate ||
        (this.isPostesView && this.selectedPosteAndAbsenceIds.length === 0) ||
        (!this.isPostesView && this.selectedUserIds.length === 0) ||
        (this.templateSelected && !this.selectedTemplateId) ||
        this.selectedWeeks.length === 0;
    },
    isSubmitSpinning() {
      return this.conflictsLoading ||
        this.duplicatingPlanning;
    },
    viewName() {
      return this.isDailyView ? 'day' : 'week';
    },
    selectUsersOrPostesDescription() {
      return this.isPostesView ?
        this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_postes.label') :
        this.$t('plannings.toolbar.modal.create_from.select_parameters.body.select_users.label');
    },
    maxSelectableWeeks() {
      return Number(templatesMaxSelectableWeeks);
    },
    /**
     * Number of weeks before and after the current week in the week picker
     * The number is the nearest multiple of 5 from maxSelectableWeeks, rounded up
     ***
     * For instance, if we have maxSelectableWeeks = 7, we want displayedSelectorWeeksLimit = 10
     * */
    displayedSelectorWeeksLimit() {
      return 52; // a year long of weeks
    },
    // weekListPosition upper and lower limit
    maxWeekListPosition() {
      return this.displayedSelectorWeeksLimit / 5;
    },
    isMinWeekListPositionReached() {
      return this.weekListPosition === -this.maxWeekListPosition;
    },
    isMaxWeekListPositionReached() {
      return this.weekListPosition === this.maxWeekListPosition;
    },
  },
  watch: {
    currentDate(newValue) {
      if (!this.isDailyView) return;

      this.selectedPreviousDay = skDate.utc(newValue).subtract(1, 'days').format();
    },
  },
  mounted() {
    if (!this.isDailyView) return;

    // Default value for select is yesterday
    this.selectedPreviousDay = skDate.utc(this.currentDate).subtract(1, 'days').format();
  },
  methods: {
    ...mapActions('planningsTemplates', ['deleteTemplate']),
    dateForWeek(date, weekIndex) {
      const newDate = date.clone();

      if (weekIndex === 0) return newDate;

      return newDate.add(weekIndex, 'isoWeeks');
    },
    calculateMondayForWeek(weekIndex) {
      return this.dateForWeek(this.mondaySkDate, weekIndex);
    },
    calculateSundayForWeek(weekIndex) {
      return this.dateForWeek(this.sundaySkDate, weekIndex);
    },
    mondayForWeek(weekIndex) {
      return this.allSkDates[weekIndex].monday;
    },
    sundayForWeek(weekIndex) {
      return this.allSkDates[weekIndex].sunday;
    },
    nextWeeks() {
      if (this.isMaxWeekListPositionReached) {
        return;
      }

      this.weekListPosition += 1;
    },
    previousWeeks() {
      if (this.isMinWeekListPositionReached) {
        return;
      }

      this.weekListPosition -= 1;
    },
    isWeekSelected(week) {
      return this.selectedWeeks.includes(week);
    },
    selectWeekButtonClass(week) {
      return {
        'pick-week__select-button': true,
        'pick-week__select-button--selected': this.isWeekSelected(week),
        'pick-week__select-button--disabled':
          !this.isWeekSelected(week) && this.selectedWeeks.length === this.maxSelectableWeeks,
      };
    },
    toggleWeek(week) {
      // At most, maxSelectableWeeks weeks can be selected simultaneously
      if (!this.isWeekSelected(week) && this.selectedWeeks.length === this.maxSelectableWeeks) {
        return;
      }

      if (this.isWeekSelected(week)) {
        this.unselectWeek(week);
      } else {
        this.selectedWeeks.push(week);
        this.selectedWeeks.sort((a, b) => a - b);
      }
    },
    unselectWeek(week) {
      const index = this.selectedWeeks.indexOf(week);
      if (index > -1) {
        this.selectedWeeks.splice(index, 1);
      }
    },
    weekNumber(weekIndex) {
      return this.mondayForWeek(weekIndex).isoWeek();
    },
    weekTooltip(weekIndex) {
      const monday = this.mondayForWeek(weekIndex).format('D MMMM');
      const sunday = this.sundayForWeek(weekIndex).format('D MMMM');
      return `${monday} - ${sunday}`;
    },
    selectedWeekDays(weekIndex) {
      const monday = this.mondayForWeek(weekIndex).format('DD/MM');
      const sunday = this.sundayForWeek(weekIndex).format('DD/MM/YYYY');
      return { monday, sunday };
    },
    onClickDeleteTemplate(templateId) {
      const { attributes: { name: templateName } } = this.templates.find(
        ({ attributes }) => attributes.id === templateId,
      );
      this.$root.$emit('confirm', event, {
        title: this.$t('plannings.toolbar.modal.create_from.select_parameters.body.delete_template.title'),
        description:
          `${this.$t('plannings.toolbar.modal.create_from.select_parameters.body.delete_template.description')} <b>${escape(templateName)}</b>.`,
        cancelLabel: this.$t('actions.cancel'),
        submitLabel: this.$t('actions.delete'),
        onConfirm: () => this.handleDeleteTemplate(templateId),
      });
    },
    handleDeleteTemplate(templateId) {
      if (this.anyDeleteTemplatePending) {
        return;
      }

      const params = {
        shop_id: this.currentShop.id,
        template_id: templateId,
        active_planning: this.viewName,
      };

      // If deleted template was selected -> remove selected
      if (this.selectedTemplateId === templateId) {
        this.selectedTemplateId = null;
      }

      this.deleteTemplate(params)
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.create_from.select_parameters.action.delete.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.create_from.select_parameters.action.delete.failure'),
            variant: 'error',
          });
        });
    },
    formatWeekNumber(week) {
      return this.$t('dates.week', { weekNumber: this.weekNumber(week) });
    },
    formatLongWeekNumber(week) {
      return this.$t('dates.full_week', { weekNumber: this.weekNumber(week) });
    },
    handleCancel() {
      this.$emit('cancel-tab');
    },
    handleSubmit() {
      if (this.isSubmitSpinning) return;

      const eventParams = {
        selectedCreateFrom: this.selectedCreateFrom,
        selectedTemplateId: this.selectedTemplateId,
        selectedPreviousWeek: this.selectedPreviousWeek,
        selectedPreviousDay: this.selectedPreviousDay,
        convertToUnassigned: this.convertToUnassigned,
        selectedWeeks: this.selectedWeeks,
      };
      if (this.isPostesView) {
        eventParams.selectedPosteAndAbsenceIds = this.selectedPosteAndAbsenceIds;
      } else {
        eventParams.selectedUserIds = this.selectedUserIds;
      }

      localStorage.setItem(`create_schedule_${this.$route.name}_${this.currentUser.id}_${this.currentShop.id}`, this.selectedCreateFrom);

      this.$emit('submit-tab', eventParams);
    },
    // Called from CreateFromTemplateModal (handleShow)
    resetData() {
      this.selectedCreateFrom = localStorage.getItem(`create_schedule_${this.$route.name}_${this.currentUser.id}_${this.currentShop.id}`) ?? 'previous-week-day';
      this.selectedTemplateId = null;
      this.selectedPreviousWeek = -1;
      // select all users or postes and absences by default
      if (this.isPostesView) {
        this.selectedPosteAndAbsenceIds = this.posteAndAbsenceOptions
          .map(posteOrAbsence => posteOrAbsence.id);
      } else {
        this.selectedUserIds = this.userIds;
        if (this.areUnassignedShiftsAllowed) {
          this.selectedUserIds.push('unassigned');
        }
      }

      this.convertToUnassigned = false;
      this.selectedWeeks = [0];
      this.weekListPosition = 0;
    },
    handleSelectPostesAndAbsences(event) {
      this.selectedPosteAndAbsenceIds = event.selectedPosteAndAbsenceIds;
    },
    updateUsers(userIds) {
      this.selectedUserIds = userIds;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__days-locked__caption {
  color: $sk-error;
  font-weight: $fw-semi-bold;
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.modal__days-locked__text {
  line-height: 22px;
  margin-bottom: -1px;
}

.modal__body__text {
  margin-bottom: 24px;
}

.modal__select-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.modal__separate-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  margin-top: 16px;
  border-top: 1px solid $sk-grey-10;
}

.modal-line__extra-spacing {
  margin-bottom: 26px;
}

.modal__select-line__description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: $fw-semi-bold;
}

.select-line__description-with-icon {
  max-width: 170px;
  margin-right: 14px;
}

.select-line__description__details-icon {
  margin-top: 3px;
}

.select_template__option__container {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.select_template__option__text {
  width: 260px;
  padding-right: 20px;
  box-sizing: border-box;
}

.select_template__option__delete-icon {
  margin-right: 15px;
}

.select-line__pick-week__container {
  display: flex;
  align-items: center;
}

.pick-week__select-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding-top: 10px;
  border-radius: 50%;
  color: $sk-grey;
  background-color: $sk-grey-10;
  text-align: center;
  font-size: $fs-text-m;
  cursor: pointer;

  &--selected {
    color: white;
    background-color: $sk-blue;
  }

  &--disabled {
    color: $sk-grey-30;
    background-color: $sk-grey-10;
    cursor: not-allowed;
  }
}

.pick-week__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;

  &:nth-of-type(1) {
    margin-right: 9px;
  }

  &:nth-last-of-type(1) {
    margin-left: 9px;
  }

  &:hover {
    background-color: $sk-grey-10;
  }
}

.modal__pick-week__results {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 20px 14px 0;
}

.pick-week__results__week-container {
  width: 100%;
  text-align: right;
  margin: 0 24px 6px 0;
  color: $sk-grey;
  font-size: $fs-text-s;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.pick-week__results__week-number {
  display: inline-block;
  font-weight: bold;
}

.pick-week__results__week-days {
  display: inline-block;
  width: 132px;
  margin-left: 7px;
  margin-right: 20px;
  white-space: nowrap;
  text-align: left;
  padding-left: 2px;
}

.pick-week__results__unselect-icon {
  cursor: pointer;
}

.modal__body__spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 322px;
  font-size: 2em;
  color: $sk-blue;
}

.select_template__spinner-wrapper {
  color: $sk-blue;
  line-height: 0;
}

.modal__select-line__weeks_selection {
  width: 320px;
}

.select_template__option__pill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 22px;
  border-radius: 3px;
  background: $sk-grey-10;
  color: $sk-grey;
  font-size: $fs-text-s;
  margin-right: 15px;
}

.modal__body__buttons-wrapper {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px 1px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 6px 6px;
}
</style>
