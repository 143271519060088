<template>
  <div>
    <p class="sk-subtitle--large mt-3">
      {{ $t('organisation_settings.tabs.pay_partners.content') }}
    </p>

    <h2
      v-if="isFortifySilaeEnabled || isCegedimEnabled"
      class="sk-header--2 my-softwares"
    >
      {{ $t('organisation_settings.tabs.pos_partners.my_softwares') }}
    </h2>
    <FortifySilaeCard v-if="isFortifySilaeEnabled" />
    <FortifyCegedimCard v-if="isCegedimEnabled" />
    <MissingPartnerCard
      v-else
      :title="$t('organisation_settings.tabs.pay_partners.missing.title')"
      :subtitle="$t('organisation_settings.tabs.pay_partners.missing.subtitle')"
    >
      <MissingPayPartnerIcon />
    </MissingPartnerCard>

    <h2 class="sk-header--2">
      {{ $t('organisation_settings.tabs.pos_partners.software_list') }}
    </h2>

    <div class="partners-list-row">
      <div
        v-for="partner in partners"
        :key="partner.name"
        class="partners-list-item"
      >
        <SkCard class="pay-partner__wrapper sk-flex-center">
          <div class="partner-card__left-part">
            <div class="partner-card__icon sk-flex-center">
              <component
                :is="partner.component"
                v-bind="partner.props"
              />
            </div>
          </div>
          <div class="partner-card__mid-part">
            <p class="partner-name sk-text-medium-regular">
              {{ partner.name }}
            </p>
            <p class="pay-partner__subtitle sk-subtitle--small sk-text-small-regular">
              {{ $t('organisation_settings.tabs.pay_partners.cards.compatible_evp') }}
            </p>
            <p
              v-if="partner.employeeData"
              class="pay-partner__subtitle sk-subtitle--small sk-text-small-regular"
            >
              {{ $t('organisation_settings.tabs.pay_partners.cards.employee_data') }}
            </p>
          </div>
        </SkCard>
      </div>
    </div>
  </div>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features';

import MissingPartnerCard from '../shared/MissingPartnerCard';

import {
  Adp,
  Cegid,
  Epaye,
  Extalys,
  Inexpaie,
  Payfit,
  Quadratus,
  Sage,
  Silae,
  MissingPayPartnerIcon,
  Cegedim,
} from '../shared/icons/index.js';

import FortifySilaeCard from './FortifySilaeCard';
import FortifyCegedimCard from './FortifyCegedimCard';

export default {
  name: 'PayPartners',
  components: {
    FortifySilaeCard,
    FortifyCegedimCard,
    MissingPartnerCard,
    MissingPayPartnerIcon,
    Adp,
    Cegid,
    Epaye,
    Extalys,
    Inexpaie,
    Payfit,
    Quadratus,
    Sage,
    Silae,
  },
  data() {
    return {
      partners: [
        { name: 'Silae', component: Silae, employeeData: true, props: { width: '32', height: '32' } },
        { name: 'Cegedim', component: Cegedim, employeeData: true, props: { width: '24', height: '25' } },
        { name: 'Sage', component: Sage },
        { name: 'Quadratus', component: Quadratus },
        { name: 'Cegid', component: Cegid },
        { name: 'Payfit', component: Payfit },
        { name: 'e-Paye', component: Epaye },
        { name: 'ADP', component: Adp },
        { name: 'Extalys', component: Extalys },
        { name: 'Inexpaie', component: Inexpaie },
      ],
      isCurrentUserShopLoaded: false,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('organisationCredentials', ['isFetchingCredentials']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['currentUser']),
    isFortifySilaeEnabled() {
      return this.isCurrentUserShopLoaded && this.checkFeatureFlag('FEATURE_FORTIFY_SILAE', this.currentUser.relationships.shop) &&
        this.isFeatureEnabled(
          FEATURES.FEATURE_FORTIFY_SILAE,
          'all',
        );
    },
    isCegedimEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_FORTIFY_CEGEDIM');
    },
  },
  async created() {
    if (!this.currentUser?.relationships?.shop) {
      await this.fetchCurrentUser();
    }

    this.isCurrentUserShopLoaded = true;
  },
  methods: {
    ...mapActions('currentUser', ['fetchCurrentUser']),
  },
};
</script>

<style lang="scss" scoped>
.my-softwares {
  margin-top: 1rem;
}

.partners-list-row {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.partners-list-item {
  width: 33.33%;
  padding: 0 20px 20px 0;

  &:nth-child(3),
  &:nth-child(6),
  &:nth-child(9) {
    padding-right: 0;
  }
}

.partner-name {
  margin-bottom: 4px;
}

.pay-partner__wrapper {
  height: 78px;
  padding: 10px;
}

.pay-partner__subtitle {
  margin-bottom: 0;
}

.partner-card__icon {
  border-radius: 50%;
}

.partner-card__icon {
  height: 50px;
  width: 50px;
  border: 1px solid #ddd;
}

.partner-card__mid-part {
  margin-right: auto;
  padding-left: 1em;
}
</style>
