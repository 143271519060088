import { skelloApiBaseUrl } from '@config/env';

export const redirectToSuperadmin = url => {
  const urlObj = new URL(url);
  // Those query params are added to superadmin to handle reviewapps
  // I need to have the api_host to make request to the correct api
  urlObj.searchParams.set('new_api_host', skelloApiBaseUrl);
  // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
  urlObj.searchParams.set('api_host', window.location.origin);
  // I need to have the app_origin to redirect back to the correct app when impersonate
  urlObj.searchParams.set('app_origin', window.location.origin);

  window.location = urlObj.toString();
};
