<template>
  <div>
    <div class="col-md-4 code-verification">
      <div class="code-verification__header">
        <div class="code-verification__header--title">
          <h1 class="sk-header--1">
            {{ $t('code_verification.form.title') }}
          </h1>
        </div>
        <p class="sk-subtitle--large">
          {{
            $t('code_verification.form.subtitle', { email: prospectEmail })
          }}
        </p>
      </div>
      <div class="code-verification__form">
        <SkInput
          v-model="codeActivation"
          :errored="codeActivationError"
          :error-message="codeActivationErrorMessage"
          :label="$t('code_verification.form.activation_code')"
          @keyup="hideError"
        />
      </div>
      <SkOroraButton
        :loading="loading"
        class="code-verification__submit"
        @click="activateAccount"
      >
        {{ $t('code_verification.form.activate') }}
      </SkOroraButton>
      <SkOroraButton
        class="code-verification__resend"
        variant="tertiary"
        @click="resendMail"
      >
        {{ $t('code_verification.form.resend_mail') }}
      </SkOroraButton>
    </div>
  </div>
</template>
<script>
import {
  SkOroraButton,
  SkInput,
} from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { TokenWebModel } from '@skelloapp/skello-auth-client';

export default {
  name: 'CodeVerification',
  components: { SkOroraButton, SkInput },
  props: {
    prospect: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      codeActivation: '',
      codeActivationError: null,
      codeActivationErrorMessage: '',
      email: this.prospect.attributes.email,
      loading: false,
    };
  },
  computed: {
    prospectEmail() {
      return this.prospect.attributes.email;
    },
  },
  methods: {
    activateAccount() {
      this.$skAnalytics.track('account_creation_user_validation_submit');
      this.loading = true;

      httpClient
        .patch(`/v3/api/users/${this.prospect.id}/validate`, {
          activation_code: this.codeActivation,
        })
        .then(response => {
          this.codeActivationError = false;
          this.codeActivationErrorMessage = '';
          authClient.setAuthToken(new TokenWebModel(
            {
              token: response.data.token,
              refreshToken: response.data.refresh_token,
            },
          ));
          // Go to onboarding app
          this.$router.push({ name: 'organisation_creation' });
        })
        .catch(error => {
          this.codeActivationError = true;

          if (error.response.data.message === 'activation_invalid') {
            this.codeActivationErrorMessage = this.$t('code_verification.error.invalid_code');
          } else if (error.response.data.message === 'activation_expired') {
            this.codeActivationErrorMessage = this.$t('code_verification.error.expired_code');
          } else if (error.response.data.message === 'account_already_validated') {
            this.codeActivationErrorMessage = this.$t('code_verification.error.account_already_validated');
            window.location = '/users/sign_in?account_validated=true';
          } else {
            this.$skToast({
              message: this.$t('organisation_creation.error.generic'),
              variant: 'error',
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hideError() {
      if (this.codeActivationError) {
        this.codeActivationErrorMessage = '';
        this.codeActivationError = false;
      }
    },
    resendMail() {
      httpClient
        .post(`/v3/api/users/${this.prospect.id}/reset_activation_code`)
        .then(() => {
          this.$skToast({
            message: this.$t('code_verification.form.resend_email_success', { email: this.email }),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('organisation_creation.error.generic'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.code-verification__submit {
  width: 83%;
  height: 40px;
  padding: 11.5px 0;
  align-items: center;
  justify-content: center;
}

.code-verification__form {
  margin-bottom: 35px;
}

.code-verification__header--title {
  margin-bottom: 9px;
}

.code-verification {
  margin-top: 130px;
  max-width: 400px;
}

.code-verification__header {
  margin-bottom: 45px;
}

.code-verification__resend {
  margin-top: 30px;
  font-weight: $fw-semi-bold !important;
}
</style>
