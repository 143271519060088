export const FEATURES = {
  FEATURE_NEW_BUNDLE_SYSTEM: 'new_bundle_system',
  FEATURE_TIMECLOCK: 'timeclock',
  FEATURE_PAYROLL: 'payroll', // will be created by Emeric - exists through superadmin option only atm
  FEATURE_PAYSLIPS: 'payslips',
  // Splitting with the ones we're adding with new offer
  // we may be brought to edit the existing ones now that we have an overall view
  FEATURE_ANALYTICS_MODULE: 'analytics_module',
  FEATURE_ANNUALIZATION_COUNTER: 'annualization_counter',
  FEATURE_AVAILABILITY_REQUEST: 'availability_request',
  FEATURE_BADGING: 'badging',
  FEATURE_CAN_ENABLE_MOBILE_PUNCH_CLOCK: 'can_enable_mobile_punch_clock',
  FEATURE_COLLECTIVE_AGREEMENT_ALERT: 'collective_agreement_alert',
  FEATURE_CONTRACTS_AMENDMENTS: 'contracts_amendments',
  FEATURE_CREATE_POSITION: 'create_position',
  FEATURE_DASHBOARD: 'dashboard',
  FEATURE_DOCUMENTS_FOLDERS: 'documents_folders',
  FEATURE_DOCUMENT_TEMPLATES: 'document_templates',
  FEATURE_DOCUMENTS_UPLOAD_PER_USER: 'documents_upload_per_user',
  FEATURE_EMPLOYEE_AUTOMATIC_DPAE: 'employee_automatic_dpae',
  FEATURE_EMPLOYEE_PAYROLL_PREPARATION: 'employee_payroll_preparation',
  FEATURE_ESIGNATURE_ADMINISTRATIVE_DOCS: 'esignature_administrative_docs',
  FEATURE_ESIGNATURE_TIMESHEET: 'esignature_timesheet',
  FEATURE_FORTIFY_SILAE: 'fortify_silae',
  FEATURE_HOLIDAYS_COUNTER: 'holidays_counter',
  FEATURE_HOURS_COUNTER: 'hours_counter',
  FEATURE_INTERMEDIATE_LOCK: 'intermediate_lock',
  FEATURE_KPI_ACTIONS_MENU: 'kpi_actions_menu',
  FEATURE_KPI_PREDICTED_REVENUE_AND_PRODUCTIVITY: 'kpi_predicted_revenue_and_productivity',
  FEATURE_KPI_PREDICTED_ADVANCED: 'kpi_predicted_advanced',
  FEATURE_LEAVE_REQUESTS: 'leave_requests',
  FEATURE_MEAL_RULES: 'meal_rules',
  FEATURE_MONTH_PLANNINGS_PRINT: 'month_plannings_print',
  FEATURE_MULTI_CONTRACTS: 'multi_contracts',
  FEATURE_PARTNER_TOOLS_ADVANCE_SOFTWARE: 'partner_tools_advance_software',
  FEATURE_PARTNER_TOOLS_POS_SOFTWARE: 'partner_tools_pos_software',
  FEATURE_PAYSLIPS_DISPATCH: 'payslips_dispatch',
  FEATURE_PERMANENT_LOCK: 'permanent_lock',
  FEATURE_PLANNING_ACCESS: 'planning_access',
  FEATURE_PLANNING_DAILY_VIEW: 'planning_daily_view',
  FEATURE_PLANNING_OPTIMISATON: 'planning_optimization',
  FEATURE_PLANNING_PUBLICATION: 'planning_publication',
  FEATURE_PLANNING_TEMPLATES: 'planning_templates',
  FEATURE_PLANNING_TEMPLATE_CONFLICTS: 'planning_template_conflicts',
  FEATURE_PLANNING_WEEKLY_EMPLOYEE_VIEW: 'planning_weekly_employee_view',
  FEATURE_PLANNING_WEEKLY_POSITION_VIEW: 'planning_weekly_position_view',
  FEATURE_PTO_COUNTER: 'pto_counter',
  FEATURE_PLANNING_WEEKLY_VIEW_AS_EMPLOYEE: 'planning_weekly_view_as_employee',
  FEATURE_PLANNINGS_UNDO_REDO: 'plannings_undo_redo',
  FEATURE_KPI_REAL_ADVANCE: 'kpi_real_advanced',
  FEATURE_KPI_REAL_REVENUE_AND_PRODUCTIVITY: 'kpi_real_revenue_and_productivity',
  FEATURE_REPORT_PAYROLL_PREPARATION: 'report_payroll_preparation',
  FEATURE_REPORT_SAAS_AND_EXCEL: 'report_saas_and_excel',
  FEATURE_RTT_COUNTER: 'rtt_counter',
  FEATURE_SCHEDULE_PRINT: 'schedule_print',
  FEATURE_SCHEDULE_PRINT_SHIFT_AND_ABSENCE: 'schedule_print_shift_and_absence',
  FEATURE_SHIFT_ACTIVITY_COMMENTS: 'shift_activity_comments',
  FEATURE_SHIFT_ACTIVITY_TASKS: 'shift_activity_tasks',
  FEATURE_SHIFT_POPULAR: 'shift_popular',
  FEATURE_STAFF_REGISTER: 'staff_register',
  FEATURE_UNASSIGNED_SHIFTS: 'unassigned_shifts',
  FEATURE_VARIABLE_CONTRACT_HOURS: 'variable_contract_hours',
  FEATURE_WORKLOAD_PLAN: 'workload_plan',
};
