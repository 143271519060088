<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.195708 519H0V0.347412C12.0695 1.80701 24.0838 4.15853 35.9461 7.41366C98.7595
         24.6503 152.309 65.8516 185.114 122.184C217.919 178.517 227.368 245.496 211.435
         308.762C195.502 372.028 155.455 426.544 99.8804 460.621L77.8075 473.533L0.195708 519Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'BackgroundCornerShape',
  props: {
    fill: {
      type: String,
      default: '#FEBF3F',
    },
    width: {
      type: String,
      default: '183',
    },
    height: {
      type: String,
      default: '438',
    },
    viewBoxHeight: {
      type: String,
      default: '519',
    },
    viewBoxWidth: {
      type: String,
      default: '219',
    },
  },
};
</script>
