<template>
  <router-link
    :to="dayQuery"
    class="item__router-link"
  >
    <div v-if="loading">
      <div class="day-item__skeleton--loading">
        <div class="day-item__skeleton__title--loading" />
        <div class="day-item__skeleton__checkmark--loading" />
      </div>
    </div>
    <div
      v-else
      :class="dayItemClasses"
    >
      <span class="day-item__date">{{ formattedDate }}</span>
      <CheckMarkIcon
        v-tooltip.top="validatedByUsersNames"
        :class="dayItemCheckboxClasses"
        fill=""
        height="24"
        width="24"
        data-test="day-validated__icon"
      />
    </div>
  </router-link>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import { uniq } from '@skello-utils/collection';

export default {
  name: 'DayItem',
  props: {
    day: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('badgings', ['currentDate', 'matchedBadgings', 'badgingsHistory']),
    ...mapGetters('badgings', ['matchedBadgingsPerDay']),
    dayQuery() {
      return { query: { date: this.day, team_ids: this.$route.query.team_ids || [] } };
    },
    dayItemClasses() {
      return {
        'day-item': true,
        'day-item--selected': this.currentDate === this.day,
      };
    },
    dayItemCheckboxClasses() {
      return {
        'day-item__checkbox': true,
        'day-item__checkbox--selected': this.currentDate === this.day,
        'day-item__checkbox--validated-date': this.matchedBadgingsPerDayValidated,
      };
    },
    matchedBadgingsPerDay() {
      // Please, if you modify this, please consider also modifying app/javascript/src/v3/app/shared/store/modules/badgings.js:1149
      if (this.currentShop.id === 'all') {
        return [];
      }

      const formattedDate = this.currentShop.attributes.openingTime === '00:00' ?
        skDate(this.day).subtract(1, 'd').utc(true).format() :
        skDate(this.day).utc(true).format();
      const shopOpeningTime = skDate(this.currentShop.attributes.openingTime, 'HH:mm').subtract(30, 'm').format('HH:mm');

      const shopClosingTime =
        this.currentShop.attributes.openingTime === this.currentShop.attributes.closingTime ?
          skDate(this.currentShop.attributes.closingTime, 'HH:mm').subtract(30, 'm').format('HH:mm') :
          this.currentShop.attributes.closingTime;

      const { openingTime, closingTime } = openingAndClosingTimeAt(
        shopOpeningTime,
        shopClosingTime,
        formattedDate,
      );

      return this.matchedBadgings.filter(matchedBadging => {
        const startsAt = matchedBadging.shift ?
          skDate(matchedBadging.shift.attributes.startsAt).utc() :
          skDate(matchedBadging.badging.in).utc();

        const previsionalStart = matchedBadging.shift ?
          skDate(matchedBadging.shift.attributes.previsionalStart).utc() :
          skDate(matchedBadging.badging.in).utc();

        return !matchedBadging.hasAlreadyOtherShiftValidatedOnDay &&
          (
            (startsAt >= openingTime && startsAt < closingTime) ||
            (previsionalStart >= openingTime && previsionalStart < closingTime)
          );
      });
    },
    matchedBadgingsPerDayValidated() {
      if (this.matchedBadgingsPerDay.length === 0) return false;

      return this.matchedBadgingsPerDay.every(mB => mB.validated);
    },
    dayBadgingsHistory() {
      return this.badgingsHistory.filter(badgingHistory => badgingHistory.date === this.day);
    },
    validators() {
      return uniq(this.dayBadgingsHistory.map(badgingHistory => badgingHistory.validated_by));
    },
    validatedByUsersNames() {
      return this.validators.length > 0 ? [
        this.$t('badgings.sidebar.validated_by'),
        this.validators.join('<br>'),
      ].join('<br>') : null;
    },
    formattedDate() {
      let date = skDate(this.day).format('dddd DD MMMM');

      // Prevent to display too long month name
      if (skDate(this.day).format('MMMM').length > 7) {
        date = skDate(this.day).format('dddd DD MMM');
      }

      const formattedDate = date.charAt(0).toUpperCase() + date.slice(1);
      return formattedDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.day-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  transition: all .125s ease-in-out;

  .day-item__checkbox {
    z-index: 1;
    margin-left: 5px;
    padding: 5px;
    border: 1px solid $sk-grey-10;
    border-radius: 50%;
    background-color: $sk-white;
    fill: $sk-grey-30;

    &.day-item__checkbox__checkmark {
      fill: $sk-grey;
    }

    &.day-item__checkbox--validated-date {
      fill: $sk-white;
      background-color: $sk-success;
      border: 1px solid $sk-success;

      &.day-item__checkbox__checkmark {
        fill: $sk-white;
      }

      &.day-item__checkbox--selected {
        border: .5px solid $sk-success;
      }
    }

    &.day-item__checkbox--selected {
      border: 1px solid $sk-blue-5;
    }
  }

  .day-item__date {
    font-size: $fs-text-m;
    color: $sk-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
    background-color: $sk-grey-10;
  }

  &.day-item--selected {
    background: $sk-blue-5;

    .day-item__date {
      color: $sk-blue;
      font-weight: $fw-semi-bold;
    }
  }
}

@keyframes loadingSkeleton {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

.day-item__skeleton--loading {
  height: 60px;
  width: 100%;
  background-color: $sk-white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  .day-item__skeleton__title--loading {
    height: 24px;
    width: 70%;
    border-radius: 4px;
    background: linear-gradient(-90deg, #ddd 0%, #f7fafd 30%, #ddd 100%);
    animation: loadingSkeleton 1s ease-in-out infinite;
    background-size: 400% 400%;
  }

  .day-item__skeleton__checkmark--loading {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: linear-gradient(-90deg, #ddd 0%, #f7fafd 30%, #ddd 100%);
    animation: loadingSkeleton 1s ease-in-out infinite;
    background-size: 400% 400%;
    animation-delay: .2s;
  }
}
</style>
