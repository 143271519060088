<template>
  <div class="employees-documents__wrapper">
    <template v-if="!isSvcDocumentInMaintenance">
      <div
        v-if="isLoading"
        class="employees-documents__spinner"
      >
        <SkLoader size="large" />
      </div>

      <template v-else>
        <component
          :is="headerComponent"
          :current-folder="currentFolder"
          :all-documents="visibleDocuments"
          :is-feature-documents-folders-enabled="isFeatureDocumentsFoldersEnabled"
          @back-to-root="backToRoot"
        />
        <template>
          <HrisFolders
            v-if="showHrisFolders"
            @go-to-folder="goToFolder"
          />
          <div
            v-else-if="showUpsellComponent"
            class="employees-documents__image-wrapper"
          >
            <FakeFolders />
            <div class="employees-document__image__lock-icon">
              <SkPopover
                as-tooltip
                x-offset="70px"
              >
                <template #anchor>
                  <LockIcon />
                </template>
                <template #content>
                  {{ $t('employees.tabs.documents.sirh_disabled.before_tag') }}
                  <PackTag :type="packType" />
                  {{ $t('employees.tabs.documents.sirh_disabled.after_tag') }}
                  <br><br>
                  {{ $t('employees.tabs.documents.sirh_disabled.second_line') }}
                </template>
              </SkPopover>
            </div>
            <SkOroraButton
              :href="$t('employees.tabs.documents.upsell.link')"
              target="_blank"
              class="employees-documents__upsell-info-link-button"
              variant="tertiary"
              icon-position="right"
              @click="handleClick('info_link')"
            >
              {{ $t('employees.tabs.documents.upsell.info_link') }}
            </SkOroraButton>
            <SkOroraButton
              :icon="buttonIcon"
              :loading="upsellLoading"
              :disabled="upsellRequestSent"
              class="employees-documents__upsell-button"
              icon-position="right"
              @click="handleClick('call_to_action')"
            >
              {{ $t('employees.tabs.documents.upsell.call_to_action') }}
            </SkOroraButton>
          </div>
        </template>

        <NoVisibleDocuments v-if="!hasVisibleDocuments" />
        <template v-else>
          <FilesQuotaBar
            v-if="documentQuotaLimit"
            :quota-used="documentQuotaUsed"
            :quota-limit="documentQuotaLimit"
          />
          <DocumentsTable
            :documents="visibleDocuments"
            :employee="selectedEmployee"
            @delete-document="deleteDocument"
          />
        </template>
      </template>
    </template>

    <template v-else>
      <h2 class="sk-header--2">
        {{ $t('employees.tabs.documents.title') }}
      </h2>
      <Maintenance>
        <h3>{{ $t('employees.tabs.documents.maintenance.title') }}</h3>
        <div>{{ $t('employees.tabs.documents.maintenance.description') }}</div>
      </Maintenance>
    </template>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <doc-esignature-introduction-modal />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import { captureException } from '@sentry/vue';

import { httpClient } from '@skello-utils/clients';
import PackTag from '@app-js/shared/components/PackTag';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import FakeFolders from '@app-js/shared/assets/svg/upsell/FakeFolders';
import LockIcon from '@app-js/shared/assets/svg/upsell/LockIcon';
import Maintenance from '@app-js/shared/components/Maintenance';
import { FEATURES } from '@app-js/shared/constants/features';
import NoVisibleDocuments from './assets/NoVisibleDocuments';
import DocumentsHeader from './Header';
import DocumentsTable from './Table';
import HrisDocumentsHeader from './hris_pack/Header';
import HrisFolders from './hris_pack/Folders';
import FilesQuotaBar from './FilesQuotaBar';
import DocEsignatureIntroductionModal from './esignature/modals/DocEsignatureIntroductionModal';

export default {
  name: 'EmployeeDocuments',
  components: {
    NoVisibleDocuments,
    DocumentsHeader,
    HrisDocumentsHeader,
    HrisFolders,
    DocumentsTable,
    PackTag,
    FakeFolders,
    LockIcon,
    FilesQuotaBar,
    Maintenance,
    DocEsignatureIntroductionModal,
  },
  beforeRouteEnter(to, _from, next) {
    next(vm => vm.updateCurrentFolder(to.query.folder));
  },
  data() {
    return {
      ajaxClient: () => httpClient,
      upsellLoading: false,
      upsellRequestSent: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    ...mapState('employeeDocuments', ['loading', 'currentFolder', 'documents', 'documentsV2']),
    ...mapState('employeeDocuments', {
      loadingDocumentsV2: state => state.loadingDocumentsV2,
    }),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('foldersManagement', ['loadingFetchFolders']),

    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled', 'limitedDocumentTemplates']),
    ...mapGetters('currentLicense', ['canReadHris', 'canReadEmployeeInfo', 'canManageEmployeeDocuments']),
    ...mapGetters('employeeDocuments', ['combinedDocuments', 'documentsInFolder', 'isDocumentV2']),
    ...mapGetters('features', ['isBundleSystemActive', 'isFeatureEnabled']),
    ...mapGetters('selectedEmployee', ['isStrictSubordinateOfCurrentUser', 'isCurrentUserOrSystemAdmin']),
    ...mapGetters('currentOrganisation', ['maxDocumentAllowedPerUser']),
    ...mapGetters('foldersManagement', ['isFoldersManagementEnabled']),
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    documentQuotaLimit() {
      return this.maxDocumentAllowedPerUser;
    },
    documentQuotaUsed() {
      return this.combinedDocuments.length;
    },
    inRootFolder() {
      return !this.currentFolder;
    },
    headerComponent() {
      if (this.canReadHris) {
        return 'HrisDocumentsHeader';
      }

      return 'DocumentsHeader';
    },
    visibleDocuments() {
      // When the shop is using a pack where we have a quota or no folders, we show all documents
      if (this.limitedDocumentTemplates && this.documentQuotaLimit !== null) {
        return this.combinedDocuments; // Docs V1 + Docs V2
      }

      // When we have folders, we want to show the documents in the currentFolder
      // Note : the || '' is to trigger the computed when loading the page on root
      return this.documentsInFolder(this.currentFolder || '');
    },
    hasVisibleDocuments() {
      return this.visibleDocuments && this.visibleDocuments.length !== 0;
    },
    buttonIcon() {
      return this.upsellRequestSent ? 'CheckMarkIcon' : null;
    },
    canAccessDocumentsTab() {
      if (this.isCurrentUserOrSystemAdmin) return true;

      return this.isStrictSubordinateOfCurrentUser &&
        this.canReadEmployeeInfo && this.canManageEmployeeDocuments;
    },
    isSvcDocumentInMaintenance() {
      return this.checkFeatureFlag('MAINTENANCE_SVC_DOCUMENTS');
    },
    isLoading() {
      return this.loading || this.loadingDocumentsV2 || this.loadingFetchFolders;
    },
    isFeatureDocumentsFoldersEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_DOCUMENTS_FOLDERS,
        this.selectedEmployee.attributes.shopId,
      );
    },
    showHrisFolders() {
      return (
        this.canReadHris &&
        this.inRootFolder &&
        this.isFeatureDocumentsFoldersEnabled
      );
    },
    showUpsellComponent() {
      return (
        (!this.isBundleSystemActive(this.currentShop.id) &&
        this.limitedDocumentTemplates &&
        this.documentQuotaLimit !== null) ||
        !this.isFeatureDocumentsFoldersEnabled
      );
    },
  },
  async created() {
    if (!this.canAccessDocumentsTab) {
      this.$router.replace({ name: 'user_personal', params: { user_id: this.selectedEmployee.id } });
    }

    // This doesn't needs to be awaited as it doesn't need to block the page load
    this.fetchOrganisationAdmins();

    await this.fetchDocuments({ employeeId: this.selectedEmployee.id });

    if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2')) {
      await this.fetchDocumentsFromV2({ employeeId: this.selectedEmployee.id });
      await this.fetchDocumentsCreators();
    }

    if (this.isFoldersManagementEnabled) {
      this.fetchFolders({ shopId: this.selectedEmployee.attributes.shopId });
    }
  },
  methods: {
    ...mapMutations('employeeDocuments', ['updateCurrentFolder']),
    ...mapActions('employeeDocuments', ['fetchDocuments', 'deleteDocuments', 'fetchDocumentsFromV2', 'deleteDocumentsV2', 'fetchDocumentsCreators']),
    ...mapActions('employees', ['fetchOrganisationAdmins']),
    ...mapActions('foldersManagement', ['fetchFolders']),
    goToFolder(folder) {
      this.$router.push({ query: { folder } });
      this.updateCurrentFolder(folder);
    },
    backToRoot() {
      this.$router.push({ query: {} });
      this.updateCurrentFolder('');
    },
    openDocument(document) {
      window.open(document.attributes.url, '_blank');
    },
    deleteDocument(event, document) {
      this.$root.$emit('confirm', event, {
        title: this.$t('employees.tabs.documents.delete_confirm'),
        onConfirm: () => {
          const deleteDocumentsFn = this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') && this.isDocumentV2(document.id) ?
            this.deleteDocumentsV2 :
            this.deleteDocuments;

          deleteDocumentsFn({
            employeeId: this.selectedEmployee.id,
            documentId: document.id,
          })
            .then(async () => {
              this.$skToast({
                message: this.$t('employees.tabs.documents.delete_success_message'),
                variant: 'success',
                containerId: 'employees__container',
              });
              this.$skAnalytics.track('delete_document_validate');

              try {
                await this.$svcEvents.create(EVENT_SUBTYPE_ACTION.EMPLOYEE_DOCUMENT_DELETED, {
                  employee: this.selectedEmployee,
                  documentId: document.id,
                  documentName: document.attributes.title,
                });
              } catch (error) {
                captureException(error);
              }
            });
        },
        onCancel: () => {
          this.$skAnalytics.track('delete_document_cancel');
        },
      });
    },
    handleClick(buttonClicked) {
      if (buttonClicked === 'call_to_action') {
        this.upsellLoading = true;
      }
      const params = {
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t(`employees.tabs.documents.upsell.${buttonClicked}`),
          upsell_type: 'hris',
          current_page: this.$t('employees.tabs.documents.upsell.origin'),
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          if (buttonClicked === 'call_to_action') {
            this.upsellLoading = false;
            this.upsellRequestSent = true;
            this.$skToast({
              message: this.$t('employees.tabs.documents.upsell.success'),
              variant: 'success',
              containerId: 'employees__container',
            });
          }
        })
        .catch(() => {
          if (buttonClicked === 'call_to_action') {
            this.upsellLoading = false;
            this.$skToast({
              message: this.$t('employees.tabs.documents.upsell.error'),
              variant: 'error',
              containerId: 'employees__container',
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.employees-document__image__lock-icon {
  position: absolute;
  top: 7px;
  left: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;

  ::v-deep svg {
    height: 70px;
    width: 70px;
  }
}

.employees-documents__wrapper {
  padding: 0 30px 100px;
}

.employees-documents__spinner {
  color: $sk-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 800px;
  height: 300px;
}

.employees-documents__image-wrapper {
  margin: 40px 0 170px;
  height: 200px;
  position: relative;
  width: 100%;

  > svg {
    border: 2px solid $sk-blue-50;
    border-radius: 4px;
    background: #eff4fe;
  }

  .employees-documents__upsell-button {
    position: absolute;
    bottom: -100px;
    left: 300px;
  }

  .employees-documents__upsell-info-link-button {
    position: absolute;
    top: 18px;
    left: 740px;
  }
}
</style>
