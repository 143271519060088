<template>
  <!-- eslint-disable max-len -->
  <table class="employee-amendments-table">
    <thead>
      <tr class="employee-amendments-table__header-row">
        <th
          v-track="'sort_amendment_by_type'"
          :class="tableHeaderClasses"
          class="employee-amendments-table__column__type"
          @click="sort('type')"
        >
          <div class="employee-amendments-table__header__text">
            <div
              v-if="sorting"
              class="employee-amendments-table__header__sorting-arrow-wrapper"
            >
              <SortingArrow
                :sorted-name="currentSorter"
                :direction="arrowDirection"
                name="type"
              />
            </div>
            {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.header.type') }}
          </div>
        </th>
        <th
          v-track="'sort_amendment_by_creation_date'"
          :class="tableHeaderClasses"
          class="employee-amendments-table__column__date-added"
          @click="sort('dateAdded')"
        >
          <div class="employee-amendments-table__header__text">
            <div
              v-if="sorting"
              class="employee-amendments-table__header__sorting-arrow-wrapper"
            >
              <SortingArrow
                :sorted-name="currentSorter"
                :direction="arrowDirection"
                name="dateAdded"
              />
            </div>
            {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.header.date_added') }}
          </div>
        </th>
        <th
          v-track="'sort_amendment_by_effective_period'"
          :class="tableHeaderClasses"
          class="employee-amendments-table__column__effective-period"
          @click="sort('effectivePeriod')"
        >
          <div class="employee-amendments-table__header__text">
            <div
              v-if="sorting"
              class="employee-amendments-table__header__sorting-arrow-wrapper"
            >
              <SortingArrow
                :sorted-name="currentSorter"
                :direction="arrowDirection"
                name="effectivePeriod"
              />
            </div>
            {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.header.effective_period') }}
          </div>
        </th>
        <th class="employee-amendments-table__column__creator">
          {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.header.added_by') }}
        </th>
        <th class="employee-amendments-table__column__contract_hours">
          {{ $t(`employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.header.${contractHoursLabel}`) }}
        </th>
        <th class="employee-amendments-table__column__actions" />
      </tr>
    </thead>

    <tbody>
      <AmendmentTableRow
        v-for="amendment in sortedAmendments"
        :key="amendment.id"
        :amendment="amendment"
        :sorting="sorting"
        @delete-amendment="handleDelete"
      />
    </tbody>
  </table>
  <!-- eslint-enable max-len -->
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

import SortingArrow from './SortingArrow';
import AmendmentTableRow from './AmendmentTableRow';

export default {
  name: 'AmendmentsTable',
  components: { AmendmentTableRow, SortingArrow },
  props: {
    amendments: {
      type: Array,
      required: true,
    },
    sorting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentSorter: 'dateAdded',
      sortingDirection: 'desc',
    };
  },

  computed: {
    ...mapGetters('currentShop', ['arePaidBreaksActivated']),
    ...mapState('selectedEmployee', ['employee']),

    tableHeaderClasses() {
      return {
        'employee-amendments-table-cell--sorting': this.sorting,
      };
    },

    sortedAmendments() {
      return [...this.amendments].sort((amendment1, amendment2) => {
        let cmp = false;
        switch (this.currentSorter) {
          case 'type':
            cmp = this.typeText(amendment1) < this.typeText(amendment2);
            break;
          case 'dateAdded':
            cmp = this.createdAt(amendment1) < this.createdAt(amendment2);
            break;
          case 'effectivePeriod':
            cmp = this.startsAt(amendment1) < this.startsAt(amendment2);
            break;
          default:
            console.error('unsupported sorting key', this.currentSorter);
        }
        const modifier = this.sortingDirection === 'asc' ? 1 : -1;
        return (cmp || amendment2.attributes.activeTemporary ? -1 : 1) * modifier;
      });
    },

    arrowDirection() {
      return this.sortingDirection === 'asc' ? 'down' : 'up';
    },

    arePaidBreaksActivatedForEmployee() {
      return this.arePaidBreaksActivated({
        shop: this.employee.relationships.shop,
        convention: this.employee.relationships.convention,
      });
    },
    contractHoursLabel() {
      return this.arePaidBreaksActivatedForEmployee ?
        'new_contract_hours_presence_time' :
        'new_contract_hours';
    },
  },

  mounted() {
    // Reset the modal sorting options when it is closed
    this.listenOnRoot('EmployeeAmendmentsModal::hidden', this.resetData);
  },

  methods: {
    sort(sorter) {
      if (this.sorting === false) return;

      if (sorter === this.currentSorter) {
        // when clicking on the already sorted column, reverse it
        this.sortingDirection = this.sortingDirection === 'asc' ? 'desc' : 'asc';
      } else {
        // else sort ascendently
        this.sortingDirection = 'asc';
      }
      this.currentSorter = sorter;
    },

    typeText(amendment) {
      const position = amendment.attributes.typePosition < 10 ?
        // handle '10' < 2 comparison
        `0${amendment.attributes.typePosition}` :
        `${amendment.attributes.typePosition}`;

      return amendment.attributes.temporary === true ?
        this.$t('amendments.amendments_table.type_text.temporary',
          { position }) :
        this.$t('amendments.amendments_table.type_text.permanent',
          { position });
    },

    createdAt(amendment) {
      return skDate(amendment.attributes.createdAt).toDate();
    },

    startsAt(amendment) {
      return skDate(amendment.attributes.startsAt).toDate();
    },

    resetData() {
      return Object.assign(this.$data, this.$options.data());
    },

    handleDelete(amendmentId, employeeId, contractId) {
      this.$emit('delete-amendment', amendmentId, employeeId, contractId);
    },
  },
};
</script>

<style lang="scss">
.employee-amendments-table {
  width: 100%;

  thead th {
    padding-top: 30px;
    padding-bottom: 10px;
    height: 0;
  }

  tr {
    height: 60px;
    border-bottom: 1px solid $sk-grey-10;

    &:last-child {
      border-bottom: none;
    }
  }

  td,
  th {
    padding: 7px;

    &.employee-amendments-table-cell--sorting {
      padding-left: 25px;
    }
  }

  th {
    white-space: nowrap;
    font-weight: $fw-regular;
    font-size: $fs-text-m;
    color: $sk-grey;
  }

  th.employee-amendments-table__column__contract_hours {
    max-width: 100px;
    white-space: break-spaces;
  }
}

.employee-amendments-table__column__creator {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-amendments-table__column__actions {
  width: 70px;
}

td.employee-amendments-table__column__type {
  white-space: nowrap;
  font-weight: bold;
}

td.employee-amendments-table__column__type,
th.employee-amendments-table__column__type {
  padding-left: 15px;
}

.employee-amendments-table__column__contract_hours {
  text-align: center;
}

.employee-amendments-table__column__actions {
  opacity: 0;
  text-align: center;
}

.employee-amendments-table tr:hover {
  td {
    background: $sk-grey-5;
  }

  .employee-amendments-table__column__actions {
    opacity: 1;
  }
}

.employee-amendments-table__column__creator {
  white-space: nowrap;
}

.employee-amendments-table__header__sorting-arrow-wrapper {
  position: absolute;
  left: -15px;
}

th.employee-amendments-table-cell--sorting {
  cursor: pointer;
}

.employee-amendments-table__header__text {
  position: relative;
}
</style>
