<template>
  <div class="stripe-box">
    <div class="stripe-box__logo-wrapper">
      <StripeLogo class="stripe-box__logo" />
    </div>
    <div class="stripe-box__checklist-wrap">
      <div class="stripe-box__checklist">
        <div class="stripe-box__checklist-item">
          <BlueCheck class="stripe-box__check" />
          {{ $t('organisation_creation.stripe_box.check1') }}
        </div>
        <div class="stripe-box__checklist-item">
          <BlueCheck class="stripe-box__check" />
          {{ $t('organisation_creation.stripe_box.check2') }}
        </div>
        <div class="stripe-box__checklist-item">
          <BlueCheck class="stripe-box__check" />
          {{ $t('organisation_creation.stripe_box.check3') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import BlueCheck from '../assets/svg/BlueCheck';
import StripeLogo from '../assets/svg/StripeLogo';

export default {
  name: 'StripeBox',
  components: {
    BlueCheck,
    StripeLogo,
  },
};
</script>

<style lang="scss" scoped>
.stripe-box {
  display: flex;
  align-items: center;
  border: 1px solid $sk-grey-10;
}

.stripe-box__logo-wrapper {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
}

.stripe-box__logo {
  max-width: 130px;
}

.stripe-box__check {
  margin-right: 10px;
}

.stripe-box__checklist-wrap {
  width: 50%;
  margin-left: -2em;
  padding-top: 10px;
}

.stripe-box__checklist {
  padding-left: 0;
  margin-bottom: 0;
}

.stripe-box__checklist-item {
  text-align: left;
  margin-bottom: 10px;
  font-size: $fs-text-s;
}
</style>
