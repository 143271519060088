<template>
  <SkOroraDialog
    id="create-amendment-modal"
    ref="createAmendmentModal"
    size="medium"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="loading"
    :submit-button-label="$t(`${amendmentsLocaleKey}.modal.add`)"
    :title="$t(`${amendmentsLocaleKey}.modal.title`)"
    :show-progress-bar="isEmployeeAnnualized"
    :step-count="isEmployeeAnnualized ? 2 : 1"
    :tracking-options="trackingOptions"
    @cancel="resetData"
    @close="resetData"
    @update-step="handleUpdateStep"
    @show="setData"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <AmendmentSetup
        v-if="currentStep === 1"
        ref="amendmentSetup"
        v-model="amendment"
        :employee-annualization-config="employeeAnnualizationConfig"
        :shop-annualization-config="shopAnnualizationConfig"
      />
      <AnnualizationSetup
        v-if="displayAnnualizationSetup"
        v-model="annualizationData"
        :amendment="amendment"
        :shop-annualization-config="shopAnnualizationConfig"
      />
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import {
  getPeriodDatesAt,
  proratedTheoreticalBalance,
  theoreticalBalance,
} from '@skelloapp/skello-annualization';

import skDate from '@skello-utils/dates';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';
import AmendmentSetup from './AmendmentSetup';
import AnnualizationSetup from './AnnualizationSetup';

export default {
  name: 'CreateAmendmentModal',
  components: {
    AnnualizationSetup,
    AmendmentSetup,
  },
  props: {
    employeeAnnualizationConfig: {
      type: Object,
      default: () => ({}),
    },
    trackingOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      amendment: {
        type: 'temporary',
        startsAt: null,
        endsAt: null,
        hours: 0,
        isInvalid: true,
      },
      amendmentsLocaleKey: 'employees.tabs.contracts.full_contract.data.salary.amendments.update_card.create_amendment',
      amendmentTypes: [
        { id: 'temporary', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.temporary') },
        { id: 'permanent', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.permanent') },
      ],
      currentStep: 1,
      annualizationData: {
        // For temporary amendment
        amendmentTheoreticalBalance: 0,
        theoreticalBalanceAfterAmendment: 0,
        // For permanent amendment
        newTheoreticalBalance: 0,
        proratedTheoretical: 0,
      },
      originalAnnualizationData: {
        amendmentTheoreticalBalance: 0,
        theoreticalBalanceAfterAmendment: 0,
        newTheoreticalBalance: 0,
        proratedTheoretical: 0,
      },
    };
  },
  computed: {
    ...mapGetters('amendments', ['hasActivePermanentAmendment', 'activePermanentAmendment']),
    ...mapState('annualization', ['shopAnnualizationConfig']),
    ...mapState('contracts', ['contract']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),

    currentPermanentContractHours() {
      return this.hasActivePermanentAmendment ?
        this.activePermanentAmendment.attributes.hours :
        this.contract.attributes.contractHours;
    },
    displayAnnualizationSetup() {
      return this.currentStep === 2 && this.isEmployeeAnnualized;
    },
    isEmployeeAnnualized() {
      return Object.keys(this.employeeAnnualizationConfig).length > 0;
    },
    isSubmitDisabled() {
      if (this.currentStep === 1) {
        return this.amendment.isInvalid;
      }

      if (this.isTemporary) {
        return !this.annualizationData.amendmentTheoreticalBalance ||
          !this.annualizationData.theoreticalBalanceAfterAmendment;
      }

      return !this.annualizationData.newTheoreticalBalance ||
        !this.annualizationData.proratedTheoretical;
    },
    isTemporary() {
      return this.amendment.type === this.amendmentTypes[0].id;
    },
  },
  methods: {
    ...mapActions('amendments', ['createAmendment', 'fetchAmendments']),
    ...mapActions('selectedEmployee', ['fetchEmployeeAnnualizationConfig']),

    async handleSubmit() {
      this.loading = true;

      try {
        await this.createAmendment({
          amendment: {
            starts_at: this.amendment.startsAt,
            ends_at: this.amendment.endsAt || null,
            hours: this.amendment.hours,
          },
          employee_id: this.employee.id,
          contract_id: this.contract.id,
        });

        if (this.$route.params.contract_id) {
          await this.fetchAmendments({
            employeeId: this.$route.params.user_id,
            contractId: this.$route.params.contract_id,
          });
        }
        if (this.currentStep === 2) {
          await this.updateAnnualizationData();
          this.trackChanges();
        }
        this.$refs.createAmendmentModal.hide();
        this.$skToast({
          message: this.$t(`${this.amendmentsLocaleKey}.modal.success`),
          variant: 'success',
          containerId: 'employees__container',
        });
      } catch (error) {
        console.error(`An error occured when creating an amendment: ${error.message}`);
        this.$skToast({
          message: this.$t('employees.error_message'),
          variant: 'error',
          containerId: 'employees__container',
        });
      } finally {
        this.loading = false;
        this.resetData();
      }
    },
    async updateAnnualizationData() {
      const shopId = this.employee.attributes.shopId;
      const upsertAnnualizationParams = {
        contractHours: this.employeeAnnualizationConfig.contractHours,
        manualChanges: this.employeeAnnualizationConfig.manualChanges,
        proratedTheoreticalBalances: this.employeeAnnualizationConfig.proratedTheoreticalBalances,
        theoreticalBalances: this.employeeAnnualizationConfig.theoreticalBalances,
      };

      const amendmentStartsAt = skDate.utc(this.amendment.startsAt).valueOf();
      if (this.amendment.type === 'permanent') {
        upsertAnnualizationParams.theoreticalBalances = {
          ...upsertAnnualizationParams.theoreticalBalances,
          [amendmentStartsAt]: this.annualizationData.newTheoreticalBalance,
        };
        upsertAnnualizationParams.proratedTheoreticalBalances = {
          ...upsertAnnualizationParams?.proratedTheoreticalBalances,
          [amendmentStartsAt]: this.annualizationData.proratedTheoretical,
        };
        upsertAnnualizationParams.contractHours = {
          ...upsertAnnualizationParams.contractHours,
          [amendmentStartsAt]: this.amendment.hours,
        };
      } else if (this.amendment.type === 'temporary') {
        const amendmentEndsAt = skDate.utc(this.amendment.endsAt).add(1, 'day').valueOf();
        upsertAnnualizationParams.proratedTheoreticalBalances = {
          ...upsertAnnualizationParams?.proratedTheoreticalBalances,
          [amendmentStartsAt]: this.annualizationData.amendmentTheoreticalBalance,
          [amendmentEndsAt]: this.annualizationData.theoreticalBalanceAfterAmendment,
        };
        upsertAnnualizationParams.contractHours = {
          ...upsertAnnualizationParams.contractHours,
          [amendmentStartsAt]: this.amendment.hours,
          [amendmentEndsAt]: this.currentPermanentContractHours,
        };
      }
      if (!upsertAnnualizationParams) return;
      try {
        await svcEmployeesClient.upsertAnnualizationConfig(
          shopId,
          this.employee.id,
          upsertAnnualizationParams,
        );
        await this.fetchEmployeeAnnualizationConfig({ shopId, userId: this.employee.id });
      } catch (updateError) {
        console.error(`Error while updating the annualization configuration: ${updateError.message}`);
        this.$skToast({
          message: this.$t('employees.error_message'),
          variant: 'error',
          containerId: 'employees__container',
        });
      }
    },
    handleUpdateStep(nextStep) {
      this.currentStep = nextStep;

      if (this.currentStep === 2) {
        this.setAnnualizationData();
      }
    },
    trackChanges() {
      this.$skAnalytics.track('annualization_create_contract_amendment');
      const {
        amendmentTheoreticalBalance,
        theoreticalBalanceAfterAmendment,
        newTheoreticalBalance,
        proratedTheoretical,
      } = this.annualizationData;

      const {
        amendmentTheoreticalBalance: originalAmendmentBalance,
        theoreticalBalanceAfterAmendment: originalAfterAmendmentBalance,
        newTheoreticalBalance: originalNewBalance,
        proratedTheoretical: originalProrated,
      } = this.originalAnnualizationData;

      if (this.isTemporary) {
        if (amendmentTheoreticalBalance !== originalAmendmentBalance) {
          this.$skAnalytics.track('annualization_temporary_amendment_edition_base_during_period');
        }

        if (theoreticalBalanceAfterAmendment !== originalAfterAmendmentBalance) {
          this.$skAnalytics.track('annualization_temporary_amendment_edition_base_after_period');
        }
      } else {
        if (newTheoreticalBalance !== originalNewBalance) {
          this.$skAnalytics.track('annualization_amendment_edition_annual_base');
        }

        if (proratedTheoretical !== originalProrated) {
          this.$skAnalytics.track('annualization_amendment_edition_prorated_base');
        }
      }
    },
    resetData() {
      this.$refs.createAmendmentModal.hide();
      this.$refs.amendmentSetup?.resetData();
    },
    setAnnualizationData() {
      if (!this.employee.relationships.shop.attributes.isFrenchShop) {
        return;
      }

      const amendmentStart = skDate.utc(this.amendment.startsAt);
      const amendmentEnd = skDate.utc(this.amendment.endsAt);

      if (this.isTemporary) {
        const period = getPeriodDatesAt(
          amendmentEnd.add(1, 'day'),
          skDate.utc(this.shopAnnualizationConfig.resetDate),
        );

        this.originalAnnualizationData.amendmentTheoreticalBalance = proratedTheoreticalBalance(
          this.amendment.hours,
          amendmentStart.format(),
          amendmentEnd.format(),
        );

        this.originalAnnualizationData.theoreticalBalanceAfterAmendment =
          proratedTheoreticalBalance(
            this.currentPermanentContractHours,
            amendmentEnd.add(1, 'day').format(),
            skDate.utc(period.endDate).format(),
          );
      } else {
        const period = getPeriodDatesAt(
          amendmentStart,
          skDate.utc(this.shopAnnualizationConfig.resetDate),
        );

        this.originalAnnualizationData.newTheoreticalBalance = theoreticalBalance(
          this.amendment.hours,
        );

        this.originalAnnualizationData.proratedTheoretical = proratedTheoreticalBalance(
          this.amendment.hours,
          amendmentStart.format(),
          skDate.utc(period.endDate).add(1, 'day').format(),
        );
      }

      this.annualizationData = { ...this.originalAnnualizationData };
    },
    setData() {
      this.amendment.hours = this.currentPermanentContractHours;
      this.$refs.amendmentSetup.resetData();
    },
  },
};
</script>
