<template>
  <div class="churn-request__page-container">
    <div class="churn-request__sidebar-container">
      <img
        :src="sidebarImg"
        class="churn-request__sidebar-img"
      >
    </div>
    <div
      v-if="displayShopsInfo"
      ref="main-container"
      class="churn-request__main-container"
      @scroll="toggleFullyScrolled()"
    >
      <div class="churn-request__main-container__content">
        <div class="churn-request__main-container__title">
          {{ this.$t('organisation_settings.churn_request.title') }}
        </div>
        <div
          v-if="isLoading"
          class="churn-request__main-container__spinner"
        >
          <SkLoader size="x-large" />
        </div>
        <div v-else>
          <!-- eslint-disable vue/no-v-html-->
          <div
            class="churn-request__main-container__paragraph-subtitle"
          >
            {{ paragraphTitle }}
            <SkOroraLink
              :href="email"
            >
              <span>{{ email }}</span>
            </SkOroraLink>.
          </div>
          <!-- eslint-enable vue/no-v-html-->
          <div class="churn-request__main-container__subtitle">
            {{ this.$t('organisation_settings.churn_request.subtitle') }}
          </div>
          <ChurnShopLine
            v-for="shop in churnShops"
            :key="shop.id"
            :shop="shop"
            @toggle-line="toggleFullyScrolled()"
          />
        </div>
      </div>
      <div
        v-if="!isLoading"
        :class="footerClasses"
      >
        <SkOroraButton
          class="churn-request__main-container__footer__submit"
          @click="createTypeform()"
        >
          {{ this.$t('organisation_settings.churn_request.submit') }}
        </SkOroraButton>
      </div>
    </div>
    <div
      v-else
      :data-tf-widget="widgetId"
      data-tf-opacity="100"
      data-tf-transitive-search-params
      data-tf-medium="snippet"
      :data-tf-iframe-props="formTitle"
      :data-tf-hidden="hiddenFields"
      class="typeform-wrapper"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import {
  svcBillingAutomationClient,
  httpClient,
} from '@skello-utils/clients';
import {
  SkOroraButton,
  SkLoader,
} from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';
import ChurnShopLine from '../../shared/components/ChurnShopLine';
import churnRequestSidebar from '../../shared/img/churn-request-sidebar.svg';

const TYPEFORM_WIDGET_IDS = {
  raul: {
    fr: 'hn2qSGOZ',
    es: 'fO2oB1eG',
    en: 'kiXncyZr',
    de: 'kiXncyZr', // German language uses English form
    it: 'kiXncyZr', // Italian language uses English form
  },
  legacy: {
    fr: 'Tpf9xf1P',
    es: 'SGkQWKdB',
    en: 'Yf2cdUuf',
    de: 'Yf2cdUuf', // German language uses English form
    it: 'Yf2cdUuf', // Italian language uses English form
  },
};

const DEFAULT_TYPEFORM_WIDGET_LANGUAGE = 'en';
const DATE_FORMAT = 'DD MMM yyyy';

export default {
  name: 'ChurnRequest',
  components: {
    ChurnShopLine,
    SkOroraButton,
    SkLoader,
  },
  data() {
    return {
      contractDetails: {},
      contractDetailsFailedWith500: false,
      displayTypeform: false,
      isFullyScrolledBottom: true,
      isLoading: true,
      isLegacyContract: false,
      shops: [],
      sidebarImg: churnRequestSidebar,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    churnShops() {
      if (this.contractType === 'legacy' || this.contractDetailsFailedWith500) {
        return this.shops.map(shop => ({
          legacy: true,
          name: shop.attributes.name,
        }));
      }

      return this.contractDetails.organisation.shops.map(shop => ({
        legacy: false,
        name: shop.name,
        renewalDate: skDate(shop.subscription.renewal_date).format(DATE_FORMAT),
        cancellationRenewalDate: skDate(
          shop.subscription.cancellation_renewal_date,
        ).format(DATE_FORMAT),
        commitment: shop.subscription.commitment,
        cancellationCutoffPeriod: shop.subscription.cancellation_cutoff_period,
      }));
    },
    displayShopsInfo() {
      return !this.displayTypeform;
    },
    isContractLegacy() {
      return (this.contractType === 'legacy' || this.contractDetailsFailedWith500);
    },
    paragraphTitle() {
      if (this.isContractLegacy) {
        return this.$t('organisation_settings.churn_request.title_paragraph_generic');
      }

      const name = this.contractDetails.organisation.owner.name;
      return this.$t(
        'organisation_settings.churn_request.title_paragraph',
        { name },
      );
    },
    email() {
      if (this.isContractLegacy) {
        return 'hello@skello.io';
      }

      return this.contractDetails.organisation.owner.email;
    },
    hiddenFields() {
      const raulOrganisationId = `o.${this.currentOrganisation.id}`;
      const src = 'skelloapp-orga-page';
      const email = this.currentUser.attributes.email;
      return `raul_orga_id=${raulOrganisationId},src=${src},email=${email}`;
    },
    footerClasses() {
      return {
        'churn-request__main-container__footer': true,
        'churn-request__main-container__footer--on-scroll': !this.isFullyScrolledBottom,
      };
    },
    formTitle() {
      return `title=${this.$t('organisation_settings.churn_request.form_title')}`;
    },
    widgetId() {
      return TYPEFORM_WIDGET_IDS[this.contractType][this.locale] ||
        TYPEFORM_WIDGET_IDS[this.contractType][DEFAULT_TYPEFORM_WIDGET_LANGUAGE];
    },
    locale() {
      return this.currentUser.attributes.lang;
    },
    contractType() {
      return this.isLegacyContract ? 'legacy' : 'raul';
    },
  },
  async mounted() {
    if (!this.isSystemAdmin) {
      this.$router.replace('404');
      this.setNavbarVisibility(true);
      return;
    }

    // We need this timeout to make sure the browser has rendered DOM when we check the scroll position
    // A better solution is to use emit on child component and listen to it here
    setTimeout(() => {
      this.toggleFullyScrolled();
    }, 800);

    // If you need to re order these calls, make sure to update isLoading value to false after the last call
    await this.getContractDetails();
    await this.fetchShops();
  },
  created() {
    this.setNavbarVisibility(false);
  },
  methods: {
    ...mapMutations(['setNavbarVisibility', 'setupError']),
    fetchShops() {
      return httpClient
        .get(
          '/v3/api/shops',
          { params: { skip_pagination: true } },
        )
        .then(response => {
          this.shops = response.data.data;
        })
        .catch(error => {
          this.setupError({ error });
          this.setNavbarVisibility(true);

          throw error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getContractDetails() {
      return svcBillingAutomationClient.getContractDetails(this.currentOrganisation.id)
        .then(contractDetails => {
          this.contractDetails = contractDetails;
        })
        .catch(error => {
          if (this.salesForceReturnedA404Error(error.response)) {
            this.isLegacyContract = true;
          } else {
            this.contractDetailsFailedWith500 = true;
          }
        });
    },
    salesForceReturnedA404Error(response) {
      return response.status === 404;
    },
    toggleFullyScrolled() {
      const element = this.$refs['main-container'];

      this.$nextTick(() => {
        this.isFullyScrolledBottom = element.scrollHeight ===
          element.clientHeight + element.scrollTop;
      });
    },
    // Typeform starts the code as soon as the JS is booted
    // This means we need to add the script AFTER the boot of the template by Vue
    createTypeform() {
      // Refreshing Typeform - if already exists, destroy the existing version
      document.getElementById('typeform-embed-code')?.remove();
      const scriptTag = document.createElement('script');
      scriptTag.id = 'typeform-embed-code';
      scriptTag.src = '//embed.typeform.com/next/embed.js';
      document.body.appendChild(scriptTag);

      this.displayTypeform = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.churn-request {
  &__page-container {
    display: flex;
    height: 100vh;
  }

  &__sidebar {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1343px), (max-height: 650px) {
        display: none;
      }
    }

    &-img {
      height: 100vh;
    }
  }

  &__main-container {
    overflow-x: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
      padding: 48px 48px 24px;

      @media (min-width: $md-width) and (max-width: 1343px) {
        padding: 65px 44px 0 40px;
      }

      @media (max-width: $md-width) {
        padding: 65px 20px 0 40px;
      }

      @media (max-width: $sm-width) {
        padding: 65px 20px 0;
      }
    }

    &__title {
      font-size: $fs-display-s;
      font-weight: $fw-semi-bold;
      line-height: 44px;
    }

    &__spinner {
      display: flex;
      justify-content: center;
      padding-top: 40vh;
    }

    &__paragraph-subtitle {
      padding: 24px 0 48px;
      font-size: $fs-text-l;
      line-height: 18px;
      color: $sk-grey-50;
    }

    &__subtitle {
      font-size: $fs-heading-s;
      font-weight: $fw-semi-bold;
      line-height: 22px;
      padding-bottom: 16px;
    }

    &__footer {
      display: flex;
      padding: 16px 0 24px;
      align-items: center;
      justify-content: flex-end;
      position: sticky;
      bottom: 0;
      background-color: white;

      &--on-scroll {
        box-shadow: 0 -6px 12px rgba(0, 0, 0, .1);
      }

      &__submit {
        margin-right: 48px;

        @media (min-width: $md-width) and (max-width: 1343px) {
          margin-right: 48px;
        }

        @media (max-width: $md-width) {
          margin-right: 20px;
        }

        @media (max-width: $sm-width) {
          margin-right: 20px;
        }
      }
    }
  }
}

.typeform-wrapper {
  height: 100vh;
  width: 100vw;
}
</style>
