<template>
  <div
    :class="headerWrapperClasses"
    :style="zoomRange.headerWrapper"
  >
    <div
      class="header__left cell--shadow cell--shadow-right"
      :style="zoomRange.headerSidebar"
    >
      {{ $t('navbar.employees') }}
    </div>
    <div
      ref="headerHoursWrapper"
      class="header__hours-wrapper"
      :style="zoomRange.headerSidebar"
      @mouseenter="handleMouseHover(true)"
      @mouseleave="handleMouseHover(false)"
      @scroll="handleEmitScroll"
    >
      <div class="header__hours header--no-select">
        <HeaderHours
          v-for="(date, index) in currentShopWorkingHours"
          :key="index"
          :header-current-date="date"
          :is-opening-hour="index === 0"
          :is-closing-hour="index === currentShopWorkingHours.length - 1"
        />
        <p
          v-if="isTimeIndicatorShown"
          class="header__time-indicator"
          :style="timeIndicatorStyle"
        >
          {{ timeIndicator }}
        </p>
      </div>
    </div>
    <div class="header__sidebar__wrapper">
      <div
        :class="counterWrapperClasses"
        :style="{ fontSize: zoomRange.headerSidebar.fontSize }"
      >
        {{ $t('plannings.table.header.counters.total') }}
      </div>
      <TotalPeriodTabHeader is-daily-view />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';

import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import {
  openingAndClosingTimeAt,
  workingHours,
} from '@app-js/plannings/shared/utils/planning_helpers';
import TotalPeriodTabHeader from '@app-js/plannings/shared/components/TotalPeriodTabHeader';
import { FEATURES } from '@app-js/shared/constants/features';
import HeaderHours from './HeaderHours';

export default {
  name: 'Header',
  components: { TotalPeriodTabHeader, HeaderHours },
  data() {
    return {
      timeIndicator: skDate().format('HH:mm'),
      timeIndicatorOffset: 0,
      mouseHover: false,
    };
  },
  computed: {
    ...mapState('planningsState', ['displayTotalPeriodTab', 'dayViewPlanningSizeVariables']),

    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('features', ['isFeatureEnabled']),

    headerWrapperClasses() {
      return {
        header__wrapper: true,
        'header--no-select': true,
        'top-separator': this.isWorkloadPlanEnabled,
      };
    },
    isWorkloadPlanEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_WORKLOAD_PLAN, this.currentShop.id);
    },
    currentShopOpeningHours() {
      return openingAndClosingTimeAt(
        this.currentShop.attributes.openingTime,
        this.currentShop.attributes.closingTime,
        skDate().utc(true).format(),
      );
    },
    currentShopWorkingHours() {
      return workingHours(this.currentShop, this.currentDate);
    },
    zoomRange() {
      const {
        headerWrapper,
        headerSidebar,
        headerCounterLinkLeft,
        headerCounterLinkRight,
      } = zoomPlanningWeek(this.planningZoom);
      return {
        headerWrapper,
        headerSidebar,
        headerCounterLinkLeft,
        headerCounterLinkRight,
      };
    },
    timeIndicatorStyle() {
      return {
        left: `${this.timeIndicatorOffset}px`,
      };
    },
    isTimeIndicatorShown() {
      const isTodaySelected =
        this.skSelectedDate.isSame(this.skTodayDate, 'day');
      const isAfterOpeningTime =
        this.skTodayDate.isAfter(this.currentShopOpeningHours.openingTime);
      const isBeforeClosingTime =
        this.skTodayDate.isBefore(this.currentShopOpeningHours.closingTime);

      return isTodaySelected && isAfterOpeningTime && isBeforeClosingTime;
    },
    skTodayDate() {
      return skDate().utc(true);
    },
    skSelectedDate() {
      return skDate(this.currentDate).utc(true);
    },
    counterWrapperClasses() {
      return {
        header__counters__wrapper: true,
        'cell--shadow': !this.displayTotalPeriodTab,
        'cell--shadow-left': !this.displayTotalPeriodTab,
      };
    },
  },
  watch: {
    // Reset scroll when changing shop
    $route() {
      this.$refs.headerHoursWrapper.scrollLeft = 0;
    },
    // Update time indicator position when pixel ratio changes
    dayViewPlanningSizeVariables: {
      deep: true,
      handler() {
        this.setTimeIndicatorOffset();
      },
    },
  },
  mounted() {
    this.listenOnRoot('day-view-scroll', this.handleScroll);

    this.setTimeIndicatorOffset();
    // Time Indicator needs to be synchronized with minute change on three
    // different between Header, Planning and footer
    // setTimeout is used in a way to wait for a minute change to trigger
    // the setInterval loop
    setTimeout(() => {
      setInterval(() => {
        this.updateTime();
      }, 60 * 1000);
    }, (60 - skDate().seconds()) * 1000);
  },
  methods: {
    updateTime() {
      this.timeIndicator = skDate().format('HH:mm');
      this.setTimeIndicatorOffset();
    },
    setTimeIndicatorOffset() {
      const minutesPastFromOpening = skDate().utc(true).diff(this.currentShopOpeningHours.openingTime, 'minutes');
      const startOfQuarterInMinutes = minutesPastFromOpening - (minutesPastFromOpening % 15);
      // Design specification + 8 allows to center in the middle of the quarter hour
      this.timeIndicatorOffset =
        startOfQuarterInMinutes * this.dayViewPlanningSizeVariables.pixelPerMinute + 8;
    },
    handleScroll(event) {
      // Avoid setting scrollLeft on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.headerHoursWrapper === event.srcElement) return;
      this.$refs.headerHoursWrapper.scrollLeft = event.target.scrollLeft;
    },
    handleEmitScroll(event) {
      if (this.mouseHover) {
        this.emitOnRoot('day-view-scroll', event);
      }
    },
    handleMouseHover(value) {
      this.mouseHover = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.header__time-indicator {
  position: absolute;
  bottom: -40px;
  font-size: .9em;
  color: $sk-grey;
}

.header__left {
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.header--no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.header__wrapper {
  width: 100%;
  display: flex;
  height: 60px;
  padding: 0;
  border-bottom: 1px solid $sk-grey-10;

  .top-separator {
    border-top: 1px solid $sk-grey-10;
  }

  .cell--shadow {
    position: relative;
    z-index: 4;
  }
}

.header__hours {
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;

  &-wrapper {
    width: 100%;
    min-height: 55px;
    line-height: 60px;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;

    // Remove the simpleBar
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.header__sidebar__wrapper {
  position: relative;
  flex: 0 0 146px;
}

.header__counters__wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 4;
}

.header__counters__wrapper.cell--shadow-left::after {
  height: 37px !important;
  margin-top: 23px;
}
</style>
