<template>
  <tr>
    <td class="employee-shifts-table__column__date">
      {{ dateText }}
    </td>
    <td
      v-if="isPositionsFeatureEnabled"
      class="employee-shifts-table__column__poste"
    >
      {{ shift.relationships.poste.attributes.name }}
    </td>
    <td class="employee-shifts-table__column__shop">
      {{ shift.relationships.shop.attributes.name }}
    </td>
    <td class="employee-shifts-table__column__start">
      {{ startText }}
    </td>
    <td class="employee-shifts-table__column__end">
      {{ endText }}
    </td>
    <td class="employee-shifts-table__column__pause">
      {{ pauseText }}
    </td>
    <td
      v-if="isShiftsFeatureEnabled"
      class="employee-shifts-table__column__delay"
    >
      {{ delayText }}
    </td>

    <td class="employee-shifts-table__column__note">
      <SkPopover
        v-if="hasNote"
        placement="bottom"
        :title="$t('employees.employee_shifts_table.dropdown_note.note')"
      >
        <template #anchor>
          <span class="employee-shifts-table__dropdown-note__anchor">
            {{ $t('employees.employee_shifts_table.read_note') }}
          </span>
        </template>
        <template #content>
          <div class="employee-shifts-table__dropdown-note__wrapper">
            <p>{{ shift.attributes.note }}</p>
          </div>
        </template>
      </SkPopover>
    </td>

    <td class="employee-shifts-table__column__working-time">
      {{ shiftDurationText }}
    </td>
  </tr>
</template>

<script>
import skDate from '@skello-utils/dates';
import moment from 'moment';
import { FEATURES } from '@app-js/shared/constants/features';
import {
  mapState, mapGetters,
} from 'vuex';

import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
  ABSENCE_TYPE_HOURS,
} from '@app-js/shared/constants/shift';

export default {
  name: 'ShiftTableRow',
  props: {
    shift: {
      type: Object,
      required: true,
    },
    onDayRate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    isPositionsFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_CREATE_POSITION, this.currentShop.id);
    },
    isShiftsFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, this.currentShop.id);
    },
    isAbsence() {
      return !!this.shift.relationships.poste.attributes.absenceType;
    },
    hasNote() {
      return !!this.shift.attributes.note;
    },

    // Displayed texts
    dateText() {
      if (!this.shift.attributes.startsAt) return '-';
      return this.dateToText(this.shift.attributes.startsAt);
    },
    startText() {
      if (this.isAbsence) {
        switch (this.shift.attributes.absenceCalculation) {
          case ABSENCE_TYPE_DAY: return this.$t('employees.employee_shifts_table.dropdown_note.all_day');
          case ABSENCE_TYPE_HALF_DAY: return this.$t('employees.employee_shifts_table.dropdown_note.half_day');
          case ABSENCE_TYPE_HOURS: return this.timeToText(this.shift.attributes.startsAt);
          default: return '-';
        }
      }
      return this.timeToText(this.shift.attributes.startsAt);
    },
    endText() {
      if (this.isAbsence) {
        switch (this.shift.attributes.absenceCalculation) {
          case ABSENCE_TYPE_HOURS: return this.timeToText(this.shift.attributes.endsAt);
          default: return '';
        }
      }
      return this.timeToText(this.shift.attributes.endsAt);
    },
    delayText() {
      const delay = this.shift.attributes.lateness;

      if (!delay || delay === 0) return '-';

      return delay;
    },
    pauseText() {
      const pause = this.shift.attributes.pauseTime;

      if (!pause || pause === 0) return '-';

      return this.secondsToMinutes(pause);
    },
    shiftDurationText() {
      if (this.onDayRate === true) {
        return this.shiftDurationInDaysText;
      }

      return this.shiftDurationInHoursText;
    },
    shiftDurationInHoursText() {
      const shiftDuration = this.shift.attributes.durationInSeconds;

      if (this.isAbsence || !shiftDuration || shiftDuration === 0) return '-';

      return this.secondsToHours(shiftDuration);
    },
    shiftDurationInDaysText() {
      const shiftDuration = this.shift.attributes.durationInDays;

      if (this.isAbsence || !shiftDuration || shiftDuration === 0) return '-';

      return +(shiftDuration).toFixed(2);
    },
  },
  methods: {
    dateToText(date) {
      if (!date) return undefined;

      return skDate(date).utc().format('dddd DD MMMM');
    },
    timeToText(date) {
      if (!date) return undefined;

      return skDate(date).utc().format('HH:mm');
    },
    secondsToMinutes(durationInSeconds) {
      if (!durationInSeconds) return undefined;

      return Math.round(durationInSeconds / 60);
    },
    secondsToHours(durationInSeconds) {
      if (!durationInSeconds) return undefined;

      // Add to use moment because skDate returns moment() as a function
      const durationInHours = moment.duration(durationInSeconds, 's').asHours();
      return `${+durationInHours.toFixed(2)}${this.$t('employees.employee_shifts_table.labels.hours')}`;
    },
    hideDropdownNote() {
      this.$refs.dropdownNote.$refs.popper.doClose();
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  font-size: $fs-text-s;
  white-space: nowrap;
}

.employee-shifts-table__column__poste {
  white-space: normal;
  font-weight: $fw-semi-bold;
}

.employee-shifts-table__dropdown-note__anchor {
  cursor: pointer;
  color: $sk-blue;
}

.employee-shifts-table__dropdown-note__wrapper {
  width: 300px;
  font-size: $fs-text-m;
  white-space: normal;
  text-align: left;
}

</style>
