<template>
  <div>
    <div
      v-if="isLoading"
      class="new-document-template-spinner"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <LeavingChoiceModal
          ref="leavingChoiceModal"
          :save-modal-tag="saveModalTag"
          @save="saveAndLeave"
          @no-save="noSaveAndLeave"
          @cancel="cancelLeave"
        />
      </MountingPortal>
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <save-text-document-template-modal
          ref="saveModal"
          @save="saveTemplateByModal"
        />
      </MountingPortal>
      <MountingPortal
        v-if="isTextDocument"
        mount-to="#modals-portal"
        append
      >
        <save-text-document-modal ref="saveModal" />
      </MountingPortal>
      <MountingPortal
        v-if="isTextDocument"
        mount-to="#modals-portal"
        append
      >
        <text-document-created-modal
          ref="createdModal"
          @submit="goToTextDocumentFolder"
          @sign-document="signDocument"
        />
      </MountingPortal>
      <MountingPortal
        v-if="isTextDocument"
        mount-to="#modals-portal"
        append
      >
        <doc-esignature-upsell-modal
          @cancel="goToTextDocumentFolder"
          @close="goToTextDocumentFolder"
          @continue="showEsignatureUpsellModal"
        />
      </MountingPortal>
      <MountingPortal
        v-if="isTextDocument"
        mount-to="#modals-portal"
        append
      >
        <esignature-upsell-modal
          :initial-step="'PriceStep'"
          :emit-update="true"
          @close="goToTextDocumentFolder"
          @upsell-finished="goToTextDocumentFolder"
          @go-back="showDocumentEsignatureUpsellModal"
        />
      </MountingPortal>
      <MountingPortal
        v-if="document"
        mount-to="#modals-portal"
        append
      >
        <document-send-email-modal
          ref="sendEmailModal"
          :document="document"
          :tracking-options="trackingOptions"
          @submit="goToTextDocumentFolder"
          @hidden="goToTextDocumentFolder"
        />
      </MountingPortal>
      <MountingPortal
        v-if="isTextDocument"
        mount-to="#modals-portal"
        append
      >
        <SignDocumentModal ref="signDocumentModal" />
      </MountingPortal>
      <EditorNavbar
        :title="title"
        :title-editable="titleEditable"
        :save-modal-tag="saveModalTag"
        @title-changed="handleTitleChanged"
        @save="saveTemplate"
      />
      <div>
        <TemplateVariablesPanel v-if="withVariables" />
        <WysiwygEditor
          v-model="body"
          :with-variables="withVariables"
          @first-line-changed="handleFirstLineChanged"
          @variables-replaced.once="resetObjectSerialized"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { isEmptyObject } from '@skello-utils/validators';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import DocumentSendEmailModal from '../../users/pages/documents/hris_pack/DocumentSendEmailModal';
import EditorNavbar from './EditorNavbar';
import TemplateVariablesPanel from './TemplateVariablesPanel';
import WysiwygEditor from './WysiwygEditor';
import LeavingChoiceModal from './LeavingChoiceModal';
import SaveTextDocumentTemplateModal from './SaveTextDocumentTemplateModal';
import SaveTextDocumentModal from './SaveTextDocumentModal';
import TextDocumentCreatedModal from './TextDocumentCreatedModal';
import SignDocumentModal from './sign_document_modal/SignDocumentModal';
import DocEsignatureUpsellModal from '../../shared/components/DocEsignatureUpsellModal';
import EsignatureUpsellModal from '../../plannings/shared/components/Toolbar/EsignatureUpsellModal';

export default {
  name: 'Editor',
  components: {
    EditorNavbar,
    TemplateVariablesPanel,
    WysiwygEditor,
    LeavingChoiceModal,
    SaveTextDocumentTemplateModal,
    SaveTextDocumentModal,
    TextDocumentCreatedModal,
    DocumentSendEmailModal,
    SignDocumentModal,
    DocEsignatureUpsellModal,
    EsignatureUpsellModal,
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'text_document_templates_new' &&
        to.name === 'text_document_templates_edit') {
      // Avoid re-render but change the URI when creating
      window.history.replaceState({}, null, to.path);
      next();
    } else {
      const wrappedNext = (goNext = true) => {
        if (goNext !== false) this.setNavbarVisibility(true);
        next(goNext);
      };
      this.handleLeaving(wrappedNext);
    }
  },
  props: {
    isTextDocument: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialObjectSerialized: null,
      titleManuallyChanged: false,
      titleEditable: !this.isTextDocument,
      withVariables: !this.isTextDocument,
      leavingCallback: null,
    };
  },
  computed: {
    ...mapState('selectedEmployee', {
      employee: state => state.employee,
      loadingEmployee: state => state.loading,
    }),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('textDocumentTemplates', {
      loadingFetch: state => state.loadingFetch,
      loadingNewTextDocument: state => state.loadingNewTextDocument,
      error: state => state.error,
      textDocumentTemplate: state => state.selectedTextDocumentTemplate,
      textDocument: state => state.selectedTextDocument,
    }),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    isLoading() {
      return this.loadingNewTextDocument || this.loadingEmployee;
    },
    object() {
      return this.isTextDocument ? this.textDocument : this.textDocumentTemplate;
    },
    objectSerialized() {
      return this.seralizeObject(this.object);
    },
    objectHasUnsavedChanges() {
      return JSON.stringify(this.objectSerialized) !==
              JSON.stringify(this.initialObjectSerialized);
    },
    document() {
      if (!this.isTextDocument || !this.textDocument ||
          !this.textDocument.relationships) {
        return null;
      }
      return this.textDocument.relationships.document;
    },
    isNew() {
      return !this.object || !this.object.id;
    },
    saveModalTag() {
      if (this.isTextDocument) return 'save-text-document-modal';
      if (this.isNew && !this.titleManuallyChanged) return 'save-text-document-template-modal';
      return null;
    },
    trackingOptions() {
      return {
        submit: 'hris_send_document_post-creation_validate',
        cancel: 'hris_send_document_post-creation_cancel',
      };
    },
    body: {
      get() {
        return this.object ? this.object.attributes.body : null;
      },
      set(body) {
        if (this.object) {
          this.object.attributes.body = body;
        }
      },
    },
    title: {
      get() {
        return this.object ? this.object.attributes.title : null;
      },
      set(title) {
        if (this.object) {
          this.object.attributes.title = title;
        }
      },
    },
  },
  watch: {
    object(object) {
      this.initialObjectSerialized = this.seralizeObject(object);
    },
    error(error) {
      let displayedMessage = error;
      if (error && error.response && error.response.data) {
        const { message, detail, title } = error.response.data;

        if (message) {
          displayedMessage = message;
        } else if (detail instanceof Object) {
          displayedMessage = Object.keys(detail)
            .map(
              field => `${field}: ${
                Array.isArray(detail[field]) ? detail[field].join(', ') : detail[field]
              }`,
            )
            .join('\n');
        } else {
          displayedMessage = detail || title;
        }
      }

      this.$skToast({
        message: displayedMessage,
        variant: 'error',
        dimension: 'short',
      });
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeUnload);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  mounted() {
    // The editor is used to create document templates and text documents
    // isTextDocument is true in Employee Space when creating a text document
    const shopId = this.isTextDocument ? this.employee.attributes.shopId : this.currentShop.id;
    this.fetchTextDocumentTemplates({ cluster_node_id: this.navContext.clusterNodeId,
      shopId });
    this.resetObjectSerialized();
  },
  methods: {
    ...mapActions('selectedEmployee', ['fetchEmployee']),
    ...mapMutations(['setNavbarVisibility']),
    ...mapActions('textDocumentTemplates', [
      'fetchTextDocumentTemplates',
      'createTextDocumentTemplate',
      'updateTextDocumentTemplate',
    ]),
    beforeUnload(event) {
      if (!this.objectHasUnsavedChanges) return;

      const next = leave => {
        if (leave || leave === undefined) return;

        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      };

      // Try the confirmation dialog if the browser don't block it
      // else some like Chrome will display a native popup
      // eslint-disable-next-line consistent-return
      this.handleLeaving(next, true);
    },
    resetObjectSerialized() {
      this.initialObjectSerialized = this.objectSerialized;
    },
    seralizeObject(object) {
      if (!object) return null;

      const { title, body } = object.attributes;
      return { title, body };
    },
    saveTemplateByModal() {
      this.saveTemplate().then(() => {
        // Caution : This was changed to fix a bug on Edge
        // issue link : https://skello.atlassian.net/browse/DEV-8288
        // I don't know why this does make things
        // work, check the diff for further infos
        this.$refs.saveModal.closeModal();
      });
    },
    handleTitleChanged: debounce(function handleTitleChanged(title) {
      this.title = title;
      this.titleManuallyChanged = true;
      if (title && title.length > 0 && !isEmptyObject(this.object.attributes.body)) {
        this.saveTemplate({ title });
      }
    }, 300),
    handleFirstLineChanged: debounce(function handleFirstLineChanged(title) {
      // Auto use the first line as title if none
      if (this.titleEditable && this.title === null) {
        this.title = title;
      }
    }, 1200),
    saveTemplate(limitedParams) {
      const params = limitedParams || {
        title: this.title,
        body: this.body,
      };

      const shopId = this.isTextDocument ?
        this.employee.attributes.shopId : this.currentShop.id;
      const saving = this.isNew ?
        this.createTemplate({ body: this.body, shopId, ...params }) :
        this.updateTemplate({ shopId, ...params });

      return saving.then(() => {
        this.initialObjectSerialized = {
          ...this.initialObjectSerialized,
          ...pick(this.objectSerialized, ...Object.keys(params)),
        };
      });
    },
    createTemplate(params) {
      return this.createTextDocumentTemplate(params)
        .then(response => {
          this.$skToast({
            message: this.$t('text_document_templates.editor.created'),
            variant: 'success',
            dimension: 'short',
          });
          this.$router.replace({
            name: 'text_document_templates_edit',
            params: { text_document_template_id: response.data.data.id },
          });
        });
    },
    updateTemplate(params) {
      return this.updateTextDocumentTemplate({
        ...params,
        id: this.textDocumentTemplate.id,
        creator_id: this.textDocumentTemplate.relationships.creator || this.currentUser.id,
      }).then(() => {
        const message = params.body ?
          this.$t('text_document_templates.editor.updated') :
          this.$t('text_document_templates.editor.title_updated');
        this.$skToast({
          message,
          variant: 'success',
          dimension: 'short',
        });
      });
    },
    handleLeaving(next, nativeModal = false) {
      if (!this.objectHasUnsavedChanges) {
        next();
        return;
      }

      if (nativeModal) {
        // Confirm to leave changes unsaved
        // Needs to be a window.confirm
        // -> triggers the browser's standard "data you entered may not be saved" popup
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(
          this.$t('text_document_templates.editor.leaving_choice_modal.helptext'),
        );

        if (confirm) {
          next();
        } else {
          next(false);
        }
        return;
      }

      this.leavingCallback = next;
      this.$refs.leavingChoiceModal.openModal();
    },
    saveAndLeave() {
      if (this.saveModalTag) {
        this.leavingCallback(false);
        return;
      }

      this.saveTemplate()
        .then(() => { this.leavingCallback(); })
        .catch(error => {
          this.leavingCallback(false);
          throw error;
        });
    },
    noSaveAndLeave() {
      this.leavingCallback();
      this.$refs.leavingChoiceModal.closeModal();
    },
    cancelLeave() {
      this.leavingCallback(false);
    },
    goToTextDocumentFolder() {
      this.goToFolder(this.textDocument.attributes.folder);
    },
    goToFolder(folder) {
      // Redirect user to the employee documents page
      const employeeId = this.$router.currentRoute.query.user_id;
      const routeShopId = this.navContext.shopId || 'all';

      this.$router.push({
        name: 'user_documents',
        params: {
          shop_id: routeShopId,
          user_id: employeeId,
        },
        query: { folder },
      });
    },
    signDocument() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'text-document-created-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'sign-document-modal');
    },
    showEsignatureUpsellModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'doc-esignature-upsell-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'esignature-upsell-modal');
    },
    showDocumentEsignatureUpsellModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'esignature-upsell-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'doc-esignature-upsell-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
.new-document-template-spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}
</style>
