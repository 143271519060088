export const ABSENCE_TYPE_DAY = 'day';
export const ABSENCE_TYPE_HALF_DAY = 'half-day';
export const ABSENCE_TYPE_HOURS = 'hours';
export const ALL_SHIFT_TYPE = 'all';
export const POSTE_SHIFT_TYPE = 'poste';
export const ABSENCE_SHIFT_TYPE = 'absence';
export const WORK_SHIFT_KEY = Object.freeze({
  MAIN: 'main',
  SECONDARY: 'secondary',
});
/**
 * Defines the time window (in hours) used when matching badgings (clock-in events)
 * to scheduled shifts. A badging is considered matching a shift if it occurs within
 * ±2 hours of the scheduled shift start time.
 *
 * Example:
 * For a shift starting at 08:00, any badging from 06:00 to 10:00 will be matched.
 */
export const SHIFT_MATCHING_TIME_WINDOW = 2;
