import { httpClient } from '@skello-utils/clients';
import { FEATURES } from '../../constants/features';

/**
 * @namespace
 */
const initialState = {
  loading: false,
  /**
   * @type {{
   *     [key: number]: {
   *       [key: string]: object
   *     }
   *   }}
   */
  features: {},
  /**
   * @type {boolean}
   */
  featuresFetched: false,
};

/**
 * @type {import('vuex').Module<initialState>}
 */
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setFeatures: (state, newFeatures) => {
      state.features = newFeatures;
    },
    setLoading: (state, newLoading) => {
      state.loading = newLoading;
    },
    setFeaturesFetched: (state, newFeaturesFetched) => {
      state.featuresFetched = newFeaturesFetched;
    },
  },

  getters: {
    // We check that the shop in params has the feature "new_bundle_system" in its features_states
    isBundleSystemActive: (state, _getters, _rootState, rootGetters) => (shopId = 'all') => {
      if (shopId === 'all') return Object.values(state.features).some(shop => FEATURES.FEATURE_NEW_BUNDLE_SYSTEM in shop);

      const shopFeatures = state.features[shopId] || new Set();
      return FEATURES.FEATURE_NEW_BUNDLE_SYSTEM in shopFeatures;
    },
    isFeatureEnabled: (state, _getters, rootState, _rootGetters) => (feature, shopId = 'all', requireAll = false) => {
      /*
      * In the 'all' view, there are three cases:
      * 1. If we are at the organization's head view (i.e., there is no list of shops attached to a specific cluster),
      *    then we check for the feature across all shops.
      * 2. If 'currentNodeShops' is defined and non-empty, then we only consider the shops within the selected cluster.
      * 3. If requireAll is true, then the feature must be enabled in every shop of the considered set (either all shops or only those in the current cluster),
      *       otherwise the check will return false
      */
      if (shopId === 'all') {
        const currentNodeShops = rootState.currentOrganisation.currentNodeShops;
        const shopIdsSet = (Array.isArray(currentNodeShops) && currentNodeShops.length) ?
          new Set(currentNodeShops.map(shop => shop.id)) :
          null;

        const checkFeature = ([id, shopFeatures]) => {
          if (shopIdsSet) {
            return shopIdsSet.has(id) && (feature in shopFeatures);
          }
          return feature in shopFeatures;
        };

        return requireAll ?
          Object.entries(state.features).every(checkFeature) :
          Object.entries(state.features).some(checkFeature);
      }

      if (!state.features[shopId]) return false;
      return feature in state.features[shopId];
    },
    areFeaturesEnabled: (_, getters) => (features, shopId = 'all') => (
      features.every(feature => getters.isFeatureEnabled(feature, shopId))
    ),
    getFeatureLimit: state => (feature, shopId) => {
      const featureState = state.features[shopId][feature];
      return featureState ? (featureState.limit || null) : 0;
    },
    isFeatureEnabledOnAllShops: (state, getters, rootState, _rootGetters) => feature => {
      const currentNodeShops = rootState.currentOrganisation.currentNodeShops;
      const shopIdsSet = (Array.isArray(currentNodeShops) && currentNodeShops.length) ?
        new Set(currentNodeShops.map(shop => shop.id)) :
        null;

      return Object.entries(state.features).every(([id, shopFeatures]) => {
        if (shopIdsSet) {
          return shopIdsSet.has(id) && (feature in shopFeatures);
        }
        return feature in shopFeatures;
      });
    },
    firstShopWithEnabledFeatureState: (state, getters, rootState, _rootGetters) => feature => {
      const currentNodeShops = rootState.currentOrganisation.currentNodeShops;
      const shopIdsSet = (Array.isArray(currentNodeShops) && currentNodeShops.length) ?
        new Set(currentNodeShops.map(shop => shop.id)) :
        null;

      const firstOccurance = Object.entries(state.features).find(([id, shopFeatures]) => {
        if (shopIdsSet) {
          return shopIdsSet.has(id) && (feature in shopFeatures);
        }
        return feature in shopFeatures;
      });
      return firstOccurance && firstOccurance[0];
    },
    anyCounterEnabled: (_, getters) => shopId => [
      FEATURES.FEATURE_ANNUALIZATION_COUNTER,
      FEATURES.FEATURE_HOLIDAYS_COUNTER,
      FEATURES.FEATURE_HOURS_COUNTER,
      FEATURES.FEATURE_PTO_COUNTER,
      FEATURES.FEATURE_RTT_COUNTER,
    ].some(feature => getters.isFeatureEnabled(feature, shopId)),
    anyPartnerToolsFeatureEnabled: (_, getters) => shopId => [
      FEATURES.FEATURE_PARTNER_TOOLS_POS_SOFTWARE,
      FEATURES.FEATURE_PARTNER_TOOLS_ADVANCE_SOFTWARE,
    ].some(feature => getters.isFeatureEnabled(feature, shopId)),
  },
  actions: {
    fetchFeatures: async ({ state, commit }) => {
      if (state.featuresFetched) return Promise.resolve();

      commit('setLoading', true);

      return httpClient.get('/v3/api/features')
        .then(response => {
          commit('setFeatures', response.data.features_by_shop);
        })
        .finally(() => {
          commit('setLoading', false);
          commit('setFeaturesFetched', true);
        });
    },
  },
};
