<template>
  <div class="kpis__real-tab">
    <DatesRow
      :current-shop="currentShop"
      :date="date"
    />
    <div
      ref="realTabContent"
      :class="tableMainContentClasses"
      class="kpis__table-main-content"
    >
      <div
        v-show="kpisLoading"
        class="kpis__read-only__table"
      />
      <slot name="no-planning" />
      <slot name="kpi-pack-offer" />

      <KpiRow
        :date="date"
        :predicted-kpis="getKpisData('revenue', 'predicted')"
        :real-kpis="getKpisData('revenue', 'real')"
        :current-shop="currentShop"
        :label="kpisLabels['revenue'].name"
        :icon-label-tooltip="kpisLabels['revenue'].tooltip"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="revenue"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
        first-row
      />

      <!-- In basic pack offer, the second line is always productivity, other kpis are hidden -->
      <div v-if="showProductivityAtSecondRow">
        <KpiRow
          v-show="canShowKpisFor('productivity')"
          :predicted-kpis="getKpisData('productivity', 'predicted')"
          :real-kpis="getKpisData('productivity', 'real')"
          :current-shop="currentShop"
          :label="kpisLabels['productivity'].name"
          :icon-label-tooltip="kpisLabels['productivity'].tooltip"
          :kpis-feature-states="kpisFeatureStates"
          kpi-name="productivity"
          :unit="`${currentShop.attributes.currencySymbol}/h`"
          label-icon="CircledQuestionMarkIcon"
        />
      </div>

      <KpiRow
        v-show="canShowKpisFor('volume')"
        :date="date"
        :predicted-kpis="getKpisData('volume', 'predicted')"
        :real-kpis="getKpisData('volume', 'real')"
        :current-shop="currentShop"
        :label="kpiVolumeName"
        :icon-label-tooltip="kpisLabels['volume'].tooltip"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="volume"
        label-icon="CircledQuestionMarkIcon"
      />
      <KpiRow
        v-show="canShowKpisFor('average_ticket')"
        :predicted-kpis="getKpisData('average_ticket', 'predicted')"
        :real-kpis="getKpisData('average_ticket', 'real')"
        :current-shop="currentShop"
        :label="kpisLabels['average_ticket'].name"
        :icon-label-tooltip="kpisLabels['average_ticket'].tooltip"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="average_ticket"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
      />
      <KpiRow
        v-show="canShowKpisFor('volume_productivity')"
        :predicted-kpis="getKpisData('volume_productivity', 'predicted')"
        :real-kpis="getKpisData('volume_productivity', 'real')"
        :current-shop="currentShop"
        :label="kpisLabels['volume_productivity'].name"
        :icon-label-tooltip="kpisLabels['volume_productivity'].tooltip"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="volume_productivity"
        unit="/h"
        label-icon="CircledQuestionMarkIcon"
      />
      <KpiRow
        v-show="canShowKpisFor('total_salary_mass', 'salary_mass')"
        :predicted-kpis="getKpisData('total_salary_mass', 'predicted')"
        :real-kpis="getKpisData('total_salary_mass', 'real')"
        :current-shop="currentShop"
        :label="kpisLabels['total_salary_mass'].name"
        :icon-label-tooltip="kpisLabels['total_salary_mass'].tooltip"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="total_salary_mass"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
      />
      <KpiRow
        v-show="canShowKpisFor('total_salary_mass_with_costs', 'salary_mass')"
        :predicted-kpis="getKpisData('total_salary_mass_with_costs', 'predicted')"
        :real-kpis="getKpisData('total_salary_mass_with_costs', 'real')"
        :current-shop="currentShop"
        :label="kpisLabels['total_salary_mass_with_costs'].name"
        :icon-label-tooltip="kpisLabels['total_salary_mass_with_costs'].tooltip"
        :label-collapsable="canDisplaySalaryMassToggle"
        :collapse-state="isSalaryMassCollapseOpen"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="total_salary_mass_with_costs"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
      />
      <div :class="collapseWrapperClasses">
        <KpiRow
          v-show="canShowKpisFor('salary_mass_productive_with_costs', 'salary_mass')"
          :predicted-kpis="getKpisData('productive_with_costs', 'predicted')"
          :real-kpis="getKpisData('productive_with_costs', 'real')"
          :current-shop="currentShop"
          :label="kpisLabels['salary_mass_productive_with_costs'].name"
          :icon-label-tooltip="kpisLabels['salary_mass_productive_with_costs'].tooltip"
          :kpis-feature-states="kpisFeatureStates"
          kpi-name="salary_mass_productive_with_costs"
          :unit="currentShop.attributes.currencySymbol"
          label-icon="CircledQuestionMarkIcon"
        />
        <KpiRow
          v-show="canShowKpisFor('salary_mass_unproductive_with_costs', 'salary_mass')"
          :predicted-kpis="getKpisData('unproductive_with_costs', 'predicted')"
          :real-kpis="getKpisData('unproductive_with_costs', 'real')"
          :current-shop="currentShop"
          :label="kpisLabels['salary_mass_unproductive_with_costs'].name"
          :icon-label-tooltip="kpisLabels['salary_mass_unproductive_with_costs'].tooltip"
          :kpis-feature-states="kpisFeatureStates"
          kpi-name="salary_mass_unproductive_with_costs"
          :unit="currentShop.attributes.currencySymbol"
          label-icon="CircledQuestionMarkIcon"
        />
      </div>
      <KpiRow
        v-show="canShowKpisFor('salary_mass_ratio')"
        :predicted-kpis="getKpisData('salary_mass_ratio', 'predicted')"
        :real-kpis="getKpisData('salary_mass_ratio', 'real')"
        :current-shop="currentShop"
        :label="kpisLabels['salary_mass_ratio'].name"
        :icon-label-tooltip="kpisLabels['salary_mass_ratio'].tooltip"
        :kpis-feature-states="kpisFeatureStates"
        kpi-name="salary_mass_ratio"
        unit="%"
        label-icon="CircledQuestionMarkIcon"
      />
      <div v-if="!showProductivityAtSecondRow">
        <KpiRow
          v-show="canShowKpisFor('productivity')"
          :predicted-kpis="getKpisData('productivity', 'predicted')"
          :real-kpis="getKpisData('productivity', 'real')"
          :current-shop="currentShop"
          :label="kpisLabels['productivity'].name"
          :icon-label-tooltip="kpisLabels['productivity'].tooltip"
          :kpis-feature-states="kpisFeatureStates"
          kpi-name="productivity"
          :unit="`${currentShop.attributes.currencySymbol}/h`"
          label-icon="CircledQuestionMarkIcon"
        />
      </div>
    </div>
    <KpiRow
      :predicted-kpis="getKpisData('worked_hours', 'predicted')"
      :real-kpis="getKpisData('worked_hours', 'real')"
      :number-of-employees="numberOfEmployees"
      :current-shop="currentShop"
      :date="date"
      :label="kpisLabels['worked_hours'].name"
      :icon-label-tooltip="kpisLabels['worked_hours'].tooltip"
      :kpis-feature-states="kpisFeatureStates"
      kpi-name="worked_hours"
      unit="h"
      label-icon="CircledQuestionMarkIcon"
      last-row
    />
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import { mapState } from 'vuex';
import DatesRow from './shared/components/DatesRow';
import KpiRow from './shared/components/KpiRow';

export default {
  name: 'RealTab',
  // eslint-disable-next-line vue/no-unused-components
  components: { KpiRow, DatesRow },
  props: {
    currentShop: {
      type: Object,
      required: true,
    },
    kpisLabels: {
      type: Object,
      required: true,
    },
    predictedKpis: {
      type: Object,
      required: true,
    },
    realKpis: {
      type: Object,
      required: true,
    },
    userKpisSettings: {
      type: Object,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
    kpisLoading: {
      type: Boolean,
      required: true,
    },
    kpisFeatureStates: {
      type: Object,
      required: true,
    },
    kpiVolumeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      simplebar: null,
    };
  },
  computed: {
    ...mapState('planningsKpis', ['isSalaryMassCollapseOpen', 'numberOfEmployees']),

    tableMainContentClasses() {
      return {
        'kpis__table-main-content': true,
        'kpis__table-main-content--loading': this.kpisLoading,
      };
    },
    canDisplaySalaryMassToggle() {
      return this.userKpisSettings.totalSalaryMassWithCosts &&
             (this.userKpisSettings.salaryMassProductiveWithCosts ||
              this.userKpisSettings.salaryMassUnproductiveWithCosts);
    },
    collapseWrapperClasses() {
      return {
        'kpis__salary-mass-collapse__wrapper': true,
        'salary-mass-collapse__show ': this.isSalaryMassCollapseOpen,
      };
    },
    showProductivityAtSecondRow() {
      return Object.values(this.realKpiFeatureStates).filter(kpi => kpi).length === 2;
    },
    realKpiFeatureStates() {
      return this.kpisFeatureStates.real;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.simplebar = new SimpleBar(this.$refs.realTabContent);
    });
  },
  methods: {
    getKpisData(kpiName, predictedOrReal) {
      const kpisData = predictedOrReal === 'predicted' ?
        this.predictedKpis :
        this.realKpis;

      if (kpiName === 'salary_mass_ratio') {
        return kpisData.salary_mass_ratio ?
          kpisData.salary_mass_ratio.salary_mass_ratio_with_costs :
          null;
      }

      if ([
        'total_salary_mass',
        'total_salary_mass_with_costs',
        'productive_with_costs',
        'unproductive_with_costs',
      ].includes(kpiName) && kpisData.salary_mass) {
        return kpisData.salary_mass[kpiName];
      }

      return kpisData[kpiName];
    },
    canShowKpisFor(kpiName, kpiDataKey) {
      const predictedData = kpiDataKey ?
        this.predictedKpis[kpiDataKey] :
        this.predictedKpis[kpiName];

      const realData = kpiDataKey ?
        this.realKpis[kpiDataKey] :
        this.realKpis[kpiName];

      return predictedData && realData && this.userKpisSettings[camelCase(kpiName)];
    },
  },
};
</script>

<style lang="scss" scoped>
.kpis__real-tab {
  width: 100%;
}

.kpis__table-main-content {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 271px;
  overflow-x: hidden;
}

.kpis__read-only__table {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $sk-blue;
  z-index: 1;
}

.kpis__table-main-content--loading {
  opacity: .5;
}

::v-deep .kpis__salary-mass-collapse__wrapper .kpi-label__wrapper {
  padding-left: 5px;
}

.kpis__salary-mass-collapse__wrapper {
  transition: opacity .5s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.kpis__salary-mass-collapse__wrapper.salary-mass-collapse__show {
  opacity: 1;
  height: auto;
}
</style>
