<template>
  <div class="hris-pack__header">
    <div :class="firstCrumbClass">
      <FolderIcon
        :fill="houseFill"
        class="hris-pack__header__house"
      />
      <a
        class="hris-pack__header__title ml-3"
        @click="$emit('back-to-root')"
      >
        {{ $t('employees.tabs.documents.title') }}
      </a>
      <div class="hris-pack__header__pack-tag">
        <PackTag
          v-if="!hasShopDocumentTemplatesAccess(selectedEmployeeShopId)"
          type="success"
          shadow
        />
        <PackTag
          v-else-if="(!isBundleSystemActive(currentShop.id) && documentQuotaLimit !== null) ||
            !isFeatureDocumentsFoldersEnabled"
          type="premium"
          shadow
        />
      </div>
    </div>
    <CaretIcon
      v-if="!inRootFolder"
      class="hris-pack__header__caret"
    />
    <div class="hris-pack__header__second-crumb">
      {{ secondCrumb }}
    </div>
    <div
      v-if="canCreateDocument"
      class="hris-pack__header__actions"
    >
      <div>
        <SkPopover
          :disabled="!quotaReached"
          placement="top-end"
          as-tooltip
        >
          <template #anchor>
            <SkOroraButton
              v-modal.create-document-modal
              :disabled="quotaReached"
              :fill="quotaReached ? null : 'outlined'"
              type="information"
              variant="secondary"
              icon="UploadV2Icon"
              class="hris-pack__header__import-btn"
            >
              {{ $t('employees.tabs.documents.add_document') }}
              <MountingPortal
                mount-to="#modals-portal"
                append
              >
                <create-document-modal :tracking-options="trackingOptionsDocument" />
              </MountingPortal>
            </SkOroraButton>
          </template>
          <template #content>
            <template v-if="documentQuotaLimit !== 0">
              {{ $t('employees.tabs.documents.quota_reached.before_tag') }}
              <PackTag :type="packType" />
              {{
                $t(
                  'employees.tabs.documents.quota_reached.after_tag',
                  { max_documents: documentQuotaLimit }
                )
              }}
              <br>
              <br>
              {{ $t('employees.tabs.documents.quota_reached.second_line') }}
            </template>
            <template v-else>
              {{ $t('employees.tabs.documents.documents_not_allowed.before_tag') }}
              <PackTag :type="packType" />
              {{ $t('employees.tabs.documents.documents_not_allowed.after_tag') }}
              <br>
              <br>
              {{ $t('employees.tabs.documents.documents_not_allowed.second_line') }}
            </template>
          </template>
        </SkPopover>
      </div>
      <SkPopover
        v-if="canCreateDocumentsFromTemplate"
        data-test="create-text-document-button"
        :disabled="!quotaReached"
        placement="left"
        as-tooltip
      >
        <template #anchor>
          <SkOroraButton
            v-modal.create-text-document-modal
            :disabled="quotaReached"
            type="information"
            icon="PlusSignV2Icon"
            class="hris-pack__header__create-btn"
          >
            {{ $t('employees.tabs.documents.add_text_document') }}
            <MountingPortal
              mount-to="#modals-portal"
              append
            >
              <create-text-document-modal
                :folder="currentFolder"
                :tracking-options="trackingOptionsTextDocument"
              />
            </MountingPortal>
          </SkOroraButton>
        </template>
        <template #content>
          <template v-if="documentQuotaLimit !== 0">
            {{ $t('employees.tabs.documents.quota_reached.before_tag') }}
            {{
              $t(
                'employees.tabs.documents.quota_reached.after_tag',
                { max_documents: documentQuotaLimit }
              )
            }}
            <br>
            <br>
            {{ $t('employees.tabs.documents.quota_reached.second_line') }}
          </template>
          <template v-else>
            {{ $t('employees.tabs.documents.documents_not_allowed.before_tag') }}
            {{ $t('employees.tabs.documents.documents_not_allowed.after_tag') }}
            <br>
            <br>
            {{ $t('employees.tabs.documents.documents_not_allowed.second_line') }}
          </template>
        </template>
      </SkPopover>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features.js';
import PackTag from '@app-js/shared/components/PackTag';
import CreateDocumentModal from '../CreateDocumentModal/index.vue';
import CreateTextDocumentModal from '../../../shared/components/CreateTextDocumentModal';

export default {
  name: 'HrisHeader',
  components: {
    CreateDocumentModal,
    CreateTextDocumentModal,
    PackTag,
  },
  props: {
    currentFolder: {
      type: String,
      default: '',
    },
    isFeatureDocumentsFoldersEnabled: {
      type: Boolean,
      default: false,
    },
    allDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('employeeDocuments', ['documents']),
    ...mapState('selectedEmployee', {
      selectedEmployeeShopId: state => state.employee.attributes.shopId,
    }),
    ...mapGetters('currentLicense', [
      'canManageEmployeeDocuments',
      'canManageHris',
      'canUploadSelfDocuments',
      'isSystemAdmin',
    ]),
    ...mapGetters('selectedEmployee', [
      'isStrictSubordinateOfCurrentUser',
      'isCurrentUser',
    ]),
    ...mapGetters('currentOrganisation', ['maxDocumentAllowedPerUser']),
    ...mapGetters('currentShop', ['hasShopDocumentTemplatesAccess']),
    ...mapGetters('features', ['isBundleSystemActive']),
    ...mapGetters('foldersManagement', ['folderName']),
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    documentQuotaLimit() {
      return this.maxDocumentAllowedPerUser;
    },
    documentQuotaUsed() {
      return this.allDocuments.length;
    },
    quotaReached() {
      // If quota is infinite, it returns null
      if (this.documentQuotaLimit === null) {
        return false;
      }

      return this.documentQuotaUsed >= this.documentQuotaLimit;
    },
    canCreateDocument() {
      const canCreateOwnDocuments = this.isCurrentUser && this.canUploadSelfDocuments;

      return canCreateOwnDocuments ||
        this.isSystemAdmin ||
        (this.canManageEmployeeDocuments && this.isStrictSubordinateOfCurrentUser);
    },
    canCreateDocumentsFromTemplate() {
      return this.canManageHris &&
        this.hasShopDocumentTemplatesAccess(this.selectedEmployeeShopId);
    },
    inRootFolder() {
      return this.currentFolder === '';
    },
    firstCrumbClass() {
      return {
        'hris-pack__header__first-crumb': true,
        'hris-pack__header__first-crumb--inactive': !this.inRootFolder,
      };
    },
    houseFill() {
      return this.inRootFolder ? '#2b66fe' : '#727272';
    },
    secondCrumb() {
      if (this.inRootFolder) return '';

      return this.folderName(this.currentFolder);
    },
    trackingOptionsDocument() {
      return {
        submit: 'hris_upload_file_validate',
      };
    },
    trackingOptionsTextDocument() {
      return {
        cancel: 'hris_template_cancel_creation',
        submit: 'hris_template_create',
      };
    },
  },
};
</script>

<style lang="scss">
.hris-pack__header {
  border-bottom: 1px solid $sk-grey-10;
  margin-top: -40px;
  height: 70px;
  display: flex;
  align-items: center;
}

.hris-pack__header__house {
  margin-top: 3px;
}

.hris-pack__header__pack-tag {
  margin-left: 20px;
}

.hris-pack__header__first-crumb {
  display: flex;

  a {
    color: $sk-black;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hris-pack__header__first-crumb--inactive {
  a {
    color: $sk-grey-30;
  }
}

.hris-pack__header__second-crumb {
  font-weight: bold;
  font-size: $fs-text-l;
}

.hris-pack__header__caret {
  margin: 0 20px;
  transform: rotate(270deg);
}

.hris-pack__header__actions {
  display: flex;
  margin-left: auto;
}

.hris-pack__header__import-btn {
  margin-right: 10px;
}

.hris-pack__header__title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $fs-text-l;
}
</style>
