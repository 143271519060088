<template>
  <div class="kpis-plannings__wrapper">
    <div
      :class="hideBackgroundClass"
      @click="handleClickOnPage"
    >
      <transition name="slide-fade">
        <div
          id="kpis__wrapper"
          :class="kpiWrapperClasses"
        >
          <!-- KPI tooltip to compare predicted / real -->
          <!-- eslint-disable max-len -->
          <template v-if="isPerformanceTooltip">
            <AnalyticsTooltip
              v-show="!!tooltipData"
              id="kpis__tooltip"
              :tooltip-data="tooltipData"
              :tooltip-type="tooltipType"
            />
          </template>
          <template v-else>
            <UpsellTooltip
              v-show="!!tooltipData"
              id="kpis__tooltip"
              :tooltip-type="tooltipType"
              :pack-limitation-text="$t('kpis.pack_offers.tooltip.current_pack_limit_performence_tooltip')"
              @mouse-enter="onTooltipEnter"
              @mouse-leave="resetTooltipData"
            />
          </template>
          <!-- eslint-enable max-len -->

          <!-- KPI open buttons (round then larger) -->
          <div
            v-if="displayKpisOpenButton"
            :class="sectionContainerActiveClass"
            class="kpis__button-container"
            @mouseleave="toggleButtons"
          >
            <div
              v-if="!allKpisTableOpen"
              @click.stop="openKpisModule"
            >
              <span
                v-if="showRoundButton && canEditManagementIndicators"
                @mouseover="toggleButtons"
              >
                <div class="kpis__round-button">
                  <ChartIcon fill="#FFFFFF" />
                </div>
              </span>
              <span v-if="showLargeButton">
                <div class="kpis__large-button">
                  <div class="kpis__round-button">
                    <ChartIcon fill="#FFFFFF" />
                  </div>
                  <div class="kpis__large-button-text">
                    {{ $t('kpis.buttons.open') }}
                  </div>
                </div>
              </span>
            </div>
          </div>

          <!-- KPI module closed (only worked hours) -->
          <div
            v-if="!allKpisTableOpen"
            class="kpis__sticky-section__container"
          >
            <div
              v-if="kpisLoading || userKpisSettingsLoading || posIntegrationsLoading"
              class="kpis__spinner"
            >
              <SkLoader size="medium" />
            </div>

            <KpiRow
              v-else
              :number-of-employees="numberOfEmployees"
              :predicted-kpis="kpis.real.worked_hours"
              :current-shop="currentShop"
              :label="kpisLabels['worked_hours'].name"
              :icon-label-tooltip="kpisLabels['worked_hours'].tooltip"
              :kpis-feature-states="kpisFeatureStates"
              :date="date"
              unit="h"
              title="WorkedHours"
              kpi-name="worked_hours"
              predicted
            />
          </div>

          <!-- KPI module opened -->
          <div
            v-if="allKpisTableOpen"
            ref="sectionContainer"
            :class="sectionContainerActiveClass"
            class="kpis__section-container"
            @mouseover="onMouseOverContent"
            @mouseup="onMouseUpOnContent"
          >
            <!-- KPI close button -->
            <div
              class="kpis__button-close"
              @click.stop="closeKpisModule"
            >
              <ClosingXIcon
                class="kpis__close-icon"
                fill="#fff"
              />
              <div class="kpis__button-close-text">
                {{ $t('kpis.buttons.close') }}
              </div>
            </div>

            <div class="kpis__header">
              <div class="kpis__tab-wrapper">
                <li class="kpis__tab-navigation">
                  <ul
                    :class="tabClasses('PredictedTab')"
                    @click.stop="selectTab('PredictedTab')"
                  >
                    <PackOfferTooltip
                      v-if="areAllPredictedRowsDisabled"
                      :pack-limitation-text="
                        $t('kpis.pack_offers.tooltip.current_pack_limit_previsional_tab')
                      "
                      placement="left"
                    >
                      <!-- Triggers tooltip -->
                      {{ $t('kpis.tabs.predicted') }}
                    </PackOfferTooltip>
                    <span v-else>
                      {{ $t('kpis.tabs.predicted') }}
                    </span>
                  </ul>
                  <ul
                    :class="tabClasses('RealTab')"
                    @click.stop="selectTab('RealTab')"
                  >
                    {{ $t('kpis.tabs.real') }}
                  </ul>
                </li>
              </div>

              <span class="kpis__section-title">
                {{ $t('kpis.title') }}
              </span>

              <!-- Options Menu -->
              <div
                ref="settingsWrapper"
                class="kpis__section-settings-wrapper"
              >
                <PackOfferTooltip
                  v-if="!isKpiActionsMenuEnabled"
                  :pack-limitation-text="
                    $t('kpis.pack_offers.tooltip.current_pack_limit_actions')
                  "
                  placement="top"
                >
                  <!-- Triggers tooltip -->
                  <div class="kpis__section-settings-round-button">
                    <KebabIcon />
                  </div>
                </PackOfferTooltip>

                <div
                  v-else
                  class="kpis__section-settings-round-button"
                  @click.stop="toggleSettingsDropdown"
                >
                  <KebabIcon />
                </div>

                <div
                  v-if="showSettingsDropdown"
                  class="kpis__section-settings-dropdown"
                >
                  <!-- Display options -->
                  <div
                    class="kpis__section-settings-dropdown-content"
                    @click.stop="openOptionsModal"
                  >
                    <div class="kpis__section-settings-dropdown-icon">
                      <OpenEyeIcon fill="#727272" />
                    </div>
                    <div class="kpis__section-settings-dropdown-text">
                      {{ $t('kpis.dropdown.labels.options') }}
                    </div>
                  </div>

                  <!-- Duplicate previous week -->
                  <div
                    class="kpis__section-settings-dropdown-content"
                    @click.stop="openDuplicateModal"
                  >
                    <div class="kpis__section-settings-dropdown-icon">
                      <FilesIcon fill="#727272" />
                    </div>
                    <div class="kpis__section-settings-dropdown-text">
                      <div class="kpis__section-settings-dropdown-text__label">
                        {{ $t('kpis.dropdown.labels.duplicate.label') }}
                      </div>
                      <div class="kpis__section-settings-dropdown-text__subtitle">
                        {{ $t('kpis.dropdown.labels.duplicate.subtitle') }}
                      </div>
                    </div>
                  </div>

                  <!-- Export current week -->
                  <div
                    class="kpis__section-settings-dropdown-content"
                    @click.stop="exportCurrentWeek"
                  >
                    <div class="kpis__section-settings-dropdown-icon">
                      <DownloadArrowIcon fill="#727272" />
                    </div>
                    <div class="kpis__section-settings-dropdown-text">
                      {{ $t('kpis.dropdown.labels.export') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PredictedTab
              v-if="showPredictedTab"
              :current-shop="currentShop"
              :kpis-labels="kpisLabels"
              :predicted-kpis="kpis.predicted"
              :user-kpis-settings="userKpisSettings"
              :date="date"
              :kpis-loading="kpisLoading"
              :kpis-feature-states="kpisFeatureStates"
              :kpi-volume-name="kpiVolumeName"
            >
              <template
                v-if="displayPlanningOverlay('PredictedTab')"
                #kpi-pack-offer
              >
                <PackOfferOverlay
                  v-show="isPackOfferOverlayDisplayed"
                  :in-line="inLinePackOfferOverlay"
                  tab="PredictedTab"
                />
              </template>
            </PredictedTab>
            <RealTab
              v-if="showRealTab"
              :current-shop="currentShop"
              :kpis-labels="kpisLabels"
              :predicted-kpis="kpis.predicted"
              :user-kpis-settings="userKpisSettings"
              :real-kpis="kpis.real"
              :date="date"
              :kpis-loading="kpisLoading"
              :kpis-feature-states="kpisFeatureStates"
              :kpi-volume-name="kpiVolumeName"
            >
              <template
                v-if="displayPlanningOverlay('RealTab')"
                #kpi-pack-offer
              >
                <PackOfferOverlay
                  v-show="isPackOfferOverlayDisplayed"
                  :in-line="inLinePackOfferOverlay"
                  tab="RealTab"
                />
              </template>
            </RealTab>
            <OptionsModal
              v-show="showOptionsModal"
              ref="optionsModal"
              :close="closeOptionsModal"
            />
            <DuplicateModal
              v-if="showDuplicateModal"
              ref="duplicateModal"
              :predicted-kpis="kpis.predicted"
              :current-shop="currentShop"
              :date="date"
              :close="closeDuplicateModal"
              :user-kpis-settings="userKpisSettings"
              :kpi-volume-name="kpiVolumeName"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// utils
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { createPopper } from '@popperjs/core';
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

// custom components
import { FEATURES } from '@app-js/shared/constants/features';
import AnalyticsTooltip from '@app-js/shared/components/Tooltips/AnalyticsTooltip';
import UpsellTooltip from '@app-js/shared/components/Tooltips/UpsellTooltip';
import DuplicateModal from './shared/components/DuplicateModal';
import OptionsModal from './shared/components/OptionsModal';
import KpiRow from './shared/components/KpiRow';
import PackOfferOverlay from './shared/components/PackOfferOverlay';
import PackOfferTooltip from './shared/components/PackOfferTooltip';
import PredictedTab from './PredictedTab';
import RealTab from './RealTab';

const TAB_NAMES = ['PredictedTab', 'RealTab'];

export default {
  name: 'Kpis',
  components: {
    DuplicateModal,
    AnalyticsTooltip,
    OptionsModal,
    PackOfferTooltip,
    PackOfferOverlay,
    PredictedTab,
    RealTab,
    KpiRow,
    UpsellTooltip,
  },
  props: {
    tabOpenedByDefault: {
      type: String,
      required: false,
      default: null,
      validator: tabName => TAB_NAMES.includes(tabName),
    },
    fetchKpisParams: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      popper: null,
      popperDelayId: null,
      tooltipResetDelayId: null,
      tooltipData: null,
      tooltipType: null,
      upsellRequested: false,
      allKpisTableOpen: false,
      selectedTabName: null,
      showDuplicateModal: false,
      showLargeButton: false,
      showOptionsModal: false,
      showRoundButton: true,
      showSettingsDropdown: false,
      posIntegrationsLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsKpis', [
      'userKpisSettings',
      'userKpisSettingsLoading',
      'kpisLoading',
      'kpis',
      'numberOfEmployees',
    ]),
    ...mapGetters('planningsState', ['monday', 'isPostesView']),
    ...mapGetters('features', ['isFeatureEnabled']),

    date() {
      return skDate(this.monday);
    },
    showPredictedTab() {
      return this.isKpiPredictedRevenueAndProductivityFeatureStateEnabled && this.isSelected('PredictedTab');
    },
    showRealTab() {
      return this.isKpiRealRevenueAndProductivityFeatureStateEnabled && this.isSelected('RealTab');
    },
    isKpiPredictedRevenueAndProductivityFeatureStateEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_KPI_PREDICTED_REVENUE_AND_PRODUCTIVITY,
        this.currentShop.id,
      );
    },
    isKpiPredictedAdvancedFeatureStateEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_KPI_PREDICTED_ADVANCED,
        this.currentShop.id,
      );
    },
    isKpiRealRevenueAndProductivityFeatureStateEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_KPI_REAL_REVENUE_AND_PRODUCTIVITY,
        this.currentShop.id,
      );
    },
    isKpiRealAdvancedFeatureStateEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_KPI_REAL_ADVANCE,
        this.currentShop.id,
      );
    },
    canEditManagementIndicators() {
      return this.currentLicense.attributes.canEditManagementIndicators;
    },
    isPerformanceTooltip() {
      return this.tooltipType === 'performance';
    },
    kpisLabels() {
      return this.$t('kpis.attributes');
    },
    kpiVolumeName() {
      // When we load the module
      // If the user has a saved volumeName
      // fallback => default value
      return this.currentShop.attributes.kpiVolumeName || this.$t('kpis.attributes.volume.name');
    },
    visibleKpisRows() {
      const kpiRows = { ...this.userKpisSettings };

      // We dont want these nested rows to impact the view mode
      delete kpiRows.salaryMassProductiveWithCosts;
      delete kpiRows.salaryMassUnproductiveWithCosts;

      // Visible KPIS depend on the userKpisSettings
      return Object.values(kpiRows).filter(settingValue => !!settingValue).length;
    },
    kpiWrapperClasses() {
      return {
        kpis__container: true,
        'kpis__content--unselectable': true,
      };
    },
    sectionContainerActiveClass() {
      return {
        'kpis__section-container--active': this.allKpisTableOpen,
      };
    },
    hideBackgroundClass() {
      // this class aims to put the grey overlay when kpis module is open
      return {
        'hide-background__wrapper': this.allKpisTableOpen,
      };
    },
    enabledKpisCount() {
      return Object.values(this.kpisFeatureStates.predicted).filter(kpi => kpi).length;
    },
    isKpiActionsMenuEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_KPI_ACTIONS_MENU, this.currentShop.id);
    },
    areAllPredictedRowsDisabled() {
      return this.enabledKpisCount === 0;
    },
    kpisFeatureStates() {
      return {
        predicted: this.translatedPredictedKpisFeatureStates,
        real: this.translatedRealKpisFeatureStates,
      };
    },
    translatedPredictedKpisFeatureStates() {
      return {
        revenue: this.isKpiPredictedRevenueAndProductivityFeatureStateEnabled,
        volume: this.isKpiPredictedAdvancedFeatureStateEnabled,
        average_ticket: this.isKpiPredictedAdvancedFeatureStateEnabled,
        volume_productivity: this.isKpiPredictedAdvancedFeatureStateEnabled,
        productivity: this.isKpiPredictedAdvancedFeatureStateEnabled,
        salary_mass_ratio: this.isKpiPredictedAdvancedFeatureStateEnabled,
        salary_mass: this.isKpiPredictedAdvancedFeatureStateEnabled,
      };
    },
    translatedRealKpisFeatureStates() {
      return {
        revenue: this.isKpiRealRevenueAndProductivityFeatureStateEnabled,
        volume: this.isKpiRealAdvancedFeatureStateEnabled,
        average_ticket: this.isKpiRealAdvancedFeatureStateEnabled,
        volume_productivity: this.isKpiRealAdvancedFeatureStateEnabled,
        productivity: this.isKpiRealRevenueAndProductivityFeatureStateEnabled,
        salary_mass_ratio: this.isKpiRealAdvancedFeatureStateEnabled,
        salary_mass: this.isKpiRealAdvancedFeatureStateEnabled,
      };
    },
    isPackOfferOverlayDisplayed() {
      if (this.areAllPredictedRowsDisabled) {
        return this.visibleKpisRows > 1;
      }

      return this.visibleKpisRows > 0;
    },
    inLinePackOfferOverlay() {
      return this.visibleKpisRows < 4;
    },
    defaultSelectedTab() {
      // If it is specified in the planning url, then it's prioritized
      if (this.tabOpenedByDefault) return this.tabOpenedByDefault;

      // Basic Pack doesn't have access to PredictedTab, so default selected tab is RealTab
      if (this.areAllPredictedRowsDisabled) return 'RealTab';

      // In the past and on current week, RealTab is selected by default
      // In the future, PredictedTab is the default selected tab
      return this.date.isBefore(skDate()) ? 'RealTab' : 'PredictedTab';
    },
    displayKpisOpenButton() {
      return !this.kpisLoading &&
        !this.posIntegrationsLoading &&
        !this.userKpisSettingsLoading &&
        !this.allKpisTableOpen &&
        !this.isPostesView;
    },
  },
  created() {
    this.fetchUserKpisSettings({ userId: this.currentUser.id });
    this.fetchKpis(this.fetchKpisParams(true, 'direct_created'));
  },
  mounted() {
    this.listenOnRoot('kpi-request-failure', this.onRequestFailure);
    this.listenOnRoot('show-kpi-tooltip', data => {
      this.popperDelayId = setTimeout(() => {
        this.tooltipData = data.tooltipData;
        this.tooltipType = data.tooltipType;
        this.updatePopper(data.anchor);
      }, 500);
    });

    this.listenOnRoot('hide-kpi-tooltip', performanceTooltipDisplayed => {
      if (performanceTooltipDisplayed) {
        this.resetTooltipData();
        return;
      }
      this.tooltipResetDelayId = setTimeout(() => {
        this.resetTooltipData();
      }, 100);
    });

    this.listenOnRoot('click-on-upsell', () => {
      this.clickOnUpsell();
    });

    if (this.tabOpenedByDefault) this.openKpisModule();
  },
  methods: {
    ...mapActions('planningsKpis', ['fetchUserKpisSettings', 'fetchKpis']),
    ...mapActions('partnersTools', ['fetchLadditionShops', 'fetchPosProvidersOnShop']),
    async loadPosIntegrations(shopId) {
      if (!this.canEditManagementIndicators) return;
      this.posIntegrationsLoading = true;

      const promises = [];
      const { id: organisationId } = this.currentOrganisation;
      promises.push([
        this.fetchLadditionShops([shopId]),
        this.fetchPosProvidersOnShop({ organisationId, shopIds: [shopId] })]);

      Promise.all(promises).finally(() => {
        this.posIntegrationsLoading = false;
      });
    },
    onMouseOverContent(event) {
      this.emitOnRoot('mouse-over-content', event);
    },
    onMouseUpOnContent(event) {
      this.emitOnRoot('mouse-up-content', event);
    },
    onTooltipEnter() {
      if (this.tooltipResetDelayId) clearTimeout(this.tooltipResetDelayId);
    },
    resetTooltipData() {
      this.tooltipData = null;
      this.tooltipType = null;
      if (this.popperDelayId) clearTimeout(this.popperDelayId);

      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    updatePopper(anchorEl, popperEl) {
      // First, instantiate
      if (!this.popper) {
        this.instantiatePopper(anchorEl);
        return;
      }

      // Next, change reference element
      // and ask popper to recalculate its position
      this.$nextTick(() => {
        this.popper.state.elements.reference = anchorEl;
        this.popper.update();
      });
    },
    instantiatePopper(anchorEl) {
      this.$nextTick(() => {
        this.popper = createPopper(anchorEl, document.getElementById('kpis__tooltip'), {
          placement: 'auto',
          modifiers: [{
            name: 'offset',
            options: { offset: [0, 10] },
          }],
        });
      });
    },
    handleClickOnPage(event) {
      // If Options modale is opened and we click outside of it, close it
      if (this.showOptionsModal) {
        if (this.$refs.optionsModal &&
          !this.$refs.optionsModal.$el.firstChild.contains(event.target)) {
          this.closeOptionsModal();
          return;
        }
      }

      // If Duplicate modale is opened and we click outside of it, close it
      if (this.showDuplicateModal) {
        if (this.$refs.duplicateModal &&
          !this.$refs.duplicateModal.$el.firstChild.contains(event.target)) {
          this.closeDuplicateModal();
          return;
        }
      }

      // Otherwise, if we click outside the KPIs module, close it
      if (this.$refs.sectionContainer &&
        !this.$refs.sectionContainer.contains(event.target)) {
        this.closeKpisModule();
      }
    },
    openKpisModule() {
      if (!this.canEditManagementIndicators) return;

      this.$skAnalytics.track('opened_kpi_module');

      this.selectedTabName = this.defaultSelectedTab;

      this.loadPosIntegrations(this.currentShop.id).then(() => {
        this.fetchKpis(this.fetchKpisParams(false, 'direct_open'))
          .then(() => {
            this.toggleButtons();
            this.toggleModule();
          });
      });
    },
    closeKpisModule() {
      this.toggleModule();
      this.$emit('clean-default-tab');
    },
    toggleModule() {
      this.allKpisTableOpen = !this.allKpisTableOpen;
    },
    toggleButtons() {
      this.showLargeButton = !this.showLargeButton;
      this.showRoundButton = !this.showRoundButton;
    },
    toggleSettingsDropdown() {
      this.showSettingsDropdown = !this.showSettingsDropdown;
    },
    closeSettingsDropdown() {
      this.showSettingsDropdown = false;
    },
    openDuplicateModal() {
      this.closeSettingsDropdown();
      this.showDuplicateModal = true;
    },
    openOptionsModal() {
      this.closeSettingsDropdown();
      this.showOptionsModal = true;
      this.$skAnalytics.track('viewed_display_settings');
    },
    closeDuplicateModal(event) {
      if (event) event.stopPropagation();
      this.showDuplicateModal = false;
    },
    closeOptionsModal(event) {
      if (event) event.stopPropagation();
      this.showOptionsModal = false;
    },
    isSelected(tabName) {
      return tabName === this.selectedTabName;
    },
    selectTab(tabName) {
      if (tabName === 'PredictedTab' && this.areAllPredictedRowsDisabled) return;

      if (!this.isSelected(tabName) && tabName === 'PredictedTab') {
        this.$skAnalytics.track('compared_kpi_values');
      }

      this.selectedTabName = tabName;
    },
    onRequestFailure(customMsg) {
      this.$skToast({
        message: customMsg || this.$t('kpis.errors.update'),
        variant: 'error',
        containerId: 'kpis__wrapper',
      });
    },
    tabClasses(tabName) {
      return {
        'kpis__tab-navigation__item': true,
        'kpis__tab-navigation__item-bottom-corner': true,
        'kpis__tab-navigation__item--selected': this.isSelected(tabName),
        'kpis__tab-navigation__item-bottom-corner-active': this.isSelected(tabName),
        'kpis__tab-navigation__item-bottom-corner-inactive': !this.isSelected(tabName),
        'kpis__tab-navigation__item__pack-offer--upsell': tabName === 'PredictedTab' && this.areAllPredictedRowsDisabled,
      };
    },
    clickOnUpsell() {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('kpis.pack_offers.upsell_already_requested'),
          variant: 'success',
          containerId: 'kpis__wrapper',
        });
        return;
      }

      const params = {
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('kpis.pack_offers.button_name', { pack_name: '' }),
          upsell_type: 'kpis',
          current_page: 'kpis',
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.upsellRequested = true;
          this.$skToast({
            message: this.$t('kpis.pack_offers.upsell_request'),
            variant: 'success',
            containerId: 'kpis__wrapper',
          });
        })
        .catch(() => {
          this.onRequestFailure(this.$t('kpis.errors.notif'));
        });
    },
    displayPlanningOverlay(tabName) {
      if (this.enabledKpisCount > 1) return false; // for pack premium
      if (this.enabledKpisCount === 0) return true; // for pack basic

      return tabName === 'PredictedTab';
    },
    exportCurrentWeek() {
      this.closeSettingsDropdown();

      const params = new URLSearchParams({
        shop_id: this.currentShop.id,
        date: this.date.format('YYYY-MM-DD'),
        source: 'planning_export',
      });

      httpClient
        .post(`/v3/api/plannings/kpis/export?${params.toString()}`, {
          user_kpis_settings: this.userKpisSettings,
        })
        .then(() => {
          this.$skToast({
            message: this.$t('kpis.modals.export.toasts.success', { email: this.currentUser.attributes.email }),
            variant: 'success',
            containerId: 'kpis__wrapper',
          });
          this.$skAnalytics.track('kpis_planning_export');
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('kpis.modals.export.toasts.error'),
            variant: 'error',
            containerId: 'kpis__wrapper',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.kpis-plannings__wrapper {
  min-height: 34px;
  background: white;
  z-index: 8;
}

.kpis__content--unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(50px);
  opacity: .5;
}

.hide-background__wrapper {
  background: rgba(0, 0, 0, .5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.kpis__container {
  width: 100%;
  max-height: 421px;
  position: fixed;
  bottom: 0;
}

.kpis__header {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sk-grey-5;

  .kpis__section-title {
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
    color: $sk-black;
  }
}

// /*
//  * Open KPIs buttons
//  */
.kpis__button-container {
  position: fixed;
  bottom: 100px;
  left: 30px;
  cursor: pointer;

  svg {
    margin: 0 !important;
  }
}

.kpis__round-button {
  position: absolute;
  height: 40px;
  min-width: 40px;
  top: 0;
  left: 0;
  background-color: $sk-black;
  box-shadow: 0 6px 28px rgba(0, 0, 0, .08);
  opacity: .56;
  border: none;
  outline: none;
  border-radius: 50%;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.kpis__large-button {
  display: flex;
  align-items: center;
  position: absolute;
  height: 40px;
  width: 200px;
  top: 0;
  left: 0;
  background-color: $sk-black;
  box-shadow: 0 8px 32px rgba(0, 0, 0, .08);
  opacity: .9;
  z-index: -1;
  border-radius: 50px;
  color: white;

  .kpis__round-button {
    opacity: .9;
  }
}

.kpis__large-button-text {
  position: absolute;
  font-size: $fs-text-s;
  right: 12px;
}

// /*
//  * Close KPIs button
//  */
.kpis__button-close {
  display: flex;
  align-items: center;
  position: absolute;
  height: 40px;
  width: 99px;
  top: -48px;
  left: 30px;
  z-index: -1;
  background-color: $sk-black;
  box-shadow: 0 8px 32px rgba(0, 0, 0, .08);
  opacity: .9;
  border-radius: 50px;
  color: white;
  cursor: pointer;

  .kpis__close-icon {
    position: absolute;
    left: 14px;
  }

  .kpis__button-close-text {
    position: absolute;
    font-size: $fs-text-s;
    right: 24px;
  }

  svg {
    margin: 0 !important;
  }
}

/*
 * TABS
 */
.kpis__tab-wrapper {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
}

.kpis__tab-navigation {
  display: flex;

  ul.kpis__tab-navigation__item {
    margin: 0;
    padding-inline-start: 0;
    list-style: none;
  }

  .kpis__tab-navigation__item {
    cursor: pointer;
    text-align: center;
    width: 160px;
    height: 38px;
    line-height: 38px;
    font-size: $fs-text-s;
    border-radius: 8px 8px 0 0;

    &:first-of-type {
      margin-left: 20px;
    }

    &:hover:not(.kpis__tab-navigation__item--selected) {
      background: rgba(255, 255, 255, .36);
    }
  }

  .kpis__tab-navigation__item__pack-tag {
    line-height: 22px;
  }

  .kpis__tab-navigation__item--selected {
    background-color: white;
    font-weight: $fw-semi-bold;
  }

  .kpis__tab-navigation__item-bottom-corner {
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
  }

  .kpis__tab-navigation__item-bottom-corner::before,
  .kpis__tab-navigation__item-bottom-corner::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 20px;
    bottom: 0;
  }

  .kpis__tab-navigation__item-bottom-corner::after {
    right: -20px;
    border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 10px;
  }

  .kpis__tab-navigation__item-bottom-corner::before {
    left: -20px;
    border-radius: 0 0 10px;
    -moz-border-radius: 0 0 10px;
    -webkit-border-radius: 0 0 10px;
  }

  .kpis__tab-navigation__item-bottom-corner-active::before {
    -webkit-box-shadow: 10px 0 0 0 white;
    box-shadow: 10px 0 0 0 white;
  }

  .kpis__tab-navigation__item-bottom-corner-active::after {
    -webkit-box-shadow: -10px 0 0 0 white;
    box-shadow: -10px 0 0 0 white;
    z-index: 1;
  }

  .kpis__tab-navigation__item-bottom-corner-inactive::before {
    -webkit-box-shadow: 10px 0 0 0 inherit;
    box-shadow: 10px 0 0 0 inherit;
  }

  .kpis__tab-navigation__item-bottom-corner-inactive::after {
    -webkit-box-shadow: -10px 0 0 0 inherit;
    box-shadow: -10px 0 0 0 inherit;
  }

  .kpis__tab-navigation__item-bottom-corner-inactive:hover::before {
    -webkit-box-shadow: 10px 0 0 0 rgba(255, 255, 255, .36);
    box-shadow: 10px 0 0 0 rgba(255, 255, 255, .36);
  }

  .kpis__tab-navigation__item-bottom-corner-inactive:hover::after {
    -webkit-box-shadow: -10px 0 0 0 rgba(255, 255, 255, .36);
    box-shadow: -10px 0 0 0 rgba(255, 255, 255, .36);
  }
}

// /*
//  * KPIs content
//  */
.kpis__section-container {
  width: 100%;
  border-radius: 5px 5px 0 0;
  background-color: white;

  .kpis__section-settings-round-button {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 8px;
    top: 5px;
    background-color: $sk-grey-10;
    border: none;
    outline: none;
    border-radius: 50%;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      margin: 0 !important;
    }
  }

  .kpis__section-settings-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 270px;
    right: 8px;
    top: 50px;
    background-color: white;
    box-shadow: 0 6px 16px rgba(0, 0, 0, .16);
    border-radius: 4px;
    cursor: pointer;
    z-index: 2;
  }

  .kpis__section-settings-dropdown-content {
    height: 36px;
    width: 270px;
    display: flex;
    align-items: center;
    font-size: $fs-text-m;
    cursor: pointer;

    &:hover {
      background-color: $sk-grey-10;
    }

    svg {
      width: 27px;
      margin: 4px 15px 0;
    }

    .kpis__section-settings-dropdown-icon {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: -4px;
    }

    .kpis__section-settings-dropdown-text {
      margin-left: -5px;

      .kpis__section-settings-dropdown-text__subtitle {
        font-size: $fs-text-s;
        color: $sk-grey;
      }
    }
  }
}

.kpis__section-settings-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 278px;
  height: 60px;
}

.kpis__sticky-section__container {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, .08);
  height: 34px;

  ::v-deep .kpi-cell__unit--right {
    position: unset;
    padding-left: 3px;
    padding-top: 3px;
  }
}

.kpis__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
  height: 100%;
}

/* ZOOMS
 * These are the classes that define
 * .kpis-total-label & .kpi-row__total-cell
 * depending on planning (V2)
 */
.planning-zoom-10 .kpis__container,
.planning-zoom-20 .kpis__container,
.planning-zoom-30 .kpis__container,
.planning-zoom-40 .kpis__container,
.planning-zoom-50 .kpis__container {
  ::v-deep .kpis-total-label,
  ::v-deep .kpi-row__total-cell {
    width: 151px;
  }
}

.planning-zoom-60 .kpis__container {
  ::v-deep .kpis-total-label,
  ::v-deep .kpi-row__total-cell {
    width: 158px;
  }
}

.planning-zoom-70 .kpis__container {
  ::v-deep .kpis-total-label,
  ::v-deep .kpi-row__total-cell {
    width: 164px;
  }
}

.planning-zoom-80 .kpis__container {
  ::v-deep .kpis-total-label,
  ::v-deep .kpi-row__total-cell {
    width: 179px;
  }
}

.planning-zoom-90 .kpis__container {
  ::v-deep .kpis-total-label,
  ::v-deep .kpi-row__total-cell {
    width: 186px;
  }
}

.planning-zoom-100 .kpis__container {
  ::v-deep .kpis-total-label,
  ::v-deep .kpi-row__total-cell {
    width: 193px;
  }
}
</style>
