import groupBy from 'lodash/groupBy';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import {
  httpClient, svcDocumentV2Client,
} from '@skello-utils/clients';
import { SkelloFolders } from '@skelloapp/svc-documents-v2-client';
import { FEATURES } from '@app-js/shared/constants/features';

const initialState = {
  documents: [],
  documentsV2: [],
  documentsCreators: [],
  currentFolder: '',
  error: null,
  loadingSendEmail: false,
  loadingDocumentsV2: false,
  loadingUpdateDocument: false,
  loading: false,
};

const matchCreatorToDocument = (document, included) => {
  const creator = associationMatcherItem(document, included, { key: 'creator', type: 'user' });
  Object.assign(document.relationships, { creator });
  return document;
};

const matchCreatorToDocumentV2 = (creators, document) => {
  const creator = creators.find(c => String(c.id) === String(document.attributes.creatorId));
  Object.assign(document.relationships, { creator });
  return document;
};

// Take a list of documents from the svcDocV2
// and return a list of documents matching the structure of the store format
const mapDocumentFromV2 = document => ({
  id: document.id,
  type: 'document',
  attributes: {
    userId: document.employeeId,
    expirationDate: document.expirationDate || null,
    format: document.mimeType,
    url: '',
    createdAt: document.createdAt,
    // the replace deletes filename extension
    title: document.title || document.fileName?.replace(/\.[^/.]+$/, ''),
    folder: document.folderPath?.substring(1) || '', // Remove the first / to go from folderPath to folder
    creatorId: document.creatorId,
  },
  relationships: {
    creator: null,
  },
});

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  updateCurrentFolder(state, folder) {
    state.currentFolder = folder;
  },

  fetchDocumentsSuccess(state, payload) {
    state.documents = payload.data;
    state.documents.forEach(document => matchCreatorToDocument(document, payload.included));
  },

  fetchDocumentsFromV2Success(state, payload) {
    state.documentsV2 = payload.documents.map(document => mapDocumentFromV2(document));
  },

  createDocumentSuccess(state, payload) {
    const document = matchCreatorToDocument(payload.data, payload.included);
    state.documents.splice(0, 0, document);
  },

  createDocumentV2Success(state, payload) {
    const documentV2 = mapDocumentFromV2(payload);
    matchCreatorToDocumentV2(state.documentsCreators, documentV2);
    state.documentsV2.splice(0, 0, documentV2);
  },

  updateDocumentSuccess(state, payload) {
    const document = matchCreatorToDocument(payload.data, payload.included);
    const index = state.documents.findIndex(e => e.id === document.id);
    state.documents.splice(index, 1, document);
  },

  updateDocumentV2Success(state, payload) {
    const documentV2 = mapDocumentFromV2(payload);
    const index = state.documentsV2.findIndex(e => e.id === documentV2.id);
    matchCreatorToDocumentV2(state.documentsCreators, documentV2);
    state.documentsV2.splice(index, 1, documentV2);
  },

  fetchDocumentsCreatorsSuccess(state, payload) {
    state.documentsCreators = payload.map(creator => ({
      id: creator.id,
      attributes: {
        firstName: creator.first_name,
        lastName: creator.last_name,
      },
    }));
    state.documentsV2.forEach(doc => matchCreatorToDocumentV2(state.documentsCreators, doc));
  },

  documentsError(state, error) {
    state.error = error;
  },

  deleteDocumentSuccess(state, documentId) {
    const deletedIndex = state.documents.findIndex(d => d.id === documentId.toString());
    state.documents.splice(deletedIndex, 1);
  },

  deleteDocumentV2Success(state, documentId) {
    const deletedIndex = state.documentsV2.findIndex(d => d.id === documentId.toString());
    state.documentsV2.splice(deletedIndex, 1);
  },
};

const actions = {
  fetchDocumentsCreators({ state, commit, rootState }) {
    commit('performingRequest');
    const { currentUser } = rootState.currentUser;
    // No need for a request if there are no documents, we just want to add the currentUser to documentsCreators
    // As he's the only one who can create documents for himself or others when no one else has
    if (state.documentsV2.length === 0) {
      return new Promise(resolve => {
        commit('fetchDocumentsCreatorsSuccess', [
          {
            id: currentUser.id,
            first_name: currentUser.attributes.firstName,
            last_name: currentUser.attributes.lastName,
          },
        ]);
        commit('requestComplete');
        resolve();
      });
    }

    const creatorIds = [
      ...new Set([currentUser.id].concat(
        state.documentsV2.filter(d => !!d.attributes.creatorId)
          .map(doc => doc.attributes.creatorId),
      )),
    ];

    return httpClient
      .get('/v3/api/users/display_names', { params: { user_ids: creatorIds } })
      .then(response => {
        commit('fetchDocumentsCreatorsSuccess', response.data);
      })
      .catch(({ response }) => {
        commit('documentsError', response.data);
        reject(response);
      })
      .finally(() => {
        commit('requestComplete');
      });
  },
  fetchDocuments({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingRequest');

      const formattedParams = {};
      if (params.folderId) {
        formattedParams.hris_folder_id = params.folderId;
      }

      httpClient
        .get(`/v3/api/users/${params.employeeId}/documents`, { params: formattedParams })
        .then(response => {
          commit('fetchDocumentsSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response.data);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },
  fetchDocumentsFromV2({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingDocumentsV2');
      svcDocumentV2Client.document.find({ employeeId: params.employeeId, order: 'desc' })
        .then(response => {
          commit('fetchDocumentsFromV2Success', response);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete', 'loadingDocumentsV2');
        });
    });
  },

  // TODO DEV-8540
  // Use this action from Employee's documents page
  // if we get rid of DropzoneJS to make the request
  createDocument({ state, commit }, { employeeId, document }) {
    commit('performingRequest');

    const url = `/v3/api/users/${employeeId}/documents`;

    const params = {
      document: {
        title: document.title,
        expiration_date: document.expirationDate,
        folder: state.currentFolder,
      },
    };

    return new Promise((resolve, reject) => {
      httpClient
        .post(url, params)
        .then(response => {
          commit('createDocumentSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response.data);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  updateDocument({ commit }, params) {
    return new Promise((resolve, reject) => {
      const { employeeId, documentId, folder, esignature, title } = params;

      commit('performingRequest', 'loadingUpdateDocument');

      httpClient
        .patch(`/v3/api/users/${employeeId}/documents/${documentId}`, {
          document: {
            folder,
            esignature,
            title,
          },
        })
        .then(response => {
          commit('updateDocumentSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response.data);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete', 'loadingUpdateDocument');
        });
    });
  },

  updateDocumentV2({ commit }, params) {
    const { documentId, folderPath, title, esignature } = params;

    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingUpdateDocument');
      svcDocumentV2Client.document
        .update(documentId, {
          folderPath,
          title,
          esignature,
        })
        .then(response => {
          commit('updateDocumentV2Success', response);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete', 'loadingUpdateDocument');
        });
    });
  },

  deleteDocuments({ commit }, params) {
    commit('performingRequest');

    return httpClient
      .delete(`/v3/api/users/${params.employeeId}/documents/${params.documentId}`)
      .then(response => {
        commit('deleteDocumentSuccess', params.documentId);
      })
      .catch(error => {
        commit('documentsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  deleteDocumentsV2({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingRequest');
      svcDocumentV2Client.document
        .delete(params.documentId, 0)
        .then(response => {
          commit('deleteDocumentV2Success', params.documentId);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  sendEmail({ commit }, { document, params }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingSendEmail');

      httpClient
        .post(
          `/v3/api/users/${document.relationships.user.id}/documents/${document.id}/send_email`,
          params,
        )
        .then(response => {
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response.data);
          reject(response);
        })
        .finally(() => { commit('requestComplete', 'loadingSendEmail'); });
    });
  },
  sendEmailV2({ commit }, { documentId, params }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingSendEmail');

      svcDocumentV2Client.document
        .mailDocument(documentId, params)
        .then(response => {
          resolve(response);
        })
        .catch(({ response }) => {
          commit('documentsError', response);
          reject(response);
        })
        .finally(() => { commit('requestComplete', 'loadingSendEmail'); });
    });
  },
  // Add a document to the store
  // From the response of the V3::Api::Employees::DocumentsController
  addDocumentFromResponse({ commit }, payload) {
    commit('createDocumentSuccess', payload);
  },

  addDocumentV2FromResponse({ commit }, payload) {
    commit('createDocumentV2Success', payload);
  },
};

const getters = {
  documentsInFolder: (_s, selfGetters, rootState, rootGetters) => folder => {
    const isDocumentsFoldersEnabled = rootGetters['features/isFeatureEnabled'](
      FEATURES.FEATURE_DOCUMENTS_FOLDERS,
      rootState.selectedEmployee.employee.attributes.shopId,
    );
    return selfGetters.combinedDocuments
      .filter(document => {
        if (folder === '') {
        // Show in root if: 1) no folder OR 2) not a Skello folder & custom folder doesn't exist
          const documentFolder = document.attributes.folder;
          const isCustomFolder = rootGetters['foldersManagement/getCustomFolder'](documentFolder);
          return (
            !document.attributes.folder ||
          (!isCustomFolder &&
          isDocumentsFoldersEnabled ?
            !SkelloFolders.find(permanentFolder => permanentFolder === documentFolder) : [])
          );
        }
        return document.attributes.folder === folder;
      });
  },
  documentsByFolder: (_state, selfGetters) => (
    groupBy(selfGetters.combinedDocuments, 'attributes.folder')
  ),
  isDocumentV2: ({ documentsV2 }) => documentId => {
    // In some cases, we have to check if the documentId is a valid UUID to determine if it's a documentV2
    // ie: Text document generation because it uses a different store but still calls isDocumentV2
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return documentsV2.some(document => document.id === documentId) || uuidRegex.test(documentId);
  },
  // Used to combine svc documents v1 and V2 while migrating
  combinedDocuments: (state, selfGetters, _rootState, rootGetters) => {
    if (!rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_FETCH_DOCS_FROM_V2')) {
      return state.documents;
    }

    const allDocuments = [...state.documents, ...state.documentsV2];

    // This makes sure that the documents don't appear twice from V1 and V2
    // when the document migration in ongoing or has been done
    // Every doc V1 that will have been migrated will contain a `svc_doc_v2_id`
    const filteredDocuments = allDocuments.filter(doc => (
      selfGetters.isDocumentV2(doc) || !doc.attributes.svcDocV2Id
    ));

    // This makes sure the documents are sorted by createdAt desc when mixing documents from V1 and V2
    // Same condition as in svcDocumentV2 DocumentManager.ts
    return filteredDocuments.sort((a, b) => {
      const dateA = selfGetters.isDocumentV2(a) ? new Date(a.createdAt) : new Date(a.created_at);
      const dateB = selfGetters.isDocumentV2(b) ? new Date(b.createdAt) : new Date(b.created_at);

      return dateB.getTime() - dateA.getTime();
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
