<template>
  <div
    v-tooltip.top.text-align-left="tooltip"
    class="activity-icon__container"
    v-on="$listeners"
  >
    <ClipboardV2Icon
      :width="width"
      :height="height"
      :fill="fillColor"
    />
    <div
      v-if="shouldDisplayActivityBubble"
      class="activity-icon__bubble"
      :style="{ border: `1px solid ${shiftColor}`, backgroundColor: `${fillColor}` }"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { isEmpty } from '@skello-utils/array';
import { FEATURES } from '@app-js/shared/constants/features.js';

export default {
  name: 'ActivityIcon',
  props: {
    note: {
      type: String,
      default: undefined,
    },
    tasks: {
      type: Array,
      default: undefined,
    },
    comments: {
      type: Array,
      default: undefined,
    },
    attachmentsCount: {
      type: Number,
      default: undefined,
    },
    shiftColor: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    fillColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('planningsShifts', ['shouldDisplayComments']),
    ...mapGetters('planningsState', ['isEmployeePlanningSpace']),
    ...mapGetters('features', ['isFeatureEnabled']),

    isAttachDocumentsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_ATTACH_DOCUMENTS');
    },
    shouldDisplayActivityIcon() {
      // Until the EmployeeV3 space evolves we do not want to show tasks nor comments
      if (this.isEmployeePlanningSpace) return false;

      return (
        this.isFeatureEnabled(FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id) &&
          !isEmpty(this.tasks)
      ) ||
        this.shouldDisplayComments(this.comments, this.currentShop.id) ||
        this.shouldDisplayAttachments;
    },
    checkedTasksCount() {
      return this.tasks.reduce((tasksNb, task) => {
        if (task.isChecked) tasksNb += 1;
        return tasksNb;
      }, 0);
    },
    shouldDisplayActivityBubble() {
      if (this.shouldDisplayComments(this.comments, this.currentShop.id)) return true;
      return this.isFeatureEnabled(
        FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id,
      ) &&
        !isEmpty(this.tasks) &&
        this.checkedTasksCount > 0;
    },
    shouldDisplayAttachments() {
      return this.isAttachDocumentsEnabled && this.attachmentsCount > 0;
    },
    tasksInfo() {
      if (!this.isFeatureEnabled(
        FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id,
      ) || isEmpty(this.tasks)) return '';

      return this.$tc(
        'plannings.table.manage_shift_modal.tabs.shift.activity_icon.tasks',
        this.checkedTasksCount,
        { count: this.checkedTasksCount, total: this.tasks.length },
      );
    },
    commentsInfo() {
      if (!this.shouldDisplayComments(this.comments, this.currentShop.id)) return '';

      return this.$tc('plannings.table.manage_shift_modal.tabs.shift.activity_icon.comments', this.comments.length);
    },
    attachmentsInfo() {
      if (!this.attachmentsCount || this.attachmentsCount <= 0) return '';

      return this.$tc('plannings.table.manage_shift_modal.tabs.shift.activity_icon.attachments', this.attachmentsCount);
    },
    tooltip() {
      if (this.shouldDisplayActivityIcon) {
        let res = '';
        if (this.isFeatureEnabled(
          FEATURES.FEATURE_SHIFT_ACTIVITY_TASKS, this.currentShop.id,
        ) && !isEmpty(this.tasks)) {
          res += `${this.tasksInfo}<br>`;
        }
        if (this.note) res += `${this.$t('plannings.table.manage_shift_modal.tabs.shift.activity_icon.note')}<br>`;
        if (this.shouldDisplayComments(this.comments, this.currentShop.id)) res += `${this.commentsInfo}<br>`;
        if (this.shouldDisplayAttachments) res += `${this.attachmentsInfo}<br>`;
        return res;
      }

      return this.note;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-icon__container {
  position: relative;
  cursor: pointer;
  pointer-events: auto;
}

.activity-icon__bubble {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid rgb(2, 135, 199);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
