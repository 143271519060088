<template>
  <div>
    <SmartAppBanner
      v-if="displayAppDownloadBanner"
      :size="isAuthenticatedRoute ? 'small' : 'large'"
    />
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <SkConfirmationModal />
      <SkOroraConfirmationDialog />
      <SkOroraBackdrop />
      <SkOroraFloatingPopoverMenu />
      <CookieDialog />
    </MountingPortal>
    <div
      v-if="loading"
      class="bootloader"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
} from 'vuex';
import { skIdleUserPageReloader } from '@skello-utils/idle';
import SmartAppBanner from '@app-js/shared/components/SmartAppBanner/SmartAppBanner';
import { isMobile } from '@skello-utils/mobile.js';
import CookieDialog from '@app-js/shared/components/CookieDialog/CookieDialog';
import {
  httpClient,
  impersonateClient,
} from '@skello-utils/clients';
import { redirectToSuperadmin } from '@skello-utils/redirection';
import { authClient } from '@skello-utils/clients/auth_client';
import { staticRoutes } from './static';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/es';
import 'moment/dist/locale/de';
import 'moment/dist/locale/it';

export default {
  name: 'Root',
  components: { SmartAppBanner, CookieDialog },
  data() {
    return {
      isMobile: isMobile(),
      loading: true,
    };
  },
  computed: {
    displayAppDownloadBanner() {
      return this.isMobile && !this.$route.fullPath.includes('v3/self_serve');
    },
    isUsingStatelessToken() {
      return this.$route.query.deal_room ||
        this.$route.query.invitation ||
        this.$route.query.profile ||
        this.$route.query.self_serve_token ||
        this.$route.query.superadmin ||
        this.$route.query.roll_out;
    },
    isAuthenticatedRoute() {
      return !staticRoutes.map(route => route.name).includes(this.$route.name) &&
        !this.$route.fullPath.includes('v3/user-creation') &&
        !this.$route.fullPath.includes('self_serve/step_two') &&
        !this.$route.fullPath.includes('self_serve/step_one');
    },
  },
  created() {
    this.$nextTick(() => {
      document.title = this.$t('tab_titles.default');
    });

    skIdleUserPageReloader.start();
  },
  beforeDestroy() {
    skIdleUserPageReloader.stop();
  },
  async mounted() {
    await this.fetchFeatureFlags();

    if (this.isUsingStatelessToken) {
      await this.handleStatelessTokenConnection();
    }

    // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
    if (!this.$route.query.superadmin && !(localStorage.getItem('UseNewImpersonateFlow') === 'true')) {
      await impersonateClient.impersonateLegacy();
    }
    //

    await this.checkAuthAndRedirect();

    this.loading = false;
  },
  methods: {
    ...mapActions('config', ['fetchFeatureFlags']),
    ...mapMutations(['setNavbarVisibility']),
    ...mapActions('currentUser', ['authWithStatelessToken']),

    async checkAuthAndRedirect() {
      const isConnected = await this.isConnectedByJwtAndBySession();

      if (this.isAuthenticatedRoute && !isConnected) {
        this.navigateToLogin();
      } else if (this.$route.path === '/') {
        /* NOTE: This logic will be handle in frontend soon by migrating HomeRedirector
        ** in front end see https://skello.atlassian.net/browse/REAPER-199
        */
        const { data } = await httpClient.get('/v3/home_url');
        /* NOTE: When a logged in superadmin access skello app directly
        ** by entering the url manually, then we use a full super admin url and change site.
        ** The router needs to use the push api only for relative path.
        */
        if (data.url.startsWith('/')) {
          this.$router.push({ path: data.url });
        } else {
          redirectToSuperadmin(data.url);
        }
      }
    },
    navigateToLogin() {
      this.setNavbarVisibility(false);

      this.$router.push({ name: 'Login', query: this.$route.query });
    },
    async isConnectedByJwtAndBySession() {
      try {
        const authToken = await authClient.getAuthToken();

        return !!authToken;
      } catch (error) {
        return false;
      }
    },
    /* NOTE: Stateless token provides a way to transmit info about who's attempting connecting
    ** for users coming from workflow outside of the main application. (ie deal room, invitation, etc)
    */
    async handleStatelessTokenConnection() {
      let params = {};

      // Erase stateless token from url but keep other query params
      const { invitation,
        profile,
        superadmin,
        deal_room: dealRoom,
        roll_out: rollOut,
        self_serve_token: selfServeToken,
        ...query } = this.$route.query;

      if (dealRoom) {
        params = { token: dealRoom, purpose: 'deal_room' };
      } else if (invitation) {
        params = { token: invitation, purpose: 'invitation' };
      } else if (profile) {
        params = { token: profile, purpose: 'profile' };
      } else if (rollOut) {
        params = { token: rollOut, purpose: 'roll_out' };
      } else if (selfServeToken) {
        params = { token: selfServeToken, purpose: 'self_serve' };
      } else if (superadmin) {
        params = { token: superadmin, purpose: 'superadmin' };
      }

      if (superadmin) {
        // delete this when https://skello.atlassian.net/browse/REAPER-365 ready
        localStorage.setItem('UseNewImpersonateFlow', 'true');
        //
        await impersonateClient.impersonate(params);
      } else {
        await this.authWithStatelessToken(params);
      }

      this.$router.replace({ path: this.$route.path, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.bootloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
