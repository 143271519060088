<template>
  <div
    class="employee-shifts-history"
    :style="employeeShiftsHistoryStyles"
  >
    <div class="employee-shifts-history__toolbar">
      <div class="employee-shifts-history__toolbar__date">
        <div class="employee-shifts-history__toolbar__date-actions">
          <SkCircleButton
            icon="ChevronLeftV2Icon"
            @click="handlePreviousShifts"
          />
        </div>
        <div class="employee-shifts-history__toolbar__date__datepicker-wrapper">
          <SkDatePicker
            v-model="shiftsDateRangeHandler"
            v-track="'employee_history_select_week'"
            :clearable="false"
            :editable="false"
            :lang="$i18n.locale"
            range
            input-moment-format="YYYY-MM-DD"
            input-fill="outlined"
            input-text-centered
            no-icon
          >
            <template #input>
              <div class="employee-shifts-history__datepicker__daterange__label">
                {{ formatDate(shiftsDateRange[0]) }} - {{ formatDate(shiftsDateRange[1], true) }}
              </div>
            </template>
          </SkDatePicker>
        </div>
        <div class="employee-shifts-history__toolbar__date-actions">
          <SkCircleButton
            icon="ChevronRightV2Icon"
            @click="handleNextShifts"
          />
        </div>
        <SkTag
          variant="light"
        >
          {{ weekTag }}
        </SkTag>
      </div>
      <div class="employee-shifts-history__toolbar__actions">
        <a
          :title="printTitle"
          :href="printLink"
          @click.prevent="handlePrint"
        >
          <SkCircleButton
            v-track="'employee_history_download_attendance'"
            v-tooltip
            icon="PrinterV2Icon"
          />
        </a>
      </div>
    </div>
    <div
      v-if="showEsignatureUpsellBanner"
      class="employee-shifts-history__upsell-banner-wrapper"
    >
      <UpsellEsignatureBanner source="history" />
    </div>
    <div class="employee-shifts-history__header">
      <h3 class="sk-header--3 employee-shifts-history__header__title">
        {{ this.$t('employees.tabs.counters.shifts_history.shifts_history') }}
      </h3>
      <div class="employee-shifts-history__header__filter">
        <SkSelectV2
          v-model="shiftType"
          :options="shiftTypes"
          fill="outlined"
        />
      </div>
    </div>

    <div
      v-if="shiftsListRequestPending"
      class="employee-shifts-history__spinner"
    >
      <SkLoader size="medium" />
    </div>

    <div
      v-else
      class="employee-shifts-history__content"
    >
      <Table
        :shifts="shifts"
        :start-date="rangeStart.format()"
        :end-date="rangeEnd.format()"
        :shift-type="currentShiftType"
        :on-day-rate="isOnDayRate"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import {
  ALL_SHIFT_TYPE,
  POSTE_SHIFT_TYPE,
  ABSENCE_SHIFT_TYPE,
} from '@app-js/shared/constants/shift';
import UpsellEsignatureBanner from '@app-js/shared/components/UpsellEsignatureBanner';
import { FEATURES } from '@app-js/shared/constants/features';
import { httpClient } from '@skello-utils/clients';
import Table from './Table';

const LAST_WEEK_MONDAY = skDate().weekday(1).subtract(1, 'w').format('YYYY-MM-DD');
const CURRENT_WEEK_SUNDAY = skDate().weekday(7).format('YYYY-MM-DD');

export default {
  name: 'ShiftsHistory',
  components: { Table, UpsellEsignatureBanner },
  data() {
    return {
      justUpdated: false,
      shiftsDateRange: [LAST_WEEK_MONDAY, CURRENT_WEEK_SUNDAY],
      currentShiftType: ALL_SHIFT_TYPE,
      FEATURES,
    };
  },
  computed: {
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    ...mapState('employeeShifts', ['shifts', 'shiftsListRequestPending']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'checkFeatureFlag', 'isEsignatureTimeSheetEnabled']),
    ...mapGetters('features', ['isFeatureEnabled', 'isBundleSystemActive']),
    // shiftsDateRange is an array of dates, not datetimes (necessary vue2-datepicker input format
    // for expected behaviour). rangeStart and rangeEnd set desired datetimes.
    // Those two are to be used for any data communication (API calls, props to components,...).
    shiftTypes() {
      return [
        {
          id: ALL_SHIFT_TYPE,
          text: this.$t('employees.tabs.counters.shifts_history.shift_types.all'),
        },
        {
          id: POSTE_SHIFT_TYPE,
          text: this.$t('employees.tabs.counters.shifts_history.shift_types.poste'),
          hidden: !this.isFeatureEnabled(
            FEATURES.FEATURE_CREATE_POSITION,
            this.currentShop.id,
          ),
        },
        {
          id: ABSENCE_SHIFT_TYPE,
          text: this.$t('employees.tabs.counters.shifts_history.shift_types.absence'),
        },
      ].filter(shiftType => !shiftType.hidden);
    },
    shiftType: {
      get() {
        return this.currentShiftType;
      },
      set(newCurrentShiftType) {
        this.currentShiftType = newCurrentShiftType;
        this.$skAnalytics.track(`employee_history_${newCurrentShiftType}`);
      },
    },
    shiftsDateRangeHandler: {
      get() {
        return this.shiftsDateRange;
      },
      set(newDates) {
        const startDateClone = skDate(newDates[0]).startOf('day');
        const endDateClone = skDate(newDates[1]).startOf('day');
        const yearsInPeriod = endDateClone.diff(startDateClone, 'years', true);

        if (yearsInPeriod <= 1.0) {
          if (this.justUpdated) {
            this.justUpdated = false;
          } else {
            this.shiftsDateRange = newDates;
          }
          return;
        }

        const startPlusOneYear = startDateClone.clone().add(1, 'year').format('YYYY-MM-DD');
        this.$skToast({
          message: this.$t('dates.notice_max_range', { max: 12 }),
          variant: 'information',
        });
        this.shiftsDateRange = [newDates[0], startPlusOneYear];
        this.justUpdated = true;
      },
    },
    rangeStart() {
      return skDate.utc(this.shiftsDateRange[0]).startOf('day');
    },
    rangeEnd() {
      return skDate.utc(this.shiftsDateRange[1]).endOf('day');
    },
    rangeDurationInDays() {
      const rangeDurationInMs = this.rangeEnd - this.rangeStart;
      return Math.round(rangeDurationInMs / (1000 * 3600 * 24));
    },
    weekFrom() {
      return this.rangeStart.isoWeek();
    },
    weekTo() {
      return this.rangeEnd.isoWeek();
    },
    weekTag() {
      if (this.weekFrom === this.weekTo) {
        return this.$t('employees.tabs.counters.shifts_history.week_tag.week', {
          week: this.weekFrom,
        });
      }
      return this.$t('employees.tabs.counters.shifts_history.week_tag.weeks', {
        weekFrom: this.weekFrom,
        weekTo: this.weekTo,
      });
    },
    printLink() {
      // Call to old pdf attendance sheet printer.
      if (!this.selectedEmployee.id) return '';

      const historyStart = this.rangeStart.format('YYYY-MM-DD');
      const historyEnd = this.rangeEnd.format('YYYY-MM-DD');
      const shopId = this.selectedEmployee.attributes.shopId;

      return `/shops/${shopId}/users_history/${this.selectedEmployee.id}/print?history_start=${historyStart}&history_end=${historyEnd}&users_history_id=${this.selectedEmployee.id}&poste=${this.currentShiftType}`;
    },
    printTitle() {
      return this.$t('employees.tabs.counters.shifts_history.captions.print');
    },
    isOnDayRate() {
      return this.selectedEmployee.attributes.onDayRate === true;
    },
    // Keep width of 3 tabs until defaultTabs up to 4
    employeeShiftsHistoryStyles() {
      const tabsCount = this.$parent.defaultTabs.length <= 3 ? 3 : 4;
      return {
        maxWidth: `calc(290px * ${tabsCount})`,
      };
    },
    showEsignatureUpsellBanner() {
      if (this.isBundleSystemActive(this.currentShop.id)) {
        return this.showEsignatureUpsellBannerNewVersion;
      }
      return this.showEsignatureUpsellBannerOldVersion;
    },
    checkEsignatureConditions() {
      return this.checkFeatureFlag('FEATURE_ESIGNATURE') &&
             this.currentLicense.attributes.canPublishPlanning &&
             this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_UPSELL');
    },
    showEsignatureUpsellBannerOldVersion() {
      return this.checkEsignatureConditions &&
              !this.isEsignatureTimeSheetEnabled({ requireAll: true });
    },
    showEsignatureUpsellBannerNewVersion() {
      const currentShopId = this.currentShop.id;
      return this.checkEsignatureConditions &&
        this.isFeatureEnabled(FEATURES.FEATURE_PLANNING_ACCESS, currentShopId) &&
        !this.isEsignatureTimeSheetEnabled();
    },
  },
  watch: {
    shiftsDateRange() {
      this.$skAnalytics.track('employee_history_select_week');
      this.fetchEmployeeShifts();
    },
  },
  created() {
    this.fetchEmployeeShifts();
  },
  methods: {
    ...mapActions('employeeShifts', ['fetchShifts']),
    ...mapMutations('planningsState', ['closeTotalPeriodTab']),

    handleNextShifts() {
      const durationToAdd = this.rangeDurationInDays;
      this.shiftsDateRange.splice(0, 1, skDate(this.shiftsDateRange[0]).add(durationToAdd, 'd').format('YYYY-MM-DD'));
      this.shiftsDateRange.splice(1, 1, skDate(this.shiftsDateRange[1]).add(durationToAdd, 'd').format('YYYY-MM-DD'));
    },

    async handlePrint() {
      const newWindow = window.open('', '_blank');
      const response = await httpClient.get(this.printLink);
      newWindow.location.href = response.data.url;
    },
    handlePreviousShifts() {
      const durationToSubtract = this.rangeDurationInDays;
      this.shiftsDateRange.splice(0, 1, skDate(this.shiftsDateRange[0]).subtract(durationToSubtract, 'd').format('YYYY-MM-DD'));
      this.shiftsDateRange.splice(1, 1, skDate(this.shiftsDateRange[1]).subtract(durationToSubtract, 'd').format('YYYY-MM-DD'));
    },
    fetchEmployeeShifts() {
      this.closeTotalPeriodTab();
      this.fetchShifts({
        employee_id: this.selectedEmployee.id,
        start: this.rangeStart.format(),
        end: this.rangeEnd.format(),
      });
    },
    formatDate(date, isYear = false) {
      return isYear ? skDate(date).format('D MMM YYYY') : skDate(date).format('D MMM');
    },
  },
};
</script>

<style lang="scss" scoped>

.employee-shifts-history {
  background: white;
}

.employee-shifts-history__toolbar {
  border-bottom: 1px solid $sk-grey-10;
  padding: 11px 0;
  display: flex;
  justify-content: space-between;
}

.employee-shifts-history__toolbar__date {
  display: flex;
  align-items: center;

  .employee-shifts-history__toolbar__date-actions {
    padding: 0 10px;
  }
}

.employee-shifts-history__toolbar__date__datepicker-wrapper {
  width: 168px;
}

.employee-shifts-history__toolbar__actions {
  padding: 0 20px;
}

.employee-shifts-history__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}

.employee-shifts-history__header__filter {
  width: 220px;
}

.employee-shifts-history__header__filter__options {
  color: $sk-black;
}

.employee-shifts-history__header__filter__selected-option {
  color: $sk-grey;
}

.employee-shifts-history__datepicker__daterange__label {
  height: 36px;
  background-color: $sk-white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;

  &:hover {
    cursor: pointer;
  }
}

.employee-shifts-history__spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.employee-shifts-history__upsell-banner-wrapper {
  margin: 23px 24px 0;
}
</style>
