<template>
  <TooltipWrapper
    :background-color="backgroundColor"
    :color-text="colorText"
    is-upsell
    @mouse-enter="onMouseEvent('mouse-enter')"
    @mouse-leave="onMouseEvent('mouse-leave')"
  >
    <div class="upsell-tooltip__description">
      <span>{{ $t('kpis.pack_offers.tooltip.introduction') }}
        &nbsp;{{ packLimitationText }}
      </span>
    </div>

    <div class="upsell-tooltip__button">
      <SkOroraButton
        size="small"
        @click.stop="clickOnUpsell"
      >
        {{ $t('kpis.pack_offers.tooltip.upsell_button') }}
      </SkOroraButton>
    </div>
  </TooltipWrapper>
</template>

<script>
import TooltipWrapper from '../shared/TooltipWrapper';

export default {
  name: 'UpsellTooltip',
  components: {
    TooltipWrapper,
  },
  props: {
    packLimitationText: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'black',
    },
    colorText: {
      type: String,
      required: false,
      default: 'white',
    },
  },
  methods: {
    clickOnUpsell() {
      this.emitOnRoot('click-on-upsell');
    },
    onMouseEvent(event) {
      this.$emit(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-tooltip__description {
  display: flex;
  line-height: 2;
  margin-bottom: 15px;
}

.upsell-tooltip__button {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 36px;
  font-weight: bold;
  background: $sk-blue;
  border-radius: 4px;
  z-index: 2;
}
</style>
