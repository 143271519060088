<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';

import { mapGetters } from 'vuex';
import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'PrintAction',
  extends: GenericToolbarAction,
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    titleOverride: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters('planningsState', ['isMonthlyView']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('shopState', ['currentShop']),

    title() {
      return this.titleOverride ? this.titleOverride : this.$t('action_bar.print.label');
    },
    icon() {
      return 'PrinterV2Icon';
    },
    iconSize() {
      return '20';
    },
    visibleIn() {
      return 'toolbar-m-dropdown-s';
    },
    disabled() {
      return this.isDataLoading || this.isDisabled;
    },
    showTooltip() {
      return true;
    },
    isVisible() {
      return this.isMonthlyView ?
        this.isFeatureEnabled(FEATURES.MONTH_PLANNINGS_PRINT, this.currentShop.id) :
        true;
    },
    dataTest() {
      return 'badging-toolbar__print';
    },
  },
  methods: {
    handler() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'print-modal');
    },
  },
};
</script>
