<template>
  <CountersCellPopover
    listener-id="counter-maj-detail"
    :popover-height="152"
    ref-name="counter_maj_detail_wrapper"
  >
    <template #popover-content="{ counters }">
      <div
        v-if="counters"
        class="counters-cell__counter"
      >
        <div class="counters-cell__counter__details">
          <div class="counters-cell__content__counter__value--neutral">
            {{ $t('plannings.table.cells.counters.details.begin_of_week') }}
          </div>
          <div class="counters-cell__content__counter">
            <CounterCellIcon
              :cell-style="counters.zoomRange.icon"
              :icon-name="counters.circleIconCounterBeginning"
              icon-color="#f0900d"
              color="#fdf0c8"
            />
            <span
              class="
              counters-cell__content__counter__value
              counters-cell__content__counter__value--neutral"
            >
              {{ counters.userHoursCounterBeginOfWeek }}
            </span>
          </div>
        </div>
        <div class="counters-cell__counter__details--separator" />
        <template v-if="counters.weeklyTotalDifference !== 0">
          <div class="counters-cell__counter__details">
            <div :class="weeklyTotalDifferenceBoldClasses(counters)">
              {{ $t(`plannings.table.cells.counters.details.${counters.weeklyTotalDifferencePlaceholder}`) }}
            </div>
            <div class="counters-cell__content__counter">
              <CounterCellIcon
                :cell-style="counters.zoomRange.icon"
                :color="counters.backgroundSignColor"
                :icon-name="counters.circleIconWeeklyDifference"
                :icon-color="counters.signColor"
              />
              <span :class="weeklyTotalDifferenceClasses(counters)">
                {{ counters.weeklyTotalDifferenceFormatted }}
              </span>
            </div>
          </div>
          <div class="counters-cell__counter__details--separator" />
        </template>
        <template v-if="counters.isWeeklyTotalDifferencePositive">
          <div class="counters-cell__counter__details">
            <div class="counters-cell__content__counter__value--primary">
              {{ $t('plannings.table.cells.counters.details.majoration') }}
            </div>
            <div class="counters-cell__content__counter__value--information">
              {{ counters.userHoursCounterMajoration }}
            </div>
          </div>
          <div class="counters-cell__counter__details--separator" />
        </template>
        <div class="counters-cell__counter__details">
          <div
            class="
            counters-cell__content__counter__value--neutral
            counters-cell__content__counter__value--bold
          "
          >
            {{ $t('plannings.table.cells.counters.details.end_of_week') }}
          </div>
          <div class="counters-cell__content__counter">
            <CounterCellIcon
              :cell-style="counters.zoomRange.icon"
              :icon-name="counters.circleIconCounterEnd"
              icon-color="#f0900d"
              color="#fdf0c8"
            />
            <span
              class="
              counters-cell__content__counter__value
              counters-cell__content__counter__value--neutral"
            >
              {{ counters.userHoursCounterEndOfWeek }}
            </span>
          </div>
        </div>
        <template
          v-if="counters.isShopWithModulationMajoration &&
            counters.arePaidBreaksActivated &&
            counters.totalBreakDuration"
        >
          <div class="counters-cell__counter__details--separator" />
          <div class="counters-cell__counter__details">
            <div class="counters-cell__content__counter__value--primary">
              {{ $t('plannings.table.cells.counters.details.total_break_duration') }}
            </div>
            <div class="counters-cell__content__counter__value--information">
              {{ counters.totalBreakDuration }}
            </div>
          </div>
          <div class="counters-cell__counter__details--separator" />
        </template>
      </div>
    </template>
  </CountersCellPopover>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import CountersCellPopover from '@app-js/plannings/shared/components/Counter/CountersCellPopover';
import CounterCellIcon from '../CountersCell/CounterCellIcon';

export default {
  name: 'CounterMajDetail',
  components: {
    CountersCellPopover,
    CounterCellIcon,
  },
  methods: {
    weeklyTotalDifferenceClasses(counters) {
      return {
        'counters-cell__content__counter__value--positive': counters.isWeeklyTotalDifferencePositive,
        'counters-cell__content__counter__value--negative': !counters.isWeeklyTotalDifferencePositive,
        'counters-cell__content__counter__value': true,
      };
    },
    weeklyTotalDifferenceBoldClasses(counters) {
      return {
        'counters-cell__content__counter__value--positive': counters.isWeeklyTotalDifferencePositive,
        'counters-cell__content__counter__value--negative': !counters.isWeeklyTotalDifferencePositive,
        'counters-cell__content__counter__value--bold': true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.counters-cell__content__no-content {
  color: $sk-grey;
  font-weight: bold;
}

.counters-cell__content__no-counter {
  background: $sk-grey-10;
  border-radius: 3px;
  width: 104px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-s;
  color: $sk-grey;
}

.counters-cell__content__counter {
  display: flex;
  align-items: center;
}

.counters-cell__content__counter--cursor {
  cursor: pointer;
}

.counters-cell__content__counter__value {
  font-size: $fs-text-s;
  margin-left: 3px;
}

.counters-cell__content__counter__value--bold {
  font-weight: $fw-semi-bold;
}

.counters-cell__content__counter__value--negative {
  color: $sk-blue;
}

.counters-cell__content__counter__value--positive {
  color: $sk-error;
}

.counters-cell__content__counter__value--information {
  color: $sk-grey;
}

.counters-cell__content__counter__value--neutral {
  color: $sk-warning;
}

.counters-cell__content__counter__value--primary {
  color: $sk-black;
}

.counters-cell__counter__details {
  font-size: $fs-text-s;
  display: flex;
  justify-content: space-between;
}

.counters-cell__counter__details--separator {
  border-bottom: 1px solid $sk-grey-10;
  margin: 14px 0;
}

.counters-cell__details-icon {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
}

.counters-cell__duration-container {
  flex: 1 1 50%;
  text-align: center;

  .total-hours {
    color: $sk-grey;
    font-weight: bold;
    line-height: 15px;
    font-size: 1.4em;
  }

  .diff-hours__duration {
    font-size: 1.2em;
  }

  .diff-hours__duration--positive {
    color: $sk-error;
  }

  .diff-hours__duration--negative {
    color: $sk-blue;
  }

  .diff-hours__sign-icon {
    position: relative;
    top: 3px;
    margin-right: 6px;
  }
}
</style>
