<template>
  <div>
    <p>
      {{ $t('organisation_settings.tabs.pos_partners.link_shops', { name: 'Zelty' }) }}
    </p>
    <!-- TO FIX: the spinner of the table needs to be in body to prevent -->
    <!-- little jump when changing page -->
    <div class="pos-card__table">
      <SkTable
        :columns="headers"
        :loading="shopsFetchLoading || zeltyShopsLoading"
      >
        <ShopRow
          v-for="shop in organisationShops"
          :key="shop.id"
          :matching-shop="shop"
          :matched-shops="zeltyShops"
          :matching-shops-list="zeltyMatchingShopsList"
          :label="$t('organisation_settings.tabs.pos_partners.zelty.card.partner_title')"
          @set-matching-shop-list="setZeltyShop"
        />
      </SkTable>
    </div>
    <div class="table__paginate__footer">
      <div>
        <SkOroraButton
          :loading="updatingOrganisationShops"
          @click="handleUpdate"
        >
          {{ $t('organisation_settings.tabs.pos_partners.update') }}
        </SkOroraButton>
      </div>
      <div
        v-if="pagination.total_pages > 1"
        class="paginate__navigation__wrapper"
      >
        <SkPaginate
          v-model="pagination.current_page"
          :total-pages="pagination.total_pages"
          :disabled="shopsFetchLoading"
          @new-page="fetchShops"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';
import { sortCollectionBy } from '@skello-utils/collection';
import { filterShopsWithPosSoftwareAccess } from '../../shared/posFeatureAccess';

import ShopRow from '../ShopRow';

export default {
  name: 'ZeltyCard',
  components: { ShopRow },
  data() {
    return {
      organisationShops: [],
      shopsFetchLoading: false,
      headers: [
        {
          title: this.$t('organisation_settings.tabs.pos_partners.shops_table.skello_title'),
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('organisation_settings.tabs.pos_partners.zelty.card.partner_title'),
          class: 'table-column__filled-section',
        },
        {
          title: null,
          class: 'table-column__empty-section',
        },
      ],
      pagination: {
        current_page: 1,
        per_page: 10,
        total_pages: 0,
      },
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('partnersTools', [
      'zeltyMatchingShopsList',
      'zeltyShopsLoading',
      'updatingOrganisationShops',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    zeltyShops() {
      return this.currentOrganisation.attributes.zeltyShopsList.map(shop => ({
        id: shop[0],
        text: shop[1],
      }));
    },
  },
  mounted() {
    this.fetchShops();
    this.fetchZeltyShops();
  },
  beforeDestroy() {
    this.resetZeltyMatchingShopsList();
  },
  methods: {
    ...mapActions('partnersTools', ['fetchZeltyShops', 'updateZeltyOrganisationShop']),
    ...mapMutations('partnersTools', [
      'resetZeltyMatchingShopsList',
      'setZeltyShop',
      'setOriginalMatching',
    ]),
    fetchShops() {
      this.shopsFetchLoading = true;

      return httpClient
        .get(
          '/v3/api/shops', {
            params: {
              current_page: this.pagination.current_page,
              per_page: this.pagination.per_page,
            },
          },
        )
        .then(response => {
          this.shopsFetchLoading = false;
          this.pagination = response.data.meta.pagination;
          const filteredShops =
            filterShopsWithPosSoftwareAccess(response.data.data, this.isFeatureEnabled);
          this.organisationShops = sortCollectionBy(filteredShops, 'name', 'toLowerCase');
          this.populateShopsMatchingList();
        })
        .catch(error => {
          console.error(error.message);
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    handleUpdate() {
      this.updateZeltyOrganisationShop({
        zeltyMatchingShopsList: this.zeltyMatchingShopsList,
        pagination: this.pagination,
      })
        .then(response => {
          this.organisationShops = sortCollectionBy(response.data, 'name', 'toLowerCase');
          this.resetZeltyMatchingShopsList();
          this.populateShopsMatchingList();
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.zelty.card.update_success'),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('organisation_settings.error_message'),
            variant: 'error',
          });
        });
    },
    populateShopsMatchingList() {
      this.organisationShops.forEach(shop => {
        //  Prevent to erase mapping when changing page
        if (!this.zeltyMatchingShopsList[shop.id]) {
          // Important conversion of Number to String
          // In order to compare id's from the backend and the SkSelect using strings
          // However we dont want to convert nil => null values to "null"
          const zeltyShopId = shop.attributes.zeltyShopId === null ?
            null : String(shop.attributes.zeltyShopId);

          this.zeltyMatchingShopsList[shop.id] = {};
          this.zeltyMatchingShopsList[shop.id] = {
            id: zeltyShopId,
            text: shop.attributes.zeltyShopName,
          };
        }
      });
      this.setOriginalMatching(this.zeltyMatchingShopsList);
    },
  },
};
</script>

<style lang="scss" scoped>

.sk-table.pos-card__table > tbody td {
  border-bottom: none;
}

.pos-card__table {
  min-height: 320px;
}

.table__paginate__footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .paginate__navigation__wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 138px;
  }
}

// Larger space between header and first row than between each row
.sk-table.pos-card__table > tbody tr:first-child td {
  padding-top: 18px;
}
</style>
