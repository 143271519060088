<template>
  <div class="step-four__container">
    <div class="step-four__form">
      <div class="step-four__content-form__header">
        <OrganisationV2Icon
          width="25"
          height="25"
        />
        <div class="step-four__content-form__title">
          {{ $t('self_serve.v5.step_four.company_info') }}
        </div>
      </div>

      <div
        v-if="country === ''"
        class="step-four__loader-wrapper"
      >
        <SkLoader size="large" />
      </div>

      <div
        v-else
        class="step-four__form"
      >
        <FrenchCompanySearch
          v-if="!manualInputSelected && isFrenchVersion"
          :company-name="name"
          :company-registration-number="registrationNumber"
          :on-select-company="autofillBillingInfos"
          @manual-switch-click="switchInput"
        />

        <template v-else>
          <SkOroraInput
            v-model.trim="name"
            type="text"
            :label="$t('self_serve.step_four.placeholders.social_denomination')"
          />
          <SkOroraInput
            v-model.trim="registrationNumber"
            type="text"
            :label="$t(`self_serve.step_four.placeholders.${businessIdLabel}`)"
          />
        </template>

        <SkOroraSelect
          v-model="country"
          :label="$t('self_serve.step_four.country_label')"
          :options="countries"
          :search-placeholder="$t('labels.search')"
          :no-search-result-label="$t('search_bar.no_result')"
        />
      </div>
    </div>
    <div class="step-four__button">
      <SkOroraButton
        id="self-serve-step-four-submit"
        fill-parent
        :disabled="isSubmitDisabled"
        :loading="submitLoading"
        @click="submitForm"
      >
        {{ $t('self_serve.step_three.button_text') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { countryKeys } from '@skello-utils/country-keys.js';
import { arrayToSelectOptions } from '@skello-utils/form';
import {
  OrganisationV2Icon,
  SkOroraInput,
  SkOroraButton,
  SkOroraSelect,
  SkLoader,
} from '@skelloapp/skello-ui';
import { removeWhiteSpaces } from '@app-js/shared/utils/method_helper';
import { getPackOfferNameFromPack } from '@app-js/shared/utils/self_serve/utils';
import parsePhoneNumber from 'libphonenumber-js';
import FrenchCompanySearch from '@app-js/onboarding/self_serve/shared/components/FrenchCompanySearch.vue';

const SOCIAL_DENOMINATION_MIN_LENGTH = 3;

export default {
  name: 'StepFour',
  components: {
    FrenchCompanySearch,
    SkOroraButton,
    SkOroraInput,
    SkOroraSelect,
    OrganisationV2Icon,
    SkLoader,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['next'],
  data() {
    return {
      country: '',
      address: '',
      city: '',
      zipCode: '',
      nameState: null,
      submitLoading: false,
      registrationNumberWithSpacesAllowed: null,
      manualInputSelected: false,
    };
  },
  computed: {
    ...mapState('onboarding', ['currentUser']),
    ...mapGetters('onboarding', ['queryRawBundle', 'salesforceBundle']),
    name: {
      get() {
        return this.nameState;
      },
      set(value) {
        this.validatePresence('name');
        this.nameState = value;
      },
    },
    registrationNumber: {
      get() {
        return (
          this.registrationNumberWithSpacesAllowed &&
          removeWhiteSpaces(this.registrationNumberWithSpacesAllowed)
        );
      },
      set(value) {
        this.validatePresence('registrationNumberWithSpacesAllowed');
        this.registrationNumberWithSpacesAllowed = value;
      },
    },
    countries() {
      return arrayToSelectOptions(countryKeys('FR'), value => this.$t(`countries.${value}`),
      );
    },
    isSubmitDisabled() {
      return (
        !this.name ||
        !this.registrationNumber ||
        this.name?.length < SOCIAL_DENOMINATION_MIN_LENGTH ||
        !this.country
      );
    },
    isFrenchVersion() {
      if (this.country === 'FR') return true;
      return this.country === undefined && this.$i18n.locale === 'fr';
    },
    businessIdLabel() {
      const countryBusinessId = {
        FR: 'siret',
        ES: 'cif',
      };

      return countryBusinessId[this.country] ?? 'other';
    },
  },
  mounted() {
    // Google Analytics script for Marketing team
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'self_serve_step_viewed',
      page_type: 'selfserve',
      page_category: 'selfserve',
      selfserve_version: localStorage.getItem('self-serve-version'),
      step_index: 'stepFour',
      step_name: 'stepFour',
      page_path: window.location.pathname,
      referrer: document.referrer,
    });
  },
  created() {
    const stepFourData = JSON.parse(localStorage.getItem('step_four'));
    window.scrollTo(0, 0);

    if (stepFourData) {
      this.name = stepFourData.name;
      this.registrationNumber = stepFourData.registrationNumber;
      this.country = stepFourData.country;
    } else {
      this.fetchCurrentUser().then(() => {
        const { email, firstName, lang, lastName, phone, shopId } = this.currentUser.attributes;

        // Google Analytics script for Marketing team
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: 'self_serve_lead_qualified',
          page_type: 'selfserve',
          page_category: 'selfserve',
          selfserve_version: localStorage.getItem('self-serve-version'),
          step_index: 'stepFour',
          step_name: 'stepFour',
          page_path: window.location.pathname,
          referrer: document.referrer,
          user_data: {
            email,
            phone,
            first_name: firstName,
            last_name: lastName,
            country_code: lang,
            skello_id: this.currentUser.id,
          },
        });

        if (shopId) {
          this.getLastCreatedShop({ shopId }).then(lastShop => {
            const shopAttributes = lastShop.data.data.attributes;
            this.name = shopAttributes.denominationSociale;
            this.registrationNumber = shopAttributes.siret;
            this.country = shopAttributes.country;
          });

          return;
        }

        const parsedNumber = parsePhoneNumber(phone);
        if (!parsedNumber) {
          captureException(new Error('Self Serve: Invalid phone number'));
          this.country = 'FR';
          return;
        }
        this.country = parsedNumber.country;
      });
    }
  },
  methods: {
    ...mapActions('onboarding', ['fetchCurrentUser', 'getLastCreatedShop', 'updateProspect']),

    async submitForm() {
      if (this.isSubmitDisabled) return;
      this.submitLoading = true;

      const organisation = {
        name: this.name,
        business_sector: null,
        denomination_sociale: this.name,
      };

      const stepThreeData = JSON.parse(localStorage.getItem('step_three'));
      const shop = {
        country: this.country,
        siret: this.registrationNumber,
        sector: stepThreeData?.selectedSector,
        created_from_svc_billing_automation: true,
      };

      // If set, we will skip step 5
      const packName = this.queryRawBundle;
      if (packName) {
        organisation.pack_offer_name = getPackOfferNameFromPack(packName);
        organisation.bundle = this.salesforceBundle(packName);
        shop.badging =
          packName === 'planning-timeclock' || packName === 'timeclock';
      }

      this.sendSegmentTrackers(stepThreeData);

      try {
        const response = await httpClient.post(
          '/v3/api/self_serve/create_shop_and_organisation',
          { organisation, shop },
        );

        localStorage.setItem(
          'step_five',
          JSON.stringify({
            sector: stepThreeData?.selectedSector,
            currencySymbol: response.data.data.attributes.currencySymbol,
          }),
        );

        const stepFourData = {
          name: this.name,
          registrationNumber: this.registrationNumber,
          country: this.country,
          address: this.address,
          city: this.city,
          zipCode: this.zipCode,
        };

        localStorage.setItem('step_four', JSON.stringify(stepFourData));

        if (packName) {
          // We need to do this here if we are skipping step 5
          await this.updateProspect({ prospect: { step: 'permissions_set' } });
        }

        this.$skAnalytics.track('self_serve_convention', {
          source: response.data.data.attributes.conventionName,
        });
        this.$skAnalytics.track('ss_shop_info_step4_next_button');
        this.next({ packName });
      } catch (_) {
        this.$skToast({
          message: this.$t('self_serve.errors.generic'),
          variant: 'error',
        });
      } finally {
        this.submitLoading = false;
      }
    },
    sendSegmentTrackers(stepThreeData) {
      const stepOneData = JSON.parse(localStorage.getItem('step_one'));

      const params = {
        first_name: this.currentUser.attributes.firstName,
        last_name: this.currentUser.attributes.lastName,
        email: this.currentUser.attributes.email,
        phone: this.currentUser.attributes.phone,
        employees: stepThreeData?.selectedEmployeesCount,
        sector: stepThreeData?.selectedSector,
        country: this.country,
        company: this.name,
        country_code: stepOneData?.country_code,
        customer_id: Date.now() + Math.random(),
      };

      this.$skAnalytics.track('self_serve_step_four_continue', params);

      if (this.manualInputSelected) {
        this.$skAnalytics.track('self_serve_manual_company_entry_success');
      }
    },
    validatePresence(attribute) {
      const value = this[attribute];

      if (value === '') {
        this[`${attribute}ErrorMessage`] = this.$t(
          'organisation_creation.error.mandatory',
        );
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    autofillBillingInfos(company) {
      if (!company) {
        this.name = null;
        this.registrationNumber = null;
        this.address = null;
        this.city = null;
        this.zipCode = null;
        return;
      }

      this.name = company.name;
      this.registrationNumber = company.shopRegistrationNumber;
      this.address = company.address;
      this.city = company.city;
      this.zipCode = company.zipCode;
    },
    switchInput() {
      this.manualInputSelected = !this.manualInputSelected;
      this.autofillBillingInfos();
      this.$skAnalytics.track('self_serve_manual_company_entry_click');
    },
  },
};
</script>

<style lang="scss" scoped>
.step-four__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.step-four__content-form__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: $sm-width) {
    padding: 0;
  }

  svg {
    flex-shrink: 0;
  }
}

.step-four__content-form__title {
  font-size: $fs-heading-m;
  font-weight: $fw-semi-bold;
  line-height: 24px;
  margin-bottom: 0;
  padding-left: 8px;
}

.step-four__content-form__row-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step-four__content-form__row {
  flex-grow: 1;
}

.step-four__container {
  display: flex;
  gap: 40px;
  flex-direction: column;
}

.step-four__content-container {
  display: flex;
  flex-direction: column;
}

.step_four__link {
  margin-left: 10px;
  margin-top: 3px;
  font-weight: 400;
  font-size: 12.5px;
  color: $sk-grey;
}

.step-four__button {
  width: 100%;

  @media (max-width: $sm-width) {
    position: fixed;
    bottom: 0px;
    left: 0;
    padding: 16px;
    background-color: white;
  }
}

.step-four__loader-wrapper {
  display: grid;
  place-items: center;
  min-height: 200px;
}
</style>
