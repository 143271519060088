<template>
  <div class="expired-token__wrapper">
    <BackgroundCornerShape
      class="expired-token__background-corner-shape"
      :width="backgroundCornerShapeWidth"
      :height="backgroundCornerShapeHeight"
      :view-box-height="viewBoxHeight"
      fill="#febf3f"
    />
    <div class="expired-token__content">
      <div class="expired-token__logo-container">
        <img
          class="expired-token__logo"
          src="@app-js/static/shared/assets/img/logo-skello.svg"
        >
      </div>
      <div class="expired-token__title">
        {{ $t('self_serve.expired_token.title') }}
      </div>
      <div class="expired-token__svg">
        <ExpiredActivationLink
          :height="height"
          :width="width"
        />
      </div>
      <div class="expired-token__text">
        {{ $t('self_serve.expired_token.text_start') }}
        <span class="expired-token__text--bold">
          &nbsp;{{ $t('self_serve.expired_token.time_limit') }}
        </span>
        &nbsp;{{ $t('self_serve.expired_token.text_end') }}
      </div>
      <div class="expired-token__button">
        <SkOroraButton :href="loginPage">
          {{ $t('self_serve.expired_token.button_text') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import { SkOroraButton } from '@skelloapp/skello-ui';
import BackgroundCornerShape from '../../shared/assets/svg/BackgroundCornerShape';
import ExpiredActivationLink from '../../shared/assets/svg/ExpiredActivationLink';

const MIN_SCREEN_WIDTH = 420; // px

export default {
  name: 'ExpiredToken',
  components: {
    SkOroraButton,
    BackgroundCornerShape,
    ExpiredActivationLink,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    languageParams() {
      const lang = this.$route.query.lang;

      if (lang === 'fr' || !lang) return '';

      return `?lang=${lang}`;
    },
    loginPage() {
      return `/users/sign_in${this.languageParams}`;
    },
    height() {
      return this.screenWidth > MIN_SCREEN_WIDTH ? '187' : '100';
    },
    width() {
      return this.screenWidth > MIN_SCREEN_WIDTH ? '126' : '100';
    },
    backgroundCornerShapeWidth() {
      return this.screenWidth < MIN_SCREEN_WIDTH ? '320' : '183';
    },
    backgroundCornerShapeHeight() {
      return this.screenWidth < MIN_SCREEN_WIDTH ? '602' : '438';
    },
    viewBoxHeight() {
      return this.screenWidth < MIN_SCREEN_WIDTH ? '400' : '438';
    },
  },
  created() {
    window.addEventListener('resize', this.updateResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateResize);
  },
  methods: {
    updateResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.expired-token__wrapper {
  background-color: $sk-blue;
  height: 100vh;
  width: 100%;
}

.expired-token__background-corner-shape {
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: $sm-width) {
    right: 0;
  }
}

.expired-token__content {
  position: absolute;
  left: 130px;
  right: 130px;
  top: 74px;
  bottom: 74px;
  background-color: $sk-white;
  border-radius: 12px;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, .24);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 60px;

  @media (max-width: 785px) {
    z-index: 0;
    margin: 20px;
    padding: 40px 20px 30px;
    right: 0;
    left: 0;
  }

  @media (max-width: $md-width) {
    top: 0;
    bottom: 0;
    padding: 30px 20px;
  }

  .expired-token__logo-container {
    border-radius: 126.667px;
    box-shadow: 14px 14px 60px rgb(0 0 0 / 12%);
    padding: 15px 20px;
    width: 114px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;

    .expired-token__logo {
      width: 70px;
    }

    @media (max-width: 320px) {
      width: 104px;
      height: 47px;
    }
  }

  .expired-token__title {
    max-width: 766px;
    font-weight: $fw-semi-bold;
    font-size: $fs-display-xs;

    @media (max-width: $md-width) {
      font-size: 28px;
      text-align: center;
      margin-top: 29px;
    }

    @media (max-width: 320px) {
      margin-top: 22px;
      font-size: 25px;
    }
  }

  .expired-token__text {
    font-size: $fs-text-l;
    max-width: 737px;
    text-align: center;

    .expired-token__text--bold {
      font-weight: $fw-semi-bold;
    }

    @media (max-width: $sm-width) {
      margin-top: 32px;
    }

    @media (max-width: 320px) {
      margin-top: 20px;
      font-size: $fs-text-m;
    }
  }

  .expired-token__button {
    @media (max-width: $md-width) {
      margin-top: 28px;
    }

    @media (max-width: 320px) {
      margin-top: 20px;
    }
  }

  .expired-token__svg {
    @media (max-width: $sm-width) {
      margin-top: 32px;
    }

    @media (max-width: 320px) {
      margin-top: 20px;
    }
  }
}
</style>
