<template>
  <div class="navbar__actions">
    <router-link
      v-if="canAccessShopSettingsPage"
      v-tooltip.oneLine="$t('navbar.actions.shop_params')"
      :to="shopSettingsUrl"
      class="navbar__actions__settings"
      data-test="navbar-shop_settings"
    >
      <CogIcon
        fill=""
        height="16"
        width="19"
      />
    </router-link>

    <NotificationCounter
      v-if="!isEmployee
        && isFeatureEnabled(FEATURES.FEATURE_DASHBOARD, currentShop.id)
        && currentLicense.attributes.canEditManagementIndicators"
      v-tooltip="$t('navbar.actions.dashboard')"
      :to="homeDashboardUrl"
      :active="$route.matched.some(({ name }) => name === 'home_dashboard')"
    />

    <div class="sk-divider--light navbar__divider" />

    <UserDropdown />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features.js';
import NotificationCounter from './NotificationsCounter';
import UserDropdown from './UserDropdown';

export default {
  name: 'Actions',
  components: { NotificationCounter, UserDropdown },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters({ isSystemAdmin: 'currentLicense/isSystemAdmin', isEmployee: 'currentLicense/isEmployee' }),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('currentOrganisation', ['atLeastOneShopWithPunchClock']),
    canAccessShopSettingsPage() {
      return this.isSystemAdmin ||
        this.currentLicense.attributes.canReadShopRulesAndAbsences ||
        this.currentLicense.attributes.canEditShopRulesAndAbsences ||
        this.currentLicense.attributes.canCreatePosteAndTeam ||
        this.currentLicense.attributes.canEditLegalAlertsConfig ||
        this.currentLicense.attributes.canEditShopBillingData ||
        (
          this.currentLicense.attributes.canEditPunchClockSettings &&
          this.atLeastOneShopWithPunchClock
        );
    },
    shopSettingsUrl() {
      // Fallback on user principal shop
      const shopId = this.navContext.shopId || this.currentUser.attributes.shopId;

      return {
        name: 'shop_settings',
        params: { shop_id: shopId },
      };
    },
    homeDashboardUrl() {
      // Fallback on user principal shop
      const shopId = this.navContext.shopId || this.currentUser.attributes.shopId;

      return {
        name: 'home_dashboard',
        params: { shop_id: shopId },
      };
    },
  },
  methods: {
    ...mapActions('currentUser', ['updateCurrentUser']),
  },
};
</script>

<style lang="scss" scoped>
.navbar__actions {
  display: flex;
  margin-left: auto;
  align-items: center;

  a.navbar__actions__settings {
    line-height: 5px;

    &.router-link-active {
      svg {
        fill: $sk-blue;
      }
    }
  }
}

.navbar__help-link {
  font-size: $fs-text-m;
  color: $sk-black;
  font-weight: $fw-regular;
  margin: 0;

  &:hover,
  &:active {
    color: $sk-blue;
  }
}

.navbar__divider {
  margin: 0 25px;

  @media (max-width: $md-width) {
    margin: 0 15px;
  }
}
</style>
