import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import { filterObjectFields } from '@skello-utils/form';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import { FEATURES } from '@app-js/shared/constants/features.js';

// Fields to exclude from sticky bar & window confirm
const SHOP_FIELDS_TO_EXCLUDE = [
  'isAnnualizationV2Active',
  'countersInitializationDoneAt',
  'dayBadgingActivated',
  'mealCompensationActivated',
  'holidaysCounterConfigured',
  'mealCompensationDone',
  'modulation',
  'modulationMajoration',
  'modulationStatus',
  'plcInitializationDoneAt',
  'plcInitialized',
  'plcStatus',
  'pauseCompensationStartsAt',
  'holidaysCounterActivationYear',
];

const isCanaryReleaseActive = (devFlag, shopId, rootState) => {
  const { canaryReleaseType, canaryReleaseIds } = devFlag;

  // If array empty, active for everyone
  if (!canaryReleaseIds || canaryReleaseIds.length === 0) return true;

  return canaryReleaseIds.includes((() => {
    switch (canaryReleaseType) {
      case 'shop':
        return shopId.toString();
      case 'organisation':
        return rootState.currentOrganisation.currentOrganisation.id.toString();
      case 'user':
        return rootState.currentUser.currentUser.id.toString();
      default:
        console.error(`Canary release type unknown: '${canaryReleaseType}'`);
        return undefined;
    }
  })());
};

const changeShopConvention = (state, convention) => {
  state.currentShop.relationships.convention.attributes.name = convention.name;
  state.currentShop.relationships.convention.attributes.legibleName = convention.legibleName;
  state.currentShop.relationships.convention.attributes.baseSector = convention.baseSector;
};

const formatShopHoursChangedEvent = state => {
  const oldOpeningTime = state.originalCurrentShopData.attributes.openingTime;
  const oldClosingTime = state.originalCurrentShopData.attributes.closingTime;
  const newOpeningTime = state.currentShop.attributes.openingTime;
  const newClosingTime = state.currentShop.attributes.closingTime;

  if (oldOpeningTime === newOpeningTime && oldClosingTime === newClosingTime) return;

  // eslint-disable-next-line consistent-return
  return {
    previousHours: [oldOpeningTime, oldClosingTime],
    newHours: [newOpeningTime, newClosingTime],
  };
};

const initialState = {
  currentShop: {
    id: 'all',
    type: 'shop',
    attributes: {},
    relationships: null,
  },
  originalCurrentShopData: {
    id: 'all',
    type: 'shop',
    attributes: {},
    relationships: null,
  },
  originalConventionName: '',
  error: false,
  loading: false,
  loadingUpdate: false,
  isPlcFieldExcluded: false,
  currentUserShops: {},
};

const baseRulesParams = state => {
  const shop = state.currentShop.attributes;
  const params = {
    modulation: shop.modulation,
    opening_time: shop.openingTime,
    closing_time: shop.closingTime,
    modulation_majoration: shop.modulationMajoration,
    plc_start_month: shop.plcStartMonth,
    paid_vacation_calculation_type: shop.paidVacationCalculationType,
    paid_vacation_acquisition_number: shop.paidVacationAcquisitionNumber,
    max_hours_without_pause: shop.maxHoursWithoutPause,
    modulation_status: shop.modulationStatus,
    plc_status: shop.plcStatus,
    pause_compensation_starts_at: shop.pauseCompensationStartsAt,
    meal_compensation_activated: shop.mealCompensationActivated,
    legal_weekly_hours: shop.legalWeeklyHours,
    holidays_counter_activation_year: shop.holidaysCounterActivationYear,
    hourly_wage_rate: shop.hourlyWageRate,
  };

  return params;
};

const billingInfoParams = state => {
  const shop = state.currentShop.attributes;
  const params = {
    name: shop.name,
    pay_identification_number: shop.payIdentificationNumber,
    denomination_sociale: shop.denominationSociale,
    address: shop.address,
    zipcode: shop.zipcode,
    city: shop.city,
    country: shop.country,
    country_region: shop.countryRegion,
    timezone: shop.timezone,
    siret: shop.siret,
    tva_number: shop.tvaNumber,
  };

  return params;
};

const shopExtendedInfoParams = state => {
  const shop = state.currentShop.attributes;

  return {
    naf_code: shop.nafCode,
    sst_code: shop.sstCode,
    urssaf_code: shop.urssafCode,
  };
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  setShopAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentShop.attributes[attribute] = payload[attribute];
    });
  },

  setShopConvention(state, convention) {
    changeShopConvention(state, convention);
  },

  squashOriginalCurrentShopData(state) {
    state.originalCurrentShopData = cloneDeep(state.currentShop);
  },

  squashCurrentShop(state) {
    state.currentShop = cloneDeep(state.originalCurrentShopData);
  },

  resetError(state) {
    state.error = false;
  },

  resetShopOpeningHours(state) {
    state.currentShop.attributes.openingTime = state.originalCurrentShopData.attributes.openingTime;
    state.currentShop.attributes.closingTime = state.originalCurrentShopData.attributes.closingTime;
  },

  resetLegalWeeklyHours(state) {
    state.currentShop.attributes.legalWeeklyHours =
      state.originalCurrentShopData.attributes.legalWeeklyHours;
  },

  resetConvention(state) {
    changeShopConvention(state, state.originalCurrentShopData.relationships.convention.attributes);
  },

  currentShopSuccess(state, payload) {
    state.currentShop = payload.data;
    if (state.currentShop.id === 'all') {
      return;
    }

    if (payload.included) {
      const convention = associationMatcherItem(state.currentShop, payload.included, {
        key: 'convention',
        type: 'convention',
      });
      const clusterNode = associationMatcherItem(state.currentShop, payload.included, {
        key: 'clusterNode',
        type: 'clusterNode',
      });
      Object.assign(state.currentShop.relationships, { convention, clusterNode });

      if (state.currentShop.relationships.convention.attributes) {
        state.originalConventionName = state.currentShop.relationships.convention.attributes.name;
      }
    }

    state.originalCurrentShopData = cloneDeep(state.currentShop);
  },

  currentShopError(state, error) {
    state.error = error;
  },

  updateOnboardingStatus(state, payload) {
    state.currentShop.attributes.onboarding_status = {
      ...state.currentShop.attributes.onboarding_status,
      ...payload,
    };
  },
  setCurrentUserShops(state, payload) {
    state.currentUserShops = payload.reduce((result, shop) => {
      result[shop.id] = { id: shop.id, ...shop.attributes };
      return result;
    }, {});
  },
};

const actions = {
  updateCurrentShop({ commit, rootGetters }, { shopId }) {
    commit('performingRequest');
    if (shopId !== 'all') {
      const params = {
        include_shops_where_employee: rootGetters['currentLicense/isEmployee'],
      };

      return httpClient
        .get(`/v3/api/shops/${shopId}/show_extended`, { params })
        .then(response => {
          commit('currentShopSuccess', response.data);

          return response;
        })
        .catch(error => {
          commit('currentShopError', error.response.data);
          throw error;
        })
        .finally(() => {
          commit('requestComplete');
        });
    }

    // Unselect current shop
    return new Promise((resolve, reject) => {
      commit('currentShopSuccess', {
        data: {
          id: 'all',
          type: 'shop',
          attributes: {},
          relationships: {},
        },
      });
      commit('requestComplete');
      resolve();
    });
  },
  updateShop({ state, commit }, { shopId, overrideParams }) {
    const defaultParams = {
      shop: baseRulesParams(state),
      shop_info: billingInfoParams(state),
      shop_extended_info: shopExtendedInfoParams(state),
      convention: { name: state.currentShop.relationships.convention.attributes.name },
      onboarding_status: state.currentShop.attributes.onboarding_status,
    };

    const params = overrideParams || defaultParams;

    commit('performingRequest', 'loadingUpdate');
    return httpClient
      .patch(`/v3/api/shops/${shopId}`, params)
      .then(response => {
        try {
          Vue.prototype.$svcEvents.create(
            EVENT_SUBTYPE_ACTION.SHOP_HOURS_CHANGED,
            formatShopHoursChangedEvent(state),
          );
        } catch (error) {
          console.error(error);
        }

        commit('currentShopSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('currentShopError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'loadingUpdate');
      });
  },
  fetchShops({ commit }, allOrgaShops = false) {
    commit('performingRequest');

    return httpClient
      .get('/v3/api/shops', { params: { include_shops_where_employee: true, skip_pagination: true, all_orga_shops: allOrgaShops } })
      .then(response => {
        commit('setCurrentUserShops', response.data.data);
      })
      .catch(error => {
        throw error;
      }).finally(() => {
        commit('requestComplete');
      });
  },
};

const getters = {
  name: state => state.currentShop.attributes.name,
  isLoaded: state => state.currentShop.id && state.loading === false,
  isMealCompensationDone(state, _selfGetters, _rootState, rootGetters) {
    return state.currentShop.attributes.mealCompensationDone &&
      rootGetters['features/isFeatureEnabled'](
        FEATURES.FEATURE_MEAL_RULES,
        state.currentShop.id,
      );
  },

  // If feature is enabled this means that the shop has one or unlimited document templates allowed.
  hasShopDocumentTemplatesAccess: (state, _selfGetters,
    _rootState, rootGetters) => shopId => rootGetters['features/isFeatureEnabled'](
    FEATURES.FEATURE_DOCUMENT_TEMPLATES,
    shopId ?? state.currentShop.id,
  ),

  limitedDocumentTemplates(state, _selfGetters, rootState, rootGetters) {
    const contextShopId = rootState.selectedEmployee?.employee?.attributes?.shopId ??
      state.currentShop.id;

    if (!contextShopId) {
      return true;
    }

    const documentTemplatesLimit = rootGetters['features/getFeatureLimit'](FEATURES.FEATURE_DOCUMENT_TEMPLATES, contextShopId);

    return (documentTemplatesLimit === 1) || (documentTemplatesLimit === 0);
  },

  isEsignatureTimeSheetEnabled: (state, _selfGetters, _rootState, rootGetters) => (requireAll = false) => rootGetters['features/isFeatureEnabled'](
    FEATURES.FEATURE_ESIGNATURE_TIMESHEET,
    state.currentShop.id,
    requireAll,
  ),
  isEsignatureAdministrativeDocsEnabled:
    (state, _selfGetters, _rootState, rootGetters) => (requireAll = false) => {
      const userPrimaryShopId = rootGetters['selectedEmployee/primaryShopNode']?.attributes.shopId;
      const shopIdToUse = state.currentShop.id !== 'all' ? userPrimaryShopId : state.currentShop.id;
      return rootGetters['features/isFeatureEnabled'](
        FEATURES.FEATURE_ESIGNATURE_ADMINISTRATIVE_DOCS,
        shopIdToUse,
        null,
        requireAll,
      );
    },
  modulationActivated: state => state.currentShop.attributes.modulation,
  sector: state => state.currentShop.relationships?.convention?.attributes?.sector || 'general',
  isHospitalitySector: (state, selfGetters) => selfGetters.sector === 'hospitality',
  defaultHourlyWageRate: () => 43.0,
  unsavedChangesToCurrentShop(state, selfGetters) {
    if (selfGetters.checkFeatureFlag('FEATURE_N1_N_PTO_TRACKER') && !state.isPlcFieldExcluded) {
      SHOP_FIELDS_TO_EXCLUDE.push('plcStartMonth');
      state.isPlcFieldExcluded = true;
    }
    const currentLocalShop =
      filterObjectFields(state.currentShop.attributes, SHOP_FIELDS_TO_EXCLUDE);
    const originalCurrentShop =
      filterObjectFields(state.originalCurrentShopData.attributes, SHOP_FIELDS_TO_EXCLUDE);

    return (
      JSON.stringify(currentLocalShop) !== JSON.stringify(originalCurrentShop) ||
      state.originalConventionName !== state.currentShop.relationships.convention.attributes.name
    );
  },
  changesMadeToShopOpeningHours({ currentShop, originalCurrentShopData }) {
    return currentShop.attributes.openingTime !==
      originalCurrentShopData.attributes.openingTime ||
      currentShop.attributes.closingTime !==
      originalCurrentShopData.attributes.closingTime;
  },
  changesMadeToLegalWeeklyHours({ currentShop, originalCurrentShopData }) {
    return currentShop.attributes.legalWeeklyHours !==
      originalCurrentShopData.attributes.legalWeeklyHours;
  },
  switchingToNonHealthConvention({ currentShop, originalCurrentShopData }) {
    return originalCurrentShopData.relationships.convention.attributes.baseSector === 'health' &&
      currentShop.relationships.convention.attributes.baseSector !== 'health';
  },
  showStickyBar(state, selfGetters, rootState) {
    return selfGetters.unsavedChangesToCurrentShop &&
      rootState.currentLicense.currentLicense.attributes.canEditShopRulesAndAbsences;
  },
  isVariableContractHoursAvailable(state, selfGetters, _rootState, rootGetters) {
    return selfGetters.checkFeatureFlag('FEATURE_VARIABLE_CONTRACT_HOURS_ENABLED') &&
      rootGetters['features/isFeatureEnabled'](
        FEATURES.FEATURE_VARIABLE_CONTRACT_HOURS,
        state.currentShop.id,
      );
  },
  isRollingPostMvpAvailable(_state, selfGetters) {
    return selfGetters.isVariableContractHoursAvailable &&
      selfGetters.isDevFlagEnabled('FEATURE_VARIABLE_CONTRACT_HOURS_POST_MVP_ENABLED');
  },
  isShopOnPaidVacationCalculationTypeOpeningDay(state, _selfGetters, rootState) {
    const { config } = rootState.config;
    const openingDay = config.absence_data.opening_day_hours_key;

    return config.paid_vacation_calculation_types[openingDay] ===
      state.currentShop.attributes.paidVacationCalculationType;
  },
  isShopOnPaidVacationCalculationTypeCalendarDay(state, _selfGetters, rootState) {
    const { config } = rootState.config;
    const calendarDay = config.absence_data.calendar_day_hours_key;

    return config.paid_vacation_calculation_types[calendarDay] ===
      state.currentShop.attributes.paidVacationCalculationType;
  },
  isShopLinkedToPosPartner(state, _selfGetters, rootState) {
    const { lightspeedShopId, tillerShopId, zeltyShopId } = state.currentShop.attributes;

    if (lightspeedShopId || tillerShopId || zeltyShopId) return true;

    const { id: shopId } = rootState.currentShop.currentShop;

    const { ladditionMatchingShopsList } = rootState.partnersTools;

    if (ladditionMatchingShopsList[shopId] !== undefined) return true;

    const { integrations } = rootState.partnersTools;

    const isShopIntegrated = Object.values(integrations)
      .some(integration => integration.registeredIntegrations
        .some(registeredIntegration => registeredIntegration.shopId === shopId));

    if (isShopIntegrated) return true;

    return false;
  },
  is24hShop: state => (
    state.currentShop.attributes.openingTime === state.currentShop.attributes.closingTime
  ),
  currentShopOpeningAndClosingTime: (state, _selfGetters, _rootState, rootGetters) => {
    const currentDate = rootGetters['planningsState/currentDate'];

    return openingAndClosingTimeAt(
      state.currentShop.attributes.openingTime,
      state.currentShop.attributes.closingTime,
      skDate.utc(currentDate).format(),
    );
  },
  isAnnualizedWorkingTimeAvailable: (state, selfGetters, _rootState, rootGetters) => (
    { shop = state.currentShop } = {},
  ) => (
    rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_ANNUALIZATION_COUNTER, shop.id) &&
      selfGetters.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_ENABLED', shop)
  ),
  isRttCounterAvailable: (state, selfGetters, _rootState, rootGetters) => {
    const isRttCounterEnabled = rootGetters['features/isFeatureEnabled'](
      FEATURES.FEATURE_RTT_COUNTER,
      state.currentShop.id,
    );

    return isRttCounterEnabled && selfGetters.checkFeatureFlag('FEATURE_RTT_TRACKER');
  },
  checkFeatureFlag:
    (state, _selfGetters, rootState) => (
      flagName,
      shop = state.currentShop,
      convention = state.currentShop.relationships?.convention) => {
      const featureFlags = rootState.config.featureFlags.feature_flags;

      let country = shop.attributes.country?.toLowerCase();
      if (rootState.config.config.dom_tom.includes(country)) {
        country = 'fr';
      }

      const featureFlagCountries = rootState.config.config.feature_flag_countries;

      if (!Object.values(featureFlagCountries).includes(country)) {
        country = featureFlagCountries.OTHER;
      }

      const featureFlag = featureFlags.find(flag => flag.name === flagName);
      if (!featureFlag || !featureFlag.sectors || !featureFlag.languages) return false;

      const sector = convention?.attributes?.sector || 'general';
      return featureFlag.languages.includes(country) && featureFlag.sectors.includes(sector);
    },
  isDevFlagEnabled:
    (state, _selfGetters, rootState) => (flagName, shopId = state.currentShop.id) => {
      const devFlags = rootState.config.featureFlags.feature_flags_dev;

      const devFlag = devFlags.find(currentDevFlag => currentDevFlag.name === flagName);

      if (!devFlag || !devFlag.active) {
        return false;
      }

      if (!devFlag.canaryReleaseType) {
        return true;
      }

      return isCanaryReleaseActive(devFlag, shopId, rootState);
    },
  isClockRulesVisible: (state, _selfGetters, _rootState, rootGetters) => (
    rootGetters['currentLicense/canEditPunchClockSettings'] &&
      rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_BADGING, state.currentShop.id)
  ),
  hasMealRule(state) {
    return state.currentShop.attributes.mealCompensationDone &&
      state.currentShop.attributes.isMealRuleBenefitInKind;
  },
  isShopHoursCounterActivated: state => (shopId = state.currentShop.id) => (
    Object.values(state.currentUserShops)
      .find(currentUserShop => Number(currentUserShop.id) === shopId)?.modulation
  ),
  shouldShowAdminOnboarding(state, selfGetters, rootState, rootGetters) {
    return rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_PLANNING_ACCESS, state.currentShop.id) &&
      rootState.currentUser.currentUser.id.length > 0 && (
      // # user related
      (!rootState.currentUser.currentUser.attributes.isOnboardingEmployeeFilled) ||
      // # shop related
      (
        selfGetters.isCurrentShopSelected &&
        state.currentShop.attributes.onboardingStatus && (
          (
            !state.currentShop.attributes.onboardingStatus.positions_filled &&
            state.currentShop.attributes.postesCount === 0
          ) ||
          (
            !state.currentShop.attributes.onboardingStatus.employees_filled &&
            !state.currentShop.attributes.hasPlanningUsers
          )
        )
      )
    );
  },
  isCurrentShopSelected: state => state.currentShop.id !== undefined && state.currentShop.id !== 'all',
  isAllRequestsView: state => state.currentShop.id && state.currentShop.id === 'all',
  arePaidBreaksActivated:
    (state, selfGetters) => ({
      shop = state.currentShop,
      convention = state.currentShop.relationships?.convention,
    } = {}) => selfGetters.isDevFlagEnabled('FEATUREDEV_CANARY_PAID_BREAKS', shop.id) &&
        convention.attributes.dissociateWorkBreakAndPresenceTimeImpact &&
        shop.attributes.pauseCompensationStartsAt,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
