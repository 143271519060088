<template>
  <div class="step-four__content-form__row-wrapper">
    <GlobalEvents
      @keyup.escape="clearSuggestedCompanyRegistrations"
      @click="handleClickOutside"
    />

    <div class="step-four__content-form__row">
      <SkOroraSearch
        ref="searchInput"
        v-model.trim="inputValue"
        type="text"
        :label="$t('self_serve.step_four.placeholders.search_company')"
        :debounce="handleSearch"
        :debounce-ms="300"
        @focus="handleSearch"
        @clear-value="clearSuggestedCompanyRegistrations"
        @blur="handleReposition"
      />

      <div>
        <SkDropdown
          ref="companyDropdown"
          placement="bottom-start"
          y-offset="4px"
          :trigger="null"
        >
          <!-- eslint-disable-next-line vue/v-slot-style -->
          <template #menu>
            <SuggestedCompanyRegistrations
              :suggested-companies="suggestedCompanyRegistrations"
              :related-input="$refs.searchInput"
              :is-loading="isLoading"
              @manual-switch-click="$emit('manual-switch-click', $event)"
              @select-suggested-company="autofillBillingInfos"
            />
          </template>
        </SkDropdown>
        <div class="step-four__siret-search-subtitle">
          <SiretManualInputLink @manual-switch-click="$emit('manual-switch-click', $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { svcBillingAutomationClient as billingAutomationClient } from '@skello-utils/clients';
import {
  SkDropdown,
  SkOroraSearch,
} from '@skelloapp/skello-ui';
import GlobalEvents from 'vue-global-events';
import { getNaf } from '@skello-utils/naf';
import { isSafariBrowser } from '@app-js/shared/utils/browser';
import { isIOS } from '@app-js/shared/utils/mobile';
import SuggestedCompanyRegistrations from './SuggestedCompanyRegistrations/index.vue';
import SiretManualInputLink from './SiretManualInputLink.vue';

export default {
  name: 'FrenchCompanySearch',
  components: {
    GlobalEvents,
    SuggestedCompanyRegistrations,
    SiretManualInputLink,
    SkDropdown,
    SkOroraSearch,
  },
  props: {
    onSelectCompany: {
      type: Function,
      required: true,
    },
    companyName: {
      type: String,
      default: undefined,
    },
    companyRegistrationNumber: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      searchValue: null,
      name: null,
      registrationNumber: null,
      suggestedCompanyRegistrations: [],
      isLoading: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        if (
          !this.name &&
          !this.registrationNumber &&
          !this.companyName &&
          !this.companyRegistrationNumber
        ) return '';

        return this.formatCompanyInfo(
          this.companyName ?? this.name,
          this.companyRegistrationNumber ?? this.registrationNumber,
        );
      },
      set(value) {
        if (!value) {
          this.resetCompanySelection();
        }

        this.searchValue = value;
      },
    },
  },
  created() {
    if (this.companyName && this.companyRegistrationNumber) {
      this.inputValue = this.formatCompanyInfo(
        this.companyName,
        this.companyRegistrationNumber,
      );
    }
  },
  methods: {
    formatCompanyInfo(name, registrationNumber) {
      return `${name} - ${registrationNumber}`;
    },
    async lookupCompanyRegistrationsByReference() {
      return billingAutomationClient.getCompanyRegistrationsByReference(
        this.searchValue,
      );
    },
    async lookupCompanyRegistrationsByName() {
      return billingAutomationClient.getCompanyRegistrationsByName(
        this.searchValue,
      );
    },
    hasExactWordMatch(companyName, searchedText) {
      const words = companyName.split(' ');
      return words.some(word => word.toLowerCase() === searchedText.toLowerCase());
    },
    mapCompany(suggestedCompany) {
      return {
        address: suggestedCompany.address,
        city: suggestedCompany.city,
        creationDate: suggestedCompany.creationDate,
        shopRegistrationNumber: suggestedCompany.shopRegistrationNumber,
        mainActivity: getNaf(
          suggestedCompany.mainActivity.replaceAll('.', ''),
        ),
        name: suggestedCompany.name,
        organisationRegistrationNumber:
          suggestedCompany.organisationRegistrationNumber,
        zipCode: suggestedCompany.zipCode,
      };
    },
    groupCompaniesByMatch(companies, searchedText) {
      // Group the companies into three categories:
      // - Those with an exact match.
      // - Those with a word match.
      // - The others.
      const exact = [];
      const contains = [];
      const others = [];

      companies.forEach(company => {
        const companyName = company.name.toLowerCase();
        if (companyName === searchedText) {
          exact.push(company);
        } else if (this.hasExactWordMatch(companyName, searchedText)) {
          contains.push(company);
        } else {
          others.push(company);
        }
      });

      return { exact, contains, others };
    },
    populateSuggestedCompanyRegistrations(inseeCompanies) {
      const searchedText = this.searchValue.toLowerCase();

      const companies = inseeCompanies.map(company => {
        const {
          address,
          city,
          creationDate,
          mainActivity,
          name,
          organisationRegistrationNumber,
          shopRegistrationNumber,
          zipCode,
        } = company;

        return {
          address,
          city,
          creationDate,
          name,
          organisationRegistrationNumber,
          shopRegistrationNumber,
          zipCode,
          displayLabel: this.highlightText(name, searchedText),
          mainActivity: getNaf(mainActivity.replaceAll('.', '')),
        };
      }).sort((companyA, companyB) => companyA.name.localeCompare(companyB.name));

      const { exact, contains, others } = this.groupCompaniesByMatch(companies, searchedText);

      this.sortCompaniesWhichContainSearch(contains);
      this.addSortingInfoToCompanies(others);
      this.sortOtherCompanies(others);

      // Put the companies in the order of exact match, word match and then the others.
      this.suggestedCompanyRegistrations = [...exact, ...contains, ...others];
    },
    getHighlightedSegments(displayLabel) {
      return displayLabel.filter(segment => segment.isHighlighted);
    },
    addSortingInfoToCompanies(companies) {
      companies.forEach(company => {
        company.consecutiveHighlightedWords =
        this.countConsecutiveWordHighlights(company.displayLabel);

        const highlightedSegments = this.getHighlightedSegments(company.displayLabel);
        company.firstHighlightedIndex = highlightedSegments[0]?.startIndex;
        company.numberOfHighlightedWords = highlightedSegments.length;
      });
    },
    countConsecutiveWordHighlights(displayLabel) {
      let maxConsecutive = 0;
      let currentConsecutive = 0;

      displayLabel.forEach(item => {
        if (item.isHighlighted) {
          currentConsecutive += 1;
          if (maxConsecutive < currentConsecutive) maxConsecutive = currentConsecutive;
        } else if (item.text.trim() !== '') {
          currentConsecutive = 0;
        }
      });

      return maxConsecutive;
    },
    sortOtherCompanies(companies) {
      companies.sort((a, b) => b.numberOfHighlightedWords - a.numberOfHighlightedWords ||
        b.consecutiveHighlightedWords - a.consecutiveHighlightedWords ||
        a.firstHighlightedIndex - b.firstHighlightedIndex,
      );
    },
    sortCompaniesWhichContainSearch(companies) {
      // The sooner the first highlighted word, the higher the company in the list.
      companies.sort((companyA, companyB) => {
        const positionA = this.getHighlightedSegments(companyA.displayLabel)[0].startIndex;
        const positionB = this.getHighlightedSegments(companyB.displayLabel)[0].startIndex;

        return positionA - positionB;
      });
    },
    async handleSearch() {
      if (!this.searchValue || this.searchValue.length < 2) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      try {
        this.isLoading = true;
        this.$refs.companyDropdown.open();

        const response = await this.lookupCompanyRegistrationsByReference();
        this.populateSuggestedCompanyRegistrations(response);
      } catch (err) {
        this.suggestedCompanyRegistrations = [];
      } finally {
        this.isLoading = false;
      }
    },
    highlightText(text, searchedText) {
      const searchWords = searchedText.split(/\s+/).filter(Boolean);
      const searchRegex = new RegExp(`(${searchWords.join('|')})`, 'gi');

      const textSegments = text.split(searchRegex).filter(Boolean);
      const wordsSet = new Set(text.toLowerCase().split(/\s+/));

      const seenHighlighted = new Set();

      return textSegments.map(segment => {
        const lowerSegment = segment.toLowerCase();
        const isHighlighted = searchWords.some(word => word.toLowerCase() === lowerSegment);
        const isFullWordMatch = isHighlighted && wordsSet.has(lowerSegment);

        // Find position of segment in original text
        const segmentIndex = text.toLowerCase().indexOf(lowerSegment);

        const wordNotMatchedYet = !seenHighlighted.has(lowerSegment);
        if (isHighlighted && wordNotMatchedYet) seenHighlighted.add(lowerSegment);

        return {
          text: segment,
          isHighlighted: wordNotMatchedYet ? isHighlighted : false,
          isFullWordMatch: wordNotMatchedYet ? isFullWordMatch : false,
          startIndex: segmentIndex,
        };
      });
    },
    handleClickOutside(event) {
      if (
        this.$refs.dropdown?.$el?.contains(event.target) ||
        this.$refs.searchInput?.$el?.contains(event.target)
      ) return;
      this.clearSuggestedCompanyRegistrations();
    },
    clearSuggestedCompanyRegistrations() {
      this.suggestedCompanyRegistrations = [];
      this.$refs.companyDropdown.hide();
    },
    autofillBillingInfos(company) {
      this.onSelectCompany(company);
      this.inputValue = `${company.name} - ${company.shopRegistrationNumber}`;
      this.name = company.name;
      this.registrationNumber = company.shopRegistrationNumber;
      this.clearSuggestedCompanyRegistrations();
    },
    resetCompanySelection() {
      this.onSelectCompany(null);
      this.name = null;
      this.registrationNumber = null;
    },
    handleReposition() {
      if (window.visualViewport.offsetTop !== 0 && isSafariBrowser() && isIOS()) {
        window.scroll({ top: -1, left: 0, behaviour: 'smooth' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-four__content-form__row-wrapper {
  display: flex;

  @media (max-width: $sm-width) {
    flex-direction: column;
  }
}

.step-four__content-form__row {
  flex-grow: 1;
}

.step-four__siret-search-subtitle {
  padding-top: 8px;
}

</style>
